import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';

import { action_status_options } from 'components/ICS/ICSConstants';

import ICSChecklist from 'components/ICS/ICSChecklist';

import { createIcsAction, updateIcsAction } from 'slices/ics_actionsSlice';

import { updateIcsIncidentAction } from 'slices/ics_incidentActionsSlice';

function setInitialStatus(editActionData, action_status_options) {
  const currentStatus =
    !!editActionData &&
    action_status_options.find((o) => o.label === editActionData?.status);
  if (currentStatus) {
    return currentStatus;
  } else {
    return { label: 'Not Started', value: 'Not Started' };
  }
}

export default function NewICSActionDialog({
  show,
  setEditActionData,
  editActionData,
  modalType,
  setModalType,
  onClose,
  incident_id,
  incident_roster,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const [isLoaded, setIsLoaded] = useState(true);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [incidentName, setIncidentName] = useState('');
  const [poc, setPoc] = useState('');
  const [briefedPoc, setBriefedPoc] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [status, setStatus] = useState(
    setInitialStatus(editActionData, action_status_options)
  );
  const [targetDate, setTargetDate] = useState(null);
  const [actualDate, setActualDate] = useState(null);
  const [checklist, setChecklist] = useState(editActionData?.checklist || []);
  const [descriptionError, setDescriptionError] = useState({
    hasError: true,
    message: '',
    touched: false,
  });

  const [actionId, setActionId] = useState('');
  const reduxDispatch = useDispatch();
  const isIncidentActionsLoaded = useSelector((state) => {
    return state.app.isIncidentActionsLoaded;
  });
  const isAddIncidentActionsLoaded = useSelector((state) => {
    return state.app.isAddIncidentActionsLoaded;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (!isLoaded && isIncidentActionsLoaded && isAddIncidentActionsLoaded) {
      onClose();
      setModalType('New');
      setEditActionData(undefined);
    }
  }, [isIncidentActionsLoaded, isAddIncidentActionsLoaded, isLoaded, onClose]);

  useEffect(() => {
    errorCheck(descriptionError);
  }, [description]);

  const errorCheck = (descriptionErrorNew) => {
    if (descriptionErrorNew.touched) {
      if (!description || description === '') {
        setDescriptionError({
          ...descriptionErrorNew,
          hasError: true,
          message: '*Description is required',
        });
      } else {
        setDescriptionError({
          ...descriptionErrorNew,
          hasError: false,
          message: '',
        });
      }
    }
  };
  const onTouched = () => {
    const descriptionErrorNew = { ...descriptionError, touched: true };
    setDescriptionError(descriptionErrorNew);
    errorCheck(descriptionErrorNew);
  };

  useEffect(() => {
    if (modalType === 'New') {
      setActionId(generateUUID());
    } else if (modalType === 'Edit') {
      setActionId(editActionData?.id);
      setValue('description', editActionData?.description);
      setValue('name', editActionData?.name);
      setValue('incident_name', editActionData?.incident_name);
      setValue('poc', editActionData?.poc);
      setValue('briefed_poc', editActionData?.briefed_poc);
      setValue('start_date', editActionData?.start_date);
      setValue('status', editActionData?.status);
      setValue('target_date', editActionData?.target_date);
      setValue('actual_date', editActionData?.actual_date);
    }
  }, [modalType]);

  const onNewActionDataSubmit = (data) => {
    let payload = {
      id: actionId,
      action_id: actionId,
      name: data.name,
      description: data.description,
      incident_name: data.incident_name,
      poc: data.poc?.value,
      briefed_poc: data.briefed_poc,
      start_date: data.start_date,
      status: status.value,
      target_date: data.target_date,
      actual_date: data.actual_date,
      incident_id: incident_id,
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      checklist: JSON.stringify(checklist),
      archived: false,
    };
    if (modalType === 'New') {
      reduxDispatch(createIcsAction(payload));
      onClose();
    } else if (modalType === 'Edit') {
      if (!incident_id) {
        reduxDispatch(updateIcsAction(payload.id, payload));
      } else if (!!incident_id) {
        payload.id = editActionData?.action_id;
        reduxDispatch(
          updateIcsIncidentAction(incident_id, payload.id, payload)
        );
      }
      onClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Action</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewActionDataSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">ID</label>
            <StylishNewInput value={actionId} readOnly disabled />
          </div>
          <div className="m-0">
            <label className="form-label">Name:</label>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.name && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Description:</label>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          {!!incident_id && (
            <>
              <div className="m-0">
                <label className="form-label">Point of Contact:</label>
                <Controller
                  control={control}
                  name="poc"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewSelect
                      options={incident_roster.map(u=>{
                        return {
                          label: u.user_name,
                          value: u.user_guid
                        }
                      })}
                      value={value}
                      defaultValue={incident_roster.find(u=>u.user_guid===editActionData?.poc)?.user_guid}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
                {errors.poc && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
              </div>
              <div className="m-0">
                <label className="form-label">Briefed POC:</label>
                <Controller
                  control={control}
                  name="briefed_poc"
                  render={({ field: { onChange, value } }) => (
                    <input
                      type="checkbox"
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">Start Date:</label>
                <Controller
                  control={control}
                  name="start_date"
                  render={({ field: { onChange, value } }) => (
                    <StylishDateTimePicker
                      onChange={(date) => onChange(date)}
                      value={value}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">Status:</label>
                <StylishNewSelect
                  options={action_status_options}
                  value={status}
                  onChange={(e) => {
                    setStatus(e);
                  }}
                />
              </div>
              <div className="m-0">
                <label className="form-label">Target Date:</label>
                <Controller
                  control={control}
                  name="target_date"
                  render={({ field: { onChange, value } }) => (
                    <StylishDateTimePicker
                      onChange={(date) => onChange(date)}
                      value={value}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">Actual Date:</label>
                <Controller
                  control={control}
                  name="actual_date"
                  render={({ field: { onChange, value } }) => (
                    <StylishDateTimePicker
                      onChange={(date) => onChange(date)}
                      value={value}
                    />
                  )}
                />
              </div>
            </>
          )}
          <div className="m-0">
            <label className="form-label">Checklist:</label>
            <div>
              <label className="form-label">
                Checklists are NOT editable after creation:
              </label>
            </div>
            <ICSChecklist
              checklist={checklist}
              setChecklist={setChecklist}
              editable={modalType === 'New' || !incident_id}
              incident_id={incident_id}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
