import React, { useEffect, useState } from 'react';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import dayjs from 'dayjs';

export default function ICSIncidentTactic({
  row,
  ics_tactics,
  ics_incident_tactics,
  ics_incident_strategies,
}) {
  const versions = ics_incident_tactics.find((it) => it.tactic_id === row.id)
    ?.versions;

  const [version, setVersion] = useState(versions && versions[0]);

  const [name, setName] = useState(row.name);
  const [description, setDescription] = useState(row.description);
  const [archived, setArchived] = useState(row.archived);
  const [lastModifiedBy, setLastModifiedBy] = useState(row.user_name);

  const [nameChanged, setNameChanged] = useState(false);
  const [descriptionChanged, setDescriptionChanged] = useState(false);
  const [archivedChanged, setArchivedChanged] = useState(false);
  const [lastModifiedByChanged, setLastModifiedByChanged] = useState(false);

  useEffect(() => {
    if (version) {
      setName(version.name);
      setDescription(version.description);
      setArchived(version.archived);
      setLastModifiedBy(version.user_name);

      // Check if changed from prior
      const vi = versions.findIndex((v) => v.id === version.id);
      if (versions[vi + 1]) {
        setNameChanged(versions[vi + 1].name !== version.name);
        setDescriptionChanged(
          versions[vi + 1].description !== version.description
        );
        setArchivedChanged(versions[vi + 1].archived !== version.archived);
        setLastModifiedByChanged(
          versions[vi + 1].user_name !== version.user_name
        );
      } else {
        setNameChanged(false);
        setDescriptionChanged(false);
        setArchivedChanged(false);
        setLastModifiedByChanged(false);
      }
    } else {
      setName(row.name);
      setDescription(row.description);
      setNameChanged(false);
      setDescriptionChanged(false);
      setArchivedChanged(false);
      setLastModifiedByChanged(false);
    }
  }, [version]);

  useEffect(() => {
    if (!!versions) {
      setVersion(versions[0]);
    }
  }, [versions]);

  let strategyName = '';
  if (!!ics_incident_strategies) {
    let tactic = ics_incident_tactics.find((t) => t.tactic_id === row.id);
    strategyName = ics_incident_strategies.find(
      (is) => is.strategy_id === tactic?.strategy_id
    )?.name;
  }

  return (
    <div className="ICSIncidentTactic">
      {versions && (
        <div className="mb-4">
          <h6 className="mb-2">Version:</h6>
          <StylishNewSelect
            options={versions.map((v) => {
              return {
                label: dayjs(v.timestamp).format('YYYY-MM-DD HH:mm'),
                value: v,
              };
            })}
            defaultValue={version}
            value={dayjs(version.timestamp).format('YYYY-MM-DD HH:mm')}
            onChange={(e) => {
              setVersion(e.value);
            }}
            placeholder={dayjs(version.timestamp).format('YYYY-MM-DD HH:mm')}
            isClearable={true}
            isSearchable={true}
            isMulti={false}
            isDisabled={false}
            className="SitrepSection-Select"
          />
        </div>
      )}
      <div className="mb-4">
        <h6 className="mb-2">Name:</h6>
        <p className="m-0" style={{ color: nameChanged ? 'yellow' : 'white' }}>
          {name}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Description:</h6>
        <p
          className="m-0"
          style={{ color: descriptionChanged ? 'yellow' : 'white' }}
        >
          {description}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Strategy:</h6>
        <p className="m-0">{strategyName}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Last Modified By:</h6>
        <p
          className="m-0"
          style={{ color: lastModifiedByChanged ? 'yellow' : 'white' }}
        >
          {lastModifiedBy}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Archived:</h6>
        <p
          className="m-0"
          style={{ color: archivedChanged ? 'yellow' : 'white' }}
        >
          {archived ? 'Yes' : 'No'}
        </p>
      </div>
    </div>
  );
}
