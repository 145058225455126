export const isUUID = (s: any) => {
  if (typeof s !== 'string') {
    return false;
  }
  return Boolean(
    s.match(
      /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
    )
  );
};
