import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import {
  selectGroupGuid,
  selectUserGuid,
} from '../../../slices/commonSelectors';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../assets/data/config';

export type ResourceSet = {
  id: UUID;
  resource_set_id: UUID;
  resource_site_id: UUID;
  group_guid: UUID;
  name: string;
  description: string;
  geolocation: any;
  kind: string;
  type: string;
  quantity: number;
  status: string;
  consolidation_target_id: UUID;
  archived: boolean;
  date_time_ordered: string; // As a string in ISO format
  eta: string; // As a string in ISO format
  incidents: UUID[];
};

export const keys = {
  base: 'resource_sets',
  get all() {
    return [keys.base];
  },
  list: (group_guid: UUID, user_guid: UUID) => [
    keys.base,
    group_guid,
    user_guid,
  ],
  usage: (resource_set_id: UUID) => [keys.base, 'usage', resource_set_id],
} as const;

export const useResourceSets = () => {
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.list(group_guid, user_guid),
    queryFn: async () => {
      const resp = await axios.get<ResourceSet[]>(
        `${config.apiGateway.ics_resource_sets}/${group_guid}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
  });
};

export type CreateResourceSetReqData = {
  name: string;
  description: string;
  geolocation: any;
  resource_site_id: UUID;
  kind: string;
  type: string;
  quantity: number;
  status: string;
  consolidation_target_id: UUID;
  archived: boolean;
  date_time_ordered: string; // Combined date and time as a single ISO string
  eta: string; // Combined date and time as a single ISO string
  incidents: UUID[];
};

export const useCreateResourceSet = () => {
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: CreateResourceSetReqData) => {
      return await axios.post<CreateResourceSetReqData, ResourceSet>(
        `${config.apiGateway.ics_resource_sets}`,
        {
          ...data,
          group_guid,
          user_guid,
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keys.list(group_guid, user_guid),
      });
    },
    onError: (error) => {
      toast.error(error.message, toastConfig);
    },
  });
};

type EditResourceSetReqData = {
  id?: UUID;
  resource_set_id: UUID;
  resource_site_id: UUID;
  group_guid?: UUID;
  name: string;
  description: string;
  geolocation: any;
  kind: string;
  type: string;
  quantity: number;
  status: string;
  consolidation_target_id: UUID;
  archived: boolean;
  date_time_ordered: string; // Combined date and time as a single ISO string
  eta: string; // Combined date and time as a single ISO string
  incidents: UUID[];
};

export const useUpdateResourceSet = (resourceSetId: UUID) => {
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useSelector(selectGroupGuid);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: EditResourceSetReqData) => {
      return await axios.put<EditResourceSetReqData, ResourceSet>(
        `${config.apiGateway.ics_resource_sets}/${resourceSetId}`,
        { ...data, user_guid, group_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.all });
    },
    onError: (error) => {
      toast.error(error.message, toastConfig as any);
    },
  });
};

export const useFetchResourceSetUsage = (resourceSetId: string) => {
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useSelector(selectGroupGuid);
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: keys.usage(resourceSetId),
    queryFn: async () => {
      try {
        const resp = await axios.get(
          `${config.apiGateway.ics_resource_sets}/usage/${resourceSetId}`,
          {
            headers: { 
              user_guid: user_guid,
              group_guid: group_guid,
            },
          }
        );
        return resp.data;
      } catch (error: any) {
        toast.error(error.message, toastConfig);
      }
    },
  });
};

// Define types for the mutation function parameters
type SplitResourceSetParams = {
  resource_set_id: UUID;
  quantity: number;
  name: string;
  resource_site_id: UUID;
};

type JoinResourceSetsParams = {
  source_id: UUID;
  target_id: UUID;
};

export const useSplitResourceSet = () => {
  const queryClient = useQueryClient();
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);

  return useMutation({
    mutationFn: async ({ resource_set_id, quantity, name, resource_site_id }: SplitResourceSetParams) => {
      return await axios.post(
        `${config.apiGateway.ics_resource_sets}/${resource_set_id}/split`,
        { quantity, name, resource_site_id, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keys.list(group_guid, user_guid),
      });
    },
    onError: (error) => {
      toast.error(error.message, toastConfig);
    },
  });
};

export const useJoinResourceSets = () => {
  const queryClient = useQueryClient();
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);

  return useMutation({
    mutationFn: async ({ source_id, target_id }: JoinResourceSetsParams) => {
      return await axios.post(
        `${config.apiGateway.ics_resource_sets}/${target_id}/join/${source_id}`,
        { user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keys.list(group_guid, user_guid),
      });
    },
    onError: (error) => {
      toast.error(error.message, toastConfig);
    },
  });
};

