import config from 'constants/apiConfig';
import axios from 'axios';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

import {
  FETCH_TWITTER_DATA_REQUEST,
  FETCH_TWITTER_DATA_SUCCESS,
  FETCH_TWITTER_DATA_ERROR,
  FETCH_TWITTER_DATA_ENRICHED_REQUEST,
  FETCH_TWITTER_DATA_ENRICHED_SUCCESS,
  FETCH_TWITTER_DATA_ENRICHED_ERROR,
  FETCH_OSINT_ASK_REQUEST,
  FETCH_OSINT_ASK_SUCCESS,
  FETCH_OSINT_ASK_ERROR,
} from 'constants/osint-action-types';

function fetchTwitterDataRequest() {
  return {
    type: FETCH_TWITTER_DATA_REQUEST,
  };
}

function fetchTwitterDataSuccess(twitterData) {
  return {
    type: FETCH_TWITTER_DATA_SUCCESS,
    payload: twitterData,
  };
}

function fetchTwitterDataError(error) {
  let label = error.message;
  if (!!error && !!error.response && !!error.response.status) {
    label = 'Invalid API Credentials';
  }
  console.error('Fetch Twitter Data API error: ' + label);
  toast.error('Fetch Twitter Data API error: ' + label, noAutoClose);
  return {
    type: FETCH_TWITTER_DATA_ERROR,
  };
}

export function fetchTwitterDataAction(
  searchText,
  numPosts,
  selectedSources,
  includeKeywords,
  excludeKeywords,
  start_time,
  end_time
) {
  return (dispatch) => {
    dispatch(fetchTwitterDataRequest());

    return axios(config.apiGateway.fetchTwitterData, {
      method: 'POST',
      data: JSON.stringify({
        search_text: searchText,
        numPosts,
        enrich: false,
        selectedSources,
        includeKeywords,
        excludeKeywords,
        start_time,
        end_time,
      }),
    })
      .then((response) => {
        const data = response?.data?.result || [];
        dispatch(fetchTwitterDataSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchTwitterDataError(error));
      });
  };
}

function fetchTwitterDataEnrichedRequest() {
  return {
    type: FETCH_TWITTER_DATA_ENRICHED_REQUEST,
  };
}

function fetchTwitterDataEnrichedSuccess(twitterData) {
  return {
    type: FETCH_TWITTER_DATA_ENRICHED_SUCCESS,
    payload: twitterData,
  };
}

function fetchTwitterDataEnrichedError(error) {
  let label = error.message;
  if (!!error && !!error.response && !!error.response.status) {
    label = 'Invalid API Credentials';
  }
  console.error('Fetch Enriched Twitter Data API error: ' + label);
  toast.error('Fetch Enriched Twitter Data API error: ' + label, noAutoClose);
  return {
    type: FETCH_TWITTER_DATA_ENRICHED_ERROR,
  };
}

export function fetchTwitterDataEnriched(
  searchText,
  numPosts,
  selectedSources,
  includeKeywords,
  excludeKeywords,
  start_time,
  end_time
) {
  return (dispatch) => {
    dispatch(fetchTwitterDataEnrichedRequest());

    return axios(config.apiGateway.fetchTwitterData, {
      method: 'POST',
      data: JSON.stringify({
        search_text: searchText,
        numPosts,
        selectedSources,
        enrich: true,
        includeKeywords,
        excludeKeywords,
        start_time,
        end_time,
      }),
    })
      .then((response) => {
        const data = response?.data?.result || [];
        dispatch(fetchTwitterDataEnrichedSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchTwitterDataEnrichedError(error));
      });
  };
}

function fetchOSINTAskRequest() {
  return {
    type: FETCH_OSINT_ASK_REQUEST,
  };
}

function fetchOSINTAskSuccess(osintData) {
  return {
    type: FETCH_OSINT_ASK_SUCCESS,
    payload: osintData,
  };
}

function fetchOSINTAskError(error) {
  let label = error.message;
  if (!!error && !!error.response && !!error.response.status) {
    label = 'Invalid API Credentials';
  }
  console.error('Fetch OSINT Data API error: ' + label);
  toast.error('Fetch OSINT Twitter Data API error: ' + label, noAutoClose);
  return {
    type: FETCH_OSINT_ASK_ERROR,
  };
}

export function fetchOSINTAsk(
  searchText,
  numPosts,
  selectedSources,
  includeKeywords,
  excludeKeywords
) {
  return (dispatch) => {
    dispatch(fetchOSINTAskRequest());

    return axios(config.apiGateway.fetchOSINTAsk, {
      method: 'POST',
      data: JSON.stringify({
        search_text: searchText,
        numPosts,
        selectedSources,
        includeKeywords,
        excludeKeywords,
      }),
    })
      .then((response) => {
        const data = response?.data?.result || [];
        dispatch(fetchOSINTAskSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchOSINTAskError(error));
      });
  };
}
