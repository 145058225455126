import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Help from 'PREPAREsrc/component/Help/Help';
import {
  HelpActivity,
  useSelfHostedKnowledgeBase,
} from 'assets/data/HelpContent';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import DiceLog from './DiceLog';
import PrepareLog from './PrepareLog';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export default function Activity() {
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState('Dice');

  const toggleData = [
    { label: 'Incident Management Workspace', value: 'Dice' },
    { label: 'Exercise Management Workspace', value: 'Prepare' },
  ];

  const handleToggleButtonClick = (value) => {
    setSelectedTab(value);
  };

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase();

  return (
    <>
      <div className="site">
        <div className="site-main">
          <div className="site-main__activity">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <button onClick={() => navigate(-1)}>
                          <SharedIcon
                            iconName="keyboard_arrow_left"
                            classes="me-2"
                          />
                          Back
                        </button>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Activity
                      </li>
                    </ol>
                  </nav>
                  <div className="form-block">
                    <div className="d-md-flex align-items-center mb-4">
                      <h4 className="m-0">Activity</h4>
                      <div className="ms-auto d-flex align-items-md-center mt-3 mt-md-0">
                        <StylishNewToggleButtonGroup
                          data={toggleData}
                          selected={selectedTab}
                          onChange={handleToggleButtonClick}
                          size={'sml'}
                          className="flex-grow-1"
                        />
                        {selectedTab === 'Prepare' ? (
                          <Help
                            classes={'ms-3 sml flex-shrink-0'}
                            title={HelpActivity.title}
                            content={HelpActivity.content}
                            link={selfHostedKnowledgeBase || HelpActivity.link}
                          />
                        ) : null}
                      </div>
                    </div>
                    {selectedTab === 'Dice' ? (
                      <DiceLog />
                    ) : selectedTab === 'Prepare' ? (
                      <PrepareLog />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
