import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from '../../assets/data/config';

//Get Paged Users
export const getPagedUsers = (
  searchTerm: string,
  offset: number,
  pageSize: number
) => {
  return useQuery({
    queryKey: ['users', searchTerm, offset, pageSize],
    queryFn: async () => {
      const users = await axios.post(
        config.apiGateway.getAllUserWithSubscription,
        {
          page: offset || 1,
          limit: pageSize || 10,
          term: searchTerm || '',
        }
      );
      return users.data;
    },
    throwOnError: (error) => {
      toast.error(error.message, toastConfig);
      return false;
    },
  });
};

//Edit User
type EditUserParams = {
  subscriptionId: string;
  value: string;
  subscribed_mass_notification: boolean;
  subscribed_international_messaging: boolean;
};

export const editUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: EditUserParams) => {
      const {
        subscriptionId,
        value,
        subscribed_mass_notification,
        subscribed_international_messaging,
      } = params;
      await axios.post(config.apiGateway.upgradeUserSubscription, {
        upgradeSubscription: {
          subscription_id: subscriptionId,
          current_status: value,
          subscribed_mass_notification,
          subscribed_international_messaging,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
    onError: (error) => {
      toast.error(error.message, toastConfig);
      return false;
    },
  });
};

type BulkEditUserParams = {
  userData: {
    current_status: string;
    is_enterprise_client: boolean;
    subscribed_international_messaging: boolean;
    subscribed_mass_notification: boolean;
    subscription_end_on: string;
    users: [];
  };
};

export const bulkEditUsers = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: BulkEditUserParams) => {
      const { userData } = params;
      await axios.post(config.apiGateway.bulkUpgradeUserSubscription, {
        subscriptions: userData,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
    onError: (error) => {
      toast.error(error.message, toastConfig);
      return false;
    },
  });
};
