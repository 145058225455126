import {
  useWorkAssignments,
  WorkAssignment,
} from './hooks/workAssignmentHooks';
import StylishNewTable from '../DesignSystems/New/StylishNewTable';
import ICSIncidentWorkAssignment from './cards/ICSIncidentWorkAssignment';
import ExpandDownArrow from '../DesignSystems/buttons/ExpandDownArrow';
import ExpandRightArrow from '../DesignSystems/buttons/ExpandRightArrow';
import React, { useState } from 'react';
import { ColumnDescription, ExpandRowProps } from 'react-bootstrap-table-next';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import PageTitle from '../headers/PageTitle';
import WorkAssignmentAddDrawer from './WorkAssignmentAddDrawer';
import WorkAssignmentEditDrawer from './WorkAssignmentEditDrawer';

const IAPAssignments: React.FC = () => {
  const [modal, setModal] = useState<'new' | 'edit' | undefined>();
  const [selectedWorkAssignment, setSelectedWorkAssignment] = useState<
    WorkAssignment | undefined
  >();

  const workAssignments = useWorkAssignments();

  const editRow = (row: WorkAssignment) => {
    setSelectedWorkAssignment(row);
    setModal('edit');
  };

  const expandRow: ExpandRowProps<WorkAssignment> = {
    renderer: (row: WorkAssignment) => {
      return <ICSIncidentWorkAssignment row={row} selectCallback={editRow} />;
    },
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? <ExpandDownArrow /> : <ExpandRightArrow />,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <span className="sr-only">Details</span>
            <ExpandDownArrow />
          </>
        );
      }
      return (
        <>
          <span className="sr-only">Details</span>
          <ExpandRightArrow />
        </>
      );
    },
  };

  return (
    <div className="maincontent">
      <PageTitle title="Work Assignments">
        <StylishNewButton
          className="button--primary"
          onClick={() => {
            setModal('new');
          }}
        >
          New Work Assignment
        </StylishNewButton>
      </PageTitle>
      {workAssignments.isSuccess && (
        <StylishNewTable
          keyField={'id'}
          columns={allColumns}
          rows={workAssignments.data}
          expandRow={expandRow}
        />
      )}
      {modal === 'new' ? (
        <WorkAssignmentAddDrawer toggle={() => setModal(undefined)} />
      ) : modal === 'edit' && selectedWorkAssignment ? (
        <WorkAssignmentEditDrawer
          toggle={() => setModal(undefined)}
          selectedEntity={selectedWorkAssignment}
        />
      ) : null}
    </div>
  );
};
export default IAPAssignments;

const allColumns: ColumnDescription<WorkAssignment>[] = [
  {
    dataField: 'work_assignment',
    text: 'Work Assignment',
    sort: true,
    attrs: { title: 'Work Assignment' },
  },
  {
    dataField: 'branch',
    text: 'Branch',
    sort: true,
    attrs: { title: 'Branch' },
  },
  {
    dataField: 'division',
    text: 'Division/Group',
    sort: true,
    attrs: { title: 'Division/Group' },
  },
  {
    dataField: 'resources_sets',
    text: 'Resources',
    sort: true,
    attrs: { title: 'Resources' },
  },
  {
    dataField: 'overhead_positions',
    text: 'Overhead Positions',
    sort: true,
    attrs: { title: 'Overhead Positions' },
  },
  {
    dataField: 'reporting_location',
    text: 'Reporting Location',
    sort: true,
    attrs: { title: 'Reporting Location' },
  },
  {
    dataField: 'requested_arrival_time',
    text: 'Requested Arrival Time',
    sort: true,
    attrs: { title: 'Requested Arrival Time' },
  },
  {
    dataField: 'special_instructions',
    text: 'Special Instructions',
    sort: true,
    attrs: { title: 'Special Instructions' },
  },
  {
    dataField: 'user_name',
    text: 'Last Modified By',
    sort: true,
    attrs: { title: 'Last Modified By' },
  },
  {
    dataField: 'archived',
    text: 'Archived',
    sort: true,
    attrs: { title: 'Archived' },
    formatter: (cell) => (cell ? 'Yes' : 'No'),
  },
];
