import React from 'react';
import { Card } from 'react-bootstrap';
import GraphicsUserAuthorizationFailed from 'assets/images/graphics__user-authorization-failed.svg';
import { useWindowSize } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { StylishNewButton } from '../New/StylishNewButton';
interface MobileWarningDialogProps {
  children: React.ReactNode;
}

const MobileWarningDialog: React.FC<MobileWarningDialogProps> = ({
  children,
}) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();

  if (width < 430) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Card className="text-center bg-bglight">
          <Card.Body className="pb-5">
            <div className="d-flex flex-column align-items-center">
              <img
                src={GraphicsUserAuthorizationFailed}
                alt=""
                className="mb-5"
              />
              <Card.Text className="text-white">
                We&apos;re sorry, this feature is not available on this screen
                size.
              </Card.Text>
              <StylishNewButton
                className="button--primary"
                onClick={() => navigate(-1)}
              >
                Go Back
              </StylishNewButton>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }

  return <>{children}</>;
};

export default MobileWarningDialog;
