import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import moment from 'moment';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import EditOperationalPeriodDialog from 'components/IncidentConfig/EditOperationalPeriodDialog';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import {
  deleteMedicalPlanHospital,
  deleteMedicalPlanMedicalAidStation,
  deleteMedicalPlanTransportation,
  fetchMedicalPlan,
  updateMedicalPlan,
  fetchMedicalAidStationList,
} from 'actions/IAPForm206Actions';
// import generateUUID from 'utils/sharedUtils/generateUUID';
import { AddMedicalAidStationToMedicalPlanDialog } from './AddMedicalAidStationToMedicalPlanDialog';
import { AddTransportationToMedicalPlanDialog } from './AddTransportationToMedicalPlan';
import { AddHospitalToMedicalPlanDialog } from './AddHospitalToMedicalPlan';
import { Config206 } from 'components/FormBuilder/FormTemplates/FormConfig/206Config';
import {
  fetchSignatures,
  fetchSignaturesPreparedBy,
  fetchSignaturesReviewedBy,
} from 'actions/signatureActions';
import { getRosterForIncident } from 'actions/incidentActions';
import { toast } from 'react-toastify';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';

import { AddMedicalAidStationDialog } from './AddMedicalAidStationDialog';
import { AddTransportationDialog } from './AddTransportationDialog';
import { AddHospitalDialog } from './AddHospitalDialog';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { toastConfig } from 'assets/data/config';

export const IAPForm206 = ({
  formRecordData,
  onFormRecordDataUpdate,
  formConfig,
  setDataLoaded,
  currentRole,
  currentFormIsMostRecentForm,
}) => {
  const reduxDispatch = useDispatch();

  const navigate = useNavigate();

  const [formState, setFormState] = useState(Config206);
  const [incidentName, setIncidentName] = useState();
  const [
    openAddMedicalAidStationsModal,
    setOpenAddMedicalAidStationsModal,
  ] = useState(false);
  const [openAddTransportaionModal, setOpenAddTransportaionModal] = useState(
    false
  );
  const [openAddHospitalsModal, setOpenAddHospitalsModal] = useState(false);
  const [
    operationalPeriodDateTimeFrom,
    setOperationalPeriodDateTimeFrom,
  ] = useState(moment().format('MM/DD/YYYY HH:mm'));
  const [
    operationalPeriodDateTimeTo,
    setOperationalPeriodDateTimeTo,
  ] = useState(moment().format('MM/DD/YYYY HH:mm'));
  const [
    isEditOperationalPeriodOpen,
    setIsEditOperationalPeriodOpen,
  ] = useState(false);
  const [
    specialMedicalEmergencyProcedures,
    setSpecialMedicalEmergencyProcedures,
  ] = useState('');
  // const [planningSectionChiefs, setPlanningSectionChiefs] = useState([]);
  const [userPreparedBy, setUserPreparedBy] = useState([]);
  const [userReviewedBy, setUserReviewedBy] = useState([]);
  const [editData, setEditData] = useState(null);
  const [notifyUserPermission, setNotifyUserPermission] = useState(true);
  const [showOperationPeriodError, setShowOperationPeriodError] = useState(
    true
  );
  const [preparedByDateTime, setPreparedByDateTime] = useState(moment());
  const [reviewedByDateTime, setReviewedByDateTime] = useState(moment());
  const [deleteData, setDeleteData] = useState();
  const [
    showMedicalAidStationConfirmDialog,
    setShowMedicalAidStationConfirmDialog,
  ] = useState(false);
  const [
    showTransportationConfirmDialog,
    setShowTransportationConfirmDialog,
  ] = useState(false);
  const [showHospitalConfirmDialog, setShowHospitalConfirmDialog] = useState(
    false
  );

  const [
    openAddNewMedicalAidStationModal,
    setOpenAddNewMedicalAidStationModal,
  ] = useState(false);

  const [
    openAddNewTransportationModal,
    setOpenAddNewTransportationModal,
  ] = useState(false);

  const [
    medicalAidStationDialogType,
    setMedicalAidStationDialogType,
  ] = useState(null);
  const [editMedicalAidStationData, setEditMedicalAidStationData] = useState(
    null
  );
  const [editTransportData, setEditTransportData] = useState(null);
  const [transportDialogType, setTransportDialogType] = useState(null);
  const [openAddNewHospitalModal, setOpenAddNewHospitalModal] = useState(false);
  const [hospitalDialogType, setHospitalDialogType] = useState(null);
  const [editHospitalData, setEditHospitalData] = useState(null);
  const [
    medicalPlanTransportationList,
    setMedicalPlanTransportationList,
  ] = useState([]);
  const [
    medicalPlanMedicalAidStationsList,
    setMedicalPlanMedicalAidStationsList,
  ] = useState([]);
  const [medicalPlanHospitalList, setMedicalPlanHospitalList] = useState([]);

  const reduxMedicalAidStationsList = useSelector(
    (state) => state.app.medicalAidStationsList
  );

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const reduxMedicalPlan = useSelector((state) => state.app.medical_plan);
  const reduxUser = useSelector((state) => {
    return state.app.user;
  });
  const reduxPreparedBySignatures = useSelector((state) => {
    return state.app.prepared_by_signatures;
  });

  const reduxReviewedBySignatures = useSelector((state) => {
    return state.app.reviewed_by_signatures;
  });
  const isFetchSignaturesLoaded = useSelector((state) => {
    return state.app.isFetchSignaturesLoaded;
  });
  const reduxRosterForIncident = useSelector(
    (state) => state.app.rosterForIncident
  );

  const refreshMedicalAidStationList = () => {
    setOpenAddNewMedicalAidStationModal(false);
    reduxDispatch(fetchMedicalPlan());
  };

  const refreshTransportationList = () => {
    setOpenAddNewTransportationModal(false);
    reduxDispatch(fetchMedicalPlan());
  };

  const onEditMedicalAidStation = (mas) => {
    setMedicalAidStationDialogType('Edit');
    setEditMedicalAidStationData({
      ...mas,
      paramedics_on_site: mas.paramedics_on_site === 'No' ? false : true,
    });
    setOpenAddNewMedicalAidStationModal(true);
  };

  const onEditTransport = (transport) => {
    setTransportDialogType('Edit');
    setEditTransportData({
      ...transport,
      als: transport.als === 'No' ? false : true,
      bls: transport.bls === 'No' ? false : true,
    });
    setOpenAddNewTransportationModal(true);
  };

  const refreshHospitalList = () => {
    setOpenAddNewHospitalModal(false);
    reduxDispatch(fetchMedicalPlan());
  };

  const onEditHospital = (hospital) => {
    setHospitalDialogType('Edit');
    setEditHospitalData({
      ...hospital,
      burn_ctr: hospital.burn_ctr === 'No' ? false : true,
      trauma_center: hospital.trauma_center === 'No' ? false : true,
      helipad: hospital.helipad === 'No' ? false : true,
    });
    setOpenAddNewHospitalModal(true);
  };

  const onDeleteMedicalAidStationAction = (row) => {
    setDeleteData(row);
    setShowMedicalAidStationConfirmDialog(true);
  };

  const onConfirmedDeleteMedicalAidStationActionData = () => {
    // reduxDispatch(startLoading());
    reduxDispatch(deleteMedicalPlanMedicalAidStation(deleteData?.mpmaId));
    setDeleteData(null);
    setShowMedicalAidStationConfirmDialog(false);
  };

  const onDeleteTransportationAction = (row) => {
    setDeleteData(row);
    setShowTransportationConfirmDialog(true);
  };

  const onConfirmedDeleteTransportationActionData = () => {
    // reduxDispatch(startLoading());
    reduxDispatch(deleteMedicalPlanTransportation(deleteData?.mptId));
    setDeleteData(null);
    setShowTransportationConfirmDialog(false);
  };

  const onDeleteHospitalAction = (row) => {
    setDeleteData(row);
    setShowHospitalConfirmDialog(true);
  };

  const onConfirmedDeleteHospitalActionData = () => {
    // reduxDispatch(startLoading());
    reduxDispatch(deleteMedicalPlanHospital(deleteData?.mphId));
    setDeleteData(null);
    setShowHospitalConfirmDialog(false);
  };

  const medicalAidStationsColumns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
    },
    {
      dataField: 'address',
      text: 'Location',
      sort: true,
      attrs: { title: 'Location' },
    },
    {
      dataField: 'contact',
      text: 'Contact Info. #',
      sort: true,
      attrs: { title: 'Contact Info. #' },
    },
    {
      dataField: 'paramedics_on_site',
      text: 'Paramedics On-Site',
      attrs: { title: 'Paramedics On-Site' },
    },
  ];

  const medicalAidStationActionColumn = {
    text: 'Action',
    isDummyField: true,
    formatter: (cell, row, rowIndex) => (
      <StylishNewTableAction
        name="Medical Plan Medical Aid Station"
        cell={cell}
        row={row}
        rowIndex={rowIndex}
        onDelete={
          () => onDeleteMedicalAidStationAction(row)
          // row?.mpmaId &&
          // reduxDispatch(deleteMedicalPlanMedicalAidStation(row?.mpmaId))
        }
        onEdit={() => {
          setEditData(row);
          onEditMedicalAidStation(row);
          // setOpenAddMedicalAidStationsModal(true);
        }}
      />
    ),
    headerStyle: {
      width: '100px',
    },
    headerAlign: 'center',
    align: 'center',
    attrs: { title: 'Action' },
  };

  const transportationColumns = [
    {
      dataField: 'ambulance_service',
      text: 'Ambulance Service',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Ambulance Service' },
    },
    {
      dataField: 'address',
      text: 'Address',
      sort: true,
      attrs: { title: 'Address' },
    },
    {
      dataField: 'contact',
      text: 'Contact Info. #',
      sort: true,
      attrs: { title: 'Contact Info. #' },
    },
    {
      dataField: 'als',
      text: 'Level of Service - ALS',
      attrs: { title: 'Level of Service - ALS' },
    },
    {
      dataField: 'bls',
      text: 'Level of Service - BLS',
      attrs: { title: 'Level of Service - BLS' },
    },
  ];

  const transportationActionColumn = {
    text: 'Action',
    isDummyField: true,
    formatter: (cell, row, rowIndex) => (
      <StylishNewTableAction
        name="Medical Plan Transportation"
        cell={cell}
        row={row}
        rowIndex={rowIndex}
        onDelete={
          () => onDeleteTransportationAction(row)
          // row?.mptId &&
          // reduxDispatch(deleteMedicalPlanTransportation(row?.mptId))
        }
        onEdit={() => {
          setEditData(row);
          onEditTransport(row);
          // setOpenAddNewTransportationModal(true);
          // setOpenAddTransportaionModal(true);
        }}
      />
    ),
    headerStyle: {
      width: '100px',
    },
    headerAlign: 'center',
    align: 'center',
    attrs: { title: 'Action' },
  };

  const hospitalColumns = [
    {
      dataField: 'name',
      text: 'Hospital Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Hospital Name' },
    },
    {
      dataField: 'address',
      text: 'Address',
      sort: true,
      attrs: { title: 'Address' },
    },
    {
      dataField: 'contact',
      text: 'Contact Info. #',
      sort: true,
      attrs: { title: 'Contact Info. #' },
    },
    {
      dataField: 'travel_time_ground',
      text: 'Travel Time (Ground)',
      sort: true,
      attrs: { title: 'Travel Time (Ground)' },
    },
    {
      dataField: 'travel_time_air',
      text: 'Travel Time (Air)',
      sort: true,
      attrs: { title: 'Travel Time (Air)' },
    },
    {
      dataField: 'trauma_center',
      text: 'Trauma Center',
      attrs: { title: 'Trauma Center' },
    },
    {
      dataField: 'trauma_center_level',
      text: 'Trauma Center Level',
      attrs: { title: 'Trauma Center Level' },
    },
    {
      dataField: 'burn_ctr',
      text: 'Burn Ctr?',
      attrs: { title: 'Burn Ctr?' },
    },
    {
      dataField: 'helipad',
      text: 'Helipad?',
      attrs: { title: 'Helipad?' },
    },
  ];

  const hospitalActionColumn = {
    text: 'Action',
    isDummyField: true,
    formatter: (cell, row, rowIndex) => (
      <StylishNewTableAction
        name="Medical Plan Hospital"
        cell={cell}
        row={row}
        rowIndex={rowIndex}
        onDelete={
          () => onDeleteHospitalAction(row)
          // row?.mphId && reduxDispatch(deleteMedicalPlanHospital(row?.mphId))
        }
        onEdit={() => {
          setEditData(row);
          onEditHospital(row);
          // setOpenAddHospitalsModal(true);
        }}
      />
    ),
    headerStyle: {
      width: '100px',
    },
    headerAlign: 'center',
    align: 'center',
    attrs: { title: 'Action' },
  };

  const updateFormState = (key, value) => {
    const index = formState.field.findIndex((field) => field.key === key);
    if (index !== -1) {
      let obj = { ...formState.field[index] };
      obj = { ...obj, value: value };
      formState.field[index] = obj;
      setFormState(formState);
      onFormRecordDataUpdate(formState);
    }
  };

  useEffect(() => {
    if (reduxCurrentIncident?.id) {
      setIncidentName(reduxCurrentIncident?.name);
      if (!!reduxCurrentIncident?.current_period) {
        reduxDispatch(fetchMedicalPlan());
      }
      if (!reduxCurrentIncident?.operational_period_start_date_time) {
        if (showOperationPeriodError) {
          toast.error('Please set an operational period first!', toastConfig);
          setShowOperationPeriodError(false);
          navigate('/incident_management');
        }
      }
      reduxDispatch(getRosterForIncident());
      setOperationalPeriodDateTimeFrom(
        moment(reduxCurrentIncident?.operational_period_start_date_time)
      );
      setOperationalPeriodDateTimeTo(
        moment(reduxCurrentIncident?.operational_period_start_date_time).add(
          reduxCurrentIncident?.operational_period_interval,
          'hours'
        )
      );

      updateFormState('1 Incident Name_10', reduxCurrentIncident?.name);

      reduxCurrentIncident?.operational_period_start_date_time &&
        updateFormState(
          'Date From',
          moment(
            reduxCurrentIncident?.operational_period_start_date_time
          ).format('DD/MM/YYYY')
        );
      reduxCurrentIncident?.operational_period_start_date_time &&
        updateFormState(
          'Time From',
          moment(
            reduxCurrentIncident?.operational_period_start_date_time
          ).format('HH:mm')
        );

      reduxCurrentIncident?.operational_period_start_date_time &&
        reduxCurrentIncident?.operational_period_interval &&
        updateFormState(
          'Date To',
          moment(reduxCurrentIncident?.operational_period_start_date_time)
            .add(reduxCurrentIncident?.operational_period_interval, 'hours')
            .format('DD/MM/YYYY')
        );
      reduxCurrentIncident?.operational_period_start_date_time &&
        reduxCurrentIncident?.operational_period_interval &&
        updateFormState(
          'Time To',
          moment(reduxCurrentIncident?.operational_period_start_date_time)
            .add(reduxCurrentIncident?.operational_period_interval, 'hours')
            .format('HH:mm')
        );
    }
  }, [reduxCurrentIncident]);

  useEffect(() => {
    reduxDispatch(fetchMedicalAidStationList());
  }, []);

  useEffect(() => {
    if (reduxCurrentIncident?.operational_period_start_date_time) {
      if (!!reduxMedicalPlan) {
        moment(reduxMedicalPlan?.prepared_by_date).isValid() &&
          setPreparedByDateTime(moment(reduxMedicalPlan?.prepared_by_date));
        moment(reduxMedicalPlan?.reviewed_by_date).isValid() &&
          setReviewedByDateTime(moment(reduxMedicalPlan?.reviewed_by_date));
        setMedicalPlanMedicalAidStationsList(
          reduxMedicalPlan?.medical_plan_medical_aid
        );
        setMedicalPlanTransportationList(
          reduxMedicalPlan?.medical_plan_transportation
        );
        setMedicalPlanHospitalList(reduxMedicalPlan?.medical_plan_hospital);
        setSpecialMedicalEmergencyProcedures(
          reduxMedicalPlan?.special_medical_emergency_procedures
        );
        reduxDispatch(
          fetchSignaturesPreparedBy(
            reduxMedicalPlan?.prepared_by?.profile_settings?.user_guid
          )
        );
        reduxDispatch(
          fetchSignaturesReviewedBy(
            reduxMedicalPlan?.reviewed_by?.profile_settings?.user_guid
          )
        );
      }
      // else {
      //   createOrUpdateMedicalPlan();
      // }

      let medicalAidStations = [];

      for (
        let masIndex = 0;
        masIndex < reduxMedicalPlan?.medical_plan_medical_aid?.length;
        masIndex++
      ) {
        const foundMedicalAidStation = reduxMedicalAidStationsList.find(
          (station) =>
            station.id ===
            reduxMedicalPlan?.medical_plan_medical_aid[masIndex].id
        );

        if (!!foundMedicalAidStation) {
          medicalAidStations.push({
            key: 'NameRow' + (masIndex + 1),
            type: 'Text',
            value: foundMedicalAidStation.name,
          });
          medicalAidStations.push({
            key: 'LocationRow' + (masIndex + 1),
            type: 'Text',
            value: foundMedicalAidStation.address,
          });
          medicalAidStations.push({
            key: 'Contact NumbersFrequencyRow' + (masIndex + 1),
            type: 'Text',
            value: foundMedicalAidStation.contact,
          });
          medicalAidStations.push({
            key: 'Paramedics  on Site - Yes, Row ' + (masIndex + 1),
            type: 'Check',
            value: foundMedicalAidStation.paramedics_on_site,
          });
          medicalAidStations.push({
            key: 'Paramedics on Site - No, Row ' + (masIndex + 1),
            type: 'Check',
            value: !foundMedicalAidStation.paramedics_on_site,
          });
        }
      }
      if (medicalAidStations.length) {
        updateFormState('Medical Aid Stations', medicalAidStations);
      }

      let transportation = [];
      for (
        let transIndex = 0;
        transIndex < reduxMedicalPlan?.medical_plan_transportation?.length;
        transIndex++
      ) {
        transportation.push({
          key: 'Ambulance ServiceRow' + (transIndex + 1),
          type: 'Text',
          value:
            reduxMedicalPlan?.medical_plan_transportation[transIndex]
              .ambulance_service,
        });
        transportation.push({
          key: 'LocationRow' + (transIndex + 1) + '-2',
          type: 'Text',
          value:
            reduxMedicalPlan?.medical_plan_transportation[transIndex].address,
        });
        transportation.push({
          key: 'Contact NumbersFrequencyRow' + (transIndex + 1) + '_2',
          type: 'Text',
          value:
            reduxMedicalPlan?.medical_plan_transportation[transIndex].contact,
        });
        transportation.push({
          key: 'Check Box' + (transIndex + 1) + ' Level of Service - ALS',
          type: 'Check',
          value: reduxMedicalPlan?.medical_plan_transportation[transIndex].als,
        });
        transportation.push({
          key: 'Check Box' + (transIndex + 1) + ' Level of Service - BLS',
          type: 'Check',
          value: reduxMedicalPlan?.medical_plan_transportation[transIndex].bls,
        });
      }
      if (transportation.length) {
        updateFormState('Transportation', transportation);
      }

      let hospitals = [];
      for (
        let hosIndex = 0;
        hosIndex < reduxMedicalPlan?.medical_plan_hospital?.length;
        hosIndex++
      ) {
        hospitals.push({
          key: 'Hospital NameRow' + (hosIndex + 1),
          type: 'Text',
          value: reduxMedicalPlan?.medical_plan_hospital[hosIndex].name,
        });
        hospitals.push({
          key: 'Address Latitude  Longitude if HelipadRow' + (hosIndex + 1),
          type: 'Text',
          value: reduxMedicalPlan?.medical_plan_hospital[hosIndex].address,
        });
        hospitals.push({
          key: 'Contact Numbers FrequencyRow' + (hosIndex + 1),
          type: 'Text',
          value: reduxMedicalPlan?.medical_plan_hospital[hosIndex].contact,
        });
        hospitals.push({
          key: 'AirRow' + (hosIndex + 1),
          type: 'Text',
          value:
            reduxMedicalPlan?.medical_plan_hospital[hosIndex].travel_time_air +
            '',
        });
        hospitals.push({
          key: 'GroundRow' + (hosIndex + 1),
          type: 'Text',
          value:
            reduxMedicalPlan?.medical_plan_hospital[hosIndex]
              .travel_time_ground + '',
        });
        hospitals.push({
          key: 'Check Box Trauma Center ' + (hosIndex + 1),
          type: 'Check',
          value:
            reduxMedicalPlan?.medical_plan_hospital[hosIndex].trauma_center,
        });
        hospitals.push({
          key: 'Trauma Center - Level (Row ' + (hosIndex + 1) + ')',
          type: 'Text',
          value:
            reduxMedicalPlan?.medical_plan_hospital[hosIndex]
              .trauma_center_level,
        });
        hospitals.push({
          key: 'Check Box Burn Center Yes ' + (hosIndex + 1),
          type: 'Check',
          value: reduxMedicalPlan?.medical_plan_hospital[hosIndex].burn_ctr,
        });
        hospitals.push({
          key: 'Check Box Burn Center No ' + (hosIndex + 1),
          type: 'Check',
          value: !reduxMedicalPlan?.medical_plan_hospital[hosIndex].burn_ctr,
        });
        hospitals.push({
          key: 'Check Box Helipad Yes ' + (hosIndex + 1),
          type: 'Check',
          value: reduxMedicalPlan?.medical_plan_hospital[hosIndex].helipad,
        });
        hospitals.push({
          key: 'Check Box Helipad No ' + (hosIndex + 1),
          type: 'Check',
          value: !reduxMedicalPlan?.medical_plan_hospital[hosIndex].helipad,
        });
      }
      if (hospitals.length) {
        updateFormState('Hospitals', hospitals);
      }

      updateFormState(
        'Special Medical Emergency Procedures',
        reduxMedicalPlan?.special_medical_emergency_procedures
      );

      updateFormState(
        '7 Prepared by Medical Unit Leader Name',
        reduxMedicalPlan?.prepared_by?.profile_settings?.userName
      );

      let isChecked = false;
      reduxMedicalPlan?.medical_plan_hospital?.map((mph) => {
        isChecked = isChecked || mph?.helipad;
      });

      updateFormState(
        'Check Box if aviation assests are utilized for rescue',
        isChecked
      );

      updateFormState(
        '8 Approved by Safety Officer Name',
        reduxMedicalPlan?.reviewed_by?.profile_settings?.userName
      );

      if (
        !!reduxPreparedBySignatures &&
        !!reduxPreparedBySignatures.length > 0
      ) {
        updateFormState(
          'Signature_11',
          reduxPreparedBySignatures[0].signature_image
        );
      }

      if (
        !!reduxReviewedBySignatures &&
        !!reduxReviewedBySignatures.length > 0
      ) {
        updateFormState(
          'Signature_12',
          reduxReviewedBySignatures[0].signature_image
        );
      }

      updateFormState('DateTime_10', moment().format('DD/MM/YYYY HH:mm'));
    }
  }, [reduxMedicalPlan, reduxCurrentIncident, reduxMedicalAidStationsList]);

  useEffect(() => {
    if (
      !!reduxRosterForIncident &&
      reduxRosterForIncident.length > 0 &&
      reduxUser &&
      reduxUser?.user_guid
    ) {
      const mulUser = reduxRosterForIncident.filter(
        (users) =>
          users.incident_seat === 'Medical Unit Leader' &&
          users.user_guid === reduxUser.user_guid
      );
      setUserPreparedBy(mulUser);
      if (!mulUser?.length && notifyUserPermission) {
        toast.info(
          'Only "Medical Unit Leader" can edit ICS 206 form',
          toastConfig
        );
        setNotifyUserPermission(false);
      }
      setUserReviewedBy(
        reduxRosterForIncident.filter(
          (users) =>
            users.incident_seat === 'Safety Officer' &&
            users.user_guid === reduxUser.user_guid
        )
      );
    }
  }, [reduxRosterForIncident]);

  const createOrUpdateMedicalPlan = () => {
    const submitData = {
      procedure: specialMedicalEmergencyProcedures,
      prepared_by: reduxMedicalPlan?.prepared_by?.user_guid,
      reviewed_by: reduxMedicalPlan?.reviewed_by?.user_guid,
      medical_plan_id: reduxMedicalPlan?.id,
    };
    reduxDispatch(updateMedicalPlan(submitData));
  };

  const createOrUpdateMedicalPlanPreviewedBy = () => {
    const submitData = {
      procedure: reduxMedicalPlan?.special_medical_emergency_procedures,
      prepared_by: reduxUser.user_guid,
      prepared_by_date: preparedByDateTime || moment(),
      reviewed_by_date: reduxMedicalPlan?.reviewed_by_date,
      reviewed_by: reduxMedicalPlan?.reviewed_by?.user_guid,
      medical_plan_id: reduxMedicalPlan?.id,
    };
    reduxDispatch(updateMedicalPlan(submitData));
  };

  const createOrUpdateMedicalPlanReviewedBy = () => {
    const submitData = {
      procedure: reduxMedicalPlan?.special_medical_emergency_procedures,
      prepared_by: reduxMedicalPlan?.prepared_by?.user_guid,
      prepared_by_date: reduxMedicalPlan?.prepared_by_date,
      reviewed_by: reduxUser.user_guid,
      reviewed_by_date: reviewedByDateTime || moment(),
      medical_plan_id: reduxMedicalPlan?.id,
    };
    reduxDispatch(updateMedicalPlan(submitData));
  };

  return (
    <>
      <div className="mb-4">
        <h4>1. Incident Name</h4>
        <label className="form-label">Name:</label>
        <p>{incidentName}</p>
      </div>
      <hr className="dashed my-4" />

      <div className="mb-4">
        <h4>2. Operational Period</h4>
        {!!reduxCurrentIncident && reduxCurrentIncident?.current_period && (
          <>
            <div className="form-group">
              <label className="form-label">From:</label>
            </div>
            <div className="mb-3">
              {moment(
                reduxCurrentIncident?.operational_period_start_date_time
              ).format('MM/DD/YYYY HH:mm')}
            </div>
            <div className="form-group">
              <label className="form-label">To:</label>
            </div>
            <div className="mb-3">
              {moment(reduxCurrentIncident?.operational_period_start_date_time)
                .add(reduxCurrentIncident?.operational_period_interval, 'hours')
                .format('MM/DD/YYYY HH:mm')}
            </div>
          </>
        )}
      </div>
      <hr className="dashed my-4" />

      <div className="mb-4">
        <h4>3. Medical Aid Stations</h4>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={() => setOpenAddMedicalAidStationsModal(true)}
          disabled={!userPreparedBy?.length}
        >
          Add
        </StylishNewButton>
        <StylishNewTable
          keyField={'mpmaId'}
          rows={
            medicalPlanMedicalAidStationsList.map((mpma) => ({
              ...mpma,
              paramedics_on_site: mpma.paramedics_on_site ? 'Yes' : 'No',
            })) || []
          }
          columns={
            !userPreparedBy?.length
              ? medicalAidStationsColumns
              : [...medicalAidStationsColumns, medicalAidStationActionColumn]
          }
        />
      </div>
      <hr className="dashed my-4" />

      <div className="mb-4">
        <h4>4. Transportation</h4>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={() => setOpenAddTransportaionModal(true)}
          disabled={!userPreparedBy?.length}
        >
          Add
        </StylishNewButton>
        <StylishNewTable
          keyField={'mptId'}
          rows={
            medicalPlanTransportationList?.map((mpt) => ({
              ...mpt,
              als: mpt.als ? 'Yes' : 'No',
              bls: mpt.bls ? 'Yes' : 'No',
            })) || []
          }
          columns={
            !userPreparedBy?.length
              ? transportationColumns
              : [...transportationColumns, transportationActionColumn]
          }
        />
      </div>
      <hr className="dashed my-4" />

      <div className="mb-4">
        <h4>5. Hospitals</h4>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={() => setOpenAddHospitalsModal(true)}
          disabled={!userPreparedBy?.length}
        >
          Add
        </StylishNewButton>
        <StylishNewTable
          keyField={'mphId'}
          rows={
            medicalPlanHospitalList?.map((mph) => ({
              ...mph,
              trauma_center: mph.trauma_center ? 'Yes' : 'No',
              burn_ctr: mph.burn_ctr ? 'Yes' : 'No',
              helipad: mph.helipad ? 'Yes' : 'No',
            })) || []
          }
          columns={
            !userPreparedBy?.length
              ? hospitalColumns
              : [...hospitalColumns, hospitalActionColumn]
          }
        />
      </div>
      <hr className="dashed my-4" />

      <div className="mb-4">
        <h4>6. Special Medical Emergency Procedures</h4>
        <StylishNewTextArea
          value={specialMedicalEmergencyProcedures}
          onChange={(e) =>
            setSpecialMedicalEmergencyProcedures(e?.target?.value)
          }
          maxLength={1000}
        />
        <StylishNewButton
          className="button--primary mt-4"
          type="button"
          onClick={createOrUpdateMedicalPlan}
          disabled={!userPreparedBy?.length}
        >
          Save
        </StylishNewButton>
      </div>
      <hr className="dashed my-4" />

      <div className="mb-4">
        <h4 className="mb-1">7. Prepared by: (Medical Unit Leader)</h4>
        <p className="fst-italic weight-500 mb-4">
          Seats Can Be Managed at The{' '}
          <Link to={'/incident_configuration'}>Duty Roster</Link>
        </p>
        <div className="mb-3">
          <label className="form-label">Name</label>
          <StylishNewInput
            value={
              reduxMedicalPlan?.prepared_by?.profile_settings?.userName ||
              userPreparedBy[0]?.profile_settings?.userName
            }
            readOnly={true}
          />
        </div>
        <div className="mb-4">
          <StylishDateTimePicker
            value={preparedByDateTime}
            type="datetime-local"
            onChange={setPreparedByDateTime}
            disabled={!userPreparedBy?.length}
            dateLabel="Date"
            timeLabel="Time"
          />
        </div>
        <div className="m-0">
          <label className="form-label">
            Signature: <span className="color-danger">*</span>
          </label>
          <p className="fst-italic weight-500 m-0">
            Signatures are used to electronically sign Forms.
          </p>
        </div>
        <StylishNewButton
          className="button--primary mt-4"
          type="button"
          onClick={createOrUpdateMedicalPlanPreviewedBy}
          disabled={!userPreparedBy?.length}
        >
          Save
        </StylishNewButton>
      </div>

      <hr className="dashed my-4" />

      <div className="mb-0">
        <h4 className="mb-1">8. Reviewed by: (Safety Officer)</h4>
        <p className="fst-italic weight-500 mb-4">
          Seats Can Be Managed at The{' '}
          <Link to={'/incident_configuration'}>Duty Roster</Link>
        </p>
        <div className="mb-3">
          <label className="form-label">Name</label>
          <StylishNewInput
            value={
              reduxMedicalPlan?.reviewed_by?.profile_settings?.userName ||
              userReviewedBy[0]?.profile_settings?.userName
            }
            readOnly={true}
          />
        </div>
        <div className="mb-4">
          <StylishDateTimePicker
            value={reviewedByDateTime}
            type="datetime-local"
            onChange={setReviewedByDateTime}
            disabled={!userReviewedBy?.length}
            dateLabel="Date"
            timeLabel="Time"
          />
        </div>
        <div className="m-0">
          <label className="form-label">
            Signature: <span className="color-danger">*</span>
          </label>
          <p className="fst-italic weight-500 m-0">
            Signatures are used to electronically sign Forms.
          </p>
        </div>
        <StylishNewButton
          className="button--primary mt-4"
          type="button"
          onClick={createOrUpdateMedicalPlanReviewedBy}
          disabled={!userReviewedBy?.length}
        >
          Save
        </StylishNewButton>
      </div>

      {isEditOperationalPeriodOpen && (
        <EditOperationalPeriodDialog
          show={isEditOperationalPeriodOpen}
          onClose={() => setIsEditOperationalPeriodOpen(false)}
        />
      )}

      {openAddMedicalAidStationsModal && (
        <AddMedicalAidStationToMedicalPlanDialog
          show={openAddMedicalAidStationsModal}
          onClose={() => {
            setOpenAddMedicalAidStationsModal(false);
            setEditData(null);
          }}
          medicalPlanId={reduxMedicalPlan?.id}
          existingMedicalAidStations={
            reduxMedicalPlan?.medical_plan_medical_aid?.map(
              (mpma) => mpma.id
            ) || []
          }
          editData={editData}
        />
      )}

      {openAddTransportaionModal && (
        <AddTransportationToMedicalPlanDialog
          show={openAddTransportaionModal}
          onClose={() => {
            setOpenAddTransportaionModal(false);
            setEditData(null);
          }}
          medicalPlanId={reduxMedicalPlan?.id}
          existingTransportations={
            reduxMedicalPlan?.medical_plan_transportation?.map(
              (trans) => trans.id
            ) || []
          }
          editData={editData}
        />
      )}

      {openAddHospitalsModal && (
        <AddHospitalToMedicalPlanDialog
          show={openAddHospitalsModal}
          onClose={() => {
            setOpenAddHospitalsModal(false);
            setEditData(null);
          }}
          medicalPlanId={reduxMedicalPlan?.id}
          existingHospitals={
            reduxMedicalPlan?.medical_plan_hospital?.map((hosp) => hosp.id) ||
            []
          }
          editData={editData}
        />
      )}

      {openAddNewMedicalAidStationModal && (
        <AddMedicalAidStationDialog
          show={openAddNewMedicalAidStationModal}
          onClose={() => setOpenAddNewMedicalAidStationModal(false)}
          refreshMedicalAidStationList={refreshMedicalAidStationList}
          medicalAidStationDialogType={medicalAidStationDialogType}
          editMedicalAidStationData={
            !!editMedicalAidStationData ? editMedicalAidStationData : {}
          }
        />
      )}

      {openAddNewTransportationModal && (
        <AddTransportationDialog
          show={openAddNewTransportationModal}
          onClose={() => setOpenAddNewTransportationModal(false)}
          refreshTransportationList={refreshTransportationList}
          transportDialogType={transportDialogType}
          editTransportData={!!editTransportData ? editTransportData : {}}
        />
      )}

      {openAddNewHospitalModal && (
        <AddHospitalDialog
          show={openAddNewHospitalModal}
          onClose={() => setOpenAddNewHospitalModal(false)}
          refreshHospitalList={refreshHospitalList}
          hospitalDialogType={hospitalDialogType}
          editHospitalData={!!editHospitalData ? editHospitalData : {}}
        />
      )}

      {showMedicalAidStationConfirmDialog && (
        <StylishConfirmDialog
          show={showMedicalAidStationConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={
            'Are you sure you want to delete this medical aid station?'
          }
          onClose={() => setShowMedicalAidStationConfirmDialog(false)}
          onConfirm={() => onConfirmedDeleteMedicalAidStationActionData()}
        />
      )}

      {showTransportationConfirmDialog && (
        <StylishConfirmDialog
          show={showTransportationConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this transportation?'}
          onClose={() => setShowTransportationConfirmDialog(false)}
          onConfirm={() => onConfirmedDeleteTransportationActionData()}
        />
      )}

      {showHospitalConfirmDialog && (
        <StylishConfirmDialog
          show={showHospitalConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this hospital?'}
          onClose={() => setShowHospitalConfirmDialog(false)}
          onConfirm={() => onConfirmedDeleteHospitalActionData()}
        />
      )}
    </>
  );
};
