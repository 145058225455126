export const FETCH_WORKFLOW_NODES_REQUEST = "FETCH_WORKFLOW_NODES_REQUEST"
export const FETCH_WORKFLOW_NODES_SUCCESS = "FETCH_WORKFLOW_NODES_SUCCESS"
export const FETCH_WORKFLOW_NODES_ERROR = "FETCH_WORKFLOW_NODES_ERROR"
export const FETCH_WORKFLOWS_REQUEST = "FETCH_WORKFLOWS_REQUEST"
export const FETCH_WORKFLOWS_SUCCESS = "FETCH_WORKFLOWS_SUCCESS"
export const FETCH_WORKFLOWS_ERROR = "FETCH_WORKFLOWS_ERROR"
export const UPSERT_WORKFLOW_REQUEST = "UPSERT_WORKFLOW_REQUEST"
export const UPSERT_WORKFLOW_SUCCESS = "UPSERT_WORKFLOW_SUCCESS"
export const UPSERT_WORKFLOW_ERROR = "UPSERT_WORKFLOW_ERROR"
export const DELETE_WORKFLOW_REQUEST = "DELETE_WORKFLOW_REQUEST"
export const DELETE_WORKFLOW_SUCCESS = "DELETE_WORKFLOW_SUCCESS"
export const DELETE_WORKFLOW_ERROR = "DELETE_WORKFLOW_ERROR"
export const EXECUTE_WORKFLOW_REQUEST = "EXECUTE_WORKFLOW_REQUEST"
export const EXECUTE_WORKFLOW_SUCCESS = "EXECUTE_WORKFLOW_SUCCESS"
export const EXECUTE_WORKFLOW_ERROR = "EXECUTE_WORKFLOW_ERROR"
export const REMOVE_WORKFLOW_EDGE_REQUEST = "REMOVE_WORKFLOW_EDGE_REQUEST"
export const REMOVE_WORKFLOW_EDGE_SUCCESS = "REMOVE_WORKFLOW_EDGE_SUCCESS"
export const REMOVE_WORKFLOW_EDGE_ERROR = "REMOVE_WORKFLOW_EDGE_ERROR"
export const ROLLBACK_WORKFLOW_REQUEST = "ROLLBACK_WORKFLOW_REQUEST"
export const ROLLBACK_WORKFLOW_SUCCESS = "ROLLBACK_WORKFLOW_SUCCESS"
export const ROLLBACK_WORKFLOW_ERROR = "ROLLBACK_WORKFLOW_ERROR"