import React, { useState } from 'react';
import StylishNewSelect from './New/StylishNewSelect';

type Props = {
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  options: { value: number | string; label: string }[];
  className?: string;
};
export const PaginationDropdown: React.FC<Props> = ({
  setPage,
  setPerPage,
  options,
  className = '',
}) => {
  const [pagination, setPagination] = useState(options[0]);
  return (
    // @ts-ignore
    <StylishNewSelect
      classes={className}
      options={options}
      value={pagination}
      onChange={(p: any) => {
        setPagination(p);
        setPerPage(p.value);
        setPage(1);
      }}
      placeholder={'Select per page'}
      isClearable={false}
      isSearchable={false}
      isMulti={false}
      isDisabled={false}
    />
  );
};
