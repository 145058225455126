import * as turf from '@turf/turf';
import dayjs from 'dayjs';


export default function queryBingNewsEffect(
  selectedEvent,
  selectedEventTabNews,
  setSelectedEventTabNews,
  eventLocationInfo,
  bingNewsKey,
  key,
  selectedDatetime
) {
  // Check if selectedEvent is valid, and the key is set to 'news'
  if (!!selectedEvent && key === 'news') {
    let centroid = eventLocationInfo?.centroid;

    // Check if it's a custom event with a geolocation and no predefined centroid
    if (selectedEvent?.geolocation?.centroid) {
      // Calculate the centroid from the geolocation using Turf.js
      centroid = selectedEvent?.geolocation?.centroid
    }

    let BING_ENDPOINT = 'https://api.bing.microsoft.com/v7.0/news/search';
    let headers = {
      'Ocp-Apim-Subscription-Key': bingNewsKey,
    };

    // Format selectedDatetime to match API requirements (e.g., 'yyyy-MM-dd')
    const formattedDate = new Date(selectedDatetime).toISOString().split('T')[0];

    // Create the date range for the query (start of the day to end of the day)
    const dateRange = `${formattedDate}..${formattedDate}`;

    // Handle custom event case
    if (selectedEvent.hazard_name === 'Hurricane') {
      const stormType = selectedEvent.hurricane_data_points[0].storm_type
      const stormName = selectedEvent.hurricane_data_points[0].unified_storm_name
      BING_ENDPOINT += `?q=${stormType} ${stormName}`;
    } else {
      // Use custom event's hazard name and place for Bing News search
      BING_ENDPOINT += `?q=${selectedEvent.hazard_name||selectedEvent.name} ${eventLocationInfo?.placeName || ''}`;

      // Add the country code if available
      if (eventLocationInfo?.cc) {
        BING_ENDPOINT += `&cc=${eventLocationInfo.cc}`;
      }

      // Add the centroid-based search location if available
      if (centroid) {
        headers['X-Search-Location'] = `lat:${centroid.geometry.coordinates[1]};long:${centroid.geometry.coordinates[0]};re:2000m`;
      }
    }

    // Add the date range to the query string
    BING_ENDPOINT += `&date=${dateRange}&sortBy=Date&count=100`;

    // Fetch news from the Bing News API
    fetch(BING_ENDPOINT, {
      headers: headers,
    })
      .then((res) => res.json())
      .then((response) => {
        const filteredArticles = response.value
          .filter((article) => {
            const articleDate = new Date(article.datePublished);
            return articleDate <= new Date(selectedDatetime)
            && articleDate >= dayjs(selectedDatetime).subtract(1, "days").toDate();
          })
          .sort((a, b) => {
            const dateA = new Date(a.datePublished);
            const dateB = new Date(b.datePublished);
            return dateB - dateA;
          });

        setSelectedEventTabNews(filteredArticles);
      })
      .catch((error) => {
        console.error('Bing News API ERROR', error);
      });
  }
}
