import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

import {
  createIcsObjective,
  updateIcsObjective,
} from 'slices/ics_objectivesSlice';

import { updateIcsIncidentObjective } from 'slices/ics_incidentObjectivesSlice';

export default function NewICSObjectiveDialog({
  show,
  setEditObjectiveData,
  editObjectiveData,
  modalType,
  setModalType,
  onClose,
  incident_id,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const [isLoaded, setIsLoaded] = useState(true);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState({
    hasError: true,
    message: '',
    touched: false,
  });

  const [objectiveId, setObjectiveId] = useState('');
  const reduxDispatch = useDispatch();
  const isIncidentObjectivesLoaded = useSelector((state) => {
    return state.app.isIncidentObjectivesLoaded;
  });
  const isAddIncidentObjectivesLoaded = useSelector((state) => {
    return state.app.isAddIncidentObjectivesLoaded;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (
      !isLoaded &&
      isIncidentObjectivesLoaded &&
      isAddIncidentObjectivesLoaded
    ) {
      onClose();
      setModalType('New');
      setEditObjectiveData(undefined);
    }
  }, [
    isIncidentObjectivesLoaded,
    isAddIncidentObjectivesLoaded,
    isLoaded,
    onClose,
  ]);

  useEffect(() => {
    errorCheck(descriptionError);
  }, [description]);

  const errorCheck = (descriptionErrorNew) => {
    if (descriptionErrorNew.touched) {
      if (!description || description === '') {
        setDescriptionError({
          ...descriptionErrorNew,
          hasError: true,
          message: '*Description is required',
        });
      } else {
        setDescriptionError({
          ...descriptionErrorNew,
          hasError: false,
          message: '',
        });
      }
    }
  };
  const onTouched = () => {
    const descriptionErrorNew = { ...descriptionError, touched: true };
    setDescriptionError(descriptionErrorNew);
    errorCheck(descriptionErrorNew);
  };

  useEffect(() => {
    if (modalType === 'New') {
      setObjectiveId(generateUUID());
    } else if (modalType === 'Edit') {
      setObjectiveId(editObjectiveData.id);
      setValue('description', editObjectiveData.description);
      setValue('name', editObjectiveData.name);
    }
  }, [modalType]);

  const onNewObjectiveDataSubmit = (data) => {
    let payload = {
      id: objectiveId,
      objective_id: objectiveId,
      name: data.name,
      description: data.description,
      incident_id: incident_id,
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      archived: false,
    };
    if (modalType === 'New') {
      reduxDispatch(createIcsObjective(payload));
      onClose();
    } else if (modalType === 'Edit') {
      if (!incident_id) {
        reduxDispatch(updateIcsObjective(payload.id, payload));
      } else if (!!incident_id) {
        payload.id = editObjectiveData?.id;
        reduxDispatch(
          updateIcsIncidentObjective(incident_id, payload.id, payload)
        );
      }
      onClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Objective</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewObjectiveDataSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">ID</label>
            <StylishNewInput value={objectiveId} readOnly disabled />
          </div>
          <div className="m-0">
            <label className="form-label">Name:</label>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.description && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Description:</label>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
