import React from 'react';
import classnames from 'classnames';

const StylishNewTextArea = React.forwardRef(
  (
    {
      className,
      black,
      fullWidth,
      maxLength,
      value,
      disableLengthMessage,
      ...props
    },
    ref
  ) => {
    const builtClassName = classnames(
      {
        'stylishInput--black': !!black,
        'stylishTextArea--fullWidth': !!fullWidth,
        'form-control': true,
        'form-textarea': true,
      },
      className
    );

    let lengthMessage;
    let error;

    if (value && maxLength) {
      lengthMessage =
        value.length >= maxLength
          ? `Maximum length has been reached.`
          : `Remaining characters: ${maxLength - value.length}`;
      error = value.length >= maxLength;
    }

    return (
      <>
        <textarea
          className={builtClassName}
          maxLength={maxLength}
          value={value}
          ref={ref}
          {...props}
        ></textarea>
        {!disableLengthMessage ? (
          <>
            {lengthMessage && (
              <span
                className={classnames('form-text no-icon', {
                  'form-error': error,
                  'text-muted': !error,
                })}
              >
                {lengthMessage}
              </span>
            )}
          </>
        ) : null}
      </>
    );
  }
);

StylishNewTextArea.displayName = 'StylishNewTextArea(ForwardRef)';

export default StylishNewTextArea;
