import React from 'react';

export default function ICSIncidentComs({ row }) {
  return (
    <div className="ICSIncidentComs">
      <div className="mb-4">
        <h6 className="mb-2">Type:</h6>
        <p className="m-0">{row.type}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Branch:</h6>
        <p className="m-0">{row.branch}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Division/Group:</h6>
        <p className="m-0">{row.division_group}</p>
      </div>
      {row.type === 'nonradio' ? (
        <div className="mb-4">
          <h6 className="mb-2">Method of Contact:</h6>
          <p className="m-0">{row.method_of_contact}</p>
        </div>
      ) : (
        <>
          <div className="mb-4">
            <h6 className="mb-2">Zone Group:</h6>
            <p className="m-0">{row.zone_group}</p>
          </div>
          <div className="mb-4">
            <h6 className="mb-2">Channel Number:</h6>
            <p className="m-0">{row.channel_number}</p>
          </div>
          <div className="mb-4">
            <h6 className="mb-2">Function:</h6>
            <p className="m-0">{row.function}</p>
          </div>
          <div className="mb-4">
            <h6 className="mb-2">Channel Name:</h6>
            <p className="m-0">{row.channel_name}</p>
          </div>
          <div className="mb-4">
            <h6 className="mb-2">Assignment:</h6>
            <p className="m-0">{row.assignment}</p>
          </div>
          <div className="mb-4">
            <h6 className="mb-2">RX Frequency:</h6>
            <p className="m-0">{row.rx_frequency}</p>
          </div>
          <div className="mb-4">
            <h6 className="mb-2">RX Tone/NAC:</h6>
            <p className="m-0">{row.rx_tone_nac}</p>
          </div>
          <div className="mb-4">
            <h6 className="mb-2">TX Frequency:</h6>
            <p className="m-0">{row.tx_frequency}</p>
          </div>
          <div className="mb-4">
            <h6 className="mb-2">TX Tone/NAC:</h6>
            <p className="m-0">{row.tx_tone_nac}</p>
          </div>
          <div className="mb-4">
            <h6 className="mb-2">Mode:</h6>
            <p className="m-0">{row.mode}</p>
          </div>
          <div className="mb-4">
            <h6 className="mb-2">Remarks:</h6>
            <p className="m-0">{row.remarks}</p>
          </div>
        </>
      )}
      <div className="mb-4">
        <h6 className="mb-2">Special Instructions:</h6>
        <p className="m-0">{row.special_instructions}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Last Modified By:</h6>
        <p className="m-0">{row.user_name}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Archived:</h6>
        <p className="m-0">{row.archived ? 'Yes' : 'No'}</p>
      </div>
    </div>
  );
}
