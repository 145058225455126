import React from 'react';
import { Offcanvas, Row, Col, Spinner, Form } from 'react-bootstrap';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormSelectField from './formFields/FormSelectField';
import { useJoinResourceSets, useResourceSets } from './hooks/resourceSetHooks';

const ResourceSetJoinDrawer = ({ toggle, selectedEntity }) => {
    const form = useForm({
        mode: 'onChange',
    });
    const { handleSubmit } = form;
    const { mutate: joinResourceSets, isLoading: isProcessing } = useJoinResourceSets();
    const { data: resourceSets } = useResourceSets();

    const onSubmit = async (data) => {
        joinResourceSets({ source_id: selectedEntity.resource_set_id, target_id: data.target_set }, {
            onSuccess: () => toggle(), // Close drawer after processing
        });
    };

    // Filter resource sets to only include those of the same kind as the selected entity
    const otherResourceSets = resourceSets
        .filter((set) => set.resource_set_id !== selectedEntity.resource_set_id && set.kind === selectedEntity.kind);

    return (
        <DrawerWrapper toggle={toggle} title="Join Resource Sets">
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column justify-content-between flex-grow-1">
                    <Offcanvas.Body>
                        <Row className="mb-3">
                            <Col>
                                <Form.Label>Joining Set:</Form.Label>
                                <div>
                                    <strong>Name:</strong> {selectedEntity.name}
                                </div>
                                <div>
                                    <strong>Quantity:</strong> {selectedEntity.quantity} units
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <FormSelectField
                                title="Select Target Resource Set"
                                fieldName="target_set"
                                options={otherResourceSets.map((set) => ({
                                    value: set.resource_set_id,
                                    label: `${set.name} | (${set.kind}) | ${set.quantity} units`,
                                }))}
                                rules={{
                                    required: { value: true, message: 'This field is required' },
                                }}
                                variant="full"
                            />
                        </Row>
                    </Offcanvas.Body>
                    <DrawerFooter>
                        <div className="button-group">
                            <StylishNewButton
                                className="button--secondary button--reverse"
                                type="button"
                                onClick={toggle}
                                disabled={isProcessing} // Disable close button while processing
                            >
                                Close
                            </StylishNewButton>
                            <StylishNewButton
                                className="button--primary"
                                type="submit"
                                disabled={isProcessing || otherResourceSets.length === 0} // Disable submit button if no valid targets
                            >
                                {isProcessing ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Join'}
                            </StylishNewButton>
                        </div>
                    </DrawerFooter>
                </form>
            </FormProvider>
        </DrawerWrapper>
    );
};

export default ResourceSetJoinDrawer;
