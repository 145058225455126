import {
  ADD_PARTICIPANT_DATA,
  UPDATE_PLATFORM_PARTICIPANT,
  UPDATE_PLANNER_PARTICIPANT,
  ADD_PARTICIPANT_ID,
  ADD_PARTICIPANT_DATA_FUNDING_SOURCE,
} from "./participant.constants";

const initialState = {
  id: null,
  name: null,
  icon: null,
  command: null,
  homeStation: null,
  DepartmentType: null,
  department: null,
  workspaceId: null,
  platformId: null,
  beddown: null,
  participantId: null,
  email: null,
  phnNumber: null,
  isPrimary: null,
  selectParticipantId: null,
  amount: null,
  fundingSourceId: null,
};

const ParticipantReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PARTICIPANT_ID:
      return {
        ...state,
        selectParticipantId: action.payload,
      };

    case ADD_PARTICIPANT_DATA:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        icon: action.payload.icon,
        command: action.payload.command,
        homeStation: action.payload.homeStation,
        DepartmentType: action.payload.DepartmentType,
        department: action.payload.department,
      };
    case UPDATE_PLATFORM_PARTICIPANT:
      return {
        ...state,
        workspaceId: action.payload.workspaceId,
        platformId: action.payload.platformId,
        beddown: action.payload.beddown,
        participantId: action.payload.participantId,
      };

    case UPDATE_PLANNER_PARTICIPANT:
      return {
        ...state,
        email: action.payload.email,
        phnNumber: action.payload.phnNumber,
        isPrimary: action.payload.isPrimary,
      };
    case ADD_PARTICIPANT_DATA_FUNDING_SOURCE:
      return {
        ...state,
        amount: action.payload.amount,
        fundingSourceId: action.payload.fundingSourceId,
      };

    default:
      return state;
  }
};

export default ParticipantReducer;
