import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import SelectIncidentModal from './SelectIncidentModal';
import {
  selectIncidentGuid,
  useAppSelector,
} from '../../slices/commonSelectors';
import { useFirstMountState } from 'react-use';

const IAPWrapper = () => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isFirstMount = useFirstMountState();
  const currentIncident = useAppSelector(selectIncidentGuid);

  useEffect(() => {
    if (!incidentId || incidentId === 'undefined') {
      setModal(true);
    } else if (modal && incidentId) {
      setModal(false);
    }
  }, [incidentId]);

  useEffect(() => {
    if (!isFirstMount && currentIncident && currentIncident !== incidentId) {
      const newPath = location.pathname.replace(
        `/iap/${incidentId}`,
        `/iap/${currentIncident}`
      );
      console.log('navigating to ', newPath);
      navigate(newPath);
    }
  }, [currentIncident]);

  return modal ? <SelectIncidentModal /> : <Outlet />;
};

export default IAPWrapper;
