import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import {
  fetchIcsIncidentComs
} from './ics_incidentComsSlice';

import {
  getUserGuid,
  getGroupGuid
} from 'slices/sliceHelpers';

// Slice
const ics_comsSlice = createSlice({
  name: 'ics_coms',
  initialState: {
    ics_coms: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    createIcsComsSuccess: (state, action) => {
      state.ics_coms.push(action.payload);
    },
    fetchIcsComsSuccess: (state, action) => {
      state.ics_coms = action.payload;
    },
    updateIcsComsSuccess: (state, action) => {
      const index = state.ics_coms.findIndex((coms) => coms.id === action.payload.id);
      state.ics_coms[index] = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  },
});

export const {
  createIcsComsSuccess,
  fetchIcsComsSuccess,
  updateIcsComsSuccess,
  setError
} = ics_comsSlice.actions;

export default ics_comsSlice.reducer;

export const createIcsComs = (data) => async (dispatch, getState) => {
  const user_guid = getUserGuid(getState);
  const group_guid = getGroupGuid(getState);
  try {
    const response = await axios.post(`${config.apiGateway.ics_coms}`, { ...data, user_guid });
    if (data.incident_id) {
      dispatch(fetchIcsIncidentComs(data.incident_id));
    }
    dispatch(fetchIcsComs(group_guid));
    dispatch(createIcsComsSuccess(response.data));
  } catch (error) {
    toast.error(error.message, toastConfig);
    dispatch(setError(error.message));
  }
};

export const fetchIcsComs = (group_guid) => async (dispatch, getState) => {
  const user_guid = getUserGuid(getState);
  try {
    const response = await axios.get(`${config.apiGateway.ics_coms}/${group_guid}`, {
      headers: { 'user_guid': user_guid }
    });
    dispatch(fetchIcsComsSuccess(response.data));
  } catch (error) {
    toast.error(error.message, toastConfig);
    dispatch(setError(error.message));
  }
};

export const updateIcsComs = (id, data) => async (dispatch, getState) => {
  const user_guid = getUserGuid(getState);
  const group_guid = getGroupGuid(getState);
  try {
    const response = await axios.put(`${config.apiGateway.ics_coms}/${id}`, { ...data, user_guid });
    dispatch(fetchIcsComs(group_guid));
    dispatch(updateIcsComsSuccess(response.data));
  } catch (error) {
    toast.error(error.message, toastConfig);
    dispatch(setError(error.message));
  }
};
