import React, { useEffect, useState } from 'react';

import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import Info from 'components/DesignSystems/text/Info';
import dayjs from 'dayjs';

// Credit @ https://stackoverflow.com/a/25867068
function degToCompass(num) {
  var val = Math.floor(num / 22.5 + 0.5);
  var arr = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW',
  ];
  return arr[val % 16];
}

function reportDataForLayer(layer, data) {
  if (!layer || !data) return;

  let finalData = '';
  let title = layer.key;
  switch (layer.key) {
    case 'Convective precipitation':
      finalData = 'N/A';
      break;
    case 'Precipitation intensity':
      finalData = 'N/A';
      break;
    case 'Accumulated precipitation':
      finalData = 'N/A';
      break;
    case 'Accumulated precipitation - rain':
      finalData = 'N/A';
      break;
    case 'Accumulated precipitation - snow':
      finalData = 'N/A';
      break;
    case 'Depth of snow':
      finalData = 'N/A';
      break;
    case 'Wind Speed':
    case 'Wind Speed and Direction':
      title = 'Wind Speed and Direction';
      finalData = !!data.wind
       && (!!data.wind.gust
        ? ' (Gust) ' + data.wind.gust
        : ' (Speed) ' + data.wind.speed);
      finalData = finalData + ' mph ' + degToCompass(data.wind.deg);
      break;
    case 'Pressure':
      finalData = !!data.main && data.main.pressure;
      break;
    case 'Temperature':
      finalData = !!data.main && data.main.temp + ' F';
      break;
    case 'Temperature (Dew Point)':
      finalData = !!data.main && data.main.feels_like + ' F';
      break;
    case 'Humidity':
      finalData = !!data.main && data.main.humidity + '%';
      break;
    case 'Cloudiness':
      finalData =
        (!!data.clouds && !!data.clouds.all && data.clouds.all + '%') || '0%';
      break;
    default:
      finalData = 'N/A';
  }

  return `${finalData}`;
}

export default function Popup({
  weatherLayer,
  weatherData,
  owmToken,
  selectedDatetime,
}) {

  if (!weatherLayer || !weatherData) return <></>;

  const [weatherDataResponse, setWeatherDataResponse] = useState(null);
    
  const forecastInfo = reportDataForLayer(weatherLayer, weatherDataResponse)
  const nowTime = dayjs().minute(0).second(0).millisecond(0)
  const tooEarly = nowTime > selectedDatetime.minute(0).second(0).millisecond(0)
  const tooLate = nowTime < selectedDatetime.minute(0).second(0).millisecond(0)

  useEffect(() => {
    if (weatherData) {
      fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${weatherData.lngLat.lat}&lon=${weatherData.lngLat.lng}&units=imperial&appid=${owmToken}`
      )
        .then((res) => res.json())
        .then((response) => {
          setWeatherDataResponse(response);
        })
        .catch((error) => {
          console.error('weather open api call error: ', error);
          setWeatherDataResponse(null);
        });
    } else {
      setWeatherDataResponse(null);
    }
  }, [weatherData]);

  useEffect(()=>{

  },[selectedDatetime])

  return (
    <>
      {weatherDataResponse ? (
        <>
          {(forecastInfo==="N/A") && (
            <Info>{weatherLayer.key} is not currently supported for point data or forecasts.</Info>
          ) || (!!tooEarly && (
            <Info>{weatherLayer.key} data not availale for prior datetimes.</Info>
          )) || (!!tooLate && (
            <Info>{weatherLayer.key} forecast in chart.</Info>
          )) || (
            <>
              <p className="weight-500 mb-2">
                Current {weatherLayer.key}
                {!!forecastInfo!=="N/A" && (
                  <span>: <strong>{forecastInfo}</strong></span>
                )}
              </p>
              
            </>
          )}
          <hr className="thin my-1" />
        </>
      ) : (null)}
    </>
  );
}
