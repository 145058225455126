import React, { useEffect, useState } from 'react';
import OSINTPostTwitterCard from './OSINTPostTwitterCard';
import { StylishCarousel } from 'components/DesignSystems/New/StylishCarousel';

export default function OSINTPostTwitter(props) {
  const { postData, isLoading } = props;

  const [twitterList, setTwitterList] = useState([]);

  //useEffect
  useEffect(() => {
    setTwitterList(postData);
  }, [postData]);

  return (
    <>
      {twitterList?.length > 0 ? (
        <StylishCarousel
          className=""
          dots={false}
          arrows={true}
          infinite={false}
          slidesToShow="4"
          slidesToShowLG="2"
          slidesToShowMD="2"
          slidesToShowSM="1"
        >
          {twitterList.map((data, idx) => (
            <div className="bg-gray-800 rounded p-4" key={'twitter-' + idx}>
              <OSINTPostTwitterCard data={data} />
            </div>
          ))}
        </StylishCarousel>
      ) : null}
    </>
  );
}
