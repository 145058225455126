import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import {
  addObjectiveToIncident,
  updateObjectiveToIncident,
} from 'actions/incidentActions';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { startLoading } from 'reducers/loading/loading.action';

export default function NewObjectiveDialog({
  show,
  setEditObjectiveData,
  editObjectiveData,
  modalType,
  setModalType,
  onClose,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const [isLoaded, setIsLoaded] = useState(true);
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState({
    hasError: true,
    message: '',
    touched: false,
  });

  const [objectiveId, setObjectiveId] = useState('');
  const reduxDispatch = useDispatch();
  const isIncidentObjectivesLoaded = useSelector((state) => {
    return state.app.isIncidentObjectivesLoaded;
  });
  const isAddIncidentObjectivesLoaded = useSelector((state) => {
    return state.app.isAddIncidentObjectivesLoaded;
  });

  useEffect(() => {
    if (
      !isLoaded &&
      isIncidentObjectivesLoaded &&
      isAddIncidentObjectivesLoaded
    ) {
      onClose();
      setModalType('New');
      setEditObjectiveData(undefined);
    }
  }, [
    isIncidentObjectivesLoaded,
    isAddIncidentObjectivesLoaded,
    isLoaded,
    onClose,
  ]);

  useEffect(() => {
    errorCheck(descriptionError);
  }, [description]);

  const errorCheck = (descriptionErrorNew) => {
    if (descriptionErrorNew.touched) {
      if (!description || description === '') {
        setDescriptionError({
          ...descriptionErrorNew,
          hasError: true,
          message: '*Description is required',
        });
      } else {
        setDescriptionError({
          ...descriptionErrorNew,
          hasError: false,
          message: '',
        });
      }
    }
  };
  const onTouched = () => {
    const descriptionErrorNew = { ...descriptionError, touched: true };
    setDescriptionError(descriptionErrorNew);
    errorCheck(descriptionErrorNew);
  };

  useEffect(() => {
    if (modalType === 'New') {
      setObjectiveId(generateUUID());
    } else if (modalType === 'Edit') {
      setObjectiveId(editObjectiveData.id);
      setValue('description', editObjectiveData.description);
    }
  }, [modalType]);

  const onNewObjectiveDataSubmit = (data) => {
    if (modalType === 'New') {
      reduxDispatch(startLoading());
      reduxDispatch(
        addObjectiveToIncident({
          id: objectiveId,
          description: data.description,
        })
      );
      onClose();
    } else if (modalType === 'Edit') {
      reduxDispatch(startLoading());
      reduxDispatch(
        updateObjectiveToIncident({
          objective_id: objectiveId,
          description: data.description,
        })
      );
      onClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Objective</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewObjectiveDataSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Number</label>
            <StylishNewInput
              value={objectiveId.substring(0, 4)}
              readOnly
              disabled
            />
          </div>
          <div className="m-0">
            <label className="form-label">Description:</label>
            <Controller
              control={control}
              name="description"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.description && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
