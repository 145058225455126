export const defaultRiskLevels = [
	{
		id:0,
		name:"None",
		escalation_level: 0,
		ADLs: [],
	},
	{
		id:1,
		name:"Low",
		escalation_level: 1,
		ADLs: [],
	},
	{
		id:2,
		name:"Moderate",
		escalation_level: 2,
		ADLs: [],
	},
	{
		id:3,
		name:"High",
		escalation_level: 3,
		ADLs: [],
	},
	{
		id:4,
		name:"Critical",
		escalation_level: 4,
		ADLs: [],
	},
	{
		id:5,
		name:"Extreme",
		escalation_level: 5,
		ADLs: [],
	},
]