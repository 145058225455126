import React from 'react';
import classNames from 'classnames';

const StylishNewButton = React.forwardRef((props, ref) => {
  const { className, customButton, id, children, disabled, ...rest } = props;
  const builtClassName = classNames(
    {
      button: !customButton,
    },
    className
  );

  return (
    <button
      id={id}
      className={builtClassName}
      disabled={disabled}
      type="button"
      {...rest}
      ref={ref}
    >
      {children}
    </button>
  );
});

StylishNewButton.displayName = 'StylishNewButton';
export { StylishNewButton };
