import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { createIncident } from 'actions/diceActions';
import { IncidentTypes } from './IncidentTypes';
import { SeverityLevels } from './SeverityLevels';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { startLoading } from 'reducers/loading/loading.action';
import FormSelectField from '../IAP/formFields/FormSelectField';
import { selectGroup, useAppSelector } from '../../slices/commonSelectors';
import FormTextField from '../IAP/formFields/FormTextField';
import { useNavigate } from 'react-router-dom';

export default function NewIncidentDialog({ onClose }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const groups = useAppSelector((state) => {
    return state.app.groups;
  });

  const currentlySelectedGroup = useAppSelector(selectGroup);

  const form = useForm({
    defaultValues: {
      sponsor_organization: currentlySelectedGroup.group_guid,
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = form;

  const onNewIncidentSubmit = async (data) => {
    dispatch(startLoading());
    const incident = await dispatch(
      createIncident({
        name: data.incident_name,
        group_guid: data.sponsor_organization,
        severity: data.incident_severity,
        incident_types: data.incident_types,
      })
    );
    if (!incident) {
      reset(data, );
    } else {
      navigate(`/iap/${incident.id}/settings`);
      onClose();
    }
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>New Incident</Modal.Title>
      </Modal.Header>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onNewIncidentSubmit)}>
          <Modal.Body>
            <div className="mb-3">
              <FormTextField
                title={'Name'}
                fieldName={'incident_name'}
                rules={{
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                }}
              />
            </div>
            <div className="mb-3">
              <FormSelectField
                title={'Incident Type'}
                fieldName={'incident_types'}
                options={IncidentTypes.map((type) => {
                  return { value: type, label: type };
                })}
                rules={{
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                }}
                isMulti={true}
              />
            </div>
            <div className="mb-3">
              <FormSelectField
                title={'Severity'}
                fieldName={'incident_severity'}
                options={SeverityLevels.map((type) => {
                  return { value: type, label: type };
                })}
                rules={{
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                }}
              />
            </div>
            <div className="m-0">
              <FormSelectField
                title={'Sponsor Organization'}
                fieldName={'sponsor_organization'}
                options={groups.map((group) => {
                  return { value: group.group_guid, label: group.group_name };
                })}
                rules={{
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={onClose}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                type="submit"
                className="button--primary"
                disabled={isSubmitting}
              >
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  );
}
