import React from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import StylishNewTextArea from '../../DesignSystems/New/StylishNewTextArea';

type FormTextAreaFieldProps = {
  title: string;
  fieldName: string;
  rules?: { [key: string]: { value: any; message: string } };
  defaultValue?: string;
  variant?: 'full' | 'half';
};

const FormTextAreaField: React.FC<FormTextAreaFieldProps> = ({
  title,
  fieldName,
  rules,
  defaultValue,
  variant = 'full',
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const required = typeof rules?.required === 'object' && rules.required.value;

  return (
    <div
      className={classNames('mt-2', {
        'col-6': variant === 'half',
        'col-12': variant === 'full',
      })}
    >
      <label className="form-label">
        {title}
        {required ? (
          <span aria-label="Required field" className="required">
            *
          </span>
        ) : null}
      </label>
      <StylishNewTextArea
        defaultValue={defaultValue}
        {...register(fieldName, rules)}
      />
      {errors[fieldName] ? (
        <div className="form-text form-error">
          {errors[fieldName].message as string}
        </div>
      ) : (
        <div className="form-text-none"></div>
      )}
    </div>
  );
};
export default FormTextAreaField;
