import React from 'react';
import { Modal } from 'react-bootstrap';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';

type EditGroupModalProps = {
  onClose: () => void;
  startDelete: () => void;
  selectedGroup: string;
  show: boolean;
};
const DeleteGroupModal: React.FC<EditGroupModalProps> = ({
  onClose,
  startDelete,
  selectedGroup,
  show,
}) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          Are you sure you want to delete {selectedGroup}?
        </div>
        <div className="mb-3">
          This action cannot be undone, and will delete all group data. This
          will not delete Incidents linked to this Group.
        </div>
        <div className="form-text form-error multiline mt-4">
          After deleting this group this page will be reloaded for security and
          performance purposes.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            type="button"
            className="button--secondary"
            onClick={onClose}
          >
            No, take me back
          </StylishNewButton>
          <StylishNewButton
            type="button"
            className="button--error"
            onClick={startDelete}
          >
            Yes, I want to delete
          </StylishNewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteGroupModal;
