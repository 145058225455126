import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Outlet } from 'react-router-dom';

import './TaskManagement.css';
import { fetchRostersForGroups } from 'actions/roleActions';
import { fetchAllDistributionList } from 'actions/notificationActions';

export default function TaskManagement() {
  const reduxDispatch = useDispatch();

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (reduxCurrentlySelectedGroup?.group_guid) {
      reduxDispatch(
        fetchRostersForGroups([reduxCurrentlySelectedGroup.group_guid])
      );
      reduxDispatch(
        fetchAllDistributionList(reduxCurrentlySelectedGroup.group_guid)
      );
    }
  }, [reduxCurrentlySelectedGroup]);

  return (
    <>
      <Outlet />
    </>
  );
}
