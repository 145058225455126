import React from 'react';

import './StylishCheckbox.css'

export default function StylishCheckbox({checked, onClick, label, className, color, disabled}) {
  return (
    <label className={`stylishCheckbox-label ${className}`}>
      <input 
        type="checkbox" 
        name={label} 
        checked={checked} 
        value="1" 
        onClick={onClick}
        disabled={disabled}
        onChange={()=>{}}
      />
      <span>
        {!!color && (
          <div style={{marginRight: "3px", display: "inline-block",backgroundColor: color, width: "10px", height: "10px"}}/>
        )}
        {label}
      </span>
    </label>
  ) 
}