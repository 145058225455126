import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import { fetchIcsIncidentMedicalTransportation } from './ics_incidentMedicalTransportationSlice';

import { getUserGuid, getGroupGuid } from 'slices/sliceHelpers';

// Slice
const ics_medicalTransportationSlice = createSlice({
    name: 'ics_medical_transportation',
    initialState: {
        ics_medical_transportation: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        createIcsMedicalTransportationSuccess: (state, action) => {
            state.ics_medical_transportation.push(action.payload);
        },
        fetchIcsMedicalTransportationSuccess: (state, action) => {
            state.ics_medical_transportation = action.payload;
        },
        updateIcsMedicalTransportationSuccess: (state, action) => {
            const index = state.ics_medical_transportation.findIndex((trans) => trans.id === action.payload.id);
            state.ics_medical_transportation[index] = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    createIcsMedicalTransportationSuccess,
    fetchIcsMedicalTransportationSuccess,
    updateIcsMedicalTransportationSuccess,
    setError
} = ics_medicalTransportationSlice.actions;

export default ics_medicalTransportationSlice.reducer;

export const createIcsMedicalTransportation = (data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_medical_transportation}`, { ...data, user_guid });
        if (data.incident_id) {
            dispatch(fetchIcsIncidentMedicalTransportation(data.incident_id));
        }
        dispatch(fetchIcsMedicalTransportation(group_guid));
        dispatch(createIcsMedicalTransportationSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsMedicalTransportation = (group_guid) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_medical_transportation}/${group_guid}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsMedicalTransportationSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsMedicalTransportation = (id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_medical_transportation}/${id}`, { ...data, user_guid });
        dispatch(fetchIcsMedicalTransportation(group_guid));
        dispatch(updateIcsMedicalTransportationSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
