import React from 'react';

const UpgradeSubscriptionModal: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  return (
    <div className="modal-upgrade">
      <div className="modal-upgrade__header">
        <span onClick={onClose}>
          <i className="material-symbols-outlined">arrow_back</i>
          Back
        </span>
      </div>
      <div className="modal-upgrade__body">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-8">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-md-5 mb-lg-0">
                <h3 className="weight-400">Elevate Your Crisis Management</h3>
                <ul>
                  <li>Access to Advanced Predictive Analytics</li>
                  <li>Receive Real-time Alerts & Updates</li>
                  <li>Seamlessly Integrate with Tools like MS Teams</li>
                  <li>Streamline Your Emergency Management Workflow</li>
                  <li>Conduct and Manage Emergency Exercises</li>
                  <li>Monitor Media for Real-Time Crisis Information</li>
                  <li>Monitor Media for Real-Time Crisis Information</li>
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="block">
                  <div className="block__catchline">
                    <i className="material-symbols-outlined icon">lock_open</i>
                    Upgrade to PRATUS Professional
                  </div>
                  <h2>
                    Complete Crisis <br />
                    Management in One <br />
                    Platform
                  </h2>
                  <p>
                    Unlock the full potential of PRATUS Professional for
                    comprehensive monitoring and advanced analytics. Elevate
                    your operational readiness and response capabilities to new
                    heights.
                  </p>
                  <a
                    href="https://www.disastertech.com/contact-us"
                    target="_blank"
                    className="button button--primary w-100"
                  >
                    Talk to Sales
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeSubscriptionModal;
