const ICS201 = {
  formname: 'ICS 201',
  filename: 'ICS_201.PDF',
  form_workflow_group: 'ICS-FEMA',
  form_states: [
    {
      state_name: 'Incomplete',
      state_instruction:
        'The Planning Section Chief must fill out the Planning Section of this form.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 0 },
        sourcePosition: 'bottom',
      },
    },
    {
      state_name: 'Submitted',
      state_instruction: 'This form is complete.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 200 },
        targetPosition: 'top',
      },
    },
  ],
  form_state_transitions: [
    {
      from: 'Incomplete',
      to: 'Submitted',
      action: 'Submit ICS 201',
      transition_conditions: {
        // Things that need to be met for a transition to be allowed
        valid_sections: ['Planning Section'],
        valid_seats: ['Planning Section Chief'],
        repeatable: true, // This allows the transition to be "repeated" - in other words, allow a form to be edited by the authorized users when the record is in the "from" category.
      },
      transition_edge_workflow_settings: {
        // Note, the unique ID for each transition/edge is [from]-to-[to].  ex: "Incomplete-to-Awaiting approval"
        arrowHeadType: 'arrowclosed',
      },
    },
  ],
  sections: [
    {
      section_name: 'Planning Section',
      section_instruction:
        'This part of the form must be filled out by the Planning Section Chief',
      section_state_roles: [
        {
          state_name: 'Incomplete',
          roles: ['Planning Section Chief'],
        },
        {
          state_name: 'Submitted',
          roles: ['Planning Section Chief'],
        },
      ],
      subsections: [
        {
          title: '1. Incident Name',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '1 Incident Name_5',
            field_label: '1. Incident Name',
            inputtype: 'Input-Text',
            autofill: 'getCurrentIncidentName',
            validations: {
              format: 'text',
            },
          },
        },
      ],
    },
  ],
};

const ICS202 = {
  formname: 'ICS 202',
  filename: 'ICS_202_CG.PDF',
  form_workflow_group: 'ICS-FEMA',
  page_number_fields: [],
  form_states: [
    {
      state_name: 'Incomplete',
      state_instruction:
        'The Planning Section Chief must fill out the Planning Section of this form and submit to await approval of the Incident Commander.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 0 },
        sourcePosition: 'bottom',
      },
    },
    {
      state_name: 'Awaiting Approval',
      state_instruction:
        'The Incident Commander must fill out the Incident Command Section of this form and submit to approve.  The Planning Section Chief may continue to edit the form until then.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 100 },
        sourcePosition: 'top',
        targetPosition: 'bottom',
      },
    },
    {
      state_name: 'Approved',
      state_instruction:
        'This form has met the approval of the Incident Commander.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 200 },
        targetPosition: 'top',
      },
    },
  ],
  form_state_transitions: [
    {
      from: 'Incomplete',
      to: 'Awaiting Approval',
      action: 'Input ICS 202',
      transition_conditions: {
        // Things that need to be met for a transition to be allowed
        valid_sections: ['Planning Section'],
        valid_seats: ['Planning Section Chief'],
        repeatable: true, // This allows the transition to be "repeated" - in other words, allow a form to be edited by the authorized users when the record is in the "from" category.
      },
      transition_edge_workflow_settings: {
        // Note, the unique ID for each transition/edge is [from]-to-[to].  ex: "Incomplete-to-Awaiting approval"
        arrowHeadType: 'arrowclosed',
      },
    },
    {
      from: 'Awaiting Approval',
      to: 'Approved',
      action: 'Approve ICS 202',
      transition_conditions: {
        // Things that need to be met for a transition to be allowed
        valid_sections: ['Planning Section', 'Incident Commander Section'],
        valid_seats: ['Incident Commander'],
      },
      transition_edge_workflow_settings: {
        // Note, the unique ID for each transition/edge is [from]-to-[to].  ex: "Incomplete-to-Awaiting approval"
        arrowHeadType: 'arrowclosed',
      },
    },
  ],
  sections: [
    {
      section_name: 'Planning Section',
      section_instruction:
        'This part of the form must be filled out by the Planning Section Chief',
      section_state_roles: [
        {
          state_name: 'Incomplete',
          roles: ['Planning Section Chief'],
        },
        {
          state_name: 'Awaiting Approval',
          roles: ['Planning Section Chief'],
        },
      ],
      subsections: [
        {
          title: '1. Incident Name',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '1 Incident Name_5',
            field_label: '1. Incident Name',
            inputtype: 'Input-Text',
            autofill: 'getCurrentIncidentName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '2. Operational Period',
          type: 'Title',
          subtitle: 'Date/Time',
        },
        {
          type: 'OPDateTimeGroup',
          fields: [
            {
              field_id: 'Date From',
              field_label: 'Date From:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateFrom',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Date To',
              field_label: 'Date To:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateTo',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Time From',
              field_label: 'Time From:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeFrom',
              validations: {
                format: 'time',
              },
            },
            {
              field_id: 'Time To',
              field_label: 'Time To:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeTo',
              validations: {
                format: 'time',
              },
            },
          ],
        },
        {
          title: '3. Objective(s)',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'ListInput',
          field: {
            field_id: '3 Objectives',
            field_label: '3. Objective(s)',
            inputtype: 'Input-List',
            validations: {
              // If this field is required to be "valid" for a state transition, check the validations.  All must be true for the field to be valid.  If this field is not required for a state transition, no check is performed.
              input_required: true, // Must be at least one item with length of > 1.  This will largely be managed by the rendering/handling code.
              format: 'array',
            },
          },
        },
        {
          title: '4. Operational Period Command Emphasis:',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'ListInput',
          field: {
            field_id: '4 Operational Period Command Emphasis',
            field_label: '4 Operational Period Command Emphasis',
            inputtype: 'Input-List',
            validations: {
              format: 'array',
              input_required: true,
            },
          },
        },
        {
          type: 'ListInput',
          field: {
            field_id: 'General Situational Awareness',
            field_label: 'General Situational Awareness',
            inputtype: 'Input-List',
            validations: {
              format: 'array',
              input_required: true,
            },
          },
        },
        {
          title: '5. Site Safety Plan Required?',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'CheckYesNoAndTextInput',
          check_yes_field: {
            field_id: 'Yes',
            field_label: 'Yes',
            inputtype: 'Input-Checkbox',
            validations: {
              format: 'boolean',
            },
          },
          check_no_field: {
            field_id: 'No',
            field_label: 'No',
            inputtype: 'Input-Checkbox',
            validations: {
              format: 'boolean',
            },
          },
          text_field: {
            field_id:
              '5 Site Safety Plan Required Yes No Approved Site Safety Plans Located at',
            field_label: 'Approved Site Safety Plan(s) Located at:',
            inputtype: 'Input-Text',
            validations: {
              field_depends_on: 'check_yes_field',
              format: 'text',
            },
          },
        },
        {
          title: '6. Incident Action Plan',
          type: 'Title',
          subtitle:
            '(the items checked below are included in this Incident Action Plan)',
        },
        {
          type: 'CheckboxInput',
          field: {
            field_id: 'ICS 203',
            field_label: 'ICS 203',
            inputtype: 'Input-Checkbox',
            validations: {
              format: 'boolean',
            },
          },
        },
        {
          type: 'CheckboxInput',
          field: {
            field_id: 'ICS 204',
            field_label: 'ICS 204',
            inputtype: 'Input-Checkbox',
            validations: {
              format: 'boolean',
            },
          },
        },
        {
          type: 'CheckboxInput',
          field: {
            field_id: 'ICS 205',
            field_label: 'ICS 205',
            inputtype: 'Input-Checkbox',
            validations: {
              format: 'boolean',
            },
          },
        },
        {
          type: 'CheckboxInput',
          field: {
            field_id: 'ICS 205A',
            field_label: 'ICS 205A',
            inputtype: 'Input-Checkbox',
            validations: {
              format: 'boolean',
            },
          },
        },
        {
          type: 'CheckboxInput',
          field: {
            field_id: 'ICS 206',
            field_label: 'ICS 206',
            inputtype: 'Input-Checkbox',
            validations: {
              format: 'boolean',
            },
          },
        },
        {
          type: 'CheckboxInput',
          field: {
            field_id: 'ICS 207',
            field_label: 'ICS 207',
            inputtype: 'Input-Checkbox',
            validations: {
              format: 'boolean',
            },
          },
        },
        {
          type: 'CheckboxInput',
          field: {
            field_id: 'ICS 208',
            field_label: 'ICS 208',
            inputtype: 'Input-Checkbox',
            validations: {
              format: 'boolean',
            },
          },
        },
        {
          type: 'CheckboxInput',
          field: {
            field_id: 'Map Chart',
            field_label: 'Map/Chart',
            inputtype: 'Input-Checkbox',
            validations: {
              format: 'boolean',
            },
          },
        },
        {
          type: 'CheckboxInput',
          field: {
            field_id: 'Weather Forecast Tides Currents',
            field_label: 'Weather Forecast/Tides/Currents',
            inputtype: 'Input-Checkbox',
            validations: {
              format: 'boolean',
            },
          },
        },
        {
          type: 'CheckAndTextInput',
          check_field: {
            field_id: 'Check 1',
            field_label: 'Other Attachments 1 Checkbox',
            validations: {
              format: 'boolean',
            },
          },
          text_field: {
            field_id: 'Other Attachments 1',
            field_label: 'Other Attachments 1 Input',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'CheckAndTextInput',
          check_field: {
            field_id: 'Check 2',
            field_label: 'Other Attachments 2 Checkbox',
            validations: {
              format: 'boolean',
            },
          },
          text_field: {
            field_id: 'Other Attachments 2',
            field_label: 'Other Attachments 2 Input',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'CheckAndTextInput',
          check_field: {
            field_id: 'Check 3',
            field_label: 'Other Attachments 3 Checkbox',
            validations: {
              format: 'boolean',
            },
          },
          text_field: {
            field_id: 'Other Attachments 3 Input',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'CheckAndTextInput',
          check_field: {
            field_id: 'Check 4',
            field_label: 'Other Attachments 4 Checkbox',
            validations: {
              format: 'boolean',
            },
          },
          text_field: {
            field_id: 'Other Attachments 4',
            field_label: 'Other Attachments 4 Input',
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '7. Prepared By',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '7 Prepared by Name',
            field_label: 'Name:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'PositionTitle_5',
            field_label: 'Position/Title:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserIncidentSeat',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'SignatureInput',
          field: {
            field_id: 'Signature_5',
            field_label: 'Signature:',
            inputtype: 'Input-Signature',
            validations: {
              input_required: true,
            },
          },
        },
      ],
    },
    {
      section_name: 'Incident Commander Section',
      section_instruction:
        'This part of the form must be filled out by the Incident Commander',
      section_state_roles: [
        {
          state_name: 'Awaiting Approval',
          roles: ['Incident Commander'],
        },
      ],
      subsections: [
        {
          title: '8. Approved by Incident Commander:',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '8 Approved by Incident Commander Name',
            field_label: 'Name:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'SignatureInput',
          field: {
            field_id: 'Signature_6',
            field_label: 'Signature:',
            inputtype: 'Input-Signature',
            validations: {
              format: 'object',
            },
          },
        },
        {
          type: 'DatetimeInput',
          field: {
            field_id: 'DateTime_5',
            field_label: 'Date/Time:',
            inputtype: 'Input-Datetime',
            autofill: 'getCurrentDateTime',
            validations: {
              format: 'datetime',
            },
          },
        },
      ],
    },
  ],
};

const ICS203 = {
  formname: 'ICS 203',
  filename: 'ICS_203.PDF',
  form_workflow_group: 'ICS-FEMA',
  page_number_fields: ['IAP Page_2'],
  form_states: [
    {
      state_name: 'Incomplete',
      state_instruction:
        'The Planning Section Chief must fill out the Planning Section of this form.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 0 },
        sourcePosition: 'bottom',
      },
    },
    {
      state_name: 'Submitted',
      state_instruction: 'This form is complete.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 200 },
        targetPosition: 'top',
      },
    },
  ],
  form_state_transitions: [
    {
      from: 'Incomplete',
      to: 'Submitted',
      action: 'Submit ICS 203',
      transition_conditions: {
        // Things that need to be met for a transition to be allowed
        valid_sections: ['Planning Section'],
        valid_seats: ['Planning Section Chief'],
        repeatable: true, // This allows the transition to be "repeated" - in other words, allow a form to be edited by the authorized users when the record is in the "from" category.
      },
      transition_edge_workflow_settings: {
        // Note, the unique ID for each transition/edge is [from]-to-[to].  ex: "Incomplete-to-Awaiting approval"
        arrowHeadType: 'arrowclosed',
      },
    },
  ],
  sections: [
    {
      section_name: 'Planning Section',
      section_instruction:
        'This part of the form must be filled out by the Planning Section Chief',
      section_state_roles: [
        {
          state_name: 'Incomplete',
          roles: ['Planning Section Chief'],
        },
        {
          state_name: 'Submitted',
          roles: ['Planning Section Chief'],
        },
      ],
      subsections: [
        {
          title: '1. Incident Name',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '1 Incident Name',
            field_label: '1. Incident Name',
            inputtype: 'Input-Text',
            autofill: 'getCurrentIncidentName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '2. Operational Period',
          type: 'Title',
          subtitle: 'Date/Time',
        },
        {
          type: 'OPDateTimeGroup',
          fields: [
            {
              field_id: 'Date From',
              field_label: 'Date From:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateFrom',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Date To',
              field_label: 'Date To:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateTo',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Time From',
              field_label: 'Time From:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeFrom',
              validations: {
                format: 'time',
              },
            },
            {
              field_id: 'Time To',
              field_label: 'Time To:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeTo',
              validations: {
                format: 'time',
              },
            },
          ],
        },
        {
          title: '3. Incident Commander(s) and Command Staff:',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'ICUCs',
            field_label: 'IC/UCs',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Incident Commander',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Deputy_2',
            field_label: 'Incident Deputy',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Incident Deputy',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Safety Officer_3',
            field_label: 'Safety Officer',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Safety Officer',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Public Info Officer',
            field_label: 'Public Info Officer',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Public Info Officer',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Liaison Officer_2',
            field_label: 'Liaison Officer',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Liaison Officer',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '4. Agency/Organization Representatives',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'Table',
          field_id: '4. Agency/Organization Representatives',
          Tableconfig: {
            main_table_rows: 6,
            uses_continuation_page: 'false', // Because there is a continuation page provided
            continuation_page_number: 0, // Where the continuation page can be found in the PDF template
            continuation_table_rows: 0,
            row_title: 'Agency/Organization Representative',
            columns: [
              {
                colname: 'Agency/ Organization',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'AgencyOrganizationRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Name',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'NameRow#',
                validations: {
                  input_required: true,
                },
              },
            ],
          },
        },
        {
          title: '5. Planning Section',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Planning Section Chief',
            field_label: 'Planning Section Chief',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Planning Section Chief',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Planning Section Deputy',
            field_label: 'Planning Section Deputy',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Planning Section Deputy',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Planning Section Resources Unit',
            field_label: 'Planning Section Resources Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Resources Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Planning Section Situation Unit',
            field_label: 'Planning Section Situation Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Situation Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Planning Section Documentation Unit',
            field_label: 'Planning Section Documentation Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Documentation Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Planning Section Demobilization Unit',
            field_label: 'Planning Section Demobilization Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Demobilization Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Planning Section Technical Specialists',
            field_label: 'Planning Section Technical Specialists',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Planning Section Technical Specialist',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '6. Logistics Section',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Logistics Section Chief',
            field_label: 'Logistics Section Chief',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Logistics Section Chief',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Logistics Section Deputy',
            field_label: 'Logistics Section Deputy',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Logistics Section Deputy',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Support Branch Director',
            field_label: 'Support Branch Director',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Support Branch Director',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Support Branch Supply Unit',
            field_label: 'Support Branch Supply Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Supply Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Support Branch Facilities Unit',
            field_label: 'Support Branch Facilities Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Facilities Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Support Branch Ground Support Unit',
            field_label: 'Support Branch Ground Support Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Ground Support Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Service Branch Director',
            field_label: 'Service Branch Director',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Service Branch Director',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Service Branch Communications Unit',
            field_label: 'Service Branch Communications Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Communications Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Service Branch Medical Unit',
            field_label: 'Service Branch Medical Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Medical Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Service Branch Food Unit',
            field_label: 'Service Branch Food Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Food Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '7. Operations Section',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Operations Section Chief 1',
            field_label: 'Operations Section Chief',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Operations Section Chief',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Operations Section Deputy 1',
            field_label: 'Operations Section Deputy',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Operations Section Deputy',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Operations Section Staging Area 1',
            field_label: 'Staging Area Manager',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Staging Area Manager',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Branch Director 1',
            field_label: 'Branch 1 - Director 1',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Branch Director 2',
            field_label: 'Branch 1 - Director 2',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Branch Director Deputy 1',
            field_label: 'Branch 1 - Deputy 1',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Branch Director Deputy 2',
            field_label: 'Branch 1 - Deputy 2',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Identifier 1',
            field_label: 'Branch 1 - Division/Group Identifier 1',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Name 1',
            field_label: 'Branch 1 - Division/Group Name 1',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Identifier 2',
            field_label: 'Branch 1 - Division/Group Identifier 2',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Name 2',
            field_label: 'Branch 1 - Division/Group Name 2',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Identifier 3',
            field_label: 'Branch 1 - Division/Group Identifier 3',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Name 3',
            field_label: 'Branch 1 - Division/Group Name 3',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Identifier 4',
            field_label: 'Branch 1 - Division/Group Identifier 4',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Name 4',
            field_label: 'Branch 1 - Division/Group Name 4',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Identifier 5',
            field_label: 'Branch 1 - Division/Group Identifier 5',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Name 5',
            field_label: 'Branch 1 - Division/Group Name 5',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Branch Director 3',
            field_label: 'Branch 2 - Director 1',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Branch Director 4',
            field_label: 'Branch 2 - Director 2',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Branch Director Deputy 3',
            field_label: 'Branch 2 - Deputy 1',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Branch Director Deputy 4',
            field_label: 'Branch 2 - Deputy 2',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Identifier 6',
            field_label: 'Branch 2 - Division/Group Identifier 1',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Name 6',
            field_label: 'Branch 2 - Division/Group Name 1',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Identifier 7',
            field_label: 'Branch 2 - Division/Group Identifier 2',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Name 7',
            field_label: 'Branch 2 - Division/Group Name 2',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Identifier 8',
            field_label: 'Branch 2 - Division/Group Identifier 3',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Name 8',
            field_label: 'Branch 2 - Division/Group Name 3',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Identifier 9',
            field_label: 'Branch 2 - Division/Group Identifier 4',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Name 9',
            field_label: 'Branch 2 - Division/Group Name 4',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Identifier 10',
            field_label: 'Branch 2 - Division/Group Identifier 5',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Name 10',
            field_label: 'Branch 2 - Division/Group Name 5',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Branch Director 5',
            field_label: 'Branch 3 - Director 1',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Branch Director 6',
            field_label: 'Branch 3 - Director 2',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Branch Director Deputy 5',
            field_label: 'Branch 3 - Deputy 1',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Branch Director Deputy 6',
            field_label: 'Branch 3 - Deputy 2',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Identifier 11',
            field_label: 'Branch 3 - Division/Group Identifier 1',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Name 11',
            field_label: 'Branch 3 - Division/Group Name 1',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Identifier 12',
            field_label: 'Branch 3 - Division/Group Identifier 2',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Name 12',
            field_label: 'Branch 3 - Division/Group Name 2',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Identifier 13',
            field_label: 'Branch 3 - Division/Group Identifier 3',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Name 13',
            field_label: 'Branch 3 - Division/Group Name 3',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Identifier 14',
            field_label: 'Branch 3 - Division/Group Identifier 4',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Name 14',
            field_label: 'Branch 3 - Division/Group Name 4',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Identifier 15',
            field_label: 'Branch 3 - Division/Group Identifier 5',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Name 15',
            field_label: 'Branch 3 - Division/Group Name 5',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },

        {
          type: 'TextInput',
          field: {
            field_id: 'Air Ops Branch Director 1',
            field_label: 'Air Ops Branch Director 1',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Air Ops Branch Director 2',
            field_label: 'Air Ops Branch Director 2',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Air Ops Branch Director 3',
            field_label: 'Air Ops Branch Director 3',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Air Ops Branch Director 4',
            field_label: 'Air Ops Branch Director 4',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Air Ops Branch Director 5',
            field_label: 'Air Ops Branch Director 5',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '8. Finance/Administration Section',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Finance/Adminsitration Section Chief',
            field_label: 'Finance/Administration Section Chief',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Finance/Admin Section Chief',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Finance/Adminsitration Section Deputy',
            field_label: 'Finance/Administration Section Deputy',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Finance/Admin Section Deputy',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Finance/Adminsitration Section Time Unit',
            field_label: 'Time Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Time Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Finance/Adminsitration Section Procurement Unit',
            field_label: 'Procurement Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Procurement Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Finance/Adminsitration Section CompClaims Unit',
            field_label: 'Comp/Claims Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Comp/Claims Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Finance/Adminsitration Section Cost Unit',
            field_label: 'Cost Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Cost Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '9. Prepared By',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '9 Prepared by Name',
            field_label: 'Name:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'PositionTitle_6',
            field_label: 'Position/Title:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserIncidentSeat',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'SignatureInput',
          field: {
            field_id: 'Signature_7',
            field_label: 'Signature:',
            inputtype: 'Input-Signature',
            validations: {
              input_required: true,
            },
          },
        },
        {
          type: 'DatetimeInput',
          field: {
            field_id: 'DateTime_6',
            field_label: 'Date/Time:',
            inputtype: 'Input-Datetime',
            autofill: 'getCurrentDateTime',
            validations: {
              format: 'datetime',
            },
          },
        },
      ],
    },
  ],
};

const ICS204 = {
  formname: 'ICS 204',
  filename: 'ICS204-CG.pdf',
  form_workflow_group: 'ICS-FEMA',
  page_number_fields: [],
  form_states: [
    {
      state_name: 'Incomplete',
      state_instruction:
        'The Planning Section Chief must fill out the Planning Section of this form.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 0 },
        sourcePosition: 'bottom',
      },
    },
    {
      state_name: 'Submitted',
      state_instruction: 'This form is complete.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 200 },
        targetPosition: 'top',
      },
    },
  ],
  form_state_transitions: [
    {
      from: 'Incomplete',
      to: 'Submitted',
      action: 'Submit ICS 204',
      transition_conditions: {
        // Things that need to be met for a transition to be allowed
        valid_sections: ['Planning Section'],
        valid_seats: ['Planning Section Chief'],
        repeatable: true, // This allows the transition to be "repeated" - in other words, allow a form to be edited by the authorized users when the record is in the "from" category.
      },
      transition_edge_workflow_settings: {
        // Note, the unique ID for each transition/edge is [from]-to-[to].  ex: "Incomplete-to-Awaiting approval"
        arrowHeadType: 'arrowclosed',
      },
    },
  ],
  sections: [
    {
      section_name: 'Planning Section',
      section_instruction:
        'This part of the form must be filled out by the Planning Section Chief',
      section_state_roles: [
        {
          state_name: 'Incomplete',
          roles: ['Planning Section Chief'],
        },
        {
          state_name: 'Submitted',
          roles: ['Planning Section Chief'],
        },
      ],
      subsections: [
        {
          title: '1. Incident Name',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '1 Incident Name_7',
            field_label: '1. Incident Name',
            inputtype: 'Input-Text',
            autofill: 'getCurrentIncidentName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '2. Operational Period',
          type: 'Title',
          subtitle: 'Date/Time',
        },
        {
          type: 'OPDateTimeGroup',
          fields: [
            {
              field_id: 'Date From',
              field_label: 'Date From:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateFrom',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Date To',
              field_label: 'Date To:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateTo',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Time From',
              field_label: 'Time From:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeFrom',
              validations: {
                format: 'time',
              },
            },
            {
              field_id: 'Time To',
              field_label: 'Time To:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeTo',
              validations: {
                format: 'time',
              },
            },
          ],
        },
        {
          title: '3.',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '3 Branch',
            field_label: 'Branch',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: '3 Division',
            field_label: 'Division',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: '3 Group',
            field_label: 'Group',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: '3 Staging Area',
            field_label: 'Staging Area',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '4. Operations Personnel (name and contact numbers)',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Operations Section Chief_3',
            field_label: 'Operations Section Chief ',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Branch Director_4',
            field_label: 'Branch Director',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'DivisionGroup Supervisor',
            field_label: 'Division/Group Supervisor',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '5. Resources Assigned:',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'Table',
          field_id: '5. Resources Assigned:',
          Tableconfig: {
            main_table_rows: 10,
            uses_continuation_page: 'false', // Because there is a continuation page provided
            continuation_page_number: 0, // Where the continuation page can be found in the PDF template
            continuation_table_rows: 0,
            row_title: 'Resource',
            columns: [
              {
                colname: 'Resource Identifier',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Resource IdentifierRow#_2',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Leader',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'LeaderRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: '# of Persons',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Number of Persons, Row #',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Contact (e.g., phone, pager, radio frequency, etc.)',
                coltype: 'TableSubsectionTextInput',
                table_field_id:
                  'Contact eg phone pager radio frequency etcRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname:
                  'Reporting Location, Special Equipment and Supplies, Remarks, Notes, Information',
                coltype: 'TableSubsectionTextInput',
                table_field_id:
                  'Reporting Location Special Equipment and Supplies Remarks Notes InformationRow#',
                validations: {
                  input_required: true,
                },
              },
            ],
          },
        },
        {
          title: '6. Work Assignments',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'ListInput',
          field: {
            field_id: '6 Work Assignments',
            inputtype: 'Input-List',
            validations: {
              input_required: true,
              format: 'array',
            },
          },
        },
        {
          title: '7. Special Instructions',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'ListInput',
          field: {
            field_id: '7 Special Instructions',
            inputtype: 'Input-List',
            validations: {
              input_required: true,
              format: 'array',
            },
          },
        },
        {
          title:
            '8 Communications (radio and/or phone contact numbers needed for this assignment):',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'Table',
          field_id:
            '8 Communications (radio and/or phone contact numbers needed for this assignment):',
          Tableconfig: {
            main_table_rows: 4,
            uses_continuation_page: 'false', // Because there is a continuation page provided
            continuation_page_number: 0, // Where the continuation page can be found in the PDF template
            continuation_table_rows: 0,
            row_title: 'Contact',
            columns: [
              {
                colname: 'Name/Function',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Name/Function#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname:
                  'Primary Contact: indicate cell, pager, or radio (frequency/system/channel)',
                coltype: 'TableSubsectionTextInput',
                table_field_id:
                  'Primary Contact  indicate cell pager or radio frequencysystemchannel #',
                validations: {
                  input_required: true,
                },
              },
            ],
          },
        },
        {
          title: '9. Prepared By',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '9 Prepared by Name_2',
            field_label: 'Name:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'PositionTitle_7',
            field_label: 'Position/Title:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserIncidentSeat',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'SignatureInput',
          field: {
            field_id: 'Signature_8',
            field_label: 'Signature:',
            inputtype: 'Input-Signature',
            validations: {
              input_required: true,
            },
          },
        },
        {
          type: 'DatetimeInput',
          field: {
            field_id: 'DateTime_7',
            field_label: 'Date/Time:',
            inputtype: 'Input-Datetime',
            autofill: 'getCurrentDateTime',
            validations: {
              format: 'datetime',
            },
          },
        },
      ],
    },
  ],
};

const ICS205 = {
  formname: 'ICS 205',
  filename: 'ICS_205_CG.PDF',
  form_workflow_group: 'ICS-FEMA',
  page_number_fields: [],
  form_states: [
    {
      state_name: 'Incomplete',
      state_instruction:
        'The Communications Unit Leader must fill out the Communications Section of this form.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 0 },
        sourcePosition: 'bottom',
      },
    },
    {
      state_name: 'Submitted',
      state_instruction: 'This form is complete.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 200 },
        targetPosition: 'top',
      },
    },
  ],
  form_state_transitions: [
    {
      from: 'Incomplete',
      to: 'Submitted',
      action: 'Submit ICS 205',
      transition_conditions: {
        // Things that need to be met for a transition to be allowed
        valid_sections: ['Communications Section'],
        valid_seats: ['Communications Unit Leader'],
        repeatable: true, // This allows the transition to be "repeated" - in other words, allow a form to be edited by the authorized users when the record is in the "from" category.
      },
      transition_edge_workflow_settings: {
        // Note, the unique ID for each transition/edge is [from]-to-[to].  ex: "Incomplete-to-Awaiting approval"
        arrowHeadType: 'arrowclosed',
      },
    },
  ],
  sections: [
    {
      section_name: 'Communications Section',
      section_instruction:
        'This part of the form must be filled out by the Communications Unit Leader',
      section_state_roles: [
        {
          state_name: 'Incomplete',
          roles: ['Communications Unit Leader'],
        },
        {
          state_name: 'Submitted',
          roles: ['Communications Unit Leader'],
        },
      ],
      subsections: [
        {
          title: '1. Incident Name',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '1 Incident Name_8',
            field_label: '1. Incident Name',
            inputtype: 'Input-Text',
            autofill: 'getCurrentIncidentName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '2. Date/Time Prepared',
          type: 'Title',
        },
        {
          type: 'DatetimeInput',
          field: {
            field_id: '2 Date/Time Prepared',
            field_label: 'Date/Time Prepared:',
            inputtype: 'Input-Datetime',
            autofill: 'getCurrentDateTime',
            validations: {
              format: 'datetime',
            },
          },
        },
        {
          title: '3. Operational Period',
          type: 'Title',
        },
        {
          type: 'OPDateTimeGroup',
          fields: [
            {
              field_id: 'Date From',
              field_label: 'Date From:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateFrom',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Date To',
              field_label: 'Date To:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateTo',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Time From',
              field_label: 'Time From:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeFrom',
              validations: {
                format: 'time',
              },
            },
            {
              field_id: 'Time To',
              field_label: 'Time To:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeTo',
              validations: {
                format: 'time',
              },
            },
          ],
        },
        {
          title: '4. Basic Radio Channel Use:',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'Table',
          field_id: '4. Basic Radio Channel Use:',
          Tableconfig: {
            main_table_rows: 8,
            uses_continuation_page: 'false', // Because there is a continuation page provided
            continuation_page_number: 0, // Where the continuation page can be found in the PDF template
            continuation_table_rows: 0,
            row_title: 'Radio Channel',
            columns: [
              {
                colname: 'Zone Grp.',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Zone GrpRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Ch. #',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'CH Row#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Function',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'FunctionRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Channel Name/Trunked Radio System Talkgroup',
                coltype: 'TableSubsectionTextInput',
                table_field_id:
                  'Channel NameTrunked Radio System TalkgroupRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Assignment',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'AssignmentRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'RX Freq N or W',
                coltype: 'TableSubsectionDropdown',
                dropdown_field: {
                  field_id: 'RX Freq N or WRow#',
                  dropdown_options: ['N', 'W'],
                },
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'RX Tone/NAC',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'RX ToneNACRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'TX Freq N or W',
                coltype: 'TableSubsectionDropdown',
                dropdown_field: {
                  field_id: 'TX Freq N or WRow#',
                  dropdown_options: ['N', 'W'],
                },
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'TX Tone/NAC',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'TX ToneNACRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Mode (Ad, D, or M)',
                coltype: 'TableSubsectionDropdown',
                dropdown_field: {
                  field_id: 'Mode A D or MRow#',
                  dropdown_options: ['A', 'D', 'M'],
                },
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Remarks',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'RemarksRow#',
                validations: {
                  input_required: true,
                },
              },
            ],
          },
        },
        {
          title: '5. Special Instructions:',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'ListInput',
          field: {
            field_id: '5 Special Instructions',
            field_label: '5. Special Instructions:',
            inputtype: 'Input-List',
            validations: {
              // If this field is required to be "valid" for a state transition, check the validations.  All must be true for the field to be valid.  If this field is not required for a state transition, no check is performed.
              input_required: true, // Must be at least one item with length of > 1.  This will largely be managed by the rendering/handling code.
              format: 'array',
            },
          },
        },
        {
          title: '6. Prepared By',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '6 Prepared by Communications Unit Leader Name',
            field_label: 'Name:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'PositionTitle_10',
            field_label: 'Position/Title:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserIncidentSeat',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'SignatureInput',
          field: {
            field_id: 'Signature_9',
            field_label: 'Signature:',
            inputtype: 'Input-Signature',
            validations: {
              input_required: true,
            },
          },
        },
        {
          type: 'DatetimeInput',
          field: {
            field_id: 'DateTime_8',
            field_label: 'Date/Time:',
            inputtype: 'Input-Datetime',
            autofill: 'getCurrentDateTime',
            validations: {
              format: 'datetime',
            },
          },
        },
      ],
    },
  ],
};

const ICS205A = {
  formname: 'ICS 205A',
  filename: 'ICS_205A.PDF',
  form_workflow_group: 'ICS-FEMA',
  page_number_fields: ['IAP Page_5'],
  form_states: [
    {
      state_name: 'Incomplete',
      state_instruction:
        'The Communications Unit Leader must fill out the Communications Section of this form.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 0 },
        sourcePosition: 'bottom',
      },
    },
    {
      state_name: 'Submitted',
      state_instruction: 'This form is complete.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 200 },
        targetPosition: 'top',
      },
    },
  ],
  form_state_transitions: [
    {
      from: 'Incomplete',
      to: 'Submitted',
      action: 'Submit ICS 205A',
      transition_conditions: {
        // Things that need to be met for a transition to be allowed
        valid_sections: ['Communications Section'],
        valid_seats: ['Communications Unit Leader'],
        repeatable: true, // This allows the transition to be "repeated" - in other words, allow a form to be edited by the authorized users when the record is in the "from" category.
      },
      transition_edge_workflow_settings: {
        // Note, the unique ID for each transition/edge is [from]-to-[to].  ex: "Incomplete-to-Awaiting approval"
        arrowHeadType: 'arrowclosed',
      },
    },
  ],
  sections: [
    {
      section_name: 'Communications Section',
      section_instruction:
        'This part of the form must be filled out by the Communications Unit Leader',
      section_state_roles: [
        {
          state_name: 'Incomplete',
          roles: ['Communications Unit Leader'],
        },
        {
          state_name: 'Submitted',
          roles: ['Communications Unit Leader'],
        },
      ],
      subsections: [
        {
          title: '1. Incident Name',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '1 Incident Name_9',
            field_label: '1. Incident Name',
            inputtype: 'Input-Text',
            autofill: 'getCurrentIncidentName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '2. Operational Period',
          type: 'Title',
          subtitle: 'Date/Time',
        },
        {
          type: 'OPDateTimeGroup',
          fields: [
            {
              field_id: 'Date From',
              field_label: 'Date From:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateFrom',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Date To',
              field_label: 'Date To:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateTo',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Time From',
              field_label: 'Time From:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeFrom',
              validations: {
                format: 'time',
              },
            },
            {
              field_id: 'Time To',
              field_label: 'Time To:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeTo',
              validations: {
                format: 'time',
              },
            },
          ],
        },
        {
          title: '3. Basic Local Communications Information:',
          type: 'Title',
          subtitle: 'Date/Time',
        },
        {
          type: 'Table',
          field_id: '3. Basic Local Communications Information:',
          Tableconfig: {
            main_table_rows: 34,
            uses_continuation_page: 'false', // Because there is a continuation page provided
            continuation_page_number: 0, // Where the continuation page can be found in the PDF template
            continuation_table_rows: 0,
            row_title: 'Radio Channel',
            columns: [
              {
                colname: 'Incident Assigned Position',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Incident Assigned PositionRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Name (Alphabetized)',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Name AlphabetizedRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Method(s) of Contact (phone, pager, cell, etc.)',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Methods of Contact phone pager cell etcRow#',
                validations: {
                  input_required: true,
                },
              },
            ],
          },
        },
        {
          title: '4. Prepared By',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '4 Prepared by Name',
            field_label: 'Name:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'PositionTitle_8',
            field_label: 'Position/Title:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserIncidentSeat',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'SignatureInput',
          field: {
            field_id: 'Signature_10',
            field_label: 'Signature:',
            inputtype: 'Input-Signature',
            validations: {
              input_required: true,
            },
          },
        },
        {
          type: 'DatetimeInput',
          field: {
            field_id: 'DateTime_9',
            field_label: 'Date/Time:',
            inputtype: 'Input-Datetime',
            autofill: 'getCurrentDateTime',
            validations: {
              format: 'datetime',
            },
          },
        },
      ],
    },
  ],
};

const ICS206 = {
  formname: 'ICS 206',
  filename: 'ICS_206.PDF',
  form_workflow_group: 'ICS-FEMA',
  page_number_fields: ['IAP Page_6'],
  form_states: [
    {
      state_name: 'Incomplete',
      state_instruction:
        'The Medical Unit Leader must fill out the Medical Plan Section of this form and submit to await approval of the Safety Officer.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 0 },
        sourcePosition: 'bottom',
      },
    },
    {
      state_name: 'Awaiting Approval',
      state_instruction:
        'The Safety Officer must fill out the Safety Officer Section of this form and submit to approve.  The Medical Unit Leader may continue to edit the form until then.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 100 },
        sourcePosition: 'top',
        targetPosition: 'bottom',
      },
    },
    {
      state_name: 'Approved',
      state_instruction:
        'This form has met the approval of the Safety Officer.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 200 },
        targetPosition: 'top',
      },
    },
  ],
  form_state_transitions: [
    {
      from: 'Incomplete',
      to: 'Awaiting Approval',
      action: 'Input ICS 206',
      transition_conditions: {
        // Things that need to be met for a transition to be allowed
        valid_sections: ['Medical Plan Section'],
        valid_seats: ['Medical Unit Leader'],
        repeatable: true, // This allows the transition to be "repeated" - in other words, allow a form to be edited by the authorized users when the record is in the "from" category.
      },
      transition_edge_workflow_settings: {
        // Note, the unique ID for each transition/edge is [from]-to-[to].  ex: "Incomplete-to-Awaiting approval"
        arrowHeadType: 'arrowclosed',
      },
    },
    {
      from: 'Awaiting Approval',
      to: 'Approved',
      action: 'Approve ICS 206',
      transition_conditions: {
        // Things that need to be met for a transition to be allowed
        valid_sections: ['Medical Plan Section', 'Safety Officer Section'],
        valid_seats: ['Safety Officer'],
      },
      transition_edge_workflow_settings: {
        // Note, the unique ID for each transition/edge is [from]-to-[to].  ex: "Incomplete-to-Awaiting approval"
        arrowHeadType: 'arrowclosed',
      },
    },
  ],
  sections: [
    {
      section_name: 'Medical Plan Section',
      section_instruction:
        'This part of the form must be filled out by the Medical Unit Leader',
      section_state_roles: [
        {
          state_name: 'Incomplete',
          roles: ['Medical Unit Leader'],
        },
        {
          state_name: 'Awaiting Approval',
          roles: ['Medical Unit Leader'],
        },
      ],
      subsections: [
        {
          title: '1. Incident Name',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '1 Incident Name_10',
            field_label: '1. Incident Name',
            inputtype: 'Input-Text',
            autofill: 'getCurrentIncidentName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '2. Operational Period',
          type: 'Title',
        },
        {
          type: 'OPDateTimeGroup',
          fields: [
            {
              field_id: 'Date From',
              field_label: 'Date From:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateFrom',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Date To',
              field_label: 'Date To:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateTo',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Time From',
              field_label: 'Time From:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeFrom',
              validations: {
                format: 'time',
              },
            },
            {
              field_id: 'Time To',
              field_label: 'Time To:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeTo',
              validations: {
                format: 'time',
              },
            },
          ],
        },
        {
          title: '3. Medical Aid Stations:',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'Table',
          field_id: '3. Medical Aid Stations',
          Tableconfig: {
            main_table_rows: 6,
            uses_continuation_page: 'false', // Because there is a continuation page provided
            continuation_page_number: 0, // Where the continuation page can be found in the PDF template
            continuation_table_rows: 0,
            row_title: 'Medical Aid Station',
            columns: [
              {
                colname: 'Name',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'NameRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Location',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'LocationRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Contact Number(s)/Frequency',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Contact NumbersFrequencyRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Paramedics on Site?',
                coltype: 'TableSubsectionCheckOptions',
                can_select_multiple: false,
                fields: [
                  {
                    field_label: 'Yes',
                    table_field_id: 'Paramedics on Site - Yes, Row #',
                  },
                  {
                    field_label: 'No',
                    table_field_id: 'Paramedics on Site - No, Row #',
                  },
                ],
                validations: {
                  input_required: true,
                },
              },
            ],
          },
        },
        {
          title: '4. Transportation (indicate air or ground):',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'Table',
          field_id: '4. Transportation (indicate air or ground):',
          Tableconfig: {
            main_table_rows: 4,
            uses_continuation_page: 'false', // Because there is a continuation page provided
            continuation_page_number: 0, // Where the continuation page can be found in the PDF template
            continuation_table_rows: 0,
            row_title: 'Transportation',
            columns: [
              {
                colname: 'Ambulance Service',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Ambulance ServiceRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Location',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'LocationRow#-2',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Contact Number(s)/Frequency',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Contact NumbersFrequencyRow#_2',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Level of Service',
                coltype: 'TableSubsectionCheckOptions',
                can_select_multiple: false,
                fields: [
                  {
                    field_label: 'ALS',
                    table_field_id: 'Check Box# Level of Service - ALS',
                  },
                  {
                    field_label: 'BLS',
                    table_field_id: 'Check Box# Level of Service - BLS',
                  },
                ],
                validations: {
                  input_required: true,
                },
              },
            ],
          },
        },
        {
          title: '5. Hospitals',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'Table',
          field_id: '5 Hospitals',
          Tableconfig: {
            main_table_rows: 5,
            uses_continuation_page: 'false', // Because there is a continuation page provided
            continuation_page_number: 0, // Where the continuation page can be found in the PDF template
            continuation_table_rows: 0,
            row_title: 'Hospital',
            columns: [
              {
                colname: 'Hospital Name',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Hospital NameRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Address,Latitude & Longitude if Helipad',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Address Latitude  Longitude if HelipadRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Contact Number(s)/Frequency',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Contact Numbers FrequencyRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Travel Time (Air)',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'AirRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Travel Time (Ground)',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'GroundRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Trauma Center',
                coltype: 'TableSubsectionCheckAndDropdown',
                check_field: {
                  field_label: 'Yes',
                  field_id: 'Check Box Trauma Center #',
                },
                dropdown_field: {
                  field_label: 'Level:',
                  field_id: 'Trauma Center - Level (Row #)',
                  dropdown_options: ['1', '2', '3', '4'],
                },
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Burn Center',
                coltype: 'TableSubsectionCheckOptions',
                can_select_multiple: false,
                fields: [
                  {
                    field_label: 'Yes',
                    table_field_id: 'Check Box Burn Center Yes #',
                  },
                  {
                    field_label: 'No',
                    table_field_id: 'Check Box Burn Center No #',
                  },
                ],
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Helipad',
                coltype: 'TableSubsectionCheckOptions',
                can_select_multiple: false,
                fields: [
                  {
                    field_label: 'Yes',
                    table_field_id: 'Check Box Helipad Yes #',
                  },
                  {
                    field_label: 'No',
                    table_field_id: 'Check Box Helipad No #',
                  },
                ],
                validations: {
                  input_required: true,
                },
              },
            ],
          },
        },
        {
          title: '6. Special Medical Emergency Procedures:',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'ListInput',
          field: {
            field_id: 'Special Medical Emergency Procedures',
            field_label: '6. Special Medical Emergency Procedures',
            inputtype: 'Input-List',
            validations: {
              // If this field is required to be "valid" for a state transition, check the validations.  All must be true for the field to be valid.  If this field is not required for a state transition, no check is performed.
              input_required: true, // Must be at least one item with length of > 1.  This will largely be managed by the rendering/handling code.
              format: 'array',
            },
          },
        },
        {
          type: 'CheckboxInput',
          field: {
            field_id: 'Check Box if aviation assests are utilized for rescue',
            field_label:
              'Check box if aviation assets are utilized for rescue. If assets are used, coordinate with Air Operations.',
            inputtype: 'Input-Checkbox',
            validations: {
              format: 'boolean',
            },
          },
        },
        {
          title: '7. Prepared By (Medical Unit Leader)',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '7 Prepared by Medical Unit Leader Name',
            field_label: 'Name:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'SignatureInput',
          field: {
            field_id: 'Signature_11',
            field_label: 'Signature:',
            inputtype: 'Input-Signature',
            validations: {
              input_required: true,
            },
          },
        },
      ],
    },
    {
      section_name: 'Safety Officer Section',
      section_instruction:
        'This part of the form must be filled out by the Safety Officer',
      section_state_roles: [
        {
          state_name: 'Awaiting Approval',
          roles: ['Safety Officer'],
        },
      ],
      subsections: [
        {
          title: '8. Approved By (Safety Officer)',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '8 Approved by Safety Officer Name',
            field_label: 'Name:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'SignatureInput',
          field: {
            field_id: 'Signature_12',
            field_label: 'Signature:',
            inputtype: 'Input-Signature',
            validations: {
              input_required: true,
            },
          },
        },
        {
          type: 'DatetimeInput',
          field: {
            field_id: 'DateTime_10',
            field_label: 'Date/Time:',
            inputtype: 'Input-Datetime',
            autofill: 'getCurrentDateTime',
            validations: {
              format: 'datetime',
            },
          },
        },
      ],
    },
  ],
};

const ICS207 = {
  formname: 'ICS 207',
  filename: 'ICS_207.PDF',
  form_workflow_group: 'ICS-FEMA',
  page_number_fields: ['IAP Page_6'],
  form_states: [
    {
      state_name: 'Incomplete',
      state_instruction:
        'The Planning Section Chief must fill out the Planning Section of this form.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 0 },
        sourcePosition: 'bottom',
      },
    },
    {
      state_name: 'Submitted',
      state_instruction: 'This form is complete.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 200 },
        targetPosition: 'top',
      },
    },
  ],
  form_state_transitions: [
    {
      from: 'Incomplete',
      to: 'Submitted',
      action: 'Submit ICS 207',
      transition_conditions: {
        // Things that need to be met for a transition to be allowed
        valid_sections: ['Planning Section'],
        valid_seats: ['Planning Section Chief'],
        repeatable: true, // This allows the transition to be "repeated" - in other words, allow a form to be edited by the authorized users when the record is in the "from" category.
      },
      transition_edge_workflow_settings: {
        // Note, the unique ID for each transition/edge is [from]-to-[to].  ex: "Incomplete-to-Awaiting approval"
        arrowHeadType: 'arrowclosed',
      },
    },
  ],
  sections: [
    {
      section_name: 'Planning Section',
      section_instruction:
        'This part of the form must be filled out by the Planning Section Chief',
      section_state_roles: [
        {
          state_name: 'Incomplete',
          roles: ['Planning Section Chief'],
        },
        {
          state_name: 'Submitted',
          roles: ['Planning Section Chief'],
        },
      ],
      subsections: [
        {
          title: '1. Incident Name',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '1 Incident Name_11',
            field_label: '1. Incident Name',
            inputtype: 'Input-Text',
            autofill: 'getCurrentIncidentName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '2. Operational Period',
          type: 'Title',
          subtitle: 'Date/Time',
        },
        {
          type: 'OPDateTimeGroup',
          fields: [
            {
              field_id: 'Date From',
              field_label: 'Date From:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateFrom',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Date To',
              field_label: 'Date To:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateTo',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Time From',
              field_label: 'Time From:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeFrom',
              validations: {
                format: 'time',
              },
            },
            {
              field_id: 'Time To',
              field_label: 'Time To:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeTo',
              validations: {
                format: 'time',
              },
            },
          ],
        },
        {
          type: 'ListInput',
          field: {
            field_id: 'Incident Commanders_2',
            field_label: 'Incident Commander(s)',
            inputtype: 'Input-List',
            validations: {
              // If this field is required to be "valid" for a state transition, check the validations.  All must be true for the field to be valid.  If this field is not required for a state transition, no check is performed.
              input_required: true, // Must be at least one item with length of > 1.  This will largely be managed by the rendering/handling code.
              format: 'array',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Liaison Officer_3',
            field_label: 'Liaison Officer',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Liaison Officer',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Safety Officer_4',
            field_label: 'Safety Officer',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Safety Officer',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Public Information Officer_4',
            field_label: 'Public Information Officer',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Public Information Officer',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Operations Section ChiefRow1',
            field_label: 'Operations Section Chief',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Operations Section Chief',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Staging Area Manager',
            field_label: 'Staging Area Manager',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Staging Area Manager',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'undefined_27',
            field_label: 'Ops Branch 1 Director',
            inputtype: 'Input-Text',
            /*
						autofill: "getUserNameBySeatName",
						autofill_settings: {
							seat_name: "Staging Area Manager"
						},
						*/
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'undefined_28',
            field_label: 'Ops Branch 2 Director',
            inputtype: 'Input-Text',
            /*
						autofill: "getUserNameBySeatName",
						autofill_settings: {
							seat_name: "Staging Area Manager"
						},
						*/
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'undefined_29',
            field_label: 'Ops Branch 3 Director',
            inputtype: 'Input-Text',
            /*
						autofill: "getUserNameBySeatName",
						autofill_settings: {
							seat_name: "Staging Area Manager"
						},
						*/
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'undefined_30',
            field_label: 'Ops Branch 4 Director',
            inputtype: 'Input-Text',
            /*
						autofill: "getUserNameBySeatName",
						autofill_settings: {
							seat_name: "Staging Area Manager"
						},
						*/
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Planning Section Chief_3',
            field_label: 'Planning Section Chief',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Planning Section Chief',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Resources Unit Ldr',
            field_label: 'Resources Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Resources Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Situation Unit Ldr',
            field_label: 'Situation Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Situation Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Documentation Unit Ldr',
            field_label: 'Documentation Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Documentation Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Demobilization Unit Ldr',
            field_label: 'Demobilization Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Demobilization Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'undefined_31',
            field_label: 'Planning Section Member (blank)',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Logistics Section Chief_2',
            field_label: 'Logistics Section Chief',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Logistics Section Chief',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Support Branch Dir',
            field_label: 'Support Branch Director',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Support Branch Director',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Supply Unit Ldr',
            field_label: 'Supply Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Supply Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Facilities Unit Ldr',
            field_label: 'Facilities Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Facilities Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Ground Spt Unit Ldr',
            field_label: 'Ground Support Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Ground Support Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Service Branch Dir',
            field_label: 'Service Branch Director',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Service Branch Director',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Comms Unit Ldr',
            field_label: 'Communications Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Communications Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Medical Unit Ldr',
            field_label: 'Medical Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Medical Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Food Unit Ldr',
            field_label: 'Food Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Food Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'FinanceAdmin Section Chief',
            field_label: 'Finance/Admin Section Chief',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Finance/Admin Section Chief',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Time Unit Ldr',
            field_label: 'Time Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Time Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Procurement Unit Ldr',
            field_label: 'Procurement Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Procurement Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'CompClaims Unit Ldr',
            field_label: 'Comp/Claims Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Comp/Claims Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'Cost Unit Ldr',
            field_label: 'Cost Unit Leader',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Cost Unit Leader',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'undefined_32',
            field_label: 'Finance/Admin blank',
            inputtype: 'Input-Text',
            autofill: 'getUserNameBySeatName',
            autofill_settings: {
              seat_name: 'Finance/Admin blank',
            },
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '4. Prepared By',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '4 Prepared by Name_2',
            field_label: 'Name:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'PositionTitle_9',
            field_label: 'Position/Title:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserIncidentSeat',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'SignatureInput',
          field: {
            field_id: 'Signature_13',
            field_label: 'Signature:',
            inputtype: 'Input-Signature',
            validations: {
              input_required: true,
            },
          },
        },
        {
          type: 'DatetimeInput',
          field: {
            field_id: 'DateTime_11',
            field_label: 'Date/Time:',
            inputtype: 'Input-Datetime',
            autofill: 'getCurrentDateTime',
            validations: {
              format: 'datetime',
            },
          },
        },
      ],
    },
  ],
};

const ICS208 = {
  formname: 'ICS 208',
  filename: 'ICS_208.PDF',
  form_workflow_group: 'ICS-FEMA',
  page_number_fields: ['IAP Page_7'],
  form_states: [
    {
      state_name: 'Incomplete',
      state_instruction:
        'The Planning Section Chief must fill out the Planning Section of this form.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 0 },
        sourcePosition: 'bottom',
      },
    },
    {
      state_name: 'Submitted',
      state_instruction: 'This form is complete.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 200 },
        targetPosition: 'top',
      },
    },
  ],
  form_state_transitions: [
    {
      from: 'Incomplete',
      to: 'Submitted',
      action: 'Submit ICS 208',
      transition_conditions: {
        // Things that need to be met for a transition to be allowed
        valid_sections: ['Planning Section'],
        valid_seats: ['Planning Section Chief'],
        repeatable: true, // This allows the transition to be "repeated" - in other words, allow a form to be edited by the authorized users when the record is in the "from" category.
      },
      transition_edge_workflow_settings: {
        // Note, the unique ID for each transition/edge is [from]-to-[to].  ex: "Incomplete-to-Awaiting approval"
        arrowHeadType: 'arrowclosed',
      },
    },
  ],
  sections: [
    {
      section_name: 'Planning Section',
      section_instruction:
        'This part of the form must be filled out by the Planning Section Chief',
      section_state_roles: [
        {
          state_name: 'Incomplete',
          roles: ['Planning Section Chief'],
        },
        {
          state_name: 'Submitted',
          roles: ['Planning Section Chief'],
        },
      ],
      subsections: [
        {
          title: '1. Incident Name',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '1 Incident Name_12',
            field_label: '1. Incident Name',
            inputtype: 'Input-Text',
            autofill: 'getCurrentIncidentName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '2. Operational Period',
          type: 'Title',
          subtitle: 'Date/Time',
        },
        {
          type: 'OPDateTimeGroup',
          fields: [
            {
              field_id: 'Date From',
              field_label: 'Date From:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateFrom',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Date To',
              field_label: 'Date To:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateTo',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Time From',
              field_label: 'Time From:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeFrom',
              validations: {
                format: 'time',
              },
            },
            {
              field_id: 'Time To',
              field_label: 'Time To:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeTo',
              validations: {
                format: 'time',
              },
            },
          ],
        },
        {
          title:
            '3. Safety Message/Expanded Safety Message, Safety Plan, Site Safety Plan:',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'ListInput',
          field: {
            field_id:
              '3 Safety MessageExpanded Safety Message Safety Plan Site Safety Plan',
            field_label: '',
            inputtype: 'Input-List',
            validations: {
              // If this field is required to be "valid" for a state transition, check the validations.  All must be true for the field to be valid.  If this field is not required for a state transition, no check is performed.
              input_required: true, // Must be at least one item with length of > 1.  This will largely be managed by the rendering/handling code.
              format: 'array',
            },
          },
        },
        {
          title: '4. Site Safety Plan Required?',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'CheckYesNoAndTextInput',
          check_yes_field: {
            field_id: 'Site Safety Plan Required? Yes',
            field_label: 'Yes',
            inputtype: 'Input-Checkbox',
            validations: {
              format: 'boolean',
            },
          },
          check_no_field: {
            field_id: 'Site Safety Plan Required? No',
            field_label: 'No',
            inputtype: 'Input-Checkbox',
            validations: {
              format: 'boolean',
            },
          },
          text_field: {
            field_id:
              '4 Site Safety Plan Required Yes No Approved Site Safety Plans Located At',
            field_label: 'Approved Site Safety Plan(s) Located at:',
            inputtype: 'Input-Text',
            validations: {
              field_depends_on: 'check_yes_field',
              format: 'text',
            },
          },
        },
        {
          title: '7. Prepared By',
          subtitle: '',
          type: 'Title',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '5 Prepared by Name',
            field_label: 'Name:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: 'PositionTitle_10',
            field_label: 'Position/Title:',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserIncidentSeat',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'SignatureInput',
          field: {
            field_id: 'Signature_14',
            field_label: 'Signature:',
            inputtype: 'Input-Signature',
            validations: {
              input_required: true,
            },
          },
        },
        {
          type: 'DatetimeInput',
          field: {
            field_id: 'DateTime_12',
            field_label: 'Date/Time:',
            inputtype: 'Input-Datetime',
            autofill: 'getCurrentDateTime',
            validations: {
              format: 'datetime',
            },
          },
        },
      ],
    },
  ],
};

const ICS208HM = {
  formname: 'ICS 208HM',
  filename: 'ICS_208HM.PDF',
  form_workflow_group: 'ICS-FEMA',
  form_states: [
    {
      state_name: 'Incomplete',
      state_instruction:
        'The Planning Section Chief must fill out the Planning Section of this form.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 0 },
        sourcePosition: 'bottom',
      },
    },
    {
      state_name: 'Submitted',
      state_instruction: 'This form is complete.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 200 },
        targetPosition: 'top',
      },
    },
  ],
  form_state_transitions: [
    {
      from: 'Incomplete',
      to: 'Submitted',
      action: 'Submit ICS 208HM',
      transition_conditions: {
        // Things that need to be met for a transition to be allowed
        valid_sections: ['Planning Section'],
        valid_seats: ['Planning Section Chief'],
        repeatable: true, // This allows the transition to be "repeated" - in other words, allow a form to be edited by the authorized users when the record is in the "from" category.
      },
      transition_edge_workflow_settings: {
        // Note, the unique ID for each transition/edge is [from]-to-[to].  ex: "Incomplete-to-Awaiting approval"
        arrowHeadType: 'arrowclosed',
      },
    },
  ],
  sections: [
    {
      section_name: 'Planning Section',
      section_instruction:
        'This part of the form must be filled out by the Planning Section Chief',
      section_state_roles: [
        {
          state_name: 'Incomplete',
          roles: ['Planning Section Chief'],
        },
        {
          state_name: 'Submitted',
          roles: ['Planning Section Chief'],
        },
      ],
      subsections: [
        {
          title: '1. Incident Name',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '1 Incident Name_5',
            field_label: '1. Incident Name',
            inputtype: 'Input-Text',
            autofill: 'getCurrentIncidentName',
            validations: {
              format: 'text',
            },
          },
        },
      ],
    },
  ],
};

const ICS213RR = {
  formname: 'ICS 213 RR',
  filename: 'ICS_213RR.PDF',
  form_workflow_group: 'ICS-FEMA',
  form_states: [
    {
      state_name: 'Incomplete',
      state_instruction:
        'You must use this form to log Activities and Resources Assigned every Operational Period.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 0 },
        sourcePosition: 'bottom',
      },
    },
    {
      state_name: 'Submitted',
      state_instruction:
        'Your ICS 214 Activity Log has been submitted for this Operational Period.  You may continue to submit modifications.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 100 },
        sourcePosition: 'top',
        targetPosition: 'bottom',
      },
    },
  ],
  form_state_transitions: [
    {
      from: 'Incomplete',
      to: 'Submitted',
      action: 'Submit ICS 214',
      transition_conditions: {
        // Things that need to be met for a transition to be allowed
        valid_sections: ['Planning Section'],
        valid_seats: ['*'],
        repeatable: true, // This allows the transition to be "repeated" - in other words, allow a form to be edited by the authorized users when the record is in the "from" category.
      },
      transition_edge_workflow_settings: {
        // Note, the unique ID for each transition/edge is [from]-to-[to].  ex: "Incomplete-to-Awaiting approval"
        arrowHeadType: 'arrowclosed',
      },
    },
  ],
  sections: [
    {
      section_name: 'Planning Section',
      section_instruction:
        'This part of the form must be filled out by the Planning Section Chief',
      section_state_roles: [
        {
          state_name: 'Incomplete',
          roles: ['*'],
        },
        {
          state_name: 'Submitted',
          roles: ['*'],
        },
      ],
      subsections: [
        {
          title: '1. Incident Name',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '1 Incident Name',
            field_label: '1. Incident Name',
            inputtype: 'Input-Text',
            autofill: 'getCurrentIncidentName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '2. Operational Period',
          subtitle: 'Date/Time',
          type: 'OPDateTimeGroup',
          fields: [
            {
              field_id: 'Date From',
              field_label: 'Date From:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateFrom',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Date To',
              field_label: 'Date To:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateTo',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Time From',
              field_label: 'Time From:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeFrom',
              validations: {
                format: 'time',
              },
            },
            {
              field_id: 'Time To',
              field_label: 'Time To:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeTo',
              validations: {
                format: 'time',
              },
            },
          ],
        },
        {
          type: 'TextInput',
          field: {
            field_id: '3 Name',
            field_label: '3. Name',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: '4 ICS Position',
            field_label: '4. ICS Position',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserIncidentSeat',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: '5 Home Agency and Unit',
            field_label: '5. Home Agency and Unit',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'Table',
          field_id: '6. Resources Assigned',
          Tableconfig: {
            main_table_rows: 8,
            uses_continuation_page: 'false', // Because there is a continuation page provided
            continuation_page_number: 0, // Where the continuation page can be found in the PDF template
            continuation_table_rows: 0,
            row_title: 'Resource',
            columns: [
              {
                colname: 'Name',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'NameRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'ICS Position',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'ICS PositionRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Activity',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Home Agency and UnitRow#',
                validations: {
                  input_required: true,
                },
              },
            ],
          },
        },

        {
          type: 'Table',
          field_id: '7. Activity Log',
          Tableconfig: {
            main_table_rows: 24,
            uses_continuation_page: 'true', // Because there is a continuation page provided
            continuation_page_number: 1, // Where the continuation page can be found in the PDF template
            continuation_table_rows: 36,
            row_title: 'Activity',
            columns: [
              {
                colname: 'Date/Time',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'DateTimeRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Activity',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Notable ActivitiesRow#',
                validations: {
                  input_required: true,
                },
              },
            ],
          },
        },
      ],
    },
  ],
};

const ICS214 = {
  formname: 'ICS 214',
  filename: 'ICS_214.PDF',
  form_workflow_group: 'ICS-FEMA',
  form_states: [
    {
      state_name: 'Incomplete',
      state_instruction:
        'You must use this form to log Activities and Resources Assigned every Operational Period.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 0 },
        sourcePosition: 'bottom',
      },
    },
    {
      state_name: 'Submitted',
      state_instruction:
        'Your ICS 214 Activity Log has been submitted for this Operational Period.  You may continue to submit modifications.',
      state_node_workflow_settings: {
        // Note, the unique ID for each state/node is the state_name
        data: {},
        position: { x: 250, y: 100 },
        sourcePosition: 'top',
        targetPosition: 'bottom',
      },
    },
  ],
  form_state_transitions: [
    {
      from: 'Incomplete',
      to: 'Submitted',
      action: 'Submit ICS 214',
      transition_conditions: {
        // Things that need to be met for a transition to be allowed
        valid_sections: ['Planning Section'],
        valid_seats: ['*'],
        repeatable: true, // This allows the transition to be "repeated" - in other words, allow a form to be edited by the authorized users when the record is in the "from" category.
      },
      transition_edge_workflow_settings: {
        // Note, the unique ID for each transition/edge is [from]-to-[to].  ex: "Incomplete-to-Awaiting approval"
        arrowHeadType: 'arrowclosed',
      },
    },
  ],
  sections: [
    {
      section_name: 'Planning Section',
      section_instruction:
        'This part of the form must be filled out by the Planning Section Chief',
      section_state_roles: [
        {
          state_name: 'Incomplete',
          roles: ['*'],
        },
        {
          state_name: 'Submitted',
          roles: ['*'],
        },
      ],
      subsections: [
        {
          title: '1. Incident Name',
          type: 'Title',
          subtitle: '',
        },
        {
          type: 'TextInput',
          field: {
            field_id: '1 Incident Name',
            field_label: '1. Incident Name',
            inputtype: 'Input-Text',
            autofill: 'getCurrentIncidentName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          title: '2. Operational Period',
          subtitle: 'Date/Time',
          type: 'OPDateTimeGroup',
          fields: [
            {
              field_id: 'Date From',
              field_label: 'Date From:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateFrom',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Date To',
              field_label: 'Date To:',
              inputtype: 'Input-Date',
              autofill: 'getCurrentOPDateTo',
              validations: {
                format: 'date',
              },
            },
            {
              field_id: 'Time From',
              field_label: 'Time From:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeFrom',
              validations: {
                format: 'time',
              },
            },
            {
              field_id: 'Time To',
              field_label: 'Time To:',
              inputtype: 'Input-Time',
              autofill: 'getCurrentOPTimeTo',
              validations: {
                format: 'time',
              },
            },
          ],
        },
        {
          type: 'TextInput',
          field: {
            field_id: '3 Name',
            field_label: '3. Name',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserName',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: '4 ICS Position',
            field_label: '4. ICS Position',
            inputtype: 'Input-Text',
            autofill: 'getCurrentUserIncidentSeat',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'TextInput',
          field: {
            field_id: '5 Home Agency and Unit',
            field_label: '5. Home Agency and Unit',
            inputtype: 'Input-Text',
            validations: {
              format: 'text',
            },
          },
        },
        {
          type: 'Table',
          field_id: '6. Resources Assigned',
          Tableconfig: {
            main_table_rows: 8,
            uses_continuation_page: 'false', // Because there is a continuation page provided
            continuation_page_number: 0, // Where the continuation page can be found in the PDF template
            continuation_table_rows: 0,
            row_title: 'Resource',
            columns: [
              {
                colname: 'Name',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'NameRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'ICS Position',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'ICS PositionRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Activity',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Home Agency and UnitRow#',
                validations: {
                  input_required: true,
                },
              },
            ],
          },
        },

        {
          type: 'Table',
          field_id: '7. Activity Log',
          Tableconfig: {
            main_table_rows: 24,
            uses_continuation_page: 'true', // Because there is a continuation page provided
            continuation_page_number: 1, // Where the continuation page can be found in the PDF template
            continuation_table_rows: 36,
            row_title: 'Activity',
            columns: [
              {
                colname: 'Date/Time',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'DateTimeRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Activity',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Notable ActivitiesRow#',
                validations: {
                  input_required: true,
                },
              },
            ],
          },
        },
      ],
    },
  ],
};

const IAPCOVERSHEET = {
	formname: "IAP COVER SHEET",
  filename: "IAPCoverSheet-CG.pdf",
	form_workflow_group: "ICS-FEMA",
	form_states: [
		{
			state_name: "Incomplete",
			state_instruction: "You must use this form to log Activities and Resources Assigned every Operational Period.",
			state_node_workflow_settings: { // Note, the unique ID for each state/node is the state_name
				data: {
				},
				position: { x: 250, y: 0 },
				sourcePosition: 'bottom',
			}
		},
		{
			state_name: "Submitted",
			state_instruction: "Your ICS 214 Activity Log has been submitted for this Operational Period.  You may continue to submit modifications.",
			state_node_workflow_settings: { // Note, the unique ID for each state/node is the state_name
				data: {
				},
				position: { x: 250, y: 100 },
				sourcePosition: 'top',
				targetPosition: 'bottom',
			}
		},
	],
	form_state_transitions: [
		{
			"from": "Incomplete",
			"to": "Submitted",
			"action": "Submit ICS 214",
			transition_conditions: { // Things that need to be met for a transition to be allowed
				valid_sections: [
					"Planning Section"
				],
				valid_seats: [
					"*"
				],
				repeatable: true // This allows the transition to be "repeated" - in other words, allow a form to be edited by the authorized users when the record is in the "from" category.
			},
			transition_edge_workflow_settings: { // Note, the unique ID for each transition/edge is [from]-to-[to].  ex: "Incomplete-to-Awaiting approval"
				arrowHeadType: 'arrowclosed',
			}
		},
	],
	sections: [
		{
			section_name: "Planning Section",
			section_instruction: "This part of the form must be filled out by the Planning Section Chief",
			section_state_roles: [
				{
					state_name: "Incomplete",
					roles: [
						"*"
					]
				},
				{
					state_name: "Submitted",
					roles: [
						"*"
					]
				},
			],
			subsections: [
				{
					title: "1. Incident Name",
					type: "Title",
					subtitle: "",
				},
				{
					type: "TextInput",
					field: {
						field_id: "1 Incident Name",
						field_label: "1. Incident Name",
						inputtype: "Input-Text",
						autofill: "getCurrentIncidentName",
						validations: {
							format: "text",
						}
					}
				},
				{
					title: "2. Operational Period",
					subtitle: "Date/Time",
					type: "OPDateTimeGroup",
					fields: [
						{
							field_id: "Date From",
							field_label: "Date From:",
							inputtype: "Input-Date",
							autofill: "getCurrentOPDateFrom",
							validations: {
								format: "date",
							}
						},
						{
							field_id: "Date To",
							field_label: "Date To:",
							inputtype: "Input-Date",
							autofill: "getCurrentOPDateTo",
							validations: {
								format: "date",
							}
						},
						{
							field_id: "Time From",
							field_label: "Time From:",
							inputtype: "Input-Time",
							autofill: "getCurrentOPTimeFrom",
							validations: {
								format: "time",
							}
						},
						{
							field_id: "Time To",
							field_label: "Time To:",
							inputtype: "Input-Time",
							autofill: "getCurrentOPTimeTo",
							validations: {
								format: "time",
							}
						},
					]
				},
				{
					type: "TextInput",
					field: {
						field_id: "3 Name",
						field_label: "3. Name",
						inputtype: "Input-Text",
						autofill: "getCurrentUserName",
						validations: {
							format: "text",
						}
					}
				},
				{
					type: "TextInput",
					field: {
						field_id: "4 ICS Position",
						field_label: "4. ICS Position",
						inputtype: "Input-Text",
						autofill: "getCurrentUserIncidentSeat",
						validations: {
							format: "text",
						}
					}
				},
				{
					type: "TextInput",
					field: {
						field_id: "5 Home Agency and Unit",
						field_label: "5. Home Agency and Unit",
						inputtype: "Input-Text",
						validations: {
							format: "text",
						}
					}
				},
				{
					type: "Table",
					field_id: "6. Resources Assigned",
					Tableconfig: {
						main_table_rows: 8,
						uses_continuation_page: "false",  // Because there is a continuation page provided
						continuation_page_number: 0, // Where the continuation page can be found in the PDF template
						continuation_table_rows: 0,
						row_title: "Resource",
						columns: [
							{
								colname: "Name",
								coltype: "TableSubsectionTextInput",
								table_field_id: "NameRow#",
								validations: {
									"input_required": true,
								},
							},
							{
								colname: "ICS Position",
								coltype: "TableSubsectionTextInput",
								table_field_id: "ICS PositionRow#",
								validations: {
									"input_required": true,
								},
							}
							,
							{
								colname: "Activity",
								coltype: "TableSubsectionTextInput",
								table_field_id: "Home Agency and UnitRow#",
								validations: {
									"input_required": true,
								},
							}
						]
					},
				},

        {
          type: 'Table',
          field_id: '7. Activity Log',
          Tableconfig: {
            main_table_rows: 24,
            uses_continuation_page: 'true', // Because there is a continuation page provided
            continuation_page_number: 1, // Where the continuation page can be found in the PDF template
            continuation_table_rows: 36,
            row_title: 'Activity',
            columns: [
              {
                colname: 'Date/Time',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'DateTimeRow#',
                validations: {
                  input_required: true,
                },
              },
              {
                colname: 'Activity',
                coltype: 'TableSubsectionTextInput',
                table_field_id: 'Notable ActivitiesRow#',
                validations: {
                  input_required: true,
                },
              },
            ],
          },
        },
      ],
    },
  ],
};

export const FormConfigs = [
  ICS201,
  ICS202,
  ICS203,
  ICS204,
  ICS205,
  ICS205A,
  ICS206,
  ICS207,
  ICS208,
  ICS214,
  IAPCOVERSHEET,
]; //ICS208HM
