import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import validatePagerAddress from 'utils/validatePagerAddress';
import { updateUserProfileSettings } from 'actions/profileActions';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import API from 'PREPAREsrc/service/api';
import Network, { PrepareBaseUrl } from 'PREPAREsrc/service/Network';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from 'axios';
import { toastConfig } from 'assets/data/config';
import {
  updateUserName,
  updateUserTimeFormat,
} from 'PREPAREsrc/store/user/user.action';

export default function ProfileSettings({
  userId,
  userName = '',
  userEmail = '',
  profileSettings = {},
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useForm();

  const { mobile_phone_number, home_phone_number, pager_address } =
    profileSettings || {};

  const reduxDispatch = useDispatch();

  const isUpdateUserProfileSettingsLoaded = useSelector((state) => {
    return state.app.isUpdateUserProfileSettingsLoaded;
  });

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const lastWorkspaceId = useSelector(
    (state) => state.app.user.epic_user_lastWorkspaceId
  );

  const reduxUser = useSelector((state) => state.prepare.user);

  useEffect(() => {
    isUpdateUserProfileSettingsLoaded && reduxDispatch(endLoading());
  }, [isUpdateUserProfileSettingsLoaded]);

  const [formUserName, setFormUserName] = useState(userName);

  useEffect(() => {
    setFormUserName(userName);
  }, [userName]);

  const [formUserEmail, setFormUserEmail] = useState(userEmail);

  useEffect(() => {
    setFormUserEmail(userEmail);
  }, [userEmail]);

  const [mobilePhoneNumber, setMobilePhoneNumber] = useState(
    mobile_phone_number || ''
  );
  const [homePhoneNumber, setHomePhoneNumber] = useState(
    home_phone_number || ''
  );
  const [pagerAddress, setPagerAddress] = useState(pager_address || '');

  const timeFormatOptions = [
    {
      value: 'hour24Mode',
      label: '24 Hour Time Format',
    },
    {
      value: 'hour12Mode',
      label: '12 Hour Time Format',
    },
  ];

  useEffect(() => {
    setMobilePhoneNumber(profileSettings.mobile_phone_number || '');
    setHomePhoneNumber(profileSettings.home_phone_number || '');
    setPagerAddress(profileSettings.pager_address || '');
  }, [userId, profileSettings]);

  const getUserData = () => {
    reduxDispatch(startLoading());
    Network
      .get(API.userDetails, {userId: reduxUser.id})
      .then((response) => {
        const timeFormatValue = timeFormatOptions.find(
          (tF) => tF.value === response.data.response.dataset[0].timeFormat
        );
        setValue('timeFormat', timeFormatValue || timeFormatOptions[0]);
      })
      .catch(console.log)
      .finally(() => {
        reduxDispatch(endLoading());
      });
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser, workspaceId]);

  function createProfileSettings() {
    return {
      ...profileSettings,
      userName: formUserName,
      mobile_phone_number: mobilePhoneNumber,
      home_phone_number: homePhoneNumber,
      pager_address: pagerAddress,
    };
  }

  const prepareProfileData = () => {
    reduxDispatch(startLoading());
    Network
      .post(API.updateUserDetails, {
        name: formUserName,
        userId: reduxUser.id,
        timeFormat: getValues('timeFormat')?.value,
      })
      .then((response) => {
        reduxDispatch(updateUserName(formUserName));
        reduxDispatch(updateUserTimeFormat(getValues('timeFormat').value));
        getUserData();
        toast.success('User Data Updated Succesfully');
      })
      .catch((error) => {
        console.log(error);
        // toast.error(error.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        reduxDispatch(endLoading());
      });
  };

  function saveProfileSettingsClicked() {
    reduxDispatch(startLoading());
    reduxDispatch(updateUserProfileSettings(userId, createProfileSettings()));
    prepareProfileData();
  }

  function validatePhoneNumberRegex(phoneNumber) {
    return (
      phoneNumber &&
      phoneNumber.match(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g
      )
    );
  }

  function validateAllProfileSettings() {
    if (
      !!mobilePhoneNumber &&
      !!mobilePhoneNumber.length &&
      !validatePhoneNumberRegex(mobilePhoneNumber)
    )
      return false;
    if (
      !!homePhoneNumber &&
      !!homePhoneNumber.length &&
      !validatePhoneNumberRegex(homePhoneNumber)
    )
      return false;
    if (
      !!pagerAddress &&
      !!pagerAddress.length &&
      !validatePagerAddress(pagerAddress)
    )
      return false;
    return true;
  }

  const areAllProfileSettingsValid = validateAllProfileSettings();

  return (
    <>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="form-label">Name</label>
          <StylishNewInput
            value={formUserName}
            onChange={(e) => setFormUserName(e.target.value)}
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Email</label>
          <StylishNewInput
            value={formUserEmail}
            onChange={(e) => setFormUserEmail(e.target.value)}
            disabled
            readOnly
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Phone (Mobile)</label>
          <StylishNewInput
            value={mobilePhoneNumber}
            onChange={(e) => setMobilePhoneNumber(e.target.value)}
          />
          {!!mobilePhoneNumber &&
            !!mobilePhoneNumber.length &&
            !validatePhoneNumberRegex(mobilePhoneNumber) && (
              <span className="form-text form-error">
                Invalid mobile phone number.
              </span>
            )}
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Phone (Home)</label>
          <StylishNewInput
            value={homePhoneNumber}
            onChange={(e) => setHomePhoneNumber(e.target.value)}
          />
          {!!homePhoneNumber &&
            !!homePhoneNumber.length &&
            !validatePhoneNumberRegex(homePhoneNumber) && (
              <span className="form-text form-error">
                Invalid home phone number.
              </span>
            )}
        </div>
        <div className="col-md-6 mb-3 mb-md-0">
          <label className="form-label">Pager Address</label>
          <StylishNewInput
            value={pagerAddress}
            onChange={(e) => setPagerAddress(e.target.value)}
          />
          {!!pagerAddress &&
            !!pagerAddress.length &&
            !validatePagerAddress(pagerAddress) && (
              <span className="form-text form-error">
                Invalid pager address.
              </span>
            )}
        </div>
        <div className="col-md-6">
          <label className="form-label">Preferred Exercise Time Format</label>
          <Controller
            control={control}
            name="timeFormat"
            render={({ field: { onChange, value } }) => (
              <StylishNewSelect
                options={timeFormatOptions}
                defaultValue={value}
                value={value}
                onChange={onChange}
                placeholder={'Select Preferred Exercise Time Format'}
                isClearable={false}
                isSearchable={true}
                isMulti={false}
                isDisabled={false}
              />
            )}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <strong>Consent for Communication: By entering your phone number(s) here, you are giving explicit consent to receive automated messages from Pratus related to active hazard alerts and incident response activations. This may include SMS messages, emails, and text-to-speech calls. You understand that this consent is a condition of your use of Pratus services and your employment requirements.</strong>
        </div>
      </div>

      <div className="button-group justify-content-end mt-4">
        <StylishNewButton
          className={'button--primary'}
          onClick={() => saveProfileSettingsClicked()}
          disabled={!areAllProfileSettingsValid}
        >
          Save
        </StylishNewButton>
      </div>

      {!areAllProfileSettingsValid && (
        <span className="form-text form-error multiline mt-3">
          Profile settings invalid. Please correct.
        </span>
      )}
    </>
  );
}
