import { useCallback } from 'react';
import IconTick from 'assets/images/icon__tick--blue.svg';

interface Props {
  steps: { id: number; label: string }[];
  activeStep: number;
  vertical?: boolean;
}

export const PratusStepper = ({
  steps,
  activeStep,
  vertical = false,
}: Props) => {
  const getStepClass = useCallback(
    (stepId: number) => {
      if (activeStep > stepId) {
        return 'completed';
      }

      if (activeStep === stepId) {
        return 'active';
      }

      return '';
    },
    [activeStep]
  );

  const getLineClass = (id: number) => {
    return activeStep >= id ? 'active' : '';
  };

  return (
    <div
      className={`steps-container ${
        vertical ? 'vertical flex-column align-items-start' : 'flex-row'
      }`}
    >
      {steps.map((step, idx) => {
        const currentStepClass = getStepClass(step.id);

        return (
          <>
            <div
              key={step.id}
              className={`step ` + currentStepClass}
            >
              <span className={`step-number ` + currentStepClass}>
                {currentStepClass !== 'completed' ? (
                  step.id
                ) : (
                  <img src={IconTick} alt="" />
                )}
              </span>
              <span className={`step-label ` + currentStepClass}>
                {step.label}
              </span>
            </div>
            {idx < steps.length - 1 && (
              <div className={`line ${vertical && 'vertical'} ${getLineClass(step.id)}`}></div>
            )}
          </>
        );
      })}
    </div>
  );
};
