import {
  deleteInitialIncidentReportTeamChecklist,
  getAllInitialIncidentReportTeamChecklist,
} from 'actions/ReportActions';
import React, { useEffect, useState } from 'react';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { sanitizeHtml } from '../../utils/sanitizeHtml';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export default function ManageTeamChecklist({
  onChangeTab,
  selectedChecklist,
  type,
}) {
  const reduxDispatch = useDispatch();
  const [showConfirmDialog, setShowConfirmDialog] = useState(null);

  const allInitialIncidentReportTeamChecklist = useSelector(
    (state) => state.app.allInitialIncidentReportTeamChecklist
  );
  const [
    cleanedInitialIncidentReportTeamChecklists,
    setCleanedInitialIncidentReportTeamChecklists,
  ] = useState([]);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const distributionLists = useSelector((state) => {
    return state.app.distributionListItems;
  });

  useEffect(() => {
    if (
      !!reduxCurrentlySelectedGroup &&
      !!reduxCurrentlySelectedGroup.group_guid
    ) {
      reduxDispatch(getAllInitialIncidentReportTeamChecklist());
    }
  }, [reduxCurrentlySelectedGroup]);

  useEffect(() => {
    if (!!allInitialIncidentReportTeamChecklist && !!distributionLists) {
      setCleanedInitialIncidentReportTeamChecklists(
        allInitialIncidentReportTeamChecklist.map((list) => {
          const teamExists = distributionLists.find((team) =>
            list.teams_activated?.includes(team.name)
          );
          return {
            ...list,
            teams_activated: teamExists
              ? list.teams_activated
              : '(please associate team)',
          };
        })
      );
    }
  }, [allInitialIncidentReportTeamChecklist, distributionLists]);

  const onCloseConfirmDialog = () => {
    setShowConfirmDialog(null);
  };
  const columns_report_data = [
    {
      dataField: 'incident_type',
      text: 'Incident Type',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Incident Name' },
    },
    {
      dataField: 'asset',
      text: 'Area of Responsibility',
      sort: true,
      attrs: { title: 'Area of Responsibility' },
    },

    {
      dataField: 'teams_activated',
      text: 'Relevant Teams Identified',
      sort: true,
      attrs: { title: 'Relevant Teams Identified' },
    },
  ];
  const onEditChecklist = (row) => {
    onChangeTab('Edit Checklist', row);
  };

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">
                Area of Responsibility
              </label>
              {row.asset}
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">Incident Type</label>
              {row.incident_type}
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">
                Relevant Teams Identified
              </label>
              {row.teams_activated}
            </div>
            <div className="col-md-12">
              <label className="form-label d-block mb-0">Team Checklist</label>
              <p
                className="m-0"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(row.team_checklist),
                }}
              ></p>
            </div>
          </div>
          <div className="button-group mt-4">
            <button
              type="button"
              className="button--icon primary ms-2"
              onClick={() => onEditChecklist(row)}
            >
              <SharedIcon iconName="stylus" />
            </button>
            <button
              type="button"
              className="button--icon primary ms-2"
              onClick={() => setShowConfirmDialog(row.id)}
            >
              <SharedIcon iconName="delete" />
            </button>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };
  return (
    <>
      <div className="form-block">
        <StylishNewTable
          keyField={'id'}
          columns={columns_report_data}
          rows={cleanedInitialIncidentReportTeamChecklists}
          expandRow={expandRow}
        />
      </div>
      {!!showConfirmDialog && (
        <StylishConfirmDialog
          show={!!showConfirmDialog}
          dialogTitle={'Delete Checklist'}
          dialogContent={'Are you sure you want to delete this checklist?'}
          onClose={onCloseConfirmDialog}
          onConfirm={() => {
            reduxDispatch(
              deleteInitialIncidentReportTeamChecklist(showConfirmDialog)
            );
            onCloseConfirmDialog();
          }}
        />
      )}
    </>
  );
}
