import { upsertNestedCategory } from 'actions/locationAction';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';

export default function NestedCategoryAddUpdateModal({
  show,
  onClose,
  mode,
  updateCategoryData,
  nestedCategories,
}) {
  const [locationTypes, setLocationTypes] = useState([]);

  const reduxDispatch = useDispatch();

  const {
    control,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...(!!locationTypes && !!locationTypes.length
        ? { locationType: locationTypes[0] }
        : {}),
    },
  });

  useEffect(() => {
    nestedCategories = nestedCategories || [];

    const formattedTypes = nestedCategories.reduce(
      (acc, category) => {
        if (!!updateCategoryData && category.id === updateCategoryData.id) {
          return acc;
        } else {
          const parentId = category.parent_category_id;
          const dependsOn =
            !!parentId &&
            nestedCategories.find((nested) => nested.id === parentId)
              ?.category_name;

          const type = {
            label: category.category_name,
            value: category.id,
            ...(!!dependsOn ? { dependsOn } : {}),
          };

          return [...acc, { ...type }];
        }
      },
      [{ label: '(none)', value: '' }]
    );

    if (!!updateCategoryData) {
      setValue(
        'locationType',
        formattedTypes.find(
          (type) => type.value === updateCategoryData.parent_category_id
        )
      );
      setValue('locationName', updateCategoryData.category_name);
    }

    setLocationTypes(formattedTypes, nestedCategories);
  }, [updateCategoryData, nestedCategories]);

  const onAddLocation = async (data) => {
    const item = {
      id: mode === 'Edit' ? updateCategoryData.id : generateUUID(),
      parent_category_id: data.locationType?.value
        ? data.locationType.value
        : null,
      category_name: data.locationName,
    };

    reduxDispatch(upsertNestedCategory(item));

    onClose();
  };

  const onLocationTypeChange = (e, onChange) => {
    setValue('refLocation', null);
    onChange(e);
  };

  const handleSaveClick = () => {
    onAddLocation(getValues());
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>
            {`${mode === 'Edit' ? 'Update' : 'Add'} Category`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!locationTypes.length && !!locationTypes[0].label && (
            <>
              <div className="mb-3">
                <label className="form-label">{`Select Parent Category (optional)`}</label>
                <Controller
                  control={control}
                  name="locationType"
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewSelect
                      options={locationTypes}
                      onChange={(e) => onLocationTypeChange(e, onChange)}
                      value={value}
                      isClearable={false}
                      isSearchable={false}
                      isMulti={false}
                      isDisabled={false}
                    />
                  )}
                />
                {errors.locationType && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
              </div>
              <hr className="dashed my-4" />
            </>
          )}
          <div className="mb-3">
            <label className="form-label">{`Enter Category Name`}</label>
            <Controller
              control={control}
              name="locationName"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={onChange}
                  value={value}
                  placeholder={`Enter Category Name`}
                />
              )}
            />
            {errors.locationName && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--primary"
              disabled={!isValid}
              onClick={() => handleSaveClick()}
            >
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
