import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import { fetchIcsMedicalTransportation } from 'slices/ics_medicalTransportationSlice';

import { getUserGuid, getGroupGuid } from 'slices/sliceHelpers';

// Slice
const ics_incidentMedicalTransportationSlice = createSlice({
    name: 'ics_incident_medical_transportation',
    initialState: {
        ics_incident_medical_transportation: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        fetchIcsIncidentMedicalTransportationSuccess: (state, action) => {
            state.ics_incident_medical_transportation = action.payload;
            state.status = 'succeeded';
        },
        createIcsIncidentMedicalTransportationSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        updateIcsIncidentMedicalTransportationSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    fetchIcsIncidentMedicalTransportationSuccess,
    createIcsIncidentMedicalTransportationSuccess,
    updateIcsIncidentMedicalTransportationSuccess,
    setError
} = ics_incidentMedicalTransportationSlice.actions;

export default ics_incidentMedicalTransportationSlice.reducer;

export const createIcsIncidentMedicalTransportation = (incidentId, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_incident_medical_transportation}/${incidentId}`, { ...data, user_guid });
        dispatch(fetchIcsMedicalTransportation(group_guid));
        dispatch(fetchIcsIncidentMedicalTransportation(incidentId));
        dispatch(createIcsIncidentMedicalTransportationSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsIncidentMedicalTransportation = (incidentId) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_incident_medical_transportation}/${incidentId}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsIncidentMedicalTransportationSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsIncidentMedicalTransportation = (incidentId, id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_incident_medical_transportation}/${incidentId}/${id}`, { ...data, user_guid });
        dispatch(fetchIcsMedicalTransportation(group_guid));
        dispatch(fetchIcsIncidentMedicalTransportation(incidentId));
        dispatch(updateIcsIncidentMedicalTransportationSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
