import React from 'react';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { action_status_options } from 'components/ICS/ICSConstants';
import StylishNewInput from '../DesignSystems/New/StylishNewInput';
import { Row } from 'react-bootstrap';

export default function ICSChecklist({
  checklist = [],
  setChecklist,
  incident_id,
  editable = false,
  existingData = false,
}) {
  const handleLabelChange = (index, newLabel) => {
    const updatedChecklist = checklist.map((item, idx) =>
      idx === index ? { ...item, label: newLabel } : item
    );
    setChecklist(updatedChecklist);
  };

  const handleStatusChange = (index, newStatus) => {
    const updatedChecklist = checklist.map((item, idx) =>
      idx === index ? { ...item, status: newStatus } : item
    );
    setChecklist(updatedChecklist);
  };

  const addItem = () => {
    setChecklist([...checklist, { label: '', status: 'Not Started' }]);
  };

  const removeItem = (index) => {
    setChecklist(checklist.filter((_, idx) => idx !== index));
  };

  return (
    <div className="ICSChecklist">
      <h6 className="mb-2">Checklist</h6>
      {checklist.map((item, index) => (
        <div key={index} className="checklist-item">
          {editable ? (
            <Row className="mb-2">
              <div className="col-6">
                <StylishNewInput
                  type="text"
                  value={item.label}
                  onChange={(e) => handleLabelChange(index, e.target.value)}
                  placeholder="Enter item label"
                />
              </div>
              <div className="col-1">
                <StylishNewButton
                  type="button"
                  className="button button--tertiary"
                  onClick={() => removeItem(index)}
                >
                  Remove
                </StylishNewButton>
              </div>
            </Row>
          ) : (
            <>
              <label className="checklist-label">
                {index + 1}. {item.label}
              </label>
              {(!!incident_id && !existingData && (
                <StylishNewSelect
                  options={action_status_options}
                  value={action_status_options.find(
                    (option) => option.value === item.status
                  )}
                  onChange={(e) => handleStatusChange(index, e.value)}
                  className="checklist-select"
                />
              )) || <span> - {item.status}</span>}
            </>
          )}
        </div>
      ))}
      {editable && (
        <StylishNewButton
          type="button"
          onClick={addItem}
          className="button--primary"
        >
          Add Item
        </StylishNewButton>
      )}
    </div>
  );
}
