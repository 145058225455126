import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getPlanningWorksheet } from 'actions/IAPForm204Actions';
import { getRosterForIncident } from 'actions/incidentActions';
import { fetchSignatures } from 'actions/signatureActions';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import EditOperationalPeriodDialog from 'components/IncidentConfig/EditOperationalPeriodDialog';
import { Config205A } from './FormTemplates/FormConfig/205AConfig';

export const IAPForm205A = ({ onFormRecordDataUpdate }) => {
  const [formState, setFormState] = useState(Config205A);
  const [incidentName, setIncidentName] = useState();
  const [
    operationalPeriodDateTimeFrom,
    setOperationalPeriodDateTimeFrom,
  ] = useState(moment().format('MM/DD/YYYY HH:mm'));
  const [
    operationalPeriodDateTimeTo,
    setOperationalPeriodDateTimeTo,
  ] = useState(moment().format('MM/DD/YYYY HH:mm'));

  const [
    isEditOperationalPeriodOpen,
    setIsEditOperationalPeriodOpen,
  ] = useState(false);

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const reduxUser = useSelector((state) => {
    return state.app.user;
  });
  const reduxSignatures = useSelector((state) => {
    return state.app.signatures;
  });
  const isFetchSignaturesLoaded = useSelector((state) => {
    return state.app.isFetchSignaturesLoaded;
  });
  const reduxRosterForIncident = useSelector(
    (state) => state.app.rosterForIncident
  );

  const planningWorksheet = useSelector((state) => {
    return state.app.planningWorksheet;
  });

  const reduxDispatch = useDispatch();

  const basicLocalCommunicationsInformationColumns = [
    {
      dataField: 'incident_assigned_position',
      text: 'Incident Assigned Position',
      sort: true,
      //   headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Incident Assigned Position' },
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      attrs: { title: 'Name' },
    },
    {
      dataField: 'method',
      text:
        'Method(s) of contact (radio frequency, phone, pager, cell #(s), etc.)',
      attrs: {
        title:
          'Method(s) of contact (radio frequency, phone, pager, cell #(s), etc.)',
      },
    },
  ];

  const updateFormState = (key, value) => {
    const index = formState.field.findIndex((field) => field.key === key);
    if (index !== -1) {
      let obj = { ...formState.field[index] };
      obj = { ...obj, value: value };
      formState.field[index] = obj;
      setFormState(formState);
      onFormRecordDataUpdate(formState);
    }
  };

  useEffect(() => {
    if (
      (!reduxSignatures || !reduxSignatures.length) &&
      !!isFetchSignaturesLoaded
    ) {
      reduxDispatch(fetchSignatures());
    }
  }, []);

  useEffect(() => {
    if (!!reduxSignatures && !!reduxSignatures.length > 0) {
      updateFormState('Signature_10', reduxSignatures[0].signature_image);
    }
  }, [reduxSignatures]);

  useEffect(() => {
    if (!!reduxCurrentIncident) {
      reduxDispatch(getRosterForIncident());
      reduxDispatch(getPlanningWorksheet());

      setIncidentName(reduxCurrentIncident?.name);
      setOperationalPeriodDateTimeFrom(
        moment(reduxCurrentIncident?.operational_period_start_date_time)
      );
      setOperationalPeriodDateTimeTo(
        moment(reduxCurrentIncident?.operational_period_start_date_time).add(
          reduxCurrentIncident?.operational_period_interval,
          'hours'
        )
      );

      updateFormState('1 Incident Name_9', reduxCurrentIncident?.name);

      reduxCurrentIncident?.operational_period_start_date_time &&
        updateFormState(
          'Date From',
          moment(
            reduxCurrentIncident?.operational_period_start_date_time
          ).format('DD/MM/YYYY')
        );
      reduxCurrentIncident?.operational_period_start_date_time &&
        updateFormState(
          'Time From',
          moment(
            reduxCurrentIncident?.operational_period_start_date_time
          ).format('HH:mm')
        );

      reduxCurrentIncident?.operational_period_start_date_time &&
        reduxCurrentIncident?.operational_period_interval &&
        updateFormState(
          'Date To',
          moment(reduxCurrentIncident?.operational_period_start_date_time)
            .add(reduxCurrentIncident?.operational_period_interval, 'hours')
            .format('DD/MM/YYYY')
        );
      reduxCurrentIncident?.operational_period_start_date_time &&
        reduxCurrentIncident?.operational_period_interval &&
        updateFormState(
          'Time To',
          moment(reduxCurrentIncident?.operational_period_start_date_time)
            .add(reduxCurrentIncident?.operational_period_interval, 'hours')
            .format('HH:mm')
        );
    }
  }, [reduxCurrentIncident]);

  useEffect(() => {
    if (!!reduxUser) {
      updateFormState(
        '4 Prepared by Name',
        reduxUser?.user_name || reduxUser?.email_address
      );
      updateFormState('DateTime_9', moment().format('DD/MM/YYYY HH:mm:ss'));
    }
  }, [reduxUser]);

  useEffect(() => {
    if (reduxRosterForIncident?.length) {
      updateFormState(
        'PositionTitle_8',
        reduxRosterForIncident.filter(
          (users) => users.user_guid === reduxUser.user_guid
        )[0]?.incident_seat
      );
    }
  }, [reduxRosterForIncident]);

  useEffect(() => {
    if (planningWorksheet?.communications) {
      let pwcData = [];
      planningWorksheet?.communications?.map((pwc, index) => {
        pwcData.push({
          key: 'Incident Assigned PositionRow' + (index + 1),
          type: 'Text',
          value: 'Assignment ' + pwc.assignment,
        });
        pwcData.push({
          key: 'Name AlphabetizedRow' + (index + 1),
          type: 'Text',
          value: pwc.channel_name,
        });
        pwcData.push({
          key: 'Methods of Contact phone pager cell etcRow' + (index + 1),
          type: 'Text',
          value: pwc.phone,
        });
      });
      updateFormState('Basic Local Communications Information', pwcData);
    }
  }, [planningWorksheet]);

  return (
    <>
      <div className="mb-4">
        <h4>1. Incident Name</h4>
        <label className="form-label">Name</label>
        <p
          className="mb-3"
        >
          {incidentName}
        </p>
      </div>
      <hr className="dashed my-4" />

      <div className="mb-4">
        <h4>2. Operational Period</h4>
          {!!reduxCurrentIncident && reduxCurrentIncident?.current_period && (
            <>
              <div className="form-group">
                <label className="form-label">From:</label>
              </div>
              <div className="mb-3">
                {moment(reduxCurrentIncident?.operational_period_start_date_time).format('MM/DD/YYYY HH:mm')}
              </div>
              <div className="form-group">
                <label className="form-label">To:</label>
              </div> 
              <div className="mb-3">
                {moment(reduxCurrentIncident?.operational_period_start_date_time)
                  .add( 
                    reduxCurrentIncident?.operational_period_interval,
                    'hours'
                  )
                  .format('MM/DD/YYYY HH:mm')}
              </div>
            </>
          )} 
      </div>
      <hr className="dashed my-4" />

      <div className="mb-4">
        <h4>3. Basic Local Communications Information</h4>
        <StylishNewTable
          keyField={'id'}
          rows={
            planningWorksheet?.communications?.map((pwc) => ({
              id: pwc.id,
              incident_assigned_position: 'Assignment ' + pwc.assignment,
              name: pwc.channel_name,
              method: pwc.phone,
            })) || []
          }
          columns={basicLocalCommunicationsInformationColumns}
        />
      </div>
      <hr className="dashed my-4" />

      <div className="mb-4">
        <h4 className="mb-1">4. Prepared by: (Communications Unit)</h4>
        <div className="mb-3">
          <label className="form-label">Name</label>
          <StylishNewInput value={reduxUser?.user_name} readOnly={true} />
        </div>
        <div className="mb-4">
          <StylishDateTimePicker
            value={moment()}
            type="datetime-local"
            onChange={(date) => date}
            disabled={true}
            dateLabel={'Date'}
            timeLabel={'Time'}
          />
        </div>
        <div className="m-0">
          <label className="form-label">
            Signature: <span className="color-danger">*</span>
          </label>
          {/* <img src={reduxSignatures[0]?.signature_image} /> */}
          <p className="fst-italic weight-500 m-0">
            Signatures Are Used To Electronically Sign Forms
          </p>
        </div>
      </div>
      <hr className="dashed my-4" />

      {isEditOperationalPeriodOpen && (
        <EditOperationalPeriodDialog
          show={isEditOperationalPeriodOpen}
          onClose={() => setIsEditOperationalPeriodOpen(false)}
        />
      )}
    </>
  );
};
