import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import { updateMedicalAidStation } from 'actions/IAPForm206Actions';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { inputStringLength, contactStringLength } from 'assets/data/config';

export const AddMedicalAidStationDialog = ({
  show,
  onClose,
  refreshMedicalAidStationList,
  medicalAidStationDialogType,
  editMedicalAidStationData,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const reduxDispatch = useDispatch();

  useEffect(() => {
    setValue('paramedics_on_site', false);
  }, []);

  useEffect(() => {
    if (editMedicalAidStationData?.id) {
      setValue(
        'name',
        !!editMedicalAidStationData.name &&
          editMedicalAidStationData.name.trim()
      );
      setValue(
        'address',
        !!editMedicalAidStationData.address &&
          editMedicalAidStationData.address.trim()
      );
      setValue(
        'contact',
        !!editMedicalAidStationData.contact &&
          editMedicalAidStationData.contact.trim()
      );
      setValue(
        'paramedics_on_site',
        editMedicalAidStationData.paramedics_on_site
      );
    }
  }, [editMedicalAidStationData]);

  const createNewMedicalAidStation = (medicalAidStationData) => {
    reduxDispatch(
      updateMedicalAidStation({
        id: editMedicalAidStationData?.id || generateUUID(),
        ...medicalAidStationData,
      })
    );
    refreshMedicalAidStationList();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <form onSubmit={handleSubmit(createNewMedicalAidStation)}>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>
              {`${
                medicalAidStationDialogType === 'New'
                  ? 'Add New'
                  : medicalAidStationDialogType === 'Edit'
                  ? 'Edit'
                  : null
              } Medical Aid Station`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <Controller
                control={control}
                name="name"
                rules={{ required: true, maxLength: inputStringLength }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.name?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.name?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {inputStringLength} character allowed
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Address</label>
              <Controller
                control={control}
                name="address"
                rules={{ required: true, maxLength: inputStringLength }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.address?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.address?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {inputStringLength} character allowed
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Contact</label>
              <Controller
                control={control}
                name="contact"
                rules={{ required: true, maxLength: contactStringLength }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.contact?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.contact?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {contactStringLength} character allowed
                </span>
              )}
            </div>

            <div className="mt-4">
              <Controller
                control={control}
                name="paramedics_on_site"
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewCheckbox
                    onClick={onChange}
                    checked={value}
                    label={'Paramedics on Site'}
                  />
                )}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton className="button--primary" type="submit">
                Save
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary button--reverse"
                onClick={onClose}
              >
                Cancel
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
