import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';

const timeOptions = (() => {
  const newTimeOptions = [];
  const format = 'HH:mm';

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 30) {
      const time = moment({ hour: i, minute: j });
      newTimeOptions.push({
        label: time.format(format),
        value: time.format('HH:mm'),
      });
    }
  }

  return newTimeOptions;
})();
const Input = (props) => <components.Input {...props} isHidden={false} />;

export default function StylishTimePicker({
  value,
  disableDateTimeLabel,
  timeLabel,
  ...props
}) {
  const [time, setTime] = useState();
  const [localTimeOptions, setLocalTimeOptions] = useState([]);
  const user = useSelector((state) => state.prepare.user);
  const selectRef = useRef();
  const [inputValue, setInputValue] = useState('00:00');

  useEffect(() => {
    if (user.timeFormat === 'hour12Mode') {
      setLocalTimeOptions(
        timeOptions.map((option) => ({
          ...option,
          label: moment(option.value, 'HH:mm').format('h:mm A'),
        }))
      );
    } else {
      setLocalTimeOptions(timeOptions);
    }
  }, []);

  useEffect(() => {
    if (value) {
      setTime(value);
      setInputValue(value);
      if (typeof onBlur === 'function') {
        props.onBlur();
      }
    }
  }, [value]);

  const formatOptionLabel = ({ label }, context) => {
    if (context.context === 'menu') {
      return (
        <div>
          <span className="material-symbols-outlined icon">
            nest_clock_farsight_analog
          </span>
          <div className="label">{label}</div>
        </div>
      );
    } else {
      return <div className="label">{label}</div>;
    }
  };

  const checkIfValid = (inputValue) => {
    return user.timeFormat === 'hour12Mode'
      ? moment(inputValue, 'h:mm A', true).isValid() ||
          moment(inputValue, 'h:mmA', true).isValid() ||
          moment(inputValue, 'hmmA', true).isValid() ||
          moment(inputValue, 'hmm A', true).isValid()
      : moment(inputValue, 'HH:mm', true).isValid() ||
          moment(inputValue, 'HHmm', true).isValid();
  };

  return (
    <>
      <div className="col-md-6 mt-2 mt-md-0">
        {!disableDateTimeLabel && (
          <label className="form-label">
            {!!timeLabel ? `${timeLabel}:` : 'Time:'}
          </label>
        )}
        <div className={'form-selectbox time-select'}>
          <CreatableSelect
            ref={selectRef}
            onInputChange={(inputValue, { action }) => {
              if (action === 'input-change') {
                setInputValue(inputValue);
              }
            }}
            inputValue={inputValue}
            components={{
              Input,
            }}
            className="react-select-container"
            classNamePrefix="react-select"
            options={localTimeOptions}
            openMenuOnClick={false}
            onBlur={() => {
              if (inputValue?.length === 0) {
                user.timeFormat === 'hour12Mode'
                  ? setInputValue('12:00 AM')
                  : setInputValue('00:00');
                user.timeFormat === 'hour12Mode'
                  ? setTime('12:00 AM')
                  : setTime('00:00');
              } else if (checkIfValid(inputValue)) {
                const time24 =
                  user.timeFormat === 'hour12Mode'
                    ? moment(inputValue, 'HH:mm A').format('h:mm A')
                    : moment(inputValue, 'HH:mm').format('HH:mm');
                setTime(time24);
                setInputValue(time24);
              } else {
                //not valid, return to original
                setInputValue(time);
              }
            }}
            value={
              inputValue?.length < 1
                ? null
                : {
                    value: time,
                    label:
                      user.timeFormat === 'hour12Mode'
                        ? moment(time, 'h:mm A').format('h:mm A')
                        : moment(time, 'HH:mm').format('HH:mm'),
                  }
            }
            onKeyDown={(e) => {
              //limit the input
              if (user.timeFormat === 'hour12Mode') {
                if (
                  !/^[\d:AMPamp\s]+$/.test(e.key) &&
                  e.key !== 'Backspace' &&
                  e.key !== 'ArrowLeft' &&
                  e.key !== 'ArrowRight'
                ) {
                  e.preventDefault();
                }
              } else {
                if (
                  !/^[\d:]+$/.test(e.key) &&
                  e.key !== 'Backspace' &&
                  e.key !== 'ArrowLeft' &&
                  e.key !== 'ArrowRight'
                ) {
                  e.preventDefault();
                }
              }
              if (e.key === 'Enter') {
                if (inputValue?.length === 0) {
                  user.timeFormat === 'hour12Mode'
                    ? setInputValue('12:00 AM')
                    : setInputValue('00:00');
                  user.timeFormat === 'hour12Mode'
                    ? setTime('12:00 AM')
                    : setTime('00:00');
                  selectRef.current.blur();
                } else if (checkIfValid(inputValue)) {
                  const time24 =
                    user.timeFormat === 'hour12Mode'
                      ? moment(inputValue, 'HH:mm A').format('h:mm A')
                      : moment(inputValue, 'HH:mm').format('HH:mm');
                  setTime(time24);
                  setInputValue(time24);
                  selectRef.current.blur();
                } else {
                  //not valid, return to original
                  setInputValue(time);
                  selectRef.current.blur();
                }
              }
            }}
            onChange={(selected) => {
              const time24 =
                user.timeFormat === 'hour12Mode'
                  ? moment(selected.value, 'HH:mm A').format('h:mm A')
                  : moment(selected.value, 'HH:mm').format('HH:mm');
              setInputValue(time24);
              setTime(time24);
            }}
            placeholder={''}
            filterOption={false}
            menuPlacement="auto"
            noOptionsMessage={() => 'No Items Found'}
            formatOptionLabel={formatOptionLabel}
            isValidNewOption={(inputValue) => {
              return checkIfValid(inputValue);
            }}
          />
        </div>
      </div>
    </>
  );
}
