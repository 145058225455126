import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useActualIncidentId } from './commonHooks';
import { useSelector } from 'react-redux';
import { selectUserGuid } from '../../../slices/commonSelectors';
import config from '../../../constants/apiConfig';
import {
  CreateActionReqData,
  keys as actionKeys,
  useCreateAction,
} from './actionHooks';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../assets/data/config';

export type IncidentAction = {
  id: UUID;
  action_id: UUID;
  group_guid: UUID;
  name: string;
  description: string;
  checklist: { label: string; status: string }[];
  incident_id: UUID;
  user_guid: UUID;
  poc: string;
  briefed_poc: boolean;
  start_date: Date;
  status: string;
  target_date: Date;
  actual_date: Date;
  archived: boolean;
};

// Base hooks keys for react-hooks
export const keys = {
  all: ['incidentActions'],
  list: (incidentId: UUID, userId: UUID) => [...keys.all, incidentId, userId],
};

// Fetch all actions
export const useIncidentActions = () => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.list(incidentId, user_guid),
    queryFn: async () => {
      const resp = await axios.get<IncidentAction[]>(
        `${config.apiGateway.ics_incident_actions}/${incidentId}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
  });
};

export const useCreateIncidentAction = () => {
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  const mutation = useCreateAction();
  return {
    ...mutation,
    mutateAsync: (data: CreateActionReqData) => {
      data.incident_id = incidentId;
      return mutation.mutateAsync(data, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: actionKeys.all });
          queryClient.invalidateQueries({ queryKey: keys.all });
        },
      });
    },
  };
};

// TODO this is most definitely wrong.
type EditIncidentActionReqData = {
  id?: UUID;
  group_guid?: UUID;
  name: string;
  description: string;
  checklist: { label: string; status: string }[];
  incident_id?: UUID;
  user_guid?: UUID;
  poc: UUID;
  briefed_poc: boolean;
  start_date: Date;
  status: string;
  target_date: Date;
  actual_date: Date;
  archived: boolean;
};

export const useUpdateIncidentAction = (actionId: UUID) => {
  const user_guid = useSelector(selectUserGuid);
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: EditIncidentActionReqData) => {
      return await axios.put<EditIncidentActionReqData, IncidentAction>(
        `${config.apiGateway.ics_incident_actions}/${incidentId}/${actionId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: actionKeys.all });
      queryClient.invalidateQueries({ queryKey: keys.all });
    },
    onError: (error) => {
      toast.error(error.message, toastConfig as any);
    },
  });
};
