import { 
  IS_SOCKET_OPEN_SUCCESS,
  SOCKET_ASSET_RISKS_SUCCESS,
  SOCKET_MESSAGE_SUCCESS,
} from 'constants/websocket-action-types'

import {
  ENDPOINT_TO_DELEGATE 
} from 'actions/endpointToDelegate'

export const setIsSocketOpenSuccess = isOpen => ({
  type: IS_SOCKET_OPEN_SUCCESS,
  payload: isOpen,
})

export function setIsSocketOpen(isOpen) {
  return dispatch => {
    dispatch(setIsSocketOpenSuccess(isOpen))
    return isOpen
  }
}

export const socketMessageSuccess = message => ({
  type: SOCKET_MESSAGE_SUCCESS,
  payload: message,
})

export function  setSocketMessage(message) {
  return dispatch => {
    dispatch(socketMessageSuccess(message))
    return message
  }
}

export const setSocketAssetRisksSuccess = assetRisks => ({
  type: SOCKET_ASSET_RISKS_SUCCESS,
  payload: assetRisks,
})

export function  setSocketAssetRisks(assetRisks) {
  return dispatch => {
    dispatch(setSocketAssetRisksSuccess(assetRisks))
    return assetRisks
  }
}

export function handleEndpointUpdate(payload) {
  return dispatch => {
    const delegateAction = ENDPOINT_TO_DELEGATE[payload.endpoint]
    dispatch(delegateAction(payload))
  }
}