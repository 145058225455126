import config from 'constants/apiConfig';
import axios from 'axios';
import {
  GET_IAP_COVER_SHEET_DATA,
  UPSERT_FORM_RECORD_ERROR,
} from 'constants/form-action-types';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

function parseError(api, error) {
  const errorMessage =
    `${api}` +
    'There was an error saving your Form.  No associated Workflows have modified. Please try again.' +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPSERT_FORM_RECORD_ERROR,
  };
}
export const upsertIapCoverSheet = (iapCoverSheet) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.upsertIapCoverSheetToOperationalPeriod, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        operational_period_id: getState().app.currentIncident.current_period,
        iapCoverSheet,
      }),
    })
      .then(() => {
        dispatch(getIapCoverSheet());
        // toast.success(
        //   'Upsert IAP Cover Sheet to Operational Period successful',
        //   {
        //     autoClose: 2000,
        //   }
        // );
      })
      .catch((error) => {
        dispatch(
          parseError(
            config.apiGateway.upsertIapCoverSheetToOperationalPeriod,
            error
          )
        );
        dispatch(endLoading());
      });
  };
};

export const getIapCoverSheet = () => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.getIapCoverSheet, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        operational_period_id: getState().app.currentIncident.current_period,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_IAP_COVER_SHEET_DATA, payload: response.data });
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.getIapCoverSheet, error));
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

export const upsertIapCoverSheetAttachment = (attachment) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.upsertIapCoverSheetAttachment, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        iapCoverSheet: {
          ...getState().app.iapCoverSheet,
          id: getState().app.iapCoverSheet?.id || generateUUID(),
          operational_period_id: getState().app.currentIncident.current_period,
        },
        attachments: attachment,
      }),
    })
      .then(() => {
        dispatch(getIapCoverSheet());
        // toast.success('Upsert IAP Cover Sheet Attachment successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.upsertIapCoverSheetAttachment, error)
        );
        dispatch(endLoading());
      });
  };
};

export const deleteIapCoverSheetAttachment = (attachment_id) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.deleteIapCoverSheetAttachment, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        attachment_id,
      }),
    })
      .then(() => {
        dispatch(getIapCoverSheet());
        // toast.success('Delete IAP Cover Sheet Attachment successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.deleteIapCoverSheetAttachment, error)
        );
        dispatch(endLoading());
      });
  };
};

export const upsertIapCoverSheetAttachmentApprovedByIC = (
  incident_commander_id,
  iap_cover_sheet_id
) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.upsertIapCoverSheetApprovedByIC, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        incident_id: getState().app.currentIncident.id,
        incident_commander_id,
        iap_cover_sheet_id,
      }),
    })
      .then(() => {
        dispatch(getIapCoverSheet());
        // toast.success('Upsert IAP Cover Sheet Approved by IC successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.upsertIapCoverSheetApprovedByIC, error)
        );
        dispatch(endLoading());
      });
  };
};

export const deleteIapCoverSheetAttachmentApprovedByIC = (
  incident_commander_id,
  iap_cover_sheet_id
) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.deleteIapCoverSheetApprovedByIC, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        incident_commander_id,
        iap_cover_sheet_id,
      }),
    })
      .then(() => {
        dispatch(getIapCoverSheet());
        // toast.success('Delete IAP Cover Sheet Approved by IC successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.deleteIapCoverSheetApprovedByIC, error)
        );
        dispatch(endLoading());
      });
  };
};

export const upsertIapChecklistAttachment = (attachment) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.upsertIapChecklistAttachment, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        iap_cover_sheet: {
          ...getState().app.iapCoverSheet,
          id: getState().app.iapCoverSheet?.id || generateUUID(),
          operational_period_id: getState().app.currentIncident.current_period,
        },
        iap_checklist_attachment: attachment,
      }),
    })
      .then(() => {
        dispatch(getIapCoverSheet());
        // toast.success('Upsert IAP Checklist Attachment successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.upsertIapChecklistAttachment, error)
        );
        dispatch(endLoading());
      });
  };
};

export const deleteIapChecklistAttachment = (attachment_id) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.deleteIapChecklistAttachment, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        iap_checklist_attachment_id: attachment_id,
      }),
    })
      .then(() => {
        dispatch(getIapCoverSheet());
        // toast.success('Delete IAP Checklist Attachment successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.deleteIapChecklistAttachment, error)
        );
        dispatch(endLoading());
      });
  };
};
