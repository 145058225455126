import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewICSMedicalAidStationDialog from 'components/ICS/NewICSMedicalAidStationDialog';

import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

import ICSIncidentMedicalAidStation from './ICSIncidentMedicalAidStation';

import { updateIcsMedicalAidStation } from 'slices/ics_medicalAidStationsSlice';

import {
  createIcsIncidentMedicalAidStation,
  updateIcsIncidentMedicalAidStation,
} from 'slices/ics_incidentMedicalAidStationsSlice';
import ExpandRightArrow from '../DesignSystems/buttons/ExpandRightArrow';
import ExpandDownArrow from '../DesignSystems/buttons/ExpandDownArrow';

export default function MedicalAidStationsDialog({
  show,
  onClose,
  incident_id = null,
  ics_medical_aid_stations,
  ics_incident_medical_aid_stations
}) {
  const reduxDispatch = useDispatch();

  const [isNewICSMedicalAidStationDialogOpen, setIsNewICSMedicalAidStationDialogOpen] = useState(
    false
  );
  const [modalType, setModalType] = useState('New');
  const [editMedicalAidStationData, setEditMedicalAidStationData] = useState();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [archiveConfirmationData, setArchiveConfirmationData] = useState(null);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const [dialogMedicalAidStations, setDialogMedicalAidStations] = useState([
    ...ics_incident_medical_aid_stations,
  ]);

  useEffect(() => {
    setDialogMedicalAidStations(ics_incident_medical_aid_stations);
  }, [ics_incident_medical_aid_stations]);

  useEffect(() => {
    // Use to rerender
  }, [ics_medical_aid_stations]);

  const allColumns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      attrs: { title: 'Name' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      attrs: { title: 'Description' },
    },
    {
      dataField: 'location',
      text: 'Location',
      sort: true,
      attrs: { title: 'Location' },
    },
    {
      dataField: 'contact_number',
      text: 'Contact Number',
      sort: true,
      attrs: { title: 'Contact Number' },
    },
    {
      dataField: 'paramedics_on_site',
      text: 'Paramedics On Site',
      sort: true,
      attrs: { title: 'Paramedics On Site' },
      formatter: (cell) => (cell ? 'Yes' : 'No'),
    },
    {
      dataField: 'user_name',
      text: 'Last Modified By',
      sort: true,
      attrs: { title: 'Last Modified By' },
    },
    {
      dataField: 'archived',
      text: 'Archived',
      sort: true,
      attrs: { title: 'Archived' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <ICSIncidentMedicalAidStation
            row={row}
            ics_medical_aid_stations={ics_medical_aid_stations}
            ics_incident_medical_aid_stations={ics_incident_medical_aid_stations}
          />
          {(!incident_id ||
            !!ics_incident_medical_aid_stations.find(
              (mas) => mas.medical_aid_station_id === row.id
            )) && (
            <div className="button-group">
              <StylishNewButton
                className="button--primary"
                onClick={() => onEdit(row)}
              >
                Edit
              </StylishNewButton>
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={() => onArchive(row)}
              >
                {row.archived ? `Unarchive` : `Archive`}
              </StylishNewButton>
            </div>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? <ExpandDownArrow /> : <ExpandRightArrow />,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <span className="sr-only">Details</span>
            <ExpandDownArrow />
          </>
        );
      }
      return (
        <>
          <span className="sr-only">Details</span>
          <ExpandRightArrow />
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    selected: ics_medical_aid_stations
      .filter((mas) => {
        const incMas = ics_incident_medical_aid_stations.find(
          (imas) => imas.medical_aid_station_id === mas.id && !imas.archived
        );
        return !!incMas;
      })
      .map((mas) => mas.id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex) => {
      const existingIncidentMedicalAidStation = ics_incident_medical_aid_stations.find(
        (imas) => imas.medical_aid_station_id === row.id
      );
      if (existingIncidentMedicalAidStation) {
        reduxDispatch(
          updateIcsIncidentMedicalAidStation(
            incident_id,
            existingIncidentMedicalAidStation.id,
            {
              ...existingIncidentMedicalAidStation,
              archived: !existingIncidentMedicalAidStation.archived,
            }
          )
        );
      } else {
        // Create a new incident medical aid station
        const incidentMedicalAidStationData = {
          medical_aid_station_id: row.id,
          name: row.name,
          description: row.description,
          location: row.location,
          contact_number: row.contact_number,
          paramedics_on_site: row.paramedics_on_site,
          user_guid: row.user_guid,
          incident_id: incident_id,
          group_guid: reduxCurrentlySelectedGroup.group_guid,
          archived: false,
        };
        reduxDispatch(
          createIcsIncidentMedicalAidStation(incident_id, incidentMedicalAidStationData)
        );
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setDialogMedicalAidStations(rows) : setDialogMedicalAidStations([]);
    },
  };

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewICSMedicalAidStationDialogOpen(true);
    setEditMedicalAidStationData(row);
  };

  const onArchive = (row) => {
    setShowConfirmDialog(true);
    setArchiveConfirmationData(row);
  };

  const onConfirmedArchiveActionData = () => {
    const payload = {
      ...archiveConfirmationData,
      incident_id,
      archived: !archiveConfirmationData.archived,
    };

    if (!!incident_id) {
      reduxDispatch(
        updateIcsIncidentMedicalAidStation(
          incident_id,
          archiveConfirmationData.id,
          payload
        )
      );
    } else {
      reduxDispatch(updateIcsMedicalAidStation(archiveConfirmationData.id, payload));
    }

    setShowConfirmDialog(false);
    setArchiveConfirmationData(null);
  };

  const onNewMedicalAidStation = () => {
    setModalType('New');
    setIsNewICSMedicalAidStationDialogOpen(true);
  };

  const tableRows = ics_medical_aid_stations.map((mas) => {
    const incMas = ics_incident_medical_aid_stations.find(
      (imas) => imas.medical_aid_station_id === mas.id
    );
    if (incMas) {
      return {
        ...incMas,
        id: mas.id,
      };
    } else {
      return mas;
    }
  });

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Select Incident Medical Aid Stations</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">
          {reduxCurrentlySelectedGroup?.group_name}
        </p>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={onNewMedicalAidStation}
        >
          New Medical Aid Station
        </StylishNewButton>
        <div>
          <label>
            {!!incident_id && (
              <>
                Select to add to incident. De-select to archive. Re-select to
                un-archive.
              </>
            )}
          </label>
        </div>
        <StylishNewTable
          keyField={'id'}
          rows={tableRows}
          columns={allColumns}
          expandRow={expandRow}
          selectRow={(!!incident_id && selectRow) || undefined}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewICSMedicalAidStationDialogOpen && (
        <NewICSMedicalAidStationDialog
          show={isNewICSMedicalAidStationDialogOpen}
          setEditMedicalAidStationData={setEditMedicalAidStationData}
          setModalType={setModalType}
          editMedicalAidStationData={editMedicalAidStationData}
          modalType={modalType}
          onClose={() => setIsNewICSMedicalAidStationDialogOpen(false)}
          incident_id={incident_id}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Archive Confirmation'}
          dialogContent={'Are you sure you want to archive this Medical Aid Station?'}
          dialogButtonText={'Archive'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedArchiveActionData}
        />
      )}
    </Modal>
  );
}
