import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import NewDistributionListDialog from './NewDistributionListDialog';
import EditDistributionListDialog from './EditDistributionListDialog';
import { useDispatch } from 'react-redux';
import {
  deleteDistributionList,
  editDistributionList,
  createDistributionList,
} from 'actions/notificationActions';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewChip } from 'components/DesignSystems/New/StylishNewChip';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { endLoading, startLoading } from 'reducers/loading/loading.action';

export default function ManageDistributionListsDialog({
  show,
  distributionLists = [],
  selectedDistributionLists = [],
  roster,
  onClose,
  recipientTypes,
}) {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);

  const [selectedLists, setSelectedLists] = useState(
    distributionLists.filter((list) =>
      selectedDistributionLists.includes(list.id)
    )
  );

  const [
    newDistributionListDialogOpen,
    setNewDistributionListDialogOpen,
  ] = useState(false);
  const [
    editDistributionListDialogOpen,
    setEditDistributionListDialogOpen,
  ] = useState();

  const allColumns = [
    {
      dataField: 'name',
      text: 'Relevant Team',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Relevant Team' },
    },
  ];

  useEffect(() => {
    setSelectedLists(
      distributionLists.filter((list) =>
        selectedDistributionLists.includes(list.id)
      )
    );

    reduxDispatch(endLoading());
  }, [distributionLists, selectedDistributionLists]);

  const onAddMembersClick = () => {
    onClose();
  };

  const handleRecipientNameClick = (recipient) => {
    navigate(`/profile/${recipient}`);
  };

  const handleNewDistributionList = (list) => {
    if (list && list.id && list.name) {
      reduxDispatch(createDistributionList(list));
    }

    setNewDistributionListDialogOpen(false);
  };

  const handleEditDistributionList = (list) => {
    if (list && list.id && list.name) {
      reduxDispatch(editDistributionList(list));
    }

    setEditDistributionListDialogOpen(false);
  };

  const handleListDeleteClick = (idToDelete) => {
    setDeleteConfirmationData(idToDelete);
    setShowConfirmDialog(true);
  };

  const onConfirmedDeleteActionData = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteDistributionList(deleteConfirmationData));
    setShowConfirmDialog(false);
    setDeleteConfirmationData(null);
  };

  const handleListEditClick = (list) => {
    setEditDistributionListDialogOpen(list);
  };

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="form-chip-group">
            {row.members
              .filter((member) =>
                roster.some((person) => person.user_guid === member)
              )
              .map((member) => {
                const user = roster.find(
                  (person) => person.user_guid === member
                );
                const name = user.user_name || user.email_address;
                return (
                  <StylishNewChip
                    key={`key-${member}`}
                    label={name}
                    handleClick={() => {
                      handleRecipientNameClick(member);
                    }}
                  />
                );
              })}
            {!!row.nonMembers &&
              row.nonMembers.map((member) => {
                return (
                  <StylishNewChip
                    key={`key-${member.id}`}
                    label={member.content}
                  />
                );
              })}
          </div>
          <hr className="dashed my-4" />
          <div className="button-group">
            <StylishNewButton
              className="button--primary"
              onClick={() => handleListEditClick(row)}
            >
              Edit List
            </StylishNewButton>
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={() => handleListDeleteClick(row.id)}
            >
              Delete List
            </StylishNewButton>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Manage Relevant Team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Select Team</h5>
          <StylishNewTable
            keyField={'id'}
            columns={allColumns}
            rows={distributionLists}
            hasPagination={true}
            paginationPerPageItem="10"
            expandRow={expandRow}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className={'button--primary'}
              onClick={() => setNewDistributionListDialogOpen(true)}
            >
              New Team
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>

      {newDistributionListDialogOpen && (
        <NewDistributionListDialog
          show={newDistributionListDialogOpen}
          roster={roster}
          onClose={(list) => handleNewDistributionList(list)}
          recipientTypes={recipientTypes}
          distributionLists={distributionLists}
        />
      )}

      {editDistributionListDialogOpen && (
        <EditDistributionListDialog
          show={editDistributionListDialogOpen}
          roster={roster}
          onClose={(list) => handleEditDistributionList(list)}
          recipientTypes={[]}
          selectedDistributionList={editDistributionListDialogOpen}
          distributionLists={distributionLists}
          teamLocation={editDistributionListDialogOpen.teamLocation}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={
            'Are you sure you want to delete this distribution list?'
          }
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedDeleteActionData}
        />
      )}
    </>
  );
}
