export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST' 
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR'
export const UPSERT_NOTIFICATIONS_REQUEST = 'UPSERT_NOTIFICATIONS_REQUEST'
export const UPSERT_NOTIFICATIONS_SUCCESS = 'UPSERT_NOTIFICATIONS_SUCCESS'
export const UPSERT_NOTIFICATIONS_ERROR = 'UPSERT_NOTIFICATIONS_ERROR'
export const DELETE_NOTIFICATIONS_REQUEST = 'DELETE_NOTIFICATIONS_REQUEST'
export const DELETE_NOTIFICATIONS_SUCCESS = 'DELETE_NOTIFICATIONS_SUCCESS'
export const DELETE_NOTIFICATIONS_ERROR = 'DELETE_NOTIFICATIONS_ERROR'
export const GET_ROSTER_FOR_INCIDENT_REQUEST = 'GET_ROSTER_FOR_INCIDENT_REQUEST';
export const GET_ROSTER_FOR_INCIDENT_SUCCESS = 'GET_ROSTER_FOR_INCIDENT_SUCCESS';
export const GET_ROSTER_FOR_INCIDENT_ERROR = 'GET_ROSTER_FOR_INCIDENT_ERROR';
export const ADD_USER_TO_INCIDENT_BY_ADMIN_REQUEST = 'ADD_USER_TO_INCIDENT_BY_ADMIN_REQUEST';
export const ADD_USER_TO_INCIDENT_BY_ADMIN_SUCCESS = 'ADD_USER_TO_INCIDENT_BY_ADMIN_SUCCESS';
export const ADD_USER_TO_INCIDENT_BY_ADMIN_ERROR = 'ADD_USER_TO_INCIDENT_BY_ADMIN_ERROR';
export const EDIT_INCIDENT_MEMBER_BY_ADMIN_REQUEST = 'EDIT_INCIDENT_MEMBER_BY_ADMIN_REQUEST';
export const EDIT_INCIDENT_MEMBER_BY_ADMIN_SUCCESS = 'EDIT_INCIDENT_MEMBER_BY_ADMIN_SUCCESS';
export const EDIT_INCIDENT_MEMBER_BY_ADMIN_ERROR = 'EDIT_INCIDENT_MEMBER_BY_ADMIN_ERROR';
export const REMOVE_USER_FROM_INCIDENT_BY_ADMIN_REQUEST = 'REMOVE_USER_FROM_INCIDENT_BY_ADMIN_REQUEST';
export const REMOVE_USER_FROM_INCIDENT_BY_ADMIN_SUCCESS = 'REMOVE_USER_FROM_INCIDENT_BY_ADMIN_SUCCESS';
export const REMOVE_USER_FROM_INCIDENT_BY_ADMIN_ERROR = 'REMOVE_USER_FROM_INCIDENT_BY_ADMIN_ERROR';
export const REMOVE_SELF_FROM_INCIDENT_REQUEST = 'REMOVE_SELF_FROM_INCIDENT_REQUEST';
export const REMOVE_SELF_FROM_INCIDENT_SUCCESS = 'REMOVE_SELF_FROM_INCIDENT_SUCCESS';
export const REMOVE_SELF_FROM_INCIDENT_ERROR = 'REMOVE_SELF_FROM_INCIDENT_ERROR';
export const RESPOND_TO_REQUEST_TO_JOIN_INCIDENT_REQUEST = 'RESPOND_TO_REQUEST_TO_JOIN_INCIDENT_REQUEST';
export const RESPOND_TO_REQUEST_TO_JOIN_INCIDENT_SUCCESS = 'RESPOND_TO_REQUEST_TO_JOIN_INCIDENT_SUCCESS';
export const RESPOND_TO_REQUEST_TO_JOIN_INCIDENT_ERROR = 'RESPOND_TO_REQUEST_TO_JOIN_INCIDENT_ERROR';
export const GET_ROSTER_REQUEST = 'GET_ROSTER_REQUEST'
export const GET_ROSTER_SUCCESS = 'GET_ROSTER_SUCCESS'
export const GET_ROSTER_ERROR = 'GET_ROSTER_ERROR'
export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST'
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS'
export const GET_DASHBOARD_ERROR = 'GET_DASHBOARD_ERROR'
export const ADD_USER_TO_INCIDENT_SUCCESS = 'ADD_USER_TO_INCIDENT_SUCCESS'
export const GET_INCIDENT_LOGS = 'GET_INCIDENT_LOGS'
export const ADD_NON_USER_TO_INCIDENT_BY_ADMIN_REQUEST = 'ADD_NON_USER_TO_INCIDENT_BY_ADMIN_REQUEST';
export const ADD_NON_USER_TO_INCIDENT_SUCCESS = 'ADD_NON_USER_TO_INCIDENT_SUCCESS';
export const DEACTIVATE_USER_TO_INCIDENT_REQUEST = 'DEACTIVATE_USER_TO_INCIDENT_REQUEST';
export const DEACTIVATE_USER_TO_INCIDENT_SUCCESS = 'DEACTIVATE_USER_TO_INCIDENT_SUCCESS';
export const UPDATE_INCIDENT_LOGS_WITH_WORKLOG = 'UPDATE_INCIDENT_LOGS_WITH_WORKLOG';
export const GET_ALL_INCIDENT_OBJECTIVES = 'GET_ALL_INCIDENT_OBJECTIVES';
export const GET_ALL_INCIDENT_OBJECTIVES_REQUEST = 'GET_ALL_INCIDENT_OBJECTIVES_REQUEST';
export const GET_ALL_INCIDENT_OBJECTIVES_SUCCESS = 'GET_ALL_INCIDENT_OBJECTIVES_SUCCESS';
export const ADD_INCIDENT_OBJECTIVES_REQUEST = 'ADD_INCIDENT_OBJECTIVES_REQUEST';
export const ADD_INCIDENT_OBJECTIVES_SUCCESS = 'ADD_INCIDENT_OBJECTIVES_SUCCESS';
export const GET_ALL_INCIDENT_RESOURCES = 'GET_ALL_INCIDENT_RESOURCES';
export const GET_ALL_INCIDENT_RESOURCES_REQUEST = 'GET_ALL_INCIDENT_RESOURCES_REQUEST';
export const GET_ALL_INCIDENT_RESOURCES_SUCCESS = 'GET_ALL_INCIDENT_RESOURCES_SUCCESS';
export const ADD_INCIDENT_RESOURCES_REQUEST = 'ADD_INCIDENT_RESOURCES_REQUEST';
export const ADD_INCIDENT_RESOURCES_SUCCESS = 'ADD_INCIDENT_RESOURCES_SUCCESS';
export const GET_ALL_GROUP_RESOURCES = 'GET_ALL_GROUP_RESOURCES';
export const GET_ALL_GROUP_RESOURCES_REQUEST = 'GET_ALL_INCIDENT_RESOURCES_REQUEST';
export const GET_ALL_GROUP_OBJECTIVES = 'GET_ALL_GROUP_OBJECTIVES';
export const GET_ALL_GROUP_OBJECTIVES_REQUEST = 'GET_ALL_INCIDENT_OBJECTIVES_REQUEST';
export const SELF_ACTIVATION_REQUEST = 'SELF_ACTIVATION_REQUEST';
export const SELF_ACTIVATION_SUCCESS = 'SELF_ACTIVATION_SUCCESS';
export const ADD_SCHEDULE_TO_INCIDENT_REQUEST = 'ADD_SCHEDULE_TO_INCIDENT_REQUEST';
export const ADD_SCHEDULE_TO_INCIDENT_SUCCESS = 'ADD_SCHEDULE_TO_INCIDENT_SUCCESS';
export const GET_ALL_SCHEDULE_TO_INCIDENT_REQUEST = 'GET_ALL_SCHEDULE_TO_INCIDENT_REQUEST';
export const GET_ALL_SCHEDULE_TO_INCIDENT_SUCCESS = 'GET_ALL_SCHEDULE_TO_INCIDENT_SUCCESS';
export const GET_ALL_SCHEDULE_TO_INCIDENT = 'GET_ALL_SCHEDULE_TO_INCIDENT';
export const GET_USER_SCHEDULE_TO_INCIDENT = 'GET_USER_SCHEDULE_TO_INCIDENT';
export const UPSERT_OPERATIONAL_PERIOD_REQUEST = 'UPSERT_OPERATIONAL_PERIOD_REQUEST';
export const UPSERT_OPERATIONAL_PERIOD_SUCCESS = 'UPSERT_OPERATIONAL_PERIOD_SUCCESS';
export const UPDATE_INCIDENT_MEMBER_WORK_LOG_REQUEST = 'UPDATE_INCIDENT_MEMBER_WORK_LOG_REQUEST';
export const UPDATE_INCIDENT_MEMBER_WORK_LOG_SUCCESS = 'UPDATE_INCIDENT_MEMBER_WORK_LOG_SUCCESS';
export const SET_INCIDENT_ACTIVE_STATUS_REQUEST = 'SET_INCIDENT_ACTIVE_STATUS_REQUEST';
export const SET_INCIDENT_ACTIVE_STATUS_SUCCESS = 'SET_INCIDENT_ACTIVE_STATUS_SUCCESS';
export const SET_INCIDENT_ACTIVE_STATUS_ERROR = 'SET_INCIDENT_ACTIVE_STATUS_ERROR';
export const UPDATE_INCIDENT_OBJECTIVES_SELECTION_REQUEST = 'UPDATE_INCIDENT_OBJECTIVES_SELECTION_REQUEST';
export const UPDATE_INCIDENT_OBJECTIVES_SELECTION_SUCCESS = 'UPDATE_INCIDENT_OBJECTIVES_SELECTION_SUCCESS';
export const UPDATE_INCIDENT_RESOURCES_SELECTION_REQUEST = 'UPDATE_INCIDENT_RESOURCES_SELECTION_REQUEST'; 
export const UPDATE_INCIDENT_RESOURCES_SELECTION_SUCCESS = 'UPDATE_INCIDENT_RESOURCES_SELECTION_SUCCESS';

export const BULK_ADD_OBJECTIVES_REQUEST = 'BULK_ADD_OBJECTIVES_REQUEST'
export const BULK_ADD_OBJECTIVES_SUCCESS = 'BULK_ADD_OBJECTIVES_SUCCESS'
export const BULK_ADD_OBJECTIVES_ERROR = 'BULK_ADD_OBJECTIVES_ERROR'

export const BULK_ADD_ACTIONS_REQUEST = 'BULK_ADD_ACTIONS_REQUEST'
export const BULK_ADD_ACTIONS_SUCCESS = 'BULK_ADD_ACTIONS_SUCCESS'
export const BULK_ADD_ACTIONS_ERROR = 'BULK_ADD_ACTIONS_ERROR'

export const UPDATE_INCIDENT_ACTION_SELECTION_REQUEST = 'UPDATE_INCIDENT_ACTION_SELECTION_REQUEST'
export const UPDATE_INCIDENT_ACTION_SELECTION_SUCCESS = 'UPDATE_INCIDENT_ACTION_SELECTION_SUCCESS'
export const UPDATE_INCIDENT_ACTION_SELECTION_ERROR = 'UPDATE_INCIDENT_ACTION_SELECTION_ERROR'

export const BULK_ADD_RESOURCES_REQUEST = 'BULK_ADD_RESOURCES_REQUEST'
export const BULK_ADD_RESOURCES_SUCCESS = 'BULK_ADD_RESOURCES_SUCCESS'
export const BULK_ADD_RESOURCES_ERROR = 'BULK_ADD_RESOURCES_ERROR'

