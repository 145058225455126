import { LOADING_REQUEST, LOADING_SUCCESS } from './loading.constants';

export const startLoading = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_REQUEST });
  };
};

export const endLoading = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_SUCCESS });
  };
};
