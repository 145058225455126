export const FETCH_PODS_REQUEST = 'FETCH_POD_REQUEST';
export const FETCH_PODS_SUCCESS = 'FETCH_POD_SUCCESS';
export const FETCH_PODS_ERROR = 'FETCH_PODS_ERROR';
export const CREATE_POD_REQUEST = 'CREATE_POD_REQUEST';
export const CREATE_POD_SUCCESS = 'CREATE_POD_SUCCESS';
export const CREATE_POD_ERROR = 'CREATE_POD_ERROR';
export const EDIT_POD_REQUEST = 'EDIT_POD_REQUEST';
export const EDIT_POD_SUCCESS = 'EDIT_POD_SUCCESS';
export const EDIT_POD_ERROR = 'EDIT_POD_ERROR';
export const DELETE_POD_REQUEST = 'DELETE_POD_REQUEST';
export const DELETE_POD_SUCCESS = 'DELETE_POD_SUCCESS';
export const DELETE_POD_ERROR = 'DELETE_POD_ERROR';
export const FETCH_POD_NTKL_REQUEST = 'FETCH_POD_NTKL_REQUEST';
export const FETCH_POD_NTKL_SUCCESS = 'FETCH_POD_NTKL_SUCCESS';
export const FETCH_POD_NTKL_ERROR = 'FETCH_POD_NTKL_ERROR';
export const ADD_TO_POD_NTKL_REQUEST = 'ADD_TO_POD_NTKL_REQUEST';
export const ADD_TO_POD_NTKL_SUCCESS = 'ADD_TO_POD_NTKL_SUCCESS';
export const ADD_TO_POD_NTKL_ERROR = 'ADD_TO_POD_NTKL_ERROR';
export const REMOVE_FROM_POD_NTKL_REQUEST = 'REMOVE_FROM_POD_NTKL_REQUEST';
export const REMOVE_FROM_POD_NTKL_SUCCESS = 'REMOVE_FROM_POD_NTKL_SUCCESS';
export const REMOVE_FROM_POD_NTKL_ERROR = 'REMOVE_FROM_POD_NTKL_ERROR';
export const FETCH_PODS_DATASETS_REQUEST = 'FETCH_PODS_DATASETS_REQUEST';
export const FETCH_PODS_DATASETS_SUCCESS = 'FETCH_PODS_DATASETS_SUCCESS';
export const FETCH_PODS_DATASETS_ERROR = 'FETCH_PODS_DATASETS_ERROR';
export const CREATE_POD_DATA_REQUEST = 'CREATE_POD_DATA_REQUEST';
export const CREATE_POD_DATA_SUCCESS = 'CREATE_POD_DATA_SUCCESS';
export const CREATE_POD_DATA_ERROR = 'CREATE_POD_DATA_ERROR';
export const EDIT_POD_DATA_REQUEST = 'EDIT_POD_DATA_REQUEST';
export const EDIT_POD_DATA_SUCCESS = 'EDIT_POD_DATA_SUCCESS';
export const EDIT_POD_DATA_ERROR = 'EDIT_POD_DATA_ERROR';
export const DELETE_POD_DATA_REQUEST = 'DELETE_POD_DATA_REQUEST';
export const DELETE_POD_DATA_SUCCESS = 'DELETE_POD_DATA_SUCCESS';
export const DELETE_POD_DATA_ERROR = 'DELETE_POD_DATA_ERROR';
export const FETCH_POD_DATASET_FOR_DASHBOARD_REQUEST = 'FETCH_POD_DATASET_FOR_DASHBOARD_REQUEST';
export const FETCH_POD_DATASET_FOR_DASHBOARD_SUCCESS = 'FETCH_POD_DATASET_FOR_DASHBOARD_SUCCESS';
export const FETCH_POD_DATASET_FOR_DASHBOARD_ERROR = 'FETCH_POD_DATASET_FOR_DASHBOARD_ERROR';
export const FETCH_LIVESTREAM_DATA_JSON_REQUEST = 'FETCH_LIVESTREAM_DATA_JSON_REQUEST';
export const FETCH_LIVESTREAM_DATA_JSON_SUCCESS = 'FETCH_LIVESTREAM_DATA_JSON_SUCCESS'; 
export const FETCH_LIVESTREAM_DATA_JSON_ERROR = 'FETCH_LIVESTREAM_DATA_JSON_ERROR';