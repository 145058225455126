export const FETCH_TASK_LIST_REQUEST = 'FETCH_TASK_LIST_REQUEST';
export const FETCH_TASK_LIST_SUCCESS = 'FETCH_TASK_LIST_SUCCESS';
export const FETCH_TASK_LIST_ERROR = 'FETCH_TASK_LIST_ERROR';
export const SAVE_TASK_DATA_REQUEST = 'SAVE_TASK_DATA_REQUEST';
export const SAVE_TASK_DATA_SUCCESS = 'SAVE_TASK_DATA_SUCCESS';
export const SAVE_TASK_DATA_ERROR = 'SAVE_TASK_DATA_ERROR';
export const ADD_TASK_COMMENT_REQUEST = 'ADD_TASK_COMMENT_REQUEST';
export const ADD_TASK_COMMENT_SUCCESS = 'ADD_TASK_COMMENT_SUCCESS';
export const ADD_TASK_COMMENT_ERROR = 'ADD_TASK_COMMENT_SUCCESS';
export const GET_ALL_TASK_COMMENT = 'GET_ALL_TASK_COMMENT';
export const GET_ALL_TASK_COMMENT_REQUEST = 'GET_ALL_TASK_COMMENT_REQUEST';
export const GET_ALL_TASK_COMMENT_ERROR = 'GET_ALL_TASK_COMMENT_ERROR';
export const GET_ALL_TASK_LOGS = 'GET_ALL_TASK_LOGS';
export const GET_ALL_TASK_LOGS_REQUEST = 'GET_ALL_TASK_LOGS_REQUEST';
export const GET_ALL_TASK_LOGS_ERROR = 'GET_ALL_TASK_LOGS_SUCCESS';
export const GET_ALL_TASK_UNIT = 'GET_ALL_TASK_UNIT';
