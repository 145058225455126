import React, { useEffect, useState } from 'react';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import dayjs from 'dayjs';

export default function ICSIncidentMedicalAidStation({ row }) {
  return (
    <div className="ICSIncidentMedicalAidStation">

      <div className="mb-4">
        <h6 className="mb-2">Name:</h6>
        <p className="m-0">{row.name}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Description:</h6>
        <p className="m-0">{row.description}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Location:</h6>
        <p className="m-0">{row.location}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Contact Number:</h6>
        <p className="m-0">{row.contact_number}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Paramedics On Site:</h6>
        <p className="m-0">{row.paramedics_on_site ? 'Yes' : 'No'}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Last Modified By:</h6>
        <p className="m-0">{row.user_name}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Archived:</h6>
        <p className="m-0">{row.archived ? 'Yes' : 'No'}</p>
      </div>
    </div>
  );
}
