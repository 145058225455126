export const STRIPE_CREATE_SESSION_REQUEST = 'STRIPE_CREATE_SESSION_REQUEST'
export const STRIPE_CREATE_SESSION_SUCCESS = 'STRIPE_CREATE_SESSION_SUCCESS'
export const STRIPE_CREATE_SESSION_ERROR = 'STRIPE_CREATE_SESSION_ERROR'

export const CREATE_STRIPE_SUBSCRIPTION_REQUEST = 'CREATE_STRIPE_SUBSCRIPTION_REQUEST'
export const CREATE_STRIPE_SUBSCRIPTION_SUCCESS = 'CREATE_STRIPE_SUBSCRIPTION_SUCCESS'
export const CREATE_STRIPE_SUBSCRIPTION_ERROR = 'CREATE_STRIPE_SUBSCRIPTION_ERROR'

export const UPDATE_STRIPE_SUBSCRIPTION_STATUS_REQUEST = 'UPDATE_STRIPE_SUBSCRIPTION_STATUS_REQUEST'
export const UPDATE_STRIPE_SUBSCRIPTION_STATUS_SUCCESS = 'UPDATE_STRIPE_SUBSCRIPTION_STATUS_SUCCESS'
export const UPDATE_STRIPE_SUBSCRIPTION_STATUS_ERROR = 'UPDATE_STRIPE_SUBSCRIPTION_STATUS_ERROR'

export const FETCH_ASSIGNABLE_USERS_BY_GROUP_REQUEST = 'FETCH_ASSIGNABLE_USERS_BY_GROUP_REQUEST'
export const FETCH_ASSIGNABLE_USERS_BY_GROUP_SUCCESS = 'FETCH_ASSIGNABLE_USERS_BY_GROUP_SUCCESS'
export const FETCH_ASSIGNABLE_USERS_BY_GROUP_ERROR = 'FETCH_ASSIGNABLE_USERS_BY_GROUP_ERROR'

export const ASSIGN_STRIPE_SEATS_REQUEST = 'ASSIGN_STRIPE_SEATS_REQUEST'
export const ASSIGN_STRIPE_SEATS_SUCCESS = 'ASSIGN_STRIPE_SEATS_SUCCESS'
export const ASSIGN_STRIPE_SEATS_ERROR = 'ASSIGN_STRIPE_SEATS_ERROR'

export const UNASSIGN_STRIPE_SEATS_REQUEST = 'UNASSIGN_STRIPE_SEATS_REQUEST'
export const UNASSIGN_STRIPE_SEATS_SUCCESS = 'UNASSIGN_STRIPE_SEATS_SUCCESS'
export const UNASSIGN_STRIPE_SEATS_ERROR = 'UNASSIGN_STRIPE_SEATS_ERROR'

export const FETCH_USER_SUBSCRIPTION_INFO_REQUEST = 'FETCH_USER_SUBSCRIPTION_INFO_REQUEST'
export const FETCH_USER_SUBSCRIPTION_INFO_SUCCESS = 'FETCH_USER_SUBSCRIPTION_INFO_SUCCESS'
export const FETCH_USER_SUBSCRIPTION_INFO_ERROR = 'FETCH_USER_SUBSCRIPTION_INFO_ERROR'
