export const sampleIconLayer = {
  id: 'icon-layer:-74.69921488496108,42.726213736022686',
  type: 'symbol',
  source: 'icon-source:-74.69921488496108,42.726213736022686', // reference the data source
  layout: {
    'icon-image': 'marker', // reference the image
    "icon-size": ['interpolate', ['linear'], ['zoom'], 5, .125, 10, 0.25, 15, .5]
  },
  metadata: {
    usermade:true,
  }
}

export const samplePointLayer = {
  id: '',
  type: 'circle',
  source: '', // reference the data source
  layout: {},
  'paint': {
    'circle-color': '#0080ff', // blue color fill
    'circle-opacity': 0.5,
    "circle-radius": [
      "interpolate",
        [
          "linear"
        ],
        [
          "zoom"
        ],
        1,
        1,
        5,
        2,
        10,
        3,
        15,
        5,
        20,
        10,
        24,
        20
    ]
  },
  metadata: {
    usermade:true,
  }
}

export const sampleDrawLayer = {
  'id': '',
  'type': 'fill',
  'source': '', // reference the data source
  'layout': {},
  'paint': {
    'fill-color': '#0080ff', // blue color fill
    'fill-opacity': 0.5,
    'fill-antialias':true,
    'fill-outline-color': '#000000'
  },
  metadata: {
    usermade:true,
  }
}


export const sampleLineLayer = {
  'id': '',
  'type': 'line',
  'source': '', // reference the data source
  'layout': {},
  'paint': {
    'line-color': '#0080ff', // blue color fill
  },
  metadata: {
    usermade:true,
  }
}

