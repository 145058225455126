import config from 'constants/apiConfig';
import axios from 'axios';

import downloadFile from 'utils/downloadFile';

import {
  FETCH_FORM_RECORDS_REQUEST,
  FETCH_FORM_RECORDS_SUCCESS,
  FETCH_FORM_RECORDS_ERROR,
  UPSERT_FORM_RECORD_REQUEST,
  UPSERT_FORM_RECORD_SUCCESS,
  UPSERT_FORM_RECORD_ERROR,
  DELETE_FORM_RECORD_REQUEST,
  DELETE_FORM_RECORD_SUCCESS,
  DELETE_FORM_RECORD_ERROR,
  EXPORT_FORM_TO_PDF_REQUEST,
  EXPORT_FORM_TO_PDF_SUCCESS,
  EXPORT_FORM_TO_PDF_ERROR,
  DOWNLOAD_FORMS_DATA_ARRAY_REQUEST,
  DOWNLOAD_FORMS_DATA_ARRAY_SUCCESS,
  DOWNLOAD_FORMS_DATA_ARRAY_ERROR,
  EXPORT_AS_FORM_REQUEST,
  EXPORT_AS_FORM_SUCCESS,
  EXPORT_AS_FORM_ERROR,
} from 'constants/form-action-types';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

import { endLoading, startLoading } from 'reducers/loading/loading.action';

function fetchFormRecordsRequest() {
  return {
    type: FETCH_FORM_RECORDS_REQUEST,
  };
}

function fetchFormRecordsSuccess(formRecords) {
  return {
    type: FETCH_FORM_RECORDS_SUCCESS,
    payload: formRecords,
  };
}

function fetchFormRecordsError(error) {
  const errorMessage =
    'fetchFormRecordsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_FORM_RECORDS_ERROR,
  };
}

export const fetchFormRecords = (incident_id, group_guid) => {
  return (dispatch) => {
    dispatch(fetchFormRecordsRequest());
    return axios(config.apiGateway.fetchFormRecords, {
      method: 'POST',
      data: JSON.stringify({
        incident_id,
        group_guid,
      }),
    })
      .then((response) => {
        const formRecords = response.data || {};
        dispatch(fetchFormRecordsSuccess(formRecords));

        return formRecords;
      })
      .catch((error) => {
        dispatch(fetchFormRecordsError(error));
      });
  };
};

function upsertFormRecordRequest() {
  return {
    type: UPSERT_FORM_RECORD_REQUEST,
  };
}

function upsertFormRecordSuccess(formRecord) {
  return {
    type: UPSERT_FORM_RECORD_SUCCESS,
    payload: formRecord,
  };
}

function upsertFormRecordError() {
  const errorMessage =
    'upsertFormRecordError\n\n' +
    'There was an error saving your Form.  No associated Workflows have modified. Please try again.' +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPSERT_FORM_RECORD_ERROR,
  };
}

export const upsertFormRecord = (form, formConfig, workflow = null) => {
  return (dispatch) => {
    dispatch(upsertFormRecordRequest());
    return axios(config.apiGateway.upsertFormRecord, {
      method: 'POST',
      data: JSON.stringify({
        form,
        formConfig,
        workflow,
      }),
    })
      .then((response) => {
        const upsertedFormRecord = response.data || {};
        dispatch(fetchFormRecords(form.incident_id, form.group_guid));
        dispatch(upsertFormRecordSuccess(upsertedFormRecord));
        // toast.success('Upsert Form Record successful', {
        //   autoClose: 2000,
        // });
      })
      .catch(() => {
        dispatch(upsertFormRecordError());
      });
  };
};

function deleteFormRecordRequest() {
  return {
    type: DELETE_FORM_RECORD_REQUEST,
  };
}

function deleteFormRecordSuccess(formRecord) {
  return {
    type: DELETE_FORM_RECORD_SUCCESS,
    payload: formRecord,
  };
}

function deleteFormRecordError(error) {
  const errorMessage =
    'deleteFormRecordError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: DELETE_FORM_RECORD_ERROR,
  };
}

export const deleteFormRecord = (form) => {
  return (dispatch) => {
    dispatch(deleteFormRecordRequest());
    return axios(config.apiGateway.deleteFormRecord, {
      method: 'POST',
      data: JSON.stringify({
        form,
      }),
    })
      .then((response) => {
        const deletedFormRecord = response.data || {};
        dispatch(deleteFormRecordSuccess(deletedFormRecord));
        dispatch(fetchFormRecords(form.incident_id, form.group_guid));
        // toast.success('Delete Form Record successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(deleteFormRecordError(error));
      });
  };
};

function exportFormToPDFRequest() {
  return {
    type: EXPORT_FORM_TO_PDF_REQUEST,
  };
}

function exportFormToPDFSuccess(exportedFormsToPDF) {
  return {
    type: EXPORT_FORM_TO_PDF_SUCCESS,
    payload: exportedFormsToPDF,
  };
}

function exportFormToPDFError(error) {
  const errorMessage =
    'exportFormToPDFError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: EXPORT_FORM_TO_PDF_ERROR,
  };
}

export const exportFormToPDF = (
  form,
  formconfig = null,
  isDownloading = false
) => {
  return (dispatch) => {
    dispatch(exportFormToPDFRequest());
    return axios(config.apiGateway.exportFormToPDF, {
      method: 'POST',
      data: JSON.stringify({
        form,
        config: formconfig,
      }),
    })
      .then((response) => {
        const statementAsDocumentFile = response.data || {};

        if (isDownloading)
          downloadFile(form.formname, 'PDF', statementAsDocumentFile);

        dispatch(
          exportFormToPDFSuccess({ ...form, PDFData: statementAsDocumentFile })
        );
        // toast.success('Export Form to PDF successful', {
        //   autoClose: 2000,
        // });
        return statementAsDocumentFile;
      })
      .catch((error) => {
        dispatch(exportFormToPDFError(error));
      });
  };
};

function downloadFormsDataArrayRequest() {
  return {
    type: DOWNLOAD_FORMS_DATA_ARRAY_REQUEST,
  };
}

function downloadFormsDataArraySuccess(downloadedForms) {
  return {
    type: DOWNLOAD_FORMS_DATA_ARRAY_SUCCESS,
    payload: downloadedForms,
  };
}

function downloadFormsDataArrayError(error) {
  const errorMessage =
    'downloadFormsDataArrayError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: DOWNLOAD_FORMS_DATA_ARRAY_ERROR,
  };
}

export const downloadFormsDataArray = (workflow, formsArray, formConfigs) => {
  return (dispatch, getState) => {
    dispatch(downloadFormsDataArrayRequest());

    const { id } = getState().app.currentIncident;
    const { group_guid } = getState().app.currentlySelectedGroup;
    return axios(config.apiGateway.downloadFormsDataArray, {
      method: 'POST',
      data: JSON.stringify({
        formsArray,
        formConfigs,
        incident_id: id,
        group_guid,
      }),
    })
      .then((response) => {
        const dataToDownload = response.data || {};

        downloadFile(workflow.name, 'PDF', dataToDownload);

        dispatch(
          downloadFormsDataArraySuccess({
            ...workflow,
            PDFData: dataToDownload,
          })
        );
        // toast.success('Download Form Data Array successful', {
        //   autoClose: 2000,
        // });
        return dataToDownload;
      })
      .catch((error) => {
        dispatch(downloadFormsDataArrayError(error));
      });
  };
};

// NEW APPROACH

function exportAsFormRequest() {
  return {
    type: EXPORT_AS_FORM_REQUEST,
  };
}

function exportAsFormSuccess(exportedFormsToPDF) {
  return {
    type: EXPORT_AS_FORM_SUCCESS,
    payload: exportedFormsToPDF,
  };
}

function exportAsFormError(error) {
  const errorMessage =
    'exportAsFormError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: EXPORT_AS_FORM_ERROR,
  };
}

/*
export const exportAsForm = ({
  formname,
  data={}
}) => {
  return (dispatch, getState) => {
    dispatch(exportAsFormRequest());
    dispatch(startLoading())
    const incident = getState().app.currentIncident
    const user = getState().app.user
    return axios(config.apiGateway.exportAsForm, {
      method: 'POST',
      data: JSON.stringify({
        formname,
        incident,
        user,
        data
      }),
    })
      .then((response) => {
        const formData = response.data || {};

        downloadFile(formname, 'PDF', formData);

        dispatch(
          exportAsFormSuccess(formData)
        );
        dispatch(endLoading())
        toast.success(`${formname} PDF export successful`, {
          autoClose: 2000,
        });
        return formData;
      })
      .catch((error) => {
        dispatch(endLoading())
        dispatch(exportAsFormError(error));
      });
  };
};
*/

export const exportAsForm = ({ formname, data = {}, mapSketch = null }) => {
  return (dispatch, getState) => {
    dispatch(exportAsFormRequest());
    dispatch(startLoading());
    const incident = getState().app.currentIncident;
    const user = getState().app.user;
    const formData = new FormData();
    const { group_guid } = getState().app.currentlySelectedGroup;
    formData.append('file', mapSketch);
    formData.append('data', JSON.stringify(data));
    formData.append('formname', formname);
    formData.append('incident', JSON.stringify(incident));
    formData.append('user', JSON.stringify(user));
    formData.append('group_guid',group_guid)

    // Create a separate upload instance due to content-type override
    const fileUploadInstance = axios.create({
      ...axios.defaults,
      headers: {
        ...axios.headers,
        'Content-Type': undefined,
      },
    });

    fileUploadInstance.interceptors.request.use((config) => {
      // This is a little dangerous, as it leaves open an edge case where the token could expire before use
      const token = sessionStorage['accessToken'];
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    fileUploadInstance
      .post(config.apiGateway.exportAsForm, formData, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        const formData = response.data || {};

        downloadFile(formname, 'PDF', formData);
        dispatch(exportAsFormSuccess(formData));
        dispatch(endLoading());
        toast.success(`${formname} PDF export successful`, {
          autoClose: 2000,
        });
      })
      .catch((error) => {
        dispatch(endLoading());
        dispatch(exportAsFormError(error));
      });
  };
};


