import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
    selectedFeatures: {}
}

const dataTableSlice = createSlice({
  name: 'dataTable',
  initialState,
  reducers: {
    setSelectedFeatures(state, action) {
      state.selectedFeatures = action.payload
    },
  },
})

export const { setSelectedFeatures } = dataTableSlice.actions
export const selectedFeatures = state => state.dataTable.selectedFeatures
export default dataTableSlice
