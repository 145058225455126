import { getAllInitialIncidentReportRecipientList, updateInitialIncidentReportRecipientList } from 'actions/ReportActions';
import { fetchRostersForGroups } from 'actions/roleActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton'
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { endLoading, startLoading } from 'reducers/loading/loading.action';

export default function RecipientListModal({
    show,
    onClose
}) {
    const reduxDispatch = useDispatch();
    const reduxCurrentlySelectedGroup = useSelector((state) => {
        return state.app.currentlySelectedGroup;
    });

    const reduxRostersForGroups = useSelector((state) => {
        return state.app.rostersForGroups || [];
    });
    const allInitialIncidentReportRecipientList = useSelector((state) => {
        return state.app.allInitialIncidentReportRecipientList;
    });
    const [
        selectedInitialIncidentReportRecipientList,
        setSelectedInitialIncidentReportRecipientList,
    ] = useState([]);
    const [rostersForGroups, setRostersForGroups] = useState([]);

    useEffect(() => {
        if (!!reduxCurrentlySelectedGroup) {
            reduxDispatch(startLoading());
            reduxDispatch(getAllInitialIncidentReportRecipientList());
            reduxDispatch(
                fetchRostersForGroups([reduxCurrentlySelectedGroup.group_guid])
            );
        }
    }, [reduxCurrentlySelectedGroup]);
    useEffect(() => {
        if (reduxRostersForGroups.length && reduxCurrentlySelectedGroup) {
            const selectedRecipientList = [];
            const collection = reduxRostersForGroups
                .filter(
                    (member) =>
                        member.group_guid === reduxCurrentlySelectedGroup.group_guid
                )
                .map((member) => {
                    const prevReturnValue = {
                        ...member,
                        role_assignments_string: member.role_assignments
                            .map((ra) => {
                                return ra.name;
                            })
                            .join(', '),
                        ...(member.seats
                            ? {
                                seat_assignments_string: member.seats
                                    .map((seat) => seat.seat_name)
                                    .join(','),
                            }
                            : {}),
                        tags_string: member.role_assignments.map((ra) => {
                            return ra.tags
                                .map((tag) => {
                                    return tag.name;
                                })
                                .join(', ');
                        }),
                    };
                    if (
                        !!allInitialIncidentReportRecipientList &&
                        allInitialIncidentReportRecipientList.length > 0
                    ) {
                        const recipientList = allInitialIncidentReportRecipientList.find(
                            (item) => item.user_guid === member.user_guid
                        );
                        if (recipientList) {
                            selectedRecipientList.push(prevReturnValue);
                        }
                    }
                    return Object.assign({}, prevReturnValue);
                });
            setSelectedInitialIncidentReportRecipientList(selectedRecipientList);
            setRostersForGroups(collection);
            reduxDispatch(endLoading());
        }
    }, [
        reduxRostersForGroups,
        reduxCurrentlySelectedGroup,
        allInitialIncidentReportRecipientList,
    ]);
    const IIRRecipientListColumns = [
        {
            dataField: 'user_name',
            text: 'Name',
            sort: true,
            headerAttrs: { title: 'Sort By:' },
            attrs: { title: 'Name' },
        },
        {
            dataField: 'email_address',
            text: 'Email',
            sort: true,
            attrs: { title: 'Email' },
        },
        {
            dataField: 'role_assignments_string',
            text: 'Organization Role',
            sort: true,
            attrs: { title: 'Organization Role' },
        },
    ];

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        hideSelectAll: false,
        selected: selectedInitialIncidentReportRecipientList.map(
            (e) => e.user_guid
        ),
        selectionHeaderRenderer: ({ mode, ...rest }) => (
            <div className="form-checkbox align-items-center">
                <input type={mode} {...rest} />
                <span className="icon"></span>
            </div>
        ),
        selectionRenderer: ({ mode, ...rest }) => (
            <div className="form-checkbox align-items-center">
                <input type={mode} {...rest} />
                <span className="icon"></span>
            </div>
        ),
        onSelect: (row, isSelect, rowIndex, e) => {
            let selection = selectedInitialIncidentReportRecipientList.map(
                (e) => e.user_guid
            );
            if (selection.includes(row.user_guid)) {
                selection = selection.filter((e) => e !== row.user_guid);
            } else {
                selection.push(row.user_guid);
            }
            reduxDispatch(startLoading());
            reduxDispatch(updateInitialIncidentReportRecipientList(selection));
        },
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect) {
                reduxDispatch(startLoading());
                reduxDispatch(
                    updateInitialIncidentReportRecipientList(rows.map((e) => e.user_guid))
                );
            } else {
                reduxDispatch(startLoading());
                reduxDispatch(updateInitialIncidentReportRecipientList([]));
            }
        },
    };
  return (
      <>
          <Modal
              show={show}
              onHide={onClose}
              centered
              backdrop="static"
              keyboard={false}
              size='lg'
          >
              <Modal.Header closeButton closeVariant="white">
                  <Modal.Title>Initial Incident Report Recipient List</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="form-block">
                      <StylishNewTable
                          keyField={'user_guid'}
                          columns={IIRRecipientListColumns}
                          rows={
                              !!rostersForGroups && rostersForGroups.length
                                  ? rostersForGroups
                                  : []
                          }
                          selectRow={selectRow}
                      />
                  </div>
              </Modal.Body>
              <Modal.Footer>
                  <div className="button-group">
                      <StylishNewButton
                          className="button--secondary button--reverse"
                          onClick={onClose}
                      >
                          Close
                      </StylishNewButton>
                  </div>
              </Modal.Footer>
          </Modal>
      </>
  )
}
