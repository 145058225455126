import React, { useEffect } from 'react';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';

import IconClose from '../../../assets/images/icon__times.svg';
import { eriScenarios, eriTableCols, eriTableRows } from '../constants/eri';
import { sampleDrawLayer } from '../constants/sampleLayers.js';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const MapImpactModels = (props) => {
  const {
    map,
    mapHasLoaded,
    apiPrefix,
    mapSettings,
    handleMapSettings,
    setLayerClicked,
    onClose,
  } = props;

  // constant variables
  const eriScenario = mapSettings?.eriScenario;
  const eriActive = mapSettings?.eriActive;
  const basemap = mapSettings?.basemap;
  const models = [{ id: 0, name: 'Energy Reliability Index' }];
  const stateToCountyZoomThreshold = 5;
  const eri_source_id = 'eri_source_id';
  const eri_source_id_state = 'eri_source_id_state';
  const eri_layer_id = 'eri_layer_id';
  const eri_layer_id_state = 'eri_layer_id_state';
  const eri_source_layer = 'public.eri_layer';
  const eri_source_layer_state = 'public.eri_layer_state';
  const selectedDatetime = mapSettings?.selectedDatetime;

  const eriTiles = `${apiPrefix}/model_tiles/${eri_source_layer}/{z}/{x}/{y}.pbf`;
  const eriTilesState = `${apiPrefix}/model_tiles/${eri_source_layer_state}/{z}/{x}/{y}.pbf`;

  // State Declarations

  // functions
  const handleClickClose = () => {
    onClose();
  };

  // useEffect
  useEffect(() => {
    if (!mapHasLoaded) return;

    if (!!eriActive) {
      if (!!map.current.getStyle().layers.find((l) => l.id === eri_layer_id))
        map.current.removeLayer(eri_layer_id);
      if (
        !!map.current.getStyle().layers.find((l) => l.id === eri_layer_id_state)
      )
        map.current.removeLayer(eri_layer_id_state);
      if (!!map.current.getSource(eri_source_id))
        map.current.removeSource(eri_source_id);
      if (!!map.current.getSource(eri_source_id_state))
        map.current.removeSource(eri_source_id_state);

      const formattedDatetime = selectedDatetime
        .minute(0)
        .second(0)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');

      if (!map.current.getSource(eri_source_id)) {
        map.current.addSource(eri_source_id, {
          type: 'vector',
          tiles: [eriTiles + '?datetime_selected=' + formattedDatetime],
        });
      }

      if (!map.current.getSource(eri_source_id_state)) {
        map.current.addSource(eri_source_id_state, {
          type: 'vector',
          tiles: [eriTilesState + '?datetime_selected=' + formattedDatetime],
        });
      }

      if (
        !map.current.getStyle().layers.find((l) => l.id === eri_layer_id) &&
        !map.current.getStyle().layers.find((l) => l.id === eri_layer_id_state)
      ) {
        const foundScenario = eriScenarios.find((s) => s.value === eriScenario);
        const eriLayerConfig = {
          ...sampleDrawLayer,
          id: eri_layer_id,
          source: eri_source_id,
          'source-layer': eri_source_layer,
          generateId: true,
          paint: {
            ...sampleDrawLayer.paint,
            'fill-color': [
              'match',
              ['get', `${eriScenario}`],
              0,
              '#FFFFFF',
              1,
              '#ffffb2',
              2,
              '#fecc5c',
              3,
              '#fd8d3c',
              4,
              '#f03b20',
              5,
              '#bd0026',
              '#FFFFFF',
            ],
            'fill-opacity': 0.4,
            'fill-outline-color': foundScenario?.color,
          },
          metadata: {
            eventlayer: false,
            usermade: undefined,
            model_layer: true,
            model_details: {
              model_name: 'Energy Reliability Index',
              model_code: 'ERI',
              political_boundaries_type: 'county',
            },
          },
          minzoom: stateToCountyZoomThreshold,
        };

        map.current.addLayer(eriLayerConfig);
        map.current.on('click', eriLayerConfig.id, (e) =>
          setLayerClicked({ layer: eriLayerConfig, e: e, features: e.features })
        );
        map.current.on('touchend', eriLayerConfig.id, (e) =>
          setLayerClicked({ layer: eriLayerConfig, e: e, features: e.features })
        );
        map.current.on('mouseenter', eriLayerConfig.id, () => {
          map.current.getCanvas().style.cursor = 'pointer';
        });
        map.current.on('mouseleave', eriLayerConfig.id, () => {
          map.current.getCanvas().style.cursor = 'grab';
        });

        let eriLayerConfigState = {
          ...eriLayerConfig,
          metadata: {
            ...eriLayerConfig.metadata,
            model_details: {
              ...eriLayerConfig.metadata.model_details,
              political_boundaries_type: 'state',
            },
          },
          id: eri_layer_id_state,
          source: eri_source_id_state,
          'source-layer': eri_source_layer_state,
          minzoom: 0,
          maxzoom: stateToCountyZoomThreshold,
        };
        map.current.addLayer(eriLayerConfigState);
        map.current.on('click', eriLayerConfigState.id, (e) =>
          setLayerClicked({
            layer: eriLayerConfigState,
            e: e,
            features: e.features,
          })
        );
        map.current.on('touchend', eriLayerConfigState.id, (e) =>
          setLayerClicked({
            layer: eriLayerConfigState,
            e: e,
            features: e.features,
          })
        );
        map.current.on('mouseenter', eriLayerConfigState.id, () => {
          map.current.getCanvas().style.cursor = 'pointer';
        });
        map.current.on('mouseleave', eriLayerConfigState.id, () => {
          map.current.getCanvas().style.cursor = 'grab';
        });
      }
    } else {
      if (!!map.current.getStyle().layers.find((l) => l.id === eri_layer_id))
        map.current.removeLayer(eri_layer_id);
      if (
        !!map.current.getStyle().layers.find((l) => l.id === eri_layer_id_state)
      )
        map.current.removeLayer(eri_layer_id_state);
      if (!!map.current.getSource(eri_source_id))
        map.current.removeSource(eri_source_id);
      if (!!map.current.getSource(eri_source_id_state))
        map.current.removeSource(eri_source_id_state);
    }
  }, [eriActive, mapHasLoaded, selectedDatetime, eriScenario, basemap]);

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        <h4 className="m-0">Impact Models</h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={() => handleClickClose()}
        >
          <img src={IconClose} alt="" />
        </StylishNewButton>
      </div>
      <div className="sidebar-inner">
        {models.map((m, idx) => {
          return (
            <div key={'model-' + idx}>
              {idx !== 0 ? (
                <hr key={idx} className="dashed w-10 thin m-0" />
              ) : null}
              <div
                key={m.id}
                className={`py-3 ${idx === 0 ? 'pt-0' : ''} ${
                  idx === models.length - 1 ? 'pb-0' : ''
                }`}
              >
                <div className="d-flex align-items-center">
                  <span>{m.name}</span>
                  <StylishSwitcher
                    className={'ms-auto'}
                    onChange={() =>
                      handleMapSettings({
                        ...mapSettings,
                        eriActive: !eriActive,
                      })
                    }
                    checked={eriActive}
                  />
                </div>
                <div className="my-3">
                  <StylishNewSelect
                    options={eriScenarios}
                    defaultValue={eriScenario}
                    value={eriScenario}
                    onChange={(e) =>
                      handleMapSettings({
                        ...mapSettings,
                        eriScenario: e.value,
                      })
                    }
                    placeholder={
                      eriScenarios?.find((s) => s.value === eriScenario)?.label
                    }
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                </div>
                <StylishNewTable
                  classes={'tbody-p-0 layout-auto'}
                  keyField={`id`}
                  rows={eriTableRows}
                  columns={eriTableCols}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MapImpactModels;
