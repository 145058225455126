import axios from 'axios';
import { toast } from 'react-toastify';
import config from 'constants/apiConfig';


import downloadFile from 'utils/downloadFile';

// New calling function
export const exportPaginatedIAP = ({ incident, user, group_guid }) => {
  const formname = incident.name + " IAP";

  const formData = new FormData();
  formData.append('formname', formname);
  formData.append('incident', JSON.stringify(incident));
  formData.append('user', JSON.stringify(user));
  formData.append('group_guid', group_guid);

  // Create a separate upload instance due to content-type override
  const fileUploadInstance = axios.create({
    ...axios.defaults,
    headers: {
      ...axios.headers,
      'Content-Type': undefined,
    },
  });

  // Attach the token for authorization
  const token = sessionStorage['accessToken'];
  if (token) {
    fileUploadInstance.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  }

  // Make the POST request
  fileUploadInstance.post(config.apiGateway.exportPaginatedIAP, formData, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  .then((response) => {
    const formDataResponse = response.data || {};

    // Download the file
    downloadFile(formname, 'PDF', formDataResponse);

    // Notify the user of success
    toast.success(`${formname} PDF export successful`, {
      autoClose: 2000,
    });
  })
  .catch((error) => {
    // Handle the error
    toast.error(`Error exporting ${formname} PDF: ${error.message}`, {
      autoClose: 2000,
    });
  });
};
