import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import { selectUserGuid } from '../../../slices/commonSelectors';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../assets/data/config';
import {
  CreateStrategyReqData,
  keys as strategyKeys,
  useCreateStrategy,
} from './strategyHooks';
import { keys as tacticKeys } from './incidentTacticHooks';
import { useActualIncidentId } from './commonHooks';
import { EditResponse } from './incidentObjectiveHooks';

export type IncidentStrategy = {
  id: UUID;
  strategy_id: UUID;
  incident_id: UUID;
  user_guid: UUID;
  version: number;
  name: string;
  description: string;
  timestamp: Date;
  archived: boolean;
  tactics: UUID[];
  user_name: string;
} & {
  objectives: UUID[];
};

export const keys = {
  base: 'incident_strategies',
  get all() {
    return [keys.base];
  },
  list: (incidentId: UUID, userId: UUID) => [keys.base, incidentId, userId],
} as const;

export const useIncidentStrategies = <TData = IncidentStrategy[]>(
  selector?: (i: IncidentStrategy[]) => TData
) => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.list(incidentId, user_guid),
    queryFn: async () => {
      const resp = await axios.get<IncidentStrategy[]>(
        `${config.apiGateway.ics_incident_strategies}/${incidentId}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
    select: selector,
  });
};

/** NOTE: this posts to the group strategy which will create the incident version.  */
export const useCreateIncidentStrategy = () => {
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  const mutation = useCreateStrategy();
  return {
    ...mutation,
    mutateAsync: (data: CreateStrategyReqData) => {
      data.incident_id = incidentId;
      return mutation.mutateAsync(data, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: strategyKeys.all });
          queryClient.invalidateQueries({ queryKey: [keys.base] });
        },
      });
    },
  };
};

interface EditIncidentStrategyReqData {
  strategy_id: UUID;
  name: string;
  description: string;
  archived: boolean;
}

export const useEditIncidentStrategy = (strategyId: UUID) => {
  const user_guid = useSelector(selectUserGuid);
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: EditIncidentStrategyReqData) => {
      return await axios.put<EditResponse<IncidentStrategy>>(
        `${config.apiGateway.ics_incident_strategies}/${incidentId}/${strategyId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: (resp) => {
      queryClient.invalidateQueries({
        queryKey: keys.list(incidentId, user_guid),
      });
      queryClient.invalidateQueries({
        queryKey: strategyKeys.all,
      });
      if (resp.data.meta.updatedTactics) {
        queryClient.invalidateQueries({
          queryKey: tacticKeys.all,
        });
      }
    },
    onError: (error) => {
      toast.error(error.message, toastConfig as any);
    },
  });
};
