import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import { selectUserGuid } from '../../../slices/commonSelectors';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../assets/data/config';
import { useActualIncidentId } from './commonHooks';

export type StrikeTeam = {
  id: string;
  strike_team_id: string;
  name: string;
  description?: string;
  group_guid: string;
  incident_id: string;
  work_assignment_id?: string;
  resource_sets: string[];
  status: string;
  version: number;
  timestamp: Date;
  user_guid: string;
  archived: boolean;
  leader: string;
  leader_contact: string;
  special_instructions: string;
};

export const keys = {
  base: 'strikeTeams',
  get all() { return [keys.base] },
  list: (incidentId: string, userId: string) => [keys.base, incidentId, userId],
} as const;

export type CreateStrikeTeamReqData = {
  strike_team_id: string;
  name: string;
  description?: string;
  group_guid: string;
  incident_id: string;
  work_assignment_id?: string;
  resource_sets?: string[];
  status?: string;
  archived?: boolean;
};

export const useStrikeTeams = () => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.list(incidentId, user_guid),
    queryFn: async () => {
      const resp = await axios.get<StrikeTeam[]>(
        `${config.apiGateway.ics_strike_teams}/${incidentId}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
  });
};

export const useCreateStrikeTeam = () => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data: CreateStrikeTeamReqData) => {
      return await axios.post(
        `${config.apiGateway.ics_strike_teams}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(incidentId, user_guid) });
    },
    onError: (error: any) => {
      toast.error(error.message, toastConfig as any);
    },
  });
  return mutation;
};

interface EditStrikeTeamReqData {
  id: string;
  name?: string;
  description?: string;
  group_guid?: string;
  work_assignment_id?: string;
  resource_sets?: string[];
  status?: string;
  archived?: boolean;
  leader: string;
  leader_contact: string;
  special_instructions: string;
}

export const useEditStrikeTeam = (strikeTeamId: string) => {
  const user_guid = useSelector(selectUserGuid);
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: EditStrikeTeamReqData) => {
      return await axios.put(
        `${config.apiGateway.ics_strike_teams}/${strikeTeamId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(incidentId, user_guid) });
    },
    onError: (error: any) => {
      toast.error(error.message, toastConfig as any);
    },
  });
};
