import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';

import { useSelector } from 'react-redux';
import OSINTPostModal from './OSINTPostModal';
import OSINTPostTwitter from './OSINTPostTwitter';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import ReactTooltip from 'react-tooltip';

import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';

import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import { Link } from 'react-router-dom';
import IconVisit from 'assets/images/icon__visit.svg';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

function colorForSentiment(sentiment) {
  switch (sentiment) {
    case 'very negative':
      return 'bg-danger';
    case 'negative':
      return 'bg-warning';
    case 'neutral':
      return 'bg-secondary';
    case 'positive':
      return 'bg-info';
    case 'very positive':
      return 'bg-success';
    default:
      return 'bg-secondary';
  }
}

function tooltipForSentiment(sentiment) {
  switch (sentiment) {
    case 'very negative':
      return 'Indicates very negative sentiment';
    case 'negative':
      return 'Indicates negative sentiment';
    case 'neutral':
      return 'Neither positive nor negative sentiment';
    case 'positive':
      return 'Indicates positive sentiment';
    case 'very positive':
      return 'Indicates very positive sentiment';
    default:
      return 'Not enough confidence to assign sentiment';
  }
}

export default function OSINTPost({ searchTerm, numPosts }) {
  const [postData, setPostData] = useState([]);
  const [postDataEnriched, setPostDataEnriched] = useState([]);
  const [openNoteModalId, setOpenNoteModalId] = useState(null);

  const reduxTwitterData = useSelector((state) => {
    return state.app.twitterData;
  });
  const reduxTwitterDataLoaded = useSelector((state) => {
    return state.app.isTwitterDataLoaded;
  });
  const reduxTwitterDataEnriched = useSelector((state) => {
    return state.app.twitterDataEnriched;
  });
  const isTwitterDataEnrichedLoaded = useSelector((state) => {
    return state.app.isTwitterDataEnrichedLoaded;
  });

  // function
  const handleActionRow = (idx) => {
    setOpenNoteModalId(idx);
  };

  const handleNoteModalClose = (note) => {
    const _postData = [...postData];
    if (openNoteModalId && note) {
      _postData[openNoteModalId - 1].note = note;
    }
    setPostData(_postData);
    setOpenNoteModalId(0);
  };

  // useEffect
  useEffect(() => {
    let postDataArray = [];
    postDataArray = reduxTwitterData.map((data) => {
      return {
        posts_grid: data?.content?.id,
        post_id: data?.content?.id,
        post_likes: data?.public_metrics?.like_count || 0,
        post_reply: data?.content?.reply_count || 0,
        post_date:
          dayjs(data?.content?.created_at).utc().format('YYYY-MM-DD HH:mm') +
          ' UTC',
        post_lang: data?.content?.lang,
        post_url: `https://twitter.com/user/status/${data?.content?.id}`,
        text: data?.content?.text,
        note: '',
        sentiment: 'Loading Sentiment...',
      };
    });
    setPostData(postDataArray);
  }, [reduxTwitterData]);

  useEffect(() => {
    let postDataE = [];
    postDataE = reduxTwitterDataEnriched.map((data) => {
      return {
        posts_grid: data?.content?.id,
        post_id: data?.content?.id,
        post_likes: data?.public_metrics?.like_count || 0,
        post_reply: data?.content?.reply_count || 0,
        post_date:
          dayjs(data?.content?.created_at).utc().format('YYYY-MM-DD HH:mm') +
          ' UTC',
        post_lang: data?.content?.lang,
        post_url: `https://twitter.com/user/status/${data?.content?.id}`,
        text: data?.content?.text,
        note: '',
        ...data.enrichment,
      };
    });
    setPostDataEnriched(postDataE);
  }, [reduxTwitterDataEnriched]);

  const columnHeaders = [
    // {
    //   dataField: 'post_id',
    //   text: 'Twitter ID',
    //   attrs: { title: 'Post Id' },
    //   headerAttrs: { title: 'Sort By:' },
    // },
    {
      headerAttrs: { title: 'Sort By:' },
      dataField: 'text',
      text: 'Text',
      sort: true,
      attrs: { title: 'Text' },
      formatter: (cell, row, rowIndex) => {
        return <div style={{ overflow: 'hidden' }}>{row.text}</div>;
      },
    },
    // {
    //   dataField: 'post_likes',
    //   text: 'Likes',
    //   sort: true,
    //   attrs: { title: 'Likes' },
    //   headerStyle: {
    //     width: '70px'
    //   }
    // },
    // {
    //   dataField: 'post_reply',
    //   text: 'Reply',
    //   sort: true,
    //   attrs: { title: 'Reply' },
    //   headerStyle: {
    //     width: '70px'
    //   }
    // },
    {
      dataField: 'post_date',
      text: 'Date',
      sort: true,
      attrs: { title: 'Date' },
    },
    {
      dataField: 'post_lang',
      text: 'Lang',
      sort: false,
      attrs: { title: 'Lang' },
      headerStyle: {
        width: '70px',
      },
    },
    {
      dataField: 'post_sentiment',
      text: 'Sentiment',
      formatter: (cell, row, rowIndex) => {
        return (
          <span
            key={'sentiment-' + rowIndex}
            //style={{backgroundColor:colorForSentiment(row.sentiment)}}
            className={`status ${colorForSentiment(row.sentiment)}`}
            onClick={() => {}}
          >
            <span className="btn-label">
              {row.sentiment}
              {(row.sentiment === 'Loading...' && (
                <i className="fa fa-1x fa-spinner fa-pulse ms-1"></i>
              )) ||
                ''}
              <span
                data-tip
                data-for={'sentiment-post-tooltip-' + rowIndex}
                style={{ marginLeft: 8 }}
              >
                <SharedIcon iconName="info" size="sm" classes="cursor-default" />
                <ReactTooltip id={'sentiment-post-tooltip-' + rowIndex}>
                  <span className="sentiment-post-tooltip-text">
                    {tooltipForSentiment(row.sentiment)}
                  </span>
                </ReactTooltip>
              </span>
            </span>
          </span>
        );
      },
      sort: false,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc') {
          return rowB.sentiment.localeCompare(rowA.sentiment);
        }
        return rowA.sentiment.localeCompare(rowB.sentiment); // desc
      },
      attrs: { title: 'Sentiment' },
      headerClasses: 'sm-hidden',
    },
    {
      dataField: 'post_url',
      text: 'Url',
      sort: false,
      attrs: { title: 'Url' },
      headerClasses: 'sm-hidden',
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      formatter: (cell, row, rowIndex) => {
        return (
          <Link
            className="button--icon cursor-pointer"
            to={row?.post_url}
            target="_blank"
          >
            <img src={IconVisit} alt="" />
          </Link>
        );
      },
    },
    // {
    //   dataField: 'text',
    //   text: 'Note',
    //   formatter: (cell, row, rowIndex) => (
    //     <StylishNewButton
    //       key={'action-' + rowIndex}
    //       className="button--action"
    //       type={'button'}
    //       onClick={() => handleActionRow(rowIndex + 1)}
    //     >
    //       <img src={IconEye} alt="" />
    //     </StylishNewButton>
    //   ),
    //   headerClasses: 'sm-hidden',
    //   headerStyle: {
    //     width: '100px',
    //   },
    //   headerAlign: 'center',
    //   align: 'center',
    //   attrs: { title: 'Add a note' },
    // },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row, rowIdx) => {
      return (
        <div key={'expanded-' + rowIdx}>
          <div className="mb-3">
            <h6 className="mb-1">Content</h6>
            <p className="m-0">{row.text}</p>
          </div>
          {/*          <h5>Note</h5>
          <div className="mb-3">{row.note}</div>*/}

          {/*            <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
              Likes: {row.post_likes}
            </div>
            <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
              EN: {row.post_lang}
            </div>
            <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
              Reply: {row.post_reply}
            </div>*/}
          <div className="mb-0">
            <h6 className="mb-1">Sentiment:</h6>
            <p className="m-0">{capitalizeFirstLetter(row?.sentiment)}</p>
          </div>
        </div>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  return (
    <>
      <div className="d-flex align-items-start">
        <h4>
          {(!!reduxTwitterDataLoaded && <span>Top {numPosts} Posts </span>) || (
            <span>Loading OSINT...</span>
          )}
        </h4>
        <span data-tip data-for="twitter-post-tooltip" className="ms-2">
          <SharedIcon iconName="info" size="sm" classes="cursor-default" />
          <ReactTooltip id="twitter-post-tooltip">
            <span className="twitter-post-tooltip-text">
              Top most relevant posts
            </span>
          </ReactTooltip>
        </span>
      </div>
      {!postData?.length && !reduxTwitterData.length ? (
        <div className="bg-gray-800 text-center p-5">
          <div className="py-0 py-md-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconEmptyExercise} alt="" />
            </div>
            <p className="weight-600 m-0">
              No OSINT data found. <br />
              Check your search options and date ranges to expand your search
              criteria.
            </p>
          </div>
        </div>
      ) : null}

      <OSINTPostTwitter
        postData={postData}
        isLoading={!reduxTwitterDataLoaded}
      />

      {!!reduxTwitterData.length > 0 &&
      !!reduxTwitterData &&
      !!reduxTwitterDataLoaded ? (
        <div className="form-block mt-5">
          <StylishNewTable
            keyField={'posts_grid'}
            columns={columnHeaders}
            rows={
              (!!postDataEnriched &&
                !!postDataEnriched.length &&
                !!isTwitterDataEnrichedLoaded &&
                postDataEnriched) ||
              postData
            }
            expandRow={expandRow}
          />
        </div>
      ) : null}

      {!!openNoteModalId && (
        <OSINTPostModal onClose={(val) => handleNoteModalClose(val)} />
      )}
    </>
  );
}
