import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import { selectUserGuid } from '../../../slices/commonSelectors';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../assets/data/config';
import { useActualIncidentId } from './commonHooks';

// Type Definitions
export type IncidentComs = {
  id: string;
  coms_id: string;
  incident_id: string;
  user_guid: string;
  version: number;
  type: string;
  branch?: string;
  division?: string;
  method_of_contact?: string;
  zone_group?: string;
  channel_number?: string;
  function?: string;
  channel_name?: string;
  assignment?: string;
  rx_frequency?: string;
  rx_tone_nac?: string;
  tx_frequency?: string;
  tx_tone_nac?: string;
  mode?: string;
  remarks?: string;
  special_instructions?: string;
  timestamp: Date;
  archived: boolean;
  name? :string;
  branch_name?: string;
  division_name?: string;
};

export const keys = {
  base: 'incident_coms',
  get all() { return [keys.base] },
  list: (incidentId: string, userId: string) => [keys.base, incidentId, userId],
} as const;

export type CreateIncidentComsReqData = {
  coms_id: string;
  incident_id: string;
  type: string;
  branch?: string;
  division?: string;
  method_of_contact?: string;
  zone_group?: string;
  channel_number?: string;
  function?: string;
  channel_name?: string;
  assignment?: string;
  rx_frequency?: string;
  rx_tone_nac?: string;
  tx_frequency?: string;
  tx_tone_nac?: string;
  mode?: string;
  remarks?: string;
  special_instructions?: string;
  archived?: boolean;
  name? :string;
};

interface EditIncidentComsReqData {
  id: string;
  type: string;
  branch?: string;
  division?: string;
  method_of_contact?: string;
  zone_group?: string;
  channel_number?: string;
  function?: string;
  channel_name?: string;
  assignment?: string;
  rx_frequency?: string;
  rx_tone_nac?: string;
  tx_frequency?: string;
  tx_tone_nac?: string;
  mode?: string;
  remarks?: string;
  special_instructions?: string;
  archived?: boolean;
  name? :string;
}

// Hook to fetch incident communications
export const useIncidentComs = () => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.list(incidentId, user_guid),
    queryFn: async () => {
      const resp = await axios.get<IncidentComs[]>(
        `${config.apiGateway.ics_incident_coms}/${incidentId}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
  });
};

// Hook to create incident communication
export const useCreateIncidentComs = () => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data: CreateIncidentComsReqData) => {
      // First, create the organization-level coms object
      const orgData = {
        ...data,
        incident_id: undefined, // remove incident_id from the payload
      };
      const orgComs = await createComs(orgData);

      // Use the org level object's ID in the incident level object
      const coms_id = orgComs.id;
      return await axios.post(
        `${config.apiGateway.ics_incident_coms}/${incidentId}`,
        { ...data, user_guid, coms_id }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(incidentId, user_guid) });
    },
    onError: (error: any) => {
      toast.error(error.message, toastConfig as any);
    },
  });
  return mutation;
};

// Hook to edit incident communication
export const useEditIncidentComs = (comsId: string) => {
  const user_guid = useSelector(selectUserGuid);
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: EditIncidentComsReqData) => {
      return await axios.put(
        `${config.apiGateway.ics_incident_coms}/${incidentId}/${comsId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(incidentId, user_guid) });
    },
    onError: (error: any) => {
      toast.error(error.message, toastConfig as any);
    },
  });
};

// Function to create organization-level coms object
export async function createComs(data: any) {
  try {
    const id = uuidv4();
    const result = await axios.post(
      `${config.apiGateway.ics_coms}`,
      { ...data, id }
    );
    return result.data;
  } catch (error: any) {
    throw new Error(`Error creating coms: ${error.message}`);
  }
}

export const getComsTitle = (coms: IncidentComs) => {
  let formattedName = `${coms.type}: Branch (${coms.branch_name}) - Div (${coms.division_name})`;
  if (coms.type === 'Radio') {
    formattedName += ` | Fn: ${coms.function} | Freq: ${coms.rx_frequency}`;
  } else {
    formattedName += ` | Name: ${coms.name} | Method: ${coms.method_of_contact}`;
  }
  return formattedName;
};