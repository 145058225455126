import React from 'react'
import firstImage from 'images/knowledge_base/1.png';
import secondImage from 'images/knowledge_base/2.png';
import thirdImage from 'images/knowledge_base/3.png';
import fourthImage from 'images/knowledge_base/4.png';
import fifthImage from 'images/knowledge_base/5.png';
import sixthImage from 'images/knowledge_base/6.png';
import seventhImage from 'images/knowledge_base/7.png';

export default function KnowledgeBase() {
  return (
    <>
      <h1>Knowledge Base 
      For U.S. Navy 
      Test & Experimentation Workspace</h1>
      <h2>Plan</h2>
      <h3>What can I do from the Design Dashboard?</h3>
      <p>The Dashboard gives you a high-level view of your entire training 
        exercise. The Dashboard provides a summary of all missions and a 
        full timeline of events. Altogether, the Design Dashboard gives you 
        an overview of all systems, test objectives, and activity logs for 
        each mission.</p>
      <p>Here, you’ll see Experimentation Event Details, Mission Timeline, 
        and the Calendar. Continue reading to learn how to design the 
        experimentation event.</p>
      <h3>What can I do from the Experimental Event Details tab?</h3>
      <p>The Experimental Event Details tab is customizable for your T&E 
        workspace. At a glance, this tab provides an overview of: </p>
      <ul>
        <li>Details</li>
        <li>Test Objectives</li>
        <li>Event Cells</li>
        <li>Event Calendar</li>
        <li>POA&M</li>
        <li>Data Collection</li>
        <li>Funding</li>
      </ul>
      <h3>How do Edit Details?</h3>
      <p>To edit the Experimental Event Details, follow these steps: </p>
      <ol>
        <li>Click the blue Edit Details button.</li>
        <li>Fill in the Host types, which include Primary and Secondary.</li>
        <li>Select the Start Date MM/DD/YYYY box.</li>
        <ol>
          <li>Choose a date from the calendar</li>
        </ol>
        <li>Click the 00:00 box associated with the date.</li>
        <ol>
          <li>Choose a time from the clock. Select AM or PM and click Done.</li>
        </ol>
        <li>Repeat steps 3 and 4 for the End Date.</li>
        <li>Type in the Purpose.</li>
        <li>Provide a Background Statement.</li>
        <li>Fill in the Test Event Location.</li>
        <li>Press Save to complete this step.</li>
      </ol>
      <img src={firstImage} alt="firstImage" />
      <br/>
      <br/>
      <img src={secondImage} alt="secondImage" />
      <h3>How do I add Test Objectives?</h3>
      <p>To edit the Test Objectives, follow these steps:</p>
      <ol>
        <li>Select the blue Create Objective button.</li>
        <li>Type in the Description.</li>
        <li>Choose MOE, MOS, or MOP from the drop-down menu.</li>
        <li>Provide the MOE, MOS, or MOP number (e.g., 1.1, 1.2, etc.).</li>
        <li>Fill in the MOE, MOS, or MOP description. </li>
        <li>Optional: Press the blue plus button associated to the MOE, MOS, or MOP. Repeat this step as needed to add multiple MOEs, MOSs, or MOPs.  </li>
        <li>Click Save to complete this step.</li>
      </ol>
      <img src={thirdImage} alt="thirdImage" />
      <br/>
      <br/>
      <img src={fourthImage} alt="fourthImage" />
      <br/>
      <i>Note: The COIC number is automatically assigned.</i>
      <h3>Can I edit a Test Objective?</h3>
      <p>Yes, Test Objectives can be updated once saved. Follow these steps to edit a Test Objective:</p>
      <ol>
        <li>Locate the Objective.</li>
        <li>Click the associated more options icon (ellipses)</li>
        <li>Select Edit Objective from the menu</li>
        <li>Complete the edits.</li>
        <li>Click Save</li>
      </ol>
      <h3>Can I delete a Test Objective?</h3>
      <p>Yes, Test Objectives can be deleted. To delete an Objective, follow these steps:</p>
      <ol>
        <li>Find the Test Objective.</li>
        <li>Select the associated more options icon (ellipses).</li>
        <li>Choose Delete Objective from the drop-down.</li>
        <li>Click the Delete button.</li>
      </ol>
      <i>Warning: Once deleted, the Test Objective is permanently removed from your workspace.</i>
      <h3>What can I do from the Event Cells tab?</h3>
      <p>From the Event Cells tab, you can add a cell.</p>
      <ol>
        <li>Click the blue Add Cell button. </li>
        <li>Select the Cell from the drop-down menu.</li>
        <li>Type in the Responsibility. </li>
        <li>Click Save to complete this step.</li>
      </ol>
      <h3>Can I edit a Cell?</h3>
      <p>Yes, Cells can be updated once saved. Follow these steps to edit a Cell:</p>
      <ol>
        <li>Locate the Cell. </li>
        <li>Click the associated more options icon (ellipses).</li>
        <li>Select Edit Cell from the menu.</li>
        <li>Complete the edits. </li>
        <li>Click Save.</li>
      </ol>
      <h3>Can I delete a Cell?</h3>
      <p>Yes, Cells can be deleted. To delete a Cell, follow these steps:</p>
      <ol>
        <li>Find the Cell. </li>
        <li>Select the associated more options icon (ellipses).</li>
        <li>Choose Delete Cell from the drop-down. </li>
        <li>Click the blue Yes button. </li>
      </ol>
      <i>Warning: Once deleted, the Cell is permanently removed from your workspace. </i>
      <h3>What can I do from the Event Calendar tab? </h3>
      <p>From the Event Calendar tab, you can Add Event Meeting(s). To add an event meeting, follow these steps: </p>
      <ol>
        <li>Click the blue Add Event Meeting button.</li>
        <li>Type in the Name. </li>
        <li>Add the Location. </li>
        <li>Select the Start Date MM/DD/YYYY box. </li>
        <li> a. Choose a date from the calendar. </li>
        <li>Click the 00:00 box associated with the date. </li>
        <li> a. Choose a time from the clock. Select AM or PM and click Done.</li>
        <li>Press the Save button.  </li>
      </ol>
      <i>Note: Once an Event Meeting is added, it will appear on the Event Calendar.</i>
      <h3>Can I add multiple Event Meetings?</h3>
      <p>Yes, you can add multiple Event Meetings. To do so, click the Add Event Meeting button.</p>
      <h3>Can I edit an Event Meeting?</h3>
      <p>Yes. To edit an Event Meeting, follow these steps:</p>
      <ol>
        <li>Select the Event Meeting from the calendar.</li>
        <li>Click the Edit button. </li>
        <li>Complete necessary edits. </li>
        <li>Press the Save button.</li>
      </ol>
      <h3>Can I delete an Event Meeting?</h3>
      <p>Yes. To delete an Event Meeting, follow these steps:</p>
      <ol>
        <li>Select the Event Meeting from the calendar.</li>
        <li>Click the Delete button.</li>
        <li>Press the Delete button to confirm this action.</li>
      </ol>
      <i>Warning: Deletions are permanent.</i>
      <h3>How do I Create a Task in the POA&M tab?</h3>
      <p>To create a task, follow these steps:</p>
      <ol>
        <li>Click the blue Create Task button. </li>
        <li>Provide a description of the Task into the box titled Input Task. </li>
        <li>Select the Due Date MM/DD/YYYY box. </li>
        <li> a. Choose a date from the calendar. </li>
        <li>Choose the Support from the drop-down menu.</li>
        <li>Select the Cell from the drop-down menu.</li>
        <li>Pick the Status from the drop-down menu. </li>
        <li>Link an Artifact. Artifacts can include links to an article, document, or web page. </li>
        <li>Click the Save button.</li>
      </ol>
      <i>Note: Task IDs are automatically assigned.</i>
      <h3>Can I edit a Task/POA&M?</h3>
      <p>To edit a task, follow these steps:</p>
      <ol>
        <li>Locate the POA&M you would like to edit. </li>
        <li>Click the associated more options icon (ellipses).</li>
        <li>Select Edit POA&M from the menu.</li>
        <li>Make any necessary changes. </li>
        <li>Press the Save button to complete edits. </li>
      </ol>
      <h3>Can I delete a Task/POA&M?</h3>
      <p>To delete a POA&M, follow these steps: </p>
      <ol>
        <li>Find the POA&M you would like to edit. </li>
        <li>Click the associated more options icon (ellipses).</li>
        <li>Select Delete POA&M from the menu.</li>
        <li>Press the Delete button to confirm this action.</li>
      </ol>
      <i>Warning: All deletions are permanent. </i>
      <h3>How do I Add a Data Source in the Data Collection tab?</h3>
      <p>To add a Data Source, follow these instructions: </p>
      <ol>
        <li>Press the Add Data Source button.</li>
        <li>Provide a Source. </li>
        <li>Type in the Provider. </li>
        <li>Fill in the Collector.</li>
        <li>Add the Method. </li>
        <li>Click the blue Save button to finish adding the data source.  </li>
      </ol>
      <i>Note: Multiple Data Sources can be added.</i>
      <h3>How do I Edit a Data Source?</h3>
      <ol>
        <li>Find the Data Source you would like to edit. </li>
        <li>Click the associated more options icon (ellipses).</li>
        <li>Press the Edit Data Source button.</li>
        <li>Make any necessary updates.</li>
        <li>Click the Save button.  </li>
      </ol>
      <h3>How do I Delete a Data Source?</h3>
      <ol>
        <li>Locate the Data Source you want to delete.</li>
        <li>Press the associated more options icon (ellipses).</li>
        <li>Click the Delete Data Source button. </li>
        <li>Press the blue Delete button to accept the permanent deletion. </li>
      </ol>
      <br/>
      <i>Warning: Once deleted, the data source cannot be retrieved.</i>
      <h3>How do I Add Funding?</h3>
      <ol>
        <li>Click the blue Add Funding button. </li>
        <li>Select the Primary Source from the drop-down. </li>
        <ol>
          <li>To add a Primary Source, click Add New, type in the Title, and click the Save button.</li>
        </ol>
        <li>Choose a Sub-source from the menu.</li>
        <ol>
          <li>To add a new Sub-source, click Add New, type in the Title, and click Save.</li>
        </ol>
        <li>Type in the funding Amount.</li>
        <li>Press the blue Save button.</li>
      </ol>
      <h3>Can I edit a Funding Source?</h3>
      <ol>
        <li>Click the more options icon (ellipses) associated with the Funding Source. </li>
        <li>Select Edit Funding Source from the menu.</li>
        <li>Complete any necessary edits.</li>
        <li>Press the blue Save button to finish making edits.</li>
      </ol>
      <h3>Can I delete a Funding Source?</h3>
      <ol>
        <li>Click the more options icon (ellipses) associated with the Funding Source. </li>
        <li>Choose Delete Funding Source from the menu.</li>
        <li>Press the blue Delete button to accept permanent deletion. </li>
      </ol>
      <i>Warning: Once a Funding Source is deleted, it cannot be retrieved. </i>
      <h3>What can I do from the TRR page?</h3>
      <p>From the Technical Readiness Review (TRR) page, you can add TRR Levels 1-3. Additionally, you can sort the TRR levels by Technical Discipline.</p>
      <h3>How do I add TRR Level 1?</h3>
      <ol>
        <li>Click Add TRR Level 1.</li>
        <li>Select a Special Interest from the drop-down menu. </li>
        <ol>
          <li>Special Interests include High Priority, Flagged, and Question. </li>
        </ol>
        <li>Write a Description.</li>
        <li>Provide Comments/Mitigation. </li>
        <li>Press Save. </li>
        <li>Continue these steps as needed.</li>
      </ol>
      <img src={fifthImage} alt="fifthImage" />
      <br/>
      <i>Note: The item number is automatically assigned in the order of creation. For example, the first item you add will be assigned numbered 1, and the second will be assigned number 2.</i>
      <h3>How do I add TRR Level 2? </h3>
      <p>A Level 1 TRR must be completed prior to adding a Level 2 TRR. Additionally, a Level 2 TRR must be associated with a Level 1 TRR. </p>
      <ol>
        <li>Locate the Level 1 TRR to which you would like to add a Level 2 TRR.</li>
        <li>Press the plus button associated with the Level 2 TRR. </li>
        <li>Select a Special Interest from the drop-down menu. </li>
        <ol>
          <li>Special Interests include High Priority, Flagged, and Question.</li>
        </ol>
        <li>Provide a Description. </li>
        <li>Add Comments/Mitigation. </li>
        <li>Click the Save button to complete the step. </li>
        <li>Repeat the above steps as necessary.</li>
      </ol>
      <br/>
      <i>Note: The item number is automatically assigned in the order of creation. For example, the first item you add will be assigned the letter a, the second will be assigned the letter b, and so on.</i>
      <h3>How do I add TRR Level 3?</h3>
      <p>A Level 2 TRR must be completed prior to adding a Level 3 TRR. Additionally, a Level 3 TRR must be associated with a Level 2 TRR.</p>
      <ol>
        <li>Find the Level 2 TRR you want to link to a Level 3 TRR.</li>
        <li>Select the plus button associated with the Level 2 TRR. </li>
        <li>Choose a Special Interest from the drop-down menu. </li>
        <ol>
          <li>Special Interests include High Priority, Flagged, and Question. </li>
        </ol>
        <li>Select Technical Discipline(s) from the drop-down menu. </li>
        <ol>
          <li>Technical Disciplines include Programmatic, Technology, Training, Software, T&E, Logistics, Inter-operability, HSI and Risk.</li>
          <li>Press Add New to input additional Technical Disciplines. Type in the Name, and press Save. </li>
        </ol>
        <li>Provide a Description. </li>
        <li>Choose a Risk Character from the drop-down menu. </li>
        <ol>
          <li>Risk Characters include Red, Yellow, Green, Unknown/Unavailable, and Not Applicable. </li>
        </ol>
        <li>Type in Comments/Mitigation. </li>
        <li>Click Save to continue.</li>
        <li>Repeat the above steps as necessary.</li>
      </ol>
      <h3>How do I delete a TRR?</h3>
      <p>The steps remain consistent regardless of which TRR level is deleted. If a TRR Level 1 is deleted, all accompanying TRR levels are also removed.</p>
      <ol>
        <li>Locate the TRR that needs to be deleted.</li>
        <li>Press the more options icon (ellipses) associated with the TRR. </li>
        <li>Choose Delete TRR.</li>
        <li>Click Delete. </li>
      </ol>
      <img src={sixthImage} alt="sixthImage" />
      <br/>
      <i>Warning: Deleting a TRR will also delete all associated TRRs. </i>
      <h3>Can I sort by Technical Discipline?</h3>
      <p>After adding a Level 3 TRR to the Technical Readiness Review page, you can select a Technical Discipline.</p>
      <ol>
        <li>Click the drop-down menu linked to Technical Discipline.</li>
        <li>Select a Technical Discipline from the menu. </li>
      </ol>
      <img src={seventhImage} alt="seventhImage" />
      <br/>
      <i>Note: Click the X button to deselect the Technical Discipline.</i>
   </>
    )
}