import React, { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import IconSearch from 'assets/images/icon__search.svg';
import IconTimes from 'assets/images/icon__times.svg';

export default function SearchBar({ onChangeSearchTerm }) {
  const [searchTerm, setSearchTerm] = useState('');

  const onSearchReset = () => {
    setSearchTerm('');
    onChangeSearchTerm('');
  };

  const onChange = (event) => {
    setSearchTerm(event.target.value);
    onChangeSearchTerm(event.target.value);
  };

  return (
    <div className="form-search has-reset form-icon">
      <DebounceInput
        value={searchTerm}
        minLength={1}
        debounceTimeout={300}
        onChange={(e) => onChange(e)}
        placeholder="Search..."
        className="form-control"
      />
      <span className="icon">
        <img src={IconSearch} alt="" />
      </span>
      {!!searchTerm && (
        <span className="icon icon-reset" onClick={onSearchReset}>
          <img src={IconTimes} alt="" />
        </span>
      )}
    </div>
  );
}
