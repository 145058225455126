import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import { fetchIcsIncidentWorkAssignments } from './ics_incidentWorkAssignmentsSlice';

import { getUserGuid, getGroupGuid } from 'slices/sliceHelpers';

// Slice
const ics_workAssignmentsSlice = createSlice({
    name: 'ics_workAssignments',
    initialState: {
        ics_work_assignments: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        createIcsWorkAssignmentSuccess: (state, action) => {
            state.ics_work_assignments.push(action.payload);
        },
        fetchIcsWorkAssignmentsSuccess: (state, action) => {
            state.ics_work_assignments = action.payload;
        },
        updateIcsWorkAssignmentSuccess: (state, action) => {
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    createIcsWorkAssignmentSuccess,
    fetchIcsWorkAssignmentsSuccess,
    updateIcsWorkAssignmentSuccess,
    setError
} = ics_workAssignmentsSlice.actions;

export default ics_workAssignmentsSlice.reducer;

export const createIcsWorkAssignment = (data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_work_assignments}`, {...data, user_guid});
        if (data.incident_id) {
            dispatch(fetchIcsIncidentWorkAssignments(data.incident_id));
        }
        dispatch(fetchIcsWorkAssignments(group_guid));
        dispatch(createIcsWorkAssignmentSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsWorkAssignments = (group_guid) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_work_assignments}/${group_guid}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsWorkAssignmentsSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsWorkAssignment = (id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_work_assignments}/${id}`, {...data, user_guid});
        dispatch(fetchIcsWorkAssignments(group_guid));
        dispatch(updateIcsWorkAssignmentSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
