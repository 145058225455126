import { UPDATE_EVENT_ID } from "./PlanDashboard.constants";

const initialState = {
  eventId: null,
};

const PlanDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_EVENT_ID:
      return {
        ...state,
        eventId: action.payload,
      };

    default:
      return state;
  }
};

export default PlanDashboardReducer;
