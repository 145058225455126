import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { addNonMemberToIncident } from 'actions/incidentActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { AVAILABLE_ROLES as availableRoles } from './AvailableRoles';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { startLoading } from 'reducers/loading/loading.action';

export default function LogNonMemberDialog({ show, onClose, incident_id }) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const [isLoaded, setIsLoaded] = useState(true);

  const reduxDispatch = useDispatch();

  const isGetRosterForIncidentLoaded = useSelector((state) => {
    return state.app.isGetRosterForIncidentLoaded;
  });
  const isAddNonUserToIncidentByAdminLoaded = useSelector((state) => {
    return state.app.isAddNonUserToIncidentByAdminLoaded;
  });

  useEffect(() => {
    if (
      !isLoaded &&
      isAddNonUserToIncidentByAdminLoaded &&
      isGetRosterForIncidentLoaded
    ) {
      onClose();
    }
  }, [
    isAddNonUserToIncidentByAdminLoaded,
    isGetRosterForIncidentLoaded,
    isLoaded,
    onClose,
  ]);

  useEffect(() => {
    setValue('login_time', new Date());
  }, [show]);

  const onLogNonMemberDataSubmit = (data) => {
    const logoutTime = moment(data.login_time)
      .add(data.minutes_worked, 'm')
      .toDate();
    const incidentNonMember = {
      id: generateUUID(),
      name: data.name,
      location: data.location,
      seat: data.seat.value,
      minutes_worked: data.minutes_worked,
      loginTime: new Date(data.login_time).toISOString(),
      logoutTime: new Date(logoutTime).toISOString(),
      note: data.note,
    };
    reduxDispatch(startLoading());
    reduxDispatch(
      addNonMemberToIncident({
        incidentNonMember,
        incident_id,
      })
    );
    onClose();
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Log Non-member Time</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onLogNonMemberDataSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Name:</label>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                />
              )}
            />
            {errors.name && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Seat:</label>
            <Controller
              control={control}
              name="seat"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={availableRoles.map((role) => {
                    return { value: role, label: role };
                  })}
                  defaultValue={value}
                  value={value || ''}
                  onChange={(e) => onChange(e)}
                  placeholder={'Assign a Seat'}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                />
              )}
            />
            {errors.seat && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Login Time</label>
            <Controller
              control={control}
              name="login_time"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishDateTimePicker
                  value={value}
                  onChange={(e) => onChange(e)}
                  type="datetime-local"
                  minDate={new Date()}
                />
              )}
            />
            {errors.login_time && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Minutes Worked:</label>
                <Controller
                  control={control}
                  name="minutes_worked"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      type="number"
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                    />
                  )}
                />
                {errors.minutes_worked && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Location:</label>
                <Controller
                  control={control}
                  name="location"
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      type="text"
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="m-0">
            <label className="form-label">Note:</label>
            <Controller
              control={control}
              name="note"
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                />
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
