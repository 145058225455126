export const Config205A = {
  formname: 'ICS 205A',
  filename: 'ICS_205A.PDF',
  field: [
    {
      key: '1 Incident Name_9',
      type: 'Text',
      value: '',
    },
    {
      key: 'Date From',
      type: 'Text',
      value: '',
    },
    {
      key: 'Date To',
      type: 'Text',
      value: '',
    },
    {
      key: 'Time From',
      type: 'Text',
      value: '',
    },
    {
      key: 'Time To',
      type: 'Text',
      value: '',
    },
    {
      key: 'Basic Local Communications Information',
      type: 'Table',
      value: [],
    },
    {
      key: '4 Prepared by Name',
      type: 'Text',
      valeu: '',
    },
    {
      key: 'PositionTitle_8',
      type: 'Text',
      valeu: '',
    },
    {
      key: 'Signature_10',
      type: 'Image',
      value: '',
    },
    {
      key: 'DateTime_9',
      type: 'Text',
      valeu: '',
    },
  ],
};
