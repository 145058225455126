// PromptLibrary.js
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCreatePrompt, usePrompts } from './hooks/usePromptLibraryHooks';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { selectGroupGuid } from 'slices/commonSelectors';
import Accordion from 'react-bootstrap/Accordion';
import './CustomAccordion.css';

// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemButton,
//   AccordionItemHeading,
//   AccordionItemPanel,
// } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { useToggle } from 'react-use';
import PromptDrawer from './PromptDrawer';

const PromptLibrary = ({ onUsePrompt }) => {
  const group_guid = useSelector(selectGroupGuid);
  const { data: prompts = [], error, isLoading } = usePrompts(group_guid);
  
  const createPrompt = useCreatePrompt();
  const [promptData, setPromptData] = useState([]);
  const [addOpen, toggleAddDrawer] = useToggle(false); //Keep this AccordionItem closed by default

  useEffect(() => {
    if (prompts.length > 0) {
      setPromptData(prompts.filter(p=>!p.archived));
    }
  }, [prompts]);

  const columns = [
    {
      dataField: 'title',
      text: 'Name',
      sort: true,
      attrs: { title: 'Name' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: false,
      formatter: (cellContent) => {
        if (cellContent && cellContent.length > 255) {
          return cellContent.substring(0, 255) + '...';
        }
        return cellContent;
      },
      attrs: { title: 'Description' },
    },
    {
      dataField: 'use',
      text: 'Use',
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <StylishNewButton
            onClick={() => onUsePrompt(row)}
          >
            Use
          </StylishNewButton>
        );
      },
      attrs: { title: 'Use' },
    },
  ];

  return (
    <div>
          <Accordion.Header className="border-b">Prompt Library</Accordion.Header>
          <Accordion.Body>
            <StylishNewButton
              className="button--primary"
              style={{ margin: '10px' }}
              onClick={toggleAddDrawer} 
            >
              Add Prompt
            </StylishNewButton>
            {isLoading && <div>Loading...</div>}
            {error && <div>Error loading prompts</div>}
            {!isLoading && !error && (
              <StylishNewTable
                keyField={'id'}
                rows={promptData}
                columns={columns}
              />
            )}
          </Accordion.Body>
      {addOpen && <PromptDrawer toggle={toggleAddDrawer} onSubmit={createPrompt.mutate} />}
    </div>
  );
};

export default PromptLibrary;
