import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import TeamRosters from 'components/GroupsRoute/TeamRosters';

export const AttendeesModal = ({
  show,
  onClose,
  teamsSelected,
  onChangeTeamSelection,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Team Roster</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TeamRosters
            allowSelection={true}
            teamsSelected={teamsSelected}
            onChangeTeamSelection={onChangeTeamSelection}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--primary"
              type="button"
              onClick={onClose}
            >
              Save
            </StylishNewButton>
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={onClose}
            >
              Cancel
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
