import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import {
  fetchIcsTactics
} from 'slices/ics_tacticsSlice'

import {
    getUserGuid,
    getGroupGuid
} from 'slices/sliceHelpers'

// Slice
const ics_incident_tacticsSlice = createSlice({
    name: 'ics_incident_tactics',
    initialState: {
        ics_incident_tactics: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        fetchIcsIncidentTacticsSuccess: (state, action) => {
            state.ics_incident_tactics = action.payload;
            state.status='succeeded'
        },
        createIcsIncidentTacticSuccess: (state, action) => {
            state.status='succeeded'
        },
        updateIcsIncidentTacticSuccess: (state, action) => {
            state.status='succeeded'
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    fetchIcsIncidentTacticsSuccess,
    createIcsIncidentTacticSuccess,
    updateIcsIncidentTacticSuccess,
    setError
} = ics_incident_tacticsSlice.actions;

export default ics_incident_tacticsSlice.reducer;

export const createIcsIncidentTactic = (incidentId, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_incident_tactics}/${incidentId}`, { ...data, user_guid });
        dispatch(fetchIcsTactics(group_guid));
        dispatch(fetchIcsIncidentTactics(incidentId));
        dispatch(createIcsIncidentTacticSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsIncidentTactics = (incidentId) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_incident_tactics}/${incidentId}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsIncidentTacticsSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsIncidentTactic = (incidentId, id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_incident_tactics}/${incidentId}/${id}`, { ...data, user_guid });
        dispatch(fetchIcsTactics(group_guid));
        dispatch(fetchIcsIncidentTactics(incidentId));
        dispatch(updateIcsIncidentTacticSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
