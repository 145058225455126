import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import moment from 'moment';
import { MdOutlineAutorenew } from 'react-icons/md';
import {
  TASK_COLOR_TAB_DATA,
  TASK_STATUS_LIST,
} from './TaskManagementConstant';
import TaskManagementCreateEditModal from './TaskManagementCreateEditModal';
import { fetchTaskListDataAction } from 'actions/TaskManagementActions';
import SearchBar from 'PREPAREsrc/component/SearchBar/SearchBar';
import TaskManagementTimelineList from './TaskManagementTimelineList';
import TaskManagementFilter from './TaskManagementFilter';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import PageTitle from 'components/headers/PageTitle';
import { SharedIcon } from '../SharedIcon/SharedIcon';

export default function TaskManagementTimeline() {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const [selectedFilters, setSelectedFilters] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedColorTab, setSelectedColorTab] = useState(
    TASK_COLOR_TAB_DATA[0].value
  );

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const reduxSaveTaskSuccess = useSelector((state) => {
    return state.app.saveTaskSuccess;
  });
  const reduxTaskList = useSelector((state) => {
    return state.app.taskList;
  });
  const totalTaskList = useSelector((state) => {
    return state.app.totalTaskList;
  });
  const reduxIsTaskListDataLoaded = useSelector((state) => {
    return state.app.isTaskListDataLoaded;
  });

  const handleCreateNewTask = () => {
    setOpenModal(true);
    setSelectedTask(null);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedTask(null);
  };

  const handleClearStartEndTime = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const refreshTaskList = () => {
    if (reduxCurrentlySelectedGroup?.group_guid) {
      const filter = {
        assessment: selectedFilters?.assessment?.value,
        restoration: selectedFilters?.restoration?.value,
        stability: selectedFilters?.stability?.value,
        priority: selectedFilters?.priority?.value,
        team: selectedFilters?.team?.value,
      };
      reduxDispatch(
        fetchTaskListDataAction(
          reduxCurrentlySelectedGroup?.group_guid,
          filter,
          currentPage,
          searchTerm,
          startDate,
          endDate
        )
      );
    }
  };

  useEffect(() => {
    if (!startDate && !endDate) {
      refreshTaskList();
    }
  }, [startDate, endDate]);

  const handleClickTask = (task) => {
    setOpenModal(true);
    setSelectedTask(task);
  };

  const filterTaskList = useMemo(() => {
    let filterTaskList = [];
    if (reduxTaskList?.length > 0) {
      const sortedTaskList = [...reduxTaskList].sort((a, b) => {
        // Assuming 'created_at' is a date string in a format that Moment.js can parse
        const dateA = moment(a.created_at);
        const dateB = moment(b.created_at);
        return dateB - dateA;
      });

      filterTaskList = sortedTaskList.map((item, index) => {
        return {
          ...item,
          index: index + 1,
          status: TASK_STATUS_LIST.find((elem) => elem.value === item.status)
            ?.label,
        };
      });
    }

    return filterTaskList;
  }, [reduxTaskList, startDate, endDate]);

  const handleSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
  };

  const handleChangeFilters = (val) => {
    setSelectedFilters(val);
  };

  useEffect(() => {
    if (reduxCurrentlySelectedGroup.group_guid) {
      const filter = {
        assessment: selectedFilters?.assessment?.value,
        restoration: selectedFilters?.restoration?.value,
        stability: selectedFilters?.stability?.value,
        priority: selectedFilters?.priority?.value,
        team: selectedFilters?.team?.value,
      };
      reduxDispatch(
        fetchTaskListDataAction(
          reduxCurrentlySelectedGroup.group_guid,
          filter,
          currentPage,
          searchTerm,
          startDate,
          endDate
        )
      );
    }
  }, [
    searchTerm,
    currentPage,
    selectedFilters,
    reduxSaveTaskSuccess,
    reduxCurrentlySelectedGroup,
  ]);

  return (
    <>
      <div className="site-main">
        <div className="container-fluid">
          <PageTitle title="Task Timeline" parents={['Task Management']} />

          <div className="row mb-2 mt-3">
            <div className="col-12 col-md-6">
              <div className="button-group">
                <StylishNewButton
                  type="button"
                  className="button--primary w-100 w-md-auto"
                  onClick={() => {
                    handleCreateNewTask();
                  }}
                >
                  Create Critical Task
                </StylishNewButton>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="d-md-flex mt-2 mt-lg-0">
                <StylishNewToggleButtonGroup
                  data={TASK_COLOR_TAB_DATA}
                  selected={selectedColorTab}
                  onChange={(val) => {
                    setSelectedColorTab(val);
                  }}
                  className="ms-auto"
                />
              </div>
            </div>
          </div>

          <div className="d-md-flex align-items-end mt-4">
            <div className="row flex-grow-1 align-items-end">
              <div className="col-md-4 mb-3 mb-md-0">
                <StylishDateTimePicker
                  dateLabel="Start Date & Time"
                  onChange={(val) => setStartDate(val)}
                  value={startDate}
                  isClearable={true}
                  placeholderText={'MM/DD/YYYY'}
                />
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <StylishDateTimePicker
                  dateLabel="End Date & Time"
                  onChange={(val) => setEndDate(val)}
                  value={endDate}
                  isClearable={true}
                  placeholderText={'MM/DD/YYYY'}
                />
              </div>

              <div className="col-md-4 mb-3 mb-md-0">
                <div className="button-group">
                  <StylishNewButton
                    type="button"
                    className="button--primary"
                    onClick={() => refreshTaskList()}
                    disabled={((!startDate || !endDate) && true) || false}
                  >
                    {reduxIsTaskListDataLoaded === false ? (
                      <div className="w-100 d-flex">
                        <i
                          className="fa fa-spinner fa-pulse m-auto"
                          style={{ fontSize: '20px', lineHeight: '20px' }}
                        ></i>
                      </div>
                    ) : (
                      'Apply'
                    )}
                  </StylishNewButton>
                  <StylishNewButton
                    className={`button--primary has-img-h-20`}
                    onClick={() => {
                      handleClearStartEndTime();
                    }}
                    disabled={((!startDate || !endDate) && true) || false}
                  >
                    <MdOutlineAutorenew className="refresh-icon" />
                  </StylishNewButton>
                </div>
              </div>
            </div>
            <div className="ms-auto flex-shrink-0">
              {filterTaskList?.length || 0} tasks
            </div>
          </div>

          <div className="form-block mt-5">
            <div className="mb-3">
              <TaskManagementFilter
                onChange={(val) => handleChangeFilters(val)}
                filters={selectedFilters}
              />
              <hr className="dashed my-4" />
            </div>

            <div className="row">
              <div className="col-12 col-lg-6">
                <SearchBar
                  onChangeSearchTerm={(val) => handleSearchTerm(val)}
                />
              </div>
            </div>
            <TaskManagementTimelineList
              selectedColorTab={selectedColorTab}
              onSearchTerm={(val) => handleSearchTerm(val)}
              tasks={filterTaskList}
              page={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
              totalTasks={totalTaskList}
              onSelectTask={(task) => handleClickTask(task)}
            />
          </div>
        </div>
      </div>

      {!!openModal && (
        <TaskManagementCreateEditModal
          selectedTask={selectedTask}
          onClose={() => handleModalClose()}
        />
      )}
    </>
  );
}
