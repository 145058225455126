import React from 'react';
import { useSelector } from 'react-redux';

import SearchBar from 'PREPAREsrc/component/SearchBar/SearchBar';
import { getAbbreviationName, getProcessStability, getTaskStatusColor } from './TaskManagementUtil';
import { RTO_TIME_FRAME_OPTIONS, TASK_COLOR_TAB_DATA } from './TaskManagementConstant';

export default function TaskManagementList(props) {
  const {
    tasks,
    selectedColorTab = TASK_COLOR_TAB_DATA[0].value,
    onSearchTerm,
    onSelectTask,
  } = props;

  const rostersForGroup = useSelector(state => {
    return state.app.rostersForGroups || []
  });

  const onChangeSearchTerm = (termSearched) => {
    onSearchTerm(termSearched);
  };

  const getAssigneeName = (task) => {
    let res = '';
    if (task?.process_owner?.length > 0 && rostersForGroup?.length > 0) {
      const person = task?.process_owner[0]
      if (person?.user_name) {
        res = getAbbreviationName(person?.user_name);
      }
    }

    return res;
  }

  return (
    <div className="row">
      <div className="col-sm-9">
        <SearchBar onChangeSearchTerm={(val) => onChangeSearchTerm(val)} />
      </div>
      
      <div className="task-list mt-4">
        {tasks?.length > 0 ? (
          tasks.map((task, index) => {
            let stability = null;
            let bgColor = getTaskStatusColor(task.status);
            let statusLabel = task.status;
            if (selectedColorTab === TASK_COLOR_TAB_DATA[1].value) {
              const rtoTimeFrameObj = RTO_TIME_FRAME_OPTIONS.find(frame => frame.value === task?.rto_time_frame);
              stability = getProcessStability(
                task?.status, 
                task?.deadline_date, 
                task?.restoration_status, 
                parseInt(task?.rto),
                rtoTimeFrameObj,
                task?.is_critical
              );
              bgColor = stability?.color;
              statusLabel = stability?.label;
            }
            return (
              <div className="d-flex bg-gray-800 tasklist-card cursor-pointer" 
                key={'tasklist-card' + index}
                onClick={() => onSelectTask(task)}
              >
                <span style={{width: '20px'}}>#{index + 1}</span>
                <div className="task-status-badge mx-2" 
                  style={{background: bgColor}}
                >
                  {statusLabel}
                </div>
                <div className="task-content">{task.title}</div>
                <span className="task-person-name">{getAssigneeName(task)}</span>
              </div>
            )
          })
        ) : (
          <div className="d-flex bg-gray-800 tasklist-card">No Task Found</div>
        )}
      </div>
    </div>
  );
}
