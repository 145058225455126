import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {
  fetchNotificationLog,
  fetchNotificationLogInstance,
} from 'actions/notificationActions';

import { sendNotificationByGroup } from 'actions/notificationActions';

import SelectSendAttemptDialog from './SelectSendAttemptDialog';
import PDFPreviewDialog from './PDFPreviewDialog';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { FETCH_NOTIFICATION_LOG_INSTANCE_SUCCESS } from 'constants/notification-action-types';
import './NotificationsContent.css';
import { useAppSelector } from '../../slices/commonSelectors';
import PageTitle from '../headers/PageTitle';

export default function NotificationLogScreen() {
  const [
    selectSendAttemptDialogOpen,
    setSelectSendAttemptDialogOpen,
  ] = useState(false);

  const [logInstance, setLogInstance] = useState();

  const [pdfPreviewOpen, setPDFPreviewOpen] = useState();

  const [componentSendResult, setComponentSendResult] = useState();

  const dispatch = useDispatch();

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxNotificationLog = useSelector((state) => {
    return state.app.notificationLog;
  });

  const isFetchNotificationLogLoaded = useSelector((state) => {
    return state.app.isFetchNotificationLogLoaded;
  });

  const isSendNotificationLoaded = useSelector((state) => {
    return state.app.isSendNotificationLoaded;
  });

  const reduxSendNotificationResult = useSelector((state) => {
    return state.app.sendNotificationResult;
  });

  const selectedTemplate = useAppSelector(s => s.notifications.selectedTemplate)

  useEffect(() => {
    dispatch({
      type: FETCH_NOTIFICATION_LOG_INSTANCE_SUCCESS,
      payload: [],
    });
  }, [reduxCurrentlySelectedGroup]);

  const reduxNotificationLogInstance = useSelector((state) => {
    return (
      !!state.app.notificationLogInstance &&
      state.app.notificationLogInstance.map((item) => {
        return {
          ...item,
          id:
            item.email_address ||
            item.mobile_phone_number ||
            item.home_phone_number ||
            item.pager_address,
        };
      })
    );
  });

  const isFetchNotificationLogInstanceLoaded = useSelector((state) => {
    return state.app.isFetchNotificationLogInstanceLoaded;
  });

  useEffect(() => {
    setComponentSendResult(reduxSendNotificationResult);
  }, [reduxSendNotificationResult]);

  useEffect(() => {
    if (selectedTemplate) {
      dispatch(fetchNotificationLog(selectedTemplate));
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (logInstance) {
      dispatch(
        fetchNotificationLogInstance(
          reduxCurrentlySelectedGroup.group_guid,
          logInstance
        )
      );
    }
    setComponentSendResult();
  }, [logInstance]);

  const notificationLogColumns = [
    {
      dataField: 'user_name',
      text: 'Member Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Member Name' },
    },
    {
      dataField: 'delivery_status',
      text: 'Delivery Status',
      sort: true,
      attrs: { title: 'Delivery Status' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          {logHasEmailType && row.email_status && (
            <>
              <div className="my-3">
                <p className="mb-1">
                  <span className="weight-500">Email Address:</span>{' '}
                  {row.email_address}
                </p>
                <ul className="list">
                  <li>Email Status: {row.email_status}</li>
                </ul>
              </div>
            </>
          )}

          {((logHasSMSType && row.sms_status) ||
            (logHasTTSCellType && row.tts_cell_status)) && (
            <>
              <div className="my-3">
                <p className="m-0">
                  <span className="weight-500">Mobile Phone Number:</span>{' '}
                  {row.mobile_phone_number}
                </p>
              </div>
            </>
          )}

          {logHasSMSType && row.sms_status && (
            <>
              <div className="my-3">
                <p className="m-0">
                  <span className="weight-500">SMS Status:</span>{' '}
                  {row.sms_status}
                </p>
              </div>
            </>
          )}

          {logHasTTSCellType && row.tts_cell_status && (
            <>
              <div className="my-3">
                <p className="m-0">
                  <span className="weight-500">
                    Text-To-Speech (cell) Status:
                  </span>{' '}
                  {row.tts_cell_status}
                </p>
              </div>
            </>
          )}

          {logHasTTSHomeType && row.tts_home_status && (
            <>
              <div className="my-3">
                <p className="mb-1">
                  <span className="weight-500">Home Phone Number:</span>{' '}
                  {row.home_phone_number}
                </p>
                <ul className="list">
                  <li>Text-To-Speech (home) Status: {row.tts_home_status}</li>
                </ul>
              </div>
            </>
          )}

          {logHasPagerType && row.pager_status && (
            <>
              <div className="my-3">
                <p className="mb-1">
                  <span className="weight-500">Pager Address:</span>{' '}
                  {row.pager_address}
                </p>
                <ul className="list">
                  <li>Pager Status: {row.pager_status}</li>
                </ul>
              </div>
            </>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const logHasSMSType =
    !!logInstance &&
    logInstance.notification_at_time_of_send.settings.recipientSettings.recipientTypes.find(
      (type) => type === 'SMS'
    );

  const logHasTTSHomeType =
    !!logInstance &&
    logInstance.notification_at_time_of_send.settings.recipientSettings.recipientTypes.find(
      (type) => type === 'Text-To-Speech (home)'
    );

  const logHasTTSCellType =
    !!logInstance &&
    logInstance.notification_at_time_of_send.settings.recipientSettings.recipientTypes.find(
      (type) => type === 'Text-To-Speech (cell)'
    );

  const logHasEmailType =
    !!logInstance &&
    logInstance.notification_at_time_of_send.settings.recipientSettings.recipientTypes.find(
      (type) => type === 'Email'
    );

  const logHasPagerType =
    !!logInstance &&
    logInstance.notification_at_time_of_send.settings.recipientSettings.recipientTypes.find(
      (type) => type === 'Pager'
    );

  function snapIt() {
    html2canvas(document.querySelector('.notificationLog-pdfPreview')).then(
      (canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'portrait',
        });
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        let testHeight;
        let testWidth;

        const aspectRatioImage = imgProps.height / imgProps.width;

        const aspectRatioPdf = pdfHeight / pdfWidth;

        if (aspectRatioImage > aspectRatioPdf) {
          testHeight = pdf.internal.pageSize.getHeight();
          testWidth = (imgProps.width * pdfHeight) / imgProps.height;
        } else {
          testWidth = pdf.internal.pageSize.getWidth();
          testHeight = (imgProps.height * pdfWidth) / imgProps.width;
        }

        pdf.addImage(imgData, 'PNG', 0, 0, testWidth, testHeight);
        pdf.save('download.pdf');
      }
    );
  }

  const handleResendButton = (data) => {
    dispatch(sendNotificationByGroup(data));
  };

  const LogSummary = () => {
    //TODO(aeortic): The situation here is that we have a structure, but it is not consistent.
    // We need to refactor that structure so that it is easier to reason about.
    // For example, the text-to-speech message is in the "content settings" node,
    // but the sms message is in the "recipeint settings" node.

    const settings =
      logInstance.notification_at_time_of_send.settings;

    const contentSettings = settings.contentSettings;

    const textToSpeechMessage = contentSettings.TTSMessage;

    const smsMessage =
      settings.recipientSettings.SMSRecipientSettings.SMSMessage;

    const types =
      logInstance.notification_at_time_of_send.settings
        .recipientSettings.recipientTypes;

    const showTTSMessage = types.find(
      (type) =>
        type === 'Text-To-Speech (cell)' || type === 'Text-To-Speech (home)'
    );
    const showSMSMessage = !!types.find((type) => type === 'SMS');

    return (
      <>
        {!!logInstance.notification_at_time_of_send
          .template_title && (
          <p className="weight-500 mb-1">
            Titlsse:{' '}
            {logInstance.notification_at_time_of_send.template_title}
          </p>
        )}
        {!!logInstance.subject && (
          <p className="weight-500 mb-1">
            Subject: {logInstance.subject}
          </p>
        )}
        {!!logInstance.message && (
          <p className="weight-500 mb-1">
            Email Message: {logInstance.message}
          </p>
        )}
        {!!logInstance.notification_sent_at_timestamp && (
          <p className="weight-500 mb-1">
            Time:{' '}
            {dayjs(logInstance.notification_sent_at_timestamp).format(
              'MM/DD/YYYY h:mm A'
            )}
          </p>
        )}
        {!!logInstance.user_name && (
          <p className="weight-500 mb-1">
            Sent by: {logInstance.user_name}
          </p>
        )}
        {!!logInstance.notification_at_time_of_send.settings
          .recipientSettings.recipientTypes && (
          <p className="weight-500 mb-1">
            Notification Types:{' '}
            {logInstance.notification_at_time_of_send.settings.recipientSettings.recipientTypes.join(
              ', '
            )}
          </p>
        )}
        {smsMessage && showSMSMessage && (
          <p className="weight-500 mb-1">SMS Message: {smsMessage}</p>
        )}
        {textToSpeechMessage && showTTSMessage && (
          <p className="weight-500 mb-1">
            Text-To-Speech Message: {textToSpeechMessage}
          </p>
        )}
        <hr className="dashed" />
      </>
    );
  };

  return (
    <>
      <PageTitle title="Notification Logs" >
        <StylishNewButton
          className={'button--secondary button--reverse w-100 w-md-auto'}
          onClick={() => setSelectSendAttemptDialogOpen(true)}
        >
          Select Notification Log
        </StylishNewButton>
      </PageTitle>
      {(!!reduxNotificationLogInstance &&
        !!reduxNotificationLogInstance.length && (
          <>
            {(!!isFetchNotificationLogLoaded && (
              <>
                {(!!logInstance &&
                  !!logInstance.notification_at_time_of_send && (
                    <>
                      <div className="form-block">
                        <LogSummary />
                        {(!!reduxNotificationLogInstance &&
                          !!isFetchNotificationLogInstanceLoaded && (
                            <>
                              <StylishNewTable
                                keyField={'id'}
                                columns={notificationLogColumns}
                                rows={reduxNotificationLogInstance}
                                expandRow={expandRow}
                              />
                            </>
                          )) || <div>Loading Notifications log data...</div>}
                        <div className="button-group mt-4 flex-wrap">
                          <StylishNewButton
                            className={'button--secondary-fill w-100 w-md-auto'}
                            onClick={() =>
                              handleResendButton(
                                logInstance.notification_at_time_of_send
                              )
                            }
                          >
                            Resend Notification
                          </StylishNewButton>
                          <StylishNewButton
                            className={
                              'button--primary w-100 w-md-auto ms-0 ms-md-3 mt-3 mt-md-0'
                            }
                            onClick={() => setPDFPreviewOpen(true)}
                          >
                            Export PDF
                          </StylishNewButton>
                        </div>
                        {!isSendNotificationLoaded && (
                          <p className="mt-4 mb-0 weight-600">
                            Sending Notification...
                          </p>
                        )}
                        {!!isSendNotificationLoaded && componentSendResult && (
                          <p className="mt-4 mb-0 weight-600">
                            Notification Sent!
                          </p>
                        )}
                      </div>
                    </>
                  )) || (
                  <div className="bg-gray-800 rounded p-5">
                    <div className="d-flex flex-column align-items-center">
                      <i
                        className="fa fa-exclamation-triangle color-brand fa-3x"
                        aria-hidden="true"
                      ></i>
                      <p className="weight-500 mt-2 mb-0 text-center">
                        Please select a Notification Log to get started.
                      </p>
                    </div>
                  </div>
                )}
              </>
            )) || (
              <p className="mb-0 weight-600">Loading Notification Log...</p>
            )}
          </>
        )) ||
        ((!logInstance ||
          (!!reduxNotificationLogInstance &&
            !reduxNotificationLogInstance.length)) && (
          <div className="bg-gray-800 rounded p-5">
            <div className="d-flex flex-column align-items-center">
              <i
                className="fa fa-exclamation-triangle color-brand fa-3x"
                aria-hidden="true"
              ></i>
              <p className="weight-500 mt-2 mb-0 text-center">
                Please select a Notification Log to get started.
              </p>
            </div>
          </div>
        )) || (
          <div className="bg-gray-800 rounded p-5">
            <div className="d-flex flex-column align-items-center">
              <i
                className="fa fa-spinner fa-pulse color-brand fa-3x"
                aria-hidden="true"
              ></i>
              <p className="weight-500 mt-2 mb-0">
                Loading Notifications Log...
              </p>
            </div>
          </div>
        )}
      {selectSendAttemptDialogOpen && (
        <SelectSendAttemptDialog
          show={selectSendAttemptDialogOpen}
          currentLoggedInstance={logInstance}
          setCurrentLoggedInstance={setLogInstance}
          onClose={() => setSelectSendAttemptDialogOpen(false)}
          notificationLog={reduxNotificationLog}
        />
      )}
      {pdfPreviewOpen && (
        <PDFPreviewDialog
          show={pdfPreviewOpen}
          onClose={() => setPDFPreviewOpen(false)}
          onCreatePDFClick={snapIt}
        >
          <div className="notificationLog-pdfPreview p-4">
            <LogSummary />
            {reduxNotificationLogInstance && reduxNotificationLogInstance.length
              ? reduxNotificationLogInstance.map((row, index) => (
                  <>
                    <div className={`${index !== 0 ? 'mt-4' : ''}`}>
                      <h5 className="mb-2">{row.user_name}</h5>
                      {logHasEmailType && row.email_status && (
                        <>
                          <div className="mt-2 mb-0">
                            <p className="mb-1">
                              <span className="weight-500">Email Address:</span>{' '}
                              {row.email_address}
                            </p>
                            <ul className="list">
                              <li>Email Status: {row.email_status}</li>
                            </ul>
                          </div>
                        </>
                      )}

                      {((logHasSMSType && row.sms_status) ||
                        (logHasTTSCellType && row.tts_cell_status)) && (
                        <>
                          <div className="mt-2 mb-0">
                            <p className="m-0">
                              <span className="weight-500">
                                Mobile Phone Number:
                              </span>{' '}
                              {row.mobile_phone_number}
                            </p>
                          </div>
                        </>
                      )}

                      {logHasSMSType && row.sms_status && (
                        <>
                          <div className="mt-2 mb-0">
                            <p className="m-0">
                              <span className="weight-500">SMS Status:</span>{' '}
                              {row.sms_status}
                            </p>
                          </div>
                        </>
                      )}

                      {logHasTTSCellType && row.tts_cell_status && (
                        <>
                          <div className="mt-2 mb-0">
                            <p className="m-0">
                              <span className="weight-500">
                                Text-To-Speech (cell) Status:
                              </span>{' '}
                              {row.tts_cell_status}
                            </p>
                          </div>
                        </>
                      )}

                      {logHasTTSHomeType && row.tts_home_status && (
                        <>
                          <div className="mt-2 mb-0">
                            <p className="mb-1">
                              <span className="weight-500">
                                Home Phone Number:
                              </span>{' '}
                              {row.home_phone_number}
                            </p>
                            <ul className="list">
                              <li>
                                Text-To-Speech (home) Status:{' '}
                                {row.tts_home_status}
                              </li>
                            </ul>
                          </div>
                        </>
                      )}

                      {logHasPagerType && row.pager_status && (
                        <>
                          <div className="mt-2 mb-0">
                            <p className="mb-1">
                              <span className="weight-500">Pager Address:</span>{' '}
                              {row.pager_address}
                            </p>
                            <ul className="list">
                              <li>Pager Status: {row.pager_status}</li>
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ))
              : null}
          </div>
        </PDFPreviewDialog>
      )}
    </>
  );
}
