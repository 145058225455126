import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { createStrikeTeam, updateStrikeTeam } from 'slices/ics_strikeTeamsSlice';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';

import {strike_team_statuses} from './ICSConstants'

export default function NewStrikeTeamDialog({
  show,
  setEditStrikeTeamData,
  editStrikeTeamData,
  modalType,
  setModalType,
  onClose,
}) {
  const { handleSubmit, control, setValue, formState: { errors } } = useForm({ mode: 'onChange' });
  const [isLoaded, setIsLoaded] = useState(true);
  const [strikeTeamId, setStrikeTeamId] = useState('');
  const reduxDispatch = useDispatch();
  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);
  const reduxCurrentIncident = useSelector((state) => state.app.currentIncident);

  useEffect(() => {
    if (modalType === 'New') {
      setStrikeTeamId(generateUUID());
    } else if (modalType === 'Edit') {
      setStrikeTeamId(editStrikeTeamData.strike_team_id);
      setValue('description', editStrikeTeamData.description);
      setValue('name', editStrikeTeamData.name);
      setValue('setatus',editStrikeTeamData.status)
    }
  }, [modalType]);

  const onNewStrikeTeamDataSubmit = (data) => {
    const payload = {
      id: strikeTeamId,
      name: data.name,
      description: data.description,
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      incident_id: reduxCurrentIncident.id,
      status: data.status,
      archived: false,
      strike_team_id: strikeTeamId
    };
    if (modalType === 'New') {
      reduxDispatch(createStrikeTeam(payload));
      onClose();
    } else if (modalType === 'Edit') {
      reduxDispatch(updateStrikeTeam(payload.id, payload));
      onClose();
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered backdrop="static" keyboard={false} size="lg">
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Strike Team</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewStrikeTeamDataSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">ID</label>
            <StylishNewInput value={strikeTeamId} readOnly disabled />
          </div>
          <div className="m-0">
            <label className="form-label">Name:</label>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.description && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Description:</label>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Status:</label>
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={strike_team_statuses.map(s=>{return {label:s,value:s}})}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                  isClearable={true}
                  isSearchable={true}
                  className="SitrepSection-Select"
                />
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton className="button--secondary button--reverse" type="button" onClick={onClose}>
              Close
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
