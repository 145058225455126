import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { ResourceTypes } from '../IncidentConfig/ResourceTypes';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';

import {
  updateIcsIncidentResource,
  createIcsIncidentResource,
} from 'slices/ics_incidentResourcesSlice';

export default function NewICSResourceDialog({
  show,
  setEditResourceData,
  editResourceData,
  modalType,
  setModalType,
  onClose,
  incident_id,
  ics_incident_tactics, // Include incident tactics
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  

  const [kind, setKind] = useState(''); // Add state for kind
  const [type, setType] = useState(''); // Add state for type
  const [resourceId, setResourceId] = useState('');
  const [selectedTactics, setSelectedTactics] = useState([]); // State for selected tactics

  const reduxDispatch = useDispatch();
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (modalType === 'New') {
      setResourceId(generateUUID());
    } else if (modalType === 'Edit') {
      setResourceId(editResourceData.incident_resource_id);
      setValue('name', editResourceData.name);
      setValue('description', editResourceData.description);
      setValue('required_quantity', editResourceData.required_quantity);
      setValue('dateTimeOrdered', editResourceData.dateTimeOrdered);
      setValue('eta', editResourceData.eta);
      setValue('arrived', editResourceData.arrived);
      setSelectedTactics(editResourceData.tactics || []); // Set selected tactics for edit mode
    }
  }, [modalType]);

  const onNewResourceDataSubmit = (data) => {
    let payload = {
      incident_resource_id: resourceId,
      name: data.name,
      description: data.description,
      required_quantity: data.required_quantity,
      res_kind: kind, // Include kind in the payload
      type: type, // Include type in the payload
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      archived: false,
      resource_id: resourceId,
      incident_id: incident_id,
      user_guid: data.user_guid,
      date_time_ordered: data.dateTimeOrdered,
      eta: data.eta,
      arrived: data.arrived,
      tactics: selectedTactics.map((tac) => tac.value), // Include selected tactics
    };

    if (modalType === 'New') {
      reduxDispatch(createIcsIncidentResource(incident_id, payload));
      onClose();
    } else if (modalType === 'Edit') {
      reduxDispatch(
        updateIcsIncidentResource(incident_id, payload.incident_resource_id, payload)
      );
      onClose();
    }
  };

  function resourceSelected(res) {
    const fullResource = ResourceTypes.find(r => r.name === res.label)
    setKind(fullResource.name)
    setType(fullResource.Type)
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Resource Requirement</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewResourceDataSubmit)}>
        <Modal.Body>
          <div className="m-0">
            <label className="form-label">Notes (Location/Assignment/Status):</label>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Resource:</label>
            <Controller
              control={control}
              name="type"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={ResourceTypes.map((type) => ({
                    value: type.name,
                    label: type.name,
                  }))}
                  defaultValue={value}
                  value={value || ''}
                  onChange={(e) => { onChange(e); !!e && resourceSelected(e) }}
                  placeholder={'Please Select a Type'}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                />
              )}
            />
            {errors?.type && (
              <span className="form-text form-error">This field is required</span>
            )}
            {!!kind && !!type && (
              <div>
                <div>
                  <label className="form-label">Resource Kind: {kind}</label>
                </div>
                <div>
                  <label className="form-label">Resource Type: {type}</label>
                </div>
              </div>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Quantity Required:</label>
            <Controller
              control={control}
              name="required_quantity"
              rules={{ required: true, validate: value => value > 0 }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="number"
                  onChange={(e) => onChange(parseFloat(e.target.value))}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.quantity && (
              <span className="form-text form-error">
                This field is required and must be greater than 0
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Tactics:</label>
            <StylishNewSelect
              options={ics_incident_tactics.map((tac) => ({
                label: tac.name,
                value: tac.tactic_id,
              }))}
              defaultValue={selectedTactics}
              onChange={setSelectedTactics}
              placeholder="Select Tactics"
              isMulti
            />
          </div>
          {incident_id && (
            <>
              <div className="m-0">
                <label className="form-label">Date Time Ordered:</label>
                <Controller
                  control={control}
                  name="dateTimeOrdered"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      type="datetime-local"
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">ETA:</label>
                <Controller
                  control={control}
                  name="eta"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      type="datetime-local"
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">Arrived:</label>
                <Controller
                  control={control}
                  name="arrived"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      type="checkbox"
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                    />
                  )}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
