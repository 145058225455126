export const FETCH_ALL_ROLES_IN_GROUP_REQUEST = 'FETCH_ALL_ROLES_IN_GROUP_REQUEST';
export const FETCH_ALL_ROLES_IN_GROUP_SUCCESS = 'FETCH_ALL_ROLES_IN_GROUP_SUCCESS';
export const FETCH_ALL_ROLES_IN_GROUP_ERROR = 'FETCH_ALL_ROLES_IN_GROUP_ERROR';
export const FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_REQUEST = 'FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_REQUEST';
export const FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_SUCCESS = 'FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_SUCCESS';
export const FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_ERROR = 'FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_ERROR';
export const FETCH_ROLE_ACTIONS_LOG_REQUEST = 'FETCH_ROLE_ACTIONS_LOG_REQUEST';
export const FETCH_ROLE_ACTIONS_LOG_SUCCESS = 'FETCH_ROLE_ACTIONS_LOG_SUCCESS';
export const FETCH_ROLE_ACTIONS_LOG_ERROR = 'FETCH_ROLE_ACTIONS_LOG_ERROR';
export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_ERROR = 'CREATE_ROLE_ERROR';
export const EDIT_ROLE_REQUEST = 'EDIT_ROLE_REQUEST';
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS';
export const EDIT_ROLE_ERROR = 'EDIT_ROLE_ERROR';
export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';
export const ASSIGN_ROLE_REQUEST = 'ASSIGN_ROLE_REQUEST';
export const ASSIGN_ROLE_SUCCESS = 'ASSIGN_ROLE_SUCCESS';
export const ASSIGN_ROLE_ERROR = 'ASSIGN_ROLE_ERROR';
export const UNASSIGN_ROLE_REQUEST = 'UNASSIGN_ROLE_REQUEST';
export const UNASSIGN_ROLE_SUCCESS = 'UNASSIGN_ROLE_SUCCESS';
export const UNASSIGN_ROLE_ERROR = 'UNASSIGN_ROLE_ERROR';
export const FETCH_ROSTERS_FOR_GROUPS_REQUEST = 'FETCH_ROSTERS_FOR_GROUPS_REQUEST';
export const FETCH_ROSTERS_FOR_GROUPS_SUCCESS = 'FETCH_ROSTERS_FOR_GROUPS_SUCCESS';
export const FETCH_ROSTERS_FOR_GROUPS_ERROR = 'FETCH_ROSTERS_FOR_GROUPS_ERROR';
export const FETCH_ALL_PERMISSIONS_REQUEST = 'FETCH_ALL_PERMISSIONS_REQUEST';
export const FETCH_ALL_PERMISSIONS_SUCCESS = 'FETCH_ALL_PERMISSIONS_SUCCESS';
export const FETCH_ALL_PERMISSIONS_ERROR = 'FETCH_ALL_PERMISSIONS_ERROR';
export const FETCH_ALL_PERMISSIONS_IN_ROLE_REQUEST = 'FETCH_ALL_PERMISSIONS_IN_ROLE_REQUEST';
export const FETCH_ALL_PERMISSIONS_IN_ROLE_SUCCESS = 'FETCH_ALL_PERMISSIONS_IN_ROLE_SUCCESS';
export const FETCH_ALL_PERMISSIONS_IN_ROLE_ERROR = 'FETCH_ALL_PERMISSIONS_IN_ROLE_ERROR';
export const REMOVE_USER_FROM_GROUP_REQUEST = 'REMOVE_USER_FROM_GROUP_REQUEST';
export const REMOVE_USER_FROM_GROUP_SUCCESS = 'REMOVE_USER_FROM_GROUP_SUCCESS';
export const REMOVE_USER_FROM_GROUP_ERROR = 'REMOVE_USER_FROM_GROUP_ERROR';
export const EDIT_GROUP_REQUEST = 'EDIT_GROUP_REQUEST';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_ERROR = 'EDIT_GROUP_ERROR';
export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR';
export const FETCH_INCIDENTS_FOR_GROUP_REQUEST = 'FETCH_INCIDENTS_FOR_GROUP_REQUEST';
export const FETCH_INCIDENTS_FOR_GROUP_SUCCESS = 'FETCH_INCIDENTS_FOR_GROUP_SUCCESS';
export const FETCH_INCIDENTS_FOR_GROUP_ERROR = 'FETCH_INCIDENTS_FOR_GROUP_ERROR';
