export const FETCH_SIGNATURES_REQUEST = 'FETCH_SIGNATURES_REQUEST';
export const FETCH_SIGNATURES_SUCCESS = 'FETCH_SIGNATURES_SUCCESS';
export const FETCH_SIGNATURES_ERROR = 'FETCH_SIGNATURES_ERROR';
export const UPSERT_SIGNATURE_REQUEST = 'UPSERT_SIGNATURE_REQUEST';
export const UPSERT_SIGNATURE_SUCCESS = 'UPSERT_SIGNATURE_SUCCESS';
export const UPSERT_SIGNATURE_ERROR = 'UPSERT_SIGNATURE_ERROR';
export const DELETE_SIGNATURE_REQUEST = 'DELETE_SIGNATURE_REQUEST';
export const DELETE_SIGNATURE_SUCCESS = 'DELETE_SIGNATURE_SUCCESS';
export const DELETE_SIGNATURE_ERROR = 'DELETE_SIGNATURE_ERROR';
export const FETCH_PREPARED_BY_SIGNATURES_SUCCESS =
  'FETCH_PREPARED_BY_SIGNATURES_SUCCESS';
export const FETCH_REVIEWED_BY_SIGNATURES_SUCCESS =
  'FETCH_REVIEWED_BY_SIGNATURES_SUCCESS';
