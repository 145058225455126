import React from 'react';
import {useSelector} from 'react-redux';

export default function LogoutRoute()
{
	const {msalInstance} = useSelector(state => {
    return state.auth
  })

  const handleLogout = () => {
    msalInstance.logout()
  }

  handleLogout()

	return (
		<div>Logging out...</div>
	)
}

