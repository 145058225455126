import React from 'react';

import {fillLinearGradient} from '../utils/mapUtils'

import './MapLegend.css'

export default function MapLegend({
	matches,
	weatherLayer
})
{

  const blackLabels = ['Cloudiness']

	return (
		<div className={`Map-Legend ${!matches&&'Map-Legend-Mobile'}`}>
      <div className={`Map-Legend-Label ${!matches&&'Map-Legend-Label-Mobile'}`}>
        <strong>{weatherLayer.key}</strong>
      </div>
      <div className="Map-Legend-Scale" style={{background:fillLinearGradient(weatherLayer)}}>
         {weatherLayer.legend.split(";").map((l,i)=> {
           return (
              <span 
                className={`Map-Legend-Item 
                  ${i===weatherLayer.legend.split(";").length-1 && 'Map-Legend-Item-Final' || ''} 
                  ${blackLabels.includes(weatherLayer.key) && 'Map-Legend-Item-Black-Text' || ''}`
                } 
                key={l.split(":")[0]}
              >
                {l.split(":")[0]}
              </span>
           )
         })}
      </div>
    </div>
	)
}