import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useActualIncidentId } from '../IAP/hooks/commonHooks';

const IncidentConfigurationRedirect: React.FC = () => {
  const { incident } = useParams<{ incident: UUID }>();
  const actualIncidentId = useActualIncidentId();

  return <Navigate to={`/iap/${incident ?? actualIncidentId}/settings`} />;
};
export default IncidentConfigurationRedirect;
