import User from "./user/user.reducer";
import workspacesReducer from "./workspaces/workspaces.reducer";
import exerciseReducer from "./exercise/exercise.reducer";
import { combineReducers } from "redux";
import ParticipantReducer from "./Participants/participant.reducer";
import PlanDashboardReducer from "./PlanDashboard/PlanDashboard.reducer";
import InjectReducer from "./injectReducer/inject.reducer";
import featureFlagReducer from "./FeatureFlag/FeatureFlag.reducer";

const reducer = combineReducers({
  user: User,
  workspace: workspacesReducer,
  exercise: exerciseReducer,
  participant: ParticipantReducer,
  planDashboard: PlanDashboardReducer,
  inject: InjectReducer,
  featureFlag: featureFlagReducer
});

export default reducer;
