import config from 'constants/apiConfig';
import axios from 'axios';
import {
  GET_ALL_ACTION,
  UPDATE_ACTION,
  ADD_NEW_ACTION,
  DELETE_ACTION,
  ADD_NEW_ACTION_REQUEST,
  ADD_NEW_ACTION_SUCCESS,
  GET_ALL_ACTION_REQUEST,
  GET_ALL_ACTION_SUCCESS,
  UPDATE_SITUATION_REQUEST,
  UPDATE_SITUATION_SUCCESS,
  FETCH_INCIDENT_ACTIONS_REQUEST,
  FETCH_INCIDENT_ACTIONS_SUCCESS,
  FETCH_INCIDENT_ACTIONS_ERROR,
} from 'constants/form-action-types';
import { getAllWorkAssignments } from './IAPForm204Actions';
import { endLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

function parseError(api, error) {
  const errorMessage =
    `${api}` +
    'There was an error saving your Form.  No associated Workflows have modified. Please try again.' +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
}

export const getAllAction = () => {
  return (dispatch, getState) => {
    dispatch({ type: GET_ALL_ACTION_REQUEST });
    const group_guid = getState().app.currentlySelectedGroup.group_guid
    return axios(config.apiGateway.getAllActionToGroup, {
      method: 'POST',
      data: JSON.stringify({
        group_guid
      })
    })
      .then((response) => {
        dispatch({ type: GET_ALL_ACTION_SUCCESS });
        dispatch({ type: GET_ALL_ACTION, payload: response.data });
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.getAllActionToGroup, error));
      });
  };
};

export const getAllActionByIncident = () => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_INCIDENT_ACTIONS_REQUEST });
    return axios(config.apiGateway.getAllActionToIncident, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup?.group_guid,
        incident_guid: getState().app.currentIncident?.id,
      }),
    })
      .then((response) => {
        dispatch({
          type: FETCH_INCIDENT_ACTIONS_SUCCESS,
          payload: response.data,
        });
        dispatch(endLoading());
      })
      .catch((error) => {
        dispatch({ type: FETCH_INCIDENT_ACTIONS_ERROR });
        dispatch(parseError(config.apiGateway.getAllActionToIncident, error));
        dispatch(endLoading());
      });
  };
};

export const addNewAction = (action) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_NEW_ACTION_REQUEST });
    return axios(config.apiGateway.addNewAction, {
      method: 'POST',
      data: JSON.stringify({
        action: {
          ...action,
          group_guid: getState().app.currentlySelectedGroup.group_guid,
        },
      }),
    })
      .then(() => {
        dispatch({ type: ADD_NEW_ACTION_SUCCESS });
        if (!!action.type && action.type === 'Work Assignment') {
          dispatch(getAllWorkAssignments());
        } else {
          dispatch(getAllActionByIncident());
        }
        // toast.success('Create new Action successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.getAllActionToGroup, error));
        dispatch(endLoading());
      });
  };
};
export const updateActionDataToGroup = (action) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_NEW_ACTION_REQUEST });
    return axios(config.apiGateway.updateActionToGroup, {
      method: 'POST',
      data: JSON.stringify({
        action,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        dispatch({ type: ADD_NEW_ACTION_SUCCESS });
        if (!!action.type && action.type === 'Work Assignment') {
          dispatch(getAllWorkAssignments());
        } else {
          dispatch(getAllActionByIncident());
        }
        // toast.success('Update Action to Group successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.updateActionToGroup, error));
      });
  };
};

export const deleteAction = (action_id, type) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteActionToGroup, {
      method: 'POST',
      data: JSON.stringify({
        action_id,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        if (!!type && type === 'Work Assignment') {
          dispatch(getAllWorkAssignments());
        } else {
          dispatch(getAllActionByIncident());
        }
        // toast.success('Delete Action to Group successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.deleteActionToGroup, error));
      });
  };
};

export const updateIncidentBriefingSituation = (situation) => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_SITUATION_REQUEST });
    return axios(config.apiGateway.updateBriefingSituation, {
      method: 'POST',
      data: JSON.stringify({
        situation,
        incident_id: getState().app.currentIncident.id,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((resp) => {
        // dispatch(getAllAction());
        dispatch({ type: UPDATE_SITUATION_SUCCESS });
        // toast.success('Update Briefing Situation successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.updateBriefingSituation, error));
      });
  };
};

export const deleteAssignmentResource = (resource_id, assignment_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteAssignmentResource, {
      method: 'POST',
      data: JSON.stringify({
        action_id: assignment_id,
        resource_id,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((resp) => {
        // dispatch(getAllAction());
        dispatch(getAllWorkAssignments());
        // toast.success('Delete Assignment Resource successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.deleteAssignmentResource, error));
      });
  };
};
