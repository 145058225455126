export const eriScenarios = [
  {
    value: 'eri_10th',
    label: '90% probability',
    tooltip: '90% probability',
    labelSmall: '90%',
    color: '#f0f9e8',
  },
  {
    value: 'eri_25th',
    label: '75% probability',
    tooltip: '75% probability',
    labelSmall: '75%',
    color: '#bae4bc',
  },
  {
    value: 'eri_mean',
    label: 'Mean scenario',
    tooltip: 'The average of scenarios',
    labelSmall: 'Mean',
    color: '#7bccc4',
  },
  {
    value: 'eri_75th',
    label: '25% probability',
    tooltip: '25% probability',
    labelSmall: '25%',
    color: '#43a2ca',
  },
  {
    value: 'eri_90th',
    label: '10% probability',
    tooltip: '10% probability',
    labelSmall: '10%',
    color: '#0868ac',
  },
  /* TODO deal with these later
  {
    value:"awetsn_mean",
    label: "Wet Snow",
    tooltip: "Average of scenarios for Wet Snow coverage"
  },
  {
    value:"FRAMthick_mean",
    label: "Ice Thickness",
    tooltip: "Average of scenarios for Ice Thickness"
  }
  */
];

export const eriTableRows = [
  {
    storm_level: 0,
    percent_pop: '<0.5%',
    restoration_time: '< 6 hours',
  },
  {
    storm_level: 1,
    percent_pop: '0.5% to 5%',
    restoration_time: '6 to 12 hours',
  },
  {
    storm_level: 2,
    percent_pop: '6% to 10%',
    restoration_time: '13 to 24 hours',
  },
  {
    storm_level: 3,
    percent_pop: '11% to 20%',
    restoration_time: '1 to 2 days',
  },
  {
    storm_level: 4,
    percent_pop: '21% to 40%',
    restoration_time: '3 to 5 days',
  },
  {
    storm_level: 5,
    percent_pop: '41% or greater',
    restoration_time: '6 or more days',
  },
];

const eriTableColsStyle = (row) => {
  return {
    padding: '.75rem 1rem',
    fontWeight: '600',
    background:
      row?.storm_level === 1
        ? '#fff7bc'
        : row?.storm_level === 2
        ? '#f2c06e'
        : row?.storm_level === 3
        ? '#e58446'
        : row?.storm_level === 4
        ? '#d02f18'
        : row?.storm_level === 5
        ? '#a2001e'
        : '#ffffff',
    color:
      row?.storm_level === 4 || row?.storm_level === 5 ? '#ffffff' : '#000000',
  };
};

export const eriTableCols = [
  {
    dataField: 'storm_level',
    text: 'ERI Storm Level',
    sort: true,
    headerAttrs: { title: 'Sort By:' },
    attrs: { title: 'ERI Storm Level' },
    style: (_, row) => {
      return eriTableColsStyle(row);
    },
    headerAlign: 'center',
    align: 'center'
  },
  {
    dataField: 'percent_pop',
    text: 'Percent of Population Affected by Power Loss',
    sort: true,
    attrs: { title: 'Percent of Population Affected by Power Loss' },
    style: (_, row) => {
      return eriTableColsStyle(row);
    },
    headerAlign: 'center',
    align: 'center'
  },
  {
    dataField: 'restoration_time',
    text: 'Estimated Power Restoration Time',
    sort: true,
    attrs: { title: 'Estimated Power Restoration Time' },
    style: (_, row) => {
      return eriTableColsStyle(row);
    },
    headerAlign: 'center',
    align: 'center'
  },
];

export const eriValCols = [
  {
    value: 0,
    color: '#FFFFFF',
  },
  {
    value: 1,
    color: '#ffffb2',
  },
  {
    value: 2,
    color: '#fecc5c',
  },
  {
    value: 3,
    color: '#fd8d3c',
  },
  {
    value: 4,
    color: '#f03b20',
  },
  {
    value: 5,
    color: '#bd0026',
  },
];
