import React, {useState} from 'react';
import { Modal } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';

export default function OSINTPostModal({ onClose }) {
  
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: 'onChange' });

  const [characterCount, setCharacterCount] = useState(0);

  const onNoteSubmit = ({note}) => {
    onClose(note);
  }

  const handleTextareaChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 2000) {
      setCharacterCount(inputValue.length);
      e.target.setCustomValidity('');
    } else {
      e.target.setCustomValidity('Text must be less than 2000 characters.');
    }
  };
  
  return (
    <>
      <Modal
        show={true}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Add Note</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onNoteSubmit)}>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Note</label>
              <Controller
                control={control}
                name="note"
                rules={{ required: true, maxLength: 2000 }}
                render={({ field }) => (
                  <StylishNewTextArea
                    {...field}
                    onChange={handleTextareaChange}
                    maxLength={2000}
                  />
                )}
              />
              <div className="d-flex justify-content-between">
                <span className="form-text mt-2">
                  {characterCount} / 2000
                </span>
                {errors.note?.type==='required' && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className='pt-0'>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                onClick={() => onClose()}
              >
                Cancel
              </StylishNewButton>
              <StylishNewButton
                type="submit"
                className="button--primary"
                disabled={(isSubmitted && !isValid)}
              >
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}