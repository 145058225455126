import { RESTORATION_STATUS_LIST, STABILITY_LIST, TASK_STATUS_LIST } from "./TaskManagementConstant";
import moment from 'moment';

export const getTaskStatusColor = (status) => {
  const findTask = TASK_STATUS_LIST.find(task => task.label === status);
  return findTask?.color || '';
}

export const getTaskContentColor = (task) => {
  const status = task?.status;
  const dueDate = task?.deadline_date;
  if (status === 'Completed') {
    const findTask = TASK_STATUS_LIST.find(_task => _task.label === status);
    return findTask?.color || '';
  } else if (isPastDate(dueDate)) {
    return '#C0392B';
  } else {
    return '#98A4A5';
  }
}

export const isPastDate = (date) => {
  let selectedDate = moment(date);
  selectedDate = selectedDate.startOf('day');
  let currentDate = moment();
  currentDate = currentDate.startOf('day');
  if (selectedDate.isBefore(currentDate)) {
    return true;
  } else {
    return false;
  }
}

export const getAbbreviationName = (name) => {
  return name
          .match(/(\b\S)?/g)
          .join('')
          .match(/(^\S|\S$)?/g)
          .join('')
          .toUpperCase();
}

export const getProcessStability = (assessmentStatus, assessmentDate, restorationStatus, rto, rtoTimeFrame, isCritical) => {
  
  let stability = STABILITY_LIST.NeedsAssessment;
  if (!isCritical) {
    stability = STABILITY_LIST.NonCritical;
  } else if (assessmentStatus === TASK_STATUS_LIST[0].value || 
    assessmentStatus === TASK_STATUS_LIST[0].label ||
    assessmentStatus === TASK_STATUS_LIST[1].value || 
    assessmentStatus === TASK_STATUS_LIST[1].label
  ) { // Not Completed, In Progress
    stability = STABILITY_LIST.NeedsAssessment;
  } else if (assessmentDate && rto >= 0 && rtoTimeFrame) {

    let selectedDate = moment(assessmentDate);
    selectedDate = selectedDate.startOf(rtoTimeFrame.unit);
    selectedDate.add(rto, rtoTimeFrame.value);
    let currentDate = moment();
    currentDate = currentDate.startOf(rtoTimeFrame.unit);
    
    if (selectedDate.isAfter(currentDate)) {
      if (!restorationStatus || restorationStatus === RESTORATION_STATUS_LIST[0].value) {
        stability = STABILITY_LIST.InRestoration;  // In restoration
      } else if (restorationStatus === RESTORATION_STATUS_LIST[1].value) {
        stability = STABILITY_LIST.PartiallyStable;  // Partially completed
      } else if (restorationStatus === RESTORATION_STATUS_LIST[2].value) {
        stability = STABILITY_LIST.Stable;  // Completed
      } 
    } else {
      stability = STABILITY_LIST.Disrupted;  // Disrupted
    }
  }

  return stability;
}