import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import {
    fetchIcsIncidentObjectives
} from './ics_incidentObjectivesSlice'

import {
    getUserGuid,
    getGroupGuid
} from 'slices/sliceHelpers'

// Slice
const ics_objectivesSlice = createSlice({
    name: 'ics_objectives',
    initialState: {
        ics_objectives: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        createIcsObjectiveSuccess: (state, action) => {
            state.ics_objectives.push(action.payload);
        },
        fetchIcsObjectivesSuccess: (state, action) => {
            state.ics_objectives = action.payload;
        },
        updateIcsObjectiveSuccess: (state, action) => {
            const index = state.ics_objectives.findIndex((obj) => obj.id === action.payload.id);
            state.ics_objectives[index] = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    createIcsObjectiveSuccess,
    fetchIcsObjectivesSuccess,
    updateIcsObjectiveSuccess,
    setError
} = ics_objectivesSlice.actions;

export default ics_objectivesSlice.reducer;

export const createIcsObjective = (data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState)
    try {
        const response = await axios.post(`${config.apiGateway.ics_objectives}`, {...data,user_guid});
        if(data.incident_id)
        {
            dispatch(fetchIcsIncidentObjectives(data.incident_id))
        }
        dispatch(fetchIcsObjectives(group_guid))
        dispatch(createIcsObjectiveSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsObjectives = (group_guid) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_objectives}/${group_guid}`,{
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsObjectivesSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsObjective = (id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState)
    try {
        const response = await axios.put(`${config.apiGateway.ics_objectives}/${id}`, {...data,user_guid});
        dispatch(fetchIcsObjectives(group_guid))
        dispatch(updateIcsObjectiveSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

