import config from 'constants/apiConfig';
import axios from 'axios';
import {
  VALIDATE_RBAC_PERMISSION_FOR_ACTION_REQUEST,
  VALIDATE_RBAC_PERMISSION_FOR_ACTION_SUCCESS,
  VALIDATE_RBAC_PERMISSION_FOR_ACTION_ERROR,
  VALIDATE_USER_IN_GROUPS_REQUEST,
  VALIDATE_USER_IN_GROUPS_SUCCESS,
  VALIDATE_USER_IN_GROUPS_ERROR,
  VALIDATE_USER_IN_GROUP_WITH_ROLE_REQUEST,
  VALIDATE_USER_IN_GROUP_WITH_ROLE_SUCCESS,
  VALIDATE_USER_IN_GROUP_WITH_ROLE_ERROR,
  VALIDATE_USER_SUBSCRIPTION_REQUEST,
  VALIDATE_USER_SUBSCRIPTION_SUCCESS,
  VALIDATE_USER_SUBSCRIPTION_ERROR,
  VALIDATE_USER_IN_INCIDENT_REQUEST,
  VALIDATE_USER_IN_INCIDENT_SUCCESS,
  VALIDATE_USER_IN_INCIDENT_ERROR,
  VALIDATE_USER_INCIDENT_ADMIN_REQUEST,
  VALIDATE_USER_INCIDENT_ADMIN_SUCCESS,
  VALIDATE_USER_INCIDENT_ADMIN_ERROR,
  VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_REQUEST,
  VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_SUCCESS,
  VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_ERROR,
  VALIDATE_USER_IN_POD_SECURITY_POLICY_REQUEST,
  VALIDATE_USER_IN_POD_SECURITY_POLICY_SUCCESS,
  VALIDATE_USER_IN_POD_SECURITY_POLICY_ERROR,
} from 'constants/validation-action-types';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

function validateRBACPermissionForActionRequest() {
  return {
    type: VALIDATE_RBAC_PERMISSION_FOR_ACTION_REQUEST,
  };
}

export const validateRBACPermissionForActionSuccess = (
  validateRBACPermissionForActionResult
) => ({
  type: VALIDATE_RBAC_PERMISSION_FOR_ACTION_SUCCESS,
  payload: validateRBACPermissionForActionResult,
});

function validateRBACPermissionForActionError(error) {
  // TODO CRITICAL
  const errorMessage =
    'validateRBACPermissionForActionError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: VALIDATE_RBAC_PERMISSION_FOR_ACTION_ERROR,
  };
}

export function validateRBACPermissionForAction(group_guid) {
  return (dispatch) => {
    dispatch(validateRBACPermissionForActionRequest());
    return axios(config.apiGateway.validateRBACPermissionForAction, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: group_guid,
      }),
    })
      .then((response) => {
        const validateRBACPermissionForActionResult = response.data || {};
        dispatch(
          validateRBACPermissionForActionSuccess(
            validateRBACPermissionForActionResult
          )
        );
        // toast.success('Validate RBAC Permission for Action successful', {
        //   autoClose: 2000,
        // });
        return validateRBACPermissionForActionResult;
      })
      .catch((response, error) => {
        dispatch(validateRBACPermissionForActionError(error));
        return;
      });
  };
}

function validateUserInGroupsRequest() {
  return {
    type: VALIDATE_USER_IN_GROUPS_REQUEST,
  };
}

export const validateUserInGroupsSuccess = (validateUserInGroupsResult) => ({
  type: VALIDATE_USER_IN_GROUPS_SUCCESS,
  payload: validateUserInGroupsResult,
});

function validateUserInGroupsError(error) {
  const errorMessage =
    'validateUserInGroupsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: VALIDATE_USER_IN_GROUPS_ERROR,
  };
}

export function validateUserInGroups(group_guids) {
  return (dispatch) => {
    dispatch(validateUserInGroupsRequest());
    return axios(config.apiGateway.validateUserInGroups, {
      method: 'POST',
      data: JSON.stringify({
        group_guids: group_guids,
      }),
    })
      .then((response) => {
        const validateUserInGroupsResultMessage = response.data || {};
        const validateUserInGroupsResult =
          JSON.stringify(validateUserInGroupsResultMessage) ===
          JSON.stringify({ message: 'Validation Passed' })
            ? true
            : false;
        dispatch(validateUserInGroupsSuccess(validateUserInGroupsResult));
        // toast.success('Validate User in Groups successful', {
        //   autoClose: 2000,
        // });
        return validateUserInGroupsResult;
      })
      .catch((error) => {
        dispatch(validateUserInGroupsError(error));
      });
  };
}

function validateUserInGroupWithRoleRequest() {
  return {
    type: VALIDATE_USER_IN_GROUP_WITH_ROLE_REQUEST,
  };
}

export const validateUserInGroupWithRoleSuccess = (
  validateUserInGroupWithRoleResult
) => ({
  type: VALIDATE_USER_IN_GROUP_WITH_ROLE_SUCCESS,
  payload: validateUserInGroupWithRoleResult,
});

function validateUserInGroupWithRoleError(error) {
  const errorMessage =
    'validateUserInGroupWithRoleError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: VALIDATE_USER_IN_GROUP_WITH_ROLE_ERROR,
  };
}

export function validateUserInGroupWithRole(group_guid, role_id) {
  return (dispatch) => {
    dispatch(validateUserInGroupWithRoleRequest());
    return axios(config.apiGateway.validateUserInGroupWithRole, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: group_guid,
        role_id: role_id,
      }),
    })
      .then((response) => {
        const validateUserInGroupWithRoleResultMessage = response.data || {};
        const validateUserInGroupWithRoleResult =
          JSON.stringify(validateUserInGroupWithRoleResultMessage) ===
          JSON.stringify({ message: 'Validation Passed' })
            ? true
            : false;
        dispatch(
          validateUserInGroupWithRoleSuccess(validateUserInGroupWithRoleResult)
        );
        // toast.success('Validate User in Group with Role successful', {
        //   autoClose: 2000,
        // });
        return validateUserInGroupWithRoleResult;
      })
      .catch((error) => {
        dispatch(validateUserInGroupWithRoleError(error));
      });
  };
}

function validateUserSubscriptionRequest() {
  return {
    type: VALIDATE_USER_SUBSCRIPTION_REQUEST,
  };
}

export const validateUserSubscriptionSuccess = (
  validateUserSubscriptionResult
) => ({
  type: VALIDATE_USER_SUBSCRIPTION_SUCCESS,
  payload: validateUserSubscriptionResult,
});

function validateUserSubscriptionError(error) {
  const errorMessage =
    'validateUserSubscriptionError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: VALIDATE_USER_SUBSCRIPTION_ERROR,
  };
}

export function validateUserSubscription(plan_id) {
  return (dispatch) => {
    dispatch(validateUserSubscriptionRequest());
    return axios(config.apiGateway.validateUserSubscription, {
      method: 'POST',
      data: JSON.stringify({
        plan_id: plan_id,
      }),
    })
      .then((response) => {
        const validateUserSubscriptionResultMessage = response.data || {};
        const validateUserSubscriptionResult =
          JSON.stringify(validateUserSubscriptionResultMessage) ===
          JSON.stringify({ message: 'Validation Passed' })
            ? true
            : false;
        dispatch(
          validateUserSubscriptionSuccess(validateUserSubscriptionResult)
        );
        // toast.success('Validate User Subscription successful', {
        //   autoClose: 2000,
        // });
        return validateUserSubscriptionResult;
      })
      .catch((error) => {
        dispatch(validateUserSubscriptionError(error));
      });
  };
}

function validateUserInIncidentRequest() {
  return {
    type: VALIDATE_USER_IN_INCIDENT_REQUEST,
  };
}

export const validateUserInIncidentSuccess = (
  validateUserInIncidentResult
) => ({
  type: VALIDATE_USER_IN_INCIDENT_SUCCESS,
  payload: validateUserInIncidentResult,
});

function validateUserInIncidentError(error) {
  const errorMessage =
    'validateUserInIncidentError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: VALIDATE_USER_IN_INCIDENT_ERROR,
  };
}

export function validateUserInIncident(incident_id) {
  return (dispatch) => {
    dispatch(validateUserInIncidentRequest());
    return axios(config.apiGateway.validateUserInIncident, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: incident_id,
      }),
    })
      .then((response) => {
        const validateUserInIncidentResultMessage = response.data || {};
        const validateUserInIncidentResult =
          JSON.stringify(validateUserInIncidentResultMessage) ===
          JSON.stringify({ message: 'Validation Passed' })
            ? true
            : false;
        dispatch(validateUserInIncidentSuccess(validateUserInIncidentResult));
        // toast.success('Validate User in Incident successful', {
        //   autoClose: 2000,
        // });
        return validateUserInIncidentResult;
      })
      .catch((error) => {
        dispatch(validateUserInIncidentError(error));
      });
  };
}

function validateUserIncidentAdminRequest() {
  return {
    type: VALIDATE_USER_INCIDENT_ADMIN_REQUEST,
  };
}

export const validateUserIncidentAdminSuccess = (
  validateUserIncidentAdminResult
) => ({
  type: VALIDATE_USER_INCIDENT_ADMIN_SUCCESS,
  payload: validateUserIncidentAdminResult,
});

function validateUserIncidentAdminError(error) {
  const errorMessage =
    'validateUserIncidentAdminError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: VALIDATE_USER_INCIDENT_ADMIN_ERROR,
  };
}

export function validateUserIncidentAdmin(incident_id) {
  return (dispatch) => {
    dispatch(validateUserIncidentAdminRequest());
    return axios(config.apiGateway.validateUserIncidentAdmin, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: incident_id,
      }),
    })
      .then((response) => {
        const validateUserIncidentAdminResultMessage = response.data || {};
        const validateUserIncidentAdminResult =
          JSON.stringify(validateUserIncidentAdminResultMessage) ===
          JSON.stringify({ message: 'Validation Passed' })
            ? true
            : false;
        dispatch(
          validateUserIncidentAdminSuccess(validateUserIncidentAdminResult)
        );
        // toast.success('Validate User Incident Admin successful', {
        //   autoClose: 2000,
        // });
        return validateUserIncidentAdminResult;
      })
      .catch((error) => {
        dispatch(validateUserIncidentAdminError(error));
      });
  };
}

function validateUserSubscriptionActionByMinimumPlanIdRequiredRequest() {
  return {
    type: VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_REQUEST,
  };
}

export const validateUserSubscriptionActionByMinimumPlanIdRequiredSuccess = (
  validateUserSubscriptionActionByMinimumPlanIdRequiredResult
) => ({
  type: VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_SUCCESS,
  payload: validateUserSubscriptionActionByMinimumPlanIdRequiredResult,
});

function validateUserSubscriptionActionByMinimumPlanIdRequiredError(error) {
  const errorMessage =
    'validateUserSubscriptionActionByMinimumPlanIdRequired\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_ERROR,
  };
}

export function validateUserSubscriptionActionByMinimumPlanIdRequired(
  minimum_plan_id_for_action
) {
  return (dispatch) => {
    dispatch(validateUserSubscriptionActionByMinimumPlanIdRequiredRequest());
    return axios(
      config.apiGateway.validateUserSubscriptionActionByMinimumPlanIdRequired,
      {
        method: 'POST',
        data: JSON.stringify({
          minimum_plan_id_for_action: minimum_plan_id_for_action,
        }),
      }
    )
      .then((response) => {
        const validateUserSubscriptionActionByMinimumPlanIdRequiredResultMessage =
          response.data || {};
        const validateUserSubscriptionActionByMinimumPlanIdRequiredResult =
          JSON.stringify(
            validateUserSubscriptionActionByMinimumPlanIdRequiredResultMessage
          ) === JSON.stringify({ message: 'Validation Passed' })
            ? true
            : false;
        dispatch(
          validateUserSubscriptionActionByMinimumPlanIdRequiredSuccess(
            validateUserSubscriptionActionByMinimumPlanIdRequiredResult
          )
        );
        // toast.success(
        //   'Validate User Subscription Action by Minimum Plan ID Required successful',
        //   {
        //     autoClose: 2000,
        //   }
        // );
        return validateUserSubscriptionActionByMinimumPlanIdRequiredResult;
      })
      .catch((error) => {
        dispatch(
          validateUserSubscriptionActionByMinimumPlanIdRequiredError(error)
        );
      });
  };
}

function validateUserInPodSecurityPolicyRequest() {
  return {
    type: VALIDATE_USER_IN_POD_SECURITY_POLICY_REQUEST,
  };
}

export const validateUserInPodSecurityPolicySuccess = (
  validateUserInPodSecurityPolicyResult
) => ({
  type: VALIDATE_USER_IN_POD_SECURITY_POLICY_SUCCESS,
  payload: validateUserInPodSecurityPolicyResult,
});

function validateUserInPodSecurityPolicyError(error) {
  const errorMessage =
    'validateUserInPodSecurityPolicyError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: VALIDATE_USER_IN_POD_SECURITY_POLICY_ERROR,
  };
}

// Action: ex: "Read Pod Data" or "Write Pod Data"
export function validateUserInPodSecurityPolicy(pod_id) {
  return (dispatch) => {
    dispatch(validateUserInPodSecurityPolicyRequest());
    return axios(config.apiGateway.validateUserInPodSecurityPolicy, {
      method: 'POST',
      data: JSON.stringify({
        pod_id: pod_id,
      }),
    })
      .then((response) => {
        const actions = response.data || {};
        const validateUserInPodSecurityPolicyResult = {
          pod_id: pod_id,
          actions: actions,
        };
        dispatch(
          validateUserInPodSecurityPolicySuccess(
            validateUserInPodSecurityPolicyResult
          )
        );
        // toast.success('Validate User in Pod Security Policy successful', {
        //   autoClose: 2000,
        // });
        return validateUserInPodSecurityPolicyResult;
      })
      .catch((error) => {
        // saving for later
        /*
        const validateUserInPodSecurityPolicyResult = {
          pod_id: pod_id,
          actions: [],
        }
        */
        dispatch(validateUserInPodSecurityPolicyError(error));
      });
  };
}
