import {
  INJECT_REFRESH_REQUEST,
  INJECT_REFRESH_SUCCESS,
} from "./inject.constants";

const initialState = {
  refresh: true,
};

const InjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case INJECT_REFRESH_REQUEST:
      return {
        ...state,
        refresh: true,
      };
    case INJECT_REFRESH_SUCCESS:
      return {
        ...state,
        refresh: false,
      };
    default:
      return state;
  }
};

export default InjectReducer;
