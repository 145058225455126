import React, { useEffect, useState } from 'react';
import { upsertDivision } from 'actions/IAPForm204Actions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import DrawerWrapper, { DrawerFooter } from '../DrawerWrapper';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import generateUUID from 'utils/sharedUtils/generateUUID';

export default function AddUpdateDivisionDrawer({
  show,
  onClose,
  selectedBranch,
  selectedDivision,
  onDivisionSelect,
}) {
  const [currentGroupUser, setCurrentGroupUser] = useState([]);
  const reduxDispatch = useDispatch();
  const reduxRostersForGroups = useSelector((state) => state.app.rostersForGroups || []);
  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);
  const branches = useSelector((state) =>
    state.app.allBranches.map((branch) => ({
      value: branch.id,
      label: branch.name,
    }))
  );

  const { handleSubmit, formState: { errors, isValid }, control, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      division_name: selectedDivision ? selectedDivision.name : '',
      branch: selectedBranch ? { value: selectedBranch.id, label: selectedBranch.name } : null,
    },
  });

  const onSubmit = async (data) => {
    const division = {
      name: data.division_name,
      branch_id: data.branch.value,
      id: selectedDivision ? selectedDivision.id : generateUUID(),
      division_supervisor: data.division_supervisor.value?.user_guid,
    };
    try {
      reduxDispatch(startLoading());
      await reduxDispatch(upsertDivision(division));
      onDivisionSelect(division);  // Trigger division selection after saving
      onClose();
    } catch (e) {
      console.log(e);
    } finally {
      reduxDispatch(endLoading());
    }
  };

  useEffect(() => {
    if (reduxCurrentlySelectedGroup && reduxRostersForGroups.length > 0) {
      const currentGroupUsers = reduxRostersForGroups
        .filter(user => user.group_guid === reduxCurrentlySelectedGroup.group_guid)
        .map(user => ({
          value: user,
          label: user.profile_settings?.userName || user.email_address,
        }));
      setCurrentGroupUser(currentGroupUsers);
    }
  }, [reduxCurrentlySelectedGroup, reduxRostersForGroups]);

  useEffect(() => {
    if (selectedDivision) {
      setValue('division_name', selectedDivision?.name?.trim());
      setValue(
        'branch',
        branches.find((b) => b.value === selectedDivision?.branch_id)
      );
      setValue(
        'division_supervisor',
        currentGroupUser.find(cgu => cgu.value.user_guid === selectedDivision?.division_supervisor)
      );
    }
  }, [selectedDivision, currentGroupUser, setValue, branches]);

  return (
    <DrawerWrapper toggle={onClose} title="Create Division / Group / Staging" show={show}>
      <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column flex-grow-1">
        <div className="flex-grow-1">
          <div className="mb-3 pt-1">
            <label className="form-label">Branch Name:</label>
            <Controller
              control={control}
              name="branch"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={branches}
                  value={value}
                  onChange={onChange}
                  placeholder={'Select Branch'}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={!!selectedBranch}
                />
              )}
            />
            {errors?.branch && (
              <span className="form-text form-error">
                This field is required.
              </span>
            )}
          </div>
          <div className="mb-3 pt-1">
            <label className="form-label">Division / Group / Staging Name:</label>
            <Controller
              control={control}
              name="division_name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput onChange={onChange} value={value} />
              )}
            />
            {errors?.division_name && (
              <span className="form-text form-error">
                This field is required.
              </span>
            )}
          </div>
          <div className="mb-3 pt-1">
            <label className="form-label">Division/Group Supervisor:</label>
            <Controller
              control={control}
              name="division_supervisor"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={currentGroupUser}
                  value={value}
                  placeholder="Select a Supervisor"
                  onChange={onChange}
                  isDisabled={false}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={false}
                />
              )}
            />
            {errors?.division_supervisor && (
              <span className="form-text form-error">
                This field is required.
              </span>
            )}
          </div>
        </div>
        <DrawerFooter>
          <div className="button-group">
            <StylishNewButton
              type="button"
              className="button--secondary button--reverse"
              onClick={onClose}
            >
              Cancel
            </StylishNewButton>
            <StylishNewButton type="submit" className="button--primary" disabled={!isValid}>
              Save
            </StylishNewButton>
          </div>
        </DrawerFooter>
      </form>
    </DrawerWrapper>
  );
}
