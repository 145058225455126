import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import { selectUserGuid } from '../../../slices/commonSelectors';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../assets/data/config';
import { useActualIncidentId } from './commonHooks';

export type IncidentMedicalTransportation = {
  id: string;
  transportation_id: string;
  incident_id: string;
  user_guid: string;
  version: number;
  ambulance_service?: string;
  description?: string;
  address?: string;
  contact_number?: string;
  paramedics_on_board: boolean;
  timestamp: Date;
  archived: boolean;
};

export const keys = {
  base: 'incident_medicalTransportation',
  get all() { return [keys.base] },
  list: (incidentId: string, userId: string) => [keys.base, incidentId, userId],
} as const;

export type CreateIncidentMedicalTransportationReqData = {
  transportation_id: string;
  incident_id: string;
  ambulance_service?: string;
  description?: string;
  address?: string;
  contact_number?: string;
  paramedics_on_board?: boolean;
  archived?: boolean;
};

export const useIncidentMedicalTransportations = () => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.list(incidentId, user_guid),
    queryFn: async () => {
      const resp = await axios.get<IncidentMedicalTransportation[]>(
        `${config.apiGateway.ics_incident_medical_transportation}/${incidentId}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
  });
};

export const useCreateIncidentMedicalTransportation = () => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data: CreateIncidentMedicalTransportationReqData) => {
      return await axios.post(
        `${config.apiGateway.ics_incident_medical_transportation}/${incidentId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(incidentId, user_guid) });
    },
    onError: (error: any) => {
      toast.error(error.message, toastConfig as any);
    },
  });
  return mutation;
};

interface EditIncidentMedicalTransportationReqData {
  id: string;
  ambulance_service?: string;
  description?: string;
  address?: string;
  contact_number?: string;
  paramedics_on_board?: boolean;
  archived?: boolean;
}

export const useEditIncidentMedicalTransportation = (medicalTransportationId: string) => {
  const user_guid = useSelector(selectUserGuid);
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: EditIncidentMedicalTransportationReqData) => {
      return await axios.put(
        `${config.apiGateway.ics_incident_medical_transportation}/${incidentId}/${medicalTransportationId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(incidentId, user_guid) });
    },
    onError: (error: any) => {
      toast.error(error.message, toastConfig as any);
    },
  });
};
