import React, { useEffect, useState } from 'react';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import moment from 'moment';
import {
  AssignmentDetailsDialog,
  StatusOptions,
} from './AssignmentDetailsDialog';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllWorkAssignments,
  getPlanningWorksheet,
} from 'actions/IAPForm204Actions';
import AddUpdateWorkAssignment from './WorkAssignment/AddUpdateWorkAssignMent';
import {
  fetchAllResourceRequest,
  updateResourceRequest,
} from 'actions/IAP213RRActions';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { ResourceDetailsDialog } from './ResourceDetailsDialog';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const FormBuilderDashboardAssignmentsResources = () => {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('Assignments');
  const [searchTerm, setSearchTerm] = useState('');
  const [assignmentDetailsDialog, setAssignmentDetailsDialog] = useState(false);
  const [
    assignmentDetailsDialogData,
    setAssignmentDetailsDialogData,
  ] = useState(null);
  const [resourceDetailsDialog, setResourceDetailsDialog] = useState(false);
  const [resourceDetailsDialogData, setResourceDetailsDialogData] = useState(
    null
  );
  const [
    isWorkAssignmentsDialogOpen,
    setIsWorkAssignmentsDialogOpen,
  ] = useState(false);
  const [allWorkAssignments, setAllWorkAssignments] = useState([]);
  const [allResources, setAllResources] = useState([]);
  const [allFilteredResources, setAllFilteredResources] = useState([]);
  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const reduxAllWorkAssignments = useSelector(
    (state) => state.app.allWorkAssignments
  );
  const incidentObjectives = useSelector(
    (state) => state.app.incidentObjectives
  );
  const reduxIncidentResources = useSelector(
    (state) => state.app.incidentResources
  );
  const reduxPlanningWorksheet = useSelector(
    (state) => state.app.planningWorksheet
  );
  const reduxAllResourceRequests = useSelector(
    (state) => state.app.all_resource_request
  );

  useEffect(() => {
    if (!!reduxCurrentIncident && !!reduxCurrentIncident.current_period) {
      reduxDispatch(getAllWorkAssignments());
      reduxDispatch(getPlanningWorksheet());
    }
  }, [reduxCurrentIncident]);

  useEffect(() => {
    if (!!reduxAllWorkAssignments && !!reduxAllWorkAssignments.length > 0) {
      let allAssignmentResources = [];
      reduxAllWorkAssignments.forEach((assignment) => {
        if (!!assignment.resources && !!assignment.resources.length > 0) {
          allAssignmentResources = allAssignmentResources.concat(
            assignment.resources
          );
        }
      });
      setAllResources(allAssignmentResources);
      setAllWorkAssignments(reduxAllWorkAssignments);
      if (assignmentDetailsDialogData) {
        setAssignmentDetailsDialogData(
          reduxAllWorkAssignments?.find(
            (rawa) => rawa.action_id === assignmentDetailsDialogData?.action_id
          )
        );
      }
      setAllFilteredResources(allAssignmentResources);
    } else {
      setAllWorkAssignments([]);
      setAllResources([]);
      setAllFilteredResources([]);
    }
  }, [reduxAllWorkAssignments]);

  useEffect(() => {
    reduxDispatch(getAllWorkAssignments());
  }, [reduxPlanningWorksheet?.branch_name]);

  const toggleData = [{ label: 'Assignments', value: 'Assignments' }];

  const handleToggleButtonClick = (value) => {
    setSelectedTab(value);
    if (value === 'Resources') {
      reduxDispatch(fetchAllResourceRequest());
    }
  };

  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
    if (selectedTab === 'Assignments') {
      const filteredAssignments = reduxAllWorkAssignments.filter(
        (assignment) => {
          return (
            assignment.special_equipment
              ?.toLowerCase()
              .includes(termSearched?.toLowerCase()) ||
            assignment.description
              ?.toLowerCase()
              .includes(termSearched?.toLowerCase()) ||
            assignment.overhead
              ?.toLowerCase()
              .includes(termSearched?.toLowerCase())
          );
        }
      );
      setAllWorkAssignments(filteredAssignments);
    } else {
      const filteredResources = allResources.filter((resource) => {
        return (
          resource.name.toLowerCase().includes(termSearched.toLowerCase()) ||
          resource.type.toLowerCase().includes(termSearched.toLowerCase()) ||
          resource.description
            .toLowerCase()
            .includes(termSearched.toLowerCase())
        );
      });
      setAllFilteredResources(filteredResources);
    }
  };

  const reduxResourceRequest = useSelector(
    (state) => state.app.resource_request
  );

  const createResourceRequest = () => {
    reduxDispatch(
      updateResourceRequest({
        id: generateUUID(),
        requisition_purchase_order: generateUUID(),
      })
    );
    navigate('/incident_management/ICS_213-RR');
  };

  return (
    <>
      <div className="d-flex mb-3">
        <StylishNewToggleButtonGroup
          data={toggleData}
          selected={selectedTab}
          onChange={handleToggleButtonClick}
          size={'sml'}
          className="flex-grow-1 flex-md-grow-0 me-4 me-md-0"
        />
        <Dropdown className="flex-shrink-0 ms-auto">
          <Dropdown.Toggle className="button--icon">
            <SharedIcon iconName="more_vert" bold />
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            {selectedTab === 'Assignments' ? (
              <>
                <Dropdown.Item
                  as="button"
                  onClick={() => setIsWorkAssignmentsDialogOpen(true)}
                >
                  <SharedIcon iconName="add" bold />
                  Create Assignment
                </Dropdown.Item>

                <Dropdown.Item>
                  <Link
                    to={'/incident_management/ICS_204-CG'}
                    className="nav-link d-flex align-items-center"
                  >
                    <SharedIcon iconName="visibility" />
                    Printable Assignments List (204)
                  </Link>
                </Dropdown.Item>
              </>
            ) : selectedTab === 'Resources' ? (
              <>
                <Dropdown.Item as="button" onClick={createResourceRequest}>
                  <SharedIcon iconName="add" bold />
                  Create Resource Request (213RR)
                </Dropdown.Item>
              </>
            ) : null}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {selectedTab === 'Assignments' ? (
        <>
          <div className="mb-3">
            <StylishNewSearchBar
              value={searchTerm}
              onChangeSearchTerm={onChangeSearchTerm}
            />
          </div>
          <div className="list__assignments">
            {!!allWorkAssignments && allWorkAssignments.length > 0 ? (
              <>
                {allWorkAssignments.map((item, index) => (
                  <div
                    key={item.action_id}
                    className={`bg-gray-800 rounded p-3 txt--sml cursor-pointer ${
                      index > 0 ? 'mt-2' : ''
                    }`}
                    onClick={() => {
                      setAssignmentDetailsDialogData(item);
                      setAssignmentDetailsDialog(true);
                    }}
                  >
                    <div className="d-flex flex-wrap align-items-center">
                      <span>#{index + 1}</span>
                      <span
                        className="status status--sml mx-2"
                        style={{
                          background: item.status
                            ? StatusOptions?.find(
                                (status) => status.label === item.status
                              )?.value?.statusColor
                            : '#000',
                        }}
                      >
                        {item.status}
                      </span>
                      &nbsp;
                      <span className="me-2">{item.description}</span>
                      <span>
                        {moment(item.required_date_time).format(
                          'Do MMM YYYY HH:mm'
                        )}
                      </span>
                    </div>
                    {!!item.special_equipment && (
                      <p className="mt-1 m-0">{item.special_equipment}</p>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="h-100 d-flex align-items-center justify-content-center bg-gray-800 rounded p-4">
                  <div className="d-flex flex-column align-items-center">
                    <i
                      className="fa fa-exclamation-triangle color-brand fa-3x"
                      aria-hidden="true"
                    ></i>
                    <p className="weight-500 mt-2">No Assignment found.</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : selectedTab === 'Requests' ? (
        <>
          <div className="list__assignments">
            {!!reduxAllResourceRequests &&
            reduxAllResourceRequests.length > 0 ? (
              <>
                {reduxAllResourceRequests.map((item, index) => (
                  <div
                    key={item.id}
                    className={`bg-gray-800 rounded p-3 txt--sml cursor-pointer ${
                      index > 0 ? 'mt-2' : ''
                    }`}
                    onClick={() => {
                      setResourceDetailsDialogData(item);
                      setResourceDetailsDialog(true);
                    }}
                  >
                    <div className="m-0">
                      <span className="d-block">
                        <span className="weight-600">Form ID:</span> {item?.id}
                      </span>
                      <span className="d-block mt-2">
                        <span className="weight-600">Purchase Order #:</span>{' '}
                        {item?.requisition_purchase_order}
                      </span>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="h-100 d-flex align-items-center justify-content-center bg-gray-800 rounded p-4">
                <div className="d-flex flex-column align-items-center">
                  <i
                    className="fa fa-exclamation-triangle color-brand fa-3x"
                    aria-hidden="true"
                  ></i>
                  <p className="weight-500 mt-2">No Resource found.</p>
                </div>
              </div>
            )}
          </div>
        </>
      ) : null}
      {assignmentDetailsDialog && (
        <AssignmentDetailsDialog
          show={assignmentDetailsDialog}
          onClose={() => {
            setAssignmentDetailsDialog(false);
            setAssignmentDetailsDialogData(null);
          }}
          data={assignmentDetailsDialogData}
          planningWorksheet={reduxPlanningWorksheet}
        />
      )}
      {isWorkAssignmentsDialogOpen && (
        <AddUpdateWorkAssignment
          incidentObjectives={incidentObjectives}
          incidentResources={reduxIncidentResources}
          workAssignmentModalType={'New'}
          updateActionData={null}
          show={isWorkAssignmentsDialogOpen}
          onClose={() => setIsWorkAssignmentsDialogOpen(false)}
        />
      )}
      {resourceDetailsDialog && (
        <ResourceDetailsDialog
          show={resourceDetailsDialog}
          onClose={() => setResourceDetailsDialog(false)}
          editData={resourceDetailsDialogData}
        />
      )}
    </>
  );
};
