import React, { useEffect, useMemo, useState } from 'react';
import * as turf from '@turf/turf';
import * as dayjs from 'dayjs';

import "./EventIntersectPowerOutage.css"


export default function EventIntersectPowerOutage(props) {

  const {
    event,
    locationInfo,
    poweroutageData
  } = props;

  const selectedGeo = locationInfo?.place?.geometry;

  const intersectedPowerOutageData = useMemo(() => {
    const intersectedPowerOutageData = [];
    if (poweroutageData?.length > 0) {
      poweroutageData.forEach(data => {
        const powerOutage = turf.feature(data?.geojson);
        const selectedArea = turf.feature(selectedGeo);

        if (turf.booleanIntersects(powerOutage, selectedArea)) {
          const title = `${
            (data.political_boundaries_type === 'county' && `${data.county_name} County, ${data.state_name}`) ||
            (data.political_boundaries_type === 'state' && data.state_name)
          }`;
          let percent = 0;
          if (data?.CustomersOut && data?.CustomersTracked) {
            percent = (data?.CustomersOut / data?.CustomersTracked * 100).toFixed(2)
          }
          intersectedPowerOutageData.push({...data, title, percent});
        }
      })
    }

    return intersectedPowerOutageData;
  }, [poweroutageData]);

  if (!selectedGeo) {
    return (
      <div className="EventIntersectPowerOutage">
        <div className="weight-600">There is no selected location info.</div>
      </div>
    )
  }

  if (!(poweroutageData?.length > 0)) {
    return (
      <div className="EventIntersectPowerOutage">
        <div className="weight-600">There is no Power Outage data.</div>
      </div>
    )
  }

  if (!(intersectedPowerOutageData?.length > 0)) {
    return (
      <div className="EventIntersectPowerOutage">
        <div className="weight-600">There is no selected Power Outage data.</div>
      </div>
    )
  }

  return (
    <div className="EventIntersectPowerOutage">
      {intersectedPowerOutageData.map((item, idx) => {
        return (
          <>
            <div key={idx}>
              <h6 className="m-0">{item?.title}</h6>

              <hr className="dashed thin my-1" />

              <div className="EventIntersectPowerOutage-Data">
                <span className="weight-600 d-block">Updated Date time: </span>
                <div className="EventIntersectPowerOutage-Model-Value mb-3">
                  <strong>
                    {dayjs(item?.LastUpdatedDateTime).format('MM/DD/YYYY hh:mm A')}
                  </strong>
                </div>

                <span className="weight-600 d-block">(Absolute) Customer Without Power: </span>
                <div className="EventIntersectPowerOutage-Model-Value mb-3">
                  <strong>{item?.CustomersOut}&nbsp;</strong> customers out
                </div>

                <span className="weight-600 d-block">(Relative) Percentage Without Power: </span>
                <div className="EventIntersectPowerOutage-Model-Value">
                  <strong>{item?.percent}%&nbsp;</strong> of customer out
                </div>
              </div>
            </div>
            
            <hr className="dashed my-3" />
          </>
        );
      })}
    </div>
  )
}