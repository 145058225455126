import React from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { useNavigate } from 'react-router-dom';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

type PageTitleProps = React.PropsWithChildren & {
  title: string;
  parents?: string[];
};

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  parents = [],
  children,
}) => {
  const navigate = useNavigate();
  return (
    <div className="page-title d-flex align-items-end justify-content-between">
      <div className="d-flex flex-column align-items-start">
        {parents.length > 0 ? (
          <div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <StylishNewButton onClick={() => navigate(-1)}>
                  <SharedIcon iconName="keyboard_arrow_left" size="sm" />
                  Back
                </StylishNewButton>
              </li>
              {parents.map((parent, index) => (
                <li key={index} className="breadcrumb-item">{parent}</li>
              ))}
            </ol>
          </div>
        ) : (
          <div className="mt-2"></div>
        )}
        <h1>{title}</h1>
      </div>
      {/** NOTE: the spacing on the top of this right content might need tweaking depending on the page*/}
      <div className="right-content d-flex align-items-end">{children}</div>
    </div>
  );
};

export default PageTitle;
