import API from '../../service/api';
import {
  LOGIN,
  LOGOUT,
  //   SEND_RESET_MAIL,
  SET_USER_DETAILS,
  UPDATE_ACCESS_REFRESH_TOKEN,
  UPDATE_SELECTED_WORKSPACE,
  UPDATE_USER_ID_NAME,
  UPDATE_USER_NAME,
  UPDATE_USER_ROLE,
  UPDATE_USER_TIME_FORMAT,
  VALIDATING_TOKEN_REQUEST,
  VALIDATING_TOKEN_SUCCESS,
} from './user.constants';
import Network from '../../service/Network';
import { changeSelectedWorkSpace } from '../workspaces/workspaces.action';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../reducers/loading/loading.action";

export const login = ({ email, password }) => {
  return (dispatch) => {
    dispatch(startLoading());
    Network.post(API.login, { email, password })
      .then((resp) => {
        toast.success(resp.data.response.status.msg, toastConfig);
        Network.http.defaults.headers.common.Authorization =
          'Bearer ' + resp.data.response.dataset.access_token;
        dispatch({
          type: LOGIN,
          payload: {
            id: resp.data.response.dataset.id,
            token: resp.data.response.dataset.access_token,
            refresh_token: resp.data.response.dataset.refresh_token,
          },
        });
        dispatch({
          type: SET_USER_DETAILS,
          payload: {
            name: resp.data.response.dataset.name,
            timeFormat: resp.data.response.dataset.timeFormat,
            // imgUrl: "TestImage.png",
          },
        });
        dispatch(
          changeSelectedWorkSpace(resp.data.response.dataset.lastWorkspaceId)
        );
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};
export const logout = () => {
  return (dispatch) => {
    dispatch({ type: LOGOUT });
  };
};

export const validateAndUpdateToken = (token, id, refresh_token) => {
  return (dispatch) => {
    dispatch({ type: VALIDATING_TOKEN_REQUEST });
    // let isValid = false;
    if (!token) {
      // isValid = false;
      dispatch({ type: VALIDATING_TOKEN_SUCCESS });
      dispatch({ type: LOGOUT });
      return;
    }
    // if (isTokenExpired(token, refresh_token)) {
    //   toast.error("Session Expired Please Log In");
    //   dispatch({ type: VALIDATING_TOKEN_SUCCESS });
    //   dispatch({ type: LOGOUT });
    //   return;
    // }
    Network.http.defaults.headers.common.Authorization = 'Bearer ' + token;
    dispatch({
      type: VALIDATING_TOKEN_SUCCESS,
      payload: { validToken: true },
    });
  };
};

export const updateSelectedWorkspaceId = (workspaceId) => {
  console.log('workspaceId', workspaceId);
  return (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_WORKSPACE,
      payload: { selectedWorkspaceId: workspaceId },
    });
  };
};

export const updateUserRole = (userRoles) => {
  return (dispatch) => {
    const roles = userRoles.map((role) => role.name);
    dispatch({ type: UPDATE_USER_ROLE, payload: roles });
  };
};

export const updateUserName = (name) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER_NAME, payload: name });
  };
};
export const updateUserTimeFormat = (timeFormat) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER_TIME_FORMAT, payload: timeFormat });
  };
};

export const updateAccessAndRefreshToken = (access_token, refresh_token) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACCESS_REFRESH_TOKEN,
      payload: { token: access_token, refresh_token },
    });
  };
};

export const updateUserIdAndName = (name, id) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_ID_NAME,
      payload: { name, id },
    });
  };
};
