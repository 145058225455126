import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { addUserToIncident } from 'actions/incidentActions';
import { fetchAllRolesInGroup } from 'actions/roleActions';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { AVAILABLE_ROLES as availableRoles } from './AvailableRoles';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { useForm, Controller } from 'react-hook-form';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { startLoading } from 'reducers/loading/loading.action';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

export default function IncidentNewMemberDialog({
  show,
  onClose,
  incident_id,
  setGroupMembersToNotify,
  reduxRosterForIncident,
  reduxRostersForTheGroup,
}) {
  const reduxDispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const [isLoaded, setIsLoaded] = useState(true);
  const [roster, setRoster] = useState([]);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  // const incidents = useSelector((state) => {
  //   return state.app.incidents?.find(incident => incident.id === incident_id);
  // })
  // const tenantId = useSelector((state) => {
  //   return state.app.tenantId;
  // })

  const isAddUserToIncidentByAdminLoaded = useSelector((state) => {
    return state.app.isAddUserToIncidentByAdminLoaded;
  });

  const isGetRosterForIncidentLoaded = useSelector((state) => {
    return state.app.isGetRosterForIncidentLoaded;
  });

  const reduxAllRolesInGroup =
    useSelector((state) => {
      return state.app.allRolesInGroup;
    }) || [];

  const { user_guid } = useSelector((state) => {
    return state.app.user;
  });

  const reduxAllMembersInGroup =
    useSelector((state) => {
      return (
        state.app.rostersForGroups &&
        state.app.rostersForGroups.filter(
          (r) => r.group_guid === state.app.currentlySelectedGroup.group_guid
        )
      );
    }) || [];

  useEffect(() => {
    if (
      (!reduxAllRolesInGroup ||
        (Array.isArray(reduxAllRolesInGroup) &&
          !reduxAllRolesInGroup.length)) &&
      !!reduxCurrentlySelectedGroup
    ) {
      reduxDispatch(
        fetchAllRolesInGroup(reduxCurrentlySelectedGroup.group_guid)
      );
    }
  }, [reduxCurrentlySelectedGroup]);

  useEffect(() => {
    if (
      !isLoaded &&
      isAddUserToIncidentByAdminLoaded &&
      isGetRosterForIncidentLoaded
    ) {
      onClose();
    }
  }, [
    isAddUserToIncidentByAdminLoaded,
    isGetRosterForIncidentLoaded,
    isLoaded,
    onClose,
  ]);

  useEffect(() => {
    const groupRosters = reduxRostersForTheGroup.filter(
      (roster) =>
        !reduxRosterForIncident.find((r) => r.user_guid === roster.user_guid)
    );
    setRoster(groupRosters);
  }, [reduxRosterForIncident, reduxRostersForTheGroup]);

  const [selectedMembers, setSelectedMembers] = useState([]);

  const allColumns = [
    {
      dataField: 'email_address',
      text: 'Email Address',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Email Address' },
      filter: textFilter(),
    },
    {
      dataField: 'user_name',
      text: 'Member Name',
      sort: true,
      attrs: { title: 'Member Name' },
      filter: textFilter(),
    },
    {
      dataField: 'role_string',
      text: 'Organization Role',
      sort: true,
      attrs: { title: 'Organization Role' },
      filter: textFilter(),
    },
  ];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: false,
    selected: selectedMembers.map((e) => e.user_guid),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      let newSelectedMembers = [...selectedMembers];
      if (selectedMembers.find((e) => e.user_guid === row.user_guid)) {
        newSelectedMembers = newSelectedMembers.filter(
          (e) => e.user_guid !== row.user_guid
        );
      } else {
        newSelectedMembers.push(row);
      }
      setSelectedMembers(newSelectedMembers);
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setSelectedMembers(roster) : setSelectedMembers([]);
    },
  };

  useEffect(() => {
    if (selectedMembers.length > 0) {
      clearErrors('roles');
    }
  }, [selectedMembers]);

  const onAssignSeatSubmit = (data) => {
    if (!selectedMembers.length > 0) {
      setError('roles', { type: 'custom' });
      return;
    } else {
      clearErrors('roles');
    }

    const incidentMembers = selectedMembers.map((member) => {
      return {
        incident_member_id: generateUUID(),
        user_guid: member.user_guid,
        seat: data.roles.value,
      };
    });
    const memberEmails = selectedMembers.map((member) => {
      return member?.email_address;
    });

    reduxDispatch(startLoading());
    reduxDispatch(
      addUserToIncident({
        incidentMembers,
        incident_id,
        memberEmails,
      })
    );
    onClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <form onSubmit={handleSubmit(onAssignSeatSubmit)}>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>Assign Incident Seat</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <StylishNewTable
              keyField={'user_guid'}
              columns={allColumns}
              rows={roster}
              selectRow={selectRow}
              filter={filterFactory()}
            />
            {errors.roles?.type === 'custom' && (
              <span className="form-text form-error">
                You have to choose somebody or all.
              </span>
            )}
            <div className="mt-4">
              <label className="form-label">Select Seat</label>
              <Controller
                control={control}
                name="roles"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={availableRoles.map((role) => {
                      return { value: role, label: role };
                    })}
                    defaultValue={value}
                    value={value || ''}
                    onChange={(e) => onChange(e)}
                    placeholder={'Assign a Role'}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.roles?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={onClose}
              >
                Close
              </StylishNewButton>
              <StylishNewButton className="button--primary" type="submit">
                Assign Incident Seat
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
