import { Offcanvas, Row } from 'react-bootstrap';
import React from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { EditEntityDrawerProps } from './EntityListProps';
import {
  IncidentStrategy,
  useEditIncidentStrategy,
} from './hooks/incidentStrategyHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import { useTactics } from './hooks/tacticHooks';
import RelationshipPickerField from './formFields/RelationshipPickerField';
import {
  IncidentObjective,
  useIncidentObjectives,
} from './hooks/incidentObjectiveHooks';
import { ParentRelationships } from './ParentRelationships';

type FormFields = {
  name: string;
  description: string;
  tactics: UUID[];
};

const StrategyEditDrawer: React.FC<EditEntityDrawerProps<IncidentStrategy>> = ({
  toggle,
  selectedEntity: editedEntity,
}) => {
  const form = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      name: editedEntity?.name ?? '',
      description: editedEntity?.description ?? '',
      tactics: editedEntity?.tactics ?? [],
    },
  });
  const { handleSubmit } = form;

  const tacticOptions = useTactics();
  const incidentObjectives = useIncidentObjectives((ios) =>
    editedEntity.objectives
      .map((ioId) => ios.find((io) => io.objective_id === ioId))
      .filter((x) => !!x)
  );

  const mutation = useEditIncidentStrategy(editedEntity.strategy_id);

  const onNewStrategyDataSubmit = async (formData: FormFields) => {
    const payload = {
      strategy_id: editedEntity.strategy_id,
      name: formData.name,
      description: formData.description,
      tactics: formData.tactics,
      archived: false,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  const toggleActive = async () => {
    await mutation.mutateAsync({
      ...editedEntity,
      archived: !editedEntity.archived,
    });
    toggle();
  };


  return (
    <DrawerWrapper toggle={toggle} title="Edit Strategy">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onNewStrategyDataSubmit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextField
                title="Name"
                fieldName="name"
                rules={{
                  required: { value: true, message: 'This field is required' },
                  maxLength: {
                    value: 300,
                    message: 'The maximum length is 300 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Description"
                fieldName="description"
                rules={{
                  maxLength: {
                    value: 300,
                    message: 'The maximum length is 300 characters',
                  },
                }}
                variant="full"
              />
            </Row>
            <Row>
              <ParentRelationships
                label="Objectives"
                parents={incidentObjectives.data}
              />
            </Row>
            <Row
              style={{
                maxHeight: '50vh',
                overflow: 'auto',
              }}
            >
              <RelationshipPickerField
                label={'Tactics'}
                fieldName={'tactics'}
                defaultValue={editedEntity.tactics}
                options={tacticOptions.data}
              />
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={toggleActive}
              >
                {(!editedEntity.archived&&"Deactivate"||"Activate")}
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default StrategyEditDrawer;
