import React from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton.js';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  MdCampaign,
  MdChat,
  MdFlashOn,
  MdLocationOn,
  MdMap,
  MdOutlineTask,
  MdOutlineWarning,
  MdSettings,
} from 'react-icons/md';
import { RiOilFill } from "react-icons/ri";
import { TiLightbulb, TiWeatherStormy } from 'react-icons/ti';
import { RiFileTextLine } from 'react-icons/ri';
import { useFeatureFlag } from '../../utils/featureFlags';
import { useWindowSize } from 'react-use';

const SIDEBAR_ICONS = [
  { key: 'DisasterChats', icon: <MdChat /> },
  { key: 'Settings', icon: <MdSettings /> },
  { key: 'AOR Reports', icon: <MdCampaign /> },
  { key: 'Basemap', icon: <MdMap /> },
  { key: 'Geolocations', icon: <MdLocationOn /> },
  { key: 'Events', icon: <MdOutlineWarning /> },
  { key: 'Weather', icon: <TiWeatherStormy /> },
  { key: 'Impact Models', icon: <TiLightbulb /> },
  { key: 'Incident Report', icon: <RiFileTextLine /> },
  { key: 'Task Management', icon: <MdOutlineTask /> },
  // { key: 'Power Outage', icon: <MdFlashOn /> },
  { key: 'Oil Spill', icon: <RiOilFill /> },
];

const isUsingTeams =
  sessionStorage['isUsingTeams'] === true ||
  sessionStorage['isUsingTeams'] === 'true';

type MapSidebarProps = {
  sidebarActiveItem: string;
  sidebarActiveItemHandler: (item: string) => void;
};

const MapSidebar: React.FC<React.PropsWithChildren & MapSidebarProps> = ({
  children,
  sidebarActiveItem,
  sidebarActiveItemHandler,
}) => {
  const streamlineFlag = useFeatureFlag('STREAMLINE_PLATFORM');
  const powerOutageFlag = useFeatureFlag('POWER_OUTAGEs');
  const disasterGPTTeamsFlag = useFeatureFlag('DISASTERGPT_TEAMS');

  let sidebarIcons = SIDEBAR_ICONS.filter((i) =>
    streamlineFlag
      ? !['AOR Reports', 'Task Management', 'DisasterChats'].includes(i.key)
      : true
  ).filter((i) => (powerOutageFlag ? i.key !== 'Power Outage' : true));

  if ((!isUsingTeams||disasterGPTTeamsFlag) && !sidebarIcons.some(i => i.key === 'DisasterChats')) {
    sidebarIcons = [{ key: 'DisasterChats', icon: <MdChat /> }, ...sidebarIcons];
  }

  const windowSize = useWindowSize();
  const disableOnMobile = windowSize.width < 768; // Our sidebars are quite wide, around 650px.

  return (
    <div className="map-sidebar">
      {children}
      <div className="sidebar-icons">
        <ul>
          {sidebarIcons.map((i) => (
            <li key={i.key}>
              <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    {i.key}
                    {disableOnMobile ? (
                      <>
                        <br />
                        (Screen size too small)
                      </>
                    ) : (
                      ''
                    )}
                  </Tooltip>
                )}
                placement="left"
              >
                <StylishNewButton
                  customButton
                  onClick={() =>
                    !disableOnMobile && sidebarActiveItemHandler(i.key)
                  }
                  className={sidebarActiveItem === i.key ? 'active' : ''}
                  aria-label={i.key}
                  disabled={disableOnMobile}
                >
                  {i.icon}
                </StylishNewButton>
              </OverlayTrigger>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MapSidebar;