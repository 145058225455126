import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import { fetchIcsIncidentActions } from './ics_incidentActionsSlice';

import { getUserGuid, getGroupGuid } from 'slices/sliceHelpers';

// Slice
const ics_actionsSlice = createSlice({
    name: 'ics_actions',
    initialState: {
        ics_actions: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        createIcsActionSuccess: (state, action) => {
            state.ics_actions.push(action.payload);
        },
        fetchIcsActionsSuccess: (state, action) => {
            state.ics_actions = action.payload;
        },
        updateIcsActionSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.status = action.payload;
        }
    },
});

export const {
    createIcsActionSuccess,
    fetchIcsActionsSuccess,
    updateIcsActionSuccess,
    setError,
    setLoading
} = ics_actionsSlice.actions;

export default ics_actionsSlice.reducer;

export const createIcsAction = (data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        dispatch(setLoading('loading'));
        const response = await axios.post(`${config.apiGateway.ics_actions}`, { ...data, user_guid });
        if (data.incident_id) {
            dispatch(fetchIcsIncidentActions(data.incident_id));
        }
        dispatch(fetchIcsActions(group_guid));
        dispatch(createIcsActionSuccess(response.data));
        dispatch(setLoading('succeeded'));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
        dispatch(setLoading('failed'));
    }
};

export const fetchIcsActions = (group_guid) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        dispatch(setLoading('loading'));
        const response = await axios.get(`${config.apiGateway.ics_actions}/${group_guid}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsActionsSuccess(response.data));
        dispatch(setLoading('succeeded'));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
        dispatch(setLoading('failed'));
    }
};

export const updateIcsAction = (id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        dispatch(setLoading('loading'));
        const response = await axios.put(`${config.apiGateway.ics_actions}/${id}`, { ...data, user_guid });
        dispatch(fetchIcsActions(group_guid));
        dispatch(updateIcsActionSuccess(response.data));
        dispatch(setLoading('succeeded'));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
        dispatch(setLoading('failed'));
    }
};
