import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { getUserGuid, getIncidentGuid } from 'slices/sliceHelpers';

// Slice
const strikeTeamsSlice = createSlice({
    name: 'ics_strike_teams',
    initialState: {
        ics_strike_teams: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        createStrikeTeamSuccess: (state, action) => {
            state.ics_strike_teams.push(action.payload);
        },
        fetchStrikeTeamsSuccess: (state, action) => {
            state.ics_strike_teams = action.payload;
        },
        updateStrikeTeamSuccess: (state, action) => {
            const index = state.ics_strike_teams.findIndex((team) => team.id === action.payload.id);
            state.ics_strike_teams[index] = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    createStrikeTeamSuccess,
    fetchStrikeTeamsSuccess,
    updateStrikeTeamSuccess,
    setError
} = strikeTeamsSlice.actions;

export default strikeTeamsSlice.reducer;

export const createStrikeTeam = (data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const incident_id = getIncidentGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_strike_teams}`, {...data, user_guid});
        dispatch(fetchStrikeTeams(incident_id));
        dispatch(createStrikeTeamSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchStrikeTeams = (incident_id) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_strike_teams}/${incident_id}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchStrikeTeamsSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateStrikeTeam = (id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const incident_id = getIncidentGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_strike_teams}/${id}`, {...data, user_guid});
        dispatch(fetchStrikeTeams(incident_id));
        dispatch(updateStrikeTeamSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
