import React from "react";
import OSINTAnalyticsLanguage from "./OSINTAnalyticsLanguage";
import OSINTAnalyticsSentiment from "./OSINTAnalyticsSentiment";

export default function OSINTAnalytics() {

  return (
    <div className="post-main">
      <h3>Sentiment</h3>
      <div className="bg-gray-800 p-3 p-md-4">
        <OSINTAnalyticsSentiment />
      </div>
      <h3 className="mt-3">Language</h3>
      <div className="bg-gray-800 p-3 p-md-4">
        <OSINTAnalyticsLanguage />
      </div>
    </div>
  )
}
