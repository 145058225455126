import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { createResourceSite, updateResourceSite } from 'slices/ics_resourceSitesSlice';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';

import { resource_site_types } from 'components/ICS/ICSConstants';

import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';

export default function NewResourceSiteDialog({
  show,
  editResourceSiteData,
  modalType,
  onClose,
}) {
  const mapboxToken = window.env.MAPBOX_ACCESS_TOKEN;
  const { handleSubmit, control, setValue, formState: { errors } } = useForm({ mode: 'onChange' });
  const [resourceSiteId, setResourceSiteId] = useState('');
  const reduxDispatch = useDispatch();
  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);

  const [geolocation, setGeolocation] = useState()

  useEffect(() => {
    if (modalType === 'New') {
      setResourceSiteId(generateUUID());
    } else if (modalType === 'Edit') {
      setResourceSiteId(editResourceSiteData.id);
      setValue('description', editResourceSiteData.description);
      setValue('name', editResourceSiteData.name);
      setValue('type',editResourceSiteData.type)
      setGeolocation(editResourceSiteData.geolocation)
    }
  }, [modalType]);

  const onNewResourceSiteDataSubmit = (data) => {
    const payload = {
      id: resourceSiteId,
      name: data.name,
      description: data.description,
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      archived: false,
      type: data.type,
      geolocation: geolocation,
    };
    if (modalType === 'New') {
      reduxDispatch(createResourceSite(payload));
      onClose();
    } else if (modalType === 'Edit') {
      reduxDispatch(updateResourceSite(payload.id, payload));
      onClose();
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered backdrop="static" keyboard={false} size="lg">
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Resource Site</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewResourceSiteDataSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">ID</label>
            <StylishNewInput value={resourceSiteId} readOnly disabled />
          </div>
          <div className="m-0">
            <label className="form-label">Name:</label>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.description && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Description:</label>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Type:</label>
            <Controller
              control={control}
              name="type"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={resource_site_types}
                  value={resource_site_types.find(
                    (option) => option.value === value
                  )}
                  onChange={(e) => onChange(e.value)}
                  className="checklist-select"
                />
              )}
            />
            {errors.description && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
        <div className="m-0">
          <label className="form-label">Location:</label>
          <GeoLocationComponent
            location={geolocation}
            setLocation={setGeolocation}
            mapboxToken={mapboxToken}
          />
        </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton className="button--secondary button--reverse" type="button" onClick={onClose}>
              Close
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
