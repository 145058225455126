import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import MapComponent from 'components/MapComponent/MapComponent';

import config from 'constants/apiConfig';

export default function MapPage() {
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  // All of these hooks represent persisted Map data that would be used in a dedicated application like DICE or EPIC
  const [layers, setLayers] = useState([]);
  const [sources, setSources] = useState([]);

  return (
    <MapComponent
      layers={layers}
      setLayers={setLayers}
      sources={sources}
      setSources={setSources}
      lng={-93.33}
      lat={45}
      zoom={3}
      apiPrefix={config.apiPrefix}
      group_guid={reduxCurrentlySelectedGroup.group_guid}
    />
  );
}
