import { Modal } from 'react-bootstrap';
import GraphicsUserAuthorizationFailed from 'assets/images/graphics__user-authorization-failed.svg';
import { useAuth0 } from '@auth0/auth0-react';

export default function UserAuthorizationFailedDialog({ show, onClose }) {
    const { logout } = useAuth0();

    const onCloseClick = () => {
        logout({ logoutParams: { returnTo: window.location.origin } });
        onClose();
    };
  return (
    <>
      <Modal
        show={show}
        onHide={onCloseClick}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white"></Modal.Header>
        <Modal.Body className="pb-5">
          <div className="d-flex flex-column align-items-center">
            <img
              src={GraphicsUserAuthorizationFailed}
              alt=""
              className="mb-5"
            />
            <h4 className="mb-2">Access Denied</h4>
            <p className="m-0">User did not authorize this request</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}