import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import StylishDateTimePicker from '../../DesignSystems/form/StylishDateTimePicker';

type FormDateTimeFieldProps = {
  title: string;
  fieldName: string;
  rules?: { [key: string]: { value: any; message: string } };
  variant?: 'full' | 'half';
  type?: 'datetime-local';
};

const FormDateTimeField: React.FC<FormDateTimeFieldProps> = ({
  title,
  fieldName,
  rules,
  variant = 'full',
  type
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const required = typeof rules?.required === 'object';

  return (
    <div
      className={classNames('mt-2', {
        'col-6': variant === 'half',
        'col-12': variant === 'full',
      })}
    >
      <Controller
        control={control}
        name={fieldName}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <StylishDateTimePicker
            onChange={(date) => onChange(date)}
            value={value}
            dateLabel={title}
            required={required}
            type={type}
          />
        )}
      />
      {errors[fieldName] ? (
        <div className="form-text form-error">
          {errors[fieldName].message as string}
        </div>
      ) : (
        <div className="form-text-none"></div>
      )}
    </div>
  );
};
export default FormDateTimeField;
