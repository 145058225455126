import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { updateIncidentMemberWorkLog } from 'actions/incidentActions';
import { useDispatch, useSelector } from 'react-redux';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import moment from 'moment';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useForm, Controller } from 'react-hook-form';
import { startLoading } from 'reducers/loading/loading.action';

export default function EditLogDialog({ show, onClose, log }) {
  const reduxDispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const incidentLogs = useSelector((state) => {
    return state.app.incidentLogs;
  });

  const isUpdateIncidentMemberWorkLogLoaded = useSelector((state) => {
    return state.app.isUpdateIncidentMemberWorkLogLoaded;
  });

  useEffect(() => {
    if (log) {
      setValue('loginTime', log.login_time);
      setValue('logoutTime', log.logout_time);
    }
  }, [log]);

  const reduxValidateRBACPermissionForActionResult =
    useSelector((state) => {
      return state.app.validateRBACPermissionForActionResult;
    }) || [];

  const onEditLogSubmit = (data) => {
    if (moment(data.loginTime).isAfter(moment(data.logoutTime))) {
      setError('logoutTime', { type: 'custom' });
      return;
    } else {
      clearErrors('logoutTime');
    }
    reduxDispatch(startLoading());
    reduxDispatch(
      updateIncidentMemberWorkLog({
        loginDateTime: data.loginTime,
        logoutDateTime: data.logoutTime,
        workLogId: log.work_log_id,
      })
    );
    onClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <form onSubmit={handleSubmit(onEditLogSubmit)}>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>Edit Log</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Login Time</label>
              <Controller
                control={control}
                name="loginTime"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    value={value || ''}
                    type="datetime-local"
                    onChange={(e) => onChange(e)}
                  />
                )}
              />
              {errors.loginTime?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
            <div className="m-0">
              <label className="form-label">Logout Time</label>
              <Controller
                control={control}
                name="logoutTime"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    value={value || ''}
                    type="datetime-local"
                    onChange={(e) => onChange(e)}
                  />
                )}
              />
              {errors.logoutTime?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors.logoutTime?.type === 'custom' && (
                <span className="form-text form-error">
                  Logout can't be before login.
                </span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <StylishNewButton type="submit" className="button--primary">
              Save Log
            </StylishNewButton>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
