import React from "react";
import ReactECharts from 'echarts-for-react';


const options = {
  grid: { top: 20, right: 40, bottom: 20, left: 40 },
  xAxis: {
    type: 'time',
  },
  yAxis: {
    type: 'value',
  },
  series: [
    {
      type: 'line',
      smooth: true, // Enable smooth line
      data: [
        [new Date('2023-01-01'), 100],
        [new Date('2023-02-01'), 200],
        [new Date('2023-03-01'), 150],
        [new Date('2023-04-01'), 350],
        [new Date('2023-05-01'), 450],
      ],
      areaStyle: {
        color: 'rgba(0, 128, 255, 0.3)'
      },
    },
  ],
  tooltip: {
    trigger: "axis"
  }
}

export default function OSINTAnalyticsSentiment() {

  return (
    <ReactECharts
      option={options}
      style={{ width: "100%", height: "300px" }}
    ></ReactECharts>
  )
}
