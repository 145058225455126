import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import { fetchIcsIncidentStrategies } from './ics_incidentStrategiesSlice';

import { getGroupGuid, getUserGuid } from 'slices/sliceHelpers';

// Slice
const ics_strategiesSlice = createSlice({
  name: 'ics_strategies',
  initialState: {
    ics_strategies: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    createIcsStrategySuccess: (state, action) => {
      state.ics_strategies.push(action.payload);
    },
    fetchIcsStrategiesSuccess: (state, action) => {
      state.ics_strategies = action.payload;
    },
    updateIcsStrategySuccess: (state, action) => {
      const index = state.ics_strategies.findIndex(
        (obj) => obj.id === action.payload.id
      );
      state.ics_strategies[index] = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  createIcsStrategySuccess,
  fetchIcsStrategiesSuccess,
  updateIcsStrategySuccess,
  setError,
} = ics_strategiesSlice.actions;

export default ics_strategiesSlice.reducer;

export const createIcsStrategy = (data) => async (dispatch, getState) => {
  const user_guid = getUserGuid(getState);
  const group_guid = getGroupGuid(getState);
  try {
    const response = await axios.post(`${config.apiGateway.ics_strategies}`, {
      ...data,
      user_guid,
    });
    console.log('response', response)
    if (data.incident_id) {
      dispatch(fetchIcsIncidentStrategies(data.incident_id));
    }
    dispatch(fetchIcsStrategies(group_guid));
    dispatch(createIcsStrategySuccess(response.data));
  } catch (error) {
    toast.error(error.message, toastConfig);
    dispatch(setError(error.message));
  }
};

export const fetchIcsStrategies = (group_guid) => async (
  dispatch,
  getState
) => {
  const user_guid = getUserGuid(getState);
  try {
    const response = await axios.get(
      `${config.apiGateway.ics_strategies}/${group_guid}`,
      {
        headers: { user_guid: user_guid },
      }
    );
    dispatch(fetchIcsStrategiesSuccess(response.data));
  } catch (error) {
    toast.error(error.message, toastConfig);
    dispatch(setError(error.message));
  }
};

export const updateIcsStrategy = (id, data) => async (dispatch, getState) => {
  const user_guid = getUserGuid(getState);
  const group_guid = getGroupGuid(getState);
  try {
    const response = await axios.put(
      `${config.apiGateway.ics_strategies}/${id}`,
      { ...data, user_guid }
    );
    dispatch(fetchIcsStrategies(group_guid));
    dispatch(updateIcsStrategySuccess(response.data));
  } catch (error) {
    toast.error(error.message, toastConfig);
    dispatch(setError(error.message));
  }
};
