import React from 'react';

export default function ICSIncidentHospital({ row }) {
  return (
    <div className="ICSIncidentHospital">
      <div className="mb-4">
        <h6 className="mb-2">Name:</h6>
        <p className="m-0">{row.hospital_name}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Description:</h6>
        <p className="m-0">{row.description}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Address:</h6>
        <p className="m-0">{row.address}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Contact Number:</h6>
        <p className="m-0">{row.contact_number}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Travel Time (Air):</h6>
        <p className="m-0">{row.travel_time_air}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Travel Time (Ground):</h6>
        <p className="m-0">{row.travel_time_ground}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Burn Center:</h6>
        <p className="m-0">{row.burn_center ? 'Yes' : 'No'}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Helipad:</h6>
        <p className="m-0">{row.heli_pad ? 'Yes' : 'No'}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Last Modified By:</h6>
        <p className="m-0">{row.user_name}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Archived:</h6>
        <p className="m-0">{row.archived ? 'Yes' : 'No'}</p>
      </div>
    </div>
  );
}
