import config from 'constants/apiConfig';
import axios from 'axios';
import {
  FETCH_SAFETY_AND_HEALTH_FORM_DATA,
  UPSERT_FORM_RECORD_ERROR,
} from 'constants/form-action-types';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

function parseError(api) {
  const errorMessage =
    `${api}` +
    'There was an error saving your Form.  No associated Workflows have modified. Please try again.' +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPSERT_FORM_RECORD_ERROR,
  };
}

export const upsertSafetyAndHealthForm = (safety_and_health) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.upsertSafetyAndHealthForm, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        safety_and_health: {
          ...safety_and_health,
          operational_period_id: getState().app.currentIncident.current_period,
        },
      }),
    })
      .then(() => {
        dispatch(getAllSaftyAndHealthForm());
      })
      .catch((error) => {
        dispatch(endLoading());
        dispatch(
          parseError(config.apiGateway.upsertSafetyAndHealthForm, error)
        );
      });
  };
};

export const getAllSaftyAndHealthForm = () => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.getAllSafetyAndHealthFormByOPId, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        operational_period_id: getState().app.currentIncident.current_period,
      }),
    })
      .then((response) => {
        dispatch({
          type: FETCH_SAFETY_AND_HEALTH_FORM_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.getAllSafetyAndHealthFormByOPId, error)
        );
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

export const deleteSaftyAndHealthForm = (safety_and_health_id) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.deleteSafetyAndHealthForm, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        operational_period_id: getState().app.currentIncident.current_period,
        safety_and_health_id,
      }),
    })
      .then(() => {
        dispatch(getAllSaftyAndHealthForm());
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.deleteSafetyAndHealthForm, error)
        );
        dispatch(endLoading());
      });
  };
};
