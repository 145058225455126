import {
  SET_EXERCISE_DETAILS_ID,
  SET_EXERCISE_DETAILS_NAME,
  SET_EXERCISE_DETAILS_STEP,
} from "./exercise.constants";

const initialState = {
  exerciseDetailsId: null,
  exerciseDetailsName: null,
  exerciseDetailsStep: null,
};

const exerciseReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXERCISE_DETAILS_ID:
      return {
        ...state,
        exerciseDetailsId: action.payload,
      };
    case SET_EXERCISE_DETAILS_NAME:
      return {
        ...state,
        exerciseDetailsName: action.payload,
      };
    case SET_EXERCISE_DETAILS_STEP:
      return {
        ...state,
        exerciseDetailsStep: action.payload,
      };
    default:
      return state;
  }
};

export default exerciseReducer;
