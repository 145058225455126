export default function validateSubscription(user,subscriptionInfo)
{
	let userSubscriptionId
	let userSubscriptionSeats
	let userSubscriptionStatus

	// Invalid if no subscription Info
	if(!subscriptionInfo) 
	{
		return false
	}

	if(!!subscriptionInfo 
		&& !!subscriptionInfo?.userSubscriptions?.length
		&& !!subscriptionInfo?.checkOverloadedResult?.length)
	{
		userSubscriptionId = subscriptionInfo.userSubscriptions[0].stripe_subscription_id
		userSubscriptionSeats = subscriptionInfo.userSubscriptions[0].subscription_seats
		userSubscriptionStatus = subscriptionInfo.userSubscriptions[0].subscription_status

		// Invalid if overmaxed (more licenses assigned than purchased)
		if(userSubscriptionSeats<subscriptionInfo.checkOverloadedResult.filter(ssa=>ssa.stripe_subscription_id===userSubscriptionId).length)
		{
			return false
		}
	}

	if(userSubscriptionStatus==="active")
	{
		return true
	}

	return false
}