import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';

import {
  createIcsMedicalTransportation,
  updateIcsMedicalTransportation,
} from 'slices/ics_medicalTransportationSlice';

import { updateIcsIncidentMedicalTransportation } from 'slices/ics_incidentMedicalTransportationSlice';

export default function NewICSMedicalTransportationDialog({
  show,
  setEditMedicalTransportationData,
  editMedicalTransportationData,
  modalType,
  setModalType,
  onClose,
  incident_id,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const [isLoaded, setIsLoaded] = useState(true);
  const [descriptionError, setDescriptionError] = useState({
    hasError: true,
    message: '',
    touched: false,
  });

  const [transportationId, setTransportationId] = useState('');
  const reduxDispatch = useDispatch();
  const isIncidentMedicalTransportationsLoaded = useSelector((state) => {
    return state.app.isIncidentMedicalTransportationsLoaded;
  });
  const isAddIncidentMedicalTransportationsLoaded = useSelector((state) => {
    return state.app.isAddIncidentMedicalTransportationsLoaded;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const [ambulanceService, setAmbulanceService] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [paramedicsOnBoard, setParamedicsOnBoard] = useState(false);

  useEffect(() => {
    if (
      !isLoaded &&
      isIncidentMedicalTransportationsLoaded &&
      isAddIncidentMedicalTransportationsLoaded
    ) {
      onClose();
      setModalType('New');
      setEditMedicalTransportationData(undefined);
    }
  }, [
    isIncidentMedicalTransportationsLoaded,
    isAddIncidentMedicalTransportationsLoaded,
    isLoaded,
    onClose,
  ]);

  useEffect(() => {
    errorCheck(descriptionError);
  }, [description]);

  const errorCheck = (descriptionErrorNew) => {
    if (descriptionErrorNew.touched) {
      if (!description || description === '') {
        setDescriptionError({
          ...descriptionErrorNew,
          hasError: true,
          message: '*Description is required',
        });
      } else {
        setDescriptionError({
          ...descriptionErrorNew,
          hasError: false,
          message: '',
        });
      }
    }
  };
  const onTouched = () => {
    const descriptionErrorNew = { ...descriptionError, touched: true };
    setDescriptionError(descriptionErrorNew);
    errorCheck(descriptionErrorNew);
  };

  useEffect(() => {
    if (modalType === 'New') {
      setTransportationId(generateUUID());
    } else if (modalType === 'Edit') {
      setTransportationId(editMedicalTransportationData.id);
      setValue('ambulance_service', editMedicalTransportationData.ambulance_service);
      setValue('description', editMedicalTransportationData.description);
      setValue('address', editMedicalTransportationData.address);
      setValue('contact_number', editMedicalTransportationData.contact_number);
      setValue('paramedics_on_board', editMedicalTransportationData.paramedics_on_board);
    }
  }, [modalType]);

  const onNewTransportationDataSubmit = (data) => {
    let payload = {
      id: transportationId,
      transportation_id: transportationId,
      ambulance_service: data.ambulance_service,
      description: data.description,
      address: data.address,
      contact_number: data.contact_number,
      paramedics_on_board: data.paramedics_on_board || false,
      incident_id: incident_id,
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      archived: false,
    };
    if (modalType === 'New') {
      reduxDispatch(createIcsMedicalTransportation(payload));
      onClose();
    } else if (modalType === 'Edit') {
      if (!incident_id) {
        reduxDispatch(updateIcsMedicalTransportation(payload.id, payload));
      } else if (!!incident_id) {
        payload.id = editMedicalTransportationData?.id;
        reduxDispatch(
          updateIcsIncidentMedicalTransportation(incident_id, payload.id, payload)
        );
      }
      onClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Medical Transportation</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewTransportationDataSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">ID</label>
            <StylishNewInput value={transportationId} readOnly disabled />
          </div>
          <div className="m-0">
            <label className="form-label">Ambulance Service:</label>
            <Controller
              control={control}
              name="ambulance_service"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.ambulance_service && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Description:</label>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Address:</label>
            <Controller
              control={control}
              name="address"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Contact Number:</label>
            <Controller
              control={control}
              name="contact_number"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={20}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Paramedics On Board:</label>
            <Controller
              control={control}
              name="paramedics_on_board"
              render={({ field: { onChange, value } }) => (
                <StylishNewCheckbox
                  checked={value || false}
                  onChange={(e) => onChange(e.target.checked)}
                />
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
