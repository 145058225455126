import axios from 'axios';
import getQueryParams from 'utils/getQueryParams';
import config from 'constants/apiConfig';
import {
  CREATE_OR_GET_USER_REQUEST,
  UPDATE_INCIDENT_STATUS,
  CREATE_OR_GET_USER_SUCCESS,
  CREATE_OR_GET_USER_ERROR,
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_ERROR,
  FETCH_GROUPS_FOR_USER_REQUEST,
  FETCH_GROUPS_FOR_USER_SUCCESS,
  FETCH_GROUPS_FOR_USER_ERROR,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_ERROR,
  CLOSE_GROUP_REQUEST,
  CLOSE_GROUP_SUCCESS,
  CLOSE_GROUP_ERROR,
  JOIN_GROUP_REQUEST,
  JOIN_GROUP_SUCCESS,
  JOIN_GROUP_ERROR,
  FETCH_INCIDENTS_REQUEST,
  FETCH_INCIDENTS_SUCCESS,
  FETCH_INCIDENTS_ERROR,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  FETCH_GROUP_SOLICITATIONS_REQUEST,
  FETCH_GROUP_SOLICITATIONS_SUCCESS,
  FETCH_GROUP_SOLICITATIONS_ERROR,
  FETCH_USER_SUBSCRIPTIONS_REQUEST,
  FETCH_USER_SUBSCRIPTIONS_SUCCESS,
  FETCH_USER_SUBSCRIPTIONS_ERROR,
  FETCH_SEAT_ASSIGNMENTS_REQUEST,
  FETCH_SEAT_ASSIGNMENTS_SUCCESS,
  FETCH_SEAT_ASSIGNMENTS_ERROR,
  ASSIGN_SEAT_REQUEST,
  ASSIGN_SEAT_SUCCESS,
  ASSIGN_SEAT_ERROR,
  UNASSIGN_SEAT_REQUEST,
  UNASSIGN_SEAT_SUCCESS,
  UNASSIGN_SEAT_ERROR,
  INVITE_GROUP_MEMBER_REQUEST,
  INVITE_GROUP_MEMBER_SUCCESS,
  INVITE_GROUP_MEMBER_ERROR,
  UPDATE_CURRENTLY_SELECTED_REQUEST,
  UPDATE_CURRENTLY_SELECTED_SUCCESS,
  UPDATE_CURRENTLY_SELECTED_ERROR,
  UPDATE_USER_PROFILE_SETTINGS_REQUEST,
  UPDATE_USER_PROFILE_SETTINGS_SUCCESS,
  UPDATE_USER_PROFILE_SETTINGS_ERROR,
  BULK_USER_IMPORT_REQUEST,
  BULK_USER_IMPORT_SUCCESS,
  BULK_USER_IMPORT_ERROR,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_ERROR,
  UPDATE_FOCUS_USER_PROFILE_SETTINGS_SUCCESS,
  CONFIRM_USER_NOT_NEW_REQUEST,
  CONFIRM_USER_NOT_NEW_SUCCESS,
  CONFIRM_USER_NOT_NEW_ERROR,
  FETCH_USER_GROUPS_ROLES_INCIDENTS_REQUEST,
  FETCH_USER_GROUPS_ROLES_INCIDENTS_SUCCESS,
  FETCH_USER_GROUPS_ROLES_INCIDENTS_ERROR,
  ADD_GROUP_QUESTIONS_REQUEST,
  ADD_GROUP_QUESTIONS_SUCCESS,
  GET_ALL_GROUP_QUESTIONS,
  GET_ALL_GROUP_QUESTIONS_SUCCESS,
  GET_ALL_GROUP_QUESTIONS_REQUEST,
  DELETE_GROUP_QUESTIONS_REQUEST,
  DELETE_GROUP_QUESTIONS_SUCCESS,
  ADD_GROUP_ANSWER,
  GROUP_ANSWER_REQUEST,
  GROUP_ANSWER_SUCCESS,
  GET_ALL_GROUP_ANSWER_FOR_ALL_MEMBERS,
} from 'constants/profile-action-types';

import { fetchRostersForGroups, fetchAllRolesInGroup } from './roleActions';

import { getRosterForIncident } from 'actions/incidentActions';

import { toast } from 'react-toastify';
import { endLoading } from 'reducers/loading/loading.action';
import {
  updateUserIdAndName,
  updateUserRole,
  updateUserTimeFormat,
} from 'PREPAREsrc/store/user/user.action';
import { changeSelectedWorkSpace } from 'PREPAREsrc/store/workspaces/workspaces.action';
import { toastConfig, noAutoClose } from 'assets/data/config';
import { FETCH_ALL_USER_WORKSPACE } from 'constants/workspace-action-types';
import { ENVIRONMENT_VAR_LOADED } from 'constants/dice-action-types';
import { checkTeamsStatus, generateTeamsSiteRequest } from './teamsActions';

function createOrGetUserRequest() {
  return {
    type: CREATE_OR_GET_USER_REQUEST,
  };
}

export const createOrGetUserSuccess = (user) => ({
  type: CREATE_OR_GET_USER_SUCCESS,
  payload: user,
});

function createOrGetUserError(error) {
  // TODO CRITICAL
  const errorMessage =
    'createOrGetUserError\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\nIf you continue to experience this error, contact support@disastertech.com';

  return {
    type: CREATE_OR_GET_USER_ERROR,
    payload: errorMessage,
  };
}

export function createOrGetUser({ userName }) {
  return (dispatch) => {
    dispatch(createOrGetUserRequest());
    return axios(config.apiGateway.createOrGetUser, {
      method: 'POST',
      data: JSON.stringify({
        email_address: userName,
      }),
    })
      .then((response) => {
        const user = response.data || {};
        window.pendo.initialize({
          visitor: {
            id: user.user_guid,
            email: user.email_address,
            full_name: user.user_name,
          },
        });
        dispatch(createOrGetUserSuccess(user));
        dispatch(updateUserIdAndName(user.epic_user_name, user.epic_user_id));
        dispatch(changeSelectedWorkSpace(user.epic_user_lastWorkspaceId));
        dispatch(updateUserTimeFormat(user.timeFormat));
        dispatch(updateUserRole(user.roles));
        return user;
      })
      .catch((error) => {
        if (
          error.message ===
          "Missing Refresh Token (audience: 'https://dev-3w6tz0rvxhgsxz4h.us.auth0.com/api/v2/', scope: 'openid profile email offline_access')"
        ) {
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
          return;
        }
        dispatch(createOrGetUserError(error));
      });
  };
}

function getUserByIdRequest() {
  return {
    type: GET_USER_BY_ID_REQUEST,
  };
}

export const getUserByIdSuccess = (user) => ({
  type: GET_USER_BY_ID_SUCCESS,
  payload: user,
});

function getUserByIdError() {
  // TODO CRITICAL
  const errorMessage =
    'You do not have permission to view this user’s profile.';
  console.warn(errorMessage);
  toast.error(errorMessage, toastConfig);
  return {
    type: GET_USER_BY_ID_ERROR,
  };
}

export function getUserById(userGuid) {
  return (dispatch) => {
    dispatch(getUserByIdRequest());
    return axios(config.apiGateway.getUserById, {
      method: 'POST',
      data: JSON.stringify({
        focus_user_id: userGuid,
      }),
    })
      .then((response) => {
        const user = response.data || {};
        dispatch(getUserByIdSuccess(user));

        return user;
      })
      .catch(() => {
        dispatch(getUserByIdError());
      })
      .finally(() => dispatch(endLoading()));
  };
}

function fetchUserSubscriptionsRequest() {
  return {
    type: FETCH_USER_SUBSCRIPTIONS_REQUEST,
  };
}

export const fetchUserSubscriptionsSuccess = (userGuid) => ({
  type: FETCH_USER_SUBSCRIPTIONS_SUCCESS,
  payload: userGuid,
});

function fetchUserSubscriptionsError(error) {
  // TODO CRITICAL
  const errorMessage =
    'fetchUserSubscriptionsError\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\nIf you continue to experience this error, 1212 contact support@disastertech.com';
  return {
    type: FETCH_USER_SUBSCRIPTIONS_ERROR,
    payload: errorMessage,
  };
}

export const fetchUserSubscriptions = () => {
  return (dispatch, getState) => {
    const { user_guid } = getState().app.user;
    dispatch(fetchUserSubscriptionsRequest());
    return axios(config.apiGateway.fetchUserSubscriptions, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
      }),
    })
      .then((response) => {
        const userSubscriptions = response.data || {};
        dispatch(fetchUserSubscriptionsSuccess(userSubscriptions));

        return userSubscriptions;
      })
      .catch((error) => {
        dispatch(fetchUserSubscriptionsError(error));
      });
  };
};

function fetchSeatAssignmentsRequest() {
  return {
    type: FETCH_SEAT_ASSIGNMENTS_REQUEST,
  };
}

export const fetchSeatAssignmentsSuccess = (seatAssignments) => ({
  type: FETCH_SEAT_ASSIGNMENTS_SUCCESS,
  payload: seatAssignments,
});

function fetchSeatAssignmentsError(error) {
  // TODO CRITICAL
  const errorMessage =
    'fetchSeatAssignmentsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_SEAT_ASSIGNMENTS_ERROR,
  };
}

export const fetchSeatAssignments = () => {
  return (dispatch, getState) => {
    const { user_guid } = getState().app.user;
    dispatch(fetchSeatAssignmentsRequest());
    return axios(config.apiGateway.fetchSeatAssignments, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
      }),
    })
      .then((response) => {
        const seatAssignments = response.data || {};
        dispatch(fetchSeatAssignmentsSuccess(seatAssignments));

        return seatAssignments;
      })
      .catch((error) => {
        dispatch(fetchSeatAssignmentsError(error));
      });
  };
};

function assignSeatRequest() {
  return {
    type: ASSIGN_SEAT_REQUEST,
  };
}

function assignSeatSuccess() {
  return {
    type: ASSIGN_SEAT_SUCCESS,
  };
}

function assignSeatError(error) {
  const errorMessage =
    'assignSeatError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: ASSIGN_SEAT_ERROR,
  };
}

export const assignSeat = ({ subscription_id, email_address }) => {
  return (dispatch) => {
    dispatch(assignSeatRequest());
    return axios(config.apiGateway.assignSeat, {
      method: 'POST',
      data: JSON.stringify({
        subscription_id,
        email_address,
      }),
    })
      .then(() => {
        dispatch(fetchSeatAssignments(subscription_id));
        dispatch(assignSeatSuccess());
        // toast.success('Assign Seat successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(assignSeatError(error));
      });
  };
};

function unassignSeatRequest() {
  return {
    type: UNASSIGN_SEAT_REQUEST,
  };
}

function unassignSeatSuccess() {
  return {
    type: UNASSIGN_SEAT_SUCCESS,
  };
}

function unassignSeatError(error) {
  const errorMessage =
    'unassignSeatError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UNASSIGN_SEAT_ERROR,
  };
}

export const unassignSeat = ({ subscription_id, email_address }) => {
  return (dispatch) => {
    dispatch(unassignSeatRequest());
    return axios(config.apiGateway.unassignSeat, {
      method: 'POST',
      data: JSON.stringify({
        subscription_id,
        email_address,
      }),
    })
      .then(() => {
        dispatch(fetchSeatAssignments(subscription_id));
        dispatch(unassignSeatSuccess());
        // toast.success('Unassign Seat successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(unassignSeatError(error));
      });
  };
};

function fetchGroupsForUserRequest() {
  return {
    type: FETCH_GROUPS_FOR_USER_REQUEST,
  };
}

export const fetchGroupsForUserSuccess = (groups, userGuid) => ({
  type: FETCH_GROUPS_FOR_USER_SUCCESS,
  payload: {
    userGuid,
    groups,
  },
});

function fetchGroupsForUserError(error) {
  // TODO CRITICAL
  const errorMessage =
    'fetchGroupsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_GROUPS_FOR_USER_ERROR,
  };
}

export const fetchGroupsForUser = ({ user_guid }) => {
  return (dispatch) => {
    dispatch(fetchGroupsForUserRequest());
    return axios(config.apiGateway.fetchGroupsForUser, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
      }),
    })
      .then((response) => {
        const groups = response.data || {};
        dispatch(fetchGroupsForUserSuccess(groups, user_guid));

        return groups;
      })
      .catch((error) => {
        dispatch(fetchGroupsForUserError(error));
      });
  };
};

function fetchGroupsRequest() {
  return {
    type: FETCH_GROUPS_REQUEST,
  };
}

export const fetchGroupsSuccess = (groups, group_id) => ({
  type: FETCH_GROUPS_SUCCESS,
  payload: { groups, group_id },
});

function fetchGroupsError(error) {
  // TODO CRITICAL
  const errorMessage =
    'fetchGroupsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_GROUPS_ERROR,
  };
}

export const fetchGroups = () => {
  const queryParams = getQueryParams(document.location.search);
  const pathname = document.location.pathname

  let pathIncidentId;
  if (pathname.includes("iap")) {
    const splitPathname = pathname.split("/")
    pathIncidentId = splitPathname[2]
  }
  return (dispatch, getState) => {
    const { user_guid } = getState().app.user;

    dispatch(fetchGroupsRequest());
    return axios(config.apiGateway.fetchGroupsForUser, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
      }),
    })
      .then(async (response) => {
        const groups = response.data || {};
        if (!!queryParams.incident || pathIncidentId) {
          const queryIncident = queryParams.incident || pathIncidentId;
          await axios(config.apiGateway.getGroupFromIncidentId, {
            method: 'POST',
            data: {
              incident: queryIncident,
            },
          })
            .then((groupResponse) => {
              if (groupResponse.data.length == 0) {
                toast.error('Something went wrong in URL.', toastConfig);
                dispatch(fetchGroupsSuccess(groups));
                dispatch(fetchIncidents());
                return;
              }
              const group_Id = groupResponse.data[0].group_id || null;

              if (!group_Id) {
                toast.error('Something went wrong', toastConfig);
                dispatch(fetchGroupsSuccess(groups));
                dispatch(fetchIncidents());
                return;
              }

              const isValidGroupForUser = response.data.some((group) => {
                return group.group_guid === group_Id;
              });

              if (!isValidGroupForUser) {
                toast.error(
                  'User does not have permission for this incident.',
                  toastConfig
                );
                dispatch(fetchGroupsSuccess(groups));
                dispatch(fetchIncidents());
                return;
              }

              dispatch(fetchGroupsSuccess(groups, group_Id));
              dispatch(fetchIncidents(queryIncident));
            })
            .catch((error) => {
              console.log('Fetch Group by incident error', error);
              toast.error('Something went wrong.', toastConfig);
              dispatch(fetchGroupsSuccess(groups));
              dispatch(fetchIncidents());
            });
        } else {
          dispatch(fetchGroupsSuccess(groups));
          dispatch(fetchIncidents());
        }
        return groups;
      })
      .catch((error) => {
        dispatch(fetchGroupsError(error));
      });
  };
};

function createGroupRequest() {
  return {
    type: CREATE_GROUP_REQUEST,
  };
}

export const createGroupSuccess = ({ group_name, groups }) => ({
  groups,
  group_name,
  type: CREATE_GROUP_SUCCESS,
});

function createGroupError(error) {
  const errorMessage =
    'createGroupError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: CREATE_GROUP_ERROR,
  };
}

export const createGroup = ({ groupName }) => {
  return (dispatch, getState) => {
    dispatch(createGroupRequest());

    const {
      user: { user_guid },
      groups: existingGroups,
    } = getState().app;
    return axios(config.apiGateway.createGroup, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
        group_name: groupName.trim(),
      }),
    })
      .then((response) => {
        const groups = response.data || existingGroups;
        const newGroup = groups.find((g) => g.group_name === groupName);
        if (newGroup) {
          dispatch(fetchRostersForGroups([newGroup.group_guid]));
          dispatch(fetchAllRolesInGroup(newGroup.group_guid));
          dispatch(fetchUserGroupsRolesIncidents());
        }
        dispatch(
          createGroupSuccess({
            group_name: groupName.trim(),
            groups,
          })
        );
        // toast.success('Craete Group successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          JSON.stringify(error.response.data) ===
            '{"ERROR":"Group Name Exists"}'
        ) {
          dispatch(
            createGroupError({
              message: 'This Group name is in use.  Please enter a new one.',
            })
          );
        } else {
          dispatch(createGroupError(error));
        }
      });
  };
};

function closeGroupRequest() {
  return {
    type: CLOSE_GROUP_REQUEST,
  };
}

function closeGroupSuccess() {
  return {
    type: CLOSE_GROUP_SUCCESS,
  };
}

function closeGroupError(error) {
  const errorMessage =
    'closeGroupError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: CLOSE_GROUP_ERROR,
  };
}

export const closeGroup = ({ groupGuid }) => {
  return (dispatch, getState) => {
    dispatch(closeGroupRequest());
    const { user_guid } = getState().app.user;
    return axios(config.apiGateway.closeGroup, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
        group_guid: groupGuid,
        isClosed: 1,
      }),
    })
      .then(() => {
        dispatch(fetchGroups());
        dispatch(closeGroupSuccess());
        // toast.success('Close Group successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(closeGroupError(error));
      });
  };
};

function joinGroupRequest() {
  return {
    type: JOIN_GROUP_REQUEST,
  };
}

function joinGroupSuccess() {
  return {
    type: JOIN_GROUP_SUCCESS,
  };
}

function joinGroupError(error) {
  const errorMessage =
    'joinGroupError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: JOIN_GROUP_ERROR,
  };
}

export const joinGroup = ({ groupName }) => {
  return (dispatch, getState) => {
    dispatch(joinGroupRequest());
    const { user_guid } = getState().app.user;
    return axios(config.apiGateway.joinGroup, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
        groupName: groupName,
      }),
    })
      .then(() => {
        dispatch(fetchGroups());
        dispatch(joinGroupSuccess());
        // toast.success('Join Group successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        if (
          JSON.stringify(error.response.data) ===
          '{"ERROR":"This user is already a member of this group"}'
        ) {
          dispatch(
            joinGroupError({
              message: 'This user is already a member of this group',
            })
          );
        } else {
          dispatch(joinGroupError(error));
        }
      });
  };
};

function fetchIncidentsRequest() {
  return {
    type: FETCH_INCIDENTS_REQUEST,
  };
}

const fetchIncidentsSuccess = (incidents, queryIncidentId) => ({
  type: FETCH_INCIDENTS_SUCCESS,
  payload: { incidents, queryIncidentId },
});

function fetchIncidentsError(error) {
  // TODO CRITICAL
  const errorMessage =
    'fetchIncidentsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_INCIDENTS_ERROR,
  };
}

function updateIncidentStatus(status) {
  return {
    type: UPDATE_INCIDENT_STATUS,
    payload: status,
  };
}

export const fetchIncidentStatus = (incidentId = null) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.fetchIncidentStatus, {
      method: 'POST',
      data: JSON.stringify({
        incidentId,
      }),
    })
      .then((response) => {
        const incident = response.data || {};
        const status = incident && Array.isArray(incident) && incident[0]?.status

        if (status) {
          dispatch(updateIncidentStatus(incident?.[0].status));
        }
      })
      .catch((error) => {
        dispatch(fetchIncidentsError(error));
      });
  };
};

export const fetchIncidents = (queryIncidentId = null) => {
  return (dispatch, getState) => {
    dispatch(fetchIncidentsRequest());
    const { user_guid } = getState().app.user;
    return axios(config.apiGateway.fetchIncidents, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
      }),
    })
      .then((response) => {
        const incidents = response.data || {};

        dispatch(fetchIncidentsSuccess(incidents, queryIncidentId));

        return incidents;
      })
      .catch((error) => {
        dispatch(fetchIncidentsError(error));
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

function updateRoleRequest() {
  return {
    type: UPDATE_ROLE_REQUEST,
  };
}

function updateRoleSuccess() {
  return {
    type: UPDATE_ROLE_SUCCESS,
  };
}

function updateRoleError(error) {
  const errorMessage =
    'updateRoleError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_ROLE_ERROR,
  };
}

export const updateRole = ({ role, userGuid, groupGuid }) => {
  return (dispatch, getState) => {
    dispatch(updateRoleRequest());
    const { user_guid } = getState().app.user;
    return axios(config.apiGateway.updateRole, {
      method: 'POST',
      data: JSON.stringify({
        admin_guid: user_guid,
        user_guid: userGuid,
        role,
        group_guid: groupGuid,
      }),
    })
      .then(() => {
        dispatch(fetchGroupSolicitations());
        dispatch(updateRoleSuccess());
        // toast.success('Update Role successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(updateRoleError(error));
      });
  };
};

function fetchGroupSolicitationsRequest() {
  return {
    type: FETCH_GROUP_SOLICITATIONS_REQUEST,
  };
}

const fetchGroupSolicitationsSuccess = (solicitations) => {
  return {
    type: FETCH_GROUP_SOLICITATIONS_SUCCESS,
    payload: solicitations,
  };
};

function fetchGroupSolicitationsError(error) {
  // TODO CRITICAL
  const errorMessage =
    'fetchGroupSolicitationsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_GROUP_SOLICITATIONS_ERROR,
  };
}

export const fetchGroupSolicitations = () => {
  return (dispatch) => {
    dispatch(fetchGroupSolicitationsRequest());
    return axios(config.apiGateway.fetchGroupSolicitations)
      .then((response) => {
        const solicitations = response.data || {};
        dispatch(fetchGroupSolicitationsSuccess(solicitations));
      })
      .catch((error) => {
        dispatch(fetchGroupSolicitationsError(error));
      });
  };
};

function inviteGroupMemberRequest() {
  return {
    type: INVITE_GROUP_MEMBER_REQUEST,
  };
}

function inviteGroupMemberSuccess() {
  return {
    type: INVITE_GROUP_MEMBER_SUCCESS,
  };
}

export function inviteGroupMemberError(error) {
  const errorMessage =
    'inviteGroupMemberError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  // TODO CRITICAL causing bugs so suppressing warnings but that may not be what we want
  //console.error(errorMessage)
  //toast.error(errorMessage)
  return {
    type: INVITE_GROUP_MEMBER_ERROR,
    payload: errorMessage,
  };
}

export const inviteGroupMember = ({
  groupGuid: group_guid,
  email: email_address,
  name: name,
}) => {
  return (dispatch) => {
    dispatch(inviteGroupMemberRequest());
    return axios(config.apiGateway.inviteGroupMember, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
        email_address,
        name,
      }),
    })
      .then((response) => {
        const data = response.data;
        const error = data['ERROR'];

        if (error) {
          dispatch(inviteGroupMemberError(error));
        } else {
          dispatch(inviteGroupMemberSuccess());
          dispatch(fetchRostersForGroups([group_guid]));
        }
        // toast.success('Invite Group Member successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        if (error.message.includes('401')) {
          // there will be more, but this is the one I have right now
          dispatch(
            inviteGroupMemberError({
              message: 'You do not have the correct permissions.',
            })
          );
        } else {
          dispatch(
            inviteGroupMemberError({ message: 'User already in Group.' })
          );
        }
      });
  };
};

function updateCurrentlySelectedRequest() {
  return {
    type: UPDATE_CURRENTLY_SELECTED_REQUEST,
  };
}

function updateCurrentlySelectedSuccess(currently_selected) {
  return {
    type: UPDATE_CURRENTLY_SELECTED_SUCCESS,
    payload: currently_selected,
  };
}

export function updateCurrentlySelectedError(error) {
  const errorMessage =
    'updateCurrentlySelectedError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_CURRENTLY_SELECTED_ERROR,
  };
}

export const updateCurrentlySelected = ({
  groupId: group_guid,
  route,
  aor_id = null,
}) => {
  return (dispatch, getState) => {
    dispatch(updateCurrentlySelectedRequest());
    const { currently_selected } = getState().app.user;
    const new_currently_selected = {
      group_guid: group_guid || currently_selected.group_guid,
      route: route || currently_selected.route,
      aor_id: aor_id || currently_selected.aor_id,
    };
    return axios(config.apiGateway.updateCurrentlySelected, {
      method: 'POST',
      data: JSON.stringify({
        currently_selected: new_currently_selected,
      }),
    })
      .then(() => {
        !!group_guid && dispatch(fetchAllRolesInGroup(group_guid));
        dispatch(updateCurrentlySelectedSuccess(new_currently_selected));
        // toast.success('Update Currently Selected successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(updateCurrentlySelectedError(error));
      });
  };
};

export function updateFocusUserProfileSettingsSuccess({ newSettings }) {
  return {
    payload: newSettings,
    type: UPDATE_FOCUS_USER_PROFILE_SETTINGS_SUCCESS,
  };
}

function updateUserProfileSettingsRequest() {
  return {
    type: UPDATE_USER_PROFILE_SETTINGS_REQUEST,
  };
}

function updateUserProfileSettingsSuccess({ newSettings }) {
  return {
    payload: newSettings,
    type: UPDATE_USER_PROFILE_SETTINGS_SUCCESS,
  };
}

export function updateUserProfileSettingsError(error) {
  const errorMessage =
    'updateUserProfileSettingsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_USER_PROFILE_SETTINGS_ERROR,
  };
}

export const updateUserProfileSettings = (userId, newSettings) => {
  return (dispatch, getState) => {
    dispatch(updateUserProfileSettingsRequest());
    return axios(config.apiGateway.updateUserProfileSettings, {
      method: 'POST',
      data: JSON.stringify({
        user_id: userId,
        newSettings,
      }),
    })
      .then(() => {
        if (userId === getState().app.user.user_guid)
          dispatch(updateUserProfileSettingsSuccess({ newSettings }));
        const { currentlySelectedGroup } = getState().app;
        const { currentIncident } = getState().app;
        if (!!currentlySelectedGroup)
          dispatch(fetchRostersForGroups([currentlySelectedGroup.group_guid]));
        if (!!currentIncident) dispatch(getRosterForIncident());
        dispatch(getUserById(userId));
        // toast.success('Update User Profile Settings successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(updateUserProfileSettingsError(error));
      });
  };
};

function bulkUserImportRequest() {
  return {
    type: BULK_USER_IMPORT_REQUEST,
  };
}

function bulkUserImportSuccess(bulkImportedUsers) {
  return {
    type: BULK_USER_IMPORT_SUCCESS,
    payload: bulkImportedUsers,
  };
}

export function bulkUserImportError(error) {
  const errorMessage =
    'bulkUserImportError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: BULK_USER_IMPORT_ERROR,
  };
}

export const bulkUserImport = (template, group) => {
  return (dispatch) => {
    dispatch(bulkUserImportRequest());
    var formData = new FormData();
    formData.append('template', template);
    formData.append('group', JSON.stringify(group));

    return axios
      .post(config.apiGateway.bulkUserImport, formData, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        const bulkImportedUsers = response.data || {};
        dispatch(bulkUserImportSuccess(bulkImportedUsers));
        dispatch(fetchRostersForGroups([group.group_guid]));
        // toast.success('Bulk User Import successful', {
        //   autoClose: 2000,
        // });
        return bulkImportedUsers;
      })
      .catch((error) => {
        //dispatch(bulkUserImportError({...error,message: error.response.data.ERROR}));
        dispatch(endLoading());
        toast.error(
          'bulkUserImportError\n\n' +
            ((!!error &&
              !!error.message &&
              typeof error.message === 'string' &&
              error.message) ||
              '') +
            '\n\nIf you continue to experience this error, contact support@disastertech.com',
          noAutoClose
        );
        dispatch({ type: BULK_USER_IMPORT_ERROR });
      });
  };
};

export const toggleAllowAdminProfileEdit = (id) => {
  return (dispatch) => {
    return axios(config.apiGateway.toggleAllowAdminProfileEdit, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: id,
      }),
    })
      .then(() => {
        dispatch(fetchGroups());
        dispatch(fetchUserGroupsRolesIncidents());
        // toast.success('Toggle Allow Admin Profile Edit successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(updateUserProfileSettingsError(error));
      });
  };
};

function confirmUserNotNewRequest() {
  return {
    type: CONFIRM_USER_NOT_NEW_REQUEST,
  };
}

function confirmUserNotNewSuccess() {
  return {
    type: CONFIRM_USER_NOT_NEW_SUCCESS,
  };
}

export function confirmUserNotNewError(error) {
  const errorMessage =
    'confirmUserNotNewError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: CONFIRM_USER_NOT_NEW_ERROR,
  };
}

export const confirmUserNotNew = () => {
  return (dispatch) => {
    dispatch(confirmUserNotNewRequest());
    return axios(config.apiGateway.confirmUserNotNew, {
      method: 'POST',
    })
      .then(() => {
        dispatch(confirmUserNotNewSuccess());
        // toast.success('Confirm User not new successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(confirmUserNotNewError(error));
      });
  };
};

function fetchUserGroupsRolesIncidentsRequest() {
  return {
    type: FETCH_USER_GROUPS_ROLES_INCIDENTS_REQUEST,
  };
}

function fetchUserGroupsRolesIncidentsSuccess(userGroupsRolesIncidents) {
  return {
    type: FETCH_USER_GROUPS_ROLES_INCIDENTS_SUCCESS,
    payload: userGroupsRolesIncidents,
  };
}

export function profileActionsError(error) {
  const errorMessage =
    'profileActions Error\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_USER_GROUPS_ROLES_INCIDENTS_ERROR,
  };
}

export const fetchUserGroupsRolesIncidents = (user_guid) => {
  return (dispatch) => {
    dispatch(fetchUserGroupsRolesIncidentsRequest());
    return axios(config.apiGateway.fetchUserGroupsRolesIncidents, {
      method: 'POST',
      data: JSON.stringify({
        user_guid: user_guid,
      }),
    })
      .then((response) => {
        const userGroupsRolesIncidents = response.data || [];
        dispatch(
          fetchUserGroupsRolesIncidentsSuccess(userGroupsRolesIncidents)
        );
      })
      .catch((error) => {
        dispatch(profileActionsError(error));
      });
  };
};

export const addGroupQuestion = (question) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_GROUP_QUESTIONS_REQUEST });
    return axios(config.apiGateway.createGroupQuestion, {
      method: 'POST',
      data: JSON.stringify({
        question,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        dispatch({ type: ADD_GROUP_QUESTIONS_SUCCESS });
        dispatch(getAllGroupQuestion());
        dispatch(getAllGroupAnswer());
        // toast.success('Create Group Question successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(profileActionsError(error));
      });
  };
};

export const getAllGroupQuestion = () => {
  return (dispatch, getState) => {
    dispatch({ type: GET_ALL_GROUP_QUESTIONS_REQUEST });
    return axios(config.apiGateway.getAllGroupQuestion, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_ALL_GROUP_QUESTIONS_SUCCESS });
        dispatch({ type: GET_ALL_GROUP_QUESTIONS, payload: response.data });
      })
      .catch((error) => {
        dispatch(profileActionsError(error));
      });
  };
};

export const deleteGroupQuestion = (question_id) => {
  return (dispatch, getState) => {
    dispatch({ type: DELETE_GROUP_QUESTIONS_REQUEST });
    return axios(config.apiGateway.deleteGroupQuestion, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        question_id,
      }),
    })
      .then(() => {
        dispatch({ type: DELETE_GROUP_QUESTIONS_SUCCESS });
        dispatch(getAllGroupQuestion());
        dispatch(getAllGroupAnswer());
        // toast.success('Delete Group Question successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(profileActionsError(error));
      });
  };
};

export const getAllQuestionAnswer = (group_guid) => {
  return (dispatch) => {
    dispatch({ type: GROUP_ANSWER_REQUEST });
    return axios(config.apiGateway.getAllGroupQuestionWithAnswer, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: group_guid,
      }),
    })
      .then((response) => {
        dispatch({ type: GROUP_ANSWER_SUCCESS });
        if (response.data.length > 0) {
          dispatch({
            type: ADD_GROUP_ANSWER,
            payload: { group_guid, response: response.data },
          });
        }
      })
      .catch((error) => {
        dispatch(profileActionsError(error));
      });
  };
};

export const getAllGroupAnswer = () => {
  return (dispatch, getState) => {
    dispatch({ type: GROUP_ANSWER_REQUEST });
    return axios(config.apiGateway.getAllGroupAnswer, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        dispatch({ type: GROUP_ANSWER_SUCCESS });
        dispatch({
          type: GET_ALL_GROUP_ANSWER_FOR_ALL_MEMBERS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(profileActionsError(error));
      });
  };
};

export const addUserAnswerForGroupQuestion = (answer, group_guid) => {
  return (dispatch) => {
    dispatch({ type: GROUP_ANSWER_REQUEST });
    return axios(config.apiGateway.addUserAnswerForGroupQuestion, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: group_guid,
        answer,
      }),
    })
      .then((response) => {
        dispatch({ type: GROUP_ANSWER_SUCCESS });
        dispatch(getAllQuestionAnswer(group_guid));
        // toast.success('Add user Answer for Group Question successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(profileActionsError(error));
      });
  };
};

export const getAllUserWorkspace = (user_guid) => {
  return (dispatch, getState) => {
    if (!getState().app?.currentlySelectedGroup) {
      return;
    }
    return axios(config.apiGateway.getAllUserWorkspace, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
        group_guid: getState().app?.currentlySelectedGroup?.group_guid,
      }),
    })
      .then((response) => {
        dispatch({ type: FETCH_ALL_USER_WORKSPACE, payload: response.data });
      })
      .catch((error) => {
        dispatch(profileActionsError(error));
      });
  };
};

export const getEnvironmentVariables = () => {
  return (dispatch) => {
    return axios(config.apiGateway.getEnvironmentVariables, {
      method: 'GET',
    })
      .then((response) => {
        window.env = {
          ...window.env,
          ...response.data,
        };
        dispatch({ type: ENVIRONMENT_VAR_LOADED });
      })
      .catch((error) => {
        dispatch(profileActionsError(error));
      });
  };
};
