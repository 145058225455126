import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewObjectiveDialog from 'components/IncidentConfig/NewObjectiveDialog';
import {
  deleteObjectiveToIncident,
  updateIncidentObjectiveSelection,
} from 'actions/incidentActions';
import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

export default function ManageObjectivesDialog({
  show,
  onClose,
  groupObjectives,
  selectedObjectives,
  setSelectedObjectives,
}) {
  const reduxDispatch = useDispatch();

  const [isNewObjectiveDialogOpen, setIsNewObjectiveDialogOpen] = useState(
    false
  );
  const [modalType, setModalType] = useState('New');
  const [editObjectiveData, setEditObjectiveData] = useState();

  const [dialogObjectives, setDialogObjectives] = useState([
    ...selectedObjectives,
  ]);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);

  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const allColumns = [
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      attrs: { title: 'Description' },
      filter: textFilter(),
    },
    {
      dataField: 'objective_id',
      text: 'ID #',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'ID #' },
      filter: textFilter(),
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="mb-4">
            <h6 className="mb-2">Description</h6>
            <p className="m-0">{row.description}</p>
          </div>
          <div className="button-group">
            {!!reduxValidateRBACPermissionForActionResult['Write Pod Data'] && (
              <>
                <StylishNewButton
                  className="button--primary"
                  onClick={() => onEdit(row)}
                >
                  Edit Description
                </StylishNewButton>
                <StylishNewButton
                  className="button--tertiary button--reverse"
                  onClick={() => onDelete(row)}
                >
                  Delete
                </StylishNewButton>
              </>
            )}
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: false,
    selected: dialogObjectives.map((e) => e.objective_id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      let selection = dialogObjectives.map((item) => item.objective_id);

      if (selection.includes(row.objective_id)) {
        selection = selection.filter((e) => e !== row.objective_id);
      } else {
        selection.push(row.objective_id);
      }

      setDialogObjectives(
        groupObjectives.filter((e) => selection.includes(e.objective_id))
      );
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setDialogObjectives(rows) : setDialogObjectives([]);
    },
  };

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewObjectiveDialogOpen(true);
    setEditObjectiveData(row);
  };

  const onDelete = (row) => {
    setShowConfirmDialog(true);
    setDeleteConfirmationData(row);
  };

  useEffect(() => {
    reduxDispatch(endLoading());
  }, [groupObjectives]);

  const onConfirmedDeleteActionData = () => {
    setDialogObjectives(
      dialogObjectives.filter((e) => e.id !== deleteConfirmationData.id)
    );
    reduxDispatch(startLoading());
    reduxDispatch(
      deleteObjectiveToIncident(deleteConfirmationData.id, 'ICS 201')
    );
    setShowConfirmDialog(false);
    setDeleteConfirmationData(null);
  };

  const onNewObjective = () => {
    setModalType('New');
    setIsNewObjectiveDialogOpen(true);
  };

  const handleSaveClick = () => {
    !!setSelectedObjectives && setSelectedObjectives([...dialogObjectives]);
    reduxDispatch(startLoading());
    reduxDispatch(
      updateIncidentObjectiveSelection(dialogObjectives.map((item) => item.id))
    );
    onClose();
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Select Incident Objectives</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">
          {reduxCurrentlySelectedGroup?.group_name}
        </p>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={onNewObjective}
          disabled={
            !reduxValidateRBACPermissionForActionResult['Write Pod Data']
          }
        >
          New Objective
        </StylishNewButton>
        <StylishNewTable
          keyField={'objective_id'}
          rows={groupObjectives}
          columns={allColumns}
          expandRow={expandRow}
          selectRow={selectRow}
          filter={filterFactory()}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
          <StylishNewButton
            type="button"
            className="button--primary"
            onClick={() => handleSaveClick()}
          >
            Save
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewObjectiveDialogOpen && (
        <NewObjectiveDialog
          show={isNewObjectiveDialogOpen}
          setEditObjectiveData={setEditObjectiveData}
          setModalType={setModalType}
          editObjectiveData={editObjectiveData}
          modalType={modalType}
          onClose={() => setIsNewObjectiveDialogOpen(false)}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this Objective?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedDeleteActionData}
        />
      )}
    </Modal>
  );
}
