import React, { useEffect, useState } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import moment from 'moment';
import { Dropdown, Modal } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import AddUpdateWorkAssignment from './WorkAssignment/AddUpdateWorkAssignMent';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAction } from 'actions/IAPForm201Actions';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { updateWorkAssignmentStatus } from 'actions/IAPForm204Actions';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const StatusOptions = [
  {
    value: { status: 'In Review', statusColor: '#6a6bec' },
    label: 'In Review',
  },
  {
    value: {
      status: 'Draft',
      statusColor: '#20c997',
    },
    label: 'Draft',
  },
  {
    value: { status: 'Approved', statusColor: '#198754' },
    label: 'Approved',
  },
  {
    value: { status: 'Assigned / In Progress', statusColor: '#dc3545' },
    label: 'Assigned / In Progress',
  },

  {
    value: { status: 'Completed', statusColor: '#d8be35' },
    label: 'Completed',
  },

  {
    value: { status: 'Not Approved', statusColor: '#ffc107' },
    label: 'Not Approved',
  },
];
export const AssignmentDetailsDialog = ({
  show,
  onClose,
  data,
  planningWorksheet,
}) => {
  const reduxDispatch = useDispatch();
  const [key, setKey] = useState('assignment_details');
  const [selectedStatus, setSelectedStatus] = useState(
    !!data.status
      ? {
          value: {
            status: data.status,
            statusColor: StatusOptions.find(
              (option) => option.label === data.status
            )?.value?.statusColor,
          },
          label: data.status,
        }
      : data.current
      ? {
          value: { status: 'Current', statusColor: '#ffc107' },
          label: 'Current',
        }
      : {
          value: { status: 'Planned', statusColor: '#0d2b6b' },
          label: 'Planned',
        }
  );
  const [
    isWorkAssignmentsDialogOpen,
    setIsWorkAssignmentsDialogOpen,
  ] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [updateAssignmentData, setUpdateAssignmentData] = useState();

  const incidentObjectives = useSelector(
    (state) => state.app.incidentObjectives
  );
  const reduxIncidentResources = useSelector(
    (state) => state.app.incidentResources
  );

  useEffect(() => {
    if (!!selectedStatus) {
      reduxDispatch(
        updateWorkAssignmentStatus(data.action_id, selectedStatus.label)
      );
    }
  }, [selectedStatus]);

  useEffect(() => {
    setUpdateAssignmentData(data);
  }, [data?.branch_name]);

  const columns = [
    {
      dataField: 'objective_id',
      text: 'Number',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Number' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      attrs: { title: 'Description' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <h6>Description</h6>
          {row.description}
          {/* {!!reduxValidateRBACPermissionForActionResult['Write Pod Data'] && (
            <div className="button-group mt-4">
              <StylishNewButton
                className="button--primary"
                onClick={() => onEdit(row)}
              >
                Edit Description
              </StylishNewButton>
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={() => onDelete(row)}
              >
                Delete
              </StylishNewButton>
            </div>
          )} */}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const columnsResourceTable = [
    {
      dataField: 'name',
      text: 'Identifier',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Identifier' },
    },
    {
      dataField: 'user_name',
      text: 'Leader',
      sort: true,
      attrs: { title: 'Leader' },
    },
    {
      dataField: 'phone_number',
      text: 'Contact Info. #',
      sort: true,
      attrs: { title: 'Contact Info. #' },
    },
    {
      dataField: 'total',
      text: '# of Persons',
      sort: true,
      attrs: { title: '# of Persons' },
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      attrs: { title: 'Type' },
    },
  ];

  const RowDetailResourceTable = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="mb-3">
            <h6 className="mb-1">Date / Time Ordered</h6>
            <p className="m-0">
              {moment(row.datetime_ordered).format('DD/MM/YYYY HH:mm')}
            </p>
            <h6 className="mb-1">ETA</h6>
            <p className="m-0">{moment(row.eta).format('DD/MM/YYYY HH:mm')}</p>
            <h6 className="mb-1">Current Location</h6>
            <p className="m-0">{row.location || row.geolocation?.name}</p>
            <h6 className="mb-1">Resouce Notes</h6>
            <p className="m-0">{row.description}</p>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const onDeleteAction = () => {
    setShowConfirmDialog(true);
  };

  const onConfirmedDeleteActionData = () => {
    reduxDispatch(deleteAction(data.action_id, 'Work Assignment'));
    onClose();
  };

  const onEditAssigmnent = () => {
    setUpdateAssignmentData(data);
    setIsWorkAssignmentsDialogOpen(true);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="xl"
        fullscreen="xl-down"
      >
        <Modal.Header closeButton closeVariant="white" className="pb-0">
          <Modal.Title>Work Assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-end mb-4">
            <div className="m-0">
              <h6 className="mb-2">
                {moment(data.required_date_time).format('Do MMM YYYY HH:mm')}
              </h6>
              <span
                className="status"
                style={{ background: selectedStatus.value.statusColor }}
              >
                {selectedStatus.label}
              </span>
            </div>
            <Dropdown className="flex-shrink-0 ms-auto">
              <Dropdown.Toggle className="button--icon">
                <SharedIcon iconName="more_vert" bold />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item as="button" onClick={onEditAssigmnent}>
                  <SharedIcon iconName="stylus" />
                  Edit Assignment
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={onDeleteAction}>
                  <SharedIcon iconName="delete" />
                  Delete Assignment
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="row mb-4">
            <div className="col-md-6 mb-3 mb-md-0">
              <h6 className="mb-1">Branch</h6>
              {data?.branch?.name || planningWorksheet?.branch_name}
            </div>
            <div className="col-md-6">
              <h6 className="mb-1">Division / Group / Staging</h6>
              {data?.division?.name || planningWorksheet?.division_name}
            </div>
          </div>
          <div className="tab-wrapper content-gap-none pt-3">
            <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
              <Tab eventKey="assignment_details" title="Assignment Details">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <h6 className="mb-1">Overhead</h6>
                    {data.overhead}
                  </div>
                  <div className="col-md-4 mb-3">
                    <h6 className="mb-1">Special Equipment and Supplies</h6>
                    {data.special_equipment}
                  </div>
                  <div className="col-md-4 mb-3">
                    <h6 className="mb-1">Required Location</h6>
                    {data.required_location}
                  </div>
                  <div className="col-md-12 mb-3">
                    <h6 className="mb-1">Description</h6>
                    {data.description}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="incident_objectives" title="Incident Objectives">
                <p className="weight-600">
                  <StylishNewTable
                    keyField={'id'}
                    columns={columns}
                    rows={data.objectives}
                    expandRow={expandRow}
                  />
                </p>
              </Tab>
              {/* <Tab eventKey="activity_log" title="Activity Log">
                <p className="weight-600">Activity Log Coming Soon !!!</p>
              </Tab> */}
              <Tab eventKey="additional_resources" title="Additional Resources">
                <StylishNewTable
                  keyField={'id'}
                  columns={columnsResourceTable}
                  rows={data.resources}
                  expandRow={RowDetailResourceTable}
                />
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <StylishNewSelect
            classes="w-100 w-md-25 mb-3 mb-md-0"
            options={StatusOptions}
            value={selectedStatus}
            placeholder="Select a Status"
            onChange={(e) => {
              if (!e) {
                setSelectedStatus(null);
              } else {
                setSelectedStatus({
                  value: {
                    status: e.value.status,
                    statusColor: e.value.statusColor,
                  },
                  label: e.label,
                });
              }
            }}
            isDisabled={false}
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            menuPlacement="top"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: !!selectedStatus?.value?.statusColor
                  ? `${selectedStatus.value.statusColor} !important`
                  : '',
                background: !!selectedStatus?.value?.statusColor
                  ? `${selectedStatus.value.statusColor} !important`
                  : '',
              }),
              indicatorSeparator: (baseStyles, state) => ({
                ...baseStyles,
                background: !!selectedStatus?.value?.statusColor
                  ? `#fff !important`
                  : '',
              }),
            }}
          />
          <div className="button-group ms-auto">
            <StylishNewButton
              type="button"
              className="button--primary"
              onClick={onEditAssigmnent}
            >
              Edit Assignment
            </StylishNewButton>
            <StylishNewButton
              onClick={onDeleteAction}
              className="button--tertiary button--reverse"
              type="button"
            >
              Delete Assignment
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>
      {isWorkAssignmentsDialogOpen && (
        <AddUpdateWorkAssignment
          incidentObjectives={incidentObjectives}
          incidentResources={reduxIncidentResources}
          workAssignmentModalType={'Edit'}
          updateActionData={updateAssignmentData}
          show={isWorkAssignmentsDialogOpen}
          onClose={(e) =>
            e ? onClose() : setIsWorkAssignmentsDialogOpen(false)
          }
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this assignment?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={() => onConfirmedDeleteActionData()}
        />
      )}
    </>
  );
};
