import { Offcanvas, Row } from 'react-bootstrap';
import React from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { AddEntityDrawerProps } from './EntityListProps';
import { useCreateIncidentHospital } from './hooks/incidentHospitalsHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import FormCheckboxField from './formFields/FormCheckboxField';
import { useActualIncidentId } from './hooks/commonHooks';
import { useSelector } from 'react-redux';
import { selectUserGuid } from '../../slices/commonSelectors';
import {
  selectGroupGuid,
  useAppSelector,
} from '../../slices/commonSelectors';

type FormFields = {
  hospital_id: string;
  hospital_name?: string;
  description?: string;
  address?: string;
  contact_number?: string;
  travel_time_air?: string;
  travel_time_ground?: string;
  burn_center?: boolean;
  heli_pad?: boolean;
  archived?: boolean;
};

const HospitalAddDrawer: React.FC<AddEntityDrawerProps> = ({ toggle }) => {
  const form = useForm<FormFields>({ mode: 'onChange' });
  const { handleSubmit } = form;

  const mutation = useCreateIncidentHospital();

  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useAppSelector(selectGroupGuid);

  const onNewHospitalDataSubmit = async (formData: FormFields) => {
    const payload = {
      ...formData,
      archived: formData.archived ?? false,
      incident_id: incidentId,
      user_guid: user_guid,
      group_guid
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Add Hospital">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onNewHospitalDataSubmit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextField
                title="Hospital Name"
                fieldName="hospital_name"
                rules={{ maxLength: { value: 200, message: 'This maximum length is 200 characters' } }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Description"
                fieldName="description"
                rules={{ maxLength: { value: 300, message: 'This maximum length is 300 characters' } }}
                variant="full"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Address"
                fieldName="address"
                rules={{ maxLength: { value: 300, message: 'This maximum length is 300 characters' } }}
                variant="full"
              />
            </Row>
            <Row>
              <FormTextField
                title="Contact Number"
                fieldName="contact_number"
                rules={{ maxLength: { value: 20, message: 'This maximum length is 20 characters' } }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextField
                title="Travel Time Air"
                fieldName="travel_time_air"
                rules={{ maxLength: { value: 50, message: 'This maximum length is 50 characters' } }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextField
                title="Travel Time Ground"
                fieldName="travel_time_ground"
                rules={{ maxLength: { value: 50, message: 'This maximum length is 50 characters' } }}
                variant="half"
              />
            </Row>
            <Row>
              <FormCheckboxField
                title="Burn Center"
                fieldName="burn_center"
              />
            </Row>
            <Row>
              <FormCheckboxField
                title="Heli Pad"
                fieldName="heli_pad"
              />
            </Row>
            {/* Add other fields as necessary */}
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default HospitalAddDrawer;
