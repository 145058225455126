import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import {
    fetchIcsIncidentTactics
} from './ics_incidentTacticsSlice';

import {
    getUserGuid,
    getGroupGuid
} from 'slices/sliceHelpers';

// Slice
const ics_tacticsSlice = createSlice({
    name: 'ics_tactics',
    initialState: {
        ics_tactics: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        createIcsTacticSuccess: (state, action) => {
            state.ics_tactics.push(action.payload);
        },
        fetchIcsTacticsSuccess: (state, action) => {
            state.ics_tactics = action.payload;
        },
        updateIcsTacticSuccess: (state, action) => {
            const index = state.ics_tactics.findIndex((obj) => obj.id === action.payload.id);
            state.ics_tactics[index] = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    createIcsTacticSuccess,
    fetchIcsTacticsSuccess,
    updateIcsTacticSuccess,
    setError
} = ics_tacticsSlice.actions;

export default ics_tacticsSlice.reducer;

export const createIcsTactic = (data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_tactics}`, { ...data, user_guid });
        if (data.incident_id) 
        {
            dispatch(fetchIcsIncidentTactics(data.incident_id));
        }
        dispatch(fetchIcsTactics(group_guid));
        dispatch(createIcsTacticSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsTactics = (group_guid) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_tactics}/${group_guid}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsTacticsSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsTactic = (id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_tactics}/${id}`, { ...data, user_guid });
        dispatch(fetchIcsTactics(group_guid));
        dispatch(updateIcsTacticSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
