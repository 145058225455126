import React from 'react';
import { getPlanningWorksheet, upsertBranch } from 'actions/IAPForm204Actions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import generateUUID from 'utils/sharedUtils/generateUUID';

export default function AddUpdateBranchModal({
  show,
  onClose,
  selectedBranch,
}) {
  const [currentGroupUser, setCurrentGroupUser] = useState([]);

  const reduxDixpatch = useDispatch();

  const reduxRostersForGroups = useSelector((state) => {
    return state.app.rostersForGroups || [];
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: { branch_name: !!selectedBranch ? selectedBranch.name : '' },
  });

  const onSubmit = async (data) => {
    const branch = {
      name: data.branch_name,
      id: !!selectedBranch ? selectedBranch.id : generateUUID(),
      branch_director: data.branch_director.value?.user_guid,
    };
    try {
      reduxDixpatch(startLoading());
      await reduxDixpatch(upsertBranch(branch));
      reduxDixpatch(getPlanningWorksheet());
      onClose();
    } catch (e) {
    } finally {
      reduxDixpatch(endLoading());
    }
  };

  useEffect(() => {
    if (!!reduxCurrentlySelectedGroup && reduxRostersForGroups.length > 0) {
      const currentGroupUsers = reduxRostersForGroups
        .filter(
          (user) => user.group_guid === reduxCurrentlySelectedGroup.group_guid
        )
        .map((user) => ({
          value: user,
          label: user.profile_settings?.userName || user.email_address,
        }));
      setCurrentGroupUser(currentGroupUsers);
    }
  }, [reduxRostersForGroups]);

  useEffect(() => {
    if (selectedBranch) {
      setValue('branch_name', selectedBranch?.name?.trim());

      setValue(
        'branch_director',
        currentGroupUser.find(
          (cgu) => cgu.value.user_guid === selectedBranch?.branch_director
        )
      );
    }
  }, [selectedBranch, currentGroupUser]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Create Branch</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3 pt-1">
            <label className="form-label">Name:</label>
            <Controller
              control={control}
              name="branch_name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput onChange={onChange} value={value} />
              )}
            />
            {errors?.branch_name && (
              <span className="form-text form-error">
                This field is required.
              </span>
            )}
          </div>
          <div className="mb-3 pt-1">
            <label className="form-label">Branch Director:</label>
            <Controller
              control={control}
              name="branch_director"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={currentGroupUser}
                  value={value}
                  placeholder="Select a Branch Director"
                  onChange={onChange}
                  isDisabled={false}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={false}
                />
              )}
            />
            {errors?.branch_director && (
              <span className="form-text form-error">
                This field is required.
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              type="button"
              className="button--secondary button--reverse"
              onClick={onClose}
            >
              Cancel
            </StylishNewButton>
            <StylishNewButton type="submit" className="button--primary">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
