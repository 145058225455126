export const eventTileHazards = [
    {
        "id": "00d620c3-491a-42a6-b618-b45a75a7b606",
        "hazard_name": "911 Telephone Outage",
        "hazard_color": "#C0C0C0",
        "hazard_alert_type": "Immediate"
    },
    {
        "id": "5fb0bc45-b123-49c9-b3a7-b355a6e4e41b",
        "hazard_name": "Administrative Message",
        "hazard_color": "#C0C0C0",
        "hazard_alert_type": "Immediate"
    },
    {
        "id": "838ca609-6853-44e2-b01a-507a1db9f193",
        "hazard_name": "Air Quality Alert",
        "hazard_color": "#808080",
        "hazard_alert_type": "Alert"
    },
    {
        "id": "fbf90391-1690-4dab-972a-bdd899a7b4c5",
        "hazard_name": "Air Stagnation Advisory",
        "hazard_color": "#808080",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "4bacf354-ea1d-4efa-8fef-38560d98e7e6",
        "hazard_name": "Arroyoand Small Stream Flood Advisory",
        "hazard_color": "#00FF7F",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "1f0f7e27-5b0f-4363-89a4-628909a00e11",
        "hazard_name": "Ashfall Advisory",
        "hazard_color": "#696969",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "abd383ce-cee8-440c-82a6-a094486db9ce",
        "hazard_name": "Ashfall Warning",
        "hazard_color": "#A9A9A9",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "e43f5365-dfdc-495d-88ac-afbe61bca5eb",
        "hazard_name": "Avalanche Advisory",
        "hazard_color": "#CD853F",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "fd1305df-10b3-417b-a147-c128f81bf045",
        "hazard_name": "Avalanche Warning",
        "hazard_color": "#1E90FF",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "f7aaac3e-bbaa-40f5-ad01-9f6507c0ade3",
        "hazard_name": "Avalanche Watch",
        "hazard_color": "#F4A460",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "8814be71-cce0-47f6-88f9-2f5d7585f72a",
        "hazard_name": "Beach Hazards Statement",
        "hazard_color": "#40E0D0",
        "hazard_alert_type": "Statement"
    },
    {
        "id": "45124d70-f653-46c6-a30b-ada0bc2e8bc7",
        "hazard_name": "Blizzard Warning",
        "hazard_color": "#FF4500",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "dea7a25e-1147-45a6-a30e-e5a3598c9b2f",
        "hazard_name": "Blizzard Watch",
        "hazard_color": "#ADFF2F",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "7b98dd36-f380-4a46-8c69-81ec4cac99d1",
        "hazard_name": "Blowing Dust Advisory",
        "hazard_color": "#BDB76B",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "08d3743d-8d57-4056-98f4-73a90d0a36df",
        "hazard_name": "Blowing Dust Warning",
        "hazard_color": "#FFE4C4",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "6c3c3cde-9ea5-4484-bc36-728a28212039",
        "hazard_name": "Blue Alert",
        "hazard_color": "#1E90FF",
        "hazard_alert_type": "Alert"
    },
    {
        "id": "3bc06125-d4ce-4d4c-9c9a-60848af1f3ea",
        "hazard_name": "Brisk Wind Advisory",
        "hazard_color": "#D8BFD8",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "9f2d539f-2309-48f1-ba0d-ad688db5993f",
        "hazard_name": "Civil Danger Warning",
        "hazard_color": "#FFB6C1",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "2c0b3502-d4cf-4467-a72e-de35b20b80e7",
        "hazard_name": "Civil Emergency Message",
        "hazard_color": "#FFB6C1",
        "hazard_alert_type": "Immediate"
    },
    {
        "id": "ebed8986-932d-4f0c-8d01-519fb0b24a6e",
        "hazard_name": "Coastal Flood Advisory",
        "hazard_color": "#7CFC00",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "0a4e1422-975a-4438-b0bc-f4cc62251c69",
        "hazard_name": "Coastal Flood Statement",
        "hazard_color": "#6B8E23",
        "hazard_alert_type": "Statement"
    },
    {
        "id": "8a5eb1a1-bbb6-4c5a-b47d-3429d2007ad3",
        "hazard_name": "Coastal Flood Warning",
        "hazard_color": "#228B22",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "7dca6885-772b-4b75-972e-5d16149abbb7",
        "hazard_name": "Coastal Flood Watch",
        "hazard_color": "#66CDAA",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "8d70e7e5-c524-417f-8a11-0d2c8c597b63",
        "hazard_name": "Dense Fog Advisory",
        "hazard_color": "#708090",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "f6a6ac17-e10a-46fe-80f6-17ee49aaa4ef",
        "hazard_name": "Dense Smoke Advisory",
        "hazard_color": "#F0E68C",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "5dc36ec1-bcdc-4a57-90f4-f790c251441e",
        "hazard_name": "Dust Advisory",
        "hazard_color": "#BDB76B",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "f2885efb-c73b-4c8a-a018-a065d74aaf69",
        "hazard_name": "Dust Storm Warning",
        "hazard_color": "#FFE4C4",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "64940e78-5cb4-4cf5-8ffd-3f254dbe58e1",
        "hazard_name": "Earthquake Warning",
        "hazard_color": "#8B4513",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "f64ea0fc-de9d-45dd-87a6-5f98b9969624",
        "hazard_name": "Evacuation-Immediate",
        "hazard_color": "#7FFF00",
        "hazard_alert_type": "Immediate"
    },
    {
        "id": "158adada-f469-4ad0-97e6-ae8a20a2066a",
        "hazard_name": "Excessive Heat Warning",
        "hazard_color": "#C71585",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "7cf90b23-c896-4d1f-93de-f027c93407ad",
        "hazard_name": "Excessive Heat Watch",
        "hazard_color": "#800000",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "28b54f19-da8a-40ce-8e3b-3d91c139c423",
        "hazard_name": "Extreme Cold Warning",
        "hazard_color": "#0000FF",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "8d04cdbd-cc90-4627-aa53-ee09e5dd7aef",
        "hazard_name": "Extreme Cold Watch",
        "hazard_color": "#0000FF",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "b3634dfc-dca9-4491-bffa-b0d5024d16cf",
        "hazard_name": "Extreme Fire Danger",
        "hazard_color": "#E9967A",
        "hazard_alert_type": "Immediate"
    },
    {
        "id": "561692e8-b6fe-482a-8379-b7a00da2fddc",
        "hazard_name": "Extreme Wind Warning",
        "hazard_color": "#FF9C00",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "3cb5bea0-3744-4764-8584-f8b57a4cecfb",
        "hazard_name": "Fire Warning",
        "hazard_color": "#A0522D",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "e7c1b1ac-5dfb-4c4a-9d29-6ff07bab8b20",
        "hazard_name": "Fire Weather Watch",
        "hazard_color": "#FFDEAD",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "f7274bf7-41dc-4b25-a492-834f60e6d21b",
        "hazard_name": "Flash Flood Statement",
        "hazard_color": "#8B0000",
        "hazard_alert_type": "Statement"
    },
    {
        "id": "ee6955ab-ada7-47c1-979d-8f975a813272",
        "hazard_name": "Flash Flood Warning",
        "hazard_color": "#8B0000",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "93d366a9-58df-491d-a995-e67f71e589db",
        "hazard_name": "Flash Flood Watch",
        "hazard_color": "#2E8B57",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "a3141482-f24e-4b12-9f99-320be9aa6e25",
        "hazard_name": "Flood Advisory",
        "hazard_color": "#00FF7F",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "d9996110-db8c-4801-a490-531bcf2c1e8d",
        "hazard_name": "Flood Statement",
        "hazard_color": "#00FF00",
        "hazard_alert_type": "Statement"
    },
    {
        "id": "9860977f-23ee-4d88-b9ea-ae67d9024787",
        "hazard_name": "Flood Warning",
        "hazard_color": "#00FF00",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "6fdeb77f-990a-4cf9-aeda-dee03b5176d7",
        "hazard_name": "Flood Watch",
        "hazard_color": "#2E8B57",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "590f7314-5e0c-4c71-bd43-9d603e0cbf31",
        "hazard_name": "Freeze Warning",
        "hazard_color": "#483D8B",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "ef328ada-bdbe-495a-84da-c6ae767b28a1",
        "hazard_name": "Freeze Watch",
        "hazard_color": "#00FFFF",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "a1482c56-5d7b-447b-a265-46d6ceaeebd5",
        "hazard_name": "Freezing Fog Advisory",
        "hazard_color": "#008080",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "606757f4-03db-4655-a2b3-96ddf5f510af",
        "hazard_name": "Freezing Rain Advisory",
        "hazard_color": "#DA70D6",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "41ea9d54-d651-4baa-9ef7-1999ece3668e",
        "hazard_name": "Freezing Spray Advisory",
        "hazard_color": "#00BFFF",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "bb7ff239-a4ba-44b4-8f42-f1a87cab067a",
        "hazard_name": "Frost Advisory",
        "hazard_color": "#6495ED",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "33632841-eb6d-45e5-bdf6-2a2482c741d2",
        "hazard_name": "Gale Warning",
        "hazard_color": "#DDA0DD",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "3f6a50cf-9b0a-4ba6-a4ed-1a8b2ac815e9",
        "hazard_name": "Gale Watch",
        "hazard_color": "#FFC0CB",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "dce0f167-716c-4b01-b626-7876eb4dc4b6",
        "hazard_name": "Hard Freeze Warning",
        "hazard_color": "#9400D3",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "47899519-1e1d-4f51-aa8c-b6e16ce86f11",
        "hazard_name": "Hard Freeze Watch",
        "hazard_color": "#4169E1",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "cfcbb0ae-1a0b-4676-8c42-b24a78eb6f63",
        "hazard_name": "Hazardous Materials Warning",
        "hazard_color": "#4B0082",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "f7d542f8-9d26-4e49-902c-529370704442",
        "hazard_name": "Hazardous Seas Warning",
        "hazard_color": "#D8BFD8",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "7aceb02f-5a6e-46c7-9dbc-8ba687acea44",
        "hazard_name": "Hazardous Seas Watch",
        "hazard_color": "#483D8B",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "83bbcc22-2341-40a1-a0ae-9e58d3e26286",
        "hazard_name": "Hazardous Weather Outlook",
        "hazard_color": "#EEE8AA",
        "hazard_alert_type": "Outlook"
    },
    {
        "id": "29f78ccd-690f-4144-893f-c8a35b9102a4",
        "hazard_name": "Heat Advisory",
        "hazard_color": "#FF7F50",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "9a20cbc4-9658-4192-8640-529cdc92a588",
        "hazard_name": "Heavy Freezing Spray Warning",
        "hazard_color": "#00BFFF",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "b984f9c1-a7fc-46ba-abfb-cda7d66f7ed0",
        "hazard_name": "Heavy Freezing Spray Watch",
        "hazard_color": "#BC8F8F",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "87f8d69c-425c-4cfe-8c14-45f80d495ac8",
        "hazard_name": "High Surf Advisory",
        "hazard_color": "#BA55D3",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "3ba9dc82-17d9-47b1-afd6-3a082d810c2c",
        "hazard_name": "High Surf Warning",
        "hazard_color": "#228B22",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "afc5bb54-62c3-40ac-9eb6-4576d0d2e63a",
        "hazard_name": "High Wind Warning",
        "hazard_color": "#DAA520",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "6d8119e5-6b90-4ac3-b633-b993d8539909",
        "hazard_name": "High Wind Watch",
        "hazard_color": "#B8860B",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "4657684e-8313-4c73-afe4-a13f714ac2b1",
        "hazard_name": "Hurricane Force Wind Warning",
        "hazard_color": "#CD5C5C",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "a333d433-3525-4763-ae1e-26f42f3cc28e",
        "hazard_name": "Hurricane Force Wind Watch",
        "hazard_color": "#9932CC",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "9c6036f4-f53e-4a39-b76a-5f73975bd9c0",
        "hazard_name": "Hurricane Local Statement",
        "hazard_color": "#FFE4B5",
        "hazard_alert_type": "Statement"
    },
    {
        "id": "579c4991-9aa6-4dba-937b-39a89f5df272",
        "hazard_name": "Hurricane Warning",
        "hazard_color": "#DC143C",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "f5b0dad6-dcab-4eec-8987-88ff49dbc72d",
        "hazard_name": "Hurricane Watch",
        "hazard_color": "#FF00FF",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "8cd91778-c799-4178-b593-f61e7431d767",
        "hazard_name": "Hurricane",
        "hazard_color": "#FF00FF",
        "hazard_alert_type": "Hurricane"
    },
    {
        "id": "e6d02850-de76-48a9-976f-174c191fd712",
        "hazard_name": "Hydrologic Advisory",
        "hazard_color": "#00FF7F",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "0ffee598-4d85-40e2-97bd-f0ecd2bf6d7c",
        "hazard_name": "Hydrologic Outlook",
        "hazard_color": "#90EE90",
        "hazard_alert_type": "Outlook"
    },
    {
        "id": "7940a78a-6a10-48b0-8114-73f54157330d",
        "hazard_name": "Ice Storm Warning",
        "hazard_color": "#8B008B",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "56a06fec-a2d9-4d6b-a660-160e3a179a2f",
        "hazard_name": "Lake Effect Snow Advisory",
        "hazard_color": "#48D1CC",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "28e1c2a8-abc1-4c24-b9e0-66f3a3d091fb",
        "hazard_name": "Lake Effect Snow Warning",
        "hazard_color": "#008B8B",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "1fa6dd14-fd09-40ed-8df0-0aabd2b737b5",
        "hazard_name": "Lake Effect Snow Watch",
        "hazard_color": "#87CEFA",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "0f911720-61cf-471d-a410-2f7a2efbe0cf",
        "hazard_name": "Lakeshore Flood Advisory",
        "hazard_color": "#7CFC00",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "6542e920-7629-4ccb-ae53-3e99de97021d",
        "hazard_name": "Lakeshore Flood Statement",
        "hazard_color": "#6B8E23",
        "hazard_alert_type": "Statement"
    },
    {
        "id": "faba1efd-9c6c-44d0-bb2f-e49244f26221",
        "hazard_name": "Lakeshore Flood Warning",
        "hazard_color": "#228B22",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "7aac7fc2-773a-43c1-bc5c-6959e881723d",
        "hazard_name": "Lakeshore Flood Watch",
        "hazard_color": "#66CDAA",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "3f67c21c-be31-47e5-936d-b1336455ee34",
        "hazard_name": "Lake Wind Advisory",
        "hazard_color": "#D2B48C",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "c1457bc1-5cbf-44b3-8dbf-7397f044d900",
        "hazard_name": "Law Enforcement Warning",
        "hazard_color": "#C0C0C0",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "9d40ece1-3a8c-45fb-beec-a138abd3fe38",
        "hazard_name": "Local Area Emergency",
        "hazard_color": "#C0C0C0",
        "hazard_alert_type": "Immediate"
    },
    {
        "id": "b0df1244-2cfa-4490-82d3-57d4655ab826",
        "hazard_name": "Low Water Advisory",
        "hazard_color": "#A52A2A",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "1363b07a-a2ef-4ae1-99a7-74e856444249",
        "hazard_name": "Marine Weather Statement",
        "hazard_color": "#FFDAB9",
        "hazard_alert_type": "Statement"
    },
    {
        "id": "b9f59f07-276f-4b65-b05b-b5050f104d48",
        "hazard_name": "Nuclear Power Plant Warning",
        "hazard_color": "#4B0082",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "68f8c5df-36a5-485d-a231-aba35c9c8e8a",
        "hazard_name": "Radiological Hazard Warning",
        "hazard_color": "#4B0082",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "9afe350b-4af9-440c-9916-35f107a089eb",
        "hazard_name": "Red Flag Warning",
        "hazard_color": "#FF1493",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "97f4c41d-a2bf-4014-b658-7212190fd5b7",
        "hazard_name": "Rip Current Statement",
        "hazard_color": "#40E0D0",
        "hazard_alert_type": "Statement"
    },
    {
        "id": "78cb66d9-7d7a-4175-9395-25a7aacfb074",
        "hazard_name": "Severe Thunderstorm Warning",
        "hazard_color": "#FFA500",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "9caab247-34f0-402e-8862-33ede7313045",
        "hazard_name": "Severe Thunderstorm Watch",
        "hazard_color": "#DB7093",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "ec43ce3e-9609-4ba5-9cdb-56d9ad7b38d6",
        "hazard_name": "Severe Weather Statement",
        "hazard_color": "#00FFFF",
        "hazard_alert_type": "Statement"
    },
    {
        "id": "53469362-8e43-4788-bb02-b1907668e240",
        "hazard_name": "Shelter In Place Warning",
        "hazard_color": "#FA8072",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "3e065249-7f82-4b1e-922e-63a8e19170a2",
        "hazard_name": "Short Term Forecast",
        "hazard_color": "#98FB98",
        "hazard_alert_type": "Forecast"
    },
    {
        "id": "88712478-b2d2-4f73-ab1b-33a273732072",
        "hazard_name": "Small Craft Advisory",
        "hazard_color": "#D8BFD8",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "98ca93a7-dcbb-4af9-8fe7-bdc949ba725e",
        "hazard_name": "Small Craft Advisory For HazardousSeas",
        "hazard_color": "#D8BFD8",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "63b9604b-e553-4a01-9c31-3009e965e4a0",
        "hazard_name": "Small Craft Advisory for RoughBar",
        "hazard_color": "#D8BFD8",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "91302374-504f-4819-85d0-adc16c40aec2",
        "hazard_name": "Small Craft Advisory for Winds",
        "hazard_color": "#D8BFD8",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "1e79e62e-c440-4b56-baa8-17ad127d30ee",
        "hazard_name": "Small Stream Flood Advisory",
        "hazard_color": "#00FF7F",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "6d0edfad-52c6-4257-ba8e-2732d1b23748",
        "hazard_name": "Snow Squall Warning",
        "hazard_color": "#C71585",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "5e98ceb8-61e1-4148-9e48-74cb2e10d5c8",
        "hazard_name": "Special Marine Warning",
        "hazard_color": "#FFA500",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "efc49df9-7c88-4133-b396-55ec5708180f",
        "hazard_name": "Special Weather Statement",
        "hazard_color": "#FFE4B5",
        "hazard_alert_type": "Statement"
    },
    {
        "id": "b3a4ec3b-ac7a-4f93-8077-d1aa268e3fe2",
        "hazard_name": "Storm Surge Warning",
        "hazard_color": "#B524F7",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "0b6e0dcb-6163-4b63-8572-78226fb36b49",
        "hazard_name": "Storm Surge Watch",
        "hazard_color": "#DB7FF7",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "86d0d3f2-ce06-49f6-a912-69d4de5fc08d",
        "hazard_name": "Storm Warning",
        "hazard_color": "#9400D3",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "d44aae14-7f9b-497f-8dae-cc9618e5eec2",
        "hazard_name": "Storm Watch",
        "hazard_color": "#FFE4B5",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "7f6995dd-cc88-4530-b070-547523b4c051",
        "hazard_name": "Tornado Warning",
        "hazard_color": "#FF0002",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "a018fb35-2fba-4d53-a3bc-69f7ca134152",
        "hazard_name": "Tornado Watch",
        "hazard_color": "#FFFF00",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "21ddc8fb-495c-488e-ae03-0e96c2810663",
        "hazard_name": "Tropical Depression Local Statement",
        "hazard_color": "#FFE4B5",
        "hazard_alert_type": "Statement"
    },
    {
        "id": "a498728c-2080-4db6-b53d-194b88d89f7d",
        "hazard_name": "Tropical Storm Local Statement",
        "hazard_color": "#FFE4B5",
        "hazard_alert_type": "Statement"
    },
    {
        "id": "db750b77-be44-48a0-9237-554f6ea537f4",
        "hazard_name": "Tropical Storm Warning",
        "hazard_color": "#B22222",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "91faaf2e-68bd-4e45-b676-8bf9bcbac70d",
        "hazard_name": "Tropical Storm Watch",
        "hazard_color": "#F08080",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "72f89000-b8cb-4a34-8823-50d4c3cc3b41",
        "hazard_name": "Tsunami Advisory",
        "hazard_color": "#D2691E",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "7b6fb713-cb60-4787-9f1c-db53c72ad2c9",
        "hazard_name": "Tsunami Warning",
        "hazard_color": "#FD6347",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "99b6acba-2624-468b-8365-cb72af5a5a5b",
        "hazard_name": "Tsunami Watch",
        "hazard_color": "#FF00FF",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "ea8f1524-4b2c-4f7c-a03f-2e420ba05e69",
        "hazard_name": "Typhoon Local Statement",
        "hazard_color": "#FFE4B5",
        "hazard_alert_type": "Statement"
    },
    {
        "id": "77b0810d-6963-432e-8e5e-c91e42db4024",
        "hazard_name": "Typhoon Warning",
        "hazard_color": "#DC143C",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "a01370cc-0650-4612-87ef-bc4e4936a8d8",
        "hazard_name": "Typhoon Watch",
        "hazard_color": "#FF00FF",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "5fca442b-8f62-4f18-b7bd-e0bbe979b5d5",
        "hazard_name": "Urban and Small Stream Flood Advisory",
        "hazard_color": "#00FF7F",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "a1387b86-d4f1-4ce4-9128-b982ef3b1fc9",
        "hazard_name": "Volcano Warning",
        "hazard_color": "#2F4F4F",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "89e2a7ab-ecef-4a81-aa26-e955231daafc",
        "hazard_name": "Wind Advisory",
        "hazard_color": "#D2B48C",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "8530ae12-c365-4af4-b7a2-a8d6532c2d46",
        "hazard_name": "Wind Chill Advisory",
        "hazard_color": "#AFEEEE",
        "hazard_alert_type": "Advisory"
    },
    {
        "id": "bf4398e5-e71a-4141-8e51-255516cabe96",
        "hazard_name": "Wind Chill Warning",
        "hazard_color": "#B0C4DE",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "e8b8b94a-6fd6-4226-a828-8811a0476798",
        "hazard_name": "Wind Chill Watch",
        "hazard_color": "#5F9EA0",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "f0754c7a-a264-4b25-b71a-2d759e6a9058",
        "hazard_name": "Winter Storm Warning",
        "hazard_color": "#FF69B4",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "af69ccb4-716c-41ef-a121-1583a89d8c94",
        "hazard_name": "Winter Storm Watch",
        "hazard_color": "#4682B4",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "7dae2c0a-e176-4011-a213-39572e8b87bd",
        "hazard_name": "Winter Weather Advisory",
        "hazard_color": "#7B68EE",
        "hazard_alert_type": "Advisory"
    }
]

export const eventHazardsCategory = [
    {
        "id": "af69ccb4-716c-41ef-a121-1583a89d8c94",
        "hazard_data_category": "NWS alerts",
        "hazard_color": "#808080",
        "hazard_alert_type": "Alert"
    },
    {
        "id": "14903ab1-ef1b-4807-93f6-89becd086e74",
        "hazard_data_category": "Wild Fires",
        "hazard_color": "#FF0000",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "40b70d11-8380-4876-8912-966c203c4c89",
        "hazard_data_category": "Earthquakes",
        "hazard_color": "#FFA500",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "7dae2c0a-e176-4011-a213-39572e8b87bd",
        "hazard_data_category": "Hurricane",
        "hazard_color": "#800080",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "bdae2c0a-e176-4011-a213-39572e8b87bd",
        "hazard_data_category": "Power Outage",
        "hazard_color": "#fd8d3c",
        "hazard_alert_type": "Warning"
    },
]

export const additionalWildFiresHazard = [
    {
        "id": "9afe350b-4af9-440c-9916-35f107a089eb",
        "hazard_name": "Red Flag Warning",
        "hazard_color": "#FF1493",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "e7c1b1ac-5dfb-4c4a-9d29-6ff07bab8b20",
        "hazard_name": "Fire Weather Watch",
        "hazard_color": "#FFDEAD",
        "hazard_alert_type": "Watch"
    },
    {
        "id": "3cb5bea0-3744-4764-8584-f8b57a4cecfb",
        "hazard_name": "Fire Warning",
        "hazard_color": "#A0522D",
        "hazard_alert_type": "Warning"
    },
    {
        "id": "b3634dfc-dca9-4491-bffa-b0d5024d16cf",
        "hazard_name": "Extreme Fire Danger",
        "hazard_color": "#E9967A",
        "hazard_alert_type": "Immediate"
    },
]