import { Offcanvas, Row } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { EditEntityDrawerProps } from './EntityListProps';
import { IncidentComs, useEditIncidentComs } from './hooks/incidentComsHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import FormCheckboxField from './formFields/FormCheckboxField';
import { useActualIncidentId } from './hooks/commonHooks';
import { useSelector, useDispatch } from 'react-redux';
import { ComsTypes, ComsFunctions } from '../ICS/ICSConstants';
import BranchDrawer from './branch/BranchDrawer';
import DivisionDrawer from './division/DivisionDrawer';
import { getAllDivision } from 'actions/IAPForm204Actions';
import FormSelectField from './formFields/FormSelectField';
import { selectUserGuid } from '../../slices/commonSelectors';
import { selectGroupGuid, useAppSelector } from '../../slices/commonSelectors';

type FormFields = {
  id: string;
  coms_id: string;
  type: string;
  branch?: string;
  division?: string;
  method_of_contact?: string;
  zone_group?: string;
  channel_number?: string;
  function?: string;
  channel_name?: string;
  assignment?: string;
  rx_frequency?: string;
  rx_tone_nac?: string;
  tx_frequency?: string;
  tx_tone_nac?: string;
  mode?: string;
  remarks?: string;
  special_instructions?: string;
  archived?: boolean;
  name?: string;
};

type Branch = {
  id: string;
  name: string;
};

type Division = {
  id: string;
  name: string;
};
interface RootState {
  app: {
    allBranches: Array<{ id: string; name: string }>;
    allDivisions: Array<{ id: string; name: string }>;
  };
}

const ComsEditDrawer: React.FC<EditEntityDrawerProps<IncidentComs>> = ({
  toggle,
  selectedEntity: editedEntity,
}) => {
  const form = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: { ...editedEntity },
  });
  const { handleSubmit, setValue, watch } = form;
  const reduxDispatch = useDispatch();

  const incidentId = useActualIncidentId();
  const mutation = useEditIncidentComs(editedEntity.id);
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useAppSelector(selectGroupGuid);
  const [branchDrawerOpen, setBranchDrawerOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState<Branch | null>(null);
  const [divDrawerOpen, setDivDrawerOpen] = useState(false);
  const [selectedDiv, setSelectedDiv] = useState<Division | null>(null);

  const branches = useSelector((state: RootState) => state.app.allBranches);
  const divisions = useSelector((state: RootState) => state.app.allDivisions);
  const watchedFields = watch(['type']);

  useEffect(() => {
    if (branches.length > 0 && editedEntity.branch) {
      const branch = branches.find((b: Branch) => b.id === editedEntity.branch);
      if (branch) {
        setSelectedBranch(branch);
        setValue('branch', branch.name);
        reduxDispatch(getAllDivision(branch.id));
      }
    }
  }, [branches, editedEntity.branch, reduxDispatch, setValue]);

  useEffect(() => {
    if (divisions.length > 0 && editedEntity.division) {
      const division = divisions.find((d: Division) => d.id === editedEntity.division);
      if (division) {
        setSelectedDiv(division);
        setValue('division', division.name);
      }
    }
  }, [divisions, editedEntity.division, setValue]);

  const handleBranchSelect = (branch: Branch) => {
    setSelectedBranch(branch);
    setValue('branch', branch.name);
    setBranchDrawerOpen(false);
    reduxDispatch(getAllDivision(branch.id));
  };

  const openBranchDrawer = () => {
    setBranchDrawerOpen(true);
  };

  const closeBranchDrawer = () => {
    setBranchDrawerOpen(false);
  };

  const handleDivSelect = (division: Division) => {
    setSelectedDiv(division);
    setValue('division', division.name);
    setDivDrawerOpen(false);
  };

  const openDivDrawer = () => {
    setDivDrawerOpen(true);
  };

  const closeDivDrawer = () => {
    setDivDrawerOpen(false);
  };

  const submitComsEdit = async (formData: FormFields) => {
    const payload = {
      ...formData,
      archived: formData.archived ?? false,
      incident_id: incidentId,
      coms_id: editedEntity.coms_id,
      user_guid,
      group_guid,
      branch: selectedBranch?.id,
      division: selectedDiv?.id,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  const toggleActive = async () => {
    await mutation.mutateAsync({
      ...editedEntity,
      archived: !editedEntity.archived,
    });
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Edit Communication">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(submitComsEdit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormSelectField
                title="Type"
                fieldName="type"
                rules={{
                  required: { value: true, message: 'This field is required' },
                }}
                options={ComsTypes}
                variant="half"
              />
            </Row>
            <Row>
              <div className="mb-3">
                <label className="form-label">Branch</label>
                <div className="d-flex">
                  <input
                    className="form-control"
                    value={selectedBranch?.name || ''}
                    readOnly
                    placeholder="Select Branch"
                    onClick={openBranchDrawer}
                  />
                  <StylishNewButton
                    type="button"
                    className="button--secondary ms-2"
                    onClick={openBranchDrawer}
                  >
                    Select Branch
                  </StylishNewButton>
                </div>
              </div>
            </Row>
            <Row>
              <div className="mb-3">
                <label className="form-label">Division</label>
                <div className="d-flex">
                  <input
                    className="form-control"
                    value={selectedDiv?.name || ''}
                    readOnly
                    placeholder="Select Division"
                    onClick={openDivDrawer}
                  />
                  <StylishNewButton
                    type="button"
                    className="button--secondary ms-2"
                    onClick={openDivDrawer}
                  >
                    Select Division
                  </StylishNewButton>
                </div>
              </div>
            </Row>
            {watchedFields.find((f) => f === 'Radio') && (
              <>
                <Row>
                  <FormTextField
                    title="Zone Group"
                    fieldName="zone_group"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextField
                    title="Channel Number"
                    fieldName="channel_number"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormSelectField
                    title="Function"
                    fieldName="function"
                    options={ComsFunctions}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextField
                    title="Channel Name"
                    fieldName="channel_name"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextField
                    title="RX Frequency"
                    fieldName="rx_frequency"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextField
                    title="RX Tone NAC"
                    fieldName="rx_tone_nac"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextField
                    title="TX Frequency"
                    fieldName="tx_frequency"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextField
                    title="TX Tone NAC"
                    fieldName="tx_tone_nac"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextField
                    title="Mode (A, D, or M)"
                    fieldName="mode"
                    rules={{ maxLength: { value: 50, message: 'This maximum length is 50 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextAreaField
                    title="Remarks"
                    fieldName="remarks"
                    rules={{ maxLength: { value: 300, message: 'This maximum length is 300 characters' } }}
                    variant="full"
                  />
                </Row>
              </>
            )}
            {!watchedFields.find((f) => f === 'Radio') && (
              <>
                <Row>
                  <FormTextField
                    title="Name of Contact"
                    fieldName="name"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextField
                    title="Method of Contact"
                    fieldName="method_of_contact"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
              </>
            )}
            <Row>
              <FormCheckboxField
                title="Archived"
                fieldName="archived"
              />
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={toggleActive}
              >
                {(!editedEntity.archived&&"Deactivate"||"Activate")}
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>

      {!!branchDrawerOpen && (
        <BranchDrawer
          show={branchDrawerOpen}
          onClose={closeBranchDrawer}
          selectedBranch={selectedBranch}
          updateSelectedBranch={handleBranchSelect}
          parent="Coms"
          rowSelectable={true}
        />
      )}

      {!!divDrawerOpen && (
        <DivisionDrawer
          show={divDrawerOpen}
          onClose={closeDivDrawer}
          selectedDivision={selectedDiv}
          selectedBranch={selectedBranch}
          updateSelectedDivision={handleDivSelect}
          parent="Coms"
          rowSelectable={true}
        />
      )}
    </DrawerWrapper>
  );
};

export default ComsEditDrawer;
