import React, { useEffect, useRef, useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useDispatch, useSelector } from 'react-redux';
import { exportFormToPDF } from 'actions/formActions';
import { FormConfigs } from 'components/FormBuilder/FormTemplates/FormConstants/FormConfigs';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { isBrowser } from 'react-device-detect';
import { Config207 } from 'components/FormBuilder/FormTemplates/FormConfig/207Config';
import moment from 'moment';
import { Modal } from 'react-bootstrap';

export default function OrganizationChart({ incident_members }) {
  const reduxDispatch = useDispatch();

  const [incidentCommander, setIncidentCommander] = useState([]);
  const [qualifiedIndividual, setQualifiedIndividual] = useState([]);
  const [legalOfficer, setLegalOfficer] = useState([]);
  const [publicInformationOfficer, setPublicInformationOfficer] = useState([]);
  const [safetyOfficer, setSafetyOfficer] = useState([]);
  const [liaisonOfficer, setLiaisonOfficer] = useState([]);
  const [medicalUnitLeader, setMedicalUnitLeader] = useState([]);
  const [operationsSectionChief, setOperationsSectionChief] = useState([]);
  const [planningSectionChief, setPlanningSectionChief] = useState([]);
  const [logisticsSectionChief, setLogisticsSectionChief] = useState([]);
  const [financeSectionChief, setFinanceSectionChief] = useState([]);
  const [communicationsUnitLeader, setCommunicationsUnitLeader] = useState([]);
  const [serviceBranch, setServiceBranch] = useState([]);
  const [showPDFPreview, setShowPDFPreview] = useState(false);
  const [showPDFPreviewNewTab, setShowPDFPreviewNewTab] = useState(false);
  const [showPDFPreviewMobile, setShowPDFPreviewMobile] = useState(false);
  const [showPDFPreviewTeams, setShowPDFPreviewTeams] = useState(false);
  const [formData207, setFormData207] = useState(null);
  const [formRecordData, setFormRecordData] = useState();
  const [form, setForm] = useState(null);
  const [formConfig, setFormConfig] = useState({});
  const [formState, setFormState] = useState(Config207);

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const exportedFormsToPDF = useSelector((state) => {
    return state.app.exportedFormsToPDF;
  });
  const user = useSelector((state) => {
    return state.app.user;
  });
  const reduxSignatures = useSelector((state) => {
    return state.app.signatures;
  });

  const formId = useRef(form?.id || generateUUID());
  const collectionId = useRef(form?.collection_id || generateUUID());

  const SeatBox = ({ className, members, seatName }) => {
    return (
      <div className={`chartBox${className ? ' ' + className : ''}`}>
        {seatName && <div className="chartBox__role">{seatName}</div>}
        {members.map((member) => {
          return (
            <div key={member.id} className="chartBox__person">
              {member.profile_settings.userName || member.email_address}
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    const incidentCommanders = incident_members.filter(
      (member) => member.incident_seat === 'Incident Commander'
    );
    setIncidentCommander(incidentCommanders);
    const qualifiedIndividuals = incident_members.filter(
      (member) => member.incident_seat === 'Qualified Individual'
    );
    setQualifiedIndividual(qualifiedIndividuals);
    const legalOfficers = incident_members.filter(
      (member) => member.incident_seat === 'Legal Officer'
    );
    setLegalOfficer(legalOfficers);
    const publicInformationOfficers = incident_members.filter(
      (member) => member.incident_seat === 'Public Information Officer'
    );
    setPublicInformationOfficer(publicInformationOfficers);
    const safetyOfficers = incident_members.filter(
      (member) => member.incident_seat === 'Safety Officer'
    );
    setSafetyOfficer(safetyOfficers);
    const liaisonOfficers = incident_members.filter(
      (member) => member.incident_seat === 'Liaison officer'
    );
    setLiaisonOfficer(liaisonOfficers);
    const medicalUnitLeaders = incident_members.filter(
      (member) => member.incident_seat === 'Medical Unit Leader'
    );
    setMedicalUnitLeader(medicalUnitLeaders);
    const operationsSectionChiefs = incident_members.filter(
      (member) => member.incident_seat === 'Operations Section Chief'
    );
    setOperationsSectionChief(operationsSectionChiefs);
    const planningSectionChiefs = incident_members.filter(
      (member) => member.incident_seat === 'Planning Section Chief'
    );
    setPlanningSectionChief(planningSectionChiefs);
    const logisticsSectionChiefs = incident_members.filter(
      (member) => member.incident_seat === 'Logistics Section Chief'
    );
    setLogisticsSectionChief(logisticsSectionChiefs);
    const financeSectionChiefs = incident_members.filter(
      (member) => member.incident_seat === 'Finance Section Chief'
    );
    setFinanceSectionChief(financeSectionChiefs);
    const communicationsUnitLeaders = incident_members.filter(
      (member) => member.incident_seat === 'Communications Unit Leader'
    );
    setCommunicationsUnitLeader(communicationsUnitLeaders);
    const serviceBranchs = incident_members.filter(
      (member) => member.incident_seat === 'Services Branch'
    );
    setServiceBranch(serviceBranchs);

    const orgChartValues = [
      {
        key: 'Incident Commanders_2',
        type: 'List',
        value: incidentCommanders.map(
          (member) => member.profile_settings.userName || member.email_address
        ),
      },
      {
        key: 'Liaison Officer_3',
        type: 'List',
        value: liaisonOfficers.map(
          (member) => member.profile_settings.userName || member.email_address
        ),
      },

      {
        key: 'Safety Officer_4',
        type: 'List',
        value: safetyOfficers.map(
          (member) => member.profile_settings.userName || member.email_address
        ),
      },
      {
        key: 'Public Information Officer_2',
        type: 'List',
        value: publicInformationOfficers.map(
          (member) => member.profile_settings.userName || member.email_address
        ),
      },
      {
        key: 'Planning Section Chief_3',
        type: 'List',
        value: planningSectionChiefs.map(
          (member) => member.profile_settings.userName || member.email_address
        ),
      },
      {
        key: 'Operations Section ChiefRow1',
        type: 'List',
        value: operationsSectionChiefs.map(
          (member) => member.profile_settings.userName || member.email_address
        ),
      },
      {
        key: 'FinanceAdmin Section Chief',
        type: 'List',
        value: financeSectionChiefs.map(
          (member) => member.profile_settings.userName || member.email_address
        ),
      },
      {
        key: 'Logistics Section Chief_2',
        type: 'List',
        value: logisticsSectionChiefs.map(
          (member) => member.profile_settings.userName || member.email_address
        ),
      },
      {
        key: 'Medical Unit Ldr',
        type: 'List',
        values: medicalUnitLeader.map(
          (member) => member.profile_settings.userName || member.email_address
        ),
      },
      {
        key: 'Comms Unit Ldr',
        type: 'List',
        values: communicationsUnitLeader.map(
          (member) => member.profile_settings.userName || member.email_address
        ),
      },
      {
        key: 'Service Branch Dir',
        type: 'List',
        values: serviceBranchs.map(
          (member) => member.profile_settings.userName || member.email_address
        ),
      },
    ];
    updateFormState('Organization Chart', orgChartValues);

    setFormData207(initializeFormCollection('ICS 207'));
  }, [incident_members]);

  useEffect(() => {
    if (formData207 && formData207?.collection_id) {
      setFormConfig(
        FormConfigs.find((fc) => fc.formname === formData207?.formname)
      );

      setForm({
        ...formData207?.collection_form_records[
          formData207?.collection_form_records.length - 1
        ],
        record_state:
          formData207?.collection_form_records[
            formData207?.collection_form_records.length - 1
          ].record_state ||
          (formConfig?.form_states?.length
            ? formConfig?.form_states[0]?.state_name
            : ''),
      });
    }
  }, [formData207]);

  useEffect(() => {
    if (!!reduxCurrentIncident) {
      updateFormState('1 Incident Name_11', reduxCurrentIncident?.name);
      reduxCurrentIncident?.operational_period_start_date_time &&
        updateFormState(
          'Date From',
          moment(
            reduxCurrentIncident?.operational_period_start_date_time
          ).format('DD/MM/YYYY')
        );
      reduxCurrentIncident?.operational_period_start_date_time &&
        updateFormState(
          'Time From',
          moment(
            reduxCurrentIncident?.operational_period_start_date_time
          ).format('HH:mm')
        );

      reduxCurrentIncident?.operational_period_start_date_time &&
        reduxCurrentIncident?.operational_period_interval &&
        updateFormState(
          'Date To',
          moment(reduxCurrentIncident?.operational_period_start_date_time)
            .add(reduxCurrentIncident?.operational_period_interval, 'hours')
            .format('DD/MM/YYYY')
        );
      reduxCurrentIncident?.operational_period_start_date_time &&
        reduxCurrentIncident?.operational_period_interval &&
        updateFormState(
          'Time To',
          moment(reduxCurrentIncident?.operational_period_start_date_time)
            .add(reduxCurrentIncident?.operational_period_interval, 'hours')
            .format('HH:mm')
        );

      if (!!reduxSignatures && !!reduxSignatures.length > 0) {
        updateFormState('Signature_13', reduxSignatures[0].signature_image);
      }

      if (!!user) {
        updateFormState(
          '4 Prepared by Name_2',
          user.user_name || user.email_address
        );
      }

      updateFormState(
        'PositionTitle_9',
        incident_members?.filter((item) => item.user_guid === user.user_guid)[0]
          ?.incident_seat || ''
      );

      updateFormState('DateTime_11', moment().format('DD/MM/YYYY HH:mm:ss'));
    }
  }, [reduxCurrentIncident, reduxSignatures, user, incident_members]);

  const b64toBlob = (b64Data) => {
    b64Data = b64Data.replace(/^[\w\d;:\/]+base64\,/g, '');
    const byteArray = Uint8Array.from(
      atob(b64Data)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    return new Blob([byteArray], { type: 'application/pdf' });
  };

  // useEffect(() => {
  //   if (
  //     showPDFPreviewMobile &&
  //     !!exportedFormsToPDF &&
  //     !!exportedFormsToPDF.find((f) => f.id === { ...formId }.current)
  //   ) {
  //     const pdfContent = URL.createObjectURL(
  //       b64toBlob(
  //         exportedFormsToPDF.find((f) => f.id === { ...formId }.current)
  //           .PDFData,
  //         'application/pdf'
  //       )
  //     );
  //     window.open(pdfContent, '_blank');
  //   }
  // }, [showPDFPreviewMobile, exportedFormsToPDF]);

  const updateFormState = (key, value) => {
    const index = formState.field.findIndex((field) => field.key === key);
    if (index !== -1) {
      let obj = { ...formState.field[index] };
      obj = { ...obj, value: value };
      formState.field[index] = obj;
      setFormState(formState);
      setFormRecordData(formState);
    }
  };

  const createForm = () => {
    let editedForm = { ...form };
    editedForm['id'] = { ...formId }.current;
    editedForm['formname'] = formData207.formname;
    editedForm['created_by_user'] = user.user_guid;
    editedForm['incident_id'] = reduxCurrentIncident.id;
    editedForm['group_guid'] = reduxCurrentlySelectedGroup.group_guid;
    editedForm['record_data'] = formRecordData;
    editedForm['collection_id'] = { ...collectionId }.current;
    return editedForm;
  };

  const handleReloadPDFPreview = (force) => {
    if (showPDFPreview || force) {
      reduxDispatch(
        exportFormToPDF(
          createForm(),
          FormConfigs.find((fc) => fc.formname === formData207.formname)
        )
      );
    }
  };

  const initializeFormCollection = (formname) => {
    const newCollectionId = { ...collectionId }.current || generateUUID();
    return {
      collection_id: newCollectionId,
      formname: formname,
      incident_id: reduxCurrentIncident?.id,
      collection_form_records: [
        {
          collection_id: newCollectionId,
          formname: formname,
          incident_id: reduxCurrentIncident?.id,
          id: generateUUID(),
          record_data: {},
        },
      ],
    };
  };

  useEffect(() => {
    if (
      ((sessionStorage['isUsingTeams'] === 'true' && showPDFPreviewNewTab) ||
        showPDFPreviewMobile) &&
      !!exportedFormsToPDF &&
      exportedFormsToPDF?.length
    ) {
      const pdfContent = URL.createObjectURL(
        b64toBlob(
          exportedFormsToPDF.find((f) => f.id === { ...formId }.current)
            ?.PDFData,
          'application/pdf'
        )
      );
      setShowPDFPreviewNewTab(false);
      window.open(pdfContent, '_blank');
    }
  }, [showPDFPreviewMobile, exportedFormsToPDF, showPDFPreviewNewTab]);

  return (
    <>
      {!!incidentCommander?.length ? (
        <>
          <div className="d-md-flex align-items-center mb-3">
            <h4>Organization Chart</h4>
            <div className="ms-auto text-md-end">
              <StylishNewButton
                className="button--secondary ms-auto mt-2 mt-md-0 w-100 w-md-auto"
                onClick={() => {
                  if (!showPDFPreview) {
                    handleReloadPDFPreview(true);
                  }
                  if (isBrowser) {
                    setShowPDFPreview(!showPDFPreview);
                  } else {
                    setShowPDFPreviewMobile(true);
                  }
                  if (sessionStorage['isUsingTeams'] === 'true') {
                    setShowPDFPreviewNewTab(true);
                    setShowPDFPreviewTeams(true);
                  }
                }}
                // onClick={() => setIsNewMemberDialogOpen(true)}
                // disabled={
                //   !reduxValidateRBACPermissionForActionResult[
                //     'Add Member to Incident'
                //   ]
                // }
              >
                Print Organization Chart
              </StylishNewButton>
              {/* {!reduxValidateRBACPermissionForActionResult[
                'Add Member to Incident'
              ] && (
                <span className="form-text form-error">
                  You don't have permission for this action
                </span>
              )} */}
            </div>
          </div>
          <div className="form-block chart">
            {!!incidentCommander && !!incidentCommander.length > 0 && (
              <Tree
                label={
                  <SeatBox
                    seatName={'Incident Commander'}
                    members={incidentCommander}
                    lineWidth={'2px'}
                    lineColor={'white'}
                    lineBorderRadius={'10px'}
                  />
                }
              >
                {!!publicInformationOfficer &&
                  !!publicInformationOfficer.length > 0 && (
                    <TreeNode
                      label={
                        <SeatBox
                          seatName={'Public Information Officer'}
                          members={publicInformationOfficer}
                        />
                      }
                    ></TreeNode>
                  )}
                {!!legalOfficer && !!legalOfficer.length > 0 && (
                  <TreeNode
                    label={
                      <SeatBox
                        seatName={'Legal Officer'}
                        members={legalOfficer}
                      />
                    }
                  ></TreeNode>
                )}
                {!!safetyOfficer && !!safetyOfficer.length > 0 && (
                  <TreeNode
                    label={
                      <SeatBox
                        seatName={'Safety Officer'}
                        members={safetyOfficer}
                      />
                    }
                  ></TreeNode>
                )}
                {!!liaisonOfficer && !!liaisonOfficer.length > 0 && (
                  <TreeNode
                    label={
                      <SeatBox
                        seatName={'Liaison Officer'}
                        members={liaisonOfficer}
                      />
                    }
                  ></TreeNode>
                )}
                {!!operationsSectionChief &&
                  !!operationsSectionChief.length > 0 && (
                    <TreeNode
                      label={
                        <SeatBox
                          seatName={'Operations Section Chief'}
                          members={operationsSectionChief}
                        />
                      }
                    ></TreeNode>
                  )}
                {!!planningSectionChief &&
                  !!planningSectionChief.length > 0 && (
                    <TreeNode
                      label={
                        <SeatBox
                          seatName={'Planning Section Chief'}
                          members={planningSectionChief}
                        />
                      }
                    ></TreeNode>
                  )}
                {!!logisticsSectionChief &&
                !!logisticsSectionChief.length > 0 ? (
                  <TreeNode
                    label={
                      <SeatBox
                        seatName={'Logistics Section Chief'}
                        members={logisticsSectionChief}
                      />
                    }
                  >
                    {!!serviceBranch && !!serviceBranch.length > 0 && (
                      <TreeNode
                        label={
                          <SeatBox
                            seatName={'Service Branch'}
                            members={serviceBranch}
                          />
                        }
                      ></TreeNode>
                    )}
                    {!!communicationsUnitLeader &&
                      !!communicationsUnitLeader.length > 0 && (
                        <TreeNode
                          label={
                            <SeatBox
                              seatName={'Communications Unit Leader'}
                              members={communicationsUnitLeader}
                            />
                          }
                        ></TreeNode>
                      )}
                    {!!medicalUnitLeader && !!medicalUnitLeader.length > 0 && (
                      <TreeNode
                        label={
                          <SeatBox
                            seatName={'Medical Unit Leader'}
                            members={medicalUnitLeader}
                          />
                        }
                      ></TreeNode>
                    )}
                  </TreeNode>
                ) : (
                  <>
                    {!!serviceBranch && !!serviceBranch.length > 0 && (
                      <TreeNode
                        label={
                          <SeatBox
                            seatName={'Service Branch'}
                            members={serviceBranch}
                          />
                        }
                      ></TreeNode>
                    )}
                    {!!communicationsUnitLeader &&
                      !!communicationsUnitLeader.length > 0 && (
                        <TreeNode
                          label={
                            <SeatBox
                              seatName={'Communications Unit Leader'}
                              members={communicationsUnitLeader}
                            />
                          }
                        ></TreeNode>
                      )}
                    {!!medicalUnitLeader && !!medicalUnitLeader.length > 0 && (
                      <TreeNode
                        label={
                          <SeatBox
                            seatName={'Medical Unit Leader'}
                            members={medicalUnitLeader}
                          />
                        }
                      ></TreeNode>
                    )}
                  </>
                )}
                {!!financeSectionChief && !!financeSectionChief.length > 0 && (
                  <TreeNode
                    label={
                      <SeatBox
                        seatName={'Finance Section Chief'}
                        members={financeSectionChief}
                      />
                    }
                  ></TreeNode>
                )}
              </Tree>
            )}
            {!!incidentCommander &&
              !!incidentCommander.length &&
              qualifiedIndividual &&
              qualifiedIndividual.length > 0 && (
                <SeatBox
                  className="qualifiedIndividual"
                  members={qualifiedIndividual}
                  seatName="Qualified Individual"
                />
              )}
          </div>
        </>
      ) : (
        <p>
          You must select an Incident Commander in order to see the organization
          chart.
        </p>
      )}
      {isBrowser ? (
        <>
          {showPDFPreview && (
            // <div className="col-md-6">
            //   <div className="FormCollectionInterface-form-PDFPreview-Reload"></div>
            //   {!!exportedFormsToPDF &&
            //     !!exportedFormsToPDF.find(
            //       (f) => f.id === { ...formId }.current
            //     ) && (
            //       <div className="h-100">
            //         <iframe
            //           style={{ border: 'none' }}
            //           src={`data:application/pdf;base64,${
            //             exportedFormsToPDF.find(
            //               (f) => f.id === { ...formId }.current
            //             ).PDFData
            //           }`}
            //           type="application/pdf"
            //           className="FormCollectionInterface-form-PDFPreview-object"
            //           width="100%"
            //           height="100%"
            //         />
            //       </div>
            //     )}
            // </div>
            <Modal
              show={showPDFPreview}
              onHide={() => setShowPDFPreview(false)}
              fullscreen={true}
            >
              <Modal.Header closeButton closeVariant="white">
                <Modal.Title>
                  {formConfig.formname === 'ICS 207' ? (
                    <>Organization Chart: ICS 207</>
                  ) : null}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="pt-2">
                {!!exportedFormsToPDF &&
                  sessionStorage['isUsingTeams'] !== 'true' &&
                  !!exportedFormsToPDF.find(
                    (f) => f.id === { ...formId }.current
                  ) && (
                    <div className="h-100">
                      <iframe
                        style={{ border: 'none' }}
                        src={`data:application/pdf;base64,${
                          exportedFormsToPDF.find(
                            (f) => f.id === { ...formId }.current
                          ).PDFData
                        }`}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                      />
                    </div>
                  )}
              </Modal.Body>
            </Modal>
          )}
        </>
      ) : (
        <Modal
          show={showPDFPreviewMobile}
          onHide={() => setShowPDFPreviewMobile(false)}
          fullscreen={true}
        >
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>
              {formConfig.formname === 'ICS 207' ? (
                <>Organization Chart: ICS 207</>
              ) : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-2">
            {!!exportedFormsToPDF &&
              sessionStorage['isUsingTeams'] !== 'true' &&
              !!exportedFormsToPDF.find(
                (f) => f.id === { ...formId }.current
              ) && (
                <div className="h-100">
                  <iframe
                    style={{ border: 'none' }}
                    src={`data:application/pdf;base64,${
                      exportedFormsToPDF.find(
                        (f) => f.id === { ...formId }.current
                      ).PDFData
                    }`}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                  />
                </div>
              )}
          </Modal.Body>
        </Modal>
      )}

      {showPDFPreviewTeams && sessionStorage['isUsingTeams'] === 'true' ? (
        <Modal
          show={showPDFPreviewTeams}
          onHide={() => setShowPDFPreviewTeams(false)}
          fullscreen={true}
        >
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>
              {formConfig.formname === 'ICS 201' ? (
                <>Incident Briefing: ICS 201</>
              ) : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-2">
            {!!exportedFormsToPDF &&
              sessionStorage['isUsingTeams'] === 'true' &&
              !!exportedFormsToPDF.find(
                (f) => f.id === { ...formId }.current
              ) && (
                <div className="h-100">
                  <a
                    href={URL.createObjectURL(
                      b64toBlob(
                        exportedFormsToPDF.find(
                          (f) => f.id === { ...formId }.current
                        )?.PDFData,
                        'image/png'
                      )
                    )}
                    target="_blank"
                  >
                    View PDF in Browser
                  </a>
                </div>
              )}
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
}
