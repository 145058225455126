import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import {
  selectGroupGuid,
  selectUserGuid,
} from '../../../slices/commonSelectors';
import { toastConfig } from '../../../assets/data/config';
import { toast } from 'react-toastify';
import {keys as incidentObjectiveKeys} from './incidentObjectiveHooks';

export type Objective = {
  id: UUID;
  group_guid: UUID;
  name: string;
  description: string;
  archived: boolean;
  strategies: UUID[];
};

export const keys = {
  base: 'objectives',
  get all() {
    return [keys.base];
  },
  get: function (group_guid: UUID, user_guid: UUID) {
    return [keys.base, group_guid, user_guid];
  },
} as const;

export const useObjectives = () => {
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.get(group_guid, user_guid),
    queryFn: async () => {
      const resp = await axios.get<Objective[]>(
        `${config.apiGateway.ics_objectives}/${group_guid}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
  });
};

export type CreateObjectiveReqData = {
  name: string;
  description: string;
  incident_id?: UUID;

  // group_guid: UUID;
  // user_guid: UUID;
};

export const useCreateObjective = () => {
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: CreateObjectiveReqData) => {
      return await axios.post<CreateObjectiveReqData, Objective>(
        `${config.apiGateway.ics_objectives}`,
        {
          ...data,
          group_guid,
          user_guid,
        }
      );
    },
    onSuccess: (_, reqObj) => {
      queryClient.invalidateQueries({
        queryKey: keys.get(group_guid, user_guid),
      });
      if (reqObj.incident_id) {
        queryClient.invalidateQueries({
          queryKey: incidentObjectiveKeys.all,
        });
      }
    },
    onError: (error) => {
      toast.error(error.message, toastConfig as any);
    },
  });
};

// edit objective?
