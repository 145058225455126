import './MapOilSpillPopupLayer.css'

export const PopupContent = () => (
  <div>
    <h5 className="mb-0">Event Type: Oil Spill</h5>
    <hr className="my-1" />
    <div className="oil-spill-info">
      <span><b>Oil Type:</b> Medium Crude</span>
      <span><b>Potential Amount:</b> 100,000 gallons</span>
      <span><b>GNOME modeled release timeframe:</b> 3 hours</span>
      <span><b>GNOME modeled amount:</b> 100,000 gallons </span>
      <span><b>GNOME modeled time to landfall:</b> 30-36 hours</span>
      <span><b>Water temperature:</b> 75°F</span>
      <span><b>Surface Winds:</b> Southeast at 5-10 mph</span>
    </div>
  </div>
);
