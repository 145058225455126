import React, { PropsWithChildren } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../SharedIcon/SharedIcon';

type DrawerProps = {
  toggle: () => void;
  title: string;
  fullscreen?: boolean; // New fullscreen prop
};

const DrawerWrapper: React.FC<PropsWithChildren<DrawerProps>> = ({
  children,
  toggle,
  title,
  fullscreen = true, // Default to true
}) => {
  return (
    <Offcanvas
      show={true}
      placement="end"
      onHide={fullscreen ? toggle : undefined} // Disable closing on backdrop click if not fullscreen
      backdrop={fullscreen} // Enable backdrop only in fullscreen mode
      scroll={!fullscreen} // Allow scrolling the backdrop content if not fullscreen
      className={`bg-neutral-1000 overflow-auto ${fullscreen ? '' : 'drawer-not-fullscreen'}`} // Optional class for custom styling
    >
      <DrawerHeader title={title} toggle={toggle} />
      {children}
    </Offcanvas>
  );
};

export default DrawerWrapper;

export const DrawerHeader = ({
  title,
  toggle,
}: {
  title: string;
  toggle: () => void;
}) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center bg-bgdark"
      style={{
        padding: '1rem 1.25rem',
        borderBottom: '1px solid var(--border-neutral)',
      }}
    >
      <div>{title}</div>
      <StylishNewButton className={'button button--link p-1'} onClick={toggle}>
        <SharedIcon iconName="close" classes="text-white" />
      </StylishNewButton>
    </div>
  );
};

export const DrawerFooter: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className="d-flex justify-content-end align-items-center bg-bgdark"
      style={{
        padding: '1rem 1.25rem',
        borderTop: '1px solid var(--border-neutral)',
      }}
    >
      {children}
    </div>
  );
};
