import React from 'react';
import generateUUID from 'utils/sharedUtils/generateUUID';
import './NotificationRecipients.css';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export default function NotificationRecipients({
  recipients = [],
  onRecipientsChange,
  getValidationMessagesForItem,
  type,
}) {
  function addListItemClicked() {
    onRecipientsChange([
      ...recipients,
      {
        id: generateUUID(),
        content: '',
        type,
      },
    ]);
  }

  function listItemContentChanged(recipient, newContent) {
    onRecipientsChange(
      recipients.map((r) => {
        if (r.id === recipient.id) {
          return {
            ...r,
            content: newContent,
            type,
          };
        } else {
          return r;
        }
      })
    );
  }

  function removeListItemClicked(recipient) {
    onRecipientsChange(recipients.filter((r) => r.id !== recipient.id));
  }

  return (
    <>
      <>
        {recipients.map((recipient, index) => {
          return (
            <>
              <div
                className={`d-md-flex justify-content-center ${
                  index !== 0 ? 'mt-3' : ''
                }`}
                key={'NotificationsRecipients-recipient-' + recipient.id}
              >
                <StylishNewInput
                  className={'w-100'}
                  value={recipient.content}
                  onChange={(e) =>
                    listItemContentChanged(recipient, e.target.value)
                  }
                />
                <div className="button-group justify-content-md-end ms-md-3 mt-3 mt-md-0">
                  <StylishNewButton
                    type="button"
                    customButton
                    className="button--icon primary"
                    onClick={() => addListItemClicked()}
                  >
                    <SharedIcon iconName="add" bold />
                  </StylishNewButton>
                  {index === recipients.length - 1 && (
                    <StylishNewButton
                      type="button"
                      customButton
                      className="button--icon primary"
                      onClick={() => removeListItemClicked(recipient)}
                    >
                      <SharedIcon iconName="delete" classes="dropdown-icon" />
                    </StylishNewButton>
                  )}
                </div>
              </div>

              {getValidationMessagesForItem(recipient.content) &&
                getValidationMessagesForItem(recipient.content).length > 0 && (
                  <span className="form-text form-error">
                    {getValidationMessagesForItem(recipient.content)}
                  </span>
                )}
            </>
          );
        })}
      </>
      {!recipients ||
        (recipients.length === 0 && (
          <StylishNewButton
            type="button"
            customButton
            className="button--icon primary"
            onClick={() => addListItemClicked()}
          >
            <SharedIcon iconName="add" bold />
          </StylishNewButton>
        ))}
    </>
  );
}
