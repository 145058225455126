import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import {
  fetchIcsComs
} from 'slices/ics_comsSlice';

import {
  getUserGuid,
  getGroupGuid
} from 'slices/sliceHelpers';

// Slice
const ics_incident_comsSlice = createSlice({
  name: 'ics_incident_coms',
  initialState: {
    ics_incident_coms: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    fetchIcsIncidentComsSuccess: (state, action) => {
      state.ics_incident_coms = action.payload;
      state.status = 'succeeded';
    },
    createIcsIncidentComsSuccess: (state, action) => {
      state.status = 'succeeded';
    },
    updateIcsIncidentComsSuccess: (state, action) => {
      state.status = 'succeeded';
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  },
});

export const {
  fetchIcsIncidentComsSuccess,
  createIcsIncidentComsSuccess,
  updateIcsIncidentComsSuccess,
  setError
} = ics_incident_comsSlice.actions;

export default ics_incident_comsSlice.reducer;

export const createIcsIncidentComs = (incidentId, data) => async (dispatch, getState) => {
  const user_guid = getUserGuid(getState);
  const group_guid = getGroupGuid(getState);
  try {
    const response = await axios.post(`${config.apiGateway.ics_incident_coms}/${incidentId}`, { ...data, user_guid });
    dispatch(fetchIcsComs(group_guid));
    dispatch(fetchIcsIncidentComs(incidentId));
    dispatch(createIcsIncidentComsSuccess(response.data));
  } catch (error) {
    toast.error(error.message, toastConfig);
    dispatch(setError(error.message));
  }
};

export const fetchIcsIncidentComs = (incidentId) => async (dispatch, getState) => {
  const user_guid = getUserGuid(getState);
  try {
    const response = await axios.get(`${config.apiGateway.ics_incident_coms}/${incidentId}`, {
      headers: { 'user_guid': user_guid }
    });
    dispatch(fetchIcsIncidentComsSuccess(response.data));
  } catch (error) {
    toast.error(error.message, toastConfig);
    dispatch(setError(error.message));
  }
};

export const updateIcsIncidentComs = (incidentId, id, data) => async (dispatch, getState) => {
  const user_guid = getUserGuid(getState);
  const group_guid = getGroupGuid(getState);
  try {
    const response = await axios.put(`${config.apiGateway.ics_incident_coms}/${incidentId}/${id}`, { ...data, user_guid });
    dispatch(fetchIcsComs(group_guid));
    dispatch(fetchIcsIncidentComs(incidentId));
    dispatch(updateIcsIncidentComsSuccess(response.data));
  } catch (error) {
    toast.error(error.message, toastConfig);
    dispatch(setError(error.message));
  }
};
