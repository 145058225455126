interface Tab {
  id: number;
  label: string;
  count?: number;
}

interface Props {
  tabs: Tab[];
  activeTabId: number;
  onTabclick: (id: number) => void;
}

export const PratusTab = ({ tabs, activeTabId, onTabclick }: Props) => {
  const getTabClasses = (tab: Tab) => {
    let classes = '';

    if (tab.id === activeTabId) {
      classes = 'active';
    }

    return classes;
  };

  return (
    <div className="pratus-tabs-container">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => onTabclick(tab.id)}
          className={`tab ` + getTabClasses(tab)}
        >
          {tab.label} {tab?.count ? `(${tab.count})` : null}
        </button>
      ))}
    </div>
  );
};
