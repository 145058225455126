import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OSINTPost from './OSINTPost';
import OSINTMap from './OSINTMap';
import OSINTFilter from './OSINTFilter';
import OSINTAnalytics from './OSINTAnalytics';

import './OSINTPage.css';
import { fetchTwitterDataAction } from 'actions/osintActions';
import moment from 'moment';
import { endLoading, startLoading } from 'reducers/loading/loading.action';

import validateSubscription from 'utils/validateSubscription';
import SubscriptionRequired from 'components/SubscriptionManagement/SubscriptionRequired';
import PageTitle from 'components/headers/PageTitle';

const TAB_DATA = [
  { label: 'Posts', value: 'Posts' },
  // { label: 'Map', value: 'Map' },
  // { label: 'Analytics', value: 'Analytics' },
];

export default function OSINTPage() {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const prevSearchTermRef = useRef(null);

  const reduxTwitterDataLoaded = useSelector((state) => {
    return state.app.isTwitterDataLoaded;
  });

  const user = useSelector((state) => {
    return state.app.user;
  });
  const reduxUserSubscriptionInfo = useSelector((state) => {
    return state.app.userSubscriptionInfo;
  });
  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const [selectedTab, setSelectedTab] = useState(TAB_DATA[0].value);
  const [searchTerm, setSearchTerm] = useState('');
  const [numPosts, setNumPosts] = useState(10);
  const [selectedSources, setSelectedSources] = useState(['twitter']);
  const [includeKeywords, setIncludeKeywords] = useState('');
  const [excludeKeywords, setExcludeKeywords] = useState('');
  const [startTime, setStartTime] = useState(moment().subtract(1, 'day'));
  const [endTime, setEndTime] = useState(moment());

  const handleToggleButtonClick = (value) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    prevSearchTermRef.current = searchTerm;
  }, [searchTerm]);

  function submitClicked() {
    if (searchTerm !== '') {
      reduxDispatch(
        fetchTwitterDataAction(
          searchTerm,
          numPosts,
          selectedSources,
          includeKeywords,
          excludeKeywords,
          startTime,
          endTime
        )
      );
      /*
      reduxDispatch(
        fetchTwitterDataEnriched(
          searchTerm,
          numPosts,
          selectedSources,
          includeKeywords,
          excludeKeywords,
          startTime,
          endTime
        )
      );
      */
      /*
      reduxDispatch(
        fetchOSINTAsk(
          searchTerm,
          numPosts,
          selectedSources,
          includeKeywords,
          excludeKeywords
        )
      );
      */
    }
  }

  useEffect(() => {
    if (!reduxTwitterDataLoaded) {
      reduxDispatch(startLoading());
    } else {
      reduxDispatch(endLoading());
    }
  }, [reduxTwitterDataLoaded]);

  let subscriptionValidated = true;
  if (
    featureFlags.includes('STRIPE') &&
    !validateSubscription(user, reduxUserSubscriptionInfo)
  ) {
    subscriptionValidated = false;
  }

  return (
    <>
      <div className="site-main">
        <div className="container-fluid">
          <PageTitle title="OSINT" />

          {!subscriptionValidated && <SubscriptionRequired />}
          <div className="row">
            <div className="col-xl-3 order-1 order-xl-0">
              <OSINTFilter
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                numPosts={numPosts}
                setNumPosts={setNumPosts}
                selectedSources={selectedSources}
                setSelectedSources={setSelectedSources}
                includeKeywords={includeKeywords}
                setIncludeKeywords={setIncludeKeywords}
                excludeKeywords={excludeKeywords}
                setExcludeKeywords={setExcludeKeywords}
                startTime={startTime}
                setStartTime={setStartTime}
                endTime={endTime}
                setEndTime={setEndTime}
                submitClicked={submitClicked}
                subscriptionValidated={subscriptionValidated}
              />
            </div>

            <div className="col-xl-9 mb-4 mb-xl-0">
              {selectedTab === 'Posts' && (
                <OSINTPost searchTerm={searchTerm} numPosts={numPosts} />
              )}
              {selectedTab === 'Map' && <OSINTMap />}
              {selectedTab === 'Analytics' && <OSINTAnalytics />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
