import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { getUserGuid, getGroupGuid } from 'slices/sliceHelpers';

// Slice
const resourceSitesSlice = createSlice({
    name: 'ics_resource_sites',
    initialState: {
        ics_resource_sites: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        createResourceSiteSuccess: (state, action) => {
            state.ics_resource_sites.push(action.payload);
        },
        fetchResourceSitesSuccess: (state, action) => {
            state.ics_resource_sites = action.payload;
        },
        updateResourceSiteSuccess: (state, action) => {
            const index = state.ics_resource_sites.findIndex((site) => site.id === action.payload.id);
            state.ics_resource_sites[index] = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    createResourceSiteSuccess,
    fetchResourceSitesSuccess,
    updateResourceSiteSuccess,
    setError
} = resourceSitesSlice.actions;

export default resourceSitesSlice.reducer;

export const createResourceSite = (data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_resource_sites}`, {...data, user_guid});
        dispatch(fetchResourceSites(group_guid));
        dispatch(createResourceSiteSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchResourceSites = (group_guid) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_resource_sites}/${group_guid}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchResourceSitesSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateResourceSite = (id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_resource_sites}/${id}`, {...data, user_guid});
        dispatch(fetchResourceSites(group_guid));
        dispatch(updateResourceSiteSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
