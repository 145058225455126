import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {StylishNewButton} from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

import ICSIncidentComs from './ICSIncidentComs';
import NewICSComsDialog from './NewICSComsDialog';

import { updateIcsComs } from 'slices/ics_comsSlice';
import { createIcsIncidentComs, updateIcsIncidentComs } from 'slices/ics_incidentComsSlice';
import ExpandRightArrow from '../DesignSystems/buttons/ExpandRightArrow';
import ExpandDownArrow from '../DesignSystems/buttons/ExpandDownArrow';

export default function ComsDialog({
  show,
  onClose,
  incident_id = null,
  ics_coms,
  ics_incident_coms,
}) {
  const reduxDispatch = useDispatch();

  const [isNewICSComsDialogOpen, setIsNewICSComsDialogOpen] = useState(false);
  const [modalType, setModalType] = useState('New');
  const [editComsData, setEditComsData] = useState();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [archiveConfirmationData, setArchiveConfirmationData] = useState(null);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const [dialogComs, setDialogComs] = useState([...ics_incident_coms]);

  useEffect(() => {
    setDialogComs(ics_incident_coms);
  }, [ics_incident_coms]);

  useEffect(() => {
    // Use to rerender
  }, [ics_coms]);

  const allColumns = [
    { dataField: 'type', text: 'Type', sort: true, attrs: { title: 'Type' } },
    { dataField: 'branch', text: 'Branch', sort: true, attrs: { title: 'Branch' } },
    { dataField: 'division_group', text: 'Division/Group', sort: true, attrs: { title: 'Division/Group' } },
    { dataField: 'method_of_contact', text: 'Method of Contact', sort: true, attrs: { title: 'Method of Contact' } },
    { dataField: 'zone_group', text: 'Zone Group', sort: true, attrs: { title: 'Zone Group' } },
    { dataField: 'channel_number', text: 'Channel Number', sort: true, attrs: { title: 'Channel Number' } },
    { dataField: 'function', text: 'Function', sort: true, attrs: { title: 'Function' } },
    { dataField: 'channel_name', text: 'Channel Name', sort: true, attrs: { title: 'Channel Name' } },
    { dataField: 'assignment', text: 'Assignment', sort: true, attrs: { title: 'Assignment' } },
    { dataField: 'rx_frequency', text: 'RX Frequency', sort: true, attrs: { title: 'RX Frequency' } },
    { dataField: 'rx_tone_nac', text: 'RX Tone/NAC', sort: true, attrs: { title: 'RX Tone/NAC' } },
    { dataField: 'tx_frequency', text: 'TX Frequency', sort: true, attrs: { title: 'TX Frequency' } },
    { dataField: 'tx_tone_nac', text: 'TX Tone/NAC', sort: true, attrs: { title: 'TX Tone/NAC' } },
    { dataField: 'mode', text: 'Mode', sort: true, attrs: { title: 'Mode' } },
    { dataField: 'remarks', text: 'Remarks', sort: true, attrs: { title: 'Remarks' } },
    { dataField: 'special_instructions', text: 'Special Instructions', sort: true, attrs: { title: 'Special Instructions' } },
    { dataField: 'user_name', text: 'Last Modified By', sort: true, attrs: { title: 'Last Modified By' } },
    { dataField: 'archived', text: 'Archived', sort: true, attrs: { title: 'Archived' }, formatter: (cell) => (cell ? 'Yes' : 'No') },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <ICSIncidentComs row={row} ics_coms={ics_coms} ics_incident_coms={ics_incident_coms} />
          {(!incident_id || !!ics_incident_coms.find((coms) => coms.coms_id === row.id)) && (
            <div className="button-group">
              <StylishNewButton className="button--primary" onClick={() => onEdit(row)}>
                Edit
              </StylishNewButton>
              <StylishNewButton className="button--tertiary button--reverse" onClick={() => onArchive(row)}>
                {row.archived ? 'Unarchive' : 'Archive'}
              </StylishNewButton>
            </div>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? <ExpandDownArrow /> : <ExpandRightArrow />,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <span className="sr-only">Details</span>
            <ExpandDownArrow />
          </>
        );
      }
      return (
        <>
          <span className="sr-only">Details</span>
          <ExpandRightArrow />
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    selected: ics_coms
      .filter((coms) => {
        const incComs = ics_incident_coms.find(
          (icoms) => icoms.coms_id === coms.id && !icoms.archived
        );
        return !!incComs;
      })
      .map((coms) => coms.id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex) => {
      const existingIncidentComs = ics_incident_coms.find(
        (icoms) => icoms.coms_id === row.id
      );
      if (existingIncidentComs) {
        reduxDispatch(
          updateIcsIncidentComs(
            incident_id,
            existingIncidentComs.id,
            {
              ...existingIncidentComs,
              archived: !existingIncidentComs.archived,
            }
          )
        );
      } else {
        // Create a new incident coms
        const incidentComsData = {
          coms_id: row.id,
          type: row.type,
          branch: row.branch,
          division_group: row.division_group,
          method_of_contact: row.method_of_contact,
          zone_group: row.zone_group,
          channel_number: row.channel_number,
          function: row.function,
          channel_name: row.channel_name,
          assignment: row.assignment,
          rx_frequency: row.rx_frequency,
          rx_tone_nac: row.rx_tone_nac,
          tx_frequency: row.tx_frequency,
          tx_tone_nac: row.tx_tone_nac,
          mode: row.mode,
          remarks: row.remarks,
          special_instructions: row.special_instructions,
          user_guid: row.user_guid,
          incident_id: incident_id,
          group_guid: reduxCurrentlySelectedGroup.group_guid,
          archived: false,
        };
        reduxDispatch(createIcsIncidentComs(incident_id, incidentComsData));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setDialogComs(rows) : setDialogComs([]);
    },
  };

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewICSComsDialogOpen(true);
    setEditComsData(row);
  };

  const onArchive = (row) => {
    setShowConfirmDialog(true);
    setArchiveConfirmationData(row);
  };

  const onConfirmedArchiveActionData = () => {
    const payload = {
      ...archiveConfirmationData,
      incident_id,
      archived: !archiveConfirmationData.archived,
    };

    if (!!incident_id) {
      reduxDispatch(
        updateIcsIncidentComs(
          incident_id,
          archiveConfirmationData.id,
          payload
        )
      );
    } else {
      reduxDispatch(updateIcsComs(archiveConfirmationData.id, payload));
    }

    setShowConfirmDialog(false);
    setArchiveConfirmationData(null);
  };

  const onNewComs = () => {
    setModalType('New');
    setIsNewICSComsDialogOpen(true);
  };

  const tableRows = ics_coms.map((coms) => {
    const incComs = ics_incident_coms.find(
      (icoms) => icoms.coms_id === coms.id
    );
    if (incComs) {
      return {
        ...incComs,
        id: coms.id,
      };
    } else {
      return coms;
    }
  });

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Select Incident Communications</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">
          {reduxCurrentlySelectedGroup?.group_name}
        </p>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={onNewComs}
        >
          New Communication
        </StylishNewButton>
        <div>
          <label>
            {!!incident_id && (
              <>
                Select to add to incident. De-select to archive. Re-select to
                un-archive.
              </>
            )}
          </label>
        </div>
        <StylishNewTable
          keyField={'id'}
          rows={tableRows}
          columns={allColumns}
          expandRow={expandRow}
          selectRow={(!!incident_id && selectRow) || undefined}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewICSComsDialogOpen && (
        <NewICSComsDialog
          show={isNewICSComsDialogOpen}
          setEditComsData={setEditComsData}
          setModalType={setModalType}
          editComsData={editComsData}
          modalType={modalType}
          onClose={() => setIsNewICSComsDialogOpen(false)}
          incident_id={incident_id}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Archive Confirmation'}
          dialogContent={'Are you sure you want to archive this Communication?'}
          dialogButtonText={'Archive'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedArchiveActionData}
        />
      )}
    </Modal>
  );
}
