import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import { exportAsForm } from 'actions/formActions';
import SOPs from 'components/DisasterGPT/SOPs';
import './PlanAI.css';

import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

import {
  getAllObjectivesToGroup,
  getAllObjectiveToIncident,
  getAllResourcesToGroup,
  getAllResourcesToIncident,
} from 'actions/incidentActions';
import {
  getAllAction,
  getAllActionByIncident,
} from 'actions/IAPForm201Actions';
import { initPlanAISuggestion } from 'slices/dchatSlice';

import MEISelection from './MEISelection';

export default function PlanAI({
  incidentTypes,
  incidentDescription,
  planAIInit,
  setPlanAIInit,
  formReady,
  setFormReady,
}) {
  const reduxDispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [formReadyType, setFormReadyType] = useState();
  const [selection, setSelection] = useState([])

  const dgptSitrepToInitialIncidentReport = useSelector((state) => {
    return state.dchat.dgptSitrepToInitialIncidentReport;
  });
  const planAISuggestions = useSelector((state) => {
    return state.dchat.planAISuggestions;
  });

  useEffect(() => {
    reduxDispatch(getAllObjectivesToGroup());
    reduxDispatch(getAllObjectiveToIncident());

    // reduxDispatch(getAllResourcesToGroup());
    // reduxDispatch(getAllResourcesToIncident());

    // reduxDispatch(getAllAction());
    // reduxDispatch(getAllActionByIncident());
  }, []);

  function isReportReadyForPlanAI() {
    if (!!incidentDescription && !!incidentTypes) {
      return true;
    }
    return false;
  }

  // Hack to get values from initial incident report form as the values aren't always accessible from hooks even after user data entry
  function planAIClicked(mei_type) {
    if (!formReady) {
      setFormReadyType(mei_type);
      setPlanAIInit(true);
    } else {
      generateMEI(mei_type);
    }
  }

  function generateMEI(mei_type) {
    if (!isReportReadyForPlanAI()) {
      window.alert('Synopsis and Incident Type fields required for PlanAI');
    } else {
      const input = {
        sitrep: dgptSitrepToInitialIncidentReport?.sitrepMarkdown,
        incidentTypes: incidentTypes,
        incidentDescription: incidentDescription,
      };
      reduxDispatch(
        initPlanAISuggestion({
          reference: dgptSitrepToInitialIncidentReport?.reference,
          reference_id: dgptSitrepToInitialIncidentReport?.reference_id,
          input,
          sitrep_id: dgptSitrepToInitialIncidentReport?.sitrep_id,
          mei_type,
        })
      );
      setFormReady(false);
      setPlanAIInit(false);
    }
  }

  useEffect(() => {
    if (!!formReady) {
      generateMEI(formReadyType);
    }
  }, [formReady]);

  function readyForExport() {
    if (
      !!planAISuggestions &&
      planAISuggestions.objectives &&
      planAISuggestions.actions &&
      planAISuggestions.resources
    ) {
      return true;
    } else {
      return false;
    }
  }

  function export201Clicked() {
    let data = planAISuggestions;
    data.situationSummary = incidentDescription;

    data.objectives = null;
    data.actions = null;
    data.resources = null;

    reduxDispatch(
      exportAsForm({
        formname: 'ICS_201-CG',
        data,
        mapSketch: dgptSitrepToInitialIncidentReport.mapScreenshot,
      })
    ) || [];
  }

  return (
    <div className="PlanAI">
      <div className="col-md-12">
        <hr className="dashed" />
      </div>
      <div>
        <h2>[PREVIEW] PlanAI</h2>
        <div>
          <span className="planai-required ">PlanAI fields required</span>
        </div>
        <SOPs 
          selection={selection}
          setSelection={setSelection}
        />
        <div>
          <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Objectives</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <MEISelection
                  mei_type={'objectives'}
                  planAIClicked={planAIClicked}
                />
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Actions</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <MEISelection
                  mei_type={'actions'}
                  planAIClicked={planAIClicked}
                />
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Resources</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <MEISelection
                  mei_type={'resources'}
                  planAIClicked={planAIClicked}
                />
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <div>
            <StylishNewButton onClick={() => export201Clicked()}>
              ICS 201-CG
            </StylishNewButton>
          </div>
        </div>
      </div>
    </div>
  );
}
