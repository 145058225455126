import config from 'constants/apiConfig';
import axios from 'axios';

import {
  ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_ERROR,
  ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_REQUEST,
  ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_SUCCESS,
  ADD_INITIAL_INCIDENT_REPORT_COMMENT_REQUEST,
  ADD_INITIAL_INCIDENT_REPORT_COMMENT_SUCCESS,
  ADD_INITIAL_INCIDENT_REPORT_ERROR,
  ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_ERROR,
  ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_REQUEST,
  ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_SUCCESS,
  ADD_INITIAL_INCIDENT_REQUEST,
  ADD_INITIAL_INCIDENT_SUCCESS,
  GET_ALL_ACTIVITY_LOG,
  GET_ALL_INITIAL_INCIDENT_REPORT,
  GET_ALL_INITIAL_INCIDENT_REPORT_CHECKLIST,
  GET_ALL_INITIAL_INCIDENT_REPORT_CHECKLIST_REQUEST,
  GET_ALL_INITIAL_INCIDENT_REPORT_LOGS,
  GET_ALL_INITIAL_INCIDENT_REPORT_LOGS_REQUEST,
  GET_ALL_INITIAL_INCIDENT_REPORT_LOGS_SUCCESS,
  GET_ALL_INITIAL_INCIDENT_REPORT_RECIPIENT_LIST,
  GET_ALL_INITIAL_INCIDENT_REPORT_REQUEST,
  GET_ALL_INITIAL_INCIDENT_REPORT_SUCCESS,
  GET_ALL_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST,
  GET_ALL_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_REQUEST,
  GET_INITIAL_INCIDENT_REPORT,
  GET_INITIAL_INCIDENT_REPORT_REQUEST,
  GET_INITIAL_INCIDENT_REPORT_SUCCESS,
} from 'constants/incident-report-Incident-types';
import parseErrorMessage from 'utils/parseErrorMessage';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

function initialIncidentReportError(errorApi, error) {
  const errorMessage =
    errorApi +
    '\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: ADD_INITIAL_INCIDENT_REPORT_ERROR,
  };
}
function initialIncidentChecklistError(errorApi, error) {
  const errorMessage =
    errorApi +
    '\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_ERROR,
  };
}

function initialIncidentTeamChecklistError(errorApi, error) {
  const errorMessage =
    errorApi +
    '\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_ERROR,
  };
}
export const getAllInitialIncidentReport = () => {
  return (dispatch, getState) => {
    dispatch({ type: GET_ALL_INITIAL_INCIDENT_REPORT_REQUEST });
    return axios(config.apiGateway.getAllInitialIncidentReportToIncident, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        const formRecords = response.data || {};
        dispatch({ type: GET_ALL_INITIAL_INCIDENT_REPORT_SUCCESS });
        dispatch({
          type: GET_ALL_INITIAL_INCIDENT_REPORT,
          payload: response.data,
        });
        return formRecords;
      })
      .catch((error) => {
        dispatch(
          initialIncidentReportError(
            config.apiGateway.getAllInitialIncidentReportToIncident,
            error
          )
        );
      });
  };
};

export const upsertInitialIncidentReport = (report) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_INITIAL_INCIDENT_REQUEST });

    return axios(config.apiGateway.addInitialIncidentReportToIncident, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        report,
      }),
    })
      .then(() => {
        dispatch({ type: ADD_INITIAL_INCIDENT_SUCCESS });
        dispatch(getAllInitialIncidentReport());
        // toast.success('Add Initial Incident Report to Incident successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(
          initialIncidentReportError(
            config.apiGateway.addInitialIncidentReportToIncident,
            error
          )
        );
      });
  };
};

export const deleteInitialIncidentReport = (report_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteInitialIncidentReportToIncident, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        report_id,
      }),
    })
      .then(() => {
        dispatch(getAllInitialIncidentReport());
        // toast.success('Delete Initial Incident Report to Incident successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(
          initialIncidentReportError(
            config.apiGateway.deleteInitialIncidentReportToIncident,
            error
          )
        );
      });
  };
};

export const escalateIncidentReport = (report_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.escalateIncidentReport, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: getState().app.currentIncident.id,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        report_id,
      }),
    })
      .then(() => {
        dispatch(getAllInitialIncidentReport());
        // toast.success('Escalate Incident Report successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(
          initialIncidentReportError(
            config.apiGateway.escalateIncidentReport,
            error
          )
        );
      });
  };
};

export const deleteFileFromUrl = (fileUrl) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteFile, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        url: fileUrl,
      }),
    })
      .then((resp) => {
        // toast.success('Delete File successful', {
        //   autoClose: 2000,
        // });
        return resp.data;
      })
      .catch((error) => {
        dispatch(
          initialIncidentReportError(config.apiGateway.deleteFile, error)
        );
      });
  };
};
export const initialIncidentReportCreateComment = (report_id, report_log) => {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_INITIAL_INCIDENT_REPORT_COMMENT_REQUEST });
    return await axios(config.apiGateway.crateInitialIncidentReportComment, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        report_id,
        report_log,
      }),
    })
      .then(() => {
        dispatch({ type: ADD_INITIAL_INCIDENT_REPORT_COMMENT_SUCCESS });
        dispatch(getAllInitialIncidentReportLogs(report_id));
        // toast.success('Create Initial Incident Report Comment successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(
          initialIncidentReportError(
            config.apiGateway.crateInitialIncidentReportComment,
            error
          )
        );
      });
  };
};

export const getAllInitialIncidentReportLogs = (report_id) => {
  return (dispatch, getState) => {
    dispatch({ type: GET_ALL_INITIAL_INCIDENT_REPORT_LOGS_REQUEST });
    return axios(config.apiGateway.getAllInitialIncidentReportLogs, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        report_id,
      }),
    })
      .then((resp) => {
        dispatch({ type: GET_ALL_INITIAL_INCIDENT_REPORT_LOGS_SUCCESS });
        dispatch({
          type: GET_ALL_INITIAL_INCIDENT_REPORT_LOGS,
          payload: resp.data,
        });
      })
      .catch((error) => {
        dispatch(
          initialIncidentReportError(
            config.apiGateway.getAllInitialIncidentReportLogs,
            error
          )
        );
      });
  };
};

export const getAllInitialIncidentReportRecipientList = () => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.getAllInitialIncidentReportRecipientList, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((resp) => {
        dispatch({
          type: GET_ALL_INITIAL_INCIDENT_REPORT_RECIPIENT_LIST,
          payload: resp.data,
        });
      })
      .catch((error) => {
        dispatch(
          initialIncidentReportError(
            config.apiGateway.getAllInitialIncidentReportRecipientList,
            error
          )
        );
      });
  };
};
export const updateInitialIncidentReportRecipientList = (recipientList) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.updateInitialIncidentReportRecipientList, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        recipientList,
      }),
    })
      .then(() => {
        dispatch(getAllInitialIncidentReportRecipientList());
        // toast.success(
        //   'Update Initial Incident Report Recipient List successful',
        //   {
        //     autoClose: 2000,
        //   }
        // );
      })
      .catch((error) => {
        dispatch(
          initialIncidentReportError(
            config.apiGateway.updateInitialIncidentReportRecipientList,
            error
          )
        );
      });
  };
};
export const upsertInitialIncidentReportChecklist = (checklist) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_REQUEST });
    return axios(config.apiGateway.addUpdateInitialIncidentReportChecklist, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        checklist,
      }),
    })
      .then(() => {
        dispatch({ type: ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_SUCCESS });
        dispatch(getAllInitialIncidentReportChecklist());
        // toast.success(
        //   'Add Update Initial Incident Report Checklist successful',
        //   {
        //     autoClose: 2000,
        //   }
        // );
      })
      .catch((error) => {
        dispatch(
          initialIncidentChecklistError(
            config.apiGateway.addUpdateInitialIncidentReportChecklist,
            error
          )
        );
      });
  };
};

export const getAllInitialIncidentReportChecklist = () => {
  return (dispatch, getState) => {
    dispatch({ type: GET_ALL_INITIAL_INCIDENT_REPORT_CHECKLIST_REQUEST });
    return axios(config.apiGateway.getAllInitialIncidentReportChecklist, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((resp) => {
        dispatch({
          type: GET_ALL_INITIAL_INCIDENT_REPORT_CHECKLIST,
          payload: resp.data,
        });
      })
      .catch((error) => {
        dispatch(
          initialIncidentChecklistError(
            config.apiGateway.getAllInitialIncidentReportChecklist,
            error
          )
        );
      });
  };
};

export const deleteInitialIncidentReportChecklist = (checklist_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteInitialIncidentReportChecklist, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        checklist_id: checklist_id,
      }),
    })
      .then(() => {
        dispatch(getAllInitialIncidentReportChecklist());
      })
      .catch((error) => {
        dispatch(
          initialIncidentChecklistError(
            config.apiGateway.deleteInitialIncidentReportChecklist,
            error
          )
        );
      });
  };
};

export const getChecklistFormAssetAndIncidentType = (asset, incident_type) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.getChecklistFormAssetAndIncidentType, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        asset,
        incident_type,
      }),
    })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        dispatch(
          initialIncidentChecklistError(
            config.apiGateway.getChecklistFormAssetAndIncidentType,
            error
          )
        );
      });
  };
};

//Team Checklist

export const upsertInitialIncidentReportTeamChecklist = (checklist) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_REQUEST });
    return axios(config.apiGateway.upsertInitialIncidentReportTeamChecklist, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        checklist,
      }),
    })
      .then(() => {
        dispatch({ type: ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_SUCCESS });
        dispatch(getAllInitialIncidentReportTeamChecklist());
        // toast.success(
        //   'Upsert Initial Incident Report Team Checklist successful',
        //   {
        //     autoClose: 2000,
        //   }
        // );
      })
      .catch((error) => {
        dispatch(
          initialIncidentTeamChecklistError(
            config.apiGateway.upsertInitialIncidentReportTeamChecklist,
            error
          )
        );
      });
  };
};

export const getAllInitialIncidentReportTeamChecklist = () => {
  return (dispatch, getState) => {
    dispatch({ type: GET_ALL_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_REQUEST });
    return axios(config.apiGateway.getAllInitialIncidentReportTeamChecklist, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((resp) => {
        dispatch({
          type: GET_ALL_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST,
          payload: resp.data,
        });
      })
      .catch((error) => {
        dispatch(
          initialIncidentChecklistError(
            config.apiGateway.getAllInitialIncidentReportTeamChecklist,
            error
          )
        );
      });
  };
};

export const deleteInitialIncidentReportTeamChecklist = (checklist_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteInitialIncidentReportTeamChecklist, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        checklist_id: checklist_id,
      }),
    })
      .then(() => {
        dispatch(getAllInitialIncidentReportTeamChecklist());
        // toast.success(
        //   'Delete Initial Incident Report Team Checklist successful',
        //   {
        //     autoClose: 2000,
        //   }
        // );
      })
      .catch((error) => {
        dispatch(
          initialIncidentChecklistError(
            config.apiGateway.deleteInitialIncidentReportTeamChecklist,
            error
          )
        );
      });
  };
};

export const getTeamChecklistFormAssetAndIncidentType = (
  asset,
  incident_type,
  teams_activated
) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.getTeamChecklistFormAssetAndIncidentType, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        asset,
        incident_type,
        teams_activated,
      }),
    })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        dispatch(
          initialIncidentChecklistError(
            config.apiGateway.getTeamChecklistFormAssetAndIncidentType,
            error
          )
        );
      });
  };
};

export const getInitialIncidentReportById = (report_id) => {
  return (dispatch, getState) => {
    dispatch({ type: GET_INITIAL_INCIDENT_REPORT_REQUEST });

    return axios(config.apiGateway.getInitialIncidentReportById, {
      method: 'POST',
      data: JSON.stringify({
        report_id: report_id,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((resp) => {
        dispatch({ type: GET_INITIAL_INCIDENT_REPORT_SUCCESS });
        dispatch({ type: GET_INITIAL_INCIDENT_REPORT, payload: resp.data });
      })
      .catch((error) => {
        dispatch(
          initialIncidentReportError(
            config.apiGateway.getInitialIncidentReportById,
            error
          )
        );
      });
  };
};

// All Activity Logs

export const getAllActivityLogs = () => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.diceActivityLogs, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_ALL_ACTIVITY_LOG, payload: response.data });
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          initialIncidentReportError(config.apiGateway.diceActivityLogs, error)
        );
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

export const getAllQuestionByAor = (aor_id) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.getAllQuestionByAor, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        aor_id,
      }),
    })
      .then((response) => {
        return response.data ? response.data : [];
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          initialIncidentReportError(
            config.apiGateway.getAllQuestionByAor,
            error
          )
        );
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

export const upsertQuestionByAor = (aorQuestions) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.upsertQuestionByAor, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        aorQuestions,
      }),
    })
      .then(async () => {
        const resp = await dispatch(getAllQuestionByAor(aorQuestions.aor));
        return resp;
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          initialIncidentReportError(
            config.apiGateway.upsertQuestionByAor,
            error
          )
        );
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};
