import {
  deleteBranch,
  getAllBranch,
} from 'actions/IAPForm204Actions';
import { endLoading, startLoading } from 'reducers/loading/loading.action';

import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DrawerWrapper, { DrawerFooter } from '../DrawerWrapper';
import AddUpdateBranchDrawer from './AddUpdateBranchDrawer';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

export default function BranchDrawer({
  show,
  onClose,
  selectedBranch,
  parent,
  rowSelectable,
  updateSelectedBranch,
}) {
  const reduxDispatch = useDispatch();
  useEffect(() => {
    reduxDispatch(getAllBranch());
  }, [reduxDispatch]);

  const reduxCurrentIncident = useSelector((state) => state.app.currentIncident);
  const branches = useSelector((state) => state.app.allBranches);

  const [branch, setSelectedBranch] = useState(selectedBranch);
  const [showNewBranchDrawer, setShowNewBranchDrawer] = useState(false);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [editBranchData, setEditBranchData] = useState(null);

  const columns = [
    {
      dataField: 'name',
      text: 'Branch',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Branch' },
    },
    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row) => (
        <StylishNewTableAction
          name="Branch"
          cell={cell}
          row={row}
          onDelete={() => onDeleteAction(row)}
          onEdit={() => {
            setEditBranchData(row);
            setShowNewBranchDrawer(true);
          }}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectAll: true,
    selected: !!branch && [branch.id],
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedBranch(row);
      }
    },
  };

  const onSave = async () => {
    if (!reduxCurrentIncident) {
      toast.error('Please select an Incident first', toastConfig);
      return;
    }
    try {
      reduxDispatch(startLoading());
      updateSelectedBranch(branches.find((b) => b.id === branch.id));
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      reduxDispatch(endLoading());
    }
  };

  const onDeleteAction = (row) => {
    setDeleteConfirmationData(row);
    setShowConfirmDialog(true);
  };

  const onConfirmedDeleteActionData = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteBranch(deleteConfirmationData.id));
    if (selectedBranch?.id === deleteConfirmationData.id) {
      setSelectedBranch(branches.length ? branches[0] : null);
    }
    setDeleteConfirmationData(null);
    setShowConfirmDialog(false);
  };

  return (
    <DrawerWrapper toggle={onClose} title="Branch" show={show}>
      <div className="d-flex flex-column flex-grow-1">
        <StylishNewButton
          onClick={() => {
            setEditBranchData(null);
            setShowNewBranchDrawer(true);
          }}
          className={'button--primary mb-4'}
        >
          New Branch
        </StylishNewButton>
        <Row className="flex-grow-1">
          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={branches}
            selectRow={rowSelectable ? selectRow : undefined}
          />
        </Row>
      </div>
      <DrawerFooter>
        <div className="button-group">
          <StylishNewButton
            type="button"
            className="button--secondary button--reverse"
            onClick={onClose}
          >
            Cancel
          </StylishNewButton>
          {parent !== 'IncidentWorkspace' && (
            <StylishNewButton
              className={'button--primary'}
              onClick={onSave}
              disabled={
                !(branches.length && branch && [branch.id])
              }
            >
              Save
            </StylishNewButton>
          )}
        </div>
      </DrawerFooter>

      {showNewBranchDrawer && (
        <AddUpdateBranchDrawer
          show={showNewBranchDrawer}
          onClose={() => setShowNewBranchDrawer(false)}
          selectedBranch={editBranchData}
          onBranchSelect={setSelectedBranch}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={
            "Are you sure you want to delete this branch and its divisions?"
          }
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedDeleteActionData}
        />
      )}
    </DrawerWrapper>
  );
}
