import React from "react";
import { StylishSwitcher } from "components/DesignSystems/New/StylishSwitcher";
import { StylishNewButton } from "components/DesignSystems/New/StylishNewButton";

import IconClose from '../../../assets/images/icon__times.svg';

const MapSettings = (props) => {

  const {
    mapSettings,
    handleMapSettings,
    onClose 
  } = props;

  // functions 
  const handleClickClose = () => {
    onClose();
  }

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        <h4 className="m-0">Settings</h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={() => handleClickClose()}
        >
          <img src={IconClose} alt="" />
        </StylishNewButton>
      </div>
      <div className="sidebar-inner">
        <div className="d-flex align-items-center py-3 pt-0">
          <p className="m-0">Show 3D Buildings</p>
          <StylishSwitcher
            className={'ms-auto'}
            checked={mapSettings?.threeDBuildingsToggle}
            onChange={() => handleMapSettings({
              ...mapSettings,
              threeDBuildingsToggle: !mapSettings?.threeDBuildingsToggle
            })}
          />
        </div>

        <hr className="dashed w-10 thin m-0 py-3" />

        <div className="d-flex align-items-center">
          <p className="m-0">Show Traffic</p>
          <StylishSwitcher
            className={'ms-auto'}
            checked={mapSettings?.trafficToggle}
            onChange={() => handleMapSettings({
              ...mapSettings,
              trafficToggle: !mapSettings?.trafficToggle
            })}
          />
        </div>
      </div>
    </div>
  )
};

export default MapSettings;
