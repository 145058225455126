import React from 'react';
import Select from 'react-select';
import { createFilter } from 'react-select';

export default function StylishNewSelect({
  classes,
  style,
  options,
  defaultValue,
  value,
  onChange,
  placeholder,
  isClearable,
  isSearchable,
  isMulti,
  isDisabled,
  filterOption,
  ...props
}) {
  return (
    <div className={'form-selectbox ' + (classes ? classes : '')} style={style}>
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        options={options}
        defaultValue={defaultValue}
        value={value || defaultValue}
        onChange={onChange}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        filterOption={
          filterOption && filterOption?.matchFrom
            ? createFilter(filterOption)
            : filterOption
        }
        isMulti={isMulti || false}
        isDisabled={isDisabled}
        noOptionsMessage={() => 'No Items Found'}
        {...props}
      />
    </div>
  );
}
