import React from 'react';
import Slider from 'react-slick';

export const StylishCarousel = ({
  className,
  dots,
  arrows,
  infinite,
  slidesToShow,
  slidesToShowLG,
  slidesToShowMD,
  slidesToShowSM,
  children,
}) => {
  const settings = {
    dots: dots,
    arrows: arrows,
    infinite: infinite,
    slidesToShow: parseInt(slidesToShow),
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: parseInt(slidesToShowLG),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: parseInt(slidesToShowMD),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: parseInt(slidesToShowSM),
        },
      },
    ],
  };
  return (
    <>
      <Slider className={className} {...settings}>
        {children}
      </Slider>
    </>
  );
};
