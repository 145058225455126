import { Offcanvas, Row } from 'react-bootstrap';
import React from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { EditEntityDrawerProps } from './EntityListProps';
import { IncidentMedicalTransportation, useEditIncidentMedicalTransportation } from './hooks/incidentMedicalTransportationHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import FormCheckboxField from './formFields/FormCheckboxField';
import { useActualIncidentId } from './hooks/commonHooks';
import { useSelector } from 'react-redux';
import { selectUserGuid } from '../../slices/commonSelectors';
import {
  selectGroupGuid,
  useAppSelector,
} from '../../slices/commonSelectors';

type FormFields = {
  id: string;
  transportation_id: string;
  ambulance_service?: string;
  description?: string;
  address?: string;
  contact_number?: string;
  paramedics_on_board?: boolean;
  archived?: boolean;
};

const MedicalTransportationEditDrawer: React.FC<EditEntityDrawerProps<IncidentMedicalTransportation>> = ({
  toggle,
  selectedEntity: editedEntity,
}) => {
  const form = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: { ...editedEntity },
  });
  const { handleSubmit } = form;

  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useAppSelector(selectGroupGuid);

  const mutation = useEditIncidentMedicalTransportation(editedEntity.id);

  const submitMedicalTransportationEdit = async (formData: FormFields) => {
    const payload = {
      ...formData,
      archived: formData.archived ?? false,
      incident_id: incidentId,
      user_guid: user_guid,
      transportation_id: editedEntity.transportation_id,
      group_guid
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  const toggleActive = async () => {
    await mutation.mutateAsync({
      ...editedEntity,
      archived: !editedEntity.archived,
    });
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Edit Medical Transportation">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(submitMedicalTransportationEdit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextField
                title="Ambulance Service"
                fieldName="ambulance_service"
                rules={{ maxLength: { value: 200, message: 'This maximum length is 200 characters' } }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Description"
                fieldName="description"
                rules={{ maxLength: { value: 300, message: 'This maximum length is 300 characters' } }}
                variant="full"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Address"
                fieldName="address"
                rules={{ maxLength: { value: 300, message: 'This maximum length is 300 characters' } }}
                variant="full"
              />
            </Row>
            <Row>
              <FormTextField
                title="Contact Number"
                fieldName="contact_number"
                rules={{ maxLength: { value: 20, message: 'This maximum length is 20 characters' } }}
                variant="half"
              />
            </Row>
            <Row>
              <FormCheckboxField
                title="Paramedics On Board"
                fieldName="paramedics_on_board"
              />
            </Row>
            {/* Add other fields as necessary */}
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={toggleActive}
              >
                {(!editedEntity.archived&&"Deactivate"||"Activate")}
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default MedicalTransportationEditDrawer;
