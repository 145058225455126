/// <reference path="../types/window.ts" />

import combinedReducer, {RootState} from '../reducers/combinedReducer';
import { configureStore, EnhancedStore } from '@reduxjs/toolkit';

let store: EnhancedStore<RootState>;
export function getStore(preloadedState: Record<string, any>): EnhancedStore {
  if (!store) {
    store = configureStore({
      reducer: combinedReducer,
      preloadedState,
      middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false, // turn off checking for serializable values since we use Date in ai2fp slice.
        immutableCheck: false, // turn off immutable check since the store is HUGE and the check is slow.
      }),
      devTools: window.devToolsExtension && process.env.NODE_ENV === 'development',
    });
  }
  return store;
}

