import React from 'react';
import moment from 'moment';

export default function TaskManagementMapLayerPopup({
  features,
  handleReportSelected
}) {

  const task = features;
  if (!task) return <></>;

  return (
    <>
      <h6 className="m-0">Task Report</h6>
      <hr className="dashed thin my-1" />
      {task.map(t => {
        return (
          <>
            <div className="task-popup-container" onClick={() => handleReportSelected(t)}>
              <span className="m-0 fw-bold">{t?.title}</span>
              <span className="d-flex">{t?.content}</span>
              <span className="d-flex">
                <span className="pe-1 fw-bold">Due Date: </span>
                {moment(t?.deadline_date)?.format('MMM D, YYYY')}
              </span>
            </div>
            <hr className="thin my-1" />
          </>
        )
      })}

    </>
  );
}
