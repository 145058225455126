import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

export default function OSINTPostTwitterCard(props) {
  const { data } = props;

  const handleGotoTwitter = (data) => {
    window.open(data?.post_url, '_blank');
  };

  return (
    <>
      <p
        className="mb-3 text-uppercase txt--sml weight-600"
        style={{ letterSpacing: '1px' }}
      >
        Twitter
      </p>
      <p style={{ minHeight: '120px' }}>
        {data?.text.substring(0, 140)}
        {data?.text.length >= 140 && '...'}
      </p>
      <p className="mb-4">{data?.post_date}</p>
      <StylishNewButton
        className="button--primary button--sml"
        onClick={() => handleGotoTwitter(data)}
      >
        View Details
      </StylishNewButton>
    </>
  );
}
