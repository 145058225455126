import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timegrid from '@fullcalendar/timegrid'; // a plugin!
import listPlugin from '@fullcalendar/list'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import AddScheduleUnavailabilityDialog from './AddScheduleUnavailabilityDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUserSchedule } from 'actions/diceActions';
import moment from 'moment';
import { toast } from 'react-toastify';
import { endLoading } from 'reducers/loading/loading.action';
import { toastConfig } from 'assets/data/config';

export default function ManageUserSchedule({ user }) {
  const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const [
    addScheduleUnavailabilityDialogOpen,
    setAddScheduleUnavailabilityDialogOpen,
  ] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [existingEvent, setExistingEvent] = useState();
  const reduxDispatch = useDispatch();

  const loggedInUser = useSelector((state) => state.app.user);

  let allUserSchedule = useSelector((state) => {
    return state.app.allUserSchedule
      ? state.app.allUserSchedule.map((schedule) => {
          const schedules = schedule.allday
            ? {
                title: schedule.incident_seat
                  ? `${schedule.user_name || schedule.email_address}: ${
                      schedule.incident_seat
                    }`
                  : schedule.description,
                start: moment(schedule.start_time).toISOString(),
                end: moment(schedule.end_time).add(1, 'days').toISOString(),
                allDay: schedule.allday,
                className: schedule.incident_id ? 'fc-incident' : '',
                ...schedule,
                ...(schedule.incident_seat
                  ? { color: '#ffffff', textColor: '#000000' }
                  : {}),
              }
            : schedule.isrecurringevent
            ? {
                title: schedule.incident_seat
                  ? `${schedule.user_name || schedule.email_address}: ${
                      schedule.incident_seat
                    }`
                  : schedule.description,
                startTime: schedule.recurringstarttime,
                endTime: schedule.recurringendtime,
                startRecur: moment(schedule.start_time).toISOString(),
                endRecur: moment(schedule.end_time)
                  .add(1, 'days')
                  .toISOString(),
                daysOfWeek: schedule.daysofweek,
                allDay: false,
                className: schedule.incident_id ? 'schedule-incident' : '',
                ...schedule,
                ...(schedule.incident_seat
                  ? { color: '#ffffff', textColor: '#000000' }
                  : {}),
              }
            : {
                title: schedule.incident_seat
                  ? `${schedule.user_name || schedule.email_address}: ${
                      schedule.incident_seat
                    }`
                  : schedule.description,
                start: moment(schedule.start_time).toISOString(),
                end: moment(schedule.end_time).toISOString(),
                allDay: schedule.allday,
                className: schedule.incident_id ? 'fc-incident' : '',
                ...schedule,
                ...(schedule.incident_seat
                  ? { color: '#ffffff', textColor: '#000000' }
                  : {}),
              };
          return schedules;
        })
      : [];
  });
  const isGetAllUserScheduleLoaded = useSelector((state) => {
    return state.app.isGetAllUserScheduleLoaded;
  });

  const openAddScheduleDialog = (e) => {
    if (user.user_guid === loggedInUser.user_guid) {
      if (moment(e.date).isBefore(moment().add(-1, 'days'))) {
        toast.error('You are not allowed to add past events.', toastConfig);
        return;
      }
      const today = new Date();
      const date = moment(e.date)
        .set({
          hour: today.getHours(),
          minute: today.getMinutes(),
          second: 0,
          microsecond: 0,
        })
        .toString();
      setSelectedDate(date);
      setExistingEvent();
      setAddScheduleUnavailabilityDialogOpen(true);
    } else {
      setExistingEvent();
      setAddScheduleUnavailabilityDialogOpen(false);
    }
  };

  const editExistingEvent = (e) => {
    if (user.user_guid === loggedInUser.user_guid) {
      if (e.extendedProps.incident_id) {
        toast.error('You cannot update an incident event', toastConfig);
        return;
      }
      setExistingEvent(e);
      setSelectedDate(e.start);
      setAddScheduleUnavailabilityDialogOpen(true);
    } else {
      setAddScheduleUnavailabilityDialogOpen(false);
    }
  };

  useEffect(() => {
    reduxDispatch(getAllUserSchedule(user.user_guid));
  }, [user]);

  useEffect(() => {
    !!isGetAllUserScheduleLoaded && reduxDispatch(endLoading());
  }, [isGetAllUserScheduleLoaded]);

  return (
    <>
      {/* <div className="form-block"> */}
      <div className="calendar">
        <FullCalendar
          dateClick={(info) => openAddScheduleDialog(info)}
          validRange={{ start: firstDay.toISOString() }}
          plugins={[dayGridPlugin, timegrid, listPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridDay,timeGridWeek,dayGridMonth,listMonth',
          }}
          initialView="dayGridMonth"
          events={allUserSchedule}
          eventClick={(info) => editExistingEvent(info.event)}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: user.timeFormat === 'hour12Mode',
          }}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: 'short',
            hour12: user.timeFormat === 'hour12Mode',
          }}
        />
      </div>
      {/* </div> */}
      {addScheduleUnavailabilityDialogOpen && (
        <AddScheduleUnavailabilityDialog
          show={addScheduleUnavailabilityDialogOpen}
          user={user}
          selectedDate={selectedDate}
          existingEvent={existingEvent}
          onClose={() => setAddScheduleUnavailabilityDialogOpen(false)}
        />
      )}
    </>
  );
}
