export const GET_ALL_INITIAL_INCIDENT_REPORT = 'GET_ALL_INITIAL_INCIDENT_REPORT';
export const GET_ALL_INITIAL_INCIDENT_REPORT_REQUEST = 'GET_ALL_INITIAL_INCIDENT_REPORT_REQUEST';
export const GET_ALL_INITIAL_INCIDENT_REPORT_SUCCESS = 'GET_ALL_INITIAL_INCIDENT_REPORT_SUCCESS';
export const ADD_INITIAL_INCIDENT_REQUEST = 'ADD_INITIAL_INCIDENT_REQUEST';
export const ADD_INITIAL_INCIDENT_SUCCESS = 'ADD_INITIAL_INCIDENT_REQUEST';
export const ADD_INITIAL_INCIDENT_REPORT_ERROR = 'ADD_INITIAL_INCIDENT_REPORT_ERROR';
export const ADD_INITIAL_INCIDENT_REPORT_COMMENT_REQUEST = 'ADD_INITIAL_INCIDENT_REPORT_COMMENT_REQUEST';
export const ADD_INITIAL_INCIDENT_REPORT_COMMENT_SUCCESS = 'ADD_INITIAL_INCIDENT_REPORT_COMMENT_SUCCESS';

export const GET_ALL_INITIAL_INCIDENT_REPORT_LOGS_REQUEST = 'GET_ALL_INITIAL_INCIDENT_REPORT_LOGS_REQUEST';
export const GET_ALL_INITIAL_INCIDENT_REPORT_LOGS_SUCCESS = 'GET_ALL_INITIAL_INCIDENT_REPORT_LOGS_SUCCESS';
export const GET_ALL_INITIAL_INCIDENT_REPORT_LOGS = 'GET_ALL_INITIAL_INCIDENT_REPORT_LOGS';
export const GET_ALL_INITIAL_INCIDENT_REPORT_RECIPIENT_LIST = 'GET_ALL_INITIAL_INCIDENT_REPORT_RECIPIENT_LIST';
export const UPDATE_INITIAL_INCIDENT_REPORT_RECIPIENT_LIST = 'UPDATE_INITIAL_INCIDENT_REPORT_RECIPIENT_LIST';
//Site Checklist
export const ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_REQUEST = 'ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_REQUEST';
export const ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_SUCCESS = 'ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_SUCCESS';
export const GET_ALL_INITIAL_INCIDENT_REPORT_CHECKLIST = 'GET_ALL_INITIAL_INCIDENT_REPORT_CHECKLIST';
export const ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_ERROR = 'ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_ERROR';
export const GET_ALL_INITIAL_INCIDENT_REPORT_CHECKLIST_REQUEST = 'GET_ALL_INITIAL_INCIDENT_REPORT_CHECKLIST_REQUEST';

//Team Checklist
export const ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_REQUEST = 'ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_REQUEST';
export const ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_SUCCESS = 'ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_SUCCESS';
export const GET_ALL_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST = 'GET_ALL_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST';
export const ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_ERROR = 'ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_ERROR';
export const GET_ALL_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_REQUEST = 'GET_ALL_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_REQUEST';

//Get Single Incident Report
export const GET_INITIAL_INCIDENT_REPORT = 'GET_INITIAL_INCIDENT_REPORT';
export const GET_INITIAL_INCIDENT_REPORT_REQUEST = 'GET_INITIAL_INCIDENT_REPORT_REQUEST';
export const GET_INITIAL_INCIDENT_REPORT_SUCCESS = 'GET_INITIAL_INCIDENT_REPORT_SUCCESS';

// Activity Log
export const GET_ALL_ACTIVITY_LOG = 'GET_ALL_ACTIVITY_LOG';