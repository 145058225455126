import React from 'react';
import classNames from 'classnames';
import StylishNewSelect from '../../DesignSystems/New/StylishNewSelect';
import { Controller, useFormContext } from 'react-hook-form';

type FormSelectFieldProps = {
  title: string;
  fieldName: string;
  rules?: { [key: string]: { value: any; message: string } };
  options: { label: string; value: string }[] | undefined;
  variant?: 'full' | 'half';
  isMulti?: boolean;
  isClearable?: boolean;
  value?: any;
  defaultValue?: any;
};

const FormSelectField: React.FC<FormSelectFieldProps> = ({
  title,
  fieldName,
  rules,
  options,
  variant = 'full',
  isMulti = false,
  isClearable = false,
  value,
  defaultValue,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const required = typeof rules?.required === 'object';

  return (
    <div
      className={classNames('mt-2', {
        'col-6': variant === 'half',
        'col-12': variant === 'full',
      })}
    >
      <label className="form-label">
        {title}
        {required ? (
          <span aria-label="Required field" className="required">
            *
          </span>
        ) : null}
      </label>
      <Controller
        control={control}
        name={fieldName}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <StylishNewSelect
            options={options}
            value={options?.filter(option =>
              isMulti
                ? value?.includes(option.value)
                : option.value === value
            )}
            onChange={(selectedOption) => {
              onChange(
                isMulti
                  ? selectedOption.map((option: any) => option.value)
                  : selectedOption?.value || ''
              );
            }}
            isMulti={isMulti}
            isClearable={isClearable}
            defaultValue={defaultValue}
          />
        )}
      />
      {errors[fieldName] ? (
        <div className="form-text form-error">
          {errors[fieldName].message as string}
        </div>
      ) : (
        <div className="form-text-none"></div>
      )}
    </div>
  );
};

export default FormSelectField;
