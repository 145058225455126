import { upsertCommunication } from 'actions/IAPForm204Actions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { toastConfig } from 'assets/data/config';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export default function Communication({
  value,
  index,
  total,
  onAdd,
  onDelete,
  onChangeValue,
  disabled,
}) {
  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const reduxDispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({ mode: 'onChange' });
  useEffect(() => {
    if (value.frequency !== getValues('frequency')) {
      setValue('frequency', value.frequency);
    }
    if (value.channel_name !== getValues('channel_name')) {
      setValue('channel_name', value.channel_name);
    }
    if (value.phone !== getValues('phone')) {
      setValue('phone', value.phone);
    }
  }, [value]);
  const onSaveCommunication = (data) => {
    if (!reduxCurrentIncident || !reduxCurrentIncident.current_period) {
      toast.error('Please select a operational period first.', toastConfig);
      return;
    }
    const saveData = {
      id: value.id || generateUUID(),
      assignment: index + 1,
      frequency: data.frequency,
      channel_name: data.channel_name,
      phone: data.phone,
    };
    console.log(saveData);
    reduxDispatch(upsertCommunication(saveData));
  };
  const onChangeFieldValue = () => {
    const newValue = {
      assignment: index + 1,
      frequency: getValues('frequency'),
      channel_name: getValues('channel_name'),
      phone: getValues('phone'),
    };
    onChangeValue(newValue, index);
  };
  return (
    <form onSubmit={handleSubmit(onSaveCommunication)}>
      <div className="bg-gray-900--light-5 rounded p-3 p-md-4 mb-4">
        <div className="d-flex align-items-center mb-3">
          <h4 className="m-0">Assignment {index + 1}</h4>
          <div className="button-group flex-shrink-0 ms-auto">
            {index === total - 1 && (
              <StylishNewButton
                customButton
                className={'button--icon primary'}
                type={'button'}
                onClick={onAdd}
              >
                <SharedIcon iconName="add" classes="px-0" bold />
              </StylishNewButton>
            )}
            {total > 1 && (
              <StylishNewButton
                customButton
                className={'button--icon primary'}
                type={'button'}
                onClick={onDelete}
              >
                <SharedIcon iconName="delete" />
              </StylishNewButton>
            )}
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label">Channel Name</label>
          <Controller
            control={control}
            name={'channel_name'}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishNewInput
                onChange={(e) => {
                  onChange(e);
                  onChangeFieldValue();
                }}
                value={value}
              />
            )}
          />
          {errors?.channel_name && (
            <span className="form-text form-error">
              This field is required.
            </span>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">Frequency (tx)</label>
          <Controller
            control={control}
            name={'frequency'}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishNewInput
                onChange={(e) => {
                  onChange(e);
                  onChangeFieldValue();
                }}
                value={value}
              />
            )}
          />
          {errors?.frequency && (
            <span className="form-text form-error">
              This field is required.
            </span>
          )}
        </div>
        <div className="mb-0">
          <label className="form-label">Phone</label>
          <Controller
            control={control}
            name={`phone`}
            rules={{
              required: true,
              pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/gim,
            }}
            render={({ field: { onChange, value } }) => (
              <StylishNewInput
                type={'text'}
                onChange={(e) => {
                  onChange(e);
                  onChangeFieldValue();
                }}
                value={value}
              />
            )}
          />
          {errors?.phone?.type === 'required' && (
            <span className="form-text form-error">
              This field is required.
            </span>
          )}
          {errors?.phone?.type === 'pattern' && (
            <span className="form-text form-error">
              Enter a valid phone number
            </span>
          )}
        </div>
        <div className="button-group mt-4">
          <StylishNewButton
            className={'button--primary'}
            type="submit"
            disabled={disabled}
          >
            Save
          </StylishNewButton>
        </div>
      </div>
    </form>
  );
}
