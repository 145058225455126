import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import { fetchIcsIncidentHospitals } from './ics_incidentHospitalsSlice';

import { getUserGuid, getGroupGuid } from 'slices/sliceHelpers';

// Slice
const ics_hospitalsSlice = createSlice({
    name: 'ics_hospitals',
    initialState: {
        ics_hospitals: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        createIcsHospitalSuccess: (state, action) => {
            state.ics_hospitals.push(action.payload);
        },
        fetchIcsHospitalsSuccess: (state, action) => {
            state.ics_hospitals = action.payload;
        },
        updateIcsHospitalSuccess: (state, action) => {
            const index = state.ics_hospitals.findIndex((hospital) => hospital.id === action.payload.id);
            state.ics_hospitals[index] = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    createIcsHospitalSuccess,
    fetchIcsHospitalsSuccess,
    updateIcsHospitalSuccess,
    setError
} = ics_hospitalsSlice.actions;

export default ics_hospitalsSlice.reducer;

export const createIcsHospital = (data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_hospitals}`, { ...data, user_guid });
        if (data.incident_id) {
            dispatch(fetchIcsIncidentHospitals(data.incident_id));
        }
        dispatch(fetchIcsHospitals(group_guid));
        dispatch(createIcsHospitalSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsHospitals = (group_guid) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_hospitals}/${group_guid}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsHospitalsSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsHospital = (id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_hospitals}/${id}`, { ...data, user_guid });
        dispatch(fetchIcsHospitals(group_guid));
        dispatch(updateIcsHospitalSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
