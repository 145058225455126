import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import SearchBar from 'PREPAREsrc/component/SearchBar/SearchBar';
import {
  getAbbreviationName,
  getProcessStability,
  getTaskContentColor,
  getTaskStatusColor,
} from './TaskManagementUtil';
import { MdChevronRight } from 'react-icons/md';
import ReactPaginate from 'react-paginate';
import {
  RTO_TIME_FRAME_OPTIONS,
  TASK_COLOR_TAB_DATA,
} from './TaskManagementConstant';

export default function TaskManagementTimelineList(props) {
  const {
    tasks,
    selectedColorTab = TASK_COLOR_TAB_DATA[0].value,
    onSelectTask,
    onPageChange,
    page,
    totalTasks,
  } = props;
  const rostersForGroup = useSelector((state) => {
    return state.app.rostersForGroups || [];
  });
  const teamList = useSelector((state) => {
    return state.app.distributionListItems;
  });
  const user = useSelector((state) => state.prepare.user);

  const getAssigneeName = (task) => {
    let res = '';
    if (task?.process_owner?.length > 0 && rostersForGroup?.length > 0) {
      const person = task?.process_owner[0];
      if (person?.user_name) {
        res = getAbbreviationName(person?.user_name);
      }
    }

    return res;
  };

  const getReporterName = (task) => {
    let res = '';
    if (task?.reporter_member_guid && rostersForGroup?.length > 0) {
      const person = rostersForGroup.find(
        (member) => member.user_guid === task.reporter_member_guid
      );
      if (person?.user_name) {
        res = getAbbreviationName(person?.user_name);
      }
    }

    return res;
  };

  const getReporterTeamName = (task) => {
    let res = '';
    if (task?.reporter_team_guid && rostersForGroup?.length > 0) {
      const team = teamList.find((team) => team.id === task.reporter_team_guid);
      if (team?.name) {
        res = team?.name;
      }
    }

    return res;
  };

  const getAssigneeTeamName = (task) => {
    let res = '';
    if (task?.assignee_team_guid && rostersForGroup?.length > 0) {
      const team = teamList.find((team) => team.id === task.assignee_team_guid);
      if (team?.name) {
        res = team?.name;
      }
    }

    return res;
  };

  return (
    <>
      <div className="row mt-4">
        <div className="col-12">
          {tasks?.length > 0 &&
            tasks.map((task, index) => {
              let stability = null;
              let bgColor = getTaskStatusColor(task.status);
              let statusLabel = task.status;
              if (selectedColorTab === TASK_COLOR_TAB_DATA[1].value) {
                const rtoTimeFrameObj = RTO_TIME_FRAME_OPTIONS.find(
                  (frame) => frame.value === task?.rto_time_frame
                );
                stability = getProcessStability(
                  task?.status,
                  task?.deadline_date,
                  task?.restoration_status,
                  parseInt(task?.rto),
                  rtoTimeFrameObj,
                  task?.is_critical
                );
                bgColor = stability?.color;
                statusLabel = stability?.label;
              }
              return (
                <div className="row mt-3" key={'task-' + index}>
                  <div className="col-xl-3 col-lg-2 col-12 d-none d-lg-flex">
                    <div className="d-flex position-relative ms-auto">
                      <div className="task-date d-none d-xl-flex">
                        {moment(task.created_at).format('MMM D, YYYY')}
                      </div>
                      <div
                        className="task-status-badge-circle d-none d-xl-flex"
                        style={{ borderColor: bgColor }}
                      >
                        {index !== tasks.length - 1 && (
                          <div className="task-status-badge-circle-bar "></div>
                        )}
                      </div>
                      <div className="d-flex-column">
                        <div
                          className="task-status-badge mx-2"
                          style={{ background: bgColor }}
                        >
                          {statusLabel}
                        </div>
                        <div className="d-xl-none d-flex">
                          <div className="task-date mt-2 ms-auto">
                            {moment(task.created_at).format('MMM D, YYYY')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-5 col-md-6 col-12 mt-2 mt-md-0">
                    <div
                      className="task-card cursor-pointer"
                      style={{ background: bgColor }}
                      onClick={() => onSelectTask(task)}
                    >
                      <div className="d-flex justify-content-between">
                        <span className="task-card-content">{task.title}</span>
                        <span className="task-person-name">
                          {getReporterName(task)}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <span>
                          {user.timeFormat === 'hour12Mode'
                            ? moment(task.created_at).format(
                                'MMM D YYYY @ hh:mm A'
                              )
                            : moment(task.created_at).format(
                                'MMM D YYYY @ HHMM'
                              )}
                        </span>
                        <div className="task-team-name-container">
                          <span className="task-team-name">
                            {getReporterTeamName(task)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-1 col-lg-1 task-card-right-icon d-none d-xl-flex">
                    <MdChevronRight style={{ fontSize: '24px' }} />
                  </div>
                  <div className="col-xl-4 col-lg-5 col-md-6 col-12 mt-2 mt-md-0">
                    <div
                      className="task-card"
                      style={{ background: getTaskContentColor(task) }}
                    >
                      <div className="d-flex justify-content-between">
                        <span className="task-card-content-three-lines">
                          {task.content}
                        </span>
                        <div className="d-flex-column">
                          <span className="task-person-name ms-auto">
                            {getAssigneeName(task)}
                          </span>
                          <div className="task-team-name-container mt-2">
                            <span className="task-team-name">
                              {getAssigneeTeamName(task)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          <div className="d-flex justify-content-center justify-conte nt-md-end mt-4">
            <ReactPaginate
              className="pagination"
              breakLabel="..."
              nextLabel="Next"
              onPageChange={({ selected }) => onPageChange(selected + 1)}
              pageRangeDisplayed={3}
              pageCount={Math.ceil(totalTasks / 50)}
              previousLabel="Prev"
              forcePage={page - 1}
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
    </>
  );
}
