import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '../../../slices/commonSelectors';
import {
  DiceNotification,
  getEmailRecipients,
  getGroupRecipients,
  getSmsRecipients,
  getTtsRecipients,
} from '../generateNotificationEntity';
import { useAors } from './notificationJobHooks';
import { Card, Row } from 'react-bootstrap';
import React from 'react';
import { FormFields } from './AddNotificationJobDrawer';

export function PreviewAndSendStep() {
  const {
    watch,
    formState: { errors },
  } = useFormContext<FormFields>();

  const formData = watch();
  const templateId = watch('template');
  const templates = useAppSelector((s) => s.app.notifications);
  const groupRoster = useAppSelector((s) => s.app.rostersForGroups);
  const template: DiceNotification = templates?.find(
    (t: any) => t.notification_id === templateId,
  );

  const aorQuery = useAors();
  const aor = aorQuery.data?.find((a) => a.id === formData.aor);

  return (
    <Card bg={'inverse'} className={'p-5'}>
      <Row className={'py-3'}>
        <div className="txt--sml txt--bold pb-1">Short Description</div>
        <div className="txt--sml text-weak">
          {formData.short_description ?? 'No Description Given'}
        </div>
      </Row>
      <Row className={'py-3'}>
        <div className="txt--sml txt--bold pb-1">Alert Type</div>
        <div className="txt--sml text-weak">{`Wind Risk Alert (${formData.threshold} mph)`}</div>
        <div className="txt--sml text-weak">{`Location set to ${aor?.name}`}</div>
      </Row>
      {template ? (
        <>
          <Row className={'py-3'}>
            <div className="txt--sml txt--bold pb-1">Chosen Template</div>
            <div className="txt--sml text-weak">{template?.template_title}</div>
          </Row>
          <Row className={'py-3'}>
            <div className="txt--sml txt--bold pb-1">Recipients</div>
            <div className="txt--sml text-weak">
              {getEmailRecipients(template)}
            </div>
            <div className="txt--sml text-weak">
              {getSmsRecipients(template)}
            </div>
            <div className="txt--sml text-weak">
              {getTtsRecipients(template)}
            </div>
            <div className="txt--sml text-weak">
              {getGroupRecipients(template, groupRoster)}
            </div>
          </Row>
          <Row className={'py-3'}>
            <div className="txt--sml txt--bold pb-1">Message</div>
            <div className="txt--sml text-weak">
              {template?.message ?? 'No Email Message'}
            </div>
            <div className="txt--sml text-weak">
              {template?.settings.recipientSettings.SMSRecipientSettings
                .SMSMessage ?? 'No SMS Message'}
            </div>
            <div className="txt--sml text-weak">
              {template?.settings.recipientSettings.TTSRecipientSettings
                .TTSMessage ?? 'No TTS Message'}
            </div>
          </Row>
        </>
      ) : (
        <Row className={'py-3'}>
          <div className="txt--sml txt--bold pb-1">Recipients</div>
          <div className="txt--sml text-weak">
            {formData.email && `Email: ${formData.email}`}
          </div>
          <div className="txt--sml text-weak">
            {formData.sms && `SMS: ${formData.sms}`}
          </div>
        </Row>
      )}
    </Card>
  );
}