import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import DrawerWrapper from './DrawerWrapper';
import { ResourceSet, useFetchResourceSetUsage } from './hooks/resourceSetHooks';
import TimeSeriesChart from './analytics/TimeSeriesChart';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { EditEntityDrawerProps } from './EntityListProps';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc); // Extend dayjs with UTC plugin

// Define the type for individual time series data points
interface TimeSeriesDataPoint {
  timestamp: string;
  quantity: number;
  kind: string;
  name: string;
  resource_site_id: string;
}

// Function to prepare the time series data
const prepareTimeSeries = (timeSeries: TimeSeriesDataPoint[]): TimeSeriesDataPoint[] => {
  return timeSeries.map(item => ({
    timestamp: item.timestamp,
    quantity: item.quantity,
    kind: item.kind,
    name: item.name,
    resource_site_id: item.resource_site_id,
  }));
};

const aggregateQuantities = (timeSeriesData: TimeSeriesDataPoint[][]): TimeSeriesDataPoint[] => {
  // Check if timeSeriesData is valid and contains at least one non-empty series
  if (!timeSeriesData || timeSeriesData.length === 0 || !timeSeriesData[0] || timeSeriesData[0].length === 0) {
    return [];
  }

  const allTimestamps: Set<string> = new Set();
  const cumulativeTotals: Record<string, number> = {};

  // Collect all unique timestamps from the data
  timeSeriesData.forEach((series: TimeSeriesDataPoint[]) => {
    series.forEach((item: TimeSeriesDataPoint) => {
      allTimestamps.add(item.timestamp);
    });
  });

  // Convert the set to a sorted array
  const sortedTimestamps = Array.from(allTimestamps).sort();

  let totalQuantity = 0;

  // Iterate over each timestamp in the sorted array
  return sortedTimestamps.map(timestamp => {
    timeSeriesData.forEach((series: TimeSeriesDataPoint[]) => {
      // Find the item in the series that matches the current timestamp
      const currentIndex = series.findIndex(item => item.timestamp === timestamp);
      if (currentIndex !== -1) {
        const currentQuantity = series[currentIndex].quantity;
        const previousQuantity = currentIndex > 0 ? series[currentIndex - 1].quantity : 0;

        // Adjust the total by the difference between the current and previous quantity
        totalQuantity += (currentQuantity - previousQuantity);
      }
    });

    return {
      timestamp,
      quantity: totalQuantity,
      kind: timeSeriesData[0][0].kind, // Safely access kind, assuming all series are of the same kind
      name: 'Total', // Use "Total" as the name for the aggregated series
      resource_site_id: timeSeriesData[0][0].resource_site_id, // Safely access resource_site_id
    };
  });
};

const calculateBurnRate = (
  series: TimeSeriesDataPoint[],
  startTimestamp: string,
  endTimestamp: string
): number => {
  if (series.length === 0) return 0;

  let startPoint: TimeSeriesDataPoint | null = null;
  let endPoint: TimeSeriesDataPoint | null = null;

  for (let i = series.length - 1; i >= 0; i--) {
    const itemTime = dayjs.utc(series[i].timestamp);
    if (!startPoint && (itemTime.isBefore(startTimestamp) || itemTime.isSame(startTimestamp))) {
      startPoint = series[i];
    }
    if (!endPoint && (itemTime.isBefore(endTimestamp) || itemTime.isSame(endTimestamp))) {
      endPoint = series[i];
    }
    if (startPoint && endPoint) break;
  }

  if (startPoint && endPoint) {
    return endPoint.quantity - startPoint.quantity;
  }

  return 0;
};

const ResourceSetUsageDrawer: React.FC<EditEntityDrawerProps<ResourceSet>> = ({
  toggle,
  selectedEntity: editedEntity,
}) => {
  const { data: resourceSetUsage } = useFetchResourceSetUsage(editedEntity?.resource_set_id);

  // Extract the metadata and site name
  const siteName = resourceSetUsage?.site_name || 'Unknown Site';
  const selectedUsageSeries = resourceSetUsage?.res_set_usage?.length
    ? {
        name: `${resourceSetUsage.res_set_usage[resourceSetUsage.res_set_usage.length - 1].kind} - (${resourceSetUsage.res_set_usage[resourceSetUsage.res_set_usage.length - 1].name})`,
        data: prepareTimeSeries(resourceSetUsage.res_set_usage),
      }
    : { name: '', data: [] };

  const aggregatedSeries = resourceSetUsage?.res_set_total_usage?.length
    ? {
        name: `Total ${resourceSetUsage.res_set_usage[resourceSetUsage.res_set_usage.length - 1].kind}`,
        data: aggregateQuantities(resourceSetUsage.res_set_total_usage),
      }
    : { name: 'Total', data: [] };

  const perSiteAggregatedSeries = {
    name: `Total Per Site (${siteName})`,
    data: aggregateQuantities(
      resourceSetUsage?.res_set_total_usage?.map((series: TimeSeriesDataPoint[]) => {
        // Check if the last item in the series matches the resource_set_id
        const lastItem = series[series.length - 1];
        if (lastItem && lastItem.resource_site_id === editedEntity?.resource_site_id) {
          return series; // Return the full array if it matches
        }
        return []; // Return an empty array if it doesn't match
      }).filter((series: TimeSeriesDataPoint[]) => series.length > 0) || [] // Filter out any empty arrays
    ),
  };

  const [startTimestamp, setStartTimestamp] = useState<Date | null>(dayjs.utc().subtract(24, 'hours').toDate());
  const [endTimestamp, setEndTimestamp] = useState<Date | null>(dayjs.utc().toDate());

  const isInvalidDateRange = endTimestamp && startTimestamp && dayjs.utc(endTimestamp).isBefore(dayjs.utc(startTimestamp));

  const burnRate = calculateBurnRate(
    selectedUsageSeries.data,
    dayjs.utc(startTimestamp).toISOString(),
    dayjs.utc(endTimestamp).toISOString()
  );

  const burnRatePerSite = calculateBurnRate(
    perSiteAggregatedSeries.data,
    dayjs.utc(startTimestamp).toISOString(),
    dayjs.utc(endTimestamp).toISOString()
  );

  const burnRateTotal = calculateBurnRate(
    aggregatedSeries.data,
    dayjs.utc(startTimestamp).toISOString(),
    dayjs.utc(endTimestamp).toISOString()
  );

  const dataSeries = [selectedUsageSeries, perSiteAggregatedSeries, aggregatedSeries];

  return (
    <DrawerWrapper toggle={toggle} title={`Resource Set Usage: ${selectedUsageSeries?.name}`}>
      <Row>
        <Col>
          <div>
            <strong>Burn Rate: (Selected):</strong> {burnRate > 0 ? `+${burnRate}` : burnRate}
          </div>
          <div>
            <strong>Burn Rate Per Site ({siteName}):</strong> {burnRatePerSite > 0 ? `+${burnRatePerSite}` : burnRatePerSite}
          </div>
          <div>
            <strong>Burn Rate Total {resourceSetUsage?.name} :</strong> {burnRateTotal > 0 ? `+${burnRateTotal}` : burnRateTotal}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
            <div>
              <StylishDateTimePicker
                value={startTimestamp}
                onChange={(date) => setStartTimestamp(date)}
                type="datetime-local"
                dateLabel="Burn Rate Start Time"
              />
            </div>
            <div>
              <StylishDateTimePicker
                value={endTimestamp}
                onChange={(date) => setEndTimestamp(date)}
                type="datetime-local"
                dateLabel="Burn Rate End Time"
              />
            </div>
          </div>
          {isInvalidDateRange && (
            <div style={{ color: 'red', marginBottom: '1rem' }}>
              Warning: The start date must be before the end date to calculate the burn rate.
            </div>
          )}
          <TimeSeriesChart 
            title={`Usage: ${selectedUsageSeries.name}`} 
            dataSeries={dataSeries} 
            currentTimestamp={dayjs.utc(endTimestamp).toISOString()}
          />
        </Col>
      </Row>
    </DrawerWrapper>
  );
};

export default ResourceSetUsageDrawer;
