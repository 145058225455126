import { useActualIncidentId } from './commonHooks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { selectGroupGuid } from '../../../slices/commonSelectors';
import { useSelector } from 'react-redux';
import { AVAILABLE_ROLES } from '../../IncidentConfig/AvailableRoles';


/** TODO: update this type obviously. */
export type RosterEntry = {
  incident_member_id: 'f74c0c2d-dfdc-4019-95ce-8e7dbce1f753';
  incident_id: 'b10b55b8-72eb-4264-89cf-c1a6031284a2';
  user_guid: '678e9d3f-82fb-4d09-bd6e-a4fff8c8b997';
  role: 'Admin';
  active: 0;
  exited: 0;
  seat: null;
  incident_seat: 'Planning Section Chief';
  active_time: null;
  minutes_worked: '0.00';
  seat_name: null;
  seat_description: null;
  email_address: 'patrickr@disastertech.com';
  profile_settings: {
    is_new_user: 'true';
    userName: 'Patrick Ryan';
    mobile_phone_number: '';
    home_phone_number: '';
    pager_address: '';
  };
  user_name: 'Patrick Ryan';
  allow_admin_profile_edits: true;
  relevant_team_memberships: [
    {
      name: 'Leadership';
    }
  ];
};

export type IncidentRoster = RosterEntry[];

export const useIncidentRoster = () => {
  const incidentId = useActualIncidentId();

  return useQuery({
    queryKey: ['incidentRoster', incidentId],
    queryFn: async () => {
      return axios<IncidentRoster>(config.apiGateway.getRosterForIncident, {
        method: 'POST',
        data: {
          incident_id: incidentId,
        },
      }).then((resp) => resp.data ?? []);
    },
  });
};

type RosterRequest = {
  incident_member_id: UUID;
  user_guid: UUID;
  seat: typeof AVAILABLE_ROLES[number];
};

export const ussAddRosterEntries = () => {
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  const group_guid = useSelector(selectGroupGuid);
  return useMutation({
    mutationFn: async (data: RosterRequest[]) => {
      return axios.post(config.apiGateway.addUserToIncident, {
        incident_id: incidentId,
        group_guid,
        incidentMembers: data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['incidentRoster', incidentId],
      });
    },
  });
};
