export const getInitials = (name: string, limit = 3) => {
  const words = name.split(' ');
  if (words.length === 1) {
    return words[0].slice(0, 3).toUpperCase();
  } else {
    return words
      .map((word) => word[0])
      .join('')
      .slice(0, limit)
      .toUpperCase();
  }
}