import {
  IncidentTactic,
  useIncidentTactics,
} from './hooks/incidentTacticHooks';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import EntityList from './EntityList';
import { useToggle } from 'react-use';
import { Card } from 'react-bootstrap';
import { useState } from 'react';
import classNames from 'classnames';
import TacticAddDrawer from './TacticAddDrawer';
import TacticEditDrawer from './TacticEditDrawer';

import {
  MdEdit,
} from 'react-icons/md';

const CollapsibleTacticsList: React.FC = () => {
  const [expanded, toggleExpanded] = useToggle(false);
  const [editOpen, toggleEditDrawer] = useToggle(false);
  const [addOpen, toggleAddDrawer] = useToggle(false);
  const [
    selectedEntity,
    setSelectedEntity,
  ] = useState<IncidentTactic | null>(null);

  const { data: incidentTactics } = useIncidentTactics();

  const selectElement = (element: IncidentTactic) => {
    setSelectedEntity(element);
    toggleEditDrawer();
  };

  return (
    <>
      <EntityList
        entities={incidentTactics}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        header={
          <div className="d-flex align-items-center justify-content-between flex-grow-1">
            <span>Incident Tactics (201, 202)</span>
            <StylishNewButton
              className={classNames('button', {
                'button--primary': expanded,
                'button--secondary': !expanded,
              })}
              onClick={toggleAddDrawer}
            >
              <SharedIcon iconName="add" classes="me-2" />
              Add
            </StylishNewButton>
          </div>
        }
        renderEntity={(element) => (
          <Card
            key={element.id}
            className="bg-neutral-800 my-1 rounded-1 flex-row align-items-center justify-content-between"
            style={{
              padding: '0.625rem 1rem 0.625rem 1rem',
            }}
          >
            <span>{element.name}</span>
            <StylishNewButton
              className="button button--link"
              onClick={() => selectElement(element)}
            >
              <MdEdit />
            </StylishNewButton>
          </Card>
        )}
      ></EntityList>
      {addOpen ? <TacticAddDrawer toggle={toggleAddDrawer} /> : null}
      {editOpen && selectedEntity ? (
        <TacticEditDrawer
          toggle={toggleEditDrawer}
          selectedEntity={selectedEntity}
        />
      ) : null}
    </>
  );
};

export default CollapsibleTacticsList;