export const VALIDATE_RBAC_PERMISSION_FOR_ACTION_REQUEST = "VALIDATE_RBAC_PERMISSION_FOR_ACTION_REQUEST";
export const VALIDATE_RBAC_PERMISSION_FOR_ACTION_SUCCESS = "VALIDATE_RBAC_PERMISSION_FOR_ACTION_SUCCESS";
export const VALIDATE_RBAC_PERMISSION_FOR_ACTION_ERROR = "VALIDATE_RBAC_PERMISSION_FOR_ACTION_ERROR";
export const VALIDATE_USER_IN_GROUPS_REQUEST = "VALIDATE_USER_IN_GROUPS_REQUEST";
export const VALIDATE_USER_IN_GROUPS_SUCCESS = "VALIDATE_USER_IN_GROUPS_SUCCESS";
export const VALIDATE_USER_IN_GROUPS_ERROR = "VALIDATE_USER_IN_GROUPS_ERROR";
export const VALIDATE_USER_IN_GROUP_WITH_ROLE_REQUEST = "VALIDATE_USER_IN_GROUP_WITH_ROLE_REQUEST";
export const VALIDATE_USER_IN_GROUP_WITH_ROLE_SUCCESS = "VALIDATE_USER_IN_GROUP_WITH_ROLE_SUCCESS";
export const VALIDATE_USER_IN_GROUP_WITH_ROLE_ERROR = "VALIDATE_USER_IN_GROUP_WITH_ROLE_ERROR";
export const VALIDATE_USER_SUBSCRIPTION_REQUEST = "VALIDATE_USER_SUBSCRIPTION_REQUEST";
export const VALIDATE_USER_SUBSCRIPTION_SUCCESS = "VALIDATE_USER_SUBSCRIPTION_SUCCESS";
export const VALIDATE_USER_SUBSCRIPTION_ERROR = "VALIDATE_USER_SUBSCRIPTION_ERROR";
export const VALIDATE_USER_IN_INCIDENT_REQUEST = "VALIDATE_USER_IN_INCIDENT_REQUEST";
export const VALIDATE_USER_IN_INCIDENT_SUCCESS = "VALIDATE_USER_IN_INCIDENT_SUCCESS";
export const VALIDATE_USER_IN_INCIDENT_ERROR = "VALIDATE_USER_IN_INCIDENT_ERROR";
export const VALIDATE_USER_INCIDENT_ADMIN_REQUEST = "VALIDATE_USER_INCIDENT_ADMIN_REQUEST";
export const VALIDATE_USER_INCIDENT_ADMIN_SUCCESS = "VALIDATE_USER_INCIDENT_ADMIN_SUCCESS";
export const VALIDATE_USER_INCIDENT_ADMIN_ERROR = "VALIDATE_USER_INCIDENT_ADMIN_ERROR";
export const VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_REQUEST = "VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_REQUEST";
export const VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_SUCCESS = "VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_SUCCESS";
export const VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_ERROR = "VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_ERROR";
export const VALIDATE_USER_IN_POD_SECURITY_POLICY_REQUEST = "VALIDATE_USER_IN_POD_SECURITY_POLICY_REQUEST";
export const VALIDATE_USER_IN_POD_SECURITY_POLICY_SUCCESS = "VALIDATE_USER_IN_POD_SECURITY_POLICY_SUCCESS";
export const VALIDATE_USER_IN_POD_SECURITY_POLICY_ERROR = "VALIDATE_USER_IN_POD_SECURITY_POLICY_ERROR";