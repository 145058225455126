import React, { useEffect, useState } from 'react';

import { deleteNestedCategory } from 'actions/locationAction';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import NestedCategoryAddUpdateModal from './NestedCategoryAddUpdateModal';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const Label = ({ label, value, onEdit, onDelete }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flexGrow: '1' }}>{label}</div>
      <div className="react-select__custom-label">
        <span
          className="ms-3"
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
        >
          <SharedIcon iconName="stylus" />
        </span>
        <span
          className="ms-3"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <SharedIcon iconName="delete" />
        </span>
      </div>
    </div>
  );
};

export default function EditCategoriesModal({
  show,
  onClose,
  categories,
}) {
  const reduxDispatch = useDispatch();

  const allColumn = [
    {
      dataField: 'category_name',
      text: 'Category',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Category' },
    },
  ];

  const [formattedCategories, setFormattedCategories] = useState([])
  const [showCategoryDeleteModal, setShowCategoryDeleteModal] = useState(false);
  const [categoryForDeletion, setCategoryForDeletion] = useState();
  const [showCategoryAddUpdateModal, setShowCategoryAddUpdateModal] = useState();
  const [categoryModalMode, setCategoryModalMode] = useState();
  const [updateCategoryData, setUpdateCategoryData] = useState(null);

  useEffect(() => {
    setFormattedCategories(categories.map(category => {
      return {
      ...category,
      category_name: <Label
        label={category.category_name}
        onEdit={() => onEditCategory(category)}
        onDelete={() => onDeleteCategory(category)}
        />
    }}))
  }, [categories])

  const onEditCategory = (category) => {
    setCategoryModalMode("Edit")
    setUpdateCategoryData(category)
    setShowCategoryAddUpdateModal(true)
  }
    
  const onCloseConfirmDialog = () => {
    setCategoryForDeletion()
    setShowCategoryDeleteModal(false);
  };

  const onDeleteCategory = (category) => {
    setCategoryForDeletion(category)
    setShowCategoryDeleteModal(true)
  }

  const onConfirmDeleteCategory = category => {
    reduxDispatch(deleteNestedCategory(categoryForDeletion))
    setCategoryForDeletion()
    setShowCategoryDeleteModal(false)
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>
              {`Edit Categories`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <StylishNewTable
              keyField={'id'}
              columns={allColumn}
              rows={formattedCategories}
              // expandRow={expandRow}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton 
                className="button--primary"
                onClick={() => {
                  setCategoryModalMode("New")
                  setShowCategoryAddUpdateModal(true)
                  setUpdateCategoryData(null)
                }}
              >
                Add Category
              </StylishNewButton>
            </div>
          </Modal.Footer>
      </Modal>
      {showCategoryDeleteModal && (
          <StylishConfirmDialog
              show={showCategoryDeleteModal}
              dialogTitle={`Delete Category "${categoryForDeletion.category_name}"?`}
              dialogContent={`Are you sure you want to delete Category "${categoryForDeletion.category_name}"?`}
              onClose={onCloseConfirmDialog}
              onConfirm={onConfirmDeleteCategory}
          />
      )}
      {showCategoryAddUpdateModal && (
        <NestedCategoryAddUpdateModal
          mode={categoryModalMode}
          show={showCategoryAddUpdateModal}
          onClose={() => {
            setCategoryModalMode()
            setShowCategoryAddUpdateModal(false)}
          }
          nestedCategories={categories}
          updateCategoryData={updateCategoryData}
        />
      )}
    </>
  );
}
