import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

/**
 * TODO: This component uses dangerouslySetInnerHTML instead of some other pattern of rendering HTML content.
 *        Technically it never renders user input, but still should be refactored to avoid future XSS attacks.
 * */
export default function Help({ classes, title, content, link }) {
  return (
    <>
      <Dropdown className={'help ' + classes}>
        <Dropdown.Toggle className="button--icon">
          <SharedIcon iconName="question_mark" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <h5>{title}</h5>
          <hr className="dashed w-25 thin mt-3 mb-3" />
          <div
            className="txt--sml"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          <a
            className="button button--primary button--sml mt-4"
            href={link}
            target="_blank"
          >
            More Help
          </a>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
