import React, { useEffect, useState } from 'react';
import { upsertBranch } from 'actions/IAPForm204Actions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import DrawerWrapper, { DrawerFooter } from '../DrawerWrapper';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import generateUUID from 'utils/sharedUtils/generateUUID';

export default function AddUpdateBranchDrawer({
  show,
  onClose,
  selectedBranch,
  onBranchSelect,
}) {
  const [currentGroupUser, setCurrentGroupUser] = useState([]);
  const reduxDispatch = useDispatch();
  const reduxRostersForGroups = useSelector((state) => state.app.rostersForGroups || []);
  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);

  const { handleSubmit, formState: { errors, isValid }, control, setValue } = useForm({
    mode: 'onChange',
    defaultValues: { branch_name: !!selectedBranch ? selectedBranch.name : '' },
  });

  const onSubmit = async (data) => {
    const branch = {
      name: data.branch_name,
      id: !!selectedBranch ? selectedBranch.id : generateUUID(),
      branch_director: data.branch_director.value?.user_guid,
    };
    try {
      reduxDispatch(startLoading());
      await reduxDispatch(upsertBranch(branch));
      onBranchSelect(branch);  // Trigger branch selection after saving
      onClose();
    } catch (e) {
      console.log("adderr?",e);
    } finally {
      reduxDispatch(endLoading());
    }
  };

  useEffect(() => {
    if (!!reduxCurrentlySelectedGroup && reduxRostersForGroups.length > 0) {
      const currentGroupUsers = reduxRostersForGroups
        .filter(user => user.group_guid === reduxCurrentlySelectedGroup.group_guid)
        .map(user => ({
          value: user,
          label: user.profile_settings?.userName || user.email_address,
        }));
      setCurrentGroupUser(currentGroupUsers);
    }
  }, [reduxCurrentlySelectedGroup, reduxRostersForGroups]);

  useEffect(() => {
    if (selectedBranch) {
      setValue('branch_name', selectedBranch?.name?.trim());
      setValue(
        'branch_director',
        currentGroupUser.find(cgu => cgu.value.user_guid === selectedBranch?.branch_director)
      );
    }
  }, [selectedBranch, currentGroupUser, setValue]);

  console.log("currentGroupUser",currentGroupUser,reduxRostersForGroups)

  return (
    <DrawerWrapper toggle={onClose} title="Create Branch" show={show}>
      <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column flex-grow-1">
        <div className="flex-grow-1">
          <div className="mb-3 pt-1">
            <label className="form-label">Name:</label>
            <Controller
              control={control}
              name="branch_name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput onChange={onChange} value={value} />
              )}
            />
            {errors?.branch_name && (
              <span className="form-text form-error">
                This field is required.
              </span>
            )}
          </div>
          <div className="mb-3 pt-1">
            <label className="form-label">Branch Director:</label>
            <Controller
              control={control}
              name="branch_director"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={currentGroupUser}
                  value={value}
                  placeholder="Select a Branch Director"
                  onChange={onChange}
                  isDisabled={false}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={false}
                />
              )}
            />
            {errors?.branch_director && (
              <span className="form-text form-error">
                This field is required.
              </span>
            )}
          </div>
        </div>
        <DrawerFooter>
          <div className="button-group">
            <StylishNewButton
              type="button"
              className="button--secondary button--reverse"
              onClick={onClose}
            >
              Cancel
            </StylishNewButton>
            <StylishNewButton type="submit" className="button--primary" disabled={!isValid}>
              Save
            </StylishNewButton>
          </div>
        </DrawerFooter>
      </form>
    </DrawerWrapper>
  );
}
