import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentIncidentById } from 'actions/diceActions';
import { useNavigate } from 'react-router-dom';
import { fetchIncidentsForGroup } from 'actions/roleActions';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import NewIncidentDialog from 'components/IncidentConfig/NewIncidentDialog';

export default function GroupIncidents() {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const reduxIncidentsForGroup = useSelector((state) => {
    const incidents = state.app.incidentsForGroup;
    return incidents;
  });

  const [newIncidentDialogActive, setNewIncidentDialogActive] = useState(false);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (!!reduxCurrentlySelectedGroup) {
      reduxDispatch(
        fetchIncidentsForGroup(reduxCurrentlySelectedGroup.group_guid)
      );
    }
  }, [reduxCurrentlySelectedGroup, newIncidentDialogActive]);

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
    },
    {
      dataField: 'types',
      text: 'Type',
      sort: true,
      attrs: { title: 'Type' },
    },
    {
      dataField: 'severity',
      text: 'Severity',
      sort: true,
      attrs: { title: 'Severity' },
    },
  ];

  const handleIncidentLinkClick = (incidentId) => {
    reduxDispatch(updateCurrentIncidentById(incidentId));
    navigate(`/iap/${incidentId}/settings`);
  };

  function onNewIncidentDialogClose() {
    reduxDispatch(
      fetchIncidentsForGroup(reduxCurrentlySelectedGroup.group_guid)
    );
    setNewIncidentDialogActive(false);
  }

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <StylishNewButton
            className="button--primary w-100 w-md-auto"
            type="button"
            onClick={() => handleIncidentLinkClick(row.incident_id)}
          >
            Configure Incident
          </StylishNewButton>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  return (
    <>
      <div className="d-md-flex align-items-center mb-3">
        <h4 className="m-0">Organization Incidents</h4>
        <StylishNewButton
          className="button--primary mt-2 mt-md-0 ms-auto"
          onClick={() => setNewIncidentDialogActive(true)}
        >
          New Incident
        </StylishNewButton>
      </div>
      <div className="form-block">
        <StylishNewTable
          keyField={'incident_id'}
          columns={columns}
          rows={
            reduxIncidentsForGroup && !!reduxIncidentsForGroup.length
              ? reduxIncidentsForGroup.map((rig) => ({
                  ...rig,
                  types: rig?.incident_types?.join(', '),
                }))
              : []
          }
          expandRow={expandRow}
        />
      </div>
      {newIncidentDialogActive && (
        <NewIncidentDialog
          onClose={onNewIncidentDialogClose}
        />
      )}
    </>
  );
}
