import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../PREPAREsrc/service/api';
import Network from '../../PREPAREsrc/service/Network';
import { updateWorkSpaceListById } from '../../PREPAREsrc/store/workspaces/workspaces.action';
import { inputStringLength, toastConfig } from 'assets/data/config';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { fetchUserGroupsRolesIncidents } from 'actions/profileActions';
import {
  endLoading,
  startLoading,
} from '../../reducers/loading/loading.action';

const WorkspaceType = [
  {
    value: 'DOD',
    label: 'DOD',
  },
  {
    value: 'T&E',
    label: 'DOD T&E',
  },
  {
    value: 'HSEEP',
    label: 'HSEEP',
  },
];

export default function AddEditWorkspace({
  onClose,
  show,
  dialogType,
  updateDialogData,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, _] = useState(10);
  const [userToAdd, setUserToAdd] = useState([]);
  const [userToRemove, setUserToRemove] = useState([]);
  const [totalUserToAdd, setTotalUserToAdd] = useState(0);
  const [allGroupsForUser, setAllGroupsForUser] = useState([]);

  const dispatch = useDispatch();
  const featureFlags = useSelector((state) => state.app.featureFlags);
  const userGroupsRolesIncidents = useSelector((state) => {
    return state.app.userGroupsRolesIncidents;
  });

  const isFetchUserGroupsRolesIncidentsLoaded = useSelector((state) => {
    return state.app.isFetchUserGroupsRolesIncidentsLoaded;
  });
  const reduxUser = useSelector((state) => {
    return state.app.user;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const reduxRostersForTheGroup = useSelector((state) => {
    let rosters = state.app.rostersForGroups;
    if (Array.isArray(rosters) && rosters.length) {
      rosters = rosters
        .filter((member) => {
          return member.group_guid === reduxCurrentlySelectedGroup.group_guid;
        })
        .map((member) => {
          return {
            ...member,
            role_string: member.role_assignments.reduce((acc, item) => {
              acc = acc ? acc + ', ' + item.name : item.name;

              return acc;
            }, ''),
          };
        });
    }
    return rosters || [];
  });
  useEffect(() => {
    if (
      (!userGroupsRolesIncidents || userGroupsRolesIncidents.length === 0) &&
      !!isFetchUserGroupsRolesIncidentsLoaded &&
      !!reduxUser
    ) {
      dispatch(fetchUserGroupsRolesIncidents(reduxUser.user_guid));
    }
  }, [reduxUser]);

  const observer = useRef();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    control,
  } = useForm({ mode: 'onChange' });

  const WorkspaceStatus = [
    {
      value: 'Available',
      label: 'Available',
    },
    {
      value: 'Archived',
      label: 'Archived',
    },
  ];

  const lastRowRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  const columnsCurrentMember = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
    },
    { dataField: 'email', text: 'Email', attrs: { title: 'Email' } },
    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewButton
          className="button--action"
          type="button"
          onClick={(e) => removeuserfromcurrent(e, row)}
        >
          <i className="fa fa-minus" aria-hidden="true"></i>
        </StylishNewButton>
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const columnsAddMember = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
    },
    { dataField: 'email', text: 'Email', attrs: { title: 'Email' } },
    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <button
          className="button--action"
          type="button"
          onClick={(e) => addMembertoCurrent(e, row)}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
        </button>
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];
  useEffect(() => {
    if (!!userGroupsRolesIncidents && userGroupsRolesIncidents.length > 0) {
      setAllGroupsForUser(
        userGroupsRolesIncidents.map((item) => {
          return {
            label: item.group_name,
            value: item.group_guid,
          };
        })
      );
      if (dialogType === 'Edit' && !!updateDialogData) {
        const organization = userGroupsRolesIncidents.find(
          (item) => item.group_guid === updateDialogData.organizationId
        );
        setValue(
          'organization',
          organization
            ? { label: organization.group_name, value: organization.group_guid }
            : null
        );
      }
    } else {
      setAllGroupsForUser([]);
    }
  }, [userGroupsRolesIncidents]);

  useEffect(() => {
    if (dialogType === 'Edit') {
      setValue('ownerType', updateDialogData.ownertype);
      setValue('status', {
        label: updateDialogData.status,
        value: updateDialogData.status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogType]);

  useEffect(() => {
    if (!!reduxCurrentlySelectedGroup && dialogType === 'Add') {
      setValue('organization', {
        label: reduxCurrentlySelectedGroup.group_name,
        value: reduxCurrentlySelectedGroup.group_guid,
      });
    }
  }, [reduxCurrentlySelectedGroup, dialogType]);

  const getUserData = async () => {
    dispatch(startLoading());
    try {
      if (updateDialogData?.workspaceId) {
        const response = await Network.get(API.getWorkspacebyID, {
          workspaceId: updateDialogData?.workspaceId,
          search: searchTerm || '',
          page: 0, // || pageNumber,
          perpage: 1000, //pageNumber * perPage,
        });
        const userToAddTemp = response.data.response.dataset[0].userToAdd;
        setUserToAdd(userToAddTemp);

        setUserToRemove(response.data.response.dataset[0].users);
        setTotalUserToAdd(Number(response.data.response.count));
        setHasMore(perPage * pageNumber < Number(response.data.response.count));
      }
    } catch (error) {
      console.log(error, 'error');
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, pageNumber]);

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      const workspace = await Network.post(
        dialogType === 'Add' ? API.createWorkspace : API.updateWorkspace,
        {
          ...data,
          ownerType: !!updateDialogData ? data.ownerType : data.ownerType.value,
          status: data?.status?.value,
          workspaceId: updateDialogData?.workspaceId,
          organization: data?.organization?.value,
        }
      );
      onClose(true);
      toast.success(
        dialogType === 'Add'
          ? 'Workspace created successfully'
          : 'Workspace updated successfully',
        toastConfig
      );
      dispatch(
        updateWorkSpaceListById({
          ...workspace.data.response.dataset[0],
          workspaceId: workspace.data.response.dataset[0].id,
        })
      );
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const addMembertoCurrent = async (e, data) => {
    e.preventDefault();
    dispatch(startLoading());
    try {
      await Network.post(API.addUser, {
        workspaceId: updateDialogData?.workspaceId,
        userId: data?.id,
      });
      setUserToAdd((prevUserToAdd) => {
        const newUserToAdd = prevUserToAdd.filter(
          (user) => user.id !== data.id
        );
        return newUserToAdd;
      });
      setUserToRemove((prevUserToRemove) => {
        return [...prevUserToRemove, data];
      });
      setTotalUserToAdd(totalUserToAdd - 1);
      setHasMore(perPage * pageNumber < totalUserToAdd - 1);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const removeuserfromcurrent = async (e, data) => {
    e.preventDefault();
    dispatch(startLoading());
    try {
      await Network.post(API.removeuser, {
        workspaceId: updateDialogData?.workspaceId,
        userId: data?.id,
      });
      setUserToAdd((prevUserToAdd) => {
        return [...prevUserToAdd, data];
      });
      setUserToRemove((prevUserToRemove) => {
        const newUserToRemove = prevUserToRemove.filter(
          (user) => user.id !== data.id
        );
        return newUserToRemove;
      });
      setHasMore(perPage * pageNumber < totalUserToAdd + 1);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onChangeSearchTerm = (term) => {
    setTotalUserToAdd(0);
    setPageNumber(1);
    setSearchTerm(term);
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size={!!updateDialogData ? 'lg' : 'md'}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{dialogType} Workspace</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label className="form-label">Title</label>
                  <Controller
                    control={control}
                    name="displayName"
                    defaultValue={updateDialogData?.displayName}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="text"
                        onChange={onChange}
                        value={value}
                        maxLength={inputStringLength}
                        className="form-control"
                        placeholder="Title"
                      />
                    )}
                  />

                  {errors?.displayName && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
                <div className={'col-md-12 mb-3'}>
                  <label className="form-label">Sponsor Organization</label>
                  <div className="">
                    <Controller
                      control={control}
                      name="organization"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewSelect
                          options={allGroupsForUser}
                          value={value}
                          onChange={(e) => onChange(e)}
                          placeholder={'Select Sponsor Organization'}
                          isClearable={false}
                          isSearchable={true}
                          isMulti={false}
                        />
                      )}
                    />
                  </div>
                  {errors?.organization && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
                <div className="col-md-12 mb-3">
                  <label className="form-label">Short Title</label>
                  <Controller
                    control={control}
                    name="shortname"
                    defaultValue={updateDialogData?.shortName}
                    rules={{
                      required: true,
                      maxLength: 4,
                      pattern: /^[A-Za-z]*[A-Za-z][A-Za-z0-9-. _@#$%&*+]*$/,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="text"
                        onChange={onChange}
                        value={value}
                        className="form-control"
                        placeholder="e.g. ab12"
                        disabled={!!updateDialogData?.shortName}
                      />
                    )}
                  />
                  {errors?.shortname?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}

                  {errors?.shortname?.type === 'maxLength' && (
                    <span className="form-text form-error">
                      Short Name can only contain up to 4 characters.
                    </span>
                  )}
                  {errors?.shortname?.type === 'pattern' && (
                    <span className="form-text form-error">
                      Short Name cannot start with a number.
                    </span>
                  )}
                </div>
                {!!updateDialogData ? (
                  <div
                    className={!!updateDialogData ? 'col-md-6' : 'col-md-12'}
                  >
                    <label className="form-label">Type</label>
                    <div className="">
                      <Controller
                        control={control}
                        name="ownertype"
                        defaultValue={updateDialogData?.ownertype}
                        readOnly
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewInput
                            type="text"
                            onChange={onChange}
                            value={value}
                            className="form-control"
                            disabled
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={!!updateDialogData ? 'col-md-6' : 'col-md-12'}
                  >
                    <label className="form-label">Type</label>
                    <div className="">
                      <Controller
                        control={control}
                        name="ownerType"
                        rules={{ required: true }}
                        defaultValue={WorkspaceType[0]}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewSelect
                            options={WorkspaceType}
                            defaultValue={value}
                            value={value}
                            onChange={(e) => {
                              if (!e) {
                                setError('ownerType');
                              } else if (e.value) {
                                clearErrors('ownerType');
                              }
                              return onChange(e);
                            }}
                            // placeholder={"Select Workspace Type"}
                            isClearable={false}
                            isSearchable={true}
                            isMulti={false}
                            isDisabled={!!updateDialogData}
                          />
                        )}
                      />
                    </div>
                    {errors?.ownerType && (
                      <span className="form-text form-error">
                        This field is required
                      </span>
                    )}
                  </div>
                )}
                {!!updateDialogData && (
                  <>
                    <div
                      className={!!updateDialogData ? 'col-md-6' : 'col-md-12'}
                    >
                      <label className="form-label">Status</label>
                      <div className="">
                        <Controller
                          control={control}
                          name="status"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <StylishNewSelect
                              options={WorkspaceStatus}
                              defaultValue={value}
                              value={value}
                              onChange={(e) => {
                                if (!e) {
                                  setError('status');
                                } else if (e.value) {
                                  clearErrors('status');
                                }
                                return onChange(e);
                              }}
                              placeholder={'Select Workspace Status'}
                              isClearable={false}
                              isSearchable={true}
                              isMulti={false}
                              isDisabled={false}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="form-label d-block mt-5 mb-3">
                        Current members
                      </label>
                      <div className="mb-3">
                        <StylishNewTable
                          keyField={'id'}
                          columns={columnsCurrentMember}
                          rows={userToRemove}
                        />
                      </div>
                      <label className="form-label d-block mt-5 mb-3">
                        Add members
                      </label>
                      <div className="mb-3">
                        <StylishNewSearchBar
                          onChangeSearchTerm={onChangeSearchTerm}
                        />
                      </div>
                      <div className="table-scroll">
                        <StylishNewTable
                          keyField={'id'}
                          columns={columnsAddMember}
                          rows={userToAdd}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button button--secondary button--reverse"
                onClick={onClose}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button button--primary"
                type="submit"
              >
                {!!updateDialogData ? 'Update' : 'Add'}
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
