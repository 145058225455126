import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import {
  fetchIcsObjectives
} from 'slices/ics_objectivesSlice'

import {
    getUserGuid,
    getGroupGuid
} from 'slices/sliceHelpers'

// Slice
const ics_incident_objectivesSlice = createSlice({
    name: 'ics_incident_objectives',
    initialState: {
        ics_incident_objectives: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        fetchIcsIncidentObjectivesSuccess: (state, action) => {
            state.ics_incident_objectives = action.payload;
            state.status='succeeded'
        },
        createIcsIncidentObjectiveSuccess: (state, action) => {
            state.status='succeeded'
        },
        updateIcsIncidentObjectiveSuccess: (state, action) => {
            state.status='succeeded'
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    fetchIcsIncidentObjectivesSuccess,
    createIcsIncidentObjectiveSuccess,
    updateIcsIncidentObjectiveSuccess,
    setError
} = ics_incident_objectivesSlice.actions;

export default ics_incident_objectivesSlice.reducer;

export const createIcsIncidentObjective = (incidentId, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState)
    try {
        const response = await axios.post(`${config.apiGateway.ics_incident_objectives}/${incidentId}`, {...data,user_guid});
        dispatch(fetchIcsObjectives(group_guid))
        dispatch(fetchIcsIncidentObjectives(incidentId))
        dispatch(createIcsIncidentObjectiveSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsIncidentObjectives = (incidentId) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_incident_objectives}/${incidentId}`,{
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsIncidentObjectivesSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsIncidentObjective = (incidentId, id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState)
    try {
        const response = await axios.put(`${config.apiGateway.ics_incident_objectives}/${incidentId}/${id}`, {...data,user_guid});
        dispatch(fetchIcsObjectives(group_guid))
        dispatch(fetchIcsIncidentObjectives(incidentId))
        dispatch(updateIcsIncidentObjectiveSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};


