import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewAction,
  updateActionDataToGroup,
} from 'actions/IAPForm201Actions';
import generateUUID from 'utils/sharedUtils/generateUUID';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewChip } from 'components/DesignSystems/New/StylishNewChip';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';
import DivisionModal from '../Division/DivisionModal';
import BranchModal from '../Branch/BranchModal';
import { getAllBranch, getAllDivision } from 'actions/IAPForm204Actions';
import { fetchRostersForGroups } from 'actions/roleActions';
import NewResourcesDialog from 'components/IncidentConfig/NewResourcesDialog';
import NewObjectiveDialog from 'components/IncidentConfig/NewObjectiveDialog';
import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';
import { productionEnv } from 'constants/debug';
import { toastConfig } from 'assets/data/config';

export default function AddUpdateWorkAssignment({
  show,
  onClose,
  incidentResources,
  incidentObjectives,
  workAssignmentModalType,
  setActionModalType,
  updateActionData,
  setUpdateActionData,
  branch = null,
  division = null,
}) {
  const mapboxToken = window.env.MAPBOX_ACCESS_TOKEN;

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: 'onChange' });

  const [isLoaded, setIsLoaded] = useState(true);
  const [selectedObjectives, setSelectedObjectives] = useState([]);
  const [availableObjectives, setAvailableObjectives] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const [availableResources, setAvailableResources] = useState([]);
  const [forceRemoveResource, setForceRemoveResource] = useState([]);
  const [prevObjective, setPrevObjective] = useState([]);
  const [prevResource, setPrevResource] = useState([]);
  const [selectedTab, setSelectedTab] = useState('Planned Action');
  const [showBranchModal, setShowBranchModal] = useState(false);
  const [showDivisionModal, setShowDivisionModal] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [isNewResourcesDialogOpen, setIsNewResourcesDialogOpen] = useState(
    false
  );
  const [isNewObjectiveDialogOpen, setIsNewObjectiveDialogOpen] = useState(
    false
  );
  const [geoLocation, setGeoLocation] = useState(
    updateActionData?.required_geo_location
  );
  const reduxDispatch = useDispatch();

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const isActionLoaded = useSelector((state) => {
    return state.app.isActionLoaded;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const isAddActionLoaded = useSelector((state) => {
    return state.app.isAddActionLoaded;
  });

  const toggleData = [
    { label: 'Current Action', value: 'Current Action' },
    { label: 'Planned Action', value: 'Planned Action' },
  ];

  useEffect(() => {
    if (!isLoaded && isActionLoaded && isAddActionLoaded) {
      onClose();
      setActionModalType('New');
      setUpdateActionData(undefined);
    }
  }, [isLoaded, onClose, isActionLoaded, isAddActionLoaded]);

  useEffect(() => {
    if (
      !!reduxCurrentlySelectedGroup &&
      !!reduxCurrentlySelectedGroup.group_guid
    ) {
      reduxDispatch(getAllBranch());
      reduxDispatch(
        fetchRostersForGroups([reduxCurrentlySelectedGroup.group_guid])
      );
    }
  }, [reduxCurrentlySelectedGroup]);

  useEffect(() => {
    if (workAssignmentModalType === 'Edit' && updateActionData) {
      setValue('description', updateActionData.description);
      setValue('location', updateActionData.required_location);
      setValue('arrivalTime', updateActionData.required_date_time);
      setValue('special_equipment', updateActionData.special_equipment);
      setValue('overhead', updateActionData.overhead);
      setValue('branch', updateActionData?.branch_name || branch?.name || '');
      setValue(
        'division',
        updateActionData.division_name || division?.name || ''
      );
      setSelectedBranch(updateActionData.branch);
      setSelectedDivision(updateActionData.division);
      setSelectedTab(
        updateActionData.current ? 'Current Action' : 'Planned Action'
      );

      setSelectedObjectives(
        updateActionData.objectives.map((objective) => {
          return {
            ...objective,
            objective_id: objective.id?.substr(0, 4),
          };
        })
      );

      setPrevObjective(
        updateActionData.objectives
          ? updateActionData.objectives.map((objective) => objective.id)
          : []
      );

      setPrevResource(
        updateActionData.resources
          ? updateActionData.resources.map((resource) => resource.id)
          : []
      );

      setSelectedResources(
        updateActionData.resources.map((resource) => {
          return {
            ...resource,
            resource_id: resource.id,
            datetime_ordered: resource.datetime_ordered
              ? moment(resource.datetime_ordered).toISOString()
              : resource.datetime_ordered,
            eta: resource.eta
              ? moment(resource.eta).toISOString()
              : resource.eta,
          };
        })
      );
      setGeoLocation(updateActionData.required_geo_location);
    } else {
      setValue('branch', branch?.name || '');
      setValue('division', division?.name || '');
    }
  }, [workAssignmentModalType, updateActionData]);

  const onDeleteObjectiveToIncident = (row) => {
    setSelectedObjectives(
      selectedObjectives.filter((objective) => objective.id !== row.id)
    );
  };

  const onDeleteResourceToIncident = (row) => {
    setSelectedResources(
      selectedResources.filter(
        (resource) => resource.resource_id !== row.resource_id
      )
    );
  };

  const handleSelectObjective = (objective) => {
    if (objective.value === 'Add New') {
      setIsNewObjectiveDialogOpen(true);
      return;
    }
    setSelectedObjectives([
      ...selectedObjectives,
      {
        ...objective.value,
        id: objective.value.objective_id,
        objective_id: objective.value.objective_id.substr(0, 4),
        name: null,
      },
    ]);
  };

  useEffect(() => {
    setAvailableObjectives(
      incidentObjectives
        ?.filter(
          (obj) =>
            !selectedObjectives.find(
              (selected) => selected.id === obj.objective_id
            )
        )
        ?.map((obj) => ({
          label: obj.description,
          value: obj,
        }))
    );
  }, [selectedObjectives, incidentObjectives]);

  const handleSelectResource = (resource) => {
    if (resource.value === 'Add New') {
      setIsNewResourcesDialogOpen(true);
      return;
    }
    setSelectedResources([
      ...selectedResources,
      { ...resource.value, required_amount: 1 },
    ]);
  };

  useEffect(() => {
    setAvailableResources(
      incidentResources
        .filter(
          (resource) =>
            !selectedResources.find(
              (selected) => selected.resource_id === resource.resource_id
            )
        )
        .map((resource) => ({
          label: `${resource.type}, ${resource.name}`,
          value: resource,
        }))
    );
  }, [selectedResources, incidentResources]);

  const handleToggleButtonClick = (value) => {
    setSelectedTab(value);
  };

  const onActionDataSubmit = async (data) => {
    if (!!reduxCurrentIncident && !reduxCurrentIncident.current_period) {
      toast.error('Please Select a Operational Period first', toastConfig);
      return;
    }
    if (workAssignmentModalType === 'New') {
      const action = {
        id: generateUUID(),
        description: data.description,
        current: selectedTab === 'Current Action' ? true : false,
        special_equipment: data.special_equipment,
        overhead: data.overhead,
        is_action_201: false,
        required_date_time: data.arrivalTime,
        required_location: data.location,
        selectedObjectives: selectedObjectives.map((objective) => objective.id),
        selectedResources: selectedResources.map((resource) => ({
          id: resource.resource_id,
          required_amount: resource.required_amount,
        })),
        status: selectedTab === 'Current Action' ? 'Current' : 'Planned',
        incident_id: reduxCurrentIncident.id,
        type: 'Work Assignment',
        current_period: reduxCurrentIncident.current_period,
        branch_id: selectedBranch?.id,
        division_id: selectedDivision?.id,
        required_geo_location: geoLocation,
      };
      reduxDispatch(startLoading());
      try {
        await reduxDispatch(addNewAction(action));
        onClose(true);
      } catch (err) {
      } finally {
        reduxDispatch(endLoading());
      }
    } else {
      const action = {
        id: updateActionData.action_id,
        description: data.description,
        current: selectedTab === 'Current Action' ? true : false,
        required_date_time: data.arrivalTime,
        required_location: data.location,
        special_equipment: data.special_equipment,
        overhead: data.overhead,
        is_action_201: false,
        addedObjectives: selectedObjectives
          .filter((objective) => !prevObjective.includes(objective.id))
          .map((objective) => objective.id),
        addedResources: selectedResources
          .filter((resource) => !prevResource.includes(resource.resource_id))
          .map((resource) => ({
            id: resource.resource_id,
            required_amount: resource.required_amount,
          })),
        removedObjectives: prevObjective.filter((prevObjective) => {
          const objective = selectedObjectives.findIndex(
            (objective) => objective.id === prevObjective
          );
          if (objective === -1) {
            return true;
          } else {
            return false;
          }
        }),
        removedResources: Array.from(
          new Set([
            ...forceRemoveResource,
            ...prevResource.filter((prevResource) => {
              const resource = selectedResources.findIndex(
                (resource) => resource.resource_id === prevResource
              );
              if (resource === -1) {
                return true;
              } else {
                return false;
              }
            }),
          ])
        ),
        status: updateActionData.status,
        incident_id: reduxCurrentIncident.id,
        type: 'Work Assignment',
        branch_id: selectedBranch?.id,
        division_id: selectedDivision?.id,
        required_geo_location: geoLocation,
      };
      const prevTab = updateActionData.current
        ? 'Current Action'
        : 'Planned Action';
      if (selectedTab !== prevTab) {
        action.status =
          selectedTab === 'Current Action' ? 'Current' : 'Planned';
      }

      reduxDispatch(startLoading());
      reduxDispatch(updateActionDataToGroup(action));
      onClose(true);
    }
  };

  const onChangeResourceNeeded = (resourceIndex, value, r) => {
    if (value < 1) {
      return;
    }
    if (workAssignmentModalType === 'Edit') {
      const newPrevResource = prevResource.filter(
        (resource) => resource !== r.id
      );
      setPrevResource(newPrevResource);
      setForceRemoveResource([...forceRemoveResource, r.id]);
    }
    const newResource = structuredClone(selectedResources);
    newResource[resourceIndex].required_amount = value;
    setSelectedResources(newResource);
  };

  const onSelectBranches = (branch) => {
    setSelectedBranch(branch);
    setValue('branch', branch.name.trim());
    reduxDispatch(getAllDivision(branch.id));
    setSelectedDivision(null);
    setValue('division', '');
  };

  const onSelectDivision = (division) => {
    setSelectedDivision(division);
    setValue('division', division.name.trim());
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Action / Key Decision</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onActionDataSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">
              Description
              <span aria-label="Required field" className="required">
                *
              </span>
            </label>
            <Controller
              control={control}
              name="description"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                />
              )}
            />
            {errors?.description && (
              <span className="form-text form-error">
                *This field is required
              </span>
            )}
            {errors?.description?.type === 'maxLength' && (
              <span className="form-text form-error">
                *Maximum 1000 character allowed
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">
              Objective(s) attached to this action
            </label>
            {availableObjectives.length ? (
              <StylishNewSelect
                options={[
                  ...availableObjectives,
                  { label: 'Add New', value: 'Add New' },
                ]}
                onChange={(e) => handleSelectObjective(e)}
                value={''}
                isClearable={false}
                isSearchable={false}
                isMulti={false}
                isDisabled={false}
                placeholder="Choose a Objective"
              />
            ) : null}
            {!!selectedObjectives && selectedObjectives.length > 0 && (
              <div
                className={`form-chip-group ${
                  availableObjectives.length ? 'mt-3' : 'mt-0'
                }`}
              >
                {selectedObjectives.map((objective) => (
                  <StylishNewChip
                    key={objective.objective_id}
                    label={objective.description}
                    handleDelete={() => onDeleteObjectiveToIncident(objective)}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">
              Resource(s) attached to this action
            </label>
            {availableResources.length ? (
              <StylishNewSelect
                options={[
                  ...availableResources,
                  { label: 'Add New', value: 'Add New' },
                ]}
                onChange={(e) => handleSelectResource(e)}
                value={''}
                isClearable={false}
                isSearchable={false}
                isMulti={false}
                isDisabled={false}
                placeholder="Choose a Resource"
              />
            ) : null}
            {!!selectedResources && selectedResources.length > 0 && (
              <div
                className={`form-chip-group ${
                  availableResources.length ? 'mt-3' : 'mt-0'
                }`}
              >
                {selectedResources.map((resource, i) => (
                  <StylishNewChip
                    key={resource.id}
                    label={`${resource.type}, ${resource.name}`}
                    handleDelete={() => onDeleteResourceToIncident(resource)}
                  >
                    <label className="form-label">Resource Needed</label>
                    <StylishNewInput
                      type="number"
                      value={resource.required_amount}
                      onChange={(e) =>
                        onChangeResourceNeeded(i, e.target.value, resource)
                      }
                    />
                  </StylishNewChip>
                ))}
              </div>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Branch</label>
            <Controller
              control={control}
              name="branch"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  readOnly={true}
                  value={value}
                  onClick={() => {
                    setShowBranchModal(true);
                  }}
                  disabled={!!branch}
                />
              )}
            />
            {errors?.branch && (
              <span className="form-text form-error">
                *This field is required
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Division / Group / Staging</label>
            <Controller
              control={control}
              name="division"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  readOnly={true}
                  value={value}
                  onClick={() => {
                    setShowDivisionModal(true);
                  }}
                  disabled={!!division}
                />
              )}
            />
            {errors?.division && (
              <span className="form-text form-error">
                *This field is required
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">
              Overhead
              <span aria-label="Required field" className="required">
                *
              </span>
            </label>
            <Controller
              control={control}
              name="overhead"
              rules={{ required: true, maxLength: 1000 }}
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                />
              )}
            />
            {errors?.overhead?.type === 'required' && (
              <span className="form-text form-error">
                *This field is required
              </span>
            )}
            {errors?.overhead?.type === 'maxLength' && (
              <span className="form-text form-error">
                *Maximum 1000 character allowed
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">
              Special Equipment and Supplies
              <span aria-label="Required field" className="required">
                *
              </span>
            </label>
            <Controller
              control={control}
              name="special_equipment"
              rules={{ required: true, maxLength: 1000 }}
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                />
              )}
            />
            {errors?.special_equipment?.type === 'required' && (
              <span className="form-text form-error">
                *This field is required
              </span>
            )}
            {errors?.special_equipment?.type === 'maxLength' && (
              <span className="form-text form-error">
                *Maximum 1000 character allowed
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">
              Required Location
              <span aria-label="Required field" className="required">
                *
              </span>
            </label>
            <Controller
              control={control}
              name="location"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                />
              )}
            />
            {errors?.location && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">GeoLocation</label>
            <GeoLocationComponent
              location={geoLocation}
              setLocation={setGeoLocation}
              mapboxToken={mapboxToken}
            />
          </div>
          <div className="mb-3">
            <label className="form-label d-block">Action Type</label>
            <StylishNewToggleButtonGroup
              className="d-md-inline-flex"
              data={toggleData}
              selected={selectedTab}
              onChange={handleToggleButtonClick}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Required Time of Arrival</label>
            <Controller
              control={control}
              name="arrivalTime"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishDateTimePicker
                  value={value}
                  onChange={(e) => onChange(e)}
                  type="datetime-local"
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  required
                />
              )}
            />
            {errors?.arrivalTime?.type === 'required' && (
              <span className="form-text form-error">
                This fields (Date &amp; Time) is required
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton type="submit" className="button--primary">
              Submit
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
      {showBranchModal && (
        <BranchModal
          selectedBranch={
            selectedBranch && selectedBranch.id ? selectedBranch : null
          }
          show={showBranchModal}
          rowSelectable={true}
          onClose={(e) => {
            if (showBranchModal) {
              setShowBranchModal(false);
            }
            return;
          }}
          updateSelectedBranch={onSelectBranches}
          parent={'Work Assignment'}
        />
      )}
      {showDivisionModal && (
        <DivisionModal
          selectedBranch={
            selectedBranch && selectedBranch.id ? selectedBranch : null
          }
          selectedDivision={
            selectedDivision && selectedDivision.id ? selectedDivision : null
          }
          show={showDivisionModal}
          rowSelectable={true}
          updateSelectedDivision={onSelectDivision}
          onClose={() => setShowDivisionModal(false)}
          parent={'Work Assignment'}
        />
      )}
      {isNewResourcesDialogOpen && (
        <NewResourcesDialog
          show={isNewResourcesDialogOpen}
          modalType={'New'}
          onClose={() => setIsNewResourcesDialogOpen(false)}
        />
      )}
      {isNewObjectiveDialogOpen && (
        <NewObjectiveDialog
          show={isNewObjectiveDialogOpen}
          modalType={'New'}
          onClose={() => setIsNewObjectiveDialogOpen(false)}
        />
      )}
    </Modal>
  );
}
