import { useParams } from 'react-router';
import {
  selectGroupGuid,
  selectIncidentGuid,
  selectIncidents,
  selectUserGuid,
  useAppSelector,
} from '../../../slices/commonSelectors';

export const useGroupGuid = () => {
  return useAppSelector(selectGroupGuid);
};

export const useUserGuid = () => {
  return useAppSelector(selectUserGuid);
};

export const useActualIncidentId = () => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const incident_guid = useAppSelector(selectIncidentGuid);
  return incidentId ?? incident_guid;
};

export const useCurrentIncident = () => {
  const incidentId = useActualIncidentId();
  const incidents = useAppSelector(selectIncidents);
  const areIncidentsLoaded = useAppSelector(
    (state) => state.app.areIncidentsLoaded
  );

  return {
    data: incidents.find((incident) => incident.id === incidentId),
    isLoading: !areIncidentsLoaded,
    isError: false,
    error: undefined,
  };
};

export const useIncidentsInCurrentGroup = () => {
  const groupGuid = useGroupGuid();
  const incidents = useAppSelector(selectIncidents);
  return incidents.filter((incident) => incident.group_guid === groupGuid);
};
