import { createSlice } from '@reduxjs/toolkit';

type NotificationsState = {
  selectedTemplate: Record<string, any> | null;
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    selectedTemplate: null,
  } as NotificationsState,
  reducers: {
    setSelectedTemplate(state, action) {
      state.selectedTemplate = action.payload;
    },
  },
});

export const { setSelectedTemplate } = notificationsSlice.actions;
export default notificationsSlice.reducer;
