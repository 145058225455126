import React, { useEffect, useState } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import IconSettings from '../../assets/images/icon__settings.svg';

import ICS from 'components/ICS/ICS'

import {exportAsForm} from 'actions/formActions'

export const FormBuilderDashboardSidebar = () => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch()

  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  return (
    <>
      <div className="dashboard__sidebar">
        <div className="d-flex">
          <StylishNewButton
            type="buttton"
            customButton
            className="button--icon ms-auto"
            onClick={() => navigate('/groups')}
          >
            <img src={IconSettings} alt="" />
          </StylishNewButton>
        </div>
        <ICS reduxCurrentIncident={reduxCurrentIncident} />
        <div className="section">
          <h6>Plan</h6>
          <ul>
              <>
                <li>
                  <Link to={'/incident_management/ICS_202-CG'}>
                    Objectives (202){' '}
                  </Link>
                </li>
                <li>
                  <Link to={'/incident_management/ICS_206-CG'}>
                    Medical (206){' '}
                  </Link>
                </li>
                <li>
                  <Link to={'/incident_management/ICS_208-CG'}>
                    Site Safety and Health Plan (208)
                  </Link>
                </li>
                <li>
                  <Link to={'/incident_management/IAP_COVER_SHEET'}>
                    Full Incident Action Plan
                  </Link>
                </li>
              </>
          </ul>
        </div>
        {!reduxFeatureFlags.includes('STREAMLINE_PLATFORM') && (
          <>
            <div className="section">
              <h6>Assignments</h6>
              <ul>
                <li>
                  <Link to={'/incident_management/assignments_list'}>List</Link>
                </li>
              </ul>
            </div>
            <div className="section">
              <h6>Resources</h6>
              <ul>
                <li>
                  <Link to={'/incident_management/resource_list'}>List</Link>
                </li>
              </ul>
            </div>
          </>
        )}
        <div className="section">
          <h6>Analysis</h6>
          <ul>
            <li>
              <Link to={'/incident_management/ICS_201-CG'}>
                Initial Reports/Briefing (201)
              </Link>
            </li>
          </ul>
        </div>
        <div className="section">
          <h6>Reports</h6>
          <ul>
            <li>
              <Link to={'/report'}>Reports</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
