export const Config204 = {
    formname: "ICS 204",
    filename: "ICS204-CG.pdf",
    field: [
        {
            key: '1 Incident Name',
            type: 'Text',
            value: '',
        },
        {
            key: 'OPDateTimeFrom',
            type: 'Text',
            value: '',
        },
        {
            key: 'OPDateTimeTo',
            type: 'Text',

            value: '',
        },
        {
            key: '3 Branch',
            type: 'Text',
            value: '',
        },

        {
            key: '4 DivisionGroupStaging',
            type: 'Text',
            value: '',
        },
        {
            key: 'Branch Director',
            type: 'Text',
            value: '',
        },
        {
            key: 'DivisionGroup SupervisorSTAM',
            type: 'Text',
            value: '',
        },
        {
            key: '6 Prepared by Name_2',
            type: 'Text',
            value: '',
        },
        {
            key: '6 Prepared by Name_3',
            type: 'Text',
            value: '',
        },
        {
            key: '6 Prepared by Name_4',
            type: 'Text',
            value: '',
        },
        {
            key: 'PositionTitle',
            type: 'Text',
            value: '',
        },
        {
            key: 'WorkAssignment',
            type: 'Table',
            value: [],
        },
        {
            key: '8 Special Instructions',
            type: 'Text',
            value: '',
        },
        {
            key: 'Comm',
            type: 'Table',
            value: [],
        },
        {
            key: 'Medical',
            type: 'Text',
            value: '',
        },
        {
            key: 'Evacuation',
            type: 'Text',
            value: '',
        },
        {
            key: 'Other',
            type: 'Text',
            value: '',
        },
        {
            key: '7 Work Assignments',
            type: 'List',
            value: [],
        },
        {
            key: 'DateTime_3',
            type: 'Text',
            value: '',
        },
        {
            key: 'DateTime_4',
            type: 'Text',
            value: '',
        },
        {
            key: 'Signature',
            type: 'Signature',
            value: '',
        },
        {
            key: 'Signature_2',
            type: 'Signature',
            value: '',
        },
        {
            key: 'Signature_3',
            type: 'Signature',
            value: '',
        },
        {
            key: 'Signature_4',
            type: 'Signature',
            value: '',
        },
        {
            key: '7 Current and Planned Objectives',
            type: 'List',
            value: [],
        },
        {
            key: 'Action',
            type: 'Table',
            value: [],
        },
        {
            key: 'Resource',
            type: 'Table',
            value: [],
        
        },
        {
            key: 'Operations Section Chief',
            type: 'Text',
            value: '',
        }

    ]
}

