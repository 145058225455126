import React from 'react';
import { useCss, useToggle } from 'react-use';
import classNames from 'classnames';
import {
  ListGroupDropdownItem,
  ListGroupNavItem,
} from './ListGroupNavComponents';
import Collapse from '@mui/material/Collapse';
import { ListGroup } from 'react-bootstrap';
import { useIncident } from '../IAP/hooks/incidentHooks';

const IncidentWorkspacePanel: React.FC<{
  navBack: () => void;
}> = ({ navBack }) => {
  const gradientClass = useCss({
    backgroundImage: 'var(--background-brand-gradient)',
    backgroundClip: 'text',
    color: 'transparent',
    fontWeight: 600,
  });
  const [open, toggle] = useToggle(false);
  const incident = useIncident();

  return (
    <nav aria-label="secondary sidebar" className="second-panel">
      <div className="second-panel-back flex-column align-items-start gap-3">
        <div className="d-flex align-items-center">
          <i
            className={classNames(
              'material-symbols-outlined',
              'me-1',
              gradientClass
            )}
          >
            electric_bolt
          </i>
          <span className={classNames(gradientClass)}>Incident Workspace</span>
        </div>
        <div className="fs-4">{incident.data?.name}</div>
        <button
          className="button button--secondary-fill border-0 bg-bglight w-100
          "
          onClick={navBack}
        >
          <i className="material-symbols-outlined me-2">arrow_back</i> Back
        </button>
      </div>
      <ListGroup>
        <ListGroupNavItem
          to={`/iap/${incident.data?.id}/dashboard`}
          icon="tactic"
          label="Planning"
          end
        />
        <ListGroupDropdownItem
          onClick={() => toggle()}
          open={open}
          icon="commit"
          label="Operations"
        />
        <Collapse in={open}>
          <ListGroupNavItem
            to={`/iap/${incident.data?.id}/assignments`}
            label="Assignments"
            secondary
          />
          <ListGroupNavItem
            to={`/iap/${incident.data?.id}/resources`}
            label="Resources"
            secondary
          />
        </Collapse>
        <ListGroupNavItem
          to="/disabled"
          icon="file_present"
          label="Document"
          isDisabled={true}
        />
        <ListGroupNavItem
          to="/disabled"
          icon="bar_chart"
          label="Analytics"
          isDisabled={true}
        />
        <ListGroupNavItem
          to={`/iap/${incident.data?.id}/settings`}
          icon="settings"
          label="Settings"
        />
      </ListGroup>
    </nav>
  );
};

export default IncidentWorkspacePanel;
