import React from 'react';
import * as dayjs from 'dayjs';

import './PowerOutageLayerPopup.css';

export default function PowerOutageLayerPopup({
  layer,
  clickPopup,
  e,
  feature,
  selectedDatetime
}) {

  let title = `${
    (feature.properties.political_boundaries_type === 'county' &&
      `${feature.properties.county_name} County, ${feature.properties.state_name}`) ||
    (feature.properties.political_boundaries_type === 'state' &&
      feature.properties.state_name)
  }`;

  let percent = 0;
  if (feature?.properties?.CustomersOut && feature?.properties?.CustomersTracked) {
    percent = (feature?.properties?.CustomersOut / feature?.properties?.CustomersTracked * 100).toFixed(2)
  }
  return (
    <div className="Map-PowerOutageLayerPopup">
      <h6 className="m-0">Observed Power Outage</h6>
      <h6 className="m-0">{title}</h6>

      <hr className="dashed thin my-1" />

      <div className="Map-PowerOutageLayerPopup-Data">
        <div className="Map-PowerOutageLayerPopup-Model-Value">
          <strong>
            {dayjs(feature?.properties?.LastUpdatedDateTime).format('MM/DD/YYYY hh:mm A')}
          </strong>
        </div>
        <div className="Map-PowerOutageLayerPopup-Model-Value">
          <strong>{feature.properties?.CustomersOut}&nbsp;</strong> customers out
        </div>
        <div className="Map-PowerOutageLayerPopup-Model-Value">
          <strong>{percent}%&nbsp;</strong> of customer out
        </div>
      </div>

      <hr className="thin my-1" />
    </div>
  );
}
