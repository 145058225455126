import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import NewDistributionListDialog from 'components/Notifications/NewDistributionListDialog';
import EditDistributionListDialog from 'components/Notifications/EditDistributionListDialog';
import {
  deleteDistributionList,
  editDistributionList,
  createDistributionList,
  fetchAllDistributionList,
} from 'actions/notificationActions';
import { StylishNewChip } from 'components/DesignSystems/New/StylishNewChip';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export default function TeamRosters({
  allowSelection,
  teamsSelected,
  onChangeTeamSelection,
}) {
  const allRecipientTypes = [
    'Email',
    'SMS',
    'Pager',
    'Text-To-Speech (cell)',
    'Text-To-Speech (home)',
  ];

  const reduxDispatch = useDispatch();
  const [isNewTeamDialogOpen, setIsNewTeamDialogOpen] = useState();
  const [isEditTeamDialogOpen, setIsEditTeamDialogOpen] = useState();
  const [distributionLists, setDistributionLists] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(null);
  const [recipientTypes, setRecipientTypes] = useState(allRecipientTypes);

  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const reduxNestedCategoryItems = useSelector((state) => {
    return state.app.nestedCategoryItems;
  });

  const reduxNestedCategories = useSelector((state) => {
    return state.app.nestedCategories;
  });

  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const distributionListItems = useSelector((state) => {
    return state.app.distributionListItems;
  });

  useEffect(() => {
    if (reduxCurrentlySelectedGroup) {
      reduxDispatch(
        fetchAllDistributionList(reduxCurrentlySelectedGroup.group_guid)
      );
    }
  }, [reduxCurrentlySelectedGroup]);

  useEffect(() => {
    setDistributionLists(
      distributionListItems.map((item) => {
        const foundItem =
          reduxNestedCategoryItems &&
          reduxNestedCategoryItems.find(
            (catItem) => catItem.id === item?.teamLocation?.location_id
          );
        const foundCategory =
          reduxNestedCategories &&
          reduxNestedCategories.find(
            (cat) => cat.id === foundItem?.category_id
          );
        const label = !!foundItem?.category_item_name
          ? `${
              foundCategory ? foundCategory.category_name + ' ' : ''
            } Area of Responsibility : ${foundItem?.category_item_name}`
          : '';

        return {
          ...item,
          location: label,
        };
      })
    );
  }, [distributionListItems]);

  const handleNewTeam = (list) => {
    if (list && list.id && list.name) {
      reduxDispatch(createDistributionList(list));
    }

    setIsNewTeamDialogOpen(false);
  };

  const handleEditTeam = (list) => {
    if (list && list.id && list.name) {
      reduxDispatch(editDistributionList(list));
    }

    setIsEditTeamDialogOpen(false);
  };

  const handleTeamDeleteClick = (idToDelete) => {
    reduxDispatch(deleteDistributionList(idToDelete));
    setShowConfirmDialog(null);
  };

  const handleTeamEditClick = (list) => {
    setIsEditTeamDialogOpen(list);
  };

  const roster = useSelector((state) => {
    let rosters = state.app.rostersForGroups;
    if (Array.isArray(rosters) && rosters.length) {
      rosters = rosters
        .filter((member) => {
          return member.group_guid === reduxCurrentlySelectedGroup.group_guid;
        })
        .map((member) => {
          return {
            ...member,
            role_string: member.role_assignments.reduce((acc, item) => {
              acc = acc ? acc + ', ' + item.name : item.name;

              return acc;
            }, ''),
          };
        });
    }
    return rosters || [];
  });

  const allColumn = [
    {
      dataField: 'name',
      text: 'Relevant Team',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Relevant Team' },
    },
    {
      dataField: 'location',
      text: 'Area of Responsibility',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Area of Responsibility' },
    },
  ];

  const handleRecipientNameClick = () => {};

  const renderNestedCategory = (props) => {
    const foundItem = reduxNestedCategoryItems?.find(
      (item) => item.id === props
    );
    const foundCategory = reduxNestedCategories?.find(
      (cat) => cat.id === foundItem?.category_id
    );
    if (!foundItem) return null;
    return (
      <div className="mt-3">
        <span>
          {`${
            foundCategory ? foundCategory.category_name + ' ' : ''
          } Area of Responsibility : ${foundItem.category_item_name}`}
        </span>
      </div>
    );
  };

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="form-chip-group">
            {row.members
              .filter((member) =>
                roster.some((person) => person.user_guid === member)
              )
              .map((member) => {
                const user = roster.find(
                  (person) => person.user_guid === member
                );
                const name = user.user_name || user.email_address;
                return (
                  <StylishNewChip
                    key={`key-${member}`}
                    className="notificationComposeScreen-recipientListChip"
                    label={name}
                    handleClick={() => {
                      handleRecipientNameClick(member);
                    }}
                  />
                );
              })}
            {!!row.nonMembers &&
              row.nonMembers.map((member) => {
                return (
                  <StylishNewChip
                    key={`key-${member.id}`}
                    className="notificationComposeScreen-recipientListChip"
                    label={member.content}
                  />
                );
              })}
          </div>
          {row.teamLocation?.location_id &&
            renderNestedCategory(row?.teamLocation?.location_id)}
          <hr className="dashed my-3" />
          <div className="button-group mt-4">
            <StylishNewButton
              customButton
              type="button"
              className="button--icon primary ms-2"
              onClick={() => handleTeamEditClick(row)}
            >
              <SharedIcon iconName="stylus" />
            </StylishNewButton>
            <StylishNewButton
              customButton
              type="button"
              className="button--icon primary ms-2"
              onClick={() => setShowConfirmDialog(row.id)}
            >
              <SharedIcon iconName="delete" />
            </StylishNewButton>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: false,
    selected: teamsSelected || [],
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      if (teamsSelected.includes(row.id)) {
        onChangeTeamSelection([
          ...teamsSelected.filter((sTeam) => sTeam !== row.id),
        ]);
      } else {
        onChangeTeamSelection([...teamsSelected, row.id]);
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect
        ? onChangeTeamSelection(rows.map((row) => row.id))
        : onChangeTeamSelection([]);
    },
  };

  return (
    <>
      <div className="d-md-flex align-items-center mb-3">
        {!allowSelection ? <h4 className="m-0">Team Rosters</h4> : null}
        {reduxValidateRBACPermissionForActionResult['Create Role'] ? (
          <>
            <StylishNewButton
              className={`button--primary${
                !allowSelection ? ' ms-auto mt-2 mt-md-0' : ' mb-2'
              } `}
              onClick={() => setIsNewTeamDialogOpen(true)}
            >
              New Team
            </StylishNewButton>
          </>
        ) : (
          <>
            <div className={!allowSelection ? 'ms-auto text-end' : ''}>
              <StylishNewButton
                className={`button--primary${
                  !allowSelection ? ' mt-2 mt-md-0' : 'mb-2'
                }`}
                disabled
              >
                New Team
              </StylishNewButton>
              <span className="form-text form-error">
                You don't have permission.
              </span>
            </div>
          </>
        )}
      </div>
      {allowSelection ? (
        <StylishNewTable
          keyField={'id'}
          columns={allColumn}
          rows={distributionLists}
          expandRow={expandRow}
          selectRow={selectRow}
        />
      ) : (
        <div className="form-block">
          <StylishNewTable
            keyField={'id'}
            columns={allColumn}
            rows={distributionLists}
            expandRow={expandRow}
          />
        </div>
      )}

      {isNewTeamDialogOpen && (
        <NewDistributionListDialog
          show={isNewTeamDialogOpen}
          roster={roster}
          onClose={(list) => handleNewTeam(list)}
          recipientTypes={recipientTypes}
          distributionLists={distributionLists}
        />
      )}

      {isEditTeamDialogOpen && (
        <EditDistributionListDialog
          roster={roster}
          onClose={(list) => handleEditTeam(list)}
          recipientTypes={[]}
          selectedDistributionList={isEditTeamDialogOpen}
          distributionLists={distributionLists}
          show={isEditTeamDialogOpen}
          teamLocation={isEditTeamDialogOpen.teamLocation}
        />
      )}
      {!!showConfirmDialog && (
        <StylishConfirmDialog
          show={!!showConfirmDialog}
          dialogTitle={'Delete This Team'}
          dialogContent={'Are you sure you want to delete this team?'}
          onClose={() => setShowConfirmDialog(null)}
          onConfirm={() => handleTeamDeleteClick(showConfirmDialog)}
        />
      )}
    </>
  );
}
