import axios from 'axios';
const baseURL =
  process.env.NODE_ENV === 'production'
    ? window.env.COMSDOMAIN + '/prepare/api/admin'
    : process.env.REACT_APP_COMSDOMAIN + '/prepare/api/admin';
const Network = {
  http: axios,
  get: async <R = any, P = Record<string, any>>(url: string, params?: P, signal?: any) => {
    return axios.get<R>(`${baseURL}/${url}`, {
      params: params,
      signal: signal,
    });
  },
  post: async <R = any, P = Record<string, any>>(url: string, data?: P) => {
    return axios.post<R>(`${baseURL}/${url}`, data);
  },
};
export const PrepareBaseUrl = baseURL;
export default Network;
