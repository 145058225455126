import React, { useState } from 'react';
import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider';
import { Handle, SliderRail, Tick, Track } from './SliderHelper';
import { StylishNewButton } from '../StylishNewButton';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import { SharedIcon } from '../../../SharedIcon/SharedIcon';
import { useSelector } from 'react-redux';

const sliderStyle = {
  position: 'relative',
  width: '100%',
};

export const StylishRangeSlider24hrs = ({
  defaultValue,
  minValue,
  maxValue,
  selectedDatetime,
  setSelectedDatetime,
}) => {
  const defaultRange = Number(defaultValue);
  const min = Number(minValue);
  const max = Number(maxValue);
  const [showCalendar, setShowCalendar] = useState(false);

  const timeFormat = useSelector((state) => state.prepare?.user?.timeFormat);

  const onChange = (data) => {
    setSelectedDatetime(selectedDatetime.hour(data).minute(0));
  };

  const onUpdate = (data) => {
    setSelectedDatetime(selectedDatetime.hour(data).minute(0));
  };

  const mapRangeDateHandler = () => {
    setShowCalendar(!showCalendar);
  };

  const mapRangeCalendarHandler = (value) => {
    setSelectedDatetime(dayjs(value).hour(selectedDatetime.hour()));
    setShowCalendar(false);
  };

  return (
    <>
      <div className="rangeSlider-24hrs">
        <div className="d-flex align-items-center position-relative  mb-4 px-4">
          <StylishNewButton
            className={'button--tertiary button--sml text-uppercase'}
            onClick={mapRangeDateHandler}
          >
            <span className="ms-2">
              {timeFormat === 'hour12Mode' ? selectedDatetime.format('MM/DD/YYYY hh:mm A') : selectedDatetime.format('MM/DD/YYYY HH:mm')}
            </span>
          </StylishNewButton>
          {showCalendar && (
            <>
              <div className="calendar">
                <div className="d-flex justify-content-end ">
                  <StylishNewButton
                    customButton
                    className="button--icon"
                    onClick={() => setShowCalendar(false)}
                  >
                    <SharedIcon iconName="close" />
                  </StylishNewButton>
                </div>
                <div className="form-calendar">
                  <Calendar
                    value={new Date(selectedDatetime)}
                    onChange={(value) => mapRangeCalendarHandler(value)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div
          style={{
            padding: '0 1.5rem',
            marginTop: '1.25rem',
            width: '100%',
            height: '2.875rem',
          }}
        >
          <Slider
            mode={1}
            step={1}
            domain={[min, max]}
            rootStyle={sliderStyle}
            onUpdate={onUpdate}
            onChange={onChange}
            values={[defaultRange]}
          >
            <Rail>
              {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => (
                <div>
                  {handles.map((handle) => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      domain={[+min, +max]}
                      getHandleProps={getHandleProps}
                    />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks right={false}>
              {({ tracks, getTrackProps }) => (
                <div>
                  {tracks.map(({ id, source, target }) => (
                    <Track
                      key={id}
                      source={source}
                      target={target}
                      getTrackProps={getTrackProps}
                    />
                  ))}
                </div>
              )}
            </Tracks>
            <Ticks count={max + 1}>
              {({ ticks }) => (
                <div>
                  {ticks.map((tick, index) => {
                    return (
                      <Tick key={tick.id} tick={tick} count={ticks.length} />
                    );
                  })}
                </div>
              )}
            </Ticks>
          </Slider>
        </div>
      </div>
    </>
  );
};
