export const addToLibraryClicked = (map,layer,personalLibraryItems,setPersonalLibraryItems) => {
  const source = map.getSource(layer.source)
  setPersonalLibraryItems([...personalLibraryItems,
    {
      layer,
      source
    }
  ])
}

export const fillLinearGradient = (layer) => {
  const numbers = layer.legend.split(";").map(l=> {
    return "#"+l.split(":")[1]
  }).join(", ")
  return `linear-gradient(to right, ${numbers})`
}

export const removeLayer = (layer,layers,setLayers) => {
  setLayers([...layers.filter(l=>l.id!==layer.id)])
}

export const InitalThresholdsMarker = [...Array(24).keys()].map((i) => {
  let label;
  if (i === 0) {
    label = '12 AM';
  } else if (i === 12) {
    label = '12 PM';
  } else if (i > 12) {
    label = i - 12 + ' PM';
  } else {
    label = i + ' AM';
  }
  return {
    value: i,
    label: label,
  };
});