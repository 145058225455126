import {updateGroupWebsocketResult} from 'actions/roleActions'

export const ENDPOINT_TO_DELEGATE = {
  '/dice/editgroup': updateGroupWebsocketResult,
  '/dice/deleteGroup': updateGroupWebsocketResult,
  '/dice/inviteTogroup': updateGroupWebsocketResult,
  '/dice/removeUserFromGroup': updateGroupWebsocketResult,
  'dice/roles/createRoleWithPermissions': updateGroupWebsocketResult,
  'dice/roles/unAssignUserFromRole': updateGroupWebsocketResult,
  '/dice/roles/assignUserToRole': updateGroupWebsocketResult,
  '/dice/roles/editRoleAndPermissionsAndTags': updateGroupWebsocketResult,
}