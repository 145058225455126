import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllActivityLogs } from 'actions/ReportActions';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import moment from 'moment';
import { startLoading } from 'reducers/loading/loading.action';
import { sanitizeHtml } from '../../utils/sanitizeHtml';

export default function DiceLog() {
  const reduxDispatch = useDispatch();
  const diceActivityLogs = useSelector((state) => state.app.diceActivityLogs);

  const dataColDice = [
    {
      dataField: 'user_name',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="me-2 flex-shrink-0 button--icon primary rounded-circle p-0">
              <span className="weight-600 txt--sml">
                {row.user_name?.charAt(0)?.toUpperCase()}
              </span>
            </div>
            <p className="mb-0">{row.user_name}</p>
          </div>
        );
      },
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      attrs: { title: 'Type' },
    },

    {
      dataField: 'created_at',
      text: 'Time',
      sort: true,
      attrs: { title: 'Time' },
      formatter: (cell, row) => {
        return <>{moment(row.created_at).fromNow()}</>;
      },
    },
  ];

  const expandRowDice = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      const clean = sanitizeHtml(row.comment);
      return (
        <>
          <div className="row">
            <label className="form-label d-block">Comment</label>
            <div
              className="m-0"
              dangerouslySetInnerHTML={{ __html: clean }}
            ></div>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded, expandable }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      if (expandable) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
          </>
        );
      }
    },
  };

  useEffect(() => {
    reduxDispatch(startLoading());
    reduxDispatch(getAllActivityLogs());
  }, []);

  return (
    <>
      <StylishNewTable
        keyField={'id'}
        columns={dataColDice}
        rows={diceActivityLogs}
        expandRow={expandRowDice}
        hasPagination={true}
        paginationPerPageItem="10"
      />
    </>
  );
}
