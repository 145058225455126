import React from 'react';
import { generateGUID } from '@microsoft/teams-js';
import { updateIncident } from 'actions/diceActions';
import {
  getICSObjectiveCommandEmphasis,
  upsertICSObjectiveCommandEmphasis,
} from 'actions/IAPForm204Actions';
import {
  getAllObjectivesToGroup,
  getAllObjectiveToIncident,
  getRosterForIncident,
  updateIncidentObjectiveSelection,
} from 'actions/incidentActions';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewChip } from 'components/DesignSystems/New/StylishNewChip';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import EditOperationalPeriodDialog from 'components/IncidentConfig/EditOperationalPeriodDialog';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { Config202 } from './FormTemplates/FormConfig/202Config';
import ManageObjectivesDialog from './ManageObjectivesDialog';
import { toastConfig } from 'assets/data/config';
export default function IAPForm202({ formRecordData, onFormRecordDataUpdate }) {
  const reduxDispatch = useDispatch();
  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });
  const incidentObjectives = useSelector(
    (state) => state.app.incidentObjectives
  );
  const reduxRosterForIncident = useSelector((state) => {
    return state.app.rosterForIncident;
  });
  const reduxUser = useSelector((state) => {
    return state.app.user;
  });
  const ics202ObjectivesData = useSelector(
    (state) => state.app.ics202ObjectivesData
  );
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' });
  const [
    isEditOperationalPeriodOpen,
    setIsEditOperationalPeriodOpen,
  ] = useState(false);
  const [
    isManageObjectivesDialogOpen,
    setIsManageObjectivesDialogOpen,
  ] = useState(false);
  const [selectedObjectives, setSelectedObjectives] = useState([]);
  const [groupObjectives, setGroupObjectives] = useState([]);
  const [planningSectionChiefs, setPlanningSectionChiefs] = useState([]);
  const [formState, setFormState] = useState(Config202);
  const [isUserPSC, setIsUserPSC] = useState(false);
  const updateFormState = (key, value) => {
    const index = formState.field.findIndex((field) => field.key === key);
    if (index !== -1) {
      let obj = { ...formState.field[index] };
      obj = { ...obj, value: value };
      formState.field[index] = obj;
      setFormState(formState);
      onFormRecordDataUpdate(formState);
    }
  };
  useEffect(() => {
    if (!!reduxRosterForIncident && reduxRosterForIncident.length > 0) {
      const pSectionChiefs = reduxRosterForIncident
        .filter((users) => users.incident_seat === 'Planning Section Chief')
        .map((users) => ({
          value: users,
          label:
            users.profile_settings.userName ||
            users.profile_settings.email_address,
        }));
      setPlanningSectionChiefs(pSectionChiefs);
    }
  }, [reduxRosterForIncident]);

  useEffect(() => {
    if (
      !!reduxCurrentlySelectedGroup &&
      !!reduxCurrentlySelectedGroup.group_guid
    ) {
      reduxDispatch(getAllObjectivesToGroup());
    }
  }, [reduxCurrentlySelectedGroup]);

  useEffect(() => {
    if (!!reduxUser && !!reduxRosterForIncident) {
      const user = reduxRosterForIncident.find(
        (incidentUser) => incidentUser.user_guid === reduxUser.user_guid
      );
      if (!!user) {
        if (user.incident_seat === 'Planning Section Chief') {
          setIsUserPSC(true);
          updateFormState(
            'PreparedbyPlanningSectionChief',
            reduxUser.profile_settings?.userName || reduxUser.email_address
          );
        } else {
          setIsUserPSC(false);
          updateFormState('PreparedbyPlanningSectionChief', '');
        }
      } else {
        setIsUserPSC(false);
        updateFormState('PreparedbyPlanningSectionChief', '');
      }
    }
  }, [reduxUser, reduxRosterForIncident]);
  useEffect(() => {
    if (!!reduxCurrentIncident) {
      if (!!reduxCurrentIncident.current_period) {
        reduxDispatch(getICSObjectiveCommandEmphasis());
        updateFormState(
          'OPFrom',
          moment(
            reduxCurrentIncident.operational_period_start_date_time
          ).format('DD/MM/YYYY HH:mm')
        );
        updateFormState(
          'OPTo',
          moment(reduxCurrentIncident.operational_period_start_date_time)
            .add(reduxCurrentIncident.operational_period_interval, 'hours')
            .format('DD/MM/YYYY HH:mm')
        );
      }
      setValue('incident_name', reduxCurrentIncident.name);
      updateFormState('1 Incident Name', reduxCurrentIncident.name);
      reduxDispatch(getAllObjectiveToIncident());
      reduxDispatch(getRosterForIncident());
    }
  }, [reduxCurrentIncident]);
  useEffect(() => {
    setSelectedObjectives(
      incidentObjectives
        ? incidentObjectives.map((ele) => {
            return {
              id: ele.objective_id,
              group_guid: ele.group_guid,
              name: null,
              description: ele.description,
              objective_id: ele.objective_id
                ? ele.objective_id.substring(0, 4)
                : ele.objective_id,
            };
          })
        : []
    );
    if (!!incidentObjectives && incidentObjectives.length > 0) {
      updateFormState(
        '3  Objectives',
        incidentObjectives.map((obj) => {
          if (obj?.description?.length > 300) {
            return obj.description.substring(0, 300);
          } else {
            return obj.description;
          }
        })
      );
    } else {
      updateFormState('3  Objectives', []);
    }
  }, [incidentObjectives]);

  const reduxGroupObjectives = useSelector(
    (state) => state.app.groupObjectives
  );

  const isGroupObjectivesLoaded = useSelector(
    (state) => state.app.isGroupObjectivesLoaded
  );

  useEffect(() => {
    setGroupObjectives(
      reduxGroupObjectives
        ? reduxGroupObjectives.map((ele) => {
            return {
              ...ele,
              objective_id: ele.id ? ele.id.substring(0, 4) : ele.id,
            };
          })
        : []
    );
  }, [reduxGroupObjectives]);
  useEffect(() => {
    if (!!ics202ObjectivesData) {
      setValue(
        'operational_period_command_emphasis',
        ics202ObjectivesData.operational_period_command_emphasis
      );
      setValue(
        'approved_site_safety_plan_located_at',
        ics202ObjectivesData.approved_site_safety_plan_located_at
      );
      updateFormState(
        'OperationalPeriodCommandEmphasis',
        ics202ObjectivesData.operational_period_command_emphasis
          ? ics202ObjectivesData.operational_period_command_emphasis.replace(
              /(.{80})/g,
              '$1\n'
            )
          : ''
      );
      updateFormState(
        '5 Site Safety Plan Required Yes No Approved Site Safety Plans Located at',
        ics202ObjectivesData.approved_site_safety_plan_located_at
          ? ics202ObjectivesData.approved_site_safety_plan_located_at
          : ''
      );
      updateFormState(
        'PreparedbyPlanningDateTime',
        moment().format('DD/MM/YYYY HH:mm')
      );
      if (!!ics202ObjectivesData.reviewed_by) {
        setValue('reviewed_by', {
          value: {
            user_guid: ics202ObjectivesData.reviewed_by,
            profile_settings: ics202ObjectivesData?.profile_settings,
            email_address: ics202ObjectivesData?.email_address,
          },
          label:
            ics202ObjectivesData?.profile_settings?.userName ||
            ics202ObjectivesData?.email_address,
        });
      }
    }
  }, [ics202ObjectivesData]);
  const onSaveSpecialInstructions = () => {
    if (!reduxCurrentIncident || !reduxCurrentIncident.current_period) {
      toast.error('Please select an operational period first', toastConfig);
      return;
    }
    reduxDispatch(
      upsertICSObjectiveCommandEmphasis({
        ...ics202ObjectivesData,
        id: !!ics202ObjectivesData ? ics202ObjectivesData.id : generateUUID(),
        operational_period_command_emphasis: getValues(
          'operational_period_command_emphasis'
        ),
      })
    );
  };
  const onSaveReviewedBy = () => {
    if (!reduxCurrentIncident || !reduxCurrentIncident.current_period) {
      toast.error('Please select an operational period first', toastConfig);
      return;
    }
    reduxDispatch(
      upsertICSObjectiveCommandEmphasis({
        ...ics202ObjectivesData,
        id: !!ics202ObjectivesData ? ics202ObjectivesData.id : generateUUID(),
        reviewed_by: getValues('reviewed_by').value.user_guid,
      })
    );
  };
  const onDeleteObjectiveToIncident = (row) => {
    const filtered = selectedObjectives.filter(
      (objective) => objective.id !== row.id
    );
    setSelectedObjectives(filtered);
    reduxDispatch(startLoading());
    reduxDispatch(
      updateIncidentObjectiveSelection(filtered.map((item) => item.id))
    );
  };

  return (
    <>
      <div className="mb-4">
        <h4 className="mb-2">Planning Section</h4>
        <p className="fst-italic weight-500 m-0">
          Only the seats Planning Section Chief can modify this section. Seats
          can be managed at the{' '}
          <Link to="/incident_configuration">Duty Roster</Link>{' '}
        </p>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>1. Incident Name</h4>
        <label className="form-label">Name:</label>
        <Controller
          control={control}
          name="incident_name"
          rules={{ required: true }}
          render={({ field: { value } }) => <p>{value}</p>}
        />
        {errors?.incident_name?.type === 'required' && (
          <span className="form-text form-error mb-3">
            This field is required
          </span>
        )}
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>2. Operational Period</h4>
        {!!reduxCurrentIncident && reduxCurrentIncident?.current_period && (
          <>
            <div className="form-group">
              <label className="form-label">From:</label>
            </div>
            <div className="mb-3">
              {moment(
                reduxCurrentIncident?.operational_period_start_date_time
              ).format('MM/DD/YYYY HH:mm')}
            </div>
            <div className="form-group">
              <label className="form-label">To:</label>
            </div>
            <div className="mb-3">
              {moment(reduxCurrentIncident?.operational_period_start_date_time)
                .add(reduxCurrentIncident?.operational_period_interval, 'hours')
                .format('MM/DD/YYYY HH:mm')}
            </div>
          </>
        )}
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>3. Objective(s)</h4>
        <StylishNewButton
          type="button"
          className="button--secondary"
          onClick={() => setIsManageObjectivesDialogOpen(true)}
          disabled={
            !reduxValidateRBACPermissionForActionResult['Write Pod Data'] ||
            !isUserPSC
          }
        >
          Manage Objectives
        </StylishNewButton>
        {!reduxValidateRBACPermissionForActionResult['Write Pod Data'] ? (
          <span className="form-text form-error">
            You do not have permission to edit the form.
          </span>
        ) : null}
        {!!selectedObjectives && selectedObjectives.length > 0 && (
          <div className="form-chip-group mt-3">
            {selectedObjectives.map((objectives) => {
              return (
                <StylishNewChip
                  key={objectives.id}
                  label={objectives.description}
                  handleDelete={() =>
                    isUserPSC && onDeleteObjectiveToIncident(objectives)
                  }
                  // disabled={!isUserPSC}
                />
              );
            })}
          </div>
        )}
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4 className="mb-1">
          4. Operational Period Command Emphasis (Safety Message, Priorities,
          Key Decisions/Directions)
        </h4>
        <Controller
          control={control}
          name="operational_period_command_emphasis"
          rules={{ required: true, maxLength: 1000 }}
          render={({ field: { onChange, value } }) => (
            <StylishNewTextArea
              value={value || ''}
              onChange={onChange}
              className="mb-3"
              readOnly={!isUserPSC}
            />
          )}
        />
        {errors?.operational_period_command_emphasis?.type === 'maxLength' && (
          <span className="form-text form-error">
            Max 1000 character allowed
          </span>
        )}
        <StylishNewButton
          className={'button--primary'}
          type="button"
          onClick={onSaveSpecialInstructions}
          disabled={!isUserPSC}
        >
          Save
        </StylishNewButton>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4 className="mb-1">5. Approved Site Safety Plan Located at:</h4>
        <p className="fst-italic weight-500 m-0">
          Site Safety Plan can be managed at the{' '}
          <Link to="/incident_management/ICS_208-CG">ICS 208 CG</Link>{' '}
        </p>
      </div>
      <div className="mb-4">
        <h4>6. Reviewed By (Planning Section Chief)</h4>
        {planningSectionChiefs && planningSectionChiefs.length > 0 ? (
          <>
            <div className="mb-4 row">
              <div className="col-md-6">
                <label className="form-label">Name:</label>
                <Controller
                  control={control}
                  name="reviewed_by"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewSelect
                      options={planningSectionChiefs}
                      value={value}
                      placeholder="Select a Type"
                      onChange={onChange}
                      isDisabled={!isUserPSC}
                      isClearable={true}
                      isSearchable={true}
                      isMulti={false}
                    />
                  )}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Date/ Time:</label>
                <StylishNewInput
                  value={moment().format('YYYY-MM-DD HH:mm')}
                  disabled={true}
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="form-label">
                Signature: <span className="color-danger">*</span>
              </label>
              <p className="fst-italic weight-500 m-0">
                Signatures are used to electronically sign Forms.
              </p>
            </div>
            <StylishNewButton
              disabled={
                !reduxValidateRBACPermissionForActionResult['Write Pod Data'] ||
                !isUserPSC
              }
              type="button"
              className="button--primary"
              onClick={() => onSaveReviewedBy()}
            >
              Save
            </StylishNewButton>
            {!reduxValidateRBACPermissionForActionResult['Write Pod Data'] ||
              (!isUserPSC && (
                <span className="form-text form-error mb-3">
                  You don't have permission to edit this
                </span>
              ))}
          </>
        ) : (
          <p className="fst-italic weight-500 m-0">
            Planning Section Chief can be managed at the{' '}
            <Link to="/incident_configuration">Duty Roster</Link>{' '}
          </p>
        )}
      </div>
      {isEditOperationalPeriodOpen && (
        <EditOperationalPeriodDialog
          show={isEditOperationalPeriodOpen}
          onClose={() => setIsEditOperationalPeriodOpen(false)}
        />
      )}
      {isManageObjectivesDialogOpen && (
        <ManageObjectivesDialog
          show={isManageObjectivesDialogOpen}
          onClose={() => setIsManageObjectivesDialogOpen(false)}
          groupObjectives={groupObjectives}
          selectedObjectives={selectedObjectives}
          setSelectedObjectives={setSelectedObjectives}
        />
      )}
    </>
  );
}
