import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { getUserGuid } from 'slices/sliceHelpers';

const ics_resource_request_itemsSlice = createSlice({
    name: 'ics_resource_request_items',
    initialState: {
        ics_resource_request_items: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        fetchIcsResourceRequestItemsSuccess: (state, action) => {
            state.ics_resource_request_items = action.payload;
            state.status = 'succeeded';
        },
        createIcsResourceRequestItemSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        updateIcsResourceRequestItemSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    fetchIcsResourceRequestItemsSuccess,
    createIcsResourceRequestItemSuccess,
    updateIcsResourceRequestItemSuccess,
    setError
} = ics_resource_request_itemsSlice.actions;

export default ics_resource_request_itemsSlice.reducer;

export const createIcsResourceRequestItem = (data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_resource_request_items}`, {...data, user_guid});
        dispatch(fetchIcsResourceRequestItems(data.resource_request_id));
        dispatch(createIcsResourceRequestItemSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsResourceRequestItems = (resource_request_id) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_resource_request_items}/${resource_request_id}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsResourceRequestItemsSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsResourceRequestItem = (id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_resource_request_items}/${id}`, {...data, user_guid});
        dispatch(fetchIcsResourceRequestItems(data.resource_request_id));
        dispatch(updateIcsResourceRequestItemSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
