import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { ListGroupNavItem } from './ListGroupNavComponents';

const TaskManagementNavPanel: React.FC<{ navBack: () => void }> = ({
  navBack,
}) => {
  return (
    <nav aria-label="secondary sidebar" className="second-panel">
      <div className="second-panel-back">
        <button
          className="button button--icon border-0 bg-bglight"
          onClick={navBack}
        >
          <i className="material-symbols-outlined">arrow_back</i>
        </button>
        <div>Task Management</div>
      </div>
      <ListGroup>
        <div className="list-group-title">Critical Tasks</div>
        <ListGroupNavItem
          to="/task_management"
          icon="dashboard"
          label="Dashboard"
          end
        />
        <ListGroupNavItem
          to="/task_management/timeline"
          icon="timeline"
          label="Task Timeline"
        />
        <ListGroupNavItem
          to="/task_management/map"
          icon="map"
          label="Task Map"
        />
      </ListGroup>
    </nav>
  );
};

export default TaskManagementNavPanel;
