import React, { useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useFeatureFlag } from '../../../utils/featureFlags';

export const StylishNewTextEditor = ({
  className,
  onChange,
  readOnly = false,
  placeholder = '',
  initialValue = '',
  editorRef,
}) => {
  const tinymceApiKey = window.env.TINYMCE_API_KEY;
  const isOffline = useFeatureFlag('OFFLINE_ENVIRONMENT');
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (isOffline) {
      editorRef.current = {
        resetContent: (defaultValue) => {
          if (textAreaRef.current !== null) {
            textAreaRef.current.value = defaultValue;
          }
        },
        setContent: (defaultValue) => {
          if (textAreaRef.current !== null) {
            textAreaRef.current.value = defaultValue;
          }
        },
        plugins: {
          // NOTE: used in CreateMission.js (not sure how important this is)
          wordcount: {
            body: {
              getCharacterCount: () => {
                if (textAreaRef.current !== null) {
                  return textAreaRef.current.value.length;
                }
              },
            },
          },
        },
      };
    }
  }, []);

  return isOffline ? (
    <textarea
      className="form-control"
      defaultValue={initialValue}
      placeholder={placeholder}
      disabled={readOnly}
      ref={textAreaRef}
      onChange={(e) => onChange(e.target.value)}
    />
  ) : (
    <Editor
      apiKey={tinymceApiKey}
      onInit={(evt, editor) => {
        if (typeof editorRef === 'object') {
          editorRef.current = editor;
        }
      }}
      init={{
        className: 'form-editor ' + (className ? className : ''),
        plugins:
          'autolink charmap codesample emoticons image link lists searchreplace table visualblocks wordcount checklist casechange export formatpainter linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage mentions autocorrect inlinecss',
        toolbar:
          'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image table | spellcheckdialog a11ycheck | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        removed_menuitems: 'newdocument print',

        skin: 'oxide-dark',
        content_css: 'dark',
        /** We need to override the content styles inside content iframe. */
        content_style:
          '.mce-content-body [data-mce-selected=inline-boundary] { background-color: rgba(64, 153, 255, 0.2); }',
        readonly: readOnly,
        placeholder: placeholder,
      }}
      initialValue={initialValue}
      onEditorChange={(value) => {
        onChange(value);
      }}
    />
  );
};
