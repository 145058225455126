export const AnomalySetup = {
	id: "AnomalySetup_id",
	anomaly_type_name: "Setup",
	type_id: "Setup_id",
	anomaly_subtype_name: "",
	subtype_id: "None_id",
	name: "Setup",
	anomaly_detected_timestamp: "",
	provider_data: {
	},
	layout_settings: {

	},
	active_or_template: "Active",
}

export const AnomalySnow = {
	id: "AnomalySnow_id",
	anomaly_type_name: "Weather",
	type_id: "Weather_id",
	anomaly_subtype_name: "Snow",
	subtype_id: "Snow_id",
	name: "High Snowfall Event",
	anomaly_detected_timestamp: "02/14/2023 1230 UTC",
	provider_data: {
		"description": "This is bad snow.",
		"instruction": "Drive carefully"
	},
	layout_settings: {

	},
	active_or_template: "Active",
}

export const AnomalyHurricane = {
	id: "AnomalyHurricane_id",
	anomaly_type_name: "Weather",
	type_id: "Weather_id",
	anomaly_subtype_name: "Hurricane",
	subtype_id: "Hurricane_id",
	name: "Hurricane Florence",
	anomaly_detected_timestamp: "02/14/2023 1230 UTC",
	provider_data: {
		"description": "This is a bad hurricane.",
		"instruction": "Stay inside and away from windows."
	},
	layout_settings: {
		
	},
	active_or_template: "Active",
}