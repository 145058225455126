import config from 'constants/apiConfig';
import axios from 'axios';
import {
  GET_ALL_BRANCH,
  GET_ALL_DIVISION,
  GET_ALL_WORK_ASSIGNMENT,
  GET_ICS_OBJECTIVES_DATA,
  GET_PLANNING_WORKSHEET,
  UPSERT_COMMUNICATION,
} from 'constants/form-action-types';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import moment from 'moment';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

function parseError(api) {
  const errorMessage =
    `${api}` +
    'Unable to delete as there are associated data. ' +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
}

function parseCustomError(errorMessage) {
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPSERT_FORM_RECORD_ERROR,
  };
}

function parseError205(navigate) {
  const errorMessage =
    'There is no planning worksheet created. Please create one and continue.';
  console.error(errorMessage);
  toast.error(errorMessage);
  navigate('/incident_management');
}

export const getPlanningWorksheet = () => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.getPlanningWorksheet, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        operational_period_id: getState().app.currentIncident.current_period,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_PLANNING_WORKSHEET, payload: response.data });
        dispatch(endLoading());
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.getPlanningWorksheet, error));
        dispatch(endLoading());
      });
  };
};

export const getPlanningWorksheet205 = (navigate) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.getPlanningWorksheet, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        operational_period_id: getState().app.currentIncident.current_period,
      }),
    })
      .then((response) => {
        if (response.data?.id) {
          dispatch({ type: GET_PLANNING_WORKSHEET, payload: response.data });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        dispatch(parseError205(navigate));
      });
  };
};

export const upsertPlanningWorksheet = (type, data) => {
  return (dispatch, getState) => {
    let planningWorksheet = {
      ...getState().app.planningWorksheet,
      id: getState().app.planningWorksheet?.id
        ? getState().app.planningWorksheet.id
        : generateUUID(),
      operational_period_id: getState().app.currentIncident.current_period,
      prepared_by_guid: getState().app.user.user_guid,
    };

    if (type === 'Branch') {
      planningWorksheet.branch_id = data;
      planningWorksheet.division_id = null;
    }
    if (type === 'Division') {
      planningWorksheet.division_id = data;
    }
    if (type === 'Special Instructions') {
      planningWorksheet.form_special_instructions = data;
    }
    if (type === 'Emergency Communications') {
      planningWorksheet.medical_comm = data.medical_comm;
      planningWorksheet.evacuation_comm = data.evacuation_comm;
      planningWorksheet.other_comm = data.other_comm;
    }
    if (type === 'ReviewedByOSC') {
      planningWorksheet.osc_reviewed_by = data ? data.user_guid : null;
      planningWorksheet.osc_reviewed_date_time = data
        ? moment().toDate()
        : null;
    }
    if (type === 'ReviewedByPSC') {
      planningWorksheet.psc_reviewed_by = data ? data.user_guid : null;
      planningWorksheet.psc_reviewed_date_time = data
        ? moment().toDate()
        : null;
    }
    return axios(config.apiGateway.upsertPlanningWorkSheets, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        planningWorksheet,
      }),
    })
      .then(() => {
        dispatch(getPlanningWorksheet());
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.upsertPlanningWorkSheets, error));
      });
  };
};

export const getAllBranch = () => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.getAllBranch, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_ALL_BRANCH, payload: response.data });

        dispatch(endLoading());
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.getAllBranch, error));
      });
  };
};

export const upsertBranch = (branch) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.upsertBranch, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        branch,
      }),
    })
      .then(() => {
        dispatch(getAllBranch());
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.upsertBranch, error));
        dispatch(endLoading());
      });
  };
};

export const deleteBranch = (branch_id) => (dispatch, getState) =>
  axios(config.apiGateway.deleteBranch, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      branch_id,
    }),
  })
    .then(() => {
      dispatch(getAllBranch());
      dispatch(getPlanningWorksheet());
    })
    .catch((error) => {
      dispatch(endLoading());
      dispatch(parseError(config.apiGateway.deleteBranch, error));
    });

export const getAllDivision = (branch_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.getAllDivision, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        branch_id,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_ALL_DIVISION, payload: response.data });

        dispatch(endLoading());
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.getAllDivision, error));
      });
  };
};

export const getAllDivisionByGroupId = () => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.getAllDivisionByGroupId, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_ALL_DIVISION, payload: response.data });

        dispatch(endLoading());
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.getAllDivision, error));
      });
  };
};

export const upsertDivision = (division, from = '', branch_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.upsertDivision, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        division,
      }),
    })
      .then(() => {
        if (from === 'getAll') {
          dispatch(getAllDivisionByGroupId());
        } else if (branch_id) {
          dispatch(getAllDivision(branch_id));
        } else {
          dispatch(getAllDivision(division?.branch_id));
        }
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.upsertDivision, error));
        dispatch(endLoading());
      });
  };
};

export const deleteDivision = (division_id, branch_id, from) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteDivision, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        division_id,
      }),
    })
      .then(() => {
        if (from === 'getAll') {
          dispatch(getAllDivisionByGroupId());
        } else {
          dispatch(getAllDivision(branch_id));
        }
        dispatch(endLoading());
        dispatch(getPlanningWorksheet());
      })
      .catch((error) => {
        dispatch(endLoading());
        dispatch(parseError(config.apiGateway.deleteDivision, error));
      });
  };
};

export const getAllWorkAssignments = (branch_id, division_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.getAllWorkAssignments, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        operational_period_id: getState().app.currentIncident.current_period,
        branch_id,
        division_id,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_ALL_WORK_ASSIGNMENT, payload: response.data });
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.getAllWorkAssignments, error));
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

export const markPlanningWorkAssignmentToWorkAssignment = (workAssignment) => {
  return (dispatch, getState) => {
    let planningWorksheet = {
      ...getState().app.planningWorksheet,
      id: getState().app.planningWorksheet?.id
        ? getState().app.planningWorksheet.id
        : generateUUID(),
      operational_period_id: getState().app.currentIncident.current_period,
      prepared_by_guid: getState().app.user.user_guid,
    };
    return axios(config.apiGateway.markWorkAssignmentWithPlanningWorksheet, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        planningWorksheet,
        workAssignment,
      }),
    })
      .then(() => {
        dispatch(getPlanningWorksheet());
      })
      .catch((error) => {
        dispatch(
          parseError(
            config.apiGateway.markWorkAssignmentWithPlanningWorksheet,
            error
          )
        );
      });
  };
};

export const unmarkPlanningWorkAssignmentToWorkAssignment = (
  workAssignment
) => {
  return (dispatch, getState) => {
    let planningWorksheet = {
      ...getState().app.planningWorksheet,
      id: getState().app.planningWorksheet?.id
        ? getState().app.planningWorksheet.id
        : generateUUID(),
      operational_period_id: getState().app.currentIncident.current_period,
      prepared_by_guid: getState().app.user.user_guid,
    };
    return axios(config.apiGateway.unmarkWorkAssignmentWithPlanningWorksheet, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        planningWorksheet,
        workAssignment,
      }),
    })
      .then(() => {
        dispatch(getPlanningWorksheet());
      })
      .catch((error) => {
        dispatch(
          parseError(
            config.apiGateway.unmarkWorkAssignmentWithPlanningWorksheet,
            error
          )
        );
      });
  };
};

export const upsertCommunication = (communication) => {
  return (dispatch, getState) => {
    let planningWorksheet = {
      ...getState().app.planningWorksheet,
      id: getState().app.planningWorksheet?.id
        ? getState().app.planningWorksheet.id
        : generateUUID(),
      operational_period_id: getState().app.currentIncident.current_period,
      prepared_by_guid: getState().app.user.user_guid,
    };
    return axios(config.apiGateway.upsertPlanningWorksheetCommunication, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        communication,
        planningWorksheet,
      }),
    })
      .then(() => {
        dispatch(getPlanningWorksheet());
      })
      .catch((error) => {
        dispatch(
          parseError(
            config.apiGateway.upsertPlanningWorksheetCommunication,
            error
          )
        );
      });
  };
};

export const createUpdateCommunication = (communicationData) => (
  dispatch,
  getState
) => {
  const { planning_worksheet_id } = communicationData.communication;

  if (!planning_worksheet_id) {
    dispatch(
      parseCustomError(
        'Please create planning worksheet before creating communication!'
      )
    );
    return;
  } else {
    return axios(config.apiGateway.upsertPlanningWorksheetCommunication, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        ...communicationData,
      }),
    })
      .then(() => {
        dispatch(getPlanningWorksheet());
      })
      .catch((error) => {
        dispatch(
          parseError(
            config.apiGateway.upsertPlanningWorksheetCommunication,
            error
          )
        );
      });
  }
};

export const deleteCommunication = (communication_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deletePlanningWorksheetCommunication, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        communication_id,
      }),
    })
      .then(() => {
        dispatch(getPlanningWorksheet());
      })
      .catch((error) => {
        dispatch(
          parseError(
            config.apiGateway.deletePlanningWorksheetCommunication,
            error
          )
        );
        dispatch(endLoading());
      });
  };
};

export const updateWorkAssignmentResource = (workAssignmentResource) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.updateAssignmentResource, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        assignment_resource: workAssignmentResource,
      }),
    })
      .then(() => {
        dispatch(getAllWorkAssignments());
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.updateAssignmentResource, error));
      });
  };
};

export const updateWorkAssignmentStatus = (action_id, status) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.updateWorkAssignmentStatus, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        status,
        action_id,
      }),
    })
      .then(() => {
        dispatch(getAllWorkAssignments());
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.updateWorkAssignmentStatus, error)
        );
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

export const upsertICSObjectiveCommandEmphasis = (ics_202_data) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.upsertICSObjectiveCommandEmphasis, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        operational_period_id: getState().app.currentIncident.current_period,
        ics_202_data,
      }),
    })
      .then(() => {
        dispatch(getICSObjectiveCommandEmphasis());
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.upsertICSObjectiveCommandEmphasis, error)
        );
        dispatch(endLoading());
      });
  };
};

export const getICSObjectiveCommandEmphasis = () => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.getICSObjectiveCommandEmphasis, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        operational_period_id: getState().app.currentIncident.current_period,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_ICS_OBJECTIVES_DATA, payload: response.data });
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.getICSObjectiveCommandEmphasis, error)
        );
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

export const upsertCommunication205 = (communicationData) => (
  dispatch,
  getState
) =>
  axios(config.apiGateway.upsertCommunication, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      operational_period_id: getState().app.currentIncident.current_period,
      ...communicationData,
    }),
  })
    .then(() => {
      dispatch({ type: UPSERT_COMMUNICATION, payload: communicationData });
    })
    .catch((error) => {
      dispatch(parseError(config.apiGateway.upsertCommunication, error));
    })
    .finally(() => {
      dispatch(endLoading());
    });

export const fetchCommunication205 = (planning_worksheet_id) => (
  dispatch,
  getState
) =>
  axios(config.apiGateway.fetchCommunication, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      operational_period_id: getState().app.currentIncident.current_period,
      planning_worksheet_id: planning_worksheet_id,
    }),
  })
    .then((response) => {
      if (!response.data?.length) {
        dispatch(
          upsertCommunication205({
            id: generateUUID(),
            planning_worksheet_id,
          })
        );
      } else {
        dispatch({ type: UPSERT_COMMUNICATION, payload: response.data[0] });
      }
    })
    .catch((error) => {
      dispatch(parseError(config.apiGateway.fetchCommunication, error));
    })
    .finally(() => {
      dispatch(endLoading());
    });
