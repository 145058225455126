import React, { useState, useRef, useEffect } from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import { MdEdit } from 'react-icons/md';
import { useUpdateDisasterChat, useDeleteDisasterChat } from './hooks/useDisasterChats';
import * as dayjs from 'dayjs';
import { Modal } from 'react-bootstrap';

const DisasterChatItem = ({ chat, onClick }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(chat.name);
    const inputRef = useRef(null);
    const editDisasterChat = useUpdateDisasterChat();
    const deleteDisasterChat = useDeleteDisasterChat();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleRename = () => {
        if (newName !== chat.name) {
            editDisasterChat.mutate({ name: newName, dchat_id: chat.id });
        }
        setIsEditing(false);
    };

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setIsEditing(false);
        }
    };

    useEffect(() => {
        if (isEditing) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isEditing]);

    const handleDelete = () => {
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        deleteDisasterChat.mutate(chat.id);
        setShowDeleteModal(false);
    };

    const handleCancelDelete = () => {
        setShowDeleteModal(false);
    };

    return (
      <li className="disaster-chat-item" style={{ marginBottom: '8px' }}>
        {isEditing ? (
          <input
            ref={inputRef}
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            onBlur={handleRename}
            onKeyDown={(e) => e.key === 'Enter' && handleRename()}
          />
        ) : (
          <StylishNewButton
            className="button--secondary me-3"
            onClick={onClick}
          >
            <span>
                <span style={{ fontWeight: 'bold', display: 'block', textAlign: 'left' }}>{chat.name}</span>
                <div>
                  <em>Last Modified: </em>{dayjs(new Date(chat.timestamp).toLocaleString()).format('YYYY-MM-DD HH:mm UTC')}
                </div>
              </span>
          </StylishNewButton>
        )}
        <div className="options">
          <StylishNewButton
              className="button button--link"
              onClick={() => setIsEditing(true)}
          >
              <MdEdit /> Rename
          </StylishNewButton>
          <StylishNewButton
              className="button button--link delete"
              onClick={handleDelete}
          >
              <SharedIcon iconName="delete" classes="me-2" /> Delete
          </StylishNewButton>
        </div>
        <Modal
          show={showDeleteModal}
          onHide={handleCancelDelete}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Session</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Delete this DisasterChat session?
          </Modal.Body>
          <Modal.Footer>
            <StylishNewButton variant="secondary" onClick={handleCancelDelete}>
              Cancel
            </StylishNewButton>
            <StylishNewButton variant="danger" onClick={handleConfirmDelete}>
              Delete
            </StylishNewButton>
          </Modal.Footer>
        </Modal>
      </li>
    );
};

export default DisasterChatItem;
