import React from 'react';

import dayjs from 'dayjs';

import './IncidentReportDetails.css';

export default function IncidentReportDetails({ report }) {
  return (
    <div className="report-detail-container">
      <h5 className="mb-1">{report?.incident_name}</h5>
      {((!report?.aor_id && !report?.aor_location) ||
        (report?.aor_id && !report?.aor_location)) && (
        <div className="mb-3">
          <span className="d-block fst-italic">
            This Category Item has no geolocation.
          </span>
        </div>
      )}
      <hr className="dashed m-0" />
      <div className="EventDetails-Specifics">
        <div className="mt-3">
          <span className="weight-600 d-block">Category Item Name: </span>
          {report.categoryName}
        </div>
        <div className="mt-3">
          <span className="weight-600 d-block">
            Is this a QI (Qualified Individual) Regulatory Drill?:{' '}
          </span>
          {report.is_qi ? 'Yes' : 'No'}
        </div>
        {!!report?.incident_types && (
          <div className="mt-3">
            <span className="weight-600 d-block">Incident Type: </span>
            {report?.incident_types.join(', ')}
          </div>
        )}
        {!!report?.start_date && (
          <div className="mt-3">
            <span className="weight-600 d-block">Start Date: </span>
            {dayjs(report?.start_date).utc().format('YYYY-MM-DD')} UTC
          </div>
        )}
        {!!report?.current_status && (
          <div className="mt-3">
            <span className="weight-600 d-block">Status: </span>
            {report?.current_status}
          </div>
        )}
        {!!report?.incident_description && (
          <div className="mt-3">
            <span className="weight-600 d-block">Brief Synopsis: </span>
            {report?.incident_description}
          </div>
        )}
        {!!report?.teams_activated_list && (
          <div className="mt-3">
            <span className="weight-600 d-block">
              Relevant Teams Identified:{' '}
            </span>
            {report?.teams_activated_list.join(', ')}
          </div>
        )}
      </div>
    </div>
  );
}
