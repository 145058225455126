import { SharedIcon } from '../SharedIcon/SharedIcon';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { allRecipientTypes } from './recipientTypes';
import { ContactMethod } from './jobs/AddNotificationJobDrawer';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';

type FormSelectMethodProps = {
  fieldName: string;
  rules?: { [key: string]: { value: any; message: string } };
};

const FormSelectMethod: React.FC<FormSelectMethodProps> = ({
  fieldName,
  rules,
}) => {
  const {
    register,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  register(fieldName, rules);
  const fieldValue: ContactMethod[] = watch(fieldName);

  const handleButtonClick = (value: ContactMethod) => {
    if (fieldValue.includes(value)) {
      setValue(
        fieldName,
        fieldValue.filter((v) => v !== value)
      );
    } else {
      setValue(fieldName, [...fieldValue, value]);
    }
  };

  useEffect(() => {
    if (rules?.required?.value && fieldValue.length === 0) {
      setError(fieldName, { message: rules?.required.message });
    } else if (errors[fieldName]) {
      clearErrors(fieldName);
    }
  }, [fieldValue]);

  return (
    <>
      <div className="d-flex mt-3">
        {allRecipientTypes.map((option) => {
          const selected = fieldValue.includes(option.label);
          return (
            <StylishNewButton
              key={option.label}
              onClick={() => handleButtonClick(option.label)}
              className={classNames(selected ? 'button--secondary-fill' : 'button--secondary', 'me-2')}
            >
              <SharedIcon
                iconName={selected ? 'check' : option.icon}
                classes={'me-2'}
                color={selected ? 'success' : 'light'}
              />
              {option.label}
            </StylishNewButton>
          );
        })}
      </div>
      {errors[fieldName] ? (
        <div className="form-text form-error">
          {errors[fieldName].message as string}
        </div>
      ) : (
        <div className="form-text-none"></div>
      )}
    </>
  );
};
export default FormSelectMethod;