import trafficLayers from '../constants/trafficlayers';

export default function trafficToggleEffect(map, trafficToggle)
{
	if (!!map && trafficToggle) {
    if (!map.getSource('trafficSource')) {
      map.addSource('trafficSource', {
        type: 'vector',
        url: 'mapbox://mapbox.mapbox-traffic-v1'
      })
    }

    let {id: poiId} = map.getStyle().layers.find(function(obj){ 
      return obj["source-layer"] === "poi_label";
    }) || {};

    for(var i = 0; i < trafficLayers.length; i++) 
    {
      !!trafficLayers[i] && !!trafficLayers[i].id && !map.getLayer(trafficLayers[i].id) && map.addLayer(trafficLayers[i], (poiId || 'gl-draw-polygon-fill-inactive.cold'));
    }
  } else {
    if (map.getSource('trafficSource')) {
      map.getStyle().layers.filter(l=>l.source==="trafficSource").forEach(l=> {
        !!map.getLayer(l.id) && map.removeLayer(l.id);
      })
      map.removeSource('trafficSource')
    }
  }
}