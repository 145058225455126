import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import {
  selectGroupGuid,
  selectUserGuid,
} from '../../../slices/commonSelectors';
import { toastConfig } from '../../../assets/data/config';
import { toast } from 'react-toastify';
import { keys as incidentTacticKeys } from './incidentTacticHooks';

export type Tactic = {
  id: UUID;
  group_guid: UUID;
  name: string;
  description: string;
  archived: boolean;
  work_assignments: UUID[];
};

export const keys = {
  base: 'tactics',
  get all() {
    return [keys.base];
  },
  get: function (group_guid: UUID, user_guid: UUID) {
    return [keys.base, group_guid, user_guid];
  },
} as const;

export const useTactics = () => {
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.get(group_guid, user_guid),
    queryFn: async () => {
      const resp = await axios.get<Tactic[]>(
        `${config.apiGateway.ics_tactics}/${group_guid}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
  });
};

export type CreateTacticReqData = {
  name: string;
  description: string;
  incident_id?: UUID;
  incident_resources?: UUID[];
};

export const useCreateTactic = () => {
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: CreateTacticReqData) => {
      return await axios.post<CreateTacticReqData, Tactic>(
        `${config.apiGateway.ics_tactics}`,
        {
          ...data,
          group_guid,
          user_guid,
        }
      );
    },
    onSuccess: (_, reqObj) => {
      queryClient.invalidateQueries({
        queryKey: keys.get(group_guid, user_guid),
      });
      if (reqObj.incident_id) {
        queryClient.invalidateQueries({
          queryKey: incidentTacticKeys.all,
        });
      }
    },
    onError: (error) => {
      toast.error(error.message, toastConfig as any);
    },
  });
};