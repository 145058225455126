import React from 'react';
import { useSelector } from 'react-redux';

export default function Loader({ showLoading = false, classes = '' }) {
  const isLoading = useSelector((state) => state.loading.isLoading);
  return (
    (isLoading || showLoading) && (
      <div className={'loader ' + classes}>
        <div className="inner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  );
}
