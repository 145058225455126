// Get the highest number of Custom Layers currently in use
export default function getUserLayerNumber(layers)
{
	let highestNumber = 0
	layers
	.filter(l=>l.id.includes("Custom Layer"))
	.forEach(l=>{
		const num = parseInt(l.id.split("Custom Layer ")[1].split(" ")[0])
		if(num>highestNumber) highestNumber = num
	})
	return highestNumber + 1
}