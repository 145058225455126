import React, {useState, useEffect} from 'react';

import ReactFlow, {
  removeElements,
  addEdge,
  Controls,
  Background,
} from 'react-flow-renderer';

import './FormWorkflow.css'
import Loading from 'components/Loading/Loading'

export default function FormWorkflow({
  formConfig,
  currentState,
  currentRole,
  currentFormIsMostRecentForm,
  userCanRepeatTransition,
}) 
{

const onLoad = (reactFlowInstance) => {
  reactFlowInstance.fitView();
};

// Background will be button-blue for call-to-action if the current user is expected to do that part, otherwise will be a different c
function createStateColors(form_state)
{
//() ?  : '#D6D5E6',
  // If current state is not given form state, background is white
  if(form_state.state_name!==currentState)
  {
    return {
      background: '#FFFFFF',
      color: '#000'
    }
  }
  else if(!!currentFormIsMostRecentForm && (!!userCanRepeatTransition || formConfig.form_state_transitions.find(fst=>fst.from===currentState && fst.transition_conditions.valid_seats.find(vs=>vs===currentRole))))
  {
    // This color denotes the call-to-action, that this state represents activity that the current user is capable of carrying out to move to the next state
    return {
      background: '#2579CA',
      color: '#FFF'
    }
  }
  else
  {
    // This color denotes the current state, but that the user cannot take any further action.
    return {
      background: '#D6D5E6',
      color: '#000'
    }
  }
}

// Change style based on form state and current state
function createStateStyle(form_state)
{
  if(form_state.state_name===currentState)
  { 
    return  {
      ...createStateColors(form_state),
      border: '1px solid #222138',
      width: 180,
    }
  }
  else 
  {
    return  {
      ...createStateColors(form_state),
      border: '1px solid #222138',
      width: 180,
    }
  }
}

function createEdgeLabel(form_state_transition)
{
  return `${form_state_transition.transition_conditions.valid_seats.map(vs=>{
    return (vs===currentRole) ? `${vs} (You)` : `${vs}`
  }).join(", ")}: ${form_state_transition.action}`
}

function getAnimatedStatus(fst)
{
  return !!currentFormIsMostRecentForm && !!fst.transition_conditions.valid_seats.find(vs=>vs===currentRole) && fst.from === currentState;
}

// Remap form config objects into workflow
function formConfigToWorkflow()
{
 return  [
    ...formConfig.form_states.map(fs=>{
      return {
        ...fs.state_node_workflow_settings,
        id: fs.state_name,
        style: createStateStyle(fs),
        data: {
          ...fs.state_node_workflow_settings.data,
          label: (
              <>
                <strong>{fs.state_name}</strong>
              </>
            ),
        }
      }
    }),
    ...formConfig.form_state_transitions.map(fst=>{
      return {
        ...fst.transition_edge_workflow_settings,
        source: fst.from,
        target: fst.to,
        id: `${fst.from} to ${fst.to}`,
        label: createEdgeLabel(fst),
        animated: getAnimatedStatus(fst),
      }
    })
  ]
}

const OverviewFlow = () => {
  const [elements, setElements] = useState(formConfigToWorkflow())
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));
  const onConnect = (params) => setElements((els) => addEdge(params, els));

  // This is used to prevent content "sticking" to certain zoom/coordinates from prior invocations
  const [renderCount, setRenderCount] = useState(0)
  useEffect(() => {
    if(renderCount < 2) setRenderCount(renderCount+1)
  },[renderCount])

  return (
    <>
      {renderCount % 2 === 0 && (
        <ReactFlow
          elements={elements}
          onElementsRemove={onElementsRemove}
          onConnect={onConnect}
          onLoad={onLoad}
          snapToGrid={true}
          snapGrid={[15, 15]}
          style={{height: "100%"}}
        >
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      ) || (
        <Loading />
      )}
    </>
  );
};

  return (
    <div className="FormWorkflow">
      <OverviewFlow />
    </div>
  )
}