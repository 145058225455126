// usePromptLibraryHooks.js
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useSelector } from 'react-redux';
import config from 'constants/apiConfig';
import { selectGroupGuid, selectUserGuid } from '../../../slices/commonSelectors';

// Prompt Library keys
const keys = {
  base: 'promptLibrary',
  list: (group_guid) => [keys.base, group_guid],
  prompt: (id) => [keys.base, id],
};

// Fetch all non-archived prompts
export const usePrompts = (group_guid) => {
  return useQuery({
    queryKey: keys.list(group_guid),
    queryFn: async () => {
      const { data } = await axios.get(`${config.apiGateway.prompt_library}?group_guid=${group_guid}`);
      return data;
    },
    enabled: !!group_guid,
  });
};

// Create a new prompt
export const useCreatePrompt = () => {
  const queryClient = useQueryClient();
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);

  return useMutation({
    mutationFn: async (newPrompt) => {
      const { data } = await axios.post(config.apiGateway.prompt_library, {
        ...newPrompt,
        group_guid,
        user_guid,
      });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(group_guid) });
    },
  });
};

// Edit an existing prompt
export const useEditPrompt = () => {
  const queryClient = useQueryClient();
  const group_guid = useSelector(selectGroupGuid); // Retrieve group_guid
  const user_guid = useSelector(selectUserGuid);

  return useMutation({
    mutationFn: async (updatedPrompt) => {
      const { data } = await axios.put(`${config.apiGateway.prompt_library}/${updatedPrompt?.id}`, {
        ...updatedPrompt,
        group_guid,
        user_guid,
      });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(group_guid) }); // Use group_guid here
    },
  });
};
