import React from 'react';

import { TASK_STATUS_LIST, TASK_TYPE } from './TaskManagementConstant';
import moment from 'moment';
import { useSelector } from 'react-redux';

export default function TaskManagementDetails({ selectedTask, }) {
    const rostersForGroup = useSelector((state) => {
        return state.app.rostersForGroups || [];
    });
    const teamList = useSelector((state) => {
        return state.app.distributionListItems;
    });
    return (
        // <div className="form-block mb-4">
        <div className="row">
            {/* <div className="col-md-12 mb-4">
                    <h4 className="m-0">Details</h4>
                </div> */}
            <div className="col-md-4 mb-3">
                <h6 className="mb-1">Critical Process</h6>
                {selectedTask.title}
            </div>
            <div className="col-md-4 mb-3">
                <h6 className="mb-1">Critical Process Description</h6>
                {selectedTask.content}
            </div>
            <div className="col-md-4 mb-3">
                <h6 className="mb-1">Status</h6>
                {TASK_STATUS_LIST.find(e => e.label === selectedTask.status)?.label}
            </div>
            <div className="col-md-4 mb-3">
                <h6 className="mb-1">Type</h6>
                {TASK_TYPE.find(e => e.value === selectedTask.type)?.label}
            </div>
            <div className="col-md-4 mb-3">
                <h6 className="mb-1">Due Date</h6>
                {selectedTask?.deadline_date ? moment(selectedTask.deadline_date).format('MMMM Do YYYY') : "N/A"}
            </div>
            <div className="col-md-4 mb-3">
                <h6 className="mb-1">Unit</h6>
                {selectedTask.unit}
            </div>
            <div className="col-md-4 mb-3">
                <h6 className="mb-1">RTO</h6>
                {selectedTask.rto}
            </div>
            <div className="col-md-4 mb-3">
                <h6 className="mb-1">Business Leadership Team Tag</h6>
                {selectedTask.business_leadership_team_tag}
            </div>
            <div className="col-md-4 mb-3">
                <h6 className="mb-1">Documents Needed</h6>
                {selectedTask.documents_needed}
            </div>
            <div className="col-md-4 mb-3">
                <h6 className="mb-1">Business Leadership Team</h6>
                {teamList.find(e => e.id === selectedTask.reporter_team_guid)?.name || ''}
            </div>
            <div className="col-md-4 mb-3">
                <h6 className="mb-1">Business Leadership Team Member</h6>
                {rostersForGroup.find(e => e.user_guid === selectedTask.reporter_member_guid)?.user_name || rostersForGroup.find(e => e.user_guid === selectedTask.reporter_member_guid)?.email_address || ''}
            </div>
            <div className="col-md-4 mb-3">
                <h6 className="mb-1">Process Owner Team</h6>
                {teamList.find(e => e.id === selectedTask.assignee_team_guid)?.name || ''}
            </div>
            <div className="col-md-12">
                <hr className='mt-0 mb-3' />
            </div>
            {selectedTask.process_owner.map((e, index) => {
                return (
                    <>
                        <div className="col-md-12" key={e?.id}>
                            {index !== 0 ? <hr className='my-3 dashed' /> : null}
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <h6 className="mb-0"> {index === 0 ? 'Process Owner' : `Alternate Process Owner ${index}`}</h6>
                                </div>
                                <div className="col-md-6 mb-3 mb-md-0">
                                    <h6 className="mb-1">Process Owner</h6>
                                    {e.user_name || e.email_address}
                                </div>
                                <div className="col-md-6 mb-3 mb-md-0">
                                    <h6 className="mb-1">Process Owner Email</h6>
                                    {e.email_address}
                                </div>
                                <div className="col-md-6">
                                    <h6 className="mb-1">Process Owner Business</h6>
                                    {e.process_owner_business}
                                </div>
                                <div className="col-md-6">
                                    <h6 className="mb-1">Process Owner Business</h6>
                                    {e.process_owner_business}
                                </div>
                            </div>
                        </div>
                    </>
                )
            })}

        </div>
        // </div>
    )
}
