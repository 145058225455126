import { ContactMethod } from './jobs/AddNotificationJobDrawer';
import { v7 } from 'uuid';

export type RecipientObject = {
  id: UUID;
  content: string;
  type: string;
};

export default function generateNotificationObject(
  subject: string,
  message: string,
  groupGuid: UUID,
  recipientTypes: ContactMethod[],

  emailRecipients?: RecipientObject[] | string[],
  smsRecipients?: RecipientObject[] | string[],
  smsMessage?: string,
  ttsHomeRecipients?: RecipientObject[] | string[],
  ttsCellRecipients?: RecipientObject[] | string[],
  ttsMessage?: string,
  groupMembersToNotify?: UUID[],
  selectedDistributionLists?: UUID[],
  disabledGuids?: UUID[],

  notification_id?: UUID,
  templateTitle?: string
) {
  if (isStringArray(emailRecipients)) {
    emailRecipients = emailRecipients.map((email) => ({
      id: v7(),
      content: email,
      type: 'emailRecipients',
    }));
  }
  if (isStringArray(smsRecipients)) {
    smsRecipients = smsRecipients.map((email) => ({
      id: v7(),
      content: email,
      type: 'SMSRecipients',
    }));
  }
  if (isStringArray(ttsHomeRecipients)) {
    ttsHomeRecipients = ttsHomeRecipients.map((email) => ({
      id: v7(),
      content: email,
      type: 'TTSCellRecipients',
    }));
  }
  if (isStringArray(ttsCellRecipients)) {
    ttsCellRecipients = ttsCellRecipients.map((email) => ({
      id: v7(),
      content: email,
      type: 'TTSCellRecipients',
    }));
  }
  return {
    notification_id: notification_id ?? v7(),
    template_title: templateTitle,
    subject: subject,
    message: message,
    group_guid: groupGuid,
    settings: {
      recipientSettings: {
        recipientTypes: recipientTypes,
        emailRecipientSettings: {
          emailRecipients: emailRecipients,
          emailSubject: subject,
          emailMessage: message,
        },
        SMSRecipientSettings: {
          SMSRecipients: smsRecipients,
          SMSMessage: smsMessage,
        },
        TTSRecipientSettings: {
          TTSHomeRecipients: ttsHomeRecipients,
          TTSCellRecipients: ttsCellRecipients,
          TTSMessage: ttsMessage,
        },
        // pagerRecipientSettings: {
        //   pagerRecipients: nonMembers.filter(
        //     (ele) => ele.type === 'pagerRecipients'
        //   ),
        //   pagerMessage: pagerMessage,
        // },
        groupMembersToNotify: groupMembersToNotify,
        distributionLists: selectedDistributionLists,
        disabledGuids: disabledGuids,
      },
      contentSettings: {
        TTSMessage: ttsMessage,
        // pagerMessage: pagerMessage,
      },
    },
  };
}

export type DiceNotification = ReturnType<typeof generateNotificationObject>;

function isStringArray(arr?: any[]): arr is string[] {
  if (!arr) return false;
  return arr.every((item) => typeof item === 'string');
}

export function getEmailRecipients(notification: DiceNotification) {
  const emails =
    notification.settings.recipientSettings.emailRecipientSettings.emailRecipients?.map(
      (rcp) => rcp.content
    ) ?? [];
  return emails.join(', ');
}

export function getSmsRecipients(notification: DiceNotification) {
  const sms =
    notification.settings.recipientSettings.SMSRecipientSettings.SMSRecipients?.map(
      (rcp) => rcp.content
    ) ?? [];
  return sms.join(', ');
}

export function getTtsRecipients(notification: DiceNotification) {
  const ttsHome =
    notification.settings.recipientSettings.TTSRecipientSettings.TTSHomeRecipients?.map(
      (rcp) => rcp.content
    ) ?? [];
  const ttsCell =
    notification.settings.recipientSettings.TTSRecipientSettings.TTSCellRecipients?.map(
      (rcp) => rcp.content
    ) ?? [];
  return ttsCell.concat(ttsHome).join(', ');
}

export function getGroupRecipients(notification: DiceNotification, roster: any[]) {
  const groupMembersToNotify =
    notification.settings.recipientSettings.groupMembersToNotify ?? [];
  return roster
    .filter((user: any) => groupMembersToNotify.includes(user.user_guid))
    .map((user: any) => user.email_address)
    .join(', ');
}
