import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
    selectedEventTypes: [],
}

const eventMonitorSlice = createSlice({
  name: 'eventMonitor',
  initialState,
  reducers: {
    setSelectedEventTypes(state, action) {
      state.selectedEventTypes = action.payload
    },
  },
})

export const { setSelectedEventTypes } = eventMonitorSlice.actions
export const selectedEventTypes = state => state.eventMonitor.selectedEventTypes
export default eventMonitorSlice
