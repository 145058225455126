import React, { useState } from 'react';
import { usePrompts, useCreatePrompt, useEditPrompt } from './hooks/usePromptLibraryHooks';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { Card } from 'react-bootstrap';
import { useToggle } from 'react-use';
import DrawerWrapper, { DrawerFooter } from 'components/IAP/DrawerWrapper';
import EntityList from 'components/IAP/EntityList';
import { useForm, FormProvider } from 'react-hook-form';
import FormTextField from 'components/IAP/formFields/FormTextField';
import FormTextAreaField from 'components/IAP/formFields/FormTextAreaField';
import classNames from 'classnames';
import { MdEdit } from 'react-icons/md';
import PromptDrawer from './PromptDrawer'

const PromptLibraryList = ({ group_guid }) => {
  const { data: prompts } = usePrompts(group_guid);
  const createPrompt = useCreatePrompt();
  const editPrompt = useEditPrompt();

  const [expanded, toggleExpanded] = useToggle(true);
  const [editOpen, toggleEditDrawer] = useToggle(false);
  const [addOpen, toggleAddDrawer] = useToggle(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  return (
    <>
      <EntityList
        entities={prompts}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        header={
          <div className="d-flex align-items-center justify-content-between flex-grow-1">
            <span>Prompt Library</span>
            <StylishNewButton
              className={classNames('button', {
                'button--primary': expanded,
                'button--secondary': !expanded,
              })}
              onClick={toggleAddDrawer}
            >
              Add Prompt
            </StylishNewButton>
          </div>
        }
        renderEntity={(prompt) => (
          <Card
            key={prompt.id}
            className={`bg-neutral-800 my-1 rounded-1 flex-row align-items-center justify-content-between 
              ${selectedEntity?.id === prompt.id ? 'selected' : ''}`}
            style={{ padding: '0.625rem 1rem', cursor: 'pointer' }}
            onClick={() => setSelectedEntity(prompt)}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#333')}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '')}
          >
            <span>{prompt.title}</span>
            <StylishNewButton
              className="button button--link"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedEntity(prompt);
                toggleEditDrawer();
              }}
            >
              <MdEdit />
            </StylishNewButton>
          </Card>
        )}
      />

      {addOpen && <PromptDrawer toggle={toggleAddDrawer} onSubmit={createPrompt.mutate} />}
      {editOpen && selectedEntity && (
        <PromptDrawer
          toggle={toggleEditDrawer}
          selectedEntity={selectedEntity}
          onSubmit={(data) => editPrompt.mutate({ ...data })}
        />
      )}
    </>
  );
};

export default PromptLibraryList;
