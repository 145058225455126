import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import { selectUserGuid } from '../../../slices/commonSelectors';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../assets/data/config';
import {
  CreateTacticReqData,
  keys as tacticKeys,
  useCreateTactic,
} from './tacticHooks';

import { useActualIncidentId } from './commonHooks';

type IncidentTactics = {
  ics_incident_tactics: [];
}



export type IncidentTactic = {
  id: UUID;
  tactic_id: UUID;
  incident_id: UUID;
  user_guid: UUID;
  version: number;
  name: string;
  description: string;
  timestamp: Date;
  archived: boolean;
  work_assignments: UUID[];
  incident_resources: UUID[];
  user_name: string;
} & {
  strategies: UUID[];
};

export const keys = {
  base: 'incident_tactics',
  get all() { return [keys.base]},
  list: (incidentId: UUID, userId: UUID) => [keys.base, incidentId, userId],
} as const;

export const useIncidentTactics = () => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.list(incidentId, user_guid),
    queryFn: async () => {
      const resp = await axios.get<IncidentTactic[]>(
        `${config.apiGateway.ics_incident_tactics}/${incidentId}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
  });
};

/** NOTE: this posts to the group tactic which will create the incident version.  */
export const useCreateIncidentTactic = () => {
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  const mutation = useCreateTactic();
  return {
    ...mutation,
    mutateAsync: (data: CreateTacticReqData) => {
      data.incident_id = incidentId;
      data.incident_resources = data.incident_resources ?? [];
      return mutation.mutateAsync(data, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: tacticKeys.all });
          queryClient.invalidateQueries({ queryKey: [keys.base] });
        },
      });
    },
  };
};

interface EditIncidentTacticReqData {
  tactic_id: UUID;
  name: string;
  description: string;
  archived: boolean;
  work_assignments: UUID[];
  incident_resources: UUID[];
}

export const useEditIncidentTactic = (tacticId: UUID) => {
  const user_guid = useSelector(selectUserGuid);
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: EditIncidentTacticReqData) => {
      return await axios.put<EditIncidentTacticReqData, IncidentTactic>(
        `${config.apiGateway.ics_incident_tactics}/${incidentId}/${tacticId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keys.list(incidentId, user_guid),
      });
      queryClient.invalidateQueries({
        queryKey: tacticKeys.all,
      });
    },
    onError: (error) => {
      toast.error(error.message, toastConfig as any);
    },
  });
};