import { SharedIcon } from 'components/SharedIcon/SharedIcon';

interface Props {
  title: string;
  description: string;
  onButtonClick: () => void;
  icon: string;
  buttonText: string;
}

export const IconContainerWithButton = ({
  title,
  description,
  icon,
  onButtonClick,
  buttonText,
}: Props) => {
  return (
    <div onClick={onButtonClick} className="icon-container-with-button">
      <div className="icon-text-container">
        <div className="icon"><SharedIcon iconName={icon} /></div>
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>
      <div className='button-container'>
        <button className="button" onClick={onButtonClick} >{buttonText}</button>
      </div>
    </div>
  );
};
