import { Offcanvas, Row } from 'react-bootstrap';
import React from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { AddEntityDrawerProps } from './EntityListProps';
import { useCreateIncidentObjective } from './hooks/incidentObjectiveHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';

type FormFields = {
  name: string;
  description: string;
};

const ObjectiveAddDrawer: React.FC<AddEntityDrawerProps> = ({ toggle }) => {
  const form = useForm<FormFields>({
    mode: 'onChange',
  });
  const {
    handleSubmit,
  } = form

  const mutation = useCreateIncidentObjective();

  const onNewObjectiveDataSubmit = async (formDate: FormFields) => {
    const payload = {
      name: formDate.name,
      description: formDate.description,
      archived: false,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  // TODO: this form _actually_ needs first show group objectives.
  // The rest of this is more of a "create" function. I'm worried about
  // multiple drawers. They won't make a lot of sense. I wonder if an inline form is better
  // We already have multiple modals and that's not very good.

  return (
    <DrawerWrapper toggle={toggle} title="Add Objective">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onNewObjectiveDataSubmit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextField
                title="Name"
                fieldName="name"
                rules={{
                  required: { value: true, message: 'This field is required' },
                  maxLength: {
                    value: 300,
                    message: 'This maximum length is 300 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Description"
                fieldName="description"
                rules={{
                  maxLength: {
                    value: 300,
                    message: 'This maximum length is 300 characters',
                  },
                }}
                variant="full"
              />
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default ObjectiveAddDrawer;
