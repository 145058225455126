export const LOAD_DATASET_REQUEST = 'LOAD_DATASET_REQUEST'
export const LOAD_DATASET_SUCCESS = 'LOAD_DATASET_SUCCESS'
export const LOAD_DATASET_ERROR = 'LOAD_DATASET_ERROR'
export const FETCH_ODDS_METADATASETS_REQUEST = 'FETCH_ODDS_METADATASETS_REQUEST'
export const FETCH_ODDS_METADATASETS_SUCCESS = 'FETCH_ODDS_METADATASETS_SUCCESS'
export const FETCH_ODDS_METADATASETS_ERROR = 'FETCH_ODDS_METADATASETS_ERROR'
export const FETCH_ODDS_DATASET_REQUEST = 'FETCH_ODDS_DATASET_REQUEST'
export const FETCH_ODDS_DATASET_SUCCESS = 'FETCH_ODDS_DATASET_SUCCESS'
export const FETCH_ODDS_DATASET_ERROR = 'FETCH_ODDS_DATASET_ERROR'
export const FETCH_ODDS_DATASET_FOR_DASHBOARD_REQUEST = 'FETCH_ODDS_DATASET_FOR_DASHBOARD_REQUEST';
export const FETCH_ODDS_DATASET_FOR_DASHBOARD_SUCCESS = 'FETCH_ODDS_DATASET_FOR_DASHBOARD_SUCCESS';
export const FETCH_ODDS_DATASET_FOR_DASHBOARD_ERROR = 'FETCH_ODDS_DATASET_FOR_DASHBOARD_ERROR';