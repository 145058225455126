import React, { useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import { SharedIcon } from '../../SharedIcon/SharedIcon';
import { StylishNewToggleButtonGroup } from '../../DesignSystems/New/StylishNewToggleButtonGroup';
import FormSearchField from '../../IAP/formFields/FormSearchField';
import FormTextField from '../../IAP/formFields/FormTextField';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '../../../slices/commonSelectors';

type RecipientType = 'Members' | 'Teams' | 'AORs';

export const SelectRecipientsStep = () => {
  const { watch } = useFormContext();
  const template = watch('template');
  const templates = useAppSelector((s) => s.app.notifications);
  const templateName = templates?.find((t: any) => t.notification_id === template)?.template_title;

  return template ? (
    <Row>
      <div className="txt--sml txt--bold pb-1">Selected Template:</div>
      <div className="txt--sml text-weak">{templateName}</div>
    </Row>
  ) : (
    <>
      <Row className={'py-3'}>
        <div className="txt--sml txt--bold pb-1">Select Recipients</div>
        <div className="txt--sml text-weak">
          Choose the teams or individuals who need to receive this notification.
        </div>
      </Row>
      <Row>
        <FormTextField
          title={'Email address to notify'}
          fieldName={'email'}
          variant={'half'}
        />
        <FormTextField
          title={'Phone number to notify'}
          fieldName={'sms'}
          variant={'half'}
          rules={{
            pattern: {
              value: /^[0-9]{7,10}$/,
              message: 'Please enter a valid phone number',
            },
          }}
        />
      </Row>
    </>
  );
};
