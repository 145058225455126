import React, { useState } from 'react';
import { useTranslate } from 'react-polyglot';
import { useDispatch, useSelector } from 'react-redux';
import EditGroupModal from './EditGroupModal';
import DeleteGroupModal from './DeleteGroupModal';
import { getInitials } from '../../helpers/intials';
import { deleteGroup } from 'actions/roleActions';

const OrgProfile: React.FC = () => {
  const dispatch = useDispatch();

  const t = useTranslate();
  const selectedGroup = useSelector(
    (s: any) => s.app.currentlySelectedGroup as Group
  );
  const [editOrgModal, setEditOrgModal] = useState(false);
  const [deleteOrgModal, setDeleteOrgModal] = useState(false);

  const onDeleteConfirmDialog = () => {
    dispatch(deleteGroup(selectedGroup.group_guid));
    setDeleteOrgModal(false);
  };

  return (
    <>
      <div className="nav-content-header">{t('ORG_PROFILE')}</div>
      <div className="card justify-content-between">
        <div className="d-flex">
          <div className="avatar-initials">
            {getInitials(selectedGroup.group_name)}
          </div>
          <div className="d-flex align-items-center ms-3 weight-500 fs-5">
            {selectedGroup.group_name}
          </div>
        </div>
        <button
          className="button button--link"
          onClick={() => setEditOrgModal(true)}
        >
          Edit
        </button>
      </div>
      <EditGroupModal
        show={editOrgModal}
        onClose={() => setEditOrgModal(false)}
        startDelete={() => {
          setEditOrgModal(false);
          setDeleteOrgModal(true);
        }}
        group={{
          value: selectedGroup.group_guid,
          key: selectedGroup.group_name,
          dailyDigestTeam: selectedGroup.daily_digest_team,
        }}
      />
      <DeleteGroupModal
        show={deleteOrgModal}
        selectedGroup={selectedGroup.group_name}
        onClose={() => {
          setDeleteOrgModal(false);
          setEditOrgModal(true);
        }}
        startDelete={() => {
          onDeleteConfirmDialog();
        }}
      />
    </>
  );
};

export default OrgProfile;
