import React, { useMemo } from 'react';

import './IncidentReportLayerPopup.css';

export default function IncidentReportLayerPopup({
  layer,
  e,
  features,
  incidentReports,
  categoryItems,
  handleReportSelected
}) {

  const feature = features?.[0]?.properties;
  const selectedIncidentReports = useMemo(() => {
    const updatedIncidentReports = incidentReports.map(report => {
      const matchingCategoryItem = categoryItems.find(categoryItem => categoryItem.id === report?.aor_id);
  
      if (matchingCategoryItem) {
        return {
          ...report,
          geo_location: matchingCategoryItem?.geo_location,
          categoryName: matchingCategoryItem?.category_item_name || 'None-Category'
        };
      }
      return report;
    });
  
    const selectedIncidentReports = updatedIncidentReports.filter(report => report?.aor_id === feature?.id);
  
    return selectedIncidentReports;
  }, [incidentReports, categoryItems, feature]);
  
  return (
    <>
      <h6 className="m-0">Incident Report</h6>
      <h6 className="m-0">{feature?.category_item_name}</h6>
      <hr className="dashed thin my-1" />
      {!!selectedIncidentReports && selectedIncidentReports?.length ? (
        <>
          <ul className="list">
            {selectedIncidentReports.map((report) => {
              return (
                <li
                  className="cursor-pointer report-layer-item"
                  key={'IncidentReportLayerPopup-Feature-' + report.id}
                  onClick={() => handleReportSelected(report)}
                >
                  {report?.incident_name}
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <p className="m-0 weight-500">No Incident Report</p>
      )}
      <hr className="thin my-1" />
    </>
  );
}
