export const Config205 = {
  formname: 'ICS 205',
  filename: 'ICS_205_CG.PDF',
  field: [
    {
      key: '1 Incident Name',
      type: 'Text',
      value: '',
    },
    {
      key: 'PreparedBy',
      type: 'Text',
      value: '',
    },
    {
      key: '3 Basic Radio Channel Use',
      type: 'Table',
      value: [],
    },
    {
      key: 'OPeriod DateTimeTo',
      type: 'Text',
      value: '',
    },
    {
      key: 'OPeriod DateTimeForm',
      type: 'Text',
      value: '',
    },
    {
      key: '6 Prepared by Communications Unit Leader Name',
      type: 'Text',
      valeu: '',
    },
    {
      key: 'PreparedByDateTime',
      type: 'Text',
      valeu: '',
    },
  ],
};
