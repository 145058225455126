import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import IconUpload from '../../assets/images/icon__upload.svg';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useDispatch, useSelector } from 'react-redux';
import { upsertSafetyAndHealthForm } from 'actions/IAPForm208Action';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import axios from 'axios';
import apiConfig from 'constants/apiConfig';
import IconClose from 'images/icons/icon__times.svg';
import IconFile from 'images/icons/icon__file.svg';
import { inputStringLength } from 'assets/data/config';

export const IAPForm208AddEditFormDialog = ({
  show,
  onClose,
  dialogType,
  existingData,
}) => {
  const reduxDispatch = useDispatch();
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const [attachments, setAttachments] = useState([]);
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: 'onChange' });

  const required_options = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    console.log('file', file);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('group_guid', reduxCurrentlySelectedGroup.group_guid);
    reduxDispatch(startLoading());
    try {
      const resp = await axios.post(apiConfig.apiGateway.uploadFile, formData);

      setAttachments([...attachments, resp.data.fileUrl]);
    } catch (error) {
      //Error File Upload
      console.error(error);
    } finally {
      reduxDispatch(endLoading());
    }
  };

  useEffect(() => {
    if (dialogType === 'Edit' && existingData?.id) {
      setValue('form_name', !!existingData.form_name && existingData.form_name);
      setValue(
        'form_number',
        !!existingData.form_number && existingData.form_number
      );
      setValue(
        'description',
        !!existingData.description && existingData.description
      );
      setValue('use', !!existingData.use && existingData.use);
      setValue(
        'required',
        !!existingData.required && existingData.required === true
          ? { value: true, label: 'Yes' }
          : { value: false, label: 'No' }
      );
      setAttachments(existingData.attachments);
    }
  }, [dialogType, existingData]);

  const onAddEditFormSubmit = (data) => {
    const updatedData = {
      id: existingData?.id || generateUUID(),
      form_name: data.form_name,
      form_number: data.form_number,
      description: data.description,
      use: data.use,
      required: data.required.value,
      attachments: attachments,
    };
    reduxDispatch(startLoading());
    reduxDispatch(upsertSafetyAndHealthForm(updatedData));
    onClose();
  };
  const deleteFile = (index) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>
            {`${
              dialogType === 'New'
                ? 'Create New'
                : dialogType === 'Edit'
                ? 'Edit'
                : null
            } Form`}
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onAddEditFormSubmit)}>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Form Name</label>
              <Controller
                control={control}
                name="form_name"
                rules={{ required: true, maxLength: inputStringLength }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={(e) => onChange(e)}
                    value={value}
                  />
                )}
              />
              {errors.form_name?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors.form_name?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {inputStringLength} character allowed
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Form Number</label>
              <Controller
                control={control}
                name="form_number"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={(e) => onChange(e)}
                    value={value}
                  />
                )}
              />
              {errors.form_number && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <Controller
                control={control}
                name="description"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea
                    value={value || ''}
                    onChange={(e) => onChange(e)}
                  />
                )}
              />
              {errors?.description?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Use</label>
              <Controller
                control={control}
                name="use"
                rules={{ required: true, maxLength: inputStringLength }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={(e) => onChange(e)}
                    value={value || ''}
                  />
                )}
              />
              {errors.use?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors.use?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {inputStringLength} character allowed
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Required:</label>
              <Controller
                control={control}
                name="required"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={required_options}
                    value={value}
                    placeholder="Select a Option"
                    onChange={onChange}
                    isDisabled={false}
                    isClearable={false}
                    isSearchable={false}
                    isMulti={false}
                  />
                )}
              />
              {errors.required && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
            <label className="form-label">Attachment:</label>
            <div className="row mb-2">
              {attachments.map((file, index) => {
                return (
                  <div key={file} className="col-md-3 col-xl-2 my-2">
                    <div className="cards cards__uploaded-file">
                      <span
                        className="remove"
                        onClick={() => deleteFile(index)}
                      >
                        <img src={IconClose} alt="" />
                      </span>
                      <span className="icon">
                        <img src={IconFile} alt="" />
                      </span>
                      <span className="title">
                        {file.trim().split('/').pop().substring(37)}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="form-upload">
              <div>
                <span className="d-flex justify-content-center mb-2">
                  <img src={IconUpload} alt="" />
                </span>
                <span className="d-block">
                  Drop Your Files Here or Click to Upload
                </span>
              </div>
              <input
                type="file"
                name="select-file-input"
                onInput={onFileChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={onClose}
              >
                Close
              </StylishNewButton>
              <StylishNewButton type="submit" className="button--primary">
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
