import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { PanelType } from './SidebarNav';
import { useSelector } from 'react-redux';
import { getInitials } from '../../helpers/intials';
import {
  ListGroupButtonItem,
  ListGroupNavItem,
} from './ListGroupNavComponents';
import {
  useFeatureFlag,
  useStreamlinePlatformFlag,
} from '../../utils/featureFlags';
import { useActualIncidentId } from '../IAP/hooks/commonHooks';
import { useSubscriptionLevel } from '../SubscriptionManagement/useSubscriptionLevel';

const MainNavPanel: React.FC<{
  setSecondPanel: (key: PanelType) => void;
}> = ({ setSecondPanel }) => {
  const graphistryStable = useFeatureFlag('GRAPHISTRY_STABILITY');
  const incidentId = useActualIncidentId();
  const streamlinePlatform = useStreamlinePlatformFlag();
  const currentlySelected = useSelector(
    (state: any) => state.app.currentlySelectedGroup as Group
  );

  const {
    lockIncident,
    lockExercise,
    freemium,
    lockNotification,
  } = useSubscriptionLevel();

  return (
    <nav aria-label="primary sidebar">
      <div className="org-label">
        <div className="avatar-initials sml me-2">
          {getInitials(currentlySelected.group_name)}
        </div>
        <div className="name-container">
          <div className="word-break">{currentlySelected.group_name}</div>
        </div>
      </div>

      <ListGroup variant="flush">
        <div className="list-group-title">Situational Awareness</div>
        <ListGroupNavItem to="/map" icon="map" label="Map" />
        {graphistryStable ? (
          <ListGroupNavItem
            to="/osint"
            icon="search"
            label="OSINT"
            isLocked={freemium}
          />
        ) : null}
        {streamlinePlatform ? null : (
          <ListGroupButtonItem
            icon="notifications"
            label="Notifications"
            onClick={() => setSecondPanel('notifications')}
            isLocked={lockNotification}
          />
        )}
      </ListGroup>

      <ListGroup variant="flush">
        <div className="list-group-title">Incident Management</div>
        <ListGroupNavItem
          to="/report/new"
          icon="report"
          label="Report Incident"
          isLocked={lockIncident}
        />
        <ListGroupNavItem
          to="/report"
          icon="list"
          label="Reports List"
          end
          isLocked={lockIncident}
        />

        <ListGroupNavItem
          to={`/iap/${incidentId}/dashboard`}
          icon="problem"
          label="Incident Management"
          checkWidth={true}
          onClick={() => setSecondPanel('ics')}
          isLocked={lockIncident}
        />

        {streamlinePlatform ? null : (
          <ListGroupButtonItem
            onClick={() => setSecondPanel('task')}
            icon="task"
            label="Task Management"
            isLocked={lockIncident}
          />
        )}
      </ListGroup>
      <>
        <ListGroup variant="flush">
          <div className="list-group-title">Exercise Management</div>
          <ListGroupButtonItem
            onClick={() => setSecondPanel('exercise')}
            icon="directions_run"
            label="Plan Exercise"
            isLocked={lockExercise}
          />
          {streamlinePlatform ? null : (
            <ListGroupButtonItem
              onClick={() => setSecondPanel('media')}
              icon="thumb_up"
              label="Media"
              isLocked={lockExercise}
            />
          )}
        </ListGroup>
      </>

      <ListGroup variant="flush">
        <div className="list-group-title">Settings</div>
        <ListGroupNavItem to="/groups" icon="settings" label="Org Settings" />
      </ListGroup>
    </nav>
  );
};

export default MainNavPanel;
