export const Config202 = {
  formname: 'ICS 202',
  filename: 'ICS_202_CG.PDF',
  field: [
    {
      key: '1 Incident Name',
      type: 'Text',
      value: '',
    },
    {
      key: 'OPFrom',
      type: 'Text',
      value: '',
    },
    {
      key: 'OPTo',
      type: 'Text',
      value: '',
    },
    {
      key: '3  Objectives',
      type: 'List',
      value: [],
    },
    {
      key: 'OperationalPeriodCommandEmphasis',
      type: 'Text',
      value: '',
    },
    {
      key:
        '5 Site Safety Plan Required Yes No Approved Site Safety Plans Located at',
      type: 'Text',
      value: '',
    },
    {
      key: 'PreparedbyPlanningSectionChief',
      type: 'Text',
      value: '',
    },
    {
      key: 'PreparedbyPlanningDateTime',
      type: 'Text',
      value: '',
    },
  ],
};
