import {
  LOGIN,
  LOGOUT,
  SET_USER_DETAILS,
  UPDATE_ACCESS_REFRESH_TOKEN,
  UPDATE_SELECTED_WORKSPACE,
  UPDATE_USER_ID_NAME,
  UPDATE_USER_NAME,
  UPDATE_USER_ROLE,
  UPDATE_USER_TIME_FORMAT,
  VALIDATING_TOKEN_REQUEST,
  VALIDATING_TOKEN_SUCCESS,
} from "./user.constants";
const initialState = {
  isLoggedIn: false,
  validatingToken: true,
  id: null,
  token: null,
  refresh_token: null,
  name: null,
  imgUrl: null,
  roles: [],
  rolesLoaded: false,
  timeFormat: "hour24Mode",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        validatingToken: false,
        token: action.payload.token,
        refresh_token: action.payload.refresh_token,
        id: action.payload.id,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        token: null,
        name: null,
        imgUrl: null,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        name: action.payload.name,
        timeFormat: action.payload.timeFormat,
        imgUrl: action.payload.imgUrl,
      };
    case UPDATE_SELECTED_WORKSPACE:
      return {
        ...state,
        selectedWorkspace: action.payload.selectedWorkspaceId,
      };
    case VALIDATING_TOKEN_REQUEST:
      return {
        ...state,
        validatingToken: true,
      };

    case VALIDATING_TOKEN_SUCCESS:
      return {
        ...state,
        validatingToken: false,
      };
    case UPDATE_USER_ROLE:
      return {
        ...state,
        roles: action.payload,
        rolesLoaded: true,
      };
    case UPDATE_USER_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case UPDATE_ACCESS_REFRESH_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        refresh_token: action.payload.refresh_token,
      };
    case UPDATE_USER_TIME_FORMAT:
      return { ...state, timeFormat: action.payload };
    case UPDATE_USER_ID_NAME:
      return {
        ...state,
        name: action.payload.name,
        id: action.payload.id,
      };
    default:
      return state;
  }
};

export default userReducer;
