import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timegrid from '@fullcalendar/timegrid'; // a plugin!
import listPlugin from '@fullcalendar/list'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import moment from 'moment';
import AddScheduleActivationDialog from './AddScheduleActivationDialog';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

export default function ActivationScheduleDialog({
  show,
  onClose,
  allScheduleToIncident: schedules,
}) {
  const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const [existingEvent, setExistingEvent] = useState();
  const [
    addScheduleActivationDialogOpen,
    setAddScheduleActivationDialogOpen,
  ] = useState(false);
  const [selectedIncidentMember, setSelectedIncidentMember] = useState();
  const [allScheduleToIncident, setAllScheduleToIncident] = useState();
  const [scheduleActivationType, setScheduleActivationType] = useState(null);

  useEffect(() => {
    if (schedules && Array.isArray(schedules)) {
      const refinedSchedules = schedules.map((schedule) => {
        const schedules = schedule.allday
          ? {
              title: `${schedule.user_name || schedule.email_address}: ${
                schedule.incident_seat || schedule.description
              }`,
              start: moment(schedule.start_time).toISOString(),
              end: moment(schedule.end_time).add(1, 'days').toISOString(),
              allDay: schedule.allday,
              className: schedule.incident_id ? 'fc-incident' : '',
              ...schedule,
              ...(schedule.incident_seat
                ? { color: '#ffffff', textColor: '#000' }
                : {}),
            }
          : schedule.isrecurringevent
          ? {
              title: `${schedule.user_name || schedule.email_address}: ${
                schedule.incident_seat || schedule.description
              }`,
              startTime: schedule.recurringstarttime,
              endTime: schedule.recurringendtime,
              startRecur: moment(schedule.start_time).toISOString(),
              endRecur: moment(schedule.end_time).add(1, 'days').toISOString(),
              daysOfWeek: schedule.daysofweek,
              allDay: false,
              className: schedule.incident_id ? 'schedule-incident' : '',
              ...schedule,
              ...(schedule.incident_seat
                ? { color: '#ffffff', textColor: '#000' }
                : {}),
            }
          : {
              title: `${schedule.user_name || schedule.email_address}: ${
                schedule.incident_seat || schedule.description
              }`,
              start: moment(schedule.start_time).toISOString(),
              end: moment(schedule.end_time).toISOString(),
              allDay: schedule.allday,
              className: schedule.incident_id ? 'fc-incident' : '',
              ...schedule,
              ...(schedule.incident_seat
                ? { color: '#ffffff', textColor: '#000' }
                : {}),
            };

        return schedules;
      });

      setAllScheduleToIncident(refinedSchedules);
    } else {
      setAllScheduleToIncident([]);
    }
  }, [schedules]);

  const editExistingEvent = (e) => {
    if (!e.extendedProps.incident_seat) {
      toast.error(
        'You cannot edit pre-approved unavailability periods',
        toastConfig
      );
    } else {
      const incidentMember = {
        incident_seat: e.extendedProps.incident_seat,
        incident_member_id: e.extendedProps.incident_member_id,
        user_guid: e.extendedProps.user_guid,
      };
      setSelectedIncidentMember(incidentMember);
      setExistingEvent(e);
      setScheduleActivationType('Edit');
      setAddScheduleActivationDialogOpen(true);
    }
  };

  const onCloseAddScheduleActivation = () => {
    setAddScheduleActivationDialogOpen(false);
    setScheduleActivationType('');
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Activation Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="calendar">
            <FullCalendar
              validRange={{ start: firstDay.toISOString() }}
              plugins={[dayGridPlugin, timegrid, listPlugin, interactionPlugin]}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'timeGridDay,timeGridWeek,dayGridMonth,listMonth',
              }}
              initialView="dayGridMonth"
              events={allScheduleToIncident}
              eventClick={(info) => editExistingEvent(info.event)}
            />
          </div>
        </Modal.Body>
      </Modal>
      {addScheduleActivationDialogOpen && (
        <AddScheduleActivationDialog
          show={addScheduleActivationDialogOpen}
          existingEvent={existingEvent}
          selectedIncidentMember={selectedIncidentMember}
          onClose={onCloseAddScheduleActivation}
          setSelectedIncidentMember={setSelectedIncidentMember}
          scheduleActivationType={scheduleActivationType}
        />
      )}
    </>
  );
}
