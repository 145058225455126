import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useDispatch } from 'react-redux';
import { createUpdateCommunication } from 'actions/IAPForm204Actions';

export const AddCommunicationDialog = ({
  show,
  onClose,
  mode,
  editData,
  planning_worksheet_id,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const reduxDispatch = useDispatch();

  const submitData = (communicationData) => {
    const communicationSubmitData = {
      communication:
        mode?.toLowerCase() === 'edit'
          ? {
              planning_worksheet_id,
              ...editData,
              ...communicationData,
            }
          : { ...communicationData, id: generateUUID(), planning_worksheet_id },
    };
    reduxDispatch(createUpdateCommunication(communicationSubmitData));
    onClose();
  };

  useEffect(() => {
    if (mode?.toLowerCase() === 'edit') {
      setValue('channel_name', editData?.channel_name);
      setValue('assignment', editData?.assignment);
      setValue('frequency', editData?.frequency);
      setValue('function', editData?.function);
      setValue('mode', editData?.mode);
      setValue('n_w', editData?.n_w);
      setValue('remarks', editData?.remarks);
      setValue('rx_frequency', editData?.rx_frequency);
      setValue('rx_tone', editData?.rx_tone);
      setValue('tx_tone', editData?.tx_tone);
      setValue('function', editData?.function);
    }
  }, [editData, mode]);

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <form onSubmit={handleSubmit(submitData)}>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>
              {mode?.toLowerCase() === 'edit' ? 'Edit' : 'Add New'}{' '}
              Communication
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">
                Channel Name/Trunked Radio System Talkgroup
              </label>
              <Controller
                control={control}
                name="channel_name"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.channel_name && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Assignment</label>
              <Controller
                control={control}
                name="assignment"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.assignment && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Frequency (tx)</label>
              <Controller
                control={control}
                name="frequency"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.frequency && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Function</label>
              <Controller
                control={control}
                name="function"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.function && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Frequency (rx)</label>
              <Controller
                control={control}
                name="rx_frequency"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.rx_frequency && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">N or W</label>
              <Controller
                control={control}
                name="n_w"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.n_w && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Rx Tone/NAC</label>
              <Controller
                control={control}
                name="rx_tone"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.rx_tone && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Tx Tone/NAC</label>
              <Controller
                control={control}
                name="tx_tone"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.tx_tone && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Mode A, D or M</label>
              <Controller
                control={control}
                name="mode"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.mode && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Remarks</label>
              <Controller
                control={control}
                name="remarks"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.remarks && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton className="button--primary" type="submit">
                Save
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary button--reverse"
                onClick={onClose}
              >
                Cancel
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
