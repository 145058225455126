import { PropsWithChildren } from 'react';
import { Modal } from 'react-bootstrap';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

interface Props {
  show: boolean;
  onClose: () => void;
  title?: string;
}

export const FullScreenModal = ({
  show,
  onClose,
  title,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      dialogClassName="fullscreen--modal-dialog"
      fullscreen
    >
      <Modal.Header className="fullscreen--modal-header">
        <h3 className="modal-title">{title}</h3>

        <button className='fullscreen--close-btn' onClick={onClose}>
            <SharedIcon color='light' classes='icon m-0 p-0' iconName='close' />
        </button>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
