import React, { useEffect, useState } from 'react';
import {
  fetchNestedCategories,
  fetchNestedCategoryItems,
  getAllLocationAsset,
  getAllLocationEnterprise,
  getAllLocationEntity,
  getAllLocationSubEntity,
} from 'actions/locationAction';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

export default function ManageTeamLocation({
  selectedTeamLocation,
  onChangeTeamLocation,
}) {

  const reduxDispatch = useDispatch();
  const currentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const featureFlags = useSelector(state => {
    return state.app.featureFlags
  })

  const locationEntity = useSelector((state) => {
    return state.app.locationEntity.map((entity) => ({
      label: entity.location_entity,
      value: entity.id,
    }));
  });
  const locationSubEntity = useSelector((state) => {
    return state.app.locationSubEntity.map((subEntity) => ({
      label: subEntity.location_sub_entity,
      value: subEntity.id,
      parent: subEntity.location_entity_id,
    }));
  });
  const locationEnterprise = useSelector((state) => {
    return state.app.locationEnterprise.map((enterprise) => ({
      label: enterprise.location_enterprise,
      value: enterprise.id,
      parent: enterprise.location_sub_entity_id,
    }));
  });

  const locationAsset = useSelector((state) => {
    return state.app.locationAsset.map((asset) => ({
      label: asset.location_asset,
      value: asset.id,
      parent: asset.location_enterprise_id,
    }));
  });

  const reduxNestedCategoryItems = useSelector(state => {
    return state.app.nestedCategoryItems
  })

  const reduxNestedCategories = useSelector(state => {
    return state.app.nestedCategories
  })

  const [nestedCategories, setNestedCategories] = useState([])

  // This code is duplicated in the "ManageLocation" component
  // We should have one single mechanism for controlling the shape of the object.
  // Maybe a custom hook. I dunno.
  useEffect(() => {
      // once we have both the categories and their items, we'll combine them.

      let organizedCategoriesWithItems = []
      let itemsWithNoMatchingCategory = (!!reduxNestedCategoryItems && Array.isArray(reduxNestedCategoryItems) && reduxNestedCategoryItems.length)
        ? [...reduxNestedCategoryItems]
        : []

      if (!!reduxNestedCategories && Array.isArray(reduxNestedCategories) && !!reduxNestedCategories.length) {
        organizedCategoriesWithItems = reduxNestedCategories.map(category => {
          let items = []

          if (!!itemsWithNoMatchingCategory.length) {
            items = itemsWithNoMatchingCategory
              .filter(item => item.category_id === category.id)

            // now we update the list of items that we have not matched to a category
            itemsWithNoMatchingCategory = itemsWithNoMatchingCategory.filter(item => item.category_id !== category.id)
          }

          return {...category, items}
        })
      } 

      if (!!itemsWithNoMatchingCategory.length) {
        organizedCategoriesWithItems.push({
          id: null,
          category_name: "Area of Responsibility",
          parent_category_id: null,
          group_guid: currentlySelectedGroup.group_guid,
          items: [...itemsWithNoMatchingCategory],
        })
      }

      setNestedCategories(organizedCategoriesWithItems)
  }, [reduxNestedCategories, reduxNestedCategoryItems])

  useEffect(() => {
    if (!!currentlySelectedGroup && !!currentlySelectedGroup.group_guid) {

        reduxDispatch(fetchNestedCategories());
        reduxDispatch(fetchNestedCategoryItems());
      
    }
  }, [currentlySelectedGroup]);

  const { control, setValue } = useForm({ mode: 'onChange' });

  useEffect(() => {
    if (!!selectedTeamLocation) {

        // This code assumes a UX in which only one area of responsibility can be selected at a time.

        let processedCategories = nestedCategories.map(({items, category_name}) => {
          const foundItem = items.find(item => item.id === selectedTeamLocation.location_id)
          if(!!foundItem) {
            return {category_name, location: {label: foundItem.category_item_name, value: selectedTeamLocation.location_id}}
          } else {
            return {category_name, location: null}
          }
        })

        processedCategories.forEach(({category_name, location}) => {
          setValue(category_name, location)
        })

    }
  }, [selectedTeamLocation, nestedCategories]);

  const onChangeLocation = (data, type, onChange, category_id, parent_category_id,) => {
    onChange(data);

    onChangeTeamLocation({
      location_id: data?.value,
    });
  };
    
  const RenderedCategory = ({
    category_id,
    category_name, 
    items,
    parent_category_id,
  }) => {

    const categoryOptions = items.map(entity => {
        return { 
          label: entity.category_item_name, 
          value: entity.id }
    });

    return (
      <div className="row mb-4">
        <label className="form-label">{category_name}</label>
          <Controller
            control={control}
            name={category_name}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                    options={categoryOptions}
                    defaultValue={value}
                    value={value}
                    onChange={(e) => onChangeLocation(
                      e, 
                      category_name, 
                      onChange, 
                      category_id,
                      parent_category_id,
                    )}
                    placeholder={`Select ${category_name}`}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                />
            )}
        />
        </div>
    )
  }


  return (
    <>
      <div className="row">
        {nestedCategories.map((category, idx) => (
          <RenderedCategory
            key={'nested-category-' + idx}
            category_id={category.id}
            category_name={category.category_name}
            items={category.items}
            parent_category_id={category.paraent_category_id}
          />
        ))}
      </div>
    </>
  );
}
