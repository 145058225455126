import config from 'constants/apiConfig';
import axios from 'axios';
import {
  FETCH_MEDICAL_PLAN,
  FETCH_MEDICAL_AID_STATION_LIST,
  UPSERT_FORM_RECORD_ERROR,
  FETCH_TRANSPORTATION_LIST,
  FETCH_HOSPITAL_LIST,
} from 'constants/form-action-types';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

function parseError(api, error) {
  const errorMessage =
    `${api}` +
    'There was an error saving your Form.  No associated Workflows have modified. Please try again.' +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPSERT_FORM_RECORD_ERROR,
  };
}

export const fetchMedicalPlan = () => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.fetchMedicalPlanData, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        operational_period_id: getState().app.currentIncident.current_period,
      }),
    })
      .then((response) => {
        if (response?.data?.length) {
          dispatch({ type: FETCH_MEDICAL_PLAN, payload: response.data[0] });
        } else {
          dispatch(
            updateMedicalPlan({
              procedure: '',
              // prepared_by: reduxMedicalPlan?.prepared_by?.user_guid,
              // reviewed_by: reduxMedicalPlan?.reviewed_by?.user_guid,
              medical_plan_id: generateUUID(),
            })
          );
        }
        dispatch(endLoading());
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.fetchMedicalPlanData, error));
      });
  };
};

export const updateMedicalPlan = (medicalPlanData) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.createMedicalPlanData, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        operational_period_id: getState().app.currentIncident.current_period,
        ...medicalPlanData,
      }),
    })
      .then(() => {
        dispatch(startLoading());
        dispatch(fetchMedicalPlan());
        // toast.success('Create Medical Plan Data successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.createMedicalPlanData, error));
      });
  };
};

export const fetchMedicalAidStationList = () => (dispatch, getState) =>
  axios(config.apiGateway.fetchMedicalAidStationList, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
    }),
  })
    .then((response) => {
      dispatch({
        type: FETCH_MEDICAL_AID_STATION_LIST,
        payload: response.data,
      });
      dispatch(endLoading());
    })
    .catch((error) => {
      dispatch(parseError(config.apiGateway.fetchMedicalAidStationList, error));
    });

export const addMedicalAidStationToMedicalPlan = (
  medicalPlanMedicalAidStation
) => (dispatch, getState) =>
  axios(config.apiGateway.createMedicalPlanMedicalAid, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      ...medicalPlanMedicalAidStation,
    }),
  })
    .then(() => {
      dispatch(startLoading());
      dispatch(fetchMedicalPlan());
      // toast.success('Create Medical Plan Medical Aid Station successful', {
      //   autoClose: 2000,
      // });
    })
    .catch((error) => {
      dispatch(
        parseError(config.apiGateway.createMedicalPlanMedicalAid, error)
      );
    });

export const updateMedicalAidStation = (medicalAidStationData) => (
  dispatch,
  getState
) =>
  axios(config.apiGateway.createMedicalAidStation, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      ...medicalAidStationData,
    }),
  })
    .then(() => {
      dispatch(startLoading());
      dispatch(fetchMedicalAidStationList());
      dispatch(fetchMedicalPlan());
      // toast.success('Create Medical Aid Station successful', {
      //   autoClose: 2000,
      // });
    })
    .catch((error) => {
      dispatch(parseError(config.apiGateway.createMedicalAidStation, error));
    });

export const deleteMedicalAidStation = (id) => (dispatch, getState) =>
  axios(config.apiGateway.deleteMedicalAidStation, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      id,
    }),
  })
    .then(() => {
      dispatch(startLoading());
      dispatch(fetchMedicalAidStationList());
      dispatch(fetchMedicalPlan());
      // toast.success('Delete Medical Aid Station successful', {
      //   autoClose: 2000,
      // });
    })
    .catch((error) => {
      dispatch(parseError(config.apiGateway.deleteMedicalAidStation, error));
    });

export const deleteMedicalPlanMedicalAidStation = (mpmaId) => (
  dispatch,
  getState
) =>
  axios(config.apiGateway.deleteMedicalPlanMedicalAidStation, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      mpmaId,
    }),
  })
    .then(() => {
      dispatch(startLoading());
      dispatch(fetchMedicalAidStationList());
      dispatch(fetchMedicalPlan());
      // toast.success('Delete Medical Plan Medical Aid Station successful', {
      //   autoClose: 2000,
      // });
    })
    .catch((error) => {
      dispatch(
        parseError(config.apiGateway.deleteMedicalPlanMedicalAidStation, error)
      );
    });

export const fetchTransportationList = () => (dispatch, getState) =>
  axios(config.apiGateway.fetchTransportationList, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
    }),
  })
    .then((response) => {
      dispatch({
        type: FETCH_TRANSPORTATION_LIST,
        payload: response.data,
      });
      dispatch(endLoading());
    })
    .catch((error) => {
      dispatch(parseError(config.apiGateway.fetchTransportationList, error));
    });

export const addTransportationToMedicalPlan = (medicalPlanTransportation) => (
  dispatch,
  getState
) =>
  axios(config.apiGateway.createMedicalPlanTransportation, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      ...medicalPlanTransportation,
    }),
  })
    .then(() => {
      dispatch(startLoading());
      dispatch(fetchMedicalPlan());
      // toast.success('Create Medical Plan Transportation successful', {
      //   autoClose: 2000,
      // });
    })
    .catch((error) => {
      dispatch(
        parseError(config.apiGateway.createMedicalPlanTransportation, error)
      );
    });

export const updateTransportation = (transportationData) => (
  dispatch,
  getState
) =>
  axios(config.apiGateway.createTransportation, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      ...transportationData,
    }),
  })
    .then(() => {
      dispatch(startLoading());
      dispatch(fetchTransportationList());
      dispatch(fetchMedicalPlan());
      // toast.success('Create Transportation successful', {
      //   autoClose: 2000,
      // });
    })
    .catch((error) => {
      dispatch(parseError(config.apiGateway.createTransportation, error));
    });

export const deleteTransportation = (id) => (dispatch, getState) =>
  axios(config.apiGateway.deleteTransportation, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      id,
    }),
  })
    .then(() => {
      dispatch(startLoading());
      dispatch(fetchTransportationList());
      dispatch(fetchMedicalPlan());
      // toast.success('Delete Transportation successful', {
      //   autoClose: 2000,
      // });
    })
    .catch((error) => {
      dispatch(parseError(config.apiGateway.deleteTransportation, error));
    });

export const deleteMedicalPlanTransportation = (mptId) => (
  dispatch,
  getState
) =>
  axios(config.apiGateway.deleteMedicalPlanTransportation, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      mptId,
    }),
  })
    .then(() => {
      dispatch(startLoading());
      dispatch(fetchTransportationList());
      dispatch(fetchMedicalPlan());
      // toast.success('Delete Medical Plan Transportation successful', {
      //   autoClose: 2000,
      // });
    })
    .catch((error) => {
      dispatch(
        parseError(config.apiGateway.deleteMedicalPlanTransportation, error)
      );
    });

export const fetchHospitalList = () => (dispatch, getState) =>
  axios(config.apiGateway.fetchHospitalList, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
    }),
  })
    .then((response) => {
      dispatch({
        type: FETCH_HOSPITAL_LIST,
        payload: response.data,
      });
      dispatch(endLoading());
    })
    .catch((error) => {
      dispatch(parseError(config.apiGateway.fetchHospitalList, error));
    });

export const addHospitalToMedicalPlan = (medicalPlanHospital) => (
  dispatch,
  getState
) =>
  axios(config.apiGateway.createMedicalPlanHospital, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      ...medicalPlanHospital,
    }),
  })
    .then(() => {
      dispatch(startLoading());
      dispatch(fetchMedicalPlan());
      // toast.success('Create Medical Plan Hospital successful', {
      //   autoClose: 2000,
      // });
    })
    .catch((error) => {
      dispatch(parseError(config.apiGateway.createMedicalPlanHospital, error));
    });

export const updateHospital = (hospitalData) => (dispatch, getState) =>
  axios(config.apiGateway.createHospital, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      ...hospitalData,
    }),
  })
    .then(() => {
      dispatch(startLoading());
      dispatch(fetchHospitalList());
      dispatch(fetchMedicalPlan());
      // toast.success('Create Hospital successful', {
      //   autoClose: 2000,
      // });
    })
    .catch((error) => {
      dispatch(parseError(config.apiGateway.createHospital, error));
    });

export const deleteHospital = (id) => (dispatch, getState) =>
  axios(config.apiGateway.deleteHospital, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      id,
    }),
  })
    .then(() => {
      dispatch(startLoading());
      dispatch(fetchHospitalList());
      dispatch(fetchMedicalPlan());
      // toast.success('Delete Hospital succcessful', {
      //   autoClose: 2000,
      // });
    })
    .catch((error) => {
      dispatch(parseError(config.apiGateway.deleteHospital, error));
    });

export const deleteMedicalPlanHospital = (mphId) => (dispatch, getState) =>
  axios(config.apiGateway.deleteMedicalPlanHospital, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      mphId,
    }),
  })
    .then(() => {
      dispatch(startLoading());
      dispatch(fetchHospitalList());
      dispatch(fetchMedicalPlan());
      // toast.success('Delete Medical Plan Hospital successful', {
      //   autoClose: 2000,
      // });
    })
    .catch((error) => {
      dispatch(parseError(config.apiGateway.deleteMedicalPlanHospital, error));
    });
