export default function parseErrorMessage(error) {

  if (typeof error === "string") {
    return error
  }

  if (error.response) {
    return error.response.data.ERROR

  }

  if (error.message && typeof error.message === "string") {
    return error.message
  }
}