import React from 'react';
import classnames from 'classnames';

const StylishNewInput = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <input
      className={classnames('form-control', className)}
      ref={ref}
      {...props}
    />
  );
});

StylishNewInput.displayName = 'StylishNewInput(forwardRef)'

export default StylishNewInput;