import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { getUserGuid, getGroupGuid } from 'slices/sliceHelpers';

const ics_resource_requestsSlice = createSlice({
    name: 'ics_resource_requests',
    initialState: {
        ics_resource_requests: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        fetchIcsResourceRequestsSuccess: (state, action) => {
            state.ics_resource_requests = action.payload;
            state.status = 'succeeded';
        },
        createIcsResourceRequestSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        updateIcsResourceRequestSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    fetchIcsResourceRequestsSuccess,
    createIcsResourceRequestSuccess,
    updateIcsResourceRequestSuccess,
    setError
} = ics_resource_requestsSlice.actions;

export default ics_resource_requestsSlice.reducer;

export const createIcsResourceRequest = (data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState)
    try {
        const response = await axios.post(`${config.apiGateway.ics_resource_requests}`, {...data, user_guid});
        dispatch(fetchIcsResourceRequests(group_guid));
        dispatch(createIcsResourceRequestSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsResourceRequests = (group_guid) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_resource_requests}/${group_guid}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsResourceRequestsSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsResourceRequest = (id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState)
    try {
        const response = await axios.put(`${config.apiGateway.ics_resource_requests}/${id}`, {...data, user_guid});
        dispatch(fetchIcsResourceRequests(group_guid));
        dispatch(updateIcsResourceRequestSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
