import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import EntityList from './EntityList';
import { useToggle } from 'react-use';
import { Card } from 'react-bootstrap';
import { useState } from 'react';
import classNames from 'classnames';
import {
  getComsTitle,
  IncidentComs,
  useIncidentComs,
} from './hooks/incidentComsHooks';
import ComsAddDrawer from './ComsAddDrawer';
import ComsEditDrawer from './ComsEditDrawer';

import {
  MdEdit,
} from 'react-icons/md';

const CollapsibleComsList: React.FC = () => {
  const [expanded, toggleExpanded] = useToggle(false);
  const [editOpen, toggleEditDrawer] = useToggle(false);
  const [addOpen, toggleAddDrawer] = useToggle(false);
  const [selectedEntity, setSelectedEntity] = useState<IncidentComs | null>(null);

  const { data: incidentComs } = useIncidentComs();

  const selectElement = (element: IncidentComs) => {
    setSelectedEntity(element);
    toggleEditDrawer();
  };

  return (
    <>
      <EntityList
        entities={incidentComs}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        header={
          <div className="d-flex align-items-center justify-content-between flex-grow-1">
            <span>Coms (205, 205A)</span>
            <StylishNewButton
              className={classNames('button', {
                'button--primary': expanded,
                'button--secondary': !expanded,
              })}
              onClick={toggleAddDrawer}
            >
              <SharedIcon iconName="add" classes="me-2" />
              Add
            </StylishNewButton>
          </div>
        }
        renderEntity={(element) => {
          const formattedName = getComsTitle(element);
          return (
            <Card
              key={element.id}
              className="bg-neutral-800 my-1 rounded-1 flex-row align-items-center justify-content-between"
              style={{
                padding: '0.625rem 1rem 0.625rem 1rem',
              }}
            >
              <span>{formattedName}</span>
              <StylishNewButton
                className="button button--link"
                onClick={() => selectElement(element)}
              >
                <MdEdit />
              </StylishNewButton>
            </Card>
        )}}
      ></EntityList>
      {addOpen ? <ComsAddDrawer toggle={toggleAddDrawer} /> : null}
      {editOpen && selectedEntity ? (
        <ComsEditDrawer
          toggle={toggleEditDrawer}
          selectedEntity={selectedEntity}
        />
      ) : null}
    </>
  );
};

export default CollapsibleComsList;
