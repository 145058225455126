import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import { fetchIcsIncidentMedicalAidStations } from './ics_incidentMedicalAidStationsSlice';

import { getUserGuid, getGroupGuid } from 'slices/sliceHelpers';

// Slice
const ics_medicalAidStationsSlice = createSlice({
    name: 'ics_medical_aid_stations',
    initialState: {
        ics_medical_aid_stations: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        createIcsMedicalAidStationSuccess: (state, action) => {
            state.ics_medical_aid_stations.push(action.payload);
        },
        fetchIcsMedicalAidStationsSuccess: (state, action) => {
            state.ics_medical_aid_stations = action.payload;
        },
        updateIcsMedicalAidStationSuccess: (state, action) => {
            const index = state.ics_medical_aid_stations.findIndex((station) => station.id === action.payload.id);
            state.ics_medical_aid_stations[index] = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    createIcsMedicalAidStationSuccess,
    fetchIcsMedicalAidStationsSuccess,
    updateIcsMedicalAidStationSuccess,
    setError
} = ics_medicalAidStationsSlice.actions;

export default ics_medicalAidStationsSlice.reducer;

export const createIcsMedicalAidStation = (data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        
        const response = await axios.post(`${config.apiGateway.ics_medical_aid_stations}`, { ...data, user_guid });
        if (data.incident_id) {
            dispatch(fetchIcsIncidentMedicalAidStations(data.incident_id));
        }
        dispatch(fetchIcsMedicalAidStations(group_guid));
        dispatch(createIcsMedicalAidStationSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsMedicalAidStations = (group_guid) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_medical_aid_stations}/${group_guid}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsMedicalAidStationsSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsMedicalAidStation = (id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_medical_aid_stations}/${id}`, { ...data, user_guid });
        dispatch(fetchIcsMedicalAidStations(group_guid));
        dispatch(updateIcsMedicalAidStationSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
