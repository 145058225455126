import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

import {
  createIcsResourceRequest,
  updateIcsResourceRequest,
} from 'slices/ics_resourceRequestsSlice';

import ResourceRequestItems from './ResourceRequestItems';
import {rr_status_options} from './ICSConstants'

function initReqItems(editResourceRequestData,ics_resource_request_items)
{
  const resourceRequestItems = ics_resource_request_items.filter(
    (item) => item.resource_request_id === editResourceRequestData?.id
  )
  return resourceRequestItems
}

export default function NewICSResourceRequestDialog({
  show,
  setEditResourceRequestData,
  editResourceRequestData,
  modalType,
  setModalType,
  onClose,
  incident_id,
  ics_resources,
  ics_resource_request_items=[],
  ics_resource_sites
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const [resourceRequestId, setResourceRequestId] = useState('');
  const [status, setStatus] = useState(rr_status_options[0].label)
  const [resourceRequestItems, setResourceRequestItems] = useState(editResourceRequestData?.resource_request_items||[])
  const reduxDispatch = useDispatch();
  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);
  const [saved, setSaved] = useState(false) // Weird situation with nested form submit

  const {user_guid} = useSelector((state) => {
    return state.app.user;
  });

  useEffect(() => {
    if (modalType === 'New') {
      setResourceRequestId(generateUUID());
    } else if (modalType === 'Edit') {
      setResourceRequestId(editResourceRequestData.id);
      setValue('name', editResourceRequestData.name);
      setValue('supply_sources', editResourceRequestData.supply_sources);
      setValue('requestor_signature', editResourceRequestData.requestor_signature);
      setValue('cmd_signature', editResourceRequestData.cmd_signature);
      setValue('logistics_order_number', editResourceRequestData.logistics_order_number);
      setValue('supplier_contact_info', editResourceRequestData.supplier_contact_info);
      setValue('logistics_notes', editResourceRequestData.logistics_notes);
      setValue('logistics_signature', editResourceRequestData.logistics_signature);
      setValue('resl_tac_or_pers', editResourceRequestData.resl_tac_or_pers);
      setValue('resl_available', editResourceRequestData.resl_available);
      setValue('resl_not_available', editResourceRequestData.resl_not_available);
      setValue('resl_notes', editResourceRequestData.resl_notes);
      setValue('resl_signature', editResourceRequestData.resl_signature);
      setValue('finance_notes', editResourceRequestData.finance_notes);
      setValue('finance_signature', editResourceRequestData.finance_signature);
      setValue('order_created_signature',editResourceRequestData.order_created_signature)
    }
  }, [modalType, editResourceRequestData, setValue]);

  const onNewResourceRequestDataSubmit = (data) => {

    if(!saved) return

    const timestamp = new Date().toISOString();
    let payload = {
      resource_request_id: editResourceRequestData?.resource_request_id || resourceRequestId,
      name: data.name,
      user_guid: user_guid,
      supply_sources: data.supply_sources,
      requestor_signature: data.requestor_signature ? user_guid : null,
      requestor_signature_timestamp: editResourceRequestData?.requestor_signature_timestamp || (data.requestor_signature ? timestamp : null),
      cmd_signature: data.cmd_signature ? user_guid : null,
      cmd_signature_timestamp: editResourceRequestData?.cmd_signature_timestamp || (data.cmd_signature ? timestamp : null),
      logistics_order_number: data.logistics_order_number,
      supplier_contact_info: data.supplier_contact_info,
      logistics_notes: data.logistics_notes,
      logistics_signature: data.logistics_signature ? user_guid : null,
      logistics_signature_timestamp: editResourceRequestData?.logistics_signature_timestamp || (data.logistics_signature ? timestamp : null),
      resl_tac_or_pers: data.resl_tac_or_pers||false,
      resl_available: data.resl_available,
      resl_not_available: data.resl_not_available,
      resl_notes: data.resl_notes,
      resl_signature: data.resl_signature ? user_guid : null,
      resl_signature_timestamp: editResourceRequestData?.resl_signature_timestamp || (data.resl_signature ? timestamp : null),
      finance_notes: data.finance_notes,
      finance_signature: data.finance_signature ? user_guid : null,
      finance_signature_timestamp: editResourceRequestData?.finance_signature_timestamp || (data.finance_signature ? timestamp : null),
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      archived: false,
      version: editResourceRequestData?.version || 0,
      status: editResourceRequestData?.status || status,
      resource_request_items: resourceRequestItems,
      order_created_signature: data.order_created_signature
    };

    // If Awaiting Approval and then all signatures have been made, the order is now Approved.
    if(payload.status===rr_status_options[0].label
      && !!payload.requestor_signature
      && !!payload.cmd_signature
      && !!payload.logistics_signature
      && !!payload.resl_signature
      && !!payload.finance_signature)
    {
      payload.status = rr_status_options[1].label
    } else if (!payload.requestor_signature
      || !payload.cmd_signature
      || !payload.logistics_signature
      || !payload.resl_signature
      || !payload.finance_signature)
    {
      payload.status = rr_status_options[0].label
    }

    // If this is an existing RR, and there is no preexisting order_created_signature, and it has been checked, then order is now In Progress
    if(!!editResourceRequestData && !editResourceRequestData.order_created_signature && !!data.order_created_signature)
    {
      payload.status = rr_status_options[2].label
    }

    if (incident_id) {
      payload.incident_id = incident_id;
    }  

    if (modalType === 'New') {
      reduxDispatch(createIcsResourceRequest(payload));
      onClose();
    } else if (modalType === 'Edit') {
      reduxDispatch(updateIcsResourceRequest(payload.resource_request_id, payload));
      onClose();
    }
  };

  const resourceRequestSubmittable = !!resourceRequestItems?.length

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Resource Request</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewResourceRequestDataSubmit)}>
        <Modal.Body>
          <div className="m-0">
            <strong>All signatures are required for "Approved" status.</strong>
          </div>
          <div className="m-0">
            <strong>Status: {status}</strong>
          </div>
          <div className="m-0">
            <label className="form-label">Name:</label>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.name && (
              <span className="form-text form-error">This field is required</span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Supply Sources:</label>
            <Controller
              control={control}
              name="supply_sources"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <ResourceRequestItems
              resourceRequestId={resourceRequestId}
              ics_resources={ics_resources}
              ics_resource_request_items={ics_resource_request_items}
              resourceRequestItems={resourceRequestItems}
              setResourceRequestItems={setResourceRequestItems}
              modalType={modalType}
              isNewResourceRequest={!editResourceRequestData}
              ics_resource_sites={ics_resource_sites}
            />
            {!resourceRequestSubmittable && (
              <span className="form-text form-error">At least one Resource must be selected.</span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Requestor Signature:</label>
            <Controller
              control={control}
              name="requestor_signature"
              render={({ field: { onChange, value } }) => (
                <input
                  type="checkbox"
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked ? user_guid : null)}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">CMD Signature:</label>
            <Controller
              control={control}
              name="cmd_signature"
              render={({ field: { onChange, value } }) => (
                <input
                  type="checkbox"
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked ? user_guid : null)}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Logistics Order Number:</label>
            <Controller
              control={control}
              name="logistics_order_number"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Supplier Contact Info:</label>
            <Controller
              control={control}
              name="supplier_contact_info"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Logistics Notes:</label>
            <Controller
              control={control}
              name="logistics_notes"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Logistics Signature:</label>
            <Controller
              control={control}
              name="logistics_signature"
              render={({ field: { onChange, value } }) => (
                <input
                  type="checkbox"
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked ? user_guid : null)}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">RESL TAC or PERS:</label>
            <Controller
              control={control}
              name="resl_tac_or_pers"
              render={({ field: { onChange, value } }) => (
                <input
                  type="checkbox"
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked)}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">RESL Available:</label>
            <Controller
              control={control}
              name="resl_available"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">RESL Not Available:</label>
            <Controller
              control={control}
              name="resl_not_available"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">RESL Notes:</label>
            <Controller
              control={control}
              name="resl_notes"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">RESL Signature:</label>
            <Controller
              control={control}
              name="resl_signature"
              render={({ field: { onChange, value } }) => (
                <input
                  type="checkbox"
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked ? user_guid : null)}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Finance Notes:</label>
            <Controller
              control={control}
              name="finance_notes"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Finance Signature:</label>
            <Controller
              control={control}
              name="finance_signature"
              render={({ field: { onChange, value } }) => (
                <input
                  type="checkbox"
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked ? user_guid : null)}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Order Created:</label>
            <Controller
              control={control}
              name="order_created_signature"
              render={({ field: { onChange, value } }) => (
                <input
                  type="checkbox"
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked ? user_guid : null)}
                />
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit"
              disabled={!resourceRequestSubmittable}
              onClick={()=>setSaved(true)}
            >
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
