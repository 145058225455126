import {
  getAllQuestionByAor,
  upsertQuestionByAor,
} from 'actions/ReportActions';
import { getAllAOR } from 'actions/locationAction';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import IconTimes from 'assets/images/icon__times.svg';
import IconAdd from 'assets/images/icon__add.svg';
import generateUUID from 'utils/sharedUtils/generateUUID';

export default function ManageCustomFieldsModal({ show, onClose }) {
  const reduxDispatch = useDispatch();
  const allAor = useSelector((state) => {
    return state.app.allAor.map((aor) => ({ label: aor.name, value: aor.id }));
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    getValues,
    watch,
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'question',
  });

  useEffect(() => {
    reduxDispatch(getAllAOR());
  }, []);
  const getAllQuestionByAorId = async (aor_id) => {
    const aorQuestion = await reduxDispatch(getAllQuestionByAor(aor_id));
    if (aorQuestion.length > 0) {
      onUpdateFromField(aorQuestion);
    } else {
      setValue('question',[]);
      onAppend();
    }
  };
  const onAppend = () => {
    append({
      question: undefined,
      options: [{ field1: '' }],
    });
  };

  const onRemove = (removeIndex) => {
    remove(removeIndex);
    if (fields.length === 1) {
      onAppend();
    }
  };

  const removeBadCharacters = (input) => {
    return input.replace("'", "`")
  }

  const onSave = async (data) => {
    const newData = {
      aor: data.aor.value,
      question: data.question.map((q) => {
        return {
          ...q,
          id: q.id ? q.id : generateUUID(),
          options: q.options.map((op) => {
            return {
              label: removeBadCharacters(op.field1),
              value: removeBadCharacters(op.field1),
            };
          }),
        };
      }),
    };
    const updatedQuestion = await reduxDispatch(upsertQuestionByAor(newData));
    onUpdateFromField(updatedQuestion);
    onClose();
  };
  const onUpdateFromField = (questionToUpdate) => {
    setValue(
      'question',
      questionToUpdate.map((q) => {
        return {
          id: q.id,
          question: q.question,
          options: q.options.map((op) => {
            return {
              field1: op.label,
            };
          }),
        };
      })
    );
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Manage Custom Fields</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSave)}>
        <Modal.Body>
          <label className="form-label">AOR (Area of Responsibility)</label>
          <Controller
            control={control}
            name="aor"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishNewSelect
                options={allAor}
                defaultValue={value}
                value={value}
                onChange={(e) => {
                  if (e) {
                    getAllQuestionByAorId(e.value);
                  }
                  onChange(e);
                }}
                placeholder={'Select AOR'}
                isClearable={true}
                isSearchable={true}
                isMulti={false}
                isDisabled={false}
              />
            )}
          />
          {errors?.aor && (
            <span className="form-text form-error">This field is required</span>
          )}
          {!!fields.length ? <hr className="dashed" /> : null}
          {fields &&
            fields.length > 0 &&
            fields.map((item, index) => {
              return (
                <div
                  className={`bg-gray-900--light-5 p-4 ${
                    index !== 0 ? 'mt-4' : ''
                  }`}
                  key={item.id}
                >
                  <div className="d-flex">
                    <img
                      src={IconTimes}
                      className="img-h-16 ms-auto cursor-pointer"
                      alt=""
                      onClick={() => onRemove(index)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Label of Field</label>
                    <Controller
                      control={control}
                      name={`question.${index}.question`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          onChange={onChange}
                          value={value}
                          placeholder="Question"
                        />
                      )}
                    />
                    {errors?.question?.[index]?.question && (
                      <span className="form-text form-error">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="m-0">
                    <label className="form-label">Possible Answers</label>
                    <Options
                      control={control}
                      nestIndex={index}
                      errors={errors}
                    />
                  </div>
                </div>
              );
            })}
          {!!fields.length ? (
            <StylishNewButton
              type="button"
              className={'button--primary mt-4 w-100 w-md-auto'}
              onClick={onAppend}
            >
              Add Another Field
            </StylishNewButton>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              type="button"
              className="button--secondary button--reverse"
              onClick={onClose}
            >
              Cancel
            </StylishNewButton>
            {watch('aor')?.value && (
              <StylishNewButton type="submit" className="button--primary">
                Save
              </StylishNewButton>
            )}
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

const Options = ({ nestIndex, control, errors }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `question.${nestIndex}.options`,
  });
  return (
    <>
      {fields.map((item, index) => {
        return (
          <div key={item.id} className={`${index !== 0 ? 'mt-3' : ''}`}>
            <div className="position-relative">
              <Controller
                control={control}
                name={`question.${nestIndex}.options.${index}.field1`}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={onChange}
                    value={value}
                    placeholder="Option"
                    style={{ paddingRight: '2.75rem' }}
                  />
                )}
              />
              <span
                onClick={() => remove(index)}
                className="cursor-pointer"
                style={{
                  position: 'absolute',
                  right: '1rem',
                  top: '50%',
                  marginTop: '-.375rem',
                }}
              >
                <img src={IconTimes} className="img-h-12 d-block" alt="" />
              </span>
            </div>
            {errors?.question?.[nestIndex]?.options?.[index]?.field1 && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
        );
      })}
      <StylishNewButton
        type="button"
        className={'button--primary mt-4 w-100 w-md-auto'}
        onClick={() => append({ field1: '' })}
      >
        <img src={IconAdd} className="me-3" alt="" />
        Add Possible Answer
      </StylishNewButton>
    </>
  );
};
