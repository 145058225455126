import React from 'react';
import { Modal } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { createGroup } from '../../actions/profileActions';
import { useDispatch } from 'react-redux';

type NewOrganizationModalProps = {
  show: boolean;
  onClose: () => void;
};

type FormProps = { createGroupName: string };

const NewOrganizationModal: React.FC<NewOrganizationModalProps> = ({
  show,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted, isSubmitting },
  } = useForm<FormProps>({ mode: 'onChange' });

  const onNewGroupSubmit: SubmitHandler<FormProps> = async ({
    createGroupName,
  }) => {
    try {
      await dispatch(createGroup({ groupName: createGroupName }));
      onClose();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <form onSubmit={handleSubmit(onNewGroupSubmit)}>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Create New Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label">Name</label>
          <input
            className="form-control"
            type="text"
            {...register('createGroupName', { required: true })}
          />
          {errors.createGroupName && (
            <span className="form-text form-error">This field is required</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton className="button--secondary" onClick={onClose}>
              Cancel
            </StylishNewButton>
            <StylishNewButton
              type="submit"
              disabled={(isSubmitted && !isValid) || isSubmitting}
              className="button--primary"
            >
              Create Organization
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default NewOrganizationModal;
