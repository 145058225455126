export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
// export const SEND_RESET_MAIL = 'SEND_RESET_MAIL';
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const VALIDATING_TOKEN_REQUEST = "VALIDATING_TOKEN_REQUEST";
export const VALIDATING_TOKEN_SUCCESS = "VALIDATING_TOKEN_SUCCESS";
export const UPDATE_SELECTED_WORKSPACE = "UPDATE_SELECTED_WORKSPACE";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";
export const UPDATE_USER_NAME = "UPDATE_USER_NAME";
export const UPDATE_ACCESS_REFRESH_TOKEN = "UPDATE_ACCESS_REFRESH_TOKEN";
export const UPDATE_USER_TIME_FORMAT = "UPDATE_USER_TIME_FORMAT";
export const UPDATE_USER_ID_NAME = "UPDATE_USER_ID_NAME";
