export const TASK_STATUS_LIST = [
  {
    label: "Not Completed",
    value: "not_completed",
    color: '#D92D20', // error-600
  },
  {
    label: "In Progress",
    value: "in_progress",
    color: '#F79009', // warning-500
  },
  {
    label: "Completed",
    value: "completed",
    color: '#039855', // success-600
  },
];

export const TASK_TYPE = [
  {
    label: "Hurricane",
    value: "hurricane",
    icon: "hurricane",
  },
  {
    label: "Wildfire",
    value: "wildfire",
    icon: "wildfire",
  },
  {
    label: "Earthquake",
    value: "earthquake",
    icon: "earthquake",
  },
  {
    label: "NWS alert",
    value: "nws_alert",
    icon: "nws_alert",
  },
];

export const RTO_TIME_FRAME_OPTIONS = [
  {
    label:"hours", 
    value: "hours",
    unit: "hour",
  },
  {
    label:"days", 
    value: "days",
    unit: "day",
  },
  {
    label:"weeks", 
    value: "weeks",
    unit: "day",
  },
  {
    label:"months", 
    value: "months",
    unit: "month",
  }
]

export const GEOLOCATION_OPTIONS = [
  {
    label: "Geolocation of AOR of Reporter Team",
    value: "Geolocation of AOR of Reporter Team"
  },
  {
    label: "Geolocation of AOR",
    value: "Geolocation of AOR"
  },
  {
    label: "Custom Geolocation",
    value: "Custom Geolocation"
  }
]

export const TASK_PRIORITY = [
  { label: 'Normal', value: 'normal' },
  { label: 'High', value: 'high' },
];

export const STABILITY_LIST = {
  NeedsAssessment: {
    label: "Needs Assessment",
    value: "Needs Assessment",
    color: '#6C6E70', // fossil
    background: "#DFE1E6",
  },  
  InRestoration: {
    label: "In-Restoration",
    value: "In-Restoration",
    color: '#B54708', // warning-700
    background: "#FBCEB1",
  },
  PartiallyStable: {
    label: "Partially Stable",
    value: "Partially Stable",
    color: '#EF8C6E', // carrot
    background: "#FFFAE6",
  },
  Stable: {
    label: "Stable",
    value: "Stable",
    color: '#027A48', // success-700
    background: "#E3FCEF",
  },
  Disrupted: {
    label: "Disrupted",
    value: "Disrupted",
    color: '#F04438', // error-500
    background: "#FFEBE6",
  },
  NonCritical: {
    label: "Non-Critical",
    value: "Non-Critical",
    color: '#4273D5', // ocean
    background: "#DEEBFF",
  },
}

export const RESTORATION_STATUS_LIST = [
  { label: 'Restoration Started', value: 'Restoration Started' },
  { label: 'Restoration Partially Complete', value: 'Restoration Partially Complete' },
  { label: 'Restoration Completed', value: 'Restoration Completed' },
];

export const TASK_COLOR_TAB_DATA = [
  { label: 'Assessment', value: 'Assessment' },
  { label: 'Stability', value: 'Stability' },
];