import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewICSMedicalTransportationDialog from 'components/ICS/NewICSMedicalTransportationDialog';

import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

import ICSIncidentMedicalTransportation from './ICSIncidentMedicalTransportation';

import { updateIcsMedicalTransportation } from 'slices/ics_medicalTransportationSlice';

import {
  createIcsIncidentMedicalTransportation,
  updateIcsIncidentMedicalTransportation,
} from 'slices/ics_incidentMedicalTransportationSlice';
import ExpandRightArrow from '../DesignSystems/buttons/ExpandRightArrow';
import ExpandDownArrow from '../DesignSystems/buttons/ExpandDownArrow';

export default function ICSMedicalTransportationDialog({
  show,
  onClose,
  incident_id = null,
  ics_medical_transportation,
  ics_incident_medical_transportation
}) {
  const reduxDispatch = useDispatch();

  const [isNewICSMedicalTransportationDialogOpen, setIsNewICSMedicalTransportationDialogOpen] = useState(
    false
  );
  const [modalType, setModalType] = useState('New');
  const [editMedicalTransportationData, setEditMedicalTransportationData] = useState();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [archiveConfirmationData, setArchiveConfirmationData] = useState(null);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const [dialogTransportation, setDialogTransportation] = useState([
    ...ics_incident_medical_transportation,
  ]);

  useEffect(() => {
    setDialogTransportation(ics_incident_medical_transportation);
  }, [ics_incident_medical_transportation]);

  useEffect(() => {
    // Use to rerender
  }, [ics_medical_transportation]);

  const allColumns = [
    {
      dataField: 'ambulance_service',
      text: 'Ambulance Service',
      sort: true,
      attrs: { title: 'Ambulance Service' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      attrs: { title: 'Description' },
    },
    {
      dataField: 'address',
      text: 'Address',
      sort: true,
      attrs: { title: 'Address' },
    },
    {
      dataField: 'contact_number',
      text: 'Contact Number',
      sort: true,
      attrs: { title: 'Contact Number' },
    },
    {
      dataField: 'paramedics_on_board',
      text: 'Paramedics On Board',
      sort: true,
      formatter: (cell) => (cell ? 'Yes' : 'No'),
      attrs: { title: 'Paramedics On Board' },
    },
    {
      dataField: 'user_name',
      text: 'Last Modified By',
      sort: true,
      attrs: { title: 'Last Modified By' },
    },
    {
      dataField: 'archived',
      text: 'Archived',
      sort: true,
      attrs: { title: 'Archived' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <ICSIncidentMedicalTransportation
            row={row}
            ics_medical_transportation={ics_medical_transportation}
            ics_incident_medical_transportation={ics_incident_medical_transportation}
          />
          {(!incident_id ||
            !!ics_incident_medical_transportation.find(
              (trans) => trans.transportation_id === row.id
            )) && (
            <div className="button-group">
              <StylishNewButton
                className="button--primary"
                onClick={() => onEdit(row)}
              >
                Edit
              </StylishNewButton>
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={() => onArchive(row)}
              >
                {row.archived ? `Unarchive` : `Archive`}
              </StylishNewButton>
            </div>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? <ExpandDownArrow /> : <ExpandRightArrow />,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <span className="sr-only">Details</span>
            <ExpandDownArrow />
          </>
        );
      }
      return (
        <>
          <span className="sr-only">Details</span>
          <ExpandRightArrow />
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    selected: ics_medical_transportation
      .filter((trans) => {
        const incTrans = ics_incident_medical_transportation.find(
          (itrans) => itrans.transportation_id === trans.id && !itrans.archived
        );
        return !!incTrans;
      })
      .map((trans) => trans.id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex) => {
      const existingIncidentTransportation = ics_incident_medical_transportation.find(
        (itrans) => itrans.transportation_id === row.id
      );
      if (existingIncidentTransportation) {
        reduxDispatch(
          updateIcsIncidentMedicalTransportation(
            incident_id,
            existingIncidentTransportation.id,
            {
              ...existingIncidentTransportation,
              archived: !existingIncidentTransportation.archived,
            }
          )
        );
      } else {
        // Create a new incident medical transportation
        const incidentTransportationData = {
          transportation_id: row.id,
          ambulance_service: row.ambulance_service,
          description: row.description,
          address: row.address,
          contact_number: row.contact_number,
          paramedics_on_board: row.paramedics_on_board,
          user_guid: row.user_guid,
          incident_id: incident_id,
          group_guid: reduxCurrentlySelectedGroup.group_guid,
          archived: false,
        };
        reduxDispatch(
          createIcsIncidentMedicalTransportation(incident_id, incidentTransportationData)
        );
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setDialogTransportation(rows) : setDialogTransportation([]);
    },
  };

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewICSMedicalTransportationDialogOpen(true);
    setEditMedicalTransportationData(row);
  };

  const onArchive = (row) => {
    setShowConfirmDialog(true);
    setArchiveConfirmationData(row);
  };

  const onConfirmedArchiveActionData = () => {
    const payload = {
      ...archiveConfirmationData,
      incident_id,
      archived: !archiveConfirmationData.archived,
    };

    if (!!incident_id) {
      reduxDispatch(
        updateIcsIncidentMedicalTransportation(
          incident_id,
          archiveConfirmationData.id,
          payload
        )
      );
    } else {
      reduxDispatch(updateIcsMedicalTransportation(archiveConfirmationData.id, payload));
    }

    setShowConfirmDialog(false);
    setArchiveConfirmationData(null);
  };

  const onNewMedicalTransportation = () => {
    setModalType('New');
    setIsNewICSMedicalTransportationDialogOpen(true);
  };

  const tableRows = ics_medical_transportation.map((trans) => {
    const incTrans = ics_incident_medical_transportation.find(
      (itrans) => itrans.transportation_id === trans.id
    );
    if (incTrans) {
      return {
        ...incTrans,
        id: trans.id,
      };
    } else {
      return trans;
    }
  });

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Select Incident Medical Transportation</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">
          {reduxCurrentlySelectedGroup?.group_name}
        </p>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={onNewMedicalTransportation}
        >
          New Medical Transportation
        </StylishNewButton>
        <div>
          <label>
            {!!incident_id && (
              <>
                Select to add to incident. De-select to archive. Re-select to
                un-archive.
              </>
            )}
          </label>
        </div>
        <StylishNewTable
          keyField={'id'}
          rows={tableRows}
          columns={allColumns}
          expandRow={expandRow}
          selectRow={(!!incident_id && selectRow) || undefined}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewICSMedicalTransportationDialogOpen && (
        <NewICSMedicalTransportationDialog
          show={isNewICSMedicalTransportationDialogOpen}
          setEditMedicalTransportationData={setEditMedicalTransportationData}
          setModalType={setModalType}
          editMedicalTransportationData={editMedicalTransportationData}
          modalType={modalType}
          onClose={() => setIsNewICSMedicalTransportationDialogOpen(false)}
          incident_id={incident_id}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Archive Confirmation'}
          dialogContent={'Are you sure you want to archive this Medical Transportation?'}
          dialogButtonText={'Archive'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedArchiveActionData}
        />
      )}
    </Modal>
  );
}
