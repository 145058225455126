import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import NewResourcesDialog from 'components/IncidentConfig/NewResourcesDialog';
import {
  deleteResourceToIncident,
  updateIncidentResourceSelection,
} from 'actions/incidentActions';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

export default function ManageResourcesDialog({
  show,
  onClose,
  groupResources,
  selectedResources,
  setSelectedResources,
  groupName,
}) {
  const [isNewResourcesDialogOpen, setIsNewResourcesDialogOpen] = useState(
    false
  );
  const [modalType, setModalType] = useState('New');
  const [editResourceData, setEditResourceData] = useState();

  const [dialogResources, setDialogResources] = useState([]);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);

  const reduxDispatch = useDispatch();
  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  useEffect(() => {
    const filtered = groupResources.filter(
      ({ id }) =>
        !!selectedResources.find(({ id: selectedId }) => selectedId === id)
    );
    setDialogResources(filtered);
  }, [selectedResources]);

  const allColumns = [
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Type' },
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      attrs: { title: 'Name' },
    },
  ];

  const onDelete = (row) => {
    setShowConfirmDialog(true);
    setDeleteConfirmationData(row);
  };

  useEffect(() => {
    reduxDispatch(endLoading());
  }, [groupResources]);

  const onConfirmedDeleteActionData = () => {
    setDialogResources(
      dialogResources.filter((e) => e.id !== deleteConfirmationData.id)
    );
    reduxDispatch(startLoading());
    reduxDispatch(deleteResourceToIncident(deleteConfirmationData.id));
    setShowConfirmDialog(false);
    setDeleteConfirmationData(null);
  };

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewResourcesDialogOpen(true);
    setEditResourceData(row);
  };

  const onNewResource = () => {
    setModalType('New');
    setIsNewResourcesDialogOpen(true);
  };

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="col-md-4 mb-3">
              <h6 className="mb-2">Datetime Ordered</h6>
              <p className="m-0">
                {moment(row.datetime_ordered).format('MM/DD/YYYY @ hh:mm a')}
              </p>
            </div>
            <div className="col-md-4 mb-3">
              <h6 className="mb-2">ETA</h6>
              <p className="m-0">
                {moment(row.eta).format('MM/DD/YYYY @ hh:mm a')}
              </p>
            </div>
            <div className="col-md-4 mb-3">
              <h6 className="mb-2">Location</h6>
              <p className="m-0">{row.location}</p>
            </div>
            <div className="col-md-12 mb-4">
              <h6 className="mb-2">Notes</h6>
              <p className="m-0">{row.description}</p>
            </div>
          </div>
          <div className="button-group">
            {!!reduxValidateRBACPermissionForActionResult['Write Pod Data'] && (
              <>
                <StylishNewButton
                  className="button--primary"
                  onClick={() => onEdit(row)}
                >
                  Edit Description
                </StylishNewButton>
                <StylishNewButton
                  className="button--tertiary button--reverse"
                  onClick={() => onDelete(row)}
                >
                  Delete
                </StylishNewButton>
              </>
            )}
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: false,
    selected: dialogResources.map((e) => e.id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      let selection = dialogResources.map((item) => item.id);
      if (selection.includes(row.id)) {
        selection = selection.filter((e) => e !== row.id);
      } else {
        selection.push(row.id);
      }
      setDialogResources(
        groupResources.filter((e) => selection.includes(e.id))
      );
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setDialogResources(rows) : setDialogResources([]);
    },
  };

  const handleSaveClick = () => {
    setSelectedResources([...dialogResources]);
    reduxDispatch(startLoading());
    reduxDispatch(
      updateIncidentResourceSelection(dialogResources.map((item) => item.id))
    );
    onClose();
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Select Incident Resources</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">{groupName}</p>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={onNewResource}
        >
          New Resource
        </StylishNewButton>
        <StylishNewTable
          keyField={'id'}
          rows={groupResources}
          columns={allColumns}
          expandRow={expandRow}
          selectRow={selectRow}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
          <StylishNewButton
            type="button"
            className="button--primary"
            onClick={() => handleSaveClick()}
          >
            Save
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewResourcesDialogOpen && (
        <NewResourcesDialog
          show={isNewResourcesDialogOpen}
          modalType={modalType}
          setModalType={setModalType}
          editResourceData={editResourceData}
          setEditResourceData={setEditResourceData}
          onClose={() => setIsNewResourcesDialogOpen(false)}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this Resource?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedDeleteActionData}
        />
      )}
    </Modal>
  );
}
