import React, { useState, useEffect } from 'react';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import dayjs from 'dayjs';
import ICSChecklist from './ICSChecklist';

export default function ICSIncidentAction({ row, ics_incident_actions }) {

  const [name, setName] = useState(row.name);
  const [description, setDescription] = useState(row.description);
  const [poc, setPoc] = useState(row.poc);
  const [briefedPoc, setBriefedPoc] = useState(row.briefed_poc);
  const [startDate, setStartDate] = useState(row.start_date);
  const [status, setStatus] = useState(row.status);
  const [targetDate, setTargetDate] = useState(row.target_date);
  const [actualDate, setActualDate] = useState(row.actual_date);
  const [checklist, setChecklist] = useState(row.checklist);
  const [archived, setArchived] = useState(row.archived);
  const [lastModifiedBy, setLastModifiedBy] = useState(row.user_name);

  useEffect(()=>{
    setName(row.name);
    setDescription(row.description);
    setPoc(row.poc);
    setBriefedPoc(row.briefed_poc);
    setStartDate(row.start_date);
    setStatus(row.status);
    setTargetDate(row.target_date);
    setActualDate(row.actual_date);
    setChecklist(row.checklist);
    setArchived(row.archived);
    setLastModifiedBy(row.user_name);
  },[ics_incident_actions])

  return (
    <div className="ICSIncidentAction">
      <div className="mb-4">
        <h6 className="mb-2">Name:</h6>
        <p className="m-0">{name}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Description:</h6>
        <p className="m-0">{description}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">POC:</h6>
        <p className="m-0">{!!row.poc && row.poc_user_name}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Briefed POC:</h6>
        <p className="m-0">{briefedPoc ? 'Yes' : 'No'}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Start Date:</h6>
        <p className="m-0">{startDate}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Status:</h6>
        <p className="m-0">{status}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Target Date:</h6>
        <p className="m-0">{targetDate}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Actual Date:</h6>
        <p className="m-0">{actualDate}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Last Modified By:</h6>
        <p className="m-0">{lastModifiedBy}</p>
      </div>
      <div className="mb-4">
        <ICSChecklist
          checklist={checklist}
          setChecklist={setChecklist}
          incident_id={row.incident_id}
          editable={false}
          existingData={!!row}
        />
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Archived:</h6>
        <p className="m-0">{archived ? 'Yes' : 'No'}</p>
      </div>
    </div>
  );
}
