export const FETCH_FORM_RECORDS_REQUEST = 'FETCH_FORM_RECORDS_REQUEST';
export const FETCH_FORM_RECORDS_SUCCESS = 'FETCH_FORM_RECORDS_SUCCESS';
export const FETCH_FORM_RECORDS_ERROR = 'FETCH_FORM_RECORDS_ERROR';
export const UPSERT_FORM_RECORD_REQUEST = 'UPSERT_FORM_RECORD_REQUEST';
export const UPSERT_FORM_RECORD_SUCCESS = 'UPSERT_FORM_RECORD_SUCCESS';
export const UPSERT_FORM_RECORD_ERROR = 'UPSERT_FORM_RECORD_ERROR';
export const DELETE_FORM_RECORD_REQUEST = 'DELETE_FORM_RECORD_REQUEST';
export const DELETE_FORM_RECORD_SUCCESS = 'DELETE_FORM_RECORD_SUCCESS';
export const DELETE_FORM_RECORD_ERROR = 'DELETE_FORM_RECORD_ERROR';
export const EXPORT_FORM_TO_PDF_REQUEST = 'EXPORT_FORM_TO_PDF_REQUEST';
export const EXPORT_FORM_TO_PDF_SUCCESS = 'EXPORT_FORM_TO_PDF_SUCCESS';
export const EXPORT_FORM_TO_PDF_ERROR = 'EXPORT_FORM_TO_PDF_ERROR';
export const DOWNLOAD_FORMS_DATA_ARRAY_REQUEST =
  'DOWNLOAD_FORMS_DATA_ARRAY_REQUEST';
export const DOWNLOAD_FORMS_DATA_ARRAY_SUCCESS =
  'DOWNLOAD_FORMS_DATA_ARRAY_SUCCESS';
export const DOWNLOAD_FORMS_DATA_ARRAY_ERROR =
  'DOWNLOAD_FORMS_DATA_ARRAY_ERROR';
export const ADD_NEW_ACTION = 'ADD_NEW_ACTION';
export const ADD_NEW_ACTION_REQUEST = 'ADD_NEW_ACTION_REQUEST';
export const ADD_NEW_ACTION_SUCCESS = 'ADD_NEW_ACTION_SUCCESS';
export const UPDATE_ACTION = 'UPDATE_ACTION';
export const DELETE_ACTION = 'DELETE_ACTION';
export const GET_ALL_ACTION = 'GET_ALL_ACTION';
export const GET_ALL_ACTION_REQUEST = 'GET_ALL_ACTION_REQUEST';
export const GET_ALL_ACTION_SUCCESS = 'GET_ALL_ACTION_SUCCESS';
export const UPDATE_SITUATION_REQUEST = 'UPDATE_SITUATION_REQUEST';
export const UPDATE_SITUATION_SUCCESS = 'UPDATE_SITUATION_SUCCESS';
export const FETCH_MEDICAL_PLAN = 'FETCH_MEDICAL_PLAN';
export const FETCH_MEDICAL_AID_STATION_LIST = 'FETCH_MEDICAL_AID_STATION_LIST';
export const FETCH_TRANSPORTATION_LIST = 'FETCH_TRANSPORTATION_LIST';
export const FETCH_HOSPITAL_LIST = 'FETCH_HOSPITAL_LIST';
export const ADD_NEW_BRANCH = 'ADD_NEW_BRANCH';
export const ADD_NEW_DIVISION = 'ADD_NEW_DIVISION';
export const GET_ALL_BRANCH = 'GET_ALL_BRANCH';
export const GET_ALL_DIVISION = 'GET_ALL_DIVISION';
export const GET_PLANNING_WORKSHEET = 'GET_PLANNING_WORKSHEET';
export const GET_ALL_WORK_ASSIGNMENT = 'GET_ALL_WORK_ASSIGNMENT';
export const GET_ICS_OBJECTIVES_DATA = 'GET_ICS_OBJECTIVES_DATA';
export const GET_IAP_COVER_SHEET_DATA = 'GET_IAP_COVER_SHEET_DATA';
export const FETCH_SAFETY_AND_HEALTH_FORM_DATA =
  'FETCH_SAFETY_AND_HEALTH_FORM_DATA';
export const UPSERT_COMMUNICATION = 'UPSERT_COMMUNICATION';
export const FETCH_MEETINGS = 'FETCH_MEETINGS';
export const FETCH_RESOURCE_REQUEST = 'FETCH_RESOURCE_REQUEST'
export const FETCH_ALL_RESOURCE_REQUEST = 'FETCH_ALL_RESOURCE_REQUEST';

export const EXPORT_AS_FORM_REQUEST = 'EXPORT_AS_FORM_REQUEST'
export const EXPORT_AS_FORM_SUCCESS = 'EXPORT_AS_FORM_SUCCESS'
export const EXPORT_AS_FORM_ERROR = 'EXPORT_AS_FORM_ERROR'

export const FETCH_INCIDENT_ACTIONS_REQUEST = 'FETCH_INCIDENT_ACTIONS_REQUEST'
export const FETCH_INCIDENT_ACTIONS_SUCCESS = 'FETCH_INCIDENT_ACTIONS_SUCCESS'
export const FETCH_INCIDENT_ACTIONS_ERROR = 'FETCH_INCIDENT_ACTIONS_ERROR'