import React, { useEffect } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

import { owmLayers } from '../constants/owmLayers.js';
import owmLayerEffect from '../mapEffects/owmLayerEffect';
import { StylishNewRadio } from 'components/DesignSystems/New/StylishNewRadio';

const MapWeather = (props) => {
  const {
    map,
    mapSettings,
    mapHasLoaded,
    owmToken,
    handleMapSettings,
    setLayerClicked,
    onClose,
  } = props;

  useEffect(() => {
    owmLayerEffect(
      owmToken,
      map,
      mapSettings?.weatherLayer,
      mapHasLoaded,
      mapSettings?.selectedDatetime,
      setLayerClicked
    );
  }, [
    mapSettings?.weatherLayer,
    mapHasLoaded,
    mapSettings?.selectedDatetime,
    map,
    owmToken,
  ]);

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        <h4 className="m-0">Weather</h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={() => onClose()}
        >
          <i className="material-symbols-outlined">close</i>
        </StylishNewButton>
      </div>
      <div className="sidebar-inner">
        <StylishNewRadio
          className={'mb-3'}
          label="None"
          checked={mapSettings?.weatherLayer === undefined}
          onChange={() => {
            handleMapSettings({
              ...mapSettings,
              weatherLayer: undefined,
            });
          }}
        />
        <hr className="dashed w-10 thin m-0" />
        {owmLayers.map((l, idx) => {
          return (
            <React.Fragment key={'weather-' + idx}>
              {idx !== 0 ? <hr className="dashed w-10 thin m-0" /> : null}
              <StylishNewRadio
                className={`my-3`}
                key={l.key}
                label={l.key}
                onChange={() =>
                  handleMapSettings({
                    ...mapSettings,
                    weatherLayer: l,
                  })
                }
                checked={mapSettings?.weatherLayer?.key === l.key}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default MapWeather;
