import {
  ADD_TO_FEATURE_FLAG_LIST,
  REMOVE_FORM_FEATURE_FLAG_LIST,
} from './FeatureFlag.constants';

const initialState = {
  featureFlag: [],
};

const featureFlagReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_FEATURE_FLAG_LIST:
      return {
        ...state,
        featureFlag: [...state.featureFlag, action.payload],
      };
    case REMOVE_FORM_FEATURE_FLAG_LIST:
      return {
        ...state,
        featureFlag: state.featureFlag.filter(
          (feature) => feature !== action.payload
        ),
      };

    default:
      return state;
  }
};

export default featureFlagReducer;
