export const Config206 = {
  formname: 'ICS 206',
  filename: 'ICS_206.PDF',
  field: [
    {
      key: '1 Incident Name_10',
      type: 'Text',
      value: '',
    },
    {
      key: 'Date From',
      type: 'Text',
      value: '',
    },
    {
      key: 'Date To',
      type: 'Text',
      value: '',
    },
    {
      key: 'Time From',
      type: 'Text',
      value: '',
    },
    {
      key: 'Time To',
      type: 'Text',
      value: '',
    },
    {
      key: 'Medical Aid Stations',
      type: 'Table',
      value: [],
    },
    {
      key: 'Transportation',
      type: 'Table',
      value: [],
    },
    {
      key: 'Hospitals',
      type: 'Table',
      value: [],
    },
    {
      key: 'Special Medical Emergency Procedures',
      type: 'Text',
      value: '',
    },
    {
      key: 'Check Box if aviation assests are utilized for rescue',
      type: 'Check',
      value: '',
    },
    {
      key: '7 Prepared by Medical Unit Leader Name',
      type: 'Text',
      value: '',
    },
    {
      key: '8 Approved by Safety Officer Name',
      type: 'Text',
      value: '',
    },
    {
      key: 'Signature_11',
      type: 'Image',
      value: '',
    },
    {
      key: 'Signature_12',
      type: 'Image',
      value: '',
    },
    {
      key: 'DateTime_10',
      type: 'Text',
      valeu: '',
    },
  ],
};
