import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { RESTORATION_STATUS_LIST, STABILITY_LIST, TASK_PRIORITY, TASK_STATUS_LIST } from './TaskManagementConstant';

// constant variables
const initialFilters = {
  assessment: null,
  restoration: null,
  stability: null,
  priority: null,
  team: null,
};

export default function TaskManagementFilter(props) {
  const {
    isVertical = false,
    onChange, 
    filters
  } = props;

  // useState variables.
  const [selectedFilters, setSelectedFilters] = useState(filters ?? initialFilters)

  const teamList = useSelector((state) => {
    return state.app.distributionListItems;
  });

  const assessmentOptions = [
    {
      label: 'All Assessment',
      value: null,
    },
    ...TASK_STATUS_LIST
  ];

  const restorationOptions = [
    {
      label: 'All Restoration',
      value: null,
    },
    ...RESTORATION_STATUS_LIST
  ];

  const stabilityList = Object.values(STABILITY_LIST).map(item => ({
    label: item.label,
    value: item.value,
  }));

  const stabilityOptions = [
    {
      label: 'All Stability',
      value: null,
    },
    ...stabilityList
  ];

  const priorityOptions = [
    {
      label: 'All Priority',
      value: null,
    },
    ...TASK_PRIORITY
  ];

  const teamOptions = useMemo(() => {
    let teamOptions = [{
      label: 'All Teams',
      value: null,
    },];
    if (teamList?.length > 0) {
      teamList.forEach(item => {
        teamOptions.push({
          label: item?.name,
          value: item?.id,
        })
      })
    }

    return teamOptions;
  }, [teamList]);

  

  const handleChange = (e, key) => {
    let _filterOptions = {...selectedFilters};
    _filterOptions = {
      ...selectedFilters,
      [key]: e
    };
    setSelectedFilters(_filterOptions);
    onChange(_filterOptions);
  }

  const handleClearFilters = () => {
    setSelectedFilters(initialFilters);
    onChange(initialFilters);
  }

  const optionClassName = isVertical ? 
      'col-12 mb-2' : 
      'col-xl-2 col-lg-4 col-md-6 col-sm-12 d-flex-column';

  return (
    <div className="row">
      <div className={optionClassName}>   
        <div className="mt-2 mt-xl-0">
          <label>Assessment</label>
        </div>
        <div className="">
          <StylishNewSelect
            value={selectedFilters?.assessment}
            defaultValue={selectedFilters?.assessment}
            options={assessmentOptions}
            onChange={(e) => { handleChange(e, 'assessment') }}
            placeholder="Assessment"
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            isDisabled={false}
          />
        </div>
      </div>
      <div className={optionClassName}>   
        <div className="mt-2 mt-xl-0">
          <label>Restoration</label>
        </div>
        <div className="">
          <StylishNewSelect
            value={selectedFilters?.restoration}
            defaultValue={selectedFilters?.restoration}
            options={restorationOptions}
            onChange={(e) => { handleChange(e, 'restoration') }}
            placeholder="Restoration"
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            isDisabled={false}
          />
        </div>
      </div>
      <div className={optionClassName}>   
        <div className="mt-2 mt-xl-0">
          <label>Stability</label>
        </div>
        <div className="">
          <StylishNewSelect
            value={selectedFilters?.stability}
            defaultValue={selectedFilters?.stability}
            options={stabilityOptions}
            onChange={(e) => { handleChange(e, 'stability') }}
            placeholder="Stability"
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            isDisabled={false}
          />
        </div>
      </div>
      <div className={optionClassName}>   
        <div className="mt-2 mt-xl-0">
          <label>Priority</label>
        </div>
        <div className="">
          <StylishNewSelect
            value={selectedFilters?.priority}
            defaultValue={selectedFilters?.priority}
            options={priorityOptions}
            onChange={(e) => { handleChange(e, 'priority') }}
            placeholder="Priority"
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            isDisabled={false}
          />
        </div>
      </div>
      <div className={optionClassName}>   
        <div className="mt-2 mt-xl-0">
          <label>Filter Team</label>
        </div>
        <div className="">
          <StylishNewSelect
            value={selectedFilters?.team}
            defaultValue={selectedFilters?.team}
            options={teamOptions}
            onChange={(e) => { handleChange(e, 'team') }}
            placeholder="Filter Team"
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            isDisabled={false}
          />
        </div>
      </div>
      <div className={optionClassName}>   
        <div className="mt-2 mt-xl-0">
          <label></label>
        </div>
        <div className="">
          <StylishNewButton
            type="button"
            className="button--primary w-100 w-md-auto"
            onClick={()=> {handleClearFilters()}}
          >
            Clear Filter
          </StylishNewButton>
        </div>
      </div>
    </div>
  );
}
