import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { markIncidentUserDeactive } from 'actions/incidentActions';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

export default function IncidentNonActiveConfirmDialog({
  show,
  onClose,
  selectedRow,
  incident_id,
}) {
  const [isLoaded, setIsLoaded] = useState(true);
  const [minutes_worked, setMinutesWorked] = useState(0);
  const [logout_time, setLogOutTime] = useState('');
  const [logoutTimeError, setLogoutTimeError] = useState({
    hasError: true,
    message: '',
    touched: false,
  });
  const reduxDispatch = useDispatch();
  const isGetRosterForIncidentLoaded = useSelector((state) => {
    return state.app.isGetRosterForIncidentLoaded;
  });
  const isUserDeactivateToIncidentLoaded = useSelector((state) => {
    return state.app.isUserDeactivateToIncidentLoaded;
  });

  useEffect(() => {
    if (
      !isLoaded &&
      isUserDeactivateToIncidentLoaded &&
      isGetRosterForIncidentLoaded
    ) {
      onClose();
    }
  }, [
    isUserDeactivateToIncidentLoaded,
    isGetRosterForIncidentLoaded,
    isLoaded,
    onClose,
  ]);

  useEffect(() => {
    const endDate = moment();
    endDate.add(-2, 'seconds');
    changeDate(endDate.format('MM/DD/YYYY HH:mm'));
  }, [selectedRow]);

  const changeDate = (date) => {
    setLogOutTime(date);
    const endDate = moment(date).add(-2, 'seconds');
    const startDate = moment(selectedRow.active_time);
    const m_worked = isNaN(endDate.diff(startDate, 'minutes'))
      ? 0
      : endDate.diff(startDate, 'minutes');
    setMinutesWorked(m_worked);
    if (endDate.isAfter(moment())) {
      setLogoutTimeError({
        touched: true,
        hasError: true,
        message: "Logout Time Can't be Greater Than Current Time",
      });
    } else if (m_worked <= 0) {
      setLogoutTimeError({
        touched: true,
        hasError: true,
        message: "Invalid Date Logout Time Can't Be Earlier Than Login Time",
      });
    } else {
      setLogoutTimeError({ touched: true, hasError: false, message: '' });
    }
  };
  const onTouched = () => {
    const datetime = moment(logout_time);
    if (datetime.isAfter(moment())) {
      setLogoutTimeError({
        touched: true,
        hasError: true,
        message: "Logout Time Can't be Greater Than Current Time",
      });
    } else if (minutes_worked <= 0) {
      setLogoutTimeError({
        touched: true,
        hasError: true,
        message: "Invalid Date Logout Time Can't Be Earlier Than Login Time",
      });
    } else {
      setLogoutTimeError({ touched: true, hasError: false, message: '' });
    }
  };
  const onConfirm = () => {
    if (logoutTimeError.hasError) {
      onTouched();
      return;
    }
    setIsLoaded(false);
    reduxDispatch(
      markIncidentUserDeactive(
        incident_id,
        selectedRow.user_guid,
        new Date(logout_time),
        minutes_worked,
        selectedRow.incident_member_id
      )
    );
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Confirm Logout Time</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="weight-600">
          Are you sure you worked for {minutes_worked} minutes?
        </p>
        <label className="form-label d-block">Logout Time</label>
        <StylishDateTimePicker
          value={logout_time}
          onChange={changeDate}
          type={'datetime-local'}
          onBlur={onTouched}
          minDate={new Date(selectedRow?.active_time)}
          maxDate={new Date()}
        />
        {logoutTimeError.touched && logoutTimeError.hasError && (
          <span className="form-text form-error">
            {logoutTimeError.message}
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
          <StylishNewButton
            className="button--primary"
            type="button"
            disabled={!isLoaded}
            onClick={() => onConfirm()}
          >
            Confirm
          </StylishNewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
