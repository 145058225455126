import { Offcanvas, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { AddEntityDrawerProps } from './EntityListProps';
import { useCreateIncidentAction } from './hooks/incidentActionHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import {
  selectIncidentGuid,
  useAppSelector,
} from '../../slices/commonSelectors';
import { useIncidentRoster } from './hooks/incidentRosterHooks';
import { action_status_options } from '../ICS/ICSConstants';
import ICSChecklist from '../ICS/ICSChecklist';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import FormSelectField from './formFields/FormSelectField';
import FormCheckboxField from './formFields/FormCheckboxField';
import FormDateTimeField from './formFields/FormDateTimeField';
import { SharedIcon } from '../SharedIcon/SharedIcon';

type FormFields = {
  name: string;
  description: string;
  poc: string;
  briefed_poc: boolean;
  start_date: Date;
  status: string;
  target_date: Date;
  actual_date: Date;
};

const ActionAddDrawer: React.FC<AddEntityDrawerProps> = ({ toggle }) => {
  const form = useForm<FormFields>({
    mode: 'onChange',
  });
  const { handleSubmit } = form;

  const [checklist, setChecklist] = useState<
    { label: string; status: string }[]
  >([]);

  const incident_id = useAppSelector(selectIncidentGuid);
  const { data: incident_roster } = useIncidentRoster();
  const mutation = useCreateIncidentAction();

  const onNewActionDataSubmit = async (formData: FormFields) => {
    const payload = {
      ...formData,
      checklist: checklist,
      archived: false,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Add Action">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onNewActionDataSubmit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextField
                title="Name"
                fieldName="name"
                rules={{
                  required: { value: true, message: 'This field is required' },
                  maxLength: {
                    value: 300,
                    message: 'This maximum length is 300 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Description"
                fieldName="description"
                rules={{
                  maxLength: {
                    value: 300,
                    message: 'This maximum length is 300 characters',
                  },
                }}
                variant="full"
              />
            </Row>
            <Row>
              <FormSelectField
                title="Point of Contact"
                fieldName="poc"
                options={incident_roster?.map((u) => {
                  return {
                    label: u.user_name,
                    value: u.user_guid,
                  };
                })}
                variant="half"
              />
              <FormCheckboxField
                title="Briefed POC"
                fieldName="briefed_poc"
                variant="half"
              />
            </Row>
            <Row>
              <FormDateTimeField
                title="Start Date"
                fieldName="start_date"
                variant="half"
              />
              <FormSelectField
                title="Status"
                fieldName="status"
                options={action_status_options}
                variant="half"
              />
            </Row>
            <Row>
              <FormDateTimeField
                title="Target Date"
                fieldName="target_date"
                variant="half"
              />
              <FormDateTimeField
                title="Actual Date"
                fieldName="actual_date"
                variant="half"
              />
            </Row>
            <div className="mt-2">
              <label className="form-label d-flex align-items-center">
                <span className="me-2">Checklist</span>
                <SharedIcon iconName="info" size="sm" />
                <span className="color-muted" style={{ fontSize: '0.675rem' }}>
                  Checklists are not editable after creation.
                </span>
                {/** TODO: in the designs this info icon is solid and blue. It's probably a popover. */}
              </label>
              <ICSChecklist
                checklist={checklist}
                setChecklist={setChecklist}
                editable={true}
                incident_id={incident_id}
              />
            </div>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default ActionAddDrawer;
