import { Modal } from 'react-bootstrap';
import GraphicsEmailVerification from 'assets/images/graphics__email-not-verified.svg';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { productionEnv } from 'constants/debug';
import { ToastContainer, toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
const apiPrefix = productionEnv
  ? window.env.COMSDOMAIN
  : process.env.REACT_APP_COMSDOMAIN;

export default function EmailVerificationDialog({ show, onClose, user }) {
  const { logout } = useAuth0();
  const onResendLink = async () => {
    axios(`${apiPrefix}/dice/resend-email-verification`, {
      method: 'POST',
      data: JSON.stringify({
        user: user,
      }),
    })
      .then(() => {
        toast.success('Verification link sent to your email');
        setTimeout(() => {
          onCloseClick();
        }, 3000);
      })
      .catch(() => {
        toast.error('Failed to send verification link', toastConfig);
      });
  };
  const onCloseClick = () => {
    // todo: we need to have a logout mechanism for MSAL auth as well.
    logout({ logoutParams: { returnTo: window.location.origin } });
    onClose();
  };
  return (
    <>
      <Modal
        show={show}
        onHide={onCloseClick}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white"></Modal.Header>
        <Modal.Body className="pb-5">
          <div className="d-flex flex-column align-items-center">
            <img src={GraphicsEmailVerification} alt="" className="mb-5" />
            <h4 className="mb-4">Your Email Is not verified. Verify email</h4>
            <StylishNewButton
              className="button--primary"
              onClick={onResendLink}
            >
              Resend Verification Link
            </StylishNewButton>
          </div>
        </Modal.Body>
        <ToastContainer />
      </Modal>
    </>
  );
}
