import { useFormContext } from 'react-hook-form';
import { Row } from 'react-bootstrap';
import FormSelectMethod from '../FormSelectMethod';
import FormTextField from '../../IAP/formFields/FormTextField';
import FormTextAreaField from '../../IAP/formFields/FormTextAreaField';
import React from 'react';
import FormSelectField from '../../IAP/formFields/FormSelectField';
import { useAppSelector } from '../../../slices/commonSelectors';
import { DiceNotification } from '../generateNotificationEntity';

type ValidTemplate = DiceNotification & { template_title: string };

function isTemplate(t: DiceNotification): t is ValidTemplate {
  return t.template_title !== '' && t.template_title !== undefined;
}
export function SelectTypeAndContentStep() {
  const { watch } = useFormContext();
  const method = watch('contact_method');
  const template = watch('template');

  const templates = useAppSelector(
    (s) => s.app.notifications as DiceNotification[]
  );

  const templateOptions = templates.filter(isTemplate).map((t) => ({
    value: t.notification_id,
    label: t.template_title,
  }));

  return (
    <>
      <Row>
        <div className="txt--sml txt--bold pb-1">
          Choose a template notification
        </div>
        <div className="txt--sml text-weak">
          If you have created a template for this notification, please select
          it. Certain notification methods and recipient types are only
          available through a template.
        </div>
        <FormSelectField
          title={''}
          fieldName={'template'}
          options={templateOptions}
          isClearable={true}
        />
      </Row>
      {!template ? (
        <Row className={'py-3'}>
          <div className="txt--sml txt--bold pb-1">
            Select Type of Your Notification
          </div>
          <div className="txt--sml text-weak">
            Select the delivery methods most suitable for the urgency and
            accessibility needs of the message.
          </div>
          <FormSelectMethod fieldName="contact_method" />
        </Row>
      ) : null}
      {method.includes('Email') ? (
        <Row>
          <FormTextField
            title={'Subject'}
            fieldName={'subject'}
            rules={{
              required: {
                value: method.includes('Email'),
                message: 'Please enter a subject',
              },
            }}
            variant={'half'}
          />
          <FormTextAreaField
            title={'Write your email message'}
            fieldName={'email_message'}
            rules={{
              required: {
                value: method.includes('Email'),
                message: 'Please enter a message',
              },
              maxLength: {
                value: 1000,
                message:
                  'Please enter a message with less than 1000 characters',
              },
            }}
            variant={'full'}
          />
        </Row>
      ) : null}
      {method.includes('SMS') ? (
        <Row>
          <FormTextAreaField
            title={'Write your SMS message'}
            fieldName={'sms_message'}
            variant={'full'}
            rules={{
              required: {
                value: method.includes('SMS'),
                message: 'Please enter a message',
              },
              maxLength: {
                value: 160,
                message: 'Please enter a message with less than 160 characters',
              },
            }}
          />
        </Row>
      ) : null}
      {method.includes('Text-To-Speech (cell)') ||
      method.includes('Text-To-Speech (home)') ? (
        <Row>
          <FormTextAreaField
            title={'Write your Text-To-Speech message'}
            fieldName={'tts_message'}
            variant={'full'}
            rules={{
              required: {
                value:
                  method.includes('Text-To-Speech (cell)') ||
                  method.includes('Text-To-Speech (home)'),
                message: 'Please enter a message',
              },
              maxLength: {
                value: 160,
                message: 'Please enter a message with less than 160 characters',
              },
            }}
          />
        </Row>
      ) : null}
    </>
  );
}
