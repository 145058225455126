import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

import generateUUID from 'utils/sharedUtils/generateUUID';

import {
  bulkAddObjectives,
  bulkAddActions,
  bulkAddResources,
} from 'actions/incidentActions'

// import { updateSuggestionsAfterSave } from 'slices/dchatSlice'
// import { updateSuggestionsAfterSave } from 'actions/dgptActions'

import "./MEISelection.css"


const MEISelection = ({
  mei_type,
  planAIClicked,
}) => {
  const reduxDispatch = useDispatch()

  const planAISuggestions = useSelector(state=>{
    return state.dchat.planAISuggestions||{}
  })

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const reduxIncidentObjectives = useSelector(state=>{
    return state.app.incidentObjectives||[]
  })
  const reduxGroupObjectives = useSelector(state=>{
    return state.app.groupObjectives||[]
  })
  const reduxActions = useSelector((state) => state.app.actions||[]);
  const reduxIncidentActions = useSelector(state=>state.app.incidentActions||[])
  const groupResources = useSelector(state=>{
    return state.app.groupResources||[]
  })
  const incidentResources = useSelector(state=>{
    return state.app.incidentResources||[]
  })
  const isBulkAddObjectivesLoaded = useSelector(state=>{
    return state.app.isBulkAddObjectivesLoaded
  })
  const isBulkAddActionsLoaded = useSelector(state=>{
    return state.app.isBulkAddActionsLoaded
  })
  const isBulkAddResourcesLoaded = useSelector(state=>{
    return state.app.isBulkAddResourcesLoaded
  })
  const isInitPlanAISuggestionsLoaded = useSelector(state=>{
    return state.dchat.isInitPlanAISuggestionsLoaded
  })
  const isFetchPlanAISuggestionsLoaded = useSelector(state=>{
    return state.dchat.isFetchPlanAISuggestionsLoaded
  })

  const [suggestions,setSuggestions] = useState([])
  const [inUse,setInUse] = useState([])

  const meiSuggestionColumns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      attrs: { title: 'Name' },
    },
    {
      dataField: 'ai_suggestion',
      text: 'New Suggestion',
      sort: true,
      attrs: { title: 'New Suggestion' },
      formatter: (cell, row, rowIndex) => {
        return (
          <div 
            className={row.suggestion && `PlanAI-suggestion` || ``}
          >
          {row.suggestion && `Yes` || `No`}
          </div>
        );
      },
    },
  ];

  useEffect(()=>{
    if(!!planAISuggestions && mei_type==="objectives")
    {
      const meiSuggestions = planAISuggestions[mei_type]||[]

      let newInUse = reduxIncidentObjectives.map(o=>{
        return {
          ...o,
          id: o.objective_id,
          name: o.description
        }
      })

      let newSuggestions = meiSuggestions.map(s=>{
        return {
          ...s,
          id: s.objective_id || s.id,
          description: s.description || s.name,
          name: s.name,
          suggestion: true,
          objective_id: s.objective_id,
        }
      })

      // Add existing group objectives, but filter out those that have already been suggested
      let finalnewSuggestions = [
        ...newSuggestions,
        ...reduxGroupObjectives
          .filter(o=>!newSuggestions.find(s=>s.objective_id===o.id||s.name===o.name))
      ]

      console.log("objectives:",reduxGroupObjectives,reduxIncidentObjectives,newSuggestions,finalnewSuggestions)
      setSuggestions(finalnewSuggestions)
      setInUse(newInUse)
    }
    else if(!!planAISuggestions && mei_type==="actions")
    {
      const meiSuggestions = planAISuggestions[mei_type]||[]

      let newInUse = reduxIncidentActions.map(a=>{
        return {
          ...a,
          id: a.action_id,
          name: a.name || a.description
        }
      })

      let newSuggestions = meiSuggestions.map(s=>{
        return s.actions.map(a=>{
          return {
            ...a,
            id: a.action_id || a.id || generateUUID(),
            description: a.description || a.name,
            name: a.name || a.description,
            suggestion: true,
            action_id: a.action_id,
            objective_id: s.id,
            objective_name: s.name || s.description
          }
        })
      }).flat()

      // Add existing group actions, but filter out those that have already been suggested
      let finalnewSuggestions = [
        ...newSuggestions,
        ...reduxActions
          .filter(a=>!newSuggestions.find(s=>s.action_id===a.action_id||s.name===a.name))
          .map(a=>{{return{...a,name:a.description,id:a.action_id}}})
      ]

      console.log("actions:",reduxActions,reduxIncidentActions,newSuggestions,finalnewSuggestions)
      setSuggestions(finalnewSuggestions)
      setInUse(newInUse)
    }
    else if(!!planAISuggestions && mei_type==="resources")
    {
      const meiSuggestions = planAISuggestions[mei_type]||[]


      let newInUse = incidentResources.map(o=>{
        return {
          ...o,
          id: o.resource_id || o.id,
          description: o.type
        }
      })

      let newSuggestions = meiSuggestions.map(s=>{
        return s.resources.map(r=>{
          return {
            ...r,
            id: r.resource_id || r.id || generateUUID(),
            description: r.description || r.name,
            name: r.name || r.description,
            suggestion: true,
            resource_id: r.resource_id,
            action_id: s.id,
            action_name: s.name || s.description
          }
        })
      }).flat()

      // Add existing group resources, but filter out those that have already been suggested
      let finalnewSuggestions = [
        ...newSuggestions,
        ...groupResources
          .filter(o=>!newSuggestions.find(s=>s.resource_id===o.id||s.name===o.name))
          .map(r=>{{return{...r,name:r.description}}})
      ]

      //console.log("resources:",groupResources,incidentResources,newSuggestions,finalnewSuggestions,newInUse)
      setSuggestions(finalnewSuggestions)
      setInUse(newInUse)
    }
  },[
    mei_type,
    planAISuggestions,
    reduxGroupObjectives,
    reduxIncidentObjectives,
    reduxActions,
    incidentResources,
    groupResources
  ])

  function saveClicked()
  {
    let suggestionsToAdd = inUse.filter(s=>!!s.suggestion)
    if(mei_type==="objectives")
    {
      suggestionsToAdd = suggestionsToAdd.filter(s=>!s.objective_id)
      reduxDispatch(bulkAddObjectives({
        newObjectives:suggestionsToAdd,
        allIncidentObjectives: inUse
      }))
    } 
    else if(mei_type==="actions")
    {
      suggestionsToAdd = suggestionsToAdd.filter(s=>!s.action_id)
      let newActions = suggestionsToAdd.map(s=>{
        return {
          ...s,
          incident_id:reduxCurrentIncident.id,
          group_guid:reduxCurrentlySelectedGroup.group_guid,
          selectedObjectives: [s.objective_id]
        }
      })
      let allIncidentActions = inUse.map(s=>s.id)
      console.log("bulkAddActions: ",{
        newActions:newActions,
        allIncidentActions: allIncidentActions
      })
      reduxDispatch(bulkAddActions({
        newActions:newActions,
        allIncidentActions: allIncidentActions
      }))
    }
    else if(mei_type==="resources")
    {
      suggestionsToAdd = suggestionsToAdd.filter(s=>!s.resource_id)
      reduxDispatch(bulkAddResources({
        newResources:suggestionsToAdd,
        allIncidentResources: inUse.filter(r=>!incidentResources.find(ir=>ir.id===r.id)).map(r=>r.id)
      }))
    }
    // updateSuggestionsAfterSave({suggestions:[],mei_type:mei_type})
  }

  function planAIReadyForMEI(mei_type)
  {
    if(mei_type==="actions" && !reduxIncidentObjectives.length)
    {
      return false
    }
    else if(mei_type==="resources" && !reduxActions.length)
    {
      return false
    }
    return true
  }

  function planAIWarning(mei_type)
  {
    if(!planAIReadyForMEI(mei_type))
    {
      if(mei_type==="actions")
      {
        return "Objectives required to generate Actions"
      }
      if(mei_type==="resources")
      {
        return "Actions required to generate Resources"
      }

    }
  }

  function setupColumns(columns,mei_type)
  {
    if(mei_type==="objectives")
    {
      return columns
    }
    if(mei_type==='actions')
    {
      return [
        columns[0],
        {
          dataField: 'objective_name',
          text: 'Objective',
          sort: true,
          attrs: { title: 'Objective' },
        },
        columns[1],
      ]
    }
    if(mei_type==='resources')
    {
      return [
        columns[0],
        {
          dataField: 'action_name',
          text: 'Action',
          sort: true,
          attrs: { title: 'Action' },
        },
        columns[1],
      ]
    }
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: false,
    selected: inUse.map((e) => e.id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      if(inUse.find(r=>r.id===row.id))
      {
        setInUse(inUse.filter(r=>r.id!==row.id))
      }
      else
      {
        setInUse([...inUse,row])
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        setInUse(rows)
      } else {
        setInUse([])
      }
    },
  };

  return (
    <div className="MEISelection">
      <div className="PlanAI-buttons">
        {(!!isInitPlanAISuggestionsLoaded && !!isFetchPlanAISuggestionsLoaded) && (
          <StylishNewButton
            onClick={()=>{planAIClicked(mei_type)}}
            disabled={!planAIReadyForMEI(mei_type)}
          >
            PlanAI: Generate {mei_type}
          </StylishNewButton>
        ) || (
          <div>Loading {mei_type}...<i className="fa fa-spinner fa-pulse"></i></div>
        )}
        <div>
          <span className="PlanAI-warning">{planAIWarning(mei_type)}</span>
        </div>

      </div>
      <StylishNewButton 
        className="button--primary"
        onClick={()=>saveClicked()}
      >
        Save
      </StylishNewButton>
      {(!isBulkAddObjectivesLoaded || !isBulkAddActionsLoaded || !isBulkAddResourcesLoaded) && (
        <div>
          <span>Saving<i className="fa fa-spinner fa-pulse"></i></span>
        </div>
      )}
      <div>
      </div>
      <div className="MEISelection-tables-wrap">
        <div className="MEISelection-table MEISelection-tables-suggestion">
          <div className="MEISelection-table-inner">
            <span>Select {mei_type} to add to Incident</span>
            <StylishNewTable
              keyField={'id'}
              rows={suggestions}
              columns={setupColumns(meiSuggestionColumns,mei_type)}
              selectRow={selectRow}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MEISelection;
