import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useForm, Controller } from 'react-hook-form';
import { startLoading, endLoading } from 'reducers/loading/loading.action';
import { inviteGroupMember } from 'actions/profileActions';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

export const AddMemberDialog = ({ show, onClose, onBulkImportClick }) => {
  const reduxDispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: 'onChange' });

  const [isLoaded, setIsLoaded] = useState(true);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const inviteGroupMemberSuccess = useSelector((state) => {
    return state.app.inviteGroupMemberSuccess;
  });

  const inviteGroupMemberError = useSelector((state) => {
    return state.app.inviteGroupMemberError;
  });

  useEffect(() => {
    if (!isLoaded && inviteGroupMemberSuccess) {
      reduxDispatch(endLoading());
      onClose();
    }
  }, [inviteGroupMemberSuccess, onClose]);

  useEffect(() => {
    if (!isLoaded && !!inviteGroupMemberError) {
      reduxDispatch(endLoading());
      toast.error(inviteGroupMemberError, toastConfig);
    }
  }, [inviteGroupMemberError, isLoaded]);

  const onAddMemberSubmit = ({ memberName, memberEmail }) => {
    reduxDispatch(startLoading());
    reduxDispatch(
      inviteGroupMember({
        groupGuid: reduxCurrentlySelectedGroup.group_guid,
        email: memberEmail,
        name: memberName,
      })
    );
    setIsLoaded(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <form onSubmit={handleSubmit(onAddMemberSubmit)}>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>Add Member</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Member Name</label>
              <Controller
                control={control}
                name="memberName"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                  />
                )}
              />
              {errors.memberName && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
            <div className="mb-0">
              <label className="form-label">Email Address</label>
              <Controller
                control={control}
                name="memberEmail"
                rules={{
                  required: true,
                  pattern: {
                    value: /^([a-zA-Z0-9_\-\.\+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-z]+)$/,
                    message: 'Invalid email address',
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="email"
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                  />
                )}
              />
              {errors?.memberEmail?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.memberEmail?.type === 'pattern' && (
                <span className="form-text form-error">
                  Please Enter a Valid Email
                </span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton type="submit" className="button--primary">
                Add Member
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="button"
                onClick={onBulkImportClick}
              >
                Bulk Import
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
