import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import { selectUserGuid } from '../../../slices/commonSelectors';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../assets/data/config';
import { useActualIncidentId } from './commonHooks';

export type IncidentResourceRequirement = {
  id: string;
  incident_resource_id: string;
  incident_id: string;
  user_guid: string;
  version: number;
  kind: string;
  type: string;
  unit?: string;
  description?: string;
  required_quantity: number;
  timestamp: Date;
  archived: boolean;
  name?: string;
  arrived?: boolean;
};

export const keys = {
  base: 'incident_resourceRequirements',
  get all() { return [keys.base] },
  list: (incidentId: string, userId: string) => [keys.base, incidentId, userId],
} as const;

export type CreateIncidentResourceRequirementReqData = {
  incident_id: string;
  name?: string;
  description?: string;
  kind: string;
  type: string;
  unit?: string;
  required_quantity: number;
  archived?: boolean;
  arrived?: boolean;
};

export const useIncidentResourceRequirements = () => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.list(incidentId, user_guid),
    queryFn: async () => {
      const resp = await axios.get<IncidentResourceRequirement[]>(
        `${config.apiGateway.ics_incident_resources}/${incidentId}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
  });
};

export const useCreateIncidentResourceRequirement = () => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data: CreateIncidentResourceRequirementReqData) => {
      return await axios.post(
        `${config.apiGateway.ics_incident_resources}/${incidentId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(incidentId, user_guid) });
    },
    onError: (error: any) => {
      toast.error(error.message, toastConfig as any);
    },
  });
  return mutation;
};

interface EditIncidentResourceRequirementReqData {
  id: string;
  name?: string;
  description?: string;
  kind: string;
  type: string;
  unit?: string;
  required_quantity: number;
  archived?: boolean;
  arrived?: boolean;
}

export const useEditIncidentResourceRequirement = (resourceRequirementId: string) => {
  const user_guid = useSelector(selectUserGuid);
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: EditIncidentResourceRequirementReqData) => {
      return await axios.put(
        `${config.apiGateway.ics_incident_resources}/${incidentId}/${resourceRequirementId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(incidentId, user_guid) });
    },
    onError: (error: any) => {
      toast.error(error.message, toastConfig as any);
    },
  });
};
