import config from 'constants/apiConfig';
import axios from 'axios';

import {
  ADD_TASK_COMMENT_REQUEST,
  ADD_TASK_COMMENT_SUCCESS,
  FETCH_TASK_LIST_ERROR,
  FETCH_TASK_LIST_REQUEST,
  FETCH_TASK_LIST_SUCCESS,
  GET_ALL_TASK_COMMENT,
  GET_ALL_TASK_COMMENT_ERROR,
  GET_ALL_TASK_COMMENT_REQUEST,
  GET_ALL_TASK_LOGS,
  GET_ALL_TASK_LOGS_ERROR,
  GET_ALL_TASK_LOGS_REQUEST,
  GET_ALL_TASK_UNIT,
  SAVE_TASK_DATA_ERROR,
  SAVE_TASK_DATA_REQUEST,
  SAVE_TASK_DATA_SUCCESS,
} from 'constants/task-management-types';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';

function fetchTaskListDataRequest() {
  return {
    type: FETCH_TASK_LIST_REQUEST,
  };
}

function fetchTaskListDataSuccess(data) {
  return {
    type: FETCH_TASK_LIST_SUCCESS,
    payload: data,
  };
}

function fetchTaskListDataError(error) {
  let label = error.message;
  if (!!error && !!error.response && !!error.response.status) {
    label = 'Invalid API Credentials';
  }
  console.error('Fetch Task List Data API error: ' + label);
  toast.error('Fetch Task List Data API error: ' + label);
  return {
    type: FETCH_TASK_LIST_ERROR,
  };
}

function fetchTaskCommentError(error) {
  let label = error.message;
  if (!!error && !!error.response && !!error.response.status) {
    label = 'Invalid API Credentials';
  }
  console.error('Fetch Task Comment error: ' + label);
  toast.error('Fetch Task Comment error: ' + label);
  return {
    type: GET_ALL_TASK_COMMENT_ERROR,
  };
}

function fetchTaskLogError(error) {
  let label = error.message;
  if (!!error && !!error.response && !!error.response.status) {
    label = 'Invalid API Credentials';
  }
  console.error('Fetch Task Log error: ' + label);
  toast.error('Fetch Task Log error: ' + label);
  return {
    type: GET_ALL_TASK_LOGS_ERROR,
  };
}

export function fetchTaskListDataAction(
  group_guid,
  filter,
  page,
  searchTerm,
  startDate = null,
  endDate = null
) {
  return (dispatch) => {
    dispatch(fetchTaskListDataRequest());
    dispatch(startLoading());
    return axios(config.apiGateway.fetchTaskList, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
        filter,
        page,
        search: searchTerm,
        startDate,
        endDate,
      }),
    })
      .then((response) => {
        dispatch(endLoading());
        const data = response?.data || [];
        dispatch(fetchTaskListDataSuccess(data));
      })
      .catch((error) => {
        dispatch(endLoading());
        dispatch(fetchTaskListDataError(error));
      });
  };
}

function saveTaskRequest() {
  return {
    type: SAVE_TASK_DATA_REQUEST,
  };
}

function saveTaskSuccess(data) {
  return {
    type: SAVE_TASK_DATA_SUCCESS,
    payload: data,
  };
}

function saveTaskError(error) {
  let label = error.message;
  if (
    !!error &&
    !!error.response &&
    error.response.data.ERROR ===
      'BAD REQUEST exception error: Task ID already exists'
  ) {
    label = 'Task ID already exists';
  } else if (!!error && !!error.response && !!error.response.status) {
    label = 'Invalid API Credentials';
  }
  console.error('saveTask API error: ' + label);
  toast.error('saveTask API error: ' + label);
  return {
    type: SAVE_TASK_DATA_ERROR,
  };
}

function addTaskCommentError(error) {
  let label = error.message;
  if (!!error && !!error.response && !!error.response.status) {
    label = 'Invalid API Credentials';
  }
  console.error('Add Task Comment API error: ' + label);
  toast.error('Add Task Comment API error: ' + label);
  return {
    type: SAVE_TASK_DATA_ERROR,
  };
}

export function saveTask(data) {
  return (dispatch) => {
    dispatch(saveTaskRequest());

    return axios(config.apiGateway.saveTask, {
      method: 'POST',
      data: JSON.stringify(data),
    })
      .then((response) => {
        const data = response?.data || null;
        dispatch(saveTaskSuccess(data));
      })
      .catch((error) => {
        dispatch(saveTaskError(error));
      });
  };
}

export function upsertTaskComment(data) {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_TASK_COMMENT_REQUEST });
    return await axios(config.apiGateway.addTaskComment, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        comment: data,
      }),
    })
      .then(() => {
        dispatch({ type: ADD_TASK_COMMENT_SUCCESS });
        dispatch(getAllCommentForTask(data.task_id));
      })
      .catch((error) => {
        dispatch(addTaskCommentError(error));
      });
  };
}

export function getAllCommentForTask(task_id) {
  return (dispatch, getState) => {
    dispatch({ type: GET_ALL_TASK_COMMENT_REQUEST });
    return axios(config.apiGateway.getAllTaskComments, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        task_id,
      }),
    })
      .then((response) => {
        const data = response?.data || null;
        dispatch({ type: GET_ALL_TASK_COMMENT, payload: data });
      })
      .catch((error) => {
        dispatch(fetchTaskCommentError(error));
      });
  };
}

export function getAllLogsForTask(task_id) {
  return (dispatch, getState) => {
    dispatch({ type: GET_ALL_TASK_LOGS_REQUEST });
    return axios(config.apiGateway.getAllTaskLogs, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        task_id,
      }),
    })
      .then((response) => {
        const data = response?.data || null;
        dispatch({ type: GET_ALL_TASK_LOGS, payload: data });
      })
      .catch((error) => {
        dispatch(fetchTaskLogError(error));
      });
  };
}

export function upsertTaskUnit(unit) {
  return (dispatch, getState) => {
    return axios(config.apiGateway.upsertTaskUnit, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        unit,
      }),
    })
      .then(() => {
        dispatch(getAllTaskUnits());
      })
      .catch((error) => {
        dispatch(fetchTaskLogError(error));
      });
  };
}

export function getAllTaskUnits() {
  return (dispatch, getState) => {
    return axios(config.apiGateway.getAllTaskUnits, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        const data = response?.data || [];
        dispatch({ type: GET_ALL_TASK_UNIT, payload: data });
      })
      .catch((error) => {
        dispatch(fetchTaskLogError(error));
      });
  };
}

export function deleteUnit(unit_id) {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteTaskUnit, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        unit_id,
      }),
    })
      .then(() => {
        dispatch(getAllTaskUnits());
      })
      .catch((error) => {
        dispatch(fetchTaskLogError(error));
      });
  };
}
