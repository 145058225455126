// Returns false if more than one Group Admin in group (meaning that a Group Member can be removed from Group or Group Admin role)
// Otherwise true (only one Group Admin, therefore they cannot be removed from Group or Group Admin role)
export default function isUserOnlyAdminInGroup(group_guid,rostersForGroups)
{
	if(!rostersForGroups || !rostersForGroups.length) return true

	const adminsInGroup = rostersForGroups
		.filter(r=>r.group_guid===group_guid)
		.filter(r=>r.role_assignments
			.find(ra=>ra.name==="Group Admin"))
	return adminsInGroup.length < 2
}