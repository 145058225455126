import {useEffect, useState} from 'react';

export default function useFileWithWarning() {
  const [file, setFile] = useState();
  //const [warningMessage, setWarningMessage] = useState();

  const acceptableTypes = [
    "application/geo+json",
    "application/json",
    "text/csv",
    "application/vnd.ms-excel",
  ];

  const setFileInQueue = (file) => setFile(file);

  
  useEffect(() => {
    // After some testing, this filter is not playing well with all browsers.
    // We need to revisit this as soon as we're able.
    const tempfalse = false
    if(tempfalse)
    {
      /*
      if (file && !acceptableTypes.includes(file.type)) {
        setWarningMessage("This file is not supported. Please consult the user guide for more information.")
      }

      if(file && file.size > 500000000) {
        setWarningMessage("This file is too large. Please consult the user guide for more information.")
      }
      */
    }
  }, [file, acceptableTypes])
  

  return [setFileInQueue]
}