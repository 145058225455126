export const allRecipientTypesOptions = [
  'Email',
  'SMS',
  // 'Pager',
  'Text-To-Speech (cell)',
  'Text-To-Speech (home)',
];

export const allRecipientTypes = [
  {label: 'Email', icon: 'email'},
  {label: 'SMS', icon: 'sms'},
  // {label: 'Pager', icon: 'pager'},
  {label: 'Text-To-Speech (cell)', icon: 'phone'},
  {label: 'Text-To-Speech (home)', icon: 'home'},
] as const;
