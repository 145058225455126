import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import {
  selectGroupGuid,
  selectUserGuid,
} from '../../../slices/commonSelectors';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../assets/data/config';

export type Action = {
  id: UUID;
  group_guid: UUID;
  name: string;
  description: string;
  checklist: { label: string; status: string }[];
  timestamp: Date;
  archived: boolean;
};

export const keys = {
  base: 'actions',
  get all() {
    return [keys.base];
  },
  list: (group_guid: UUID, user_guid: UUID) => [
    keys.base,
    group_guid,
    user_guid,
  ],
} as const;

export const useActions = () => {
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.list(group_guid, user_guid),
    queryFn: async () => {
      const resp = await axios.get<Action[]>(
        `${config.apiGateway.ics_actions}/${group_guid}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
  });
};

export type CreateActionReqData = {
  name: string;
  description: string;
  checklist: { label: string; status: string }[];

  incident_id?: UUID;

  // group_guid and user_guid are inferred from context
};

export const useCreateAction = () => {
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: CreateActionReqData) => {
      return await axios.post<CreateActionReqData, Action>(
        `${config.apiGateway.ics_actions}`,
        {
          ...data,
          group_guid,
          user_guid,
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keys.list(group_guid, user_guid),
      });
    },
    onError: (error) => {
      toast.error(error.message, toastConfig);
    },
  });
};
