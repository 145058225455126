import config from 'constants/apiConfig';
import axios from 'axios';

import {
  FETCH_SIGNATURES_REQUEST,
  FETCH_SIGNATURES_SUCCESS,
  FETCH_SIGNATURES_ERROR,
  UPSERT_SIGNATURE_REQUEST,
  UPSERT_SIGNATURE_SUCCESS,
  UPSERT_SIGNATURE_ERROR,
  DELETE_SIGNATURE_REQUEST,
  DELETE_SIGNATURE_SUCCESS,
  DELETE_SIGNATURE_ERROR,
  FETCH_PREPARED_BY_SIGNATURES_SUCCESS,
  FETCH_REVIEWED_BY_SIGNATURES_SUCCESS,
} from 'constants/signature-action-types';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

function fetchSignaturesRequest() {
  return {
    type: FETCH_SIGNATURES_REQUEST,
  };
}

function fetchSignaturesSuccess(signatures) {
  return {
    type: FETCH_SIGNATURES_SUCCESS,
    payload: signatures,
  };
}

function fetchPreparedBySignaturesSuccess(signatures) {
  return {
    type: FETCH_PREPARED_BY_SIGNATURES_SUCCESS,
    payload: signatures,
  };
}

function fetchReviewedBySignaturesSuccess(signatures) {
  return {
    type: FETCH_REVIEWED_BY_SIGNATURES_SUCCESS,
    payload: signatures,
  };
}

function fetchSignaturesError(error) {
  const errorMessage =
    'fetchSignaturesError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_SIGNATURES_ERROR,
  };
}

export const fetchSignatures = (user_guid = null) => {
  return (dispatch) => {
    dispatch(fetchSignaturesRequest());

    return axios(config.apiGateway.fetchSignatures, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
      }),
    })
      .then((response) => {
        const signatures = response.data || {};
        dispatch(fetchSignaturesSuccess(signatures));

        return signatures;
      })
      .catch((error) => {
        dispatch(fetchSignaturesError(error));
      });
  };
};

export const fetchSignaturesPreparedBy = (user_guid = null) => {
  return (dispatch) => {
    dispatch(fetchSignaturesRequest());

    return axios(config.apiGateway.fetchSignatures, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
      }),
    })
      .then((response) => {
        const signatures = response.data || {};
        dispatch(fetchPreparedBySignaturesSuccess(signatures));

        return signatures;
      })
      .catch((error) => {
        dispatch(fetchSignaturesError(error));
      });
  };
};

export const fetchSignaturesReviewedBy = (user_guid = null) => {
  return (dispatch) => {
    dispatch(fetchSignaturesRequest());

    return axios(config.apiGateway.fetchSignatures, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
      }),
    })
      .then((response) => {
        const signatures = response.data || {};
        dispatch(fetchReviewedBySignaturesSuccess(signatures));

        return signatures;
      })
      .catch((error) => {
        dispatch(fetchSignaturesError(error));
      });
  };
};

function upsertSignatureRequest() {
  return {
    type: UPSERT_SIGNATURE_REQUEST,
  };
}

function upsertSignatureSuccess(signature) {
  return {
    type: UPSERT_SIGNATURE_SUCCESS,
    payload: signature,
  };
}

function upsertSignatureError(error) {
  const errorMessage =
    'upsertSignatureError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPSERT_SIGNATURE_ERROR,
  };
}

export const upsertSignature = (signature) => {
  return (dispatch) => {
    dispatch(upsertSignatureRequest());

    return axios(config.apiGateway.upsertSignature, {
      method: 'POST',
      data: JSON.stringify({
        signature,
      }),
    })
      .then(() => {
        dispatch(fetchSignatures());
        dispatch(upsertSignatureSuccess(signature));
        // toast.success('Upsert Signature successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(upsertSignatureError(error));
      });
  };
};

function deleteSignatureRequest() {
  return {
    type: DELETE_SIGNATURE_REQUEST,
  };
}

function deleteSignatureSuccess(signature) {
  return {
    type: DELETE_SIGNATURE_SUCCESS,
    payload: signature,
  };
}

function deleteSignatureError(error) {
  const errorMessage =
    'deleteSignatureError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: DELETE_SIGNATURE_ERROR,
  };
}

export const deleteSignature = (signature) => {
  return (dispatch) => {
    dispatch(deleteSignatureRequest());

    return axios(config.apiGateway.deleteSignature, {
      method: 'POST',
      data: JSON.stringify({
        signature,
      }),
    })
      .then((response) => {
        const deletedSignature = response.data || {};
        dispatch(deleteSignatureSuccess(deletedSignature));
        dispatch(fetchSignatures(signature.incident_id, signature.group_guid));
        // toast.success('Delete Signature successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(deleteSignatureError(error));
      });
  };
};
