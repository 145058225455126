export const powerOutageScenarios = [
  {
    value: 'relative',
    label: 'Relative',
    color: '#f0f9e8',
    scenario: [
      {
        limit: 0,
        value: 0,
      },
      {
        limit: 5,
        value: 1,
      },
      {
        limit: 10,
        value: 2,
      },
      {
        limit: 15,
        value: 3,
      },
      {
        limit: 25,
        value: 4,
      },
      {
        limit: 35,
        value: 5,
      },
    ],
  },
  {
    value: 'absolute',
    label: 'Absolute',
    color: '#0868ac',
    scenario: [
      {
        limit: 0,
        value: 0,
      },
      {
        limit: 50,
        value: 1,
      },
      {
        limit: 500,
        value: 2,
      },
      {
        limit: 2000,
        value: 3,
      },
      {
        limit: 5000,
        value: 4,
      },
      {
        limit: 10000,
        value: 5,
      },
    ],
  },
];

export const powerOutageTableRows = [
  {
    poweroutage_level: 0,
    percent_pop: '0.1% to 5%',
    count_pop: 'Less than 50',
  },
  {
    poweroutage_level: 1,
    percent_pop: '5% to 9%',
    count_pop: '51 to 500',
  },
  {
    poweroutage_level: 2,
    percent_pop: '10% to 15%',
    count_pop: '501 to 2,000',
  },
  {
    poweroutage_level: 3,
    percent_pop: '16% to 25%',
    count_pop: '2,001 to 5,000',
  },
  {
    poweroutage_level: 4,
    percent_pop: '26% to 35%',
    count_pop: '5,001 to 10,000',
  },
  {
    poweroutage_level: 5,
    percent_pop: '35%',
    count_pop: '10,001+',
  },
];

const powerOutageTableColsStyle = (row) => {
  return {
    padding: '.75rem 1rem',
    fontWeight: '600',
    background:
      row?.poweroutage_level === 1
        ? '#fff7bb'
        : row?.poweroutage_level === 2
        ? '#f5c36b'
        : row?.poweroutage_level === 3
        ? '#eb8644'
        : row?.poweroutage_level === 4
        ? '#d8311a'
        : row?.poweroutage_level === 5
        ? '#a80021'
        : '#57fb31',
    color:
      row?.poweroutage_level === 4 || row?.poweroutage_level === 5
        ? '#ffffff'
        : '#000000',
  };
};

export const powerOutageTableCols = [
  {
    dataField: 'poweroutage_level',
    text: 'Power Outage Level',
    sort: true,
    headerAttrs: { title: 'Sort By:' },
    attrs: { title: 'Power Outage Level' },
    style: (_, row) => {
      return powerOutageTableColsStyle(row);
    },
    headerAlign: 'center',
    align: 'center',
  },
  {
    dataField: 'percent_pop',
    text: '(Relative) Percentage Without Power',
    sort: true,
    attrs: { title: '(Relative) Percentage Without Power' },
    style: (_, row) => {
      return powerOutageTableColsStyle(row);
    },
    headerAlign: 'center',
    align: 'center',
  },
  {
    dataField: 'count_pop',
    text: '(Absolute) Customer Without Power',
    sort: true,
    attrs: { title: '(Absolute) Customer Without Power' },
    style: (_, row) => {
      return powerOutageTableColsStyle(row);
    },
    headerAlign: 'center',
    align: 'center',
  },
];

export const eriValCols = [
  {
    value: 0,
    color: '#FFFFFF',
  },
  {
    value: 1,
    color: '#ffffcc',
  },
  {
    value: 2,
    color: '#a1dab4',
  },
  {
    value: 3,
    color: '#41b6c4',
  },
  {
    value: 4,
    color: '#2c7fb8',
  },
  {
    value: 5,
    color: '#253494',
  },
];
