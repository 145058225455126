
export default function getCurrentUserIncidentSeat(DICEstate)
{
  const { rosterForIncident } = DICEstate.app
  const { user_guid: userGuid } = DICEstate.app.user

  const rosterEntryForUser = (rosterForIncident && rosterForIncident.find(item => {
    return item.user_guid === userGuid 
  })) || {}

	return rosterEntryForUser.incident_seat
}