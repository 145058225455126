import { Card } from 'react-bootstrap';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { useDispatch } from 'react-redux';
import { useAppSelector, selectIsUploadFileToTeamsLoaded } from '../../slices/commonSelectors';
import { exportAsForm } from '../../actions/formActions';
import { shareFileWithTeams } from 'actions/teamsActions';

type FormExportCardProps = {
  formName: string;
  label: string;
  canShareWithTeams: boolean;
};

const FormExportCard: React.FC<FormExportCardProps> = ({ formName, label, canShareWithTeams = false }) => {
  const dispatch = useDispatch();
  const isUploadFileToTeamsLoaded = useAppSelector(selectIsUploadFileToTeamsLoaded)

  return (
    <Card className="bg-bgdark m-3">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div>{label}</div>
          {canShareWithTeams && (
              <StylishNewButton
                className="button--secondary"
                onClick={() => dispatch(shareFileWithTeams({ formName: formName }))}
                disabled={!isUploadFileToTeamsLoaded}
              >
                Share
              </StylishNewButton>
          )}
          <StylishNewButton
            className="button--secondary"
            onClick={() => dispatch(exportAsForm({ formname: formName }))}
          >
            Export
          </StylishNewButton>
        </div>
      </Card.Body>
    </Card>
  );
};

export default FormExportCard;
