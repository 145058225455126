import React from 'react';
import moment from 'moment';
import { RTO_TIME_FRAME_OPTIONS } from './TaskManagementConstant';
import { getProcessStability } from './TaskManagementUtil';

export default function TaskManagementMapDetail(props) {
  const {
    task
  } = props;

  const rtoTimeFrameObj = RTO_TIME_FRAME_OPTIONS.find(frame => frame.value === task?.rto_time_frame);
  const stability = getProcessStability(
    task?.status, 
    task?.deadline_date, 
    task?.restoration_status, 
    parseInt(task?.rto),
    rtoTimeFrameObj,
    task?.is_critical
  );
  const stabilityStatus = stability?.label;

  return (
    <div className="task-detail-container">
      <h5 className="mb-1">{task?.title}</h5>
      {!task?.geolocation && (
        <div className="mb-3">
          <span className="d-block fst-italic">
            This Task has no geolocation.
          </span>
        </div>
      )}
      <hr className="dashed m-0" />
      <div className="EventDetails-Specifics">
        <div className="mt-2">
          <span className="weight-600 d-block">Task Assessment Status: </span>
          {task?.status}
        </div>
        <div className="mt-2">
          <span className="weight-600 d-block">Task Stability: </span>
          {stabilityStatus}
        </div>
        <div className="mt-2">
          <span className="weight-600 d-block">Task Description: </span>
          {task?.content}
        </div>
        {(!!task?.deadline_date || !!task?.created_at) && (
          <div className="mt-2">
            <div className="row">
              {!!task?.deadline_date && (
                <div className="col-6">
                  <span className="weight-600 d-block">Due Date: </span>
                  {moment(task?.deadline_date).format('MMM D, YYYY')}
                </div>
              )}
              {!!task?.created_at && (
                <div className="col-6">
                  <span className="weight-600 d-block">Created Date: </span>
                  {moment(task?.created_at).format('MMM D, YYYY')}
                </div>
              )}
            </div>
            
          </div>
        )}
        {!!task?.unit && (
          <div className="mt-2">
            <span className="weight-600 d-block">Unit / Squad: </span>
            {task?.unit}
          </div>
        )}
        {!!task?.business_leadership_team_tag && (
          <div className="mt-2">
            <span className="weight-600 d-block">BLT (Business Leadership Team) Tag: </span>
            {task?.business_leadership_team_tag}
          </div>
        )}

        {task?.process_owner?.length > 0 && task.process_owner.map((e, index) => {
          const prefix = index === 0 ? '' : 'Alternate';
          return (
            <>
              <div className="mt-2">
                <span className="weight-600 d-block">
                  {prefix} Process Owner
                </span>
                {e.user_name || e.email_address}
              </div>
              <div className="mt-2">
                <span className="weight-600 d-block">
                  {prefix} Process Owner Position
                </span>
                {e?.process_owner_position}
              </div>
              <div className="mt-2">
                <span className="weight-600 d-block">
                  {prefix} Process Owner Business
                </span>
                {e?.process_owner_business}
              </div>
            </>
          )
        })}

        {(!!task?.rto || !!task?.rto_time_frame) && (
          <div className="mt-2">
            <span className="weight-600 d-block">RTO: </span>
            {task?.rto} ({task?.rto_time_frame})
          </div>
        )}
        {!!task?.documents_needed && (
          <div className="mt-2">
            <span className="weight-600 d-block">Documents Needed: </span>
            {task?.documents_needed}
          </div>
        )}
        {!!task?.system_apps && (
          <div className="mt-2">
            <span className="weight-600 d-block">BP Systems / Apps: </span>
            {task?.system_apps}
          </div>
        )}
      </div>
    </div>
  );
}
