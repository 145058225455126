import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import {
  selectGroupGuid,
  selectUserGuid,
} from '../../../slices/commonSelectors';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../assets/data/config';

export type ResourceSite = {
  id: UUID;
  group_guid: UUID;
  name: string;
  description: string;
  geolocation: any;
  archived: boolean;
  type:
    | 'Staging Area'
    | 'Incident Command Post'
    | 'Warehouse'
    | 'Hotel/Lodging';
};

export const keys = {
  base: 'resource_sites',
  get all() {
    return [keys.base];
  },
  list: (group_guid: UUID, user_guid: UUID) => [
    keys.base,
    group_guid,
    user_guid,
  ],
} as const;

export const useResourceSites = () => {
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.list(group_guid, user_guid),
    queryFn: async () => {
      const resp = await axios.get<ResourceSite[]>(
        `${config.apiGateway.ics_resource_sites}/${group_guid}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
  });
};

export type CreateResourceSiteReqData = {
  name: string;
  description: string;
  geolocation: any;
  archived: boolean;
  // group_guid and user_guid are inferred from context
};

export const useCreateResourceSite = () => {
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: CreateResourceSiteReqData) => {
      return await axios.post<CreateResourceSiteReqData, ResourceSite>(
        `${config.apiGateway.ics_resource_sites}`,
        {
          ...data,
          group_guid,
          user_guid,
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keys.list(group_guid, user_guid),
      });
    },
    onError: (error) => {
      toast.error(error.message, toastConfig);
    },
  });
};

type EditResourceSiteReqData = {
  id?: UUID;
  group_guid?: UUID;
  name: string;
  description: string;
  user_guid?: UUID;
  geolocation: any;
  archived: boolean;
};

export const useUpdateResourceSite = (resourceSiteId: UUID) => {
  const user_guid = useSelector(selectUserGuid);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: EditResourceSiteReqData) => {
      return await axios.put<EditResourceSiteReqData, ResourceSite>(
        `${config.apiGateway.ics_resource_sites}/${resourceSiteId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.all });
    },
    onError: (error) => {
      toast.error(error.message, toastConfig);
    },
  });
};
