import { Modal } from 'react-bootstrap';
import GraphicsUserAuthorizationFailed from 'assets/images/graphics__user-authorization-failed.svg';

export default function TeamsAppErrorDialog({ show, error, clientId }) {

  const ErrorCodeInstructions = (error) => {
    if (error.includes('consented') 
      || error.includes('AADSTS500011')) 
    {
      return (
        <>
          <p>PRATUS requires that the following steps be completed.</p>
          <ol>
            <li>
              <p>This app to first be installed in your organization&apos;s app catalog:</p>
              <p>
                <a href="https://admin.teams.microsoft.com/policies/manage-apps">
                  App Catalog
                </a>
              </p>
            </li>
            <li>
              <p>An administrator would then need to grant the required permissions for all users by visiting the following URL:</p>
              <p>
                <a href={`https://login.microsoftonline.com/common/adminconsent?client_id=${clientId}&redirect_uri=${encodeURIComponent(window.location.origin)}`} target="_blank" rel="noopener noreferrer">
                  Admin Consent Link
                </a>
              </p>
            </li>
          </ol>
          <p>To continue after these steps are complete, please uninstall and re-add the app in your personal scope.</p>
        </>
      )
    } else {
      return (
        <>
          <p>We&apos;re sorry, Pratus is having trouble authenticating with Teams.</p>
          {error && (
            <p>{error}</p>
          )}
        </>
      )
    }
  }

  return (
    <>
      <Modal
        show={show}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="pb-5">
          <div className="d-flex flex-column align-items-center">
            <img src={GraphicsUserAuthorizationFailed} alt="" className="mb-5" />
            {ErrorCodeInstructions(error)}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}