import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  fetchAllRoleAssignmentsInGroup,
} from 'actions/roleActions';

export default function useGroupAdminForCurrentIncident() {

  const reduxDispatch = useDispatch();

  const [userIsGroupAdmin, setUserIsGroupAdmin] = useState(false);

  const {id: incident_guid, group_guid} = useSelector(state => {
    return state.app.currentIncident || {}
  })

  const reduxAllRoleAssignmentsInGroup = useSelector(state => {
      return state.app.allRoleAssignmentsInGroup;
  })

  const {email_address} = useSelector(state => {
    return state.app.user
  }) || {}

  useEffect(() => {
    if (group_guid) {
      reduxDispatch(fetchAllRoleAssignmentsInGroup(group_guid))
    }
  }, [incident_guid, group_guid])


  useEffect(() => {
    if (Array.isArray(reduxAllRoleAssignmentsInGroup) && !!reduxAllRoleAssignmentsInGroup.length) {
      const foundGroupAdmin = reduxAllRoleAssignmentsInGroup
        .filter(assignment => {
          return assignment.email_address === email_address
        })
        .find(assignment => {
          return assignment.name === "Group Admin"
        })

      setUserIsGroupAdmin(!!foundGroupAdmin)
    }
  }, [reduxAllRoleAssignmentsInGroup, email_address])

  return userIsGroupAdmin
}