import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useSelector } from 'react-redux';
import config from 'constants/apiConfig';
import { selectGroupGuid, selectUserGuid } from '../../../slices/commonSelectors';

// Custom Event keys
const keys = {
  base: 'customEvents',
  list: () => [keys.base],
  event: (id) => [keys.base, id],
};

// Fetch all non-archived custom events
export const useCustomEvents = (group_guid) => {
  return useQuery({
    queryKey: [keys.base, group_guid], // Pass group_guid as part of the queryKey to identify the query
    queryFn: async () => {
      // Fetch custom events based on the group_guid
      const { data } = await axios.get(`${config.apiGateway.custom_events}?group_guid=${group_guid}`);
      return data;
    },
    enabled: !!group_guid, // Only run the query if group_guid is defined
  });
};


// Create a new custom event
export const useCreateCustomEvent = () => {
  const queryClient = useQueryClient();
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);

  return useMutation({
    mutationFn: async (newEvent) => {
      const { data } = await axios.post(config.apiGateway.custom_events, {
        ...newEvent,
        group_guid,
        user_guid,
      });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list() });
    },
  });
};

// Edit an existing custom event
export const useEditCustomEvent = () => {
  const queryClient = useQueryClient();
  const user_guid = useSelector(selectUserGuid);

  return useMutation({
    mutationFn: async (updatedEvent) => {
      const { data } = await axios.put(`${config.apiGateway.custom_events}/${updatedEvent?.id}`, {
        ...updatedEvent,
        user_guid,
      });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list() });
    },
  });
};
