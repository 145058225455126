import {
  deleteNestedCategoryItem,
  fetchNestedCategories,
  fetchNestedCategoryItems,
  getAllAOR,
} from 'actions/locationAction';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import React, { useEffect, useState } from 'react';
import { Controller, FieldPath, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import LocationAddUpdateModal from './LocationAddUpdateModal';
import EditCategoriesModal from './EditCategoriesModal';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import Dropdown from 'react-bootstrap/Dropdown';
import './ManageLocation.css';
import { MdOutlineSubdirectoryArrowRight } from 'react-icons/md';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

type CategoryObj = {
  id: string | null;
  category_name: string;
  parent_category_id: string | null;
  group_guid: string;
  items: { category_item_name: string; id: string }[];
  children?: CategoryObj[];
};

const Label = (props: {
  label: string;
  onEdit: (
    location?: {
      category_item_name: string;
      id: string;
    },
    type?: string
  ) => void;
  onDelete: (id?: string, type?: string) => void;
}) => {
  return (
    <>
      {props.label}
      <div className="react-select__custom-label">
        <span
          className="ms-3"
          onClick={(e) => {
            e.stopPropagation();
            props.onEdit();
          }}
        >
          <SharedIcon iconName="stylus" />
        </span>
        <span
          className="ms-1"
          onClick={(e) => {
            e.stopPropagation();
            props.onDelete();
          }}
        >
          <SharedIcon iconName="delete" />
        </span>
      </div>
    </>
  );
};

const ManageLocation: React.FC = () => {
  const currentlySelectedGroup = useSelector((state: any) => {
    return state.app.currentlySelectedGroup;
  });
  const groupLocation = useSelector((state: any) => {
    return state.app.groupLocation;
  });

  const nestedCategoryItems = useSelector((state: any) => {
    return state.app.nestedCategoryItems;
  });

  const reduxNestedCategories = useSelector((state: any) => {
    return state.app.nestedCategories;
  });

  const [showLocationAddModal, setShowLocationAddModal] = useState(false);
  const [showLocationDeleteModal, setShowLocationDeleteModal] = useState(false);
  const [locationModalMode, setLocationModalMode] = useState('Add');
  const [updateLocationData, setUpdateLocationData] = useState<{
    category_item_name: string;
    id: string;
  } | null>(null);
  const [updateLocationType, setUpdateLocationType] = useState<string | null>(
    'Entity'
  );
  const [deleteLocationData, setDeleteLocationData] = useState<string | null>(
    null
  );

  const [nestedCategories, setNestedCategories] = useState<CategoryObj[]>([]);
  const [categoryHierarchy, setCategoryHierarchy] = useState<CategoryObj[]>([]);
  const [showEditCategoriesModal, setShowEditCategoriesModal] = useState(false);

  const getCategoryChildren: any = (
    category: CategoryObj,
    normalizedCategory: CategoryObj[],
    depth = 0
  ) => {
    return normalizedCategory
      .filter((norm) => norm.parent_category_id === category.id)
      .map((filtered: any) => {
        return {
          ...filtered,
          children: getCategoryChildren(
            filtered,
            normalizedCategory,
            depth + 1
          ),
        };
      });
  };
  // This code is duplicated in the "ManageTeamLocation" component
  // We should have one single mechanism for controlling the shape of the object.
  // Maybe a custom hook. I dunno.
  useEffect(() => {
    // once we have both the categories and their items, we'll combine them.

    let organizedCategoriesWithItems: CategoryObj[] = [];
    let itemsWithNoMatchingCategory =
      !!nestedCategoryItems &&
      Array.isArray(nestedCategoryItems) &&
      nestedCategoryItems.length
        ? [...nestedCategoryItems]
        : [];

    if (
      !!reduxNestedCategories &&
      Array.isArray(reduxNestedCategories) &&
      !!reduxNestedCategories.length
    ) {
      organizedCategoriesWithItems = reduxNestedCategories.map((category) => {
        let items = [];

        if (!!itemsWithNoMatchingCategory.length) {
          items = itemsWithNoMatchingCategory.filter(
            (item) => item.category_id === category.id
          );

          // now we update the list of items that we have not matched to a category
          itemsWithNoMatchingCategory = itemsWithNoMatchingCategory.filter(
            (item) => item.category_id !== category.id
          );
        }

        return { ...category, items };
      });
    }

    if (!!itemsWithNoMatchingCategory.length) {
      organizedCategoriesWithItems.push({
        id: null,
        category_name: 'No Category',
        parent_category_id: null,
        group_guid: currentlySelectedGroup.group_guid,
        items: [...itemsWithNoMatchingCategory],
      });
    }

    setNestedCategories(organizedCategoriesWithItems);

    const hierarchy = organizedCategoriesWithItems.reduce(
      (acc: CategoryObj[], item) => {
        if (!item.parent_category_id) {
          if (item.id) {
            return [
              ...acc,
              {
                ...item,
                children: getCategoryChildren(
                  item,
                  organizedCategoriesWithItems
                ),
              },
            ];
          } else {
            return [
              ...acc,
              {
                ...item,
                children: [],
              },
            ];
          }
        } else {
          return acc;
        }
      },
      []
    );

    setCategoryHierarchy(hierarchy);
  }, [reduxNestedCategories, nestedCategoryItems]);

  useEffect(() => {
    if (!!currentlySelectedGroup && !!currentlySelectedGroup.group_guid) {
      // here's where we would do our initial fetch of the nested categories
      reduxDispatch(fetchNestedCategories());
      reduxDispatch(fetchNestedCategoryItems());
      reduxDispatch(getAllAOR());
    }
  }, [currentlySelectedGroup]);

  useEffect(() => {
    if (!!groupLocation) {
      if (!!groupLocation.location_entity_id) {
        setValue('locationEntity', {
          value: groupLocation.location_entity_data.id,
          label: groupLocation.location_entity_data.location_entity,
        });
      } else {
        setValue('locationEntity', null);
      }
      if (!!groupLocation.location_sub_entity_id) {
        setValue('locationSubEntity', {
          value: groupLocation.location_sub_entity_data.id,
          label: groupLocation.location_sub_entity_data.location_sub_entity,
        });
      } else {
        setValue('locationSubEntity', null);
      }
      if (!!groupLocation.location_enterprise_id) {
        setValue('locationEnterprise', {
          value: groupLocation.location_enterprise_data.id,
          label: groupLocation.location_enterprise_data.location_enterprise,
        });
      } else {
        setValue('locationEnterprise', null);
      }
      if (!!groupLocation.location_asset_id) {
        setValue('locationAsset', {
          value: groupLocation.location_asset_data.id,
          label: groupLocation.location_asset_data.location_asset,
        });
      } else {
        setValue('locationAsset', null);
      }
    } else {
      setValue('locationEntity', null);
      setValue('locationSubEntity', null);
      setValue('locationEnterprise', null);
      setValue('locationAsset', null);
    }
  }, [groupLocation]);

  const reduxDispatch = useDispatch();
  const { control, setValue } = useForm({ mode: 'onChange' });
  const onEditLocation = (
    location: { category_item_name: string; id: string },
    type: string
  ) => {
    setLocationModalMode('Edit');
    setUpdateLocationData(location);
    setUpdateLocationType(type);
    setShowLocationAddModal(true);
  };
  const onDeleteLocation = (id: string) => {
    setDeleteLocationData(id);
    setShowLocationDeleteModal(true);
  };

  const onConfirmDeleteLocation = () => {
    reduxDispatch(deleteNestedCategoryItem(deleteLocationData));

    setDeleteLocationData(null);
    setShowLocationDeleteModal(false);
  };

  const onCloseConfirmDialog = () => {
    setDeleteLocationData(null);
    setShowLocationDeleteModal(false);
  };
  const onAddLocation = () => {
    setUpdateLocationData(null);
    setUpdateLocationType(null);
    setLocationModalMode('Add');
    setShowLocationAddModal(true);
  };
  const onCloseLocationAddModal = () => {
    setUpdateLocationData(null);
    setUpdateLocationType(null);
    setShowLocationAddModal(false);
  };

  const RenderedCategory = (props: {
    category_name: string;
    items: { category_item_name: string; id: string }[];
    childCategories: CategoryObj[] | undefined;
    depth: number;
    isFirst?: boolean;
    key: number;
  }) => {
    const categoryOptions: {
      label: JSX.Element;
      value: string | number;
    }[] = props.items.map((entity) => {
      return {
        label: (
          <Label
            label={entity.category_item_name}
            onEdit={() => onEditLocation(entity, props.category_name)}
            onDelete={() => onDeleteLocation(entity.id)}
          />
        ),

        value: entity.id,
      };
    });

    return (
      <div
        key={props.key}
        className={`row mb-4${props.isFirst ? ' isFirstChildCategory' : ''}`}
      >
        {props.isFirst && (
          <span>
            <MdOutlineSubdirectoryArrowRight />
          </span>
        )}
        <label className="form-label">{props.category_name}</label>
        {/* @ts-ignore https://github.com/react-hook-form/react-hook-form/issues/6679 */}
        <Controller
          control={control}
          name={props.category_name as FieldPath<any>}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <StylishNewSelect
              options={categoryOptions}
              defaultValue={categoryOptions.find(
                (co) => co.value === value?.value
              )}
              value={categoryOptions.find((co) => co.value === value?.value)}
              onChange={(e) => onChange(e)}
              placeholder={`Select ${props.category_name}`}
              isClearable={true}
              isSearchable={true}
              isMulti={false}
              isDisabled={false}
            />
          )}
        />
        {props.childCategories?.map((child: CategoryObj, idx: number) => (
          <div key={idx} style={{ paddingLeft: '40px' }}>
            <RenderedCategory
              key={idx}
              category_name={child.category_name}
              items={child.items}
              childCategories={child.children}
              depth={props.depth + 1}
              isFirst={idx === 0}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="bg-gray-800 rounded p-4">
        <div className="d-flex align-items-center mb-4">
          <h6 className="text-uppercase m-0">
            Configure Areas of Responsibility
          </h6>
          <Dropdown className="flex-shrink-0 ms-auto">
            <Dropdown.Toggle className="button--icon">
              <SharedIcon iconName="more_vert" bold />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item
                as="button"
                onClick={() => setShowEditCategoriesModal(true)}
              >
                <SharedIcon iconName="stylus" />
                Edit Categories
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <hr className="dashed my-3" />

        {categoryHierarchy.map((category, idx) => (
          <RenderedCategory
            key={idx}
            category_name={category.category_name}
            items={category.items}
            childCategories={category.children}
            depth={0}
          />
        ))}

        <div className="button-group justify-content-end">
          <StylishNewButton
            type="button"
            className="button--primary w-100 w-md-auto"
            onClick={onAddLocation}
          >
            {`Add Item`}
          </StylishNewButton>
        </div>
      </div>
      {showLocationAddModal && (
        <LocationAddUpdateModal
          mode={locationModalMode}
          show={showLocationAddModal}
          onClose={onCloseLocationAddModal}
          updateLocationData={updateLocationData}
          updateLocationType={updateLocationType}
          nestedCategories={nestedCategories}
        />
      )}
      {showLocationDeleteModal && (
        <StylishConfirmDialog
          show={showLocationDeleteModal}
          dialogTitle={'Delete this Location?'}
          dialogContent={'Are you sure you want to delete this Location?'}
          onClose={onCloseConfirmDialog}
          onConfirm={onConfirmDeleteLocation}
        />
      )}

      {showEditCategoriesModal && (
        <EditCategoriesModal
          show={showEditCategoriesModal}
          onClose={() => setShowEditCategoriesModal(false)}
          categories={reduxNestedCategories}
        />
      )}
    </>
  );
};

export default ManageLocation;
