import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import useFileWithWarning from '../../hooks/useFileWithWarning';
import { bulkUserImport } from 'actions/profileActions';
import { Link } from "react-router-dom";
import IconUpload from "../../assets/images/icon__upload.svg";
import { startLoading, endLoading } from 'reducers/loading/loading.action';

export default function BulkUserImportDialog({ show, onClose }) {
  const reduxDispatch = useDispatch();

  const [setFileInQueue] = useFileWithWarning()
  const [isLoaded, setIsLoaded] = useState(true);
  const [fileToUpload, setFileToUpload] = useState();

  const isBulkUserImportLoaded = useSelector(state => {
    return state.app.isBulkUserImportLoaded
  })

  const isFetchRostersForGroupsLoaded = useSelector(state => {
    return state.app.isFetchRostersForGroupsLoaded
  })

  const reduxCurrentlySelectedGroup = useSelector(state => {
    return state.app.currentlySelectedGroup
  })

  const handleCreateClick = () => {
    reduxDispatch(startLoading());
    reduxDispatch(bulkUserImport(fileToUpload, reduxCurrentlySelectedGroup))
    setIsLoaded(false)
  }

  const onFileChange = (e) => {
    setFileInQueue(e.target.files[0]);
    setFileToUpload(e.target.files[0]);
  }

  useEffect(() => {
    if (!isLoaded && (isBulkUserImportLoaded && isFetchRostersForGroupsLoaded)) {
      reduxDispatch(endLoading())
      onClose()
    }
  }, [isBulkUserImportLoaded, isFetchRostersForGroupsLoaded, isLoaded, onClose])

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Bulk User Import</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <h4>Rules</h4>
            <p>If users in the import spreadsheet are not in Pratus, their accounts will be created.</p>
            <p>If users in the import are not in this Group, they will be added to the group with the default role of&quot;Community Member.&quot;</p>
            <p>Any additional information in the import such as mobile phone number will be added to their profile, ONLY IF they do not already have that information in place.  Bulk User Import cannot overwrite data.</p>
            <p>Adding users to a PRATUS organization will not provision or invite users to Teams at this time.</p>
            <h4>Import Process:</h4>
            <ol className='ol'>
              <li>Go to “Manage Users” in the <a target="_blank" rel="noopener noreferrer" href="https://admin.teams.microsoft.com/users" >Teams Admin Center</a> </li>
              <li>Click “Export” from the toolbar</li>
              <li>Download the “Users List” when it is ready</li>
            </ol>
            <h4>Upload data</h4>
            <div className="form-upload">
              <div>
                <span className="d-flex justify-content-center mb-2">
                  <img src={IconUpload} alt="" />
                </span>
                <span className="d-block">
                  Drop Your Files Here or Click to Upload
                </span>
              </div>
              <input
                type="file"
                name="select-file-input"
                onChange={onFileChange}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              type="button"
              className="button--primary"
              disabled={!isLoaded || !fileToUpload}
              onClick={() => handleCreateClick()}
            >
              Import
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}