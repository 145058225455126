import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewICSResourceSiteDialog from 'components/ICS/NewICSResourceSiteDialog';
import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { fetchResourceSites, updateResourceSite, createResourceSite } from 'slices/ics_resourceSitesSlice';
import ExpandRightArrow from 'components/DesignSystems/buttons/ExpandRightArrow';
import ExpandDownArrow from 'components/DesignSystems/buttons/ExpandDownArrow';

export default function ResourceSitesDialog({ show, onClose }) {
  const dispatch = useDispatch();
  const ics_resource_sites = useSelector((state) => state.ics_resource_sites.ics_resource_sites);
  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);

  const [isNewResourceSiteDialogOpen, setIsNewResourceSiteDialogOpen] = useState(false);
  const [modalType, setModalType] = useState('New');
  const [editResourceSiteData, setEditResourceSiteData] = useState();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [archiveConfirmationData, setArchiveConfirmationData] = useState(null);

  useEffect(() => {
    if (reduxCurrentlySelectedGroup) {
      dispatch(fetchResourceSites(reduxCurrentlySelectedGroup.group_guid));
    }
  }, [dispatch, reduxCurrentlySelectedGroup]);

  const columns = [
    { dataField: 'name', text: 'Name', sort: true, attrs: { title: 'Name' } },
    { dataField: 'description', text: 'Description', sort: true, attrs: { title: 'Description' } },
    { dataField: 'type', text: 'Type', sort: true, attrs: { title: 'Type' } },
    { dataField: 'archived', text: 'Archived', sort: true, attrs: { title: 'Archived' } },
  ];

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewResourceSiteDialogOpen(true);
    setEditResourceSiteData(row);
  };

  const onArchive = (row) => {
    setShowConfirmDialog(true);
    setArchiveConfirmationData(row);
  };

  const onConfirmedArchiveActionData = () => {
    const payload = {
      ...archiveConfirmationData,
      archived: !archiveConfirmationData.archived,
    };
    dispatch(updateResourceSite(archiveConfirmationData.id, payload));
    setShowConfirmDialog(false);
    setArchiveConfirmationData(null);
  };

  const onNewResourceSite = () => {
    setModalType('New');
    setIsNewResourceSiteDialogOpen(true);
  };

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => (
      <>
        <div className="mb-4">
          <h6 className="mb-2">Name:</h6>
          <p className="m-0">{row.name}</p>
        </div>
        <div className="mb-4">
          <h6 className="mb-2">Description:</h6>
          <p className="m-0">{row.description}</p>
        </div>
        <div className="mb-4">
          <h6 className="mb-2">Type:</h6>
          <p className="m-0">{row.type}</p>
        </div>
        <div className="mb-4">
          <h6 className="mb-2">Archived:</h6>
          <p className="m-0">{row.archived ? 'Yes' : 'No'}</p>
        </div>
        <div className="button-group">
          <StylishNewButton className="button--primary" onClick={() => onEdit(row)}>
            Edit
          </StylishNewButton>
          <StylishNewButton className="button--tertiary button--reverse" onClick={() => onArchive(row)}>
            {row.archived ? 'Unarchive' : 'Archive'}
          </StylishNewButton>
        </div>
      </>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? <ExpandDownArrow /> : <ExpandRightArrow />,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <span className="sr-only">Details</span>
            <ExpandDownArrow />
          </>
        );
      }
      return (
        <>
          <span className="sr-only">Details</span>
          <ExpandRightArrow />
        </>
      );
    },
  };

  return (
    <Modal show={show} onHide={onClose} centered backdrop="static" keyboard={false} size="lg">
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Resource Sites</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">{reduxCurrentlySelectedGroup?.group_name}</p>
        <StylishNewButton type="button" className="button--primary mb-4" onClick={onNewResourceSite}>
          New Resource Site
        </StylishNewButton>
        <StylishNewTable keyField={'id'} rows={ics_resource_sites} columns={columns} expandRow={expandRow} />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton className="button--secondary button--reverse" type="button" onClick={onClose}>
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewResourceSiteDialogOpen && (
        <NewICSResourceSiteDialog
          show={isNewResourceSiteDialogOpen}
          setEditResourceSiteData={setEditResourceSiteData}
          setModalType={setModalType}
          editResourceSiteData={editResourceSiteData}
          modalType={modalType}
          onClose={() => setIsNewResourceSiteDialogOpen(false)}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Archive Confirmation'}
          dialogContent={'Are you sure you want to archive this Resource Site?'}
          dialogButtonText={'Archive'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedArchiveActionData}
        />
      )}
    </Modal>
  );
}
