import { Card } from 'react-bootstrap';
import ExpandDownArrow from '../../DesignSystems/buttons/ExpandDownArrow';
import ExpandRightArrow from '../../DesignSystems/buttons/ExpandRightArrow';
import { StylishNewButton } from '../../DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../../SharedIcon/SharedIcon';
import Collapse from '@mui/material/Collapse';
import React, { useState } from 'react';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import { difference } from 'lodash';
import { useController, useFormContext } from 'react-hook-form';
import { StylishNewSearchBar } from '../../DesignSystems/New/StylishNewSearchBar';
import SelectableEntityCardSwitch, {
  CardType,
} from '../cards/SelectableEntityCardSwitch';

type Entity = {
  id: UUID;
} & {
  [key: string]: any;
};

type Props<G extends Entity> = {
  label: string;
  fieldName: string;
  defaultValue: UUID[]; // I need this because the generics don't work quite well enough
  options: G[] | undefined;
  identity?: (entity: G) => UUID;
  title?: (entity: G) => string;
  cardType?: CardType;
};

const RelationshipPickerField = <G extends Entity>({
  label,
  fieldName,
  defaultValue,
  options = [],
  identity = (entity) => entity.id,
  title = (entity) => entity?.name,
  cardType = 'default',
}: Props<G>) => {
  const { control } = useFormContext();
  const { field } = useController<{ [key: string]: UUID[] }>({
    name: fieldName,
    control,
    defaultValue: defaultValue ?? [],
  });
  const { onChange, value } = field;

  const [editMode, toggleEditMode] = useToggle(false);
  const [textFilter, setTextFilter] = useState('');

  console.log('value', value);

  const s1 = value.map((id) => {
    const found = options.find((v) => identity(v) === id);
    console.log('found', found);
    return found;
  });
  console.log('s1', s1);
  const s2 = s1.filter((x) => !!x);
  console.log('s2', s2);
  const unselectedOptions = difference(options, s2).filter((option: any) => {
    return title(option)?.toLowerCase().includes(textFilter.toLowerCase()) ?? true;
  });
  console.log('unselectedOptions', unselectedOptions);

  const toggleRelationship = (child: any) => {
    if (value.includes(identity(child))) {
      onChange(value.filter((id) => id !== identity(child)));
    } else {
      onChange([...value, identity(child)]);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="txt--semibold pt-4">
          {label}: {value.length ?? 0}
        </div>
        <div className="d-flex align-items-center">
          {editMode && (
            <StylishNewSearchBar
              value={textFilter}
              onChangeSearchTerm={setTextFilter}
              icon={'filter_alt'}
              placeholder={'Filter...'}
              className={'pb-0'}
            />
          )}
          <StylishNewButton
            className="button button--icon button--sml sml border-0"
            onClick={toggleEditMode}
          >
            <SharedIcon iconName="edit" size={'sm'} />
          </StylishNewButton>
        </div>
      </div>
      {editMode ? (
        <>
          <div className="d-flex flex-column gap-2">
            {s2.map((entity) => (
              <SelectableEntityCardSwitch
                key={entity.id}
                entity={entity}
                value={value}
                toggleRelationship={toggleRelationship}
                cardType={cardType}
              />
            ))}
          </div>
          <div className="d-flex flex-column gap-2 mt-3">
            {unselectedOptions.map((entity: any) => (
              <SelectableEntityCardSwitch
                key={entity.id}
                entity={entity}
                value={value}
                toggleRelationship={toggleRelationship}
                cardType={cardType}
              />
            ))}
          </div>
        </>
      ) : (
        <div>
          {s2.map((entity) => (
            <span className="badge badge--sm badge--neutral me-2">
              <span>{title(entity)}</span>
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default RelationshipPickerField;

type RowProps = {
  selected: boolean;
  onClick: () => void;
  header: () => JSX.Element;
  children: React.ReactNode;
};

export const CollapsibleRow: React.FC<RowProps> = ({
  selected,
  onClick,
  header,
  children,
}) => {
  const [expanded, toggleExpanded] = useToggle(false);

  return (
    <Card className="bg-bglight rounded-1 align-items-center justify-content-between p-2">
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          <div>
            {expanded ? (
              <ExpandDownArrow onClick={toggleExpanded} />
            ) : (
              <ExpandRightArrow onClick={toggleExpanded} />
            )}
          </div>
          {header()}
        </div>
        <StylishNewButton
          className={classNames('button button--icon button--sml sml', {
            'button--primary': selected,
            'border-0': selected,
          })}
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
        >
          {selected ? (
            <SharedIcon iconName="check_small" size={'sm'} />
          ) : (
            <SharedIcon iconName="add" size={'sm'} />
          )}
        </StylishNewButton>
      </div>
      <Collapse in={expanded} className="w-100">
        {children}
      </Collapse>
    </Card>
  );
};
