export const inputStringLength = 200;
export const textareaStringLength = 1500;
export const calendarStringLength = 20;
export const cardStringLength = 100;
export const contentBlockLength = 700;
export const contactStringLength = 36;

export const locationNameStringLength = 36;

export const paperBgColor = '#343A40';
export const plotBgColor = '#343A40';
export const plotOrange = '#F99109';
export const plotGreen = '#50EA50';
export const plotBlue = '#1F77B4';
export const plotBrick = '#C0392B';
export const plotYellow = '#FFEF11';
export const plotGrey = '#6C757D';

export const timeFormatOptions = [
  {
    value: 'hour24Mode',
    label: '24 Hour Time Format',
  },
  {
    value: 'hour12Mode',
    label: '12 Hour Time Format',
  },
];

export const toastConfig = {
  autoClose: 1500,
  theme: 'dark',
} as const;

export const noAutoClose = {
  autoClose: false,
};

export const SpecialInterestOptions = [
  { label: 'High Priority', value: 'high_priority' },
  { label: 'Flagged', value: 'flagged' },
  { label: 'Question', value: 'question' },
];

export const blankRiskCharacter = {
  red: 0,
  yellow: 0,
  unknown: 0,
  na: 0,
  green: 0,
};

export const Legends = [
  {
    name: 'Red',
    color: '#FF0000',
    value: 'red',
  },
  {
    name: 'Yellow',
    color: '#FBC02D',
    value: 'yellow',
  },
  {
    name: 'Green',
    color: '#20BA38',
    value: 'green',
  },
  {
    name: 'Unknown/Unavailable',
    color: '#FFFFFF',
    value: 'unknown',
  },
  {
    name: 'Not Applicable',
    color: '#818181',
    value: 'na',
  },
];

export const StyleLabel = {
  width: '2rem',
  height: '2rem',
  borderRadius: '5px',
};

export const technicalDisciplinesOptions = [
  { label: 'Level 1', value: 'level_1' },
  { label: 'Programmatic', value: 'programmatic' },
  { label: 'T&E', value: 'te' },
  { label: 'Technology', value: 'technology' },
  { label: 'Training', value: 'training' },
  { label: 'Software', value: 'software' },
];

export const alphabeticalNumbering = (number: number) => {
  let result = '';
  while (number > 0) {
    const remainder = (number - 1) % 26;
    result = String.fromCharCode(97 + remainder) + result;
    number = Math.floor((number - 1) / 26);
  }
  return result;
};
export const stringToNumber = (str: string) => {
  let result = 0;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i) - 96;
    result = result * 26 + charCode;
  }
  return result;
};
