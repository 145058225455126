import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BulkUpgradeSubscriptionDialog } from './BulkUpgradeSubscriptionDialog';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { SubscriptionAddOnDialog } from './SubscriptionAddOnDialog';
import { getPagedUsers, editUser } from './subscriptionHooks';

export const SubscriptionManagement = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const { data, isLoading, isError, error } = getPagedUsers(
    searchTerm,
    currentPage,
    itemsPerPage
  );
  const { mutateAsync } = editUser();

  const [
    showBulkUpgradeSubscriptionDialog,
    setShowBulkUpgradeSubscriptionDialog,
  ] = useState(false);

  const [usersToBeUpgraded, setUsersToBeUpgraded] = useState([]);
  const [openAddOnDialog, setOpenAddOnDialog] = useState(false);

  const statusOptions = [
    { label: 'Trial', value: 'Trial' },
    { label: 'Paid', value: 'Paid' },
    { label: 'Paid (Incident only)', value: 'Paid (Incident only)' },
    { label: 'Paid (Exercise only)', value: 'Paid (Exercise only)' },
    { label: 'Expired', value: 'Expired' },
  ];

  const columns = [
    {
      dataField: 'userName',
      text: 'Name',
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
      formatter: (cell, row, rowIndex) => {
        return <>{row?.profile_settings?.userName}</>;
      },
    },
    {
      dataField: 'email_address',
      text: 'Email',
      attrs: { title: 'Email' },
    },
    {
      dataField: 'current_status',
      text: 'Subscription Status',
      formatter: (cell, row, rowIndex) => {
        return (
          <div style={{ width: '200px' }} key={row?.current_status}>
            <StylishNewSelect
              options={statusOptions}
              value={
                row?.current_status
                  ? { label: row?.current_status, value: row?.current_status }
                  : null
              }
              placeholder="Select a Status"
              onChange={(e) =>
                upgradeSubscriptionHandler(
                  row?.subscription_id,
                  e.value,
                  row?.subscribed_mass_notification,
                  row?.subscribed_international_messaging
                )
              }
              isDisabled={false}
              isClearable={false}
              isSearchable={false}
              isMulti={false}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor:
                    row?.current_status === 'Trial'
                      ? '#4A98CE !important'
                      : row?.current_status === 'Paid'
                      ? '#37CC70 !important'
                      : row?.current_status === 'Paid (Incident only)'
                      ? '#37CC70 !important'
                      : row?.current_status === 'Paid (Exercise only'
                      ? '#37CC70 !important'
                      : row?.current_status === 'Expired'
                      ? '#FF2323 !important'
                      : null,
                  background:
                    row?.current_status === 'Trial'
                      ? '#4A98CE !important'
                      : row?.current_status === 'Paid'
                      ? '#37CC70 !important'
                      : row?.current_status === 'Paid (Incident only)'
                      ? '#37CC70 !important'
                      : row?.current_status === 'Paid (Exercise only'
                      ? '#37CC70 !important'
                      : row?.current_status === 'Expired'
                      ? '#FF2323 !important'
                      : null,
                }),
                indicatorSeparator: (baseStyles, state) => ({
                  ...baseStyles,
                  background: row?.current_status ? `#fff !important` : null,
                }),
              }}
            />
          </div>
        );
      },
      attrs: { title: 'Subscription Status' },
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Profile"
          cell={cell}
          row={row}
          onView={() =>
            row?.user_guid ? navigate(`/profile/${row?.user_guid}`) : undefined
          }
          onAddOn={() => {
            if (row?.user_guid) {
              setOpenAddOnDialog(true);
              setSubscriptionData(row);
            }
          }}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="col-md-6">
              <h6 className="mb-2">Mobile Phone No.</h6>
              {row?.profile_settings?.mobile_phone_number
                ? row?.profile_settings?.mobile_phone_number
                : '-'}
            </div>
            <div className="col-md-6">
              <h6 className="mb-2">Home Phone No.</h6>
              {row?.profile_settings?.home_phone_number
                ? row?.profile_settings?.home_phone_number
                : '-'}
            </div>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    hideSelectAll: false,
    selected: usersToBeUpgraded.map((i) => i.user_guid),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, checked, disabled }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} checked={checked} disabled={disabled} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      const user = {
        user_guid: row.user_guid,
        subscription_id: row.subscription_id,
      };
      const s = new Set(usersToBeUpgraded);
      if (isSelect) {
        s.add(user);
      } else {
        s.delete(user);
      }
      setUsersToBeUpgraded(Array.from(s));
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        const users = rows.map((i) => ({
          user_guid: i.user_guid,
          subscription_id: i.subscription_id,
        }));
        setUsersToBeUpgraded(users);
      } else {
        setUsersToBeUpgraded([]);
      }
    },
  };

  const onChangeSearchTerm = (value) => {
    setCurrentPage(1);
    setSearchTerm(value);
  };

  const upgradeSubscriptionHandler = async (
    subscriptionId,
    value,
    subscribed_mass_notification,
    subscribed_international_messaging
  ) => {
    await mutateAsync({
      subscriptionId,
      value,
      subscribed_mass_notification,
      subscribed_international_messaging,
    });
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="site-main">
        <div className="site-main__profile">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <button onClick={() => navigate(-1)}>
                        <SharedIcon
                          iconName="keyboard_arrow_left"
                          classes="me-2"
                        />
                        Back
                      </button>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Subscription Management
                    </li>
                  </ol>
                </nav>
                <div className="d-flex mb-4">
                  <StylishNewSearchBar
                    value={searchTerm}
                    onChangeSearchTerm={onChangeSearchTerm}
                    placeholder={'Search by email..'}
                  />
                  {!!usersToBeUpgraded.length && (
                    <StylishNewButton
                      className={'button--primary ms-auto'}
                      onClick={() => setShowBulkUpgradeSubscriptionDialog(true)}
                    >
                      Bulk Upgrade
                    </StylishNewButton>
                  )}
                </div>
                <div className="form-block">
                  <p className="weight-600">{data?.total || 0} Member(s)</p>
                  <StylishNewTable
                    keyField={'user_guid'}
                    columns={columns}
                    rows={data?.users || []}
                    expandRow={expandRow}
                    selectRow={selectRow}
                    pagination={true}
                    page={currentPage}
                    perPage={itemsPerPage}
                    total={data?.total}
                    noDataIndication={
                      isLoading ? (
                        'Loading Users...'
                      ) : isError ? (
                        <div className="form-text form-error">
                          {error?.message}
                        </div>
                      ) : (
                        'No Users'
                      )
                    }
                    onPageChange={(page) => handlePageClick(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showBulkUpgradeSubscriptionDialog && (
        <BulkUpgradeSubscriptionDialog
          show={showBulkUpgradeSubscriptionDialog}
          onClose={() => {
            setShowBulkUpgradeSubscriptionDialog(false);
            setUsersToBeUpgraded([]);
          }}
          users={usersToBeUpgraded}
        />
      )}

      {openAddOnDialog && (
        <SubscriptionAddOnDialog
          show={openAddOnDialog}
          onClose={() => {
            setOpenAddOnDialog(false);
            setSubscriptionData(null);
          }}
          subscriptionData={subscriptionData}
        />
      )}
    </>
  );
};
