import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useForm } from 'react-hook-form';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';

import {
  createIcsStrategy,
  updateIcsStrategy,
} from 'slices/ics_strategiesSlice';

import { updateIcsIncidentStrategy } from 'slices/ics_incidentStrategiesSlice';
import { toast } from 'react-toastify';

type NewICSStrategyDialogProps = {
  setEditStrategyData: any;
  editStrategyData: any;
  modalType: string;
  setModalType: any;
  onClose: any;
  incident_id: string;
  ics_objectives: any;
  ics_incident_objectives: any;
};

const NewICSStrategyDialog: React.FC<NewICSStrategyDialogProps> = ({
  setEditStrategyData,
  editStrategyData,
  modalType,
  setModalType,
  onClose,
  incident_id,
  ics_incident_objectives,
}) => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    register,
  } = useForm<{name: string, description: string}>({ mode: 'onChange' });

  const nameInputProps = register('name', { required: true });
  const descInputProps = register('description', { required: true });

  const ics_objectives = useSelector((state: any) => state.ics_objectives.ics_objectives);

  const objectivesToUse =
    (!!incident_id && ics_incident_objectives) || ics_objectives;

  const [obj, setobj] = useState(
    objectivesToUse.filter(
      (o: any) => (o.objective_id ?? o.id) === editStrategyData?.objective_id
    )
  );

  const [strategyId, setStrategyId] = useState('');
  const dispatch = useDispatch();

  const reduxCurrentlySelectedGroup = useSelector((state: any) => {
    return state.app.currentlySelectedGroup;
  });



  useEffect(() => {
    if (modalType === 'New') {
      setStrategyId(generateUUID());
    } else if (modalType === 'Edit') {
      setStrategyId(editStrategyData.id);
      setValue('description', editStrategyData.description);
      setValue('name', editStrategyData.name);
    }
  }, [modalType]);

  const onNewStrategyDataSubmit = async (data: {name: string, description: string}) => {

    if (modalType === 'New') {
      const payload = {
        strategy_id: strategyId,
        name: data.name,
        description: data.description,
        incident_id: incident_id,
        group_guid: reduxCurrentlySelectedGroup.group_guid,
        objectives: obj.map((o: any) => o.value.objective_id ?? o.value.id),
      }
      try {
        await dispatch(createIcsStrategy(payload));
      } catch (error: any) {
        toast.error('Error creating strategy: ' + error?.message)
      }
      onClose();
    } else if (modalType === 'Edit') {
      const payload = {
        id: strategyId, // TODO: is this needed?
        strategy_id: strategyId,
        name: data.name,
        description: data.description,
        incident_id: incident_id,
        group_guid: reduxCurrentlySelectedGroup.group_guid, // TODO: already in the action and not in DB.
        archived: false, // TODO:
      }
      payload.id = editStrategyData?.id;
      if (!incident_id) {
        dispatch(updateIcsStrategy(payload.id, payload));
      } else if (!!incident_id) {
        dispatch(
          updateIcsIncidentStrategy(incident_id, payload.id, payload)
        );
      }

      onClose();
    }
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Strategy</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewStrategyDataSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">ID</label>
            <input
              className="form-control"
              value={strategyId}
              readOnly
              disabled
            />
          </div>
          <div className="m-0">
            <label className="form-label">Name:</label>
            <input
              className="form-control"
              maxLength={300}
              {...nameInputProps}
            />
            {errors.name && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Description:</label>
            <input
              className="form-control"
              maxLength={300}
              {...descInputProps}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Objective:</label>
            {(!!editStrategyData && (
              <div>
                <label>{obj?.name}</label>
              </div>
            )) || (
              <StylishNewSelect
                options={objectivesToUse.map((o: any) => {
                  return {
                    label: o.name,
                    value: o,
                  };
                })}
                defaultValue={obj}
                onChange={(e) => {
                  console.log(e)
                  setobj(e);
                }}
                placeholder="Select Objective (required)"
                disabled={modalType === 'Edit'}
                isMulti={true}
              />
            )}
            <div>
              <strong className="form-text">
                (Objective cannot be changed after creation)
              </strong>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton
              className="button--primary"
              type="submit"
              disabled={!obj}
            >
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default NewICSStrategyDialog;
