import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { ResourceTypes } from './ResourceTypes';
import generateUUID from 'utils/sharedUtils/generateUUID';
import {
  addResourceToIncident,
  updateResourceToIncident,
} from 'actions/incidentActions';
import { useDispatch, useSelector } from 'react-redux';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';
import { productionEnv } from 'constants/debug';

export default function NewResourcesDialog({
  show,
  onClose,
  modalType,
  setModalType,
  editResourceData,
  setEditResourceData,
}) {
  const mapboxToken = window.env.MAPBOX_ACCESS_TOKEN;

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const [isLoaded, setIsLoaded] = useState(true);
  const [resourceId, setResourceId] = useState('');
  const [arrived, setArrived] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Not Arrived');

  const [geoLocation, setGeoLocation] = useState(editResourceData?.geolocation);

  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (modalType === 'New') {
      setResourceId(generateUUID());
      setGeoLocation(undefined);
    } else if (modalType === 'Edit') {
      setResourceId(editResourceData.id);
      handeleEditPatch();
    }
  }, [modalType]);

  const handeleEditPatch = () => {
    setValue('name', editResourceData.name);
    setValue(
      'date_time_ordered',
      moment(editResourceData.datetime_ordered).isValid()
        ? moment(editResourceData.datetime_ordered).format('MM/DD/YYYY HH:mm')
        : moment()
    );
    setValue(
      'eta',
      moment(editResourceData.eta).isValid()
        ? moment(editResourceData.eta).format('MM/DD/YYYY HH:mm')
        : moment()
    );
    setValue('location', editResourceData.location);
    setValue('note', editResourceData.description);
    setValue('type', {
      value: editResourceData.type,
      label: editResourceData.type,
    });
    setArrived(editResourceData.arrived);
    setSelectedTab(editResourceData.arrived ? 'Arrived' : 'Not Arrived');
  };

  const resourceType = ResourceTypes.map((e) => {
    return { name: e.name, value: e.name };
  });

  const isIncidentResourcesLoaded = useSelector((state) => {
    return state.app.isIncidentResourcesLoaded;
  });

  const isAddIncidentResourcesLoaded = useSelector((state) => {
    return state.app.isAddIncidentResourcesLoaded;
  });

  useEffect(() => {
    if (
      !isLoaded &&
      isIncidentResourcesLoaded &&
      isAddIncidentResourcesLoaded
    ) {
      onClose();
      setModalType('New');
      setEditResourceData(undefined);
    }
  }, [
    isIncidentResourcesLoaded,
    isAddIncidentResourcesLoaded,
    isLoaded,
    onClose,
  ]);

  const toggleData = [
    { label: 'Not Arrived', value: 'Not Arrived' },
    { label: 'Arrived', value: 'Arrived' },
  ];

  const handleToggleButtonClick = (value) => {
    setSelectedTab(value);
    setArrived(value === 'Arrived' ? true : false);
  };

  const onNewResourceDataSubmit = (data) => {
    if (
      moment(data.date_time_ordered).isAfter(moment(data.eta)) ||
      moment(data.date_time_ordered).isSame(moment(data.eta))
    ) {
      setError('date_time_ordered', { type: 'custom' });
      setError('eta', { type: 'custom' });
      return;
    } else {
      clearErrors('date_time_ordered');
      clearErrors('eta');
    }
    const current_location_geom =
      !!geoLocation && !!geoLocation.geojson
        ? geoLocation.geojson.data.features[0].geometry
        : null;
    const resource = {
      id: resourceId,
      name: data.name,
      type: data.type.value,
      datetime_ordered: new Date(data.date_time_ordered),
      eta: new Date(data.eta),
      arrived,
      location: data.location,
      description: data.note,
      geolocation: geoLocation,
      current_location_geom: current_location_geom,
    };
    if (modalType === 'New') {
      reduxDispatch(startLoading());
      reduxDispatch(addResourceToIncident(resource));
      onClose();
    } else if (modalType === 'Edit') {
      reduxDispatch(startLoading());
      reduxDispatch(updateResourceToIncident(resource));
      reduxDispatch(endLoading());
      onClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Resources</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label className="form-label">Name:</label>
          <Controller
            control={control}
            name="name"
            rules={{ required: true, maxLength: 201 }}
            render={({ field: { onChange, value } }) => (
              <StylishNewInput
                type="text"
                onChange={(e) => onChange(e.target.value)}
                value={value || ''}
              />
            )}
          />
          {errors?.name?.type === 'required' && (
            <span className="form-text form-error">This field is required</span>
          )}
          {errors?.name?.type === 'maxLength' && (
            <span className="form-text form-error">
              Maximum length must not exceed 200 characters
            </span>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">Type:</label>
          <Controller
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishNewSelect
                options={ResourceTypes.map((type) => {
                  return { value: type.name, label: type.name };
                })}
                defaultValue={value}
                value={value || ''}
                onChange={(e) => onChange(e)}
                placeholder={'Please Select a Type'}
                isClearable={true}
                isSearchable={true}
                isMulti={false}
                isDisabled={false}
              />
            )}
          />
          {errors?.type && (
            <span className="form-text form-error">This field is required</span>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">Date / Time Ordered</label>
          <Controller
            control={control}
            name="date_time_ordered"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishDateTimePicker
                value={value}
                onChange={(e) => onChange(e)}
                type="datetime-local"
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
              />
            )}
          />
          {errors?.date_time_ordered?.type === 'required' && (
            <span className="form-text form-error">
              This fields (Date &amp; Time) is required
            </span>
          )}
          {errors?.date_time_ordered?.type === 'custom' && (
            <span className="form-text form-error">
              Start Date/Time Cannot Be Greater than or Equal to End Date/Time
            </span>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">ETA</label>
          <Controller
            control={control}
            name="eta"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishDateTimePicker
                value={value}
                onChange={(e) => onChange(e)}
                type="datetime-local"
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
              />
            )}
          />
          {errors?.eta?.type === 'required' && (
            <span className="form-text form-error">
              This fields (Date &amp; Time) is required
            </span>
          )}
          {errors?.eta?.type === 'custom' && (
            <span className="form-text form-error">
              ETA Date/Time Cannot Be Less than or Equal to Start Date/Time
            </span>
          )}
        </div>
        <div className="mb-3 pt-2">
          <StylishNewToggleButtonGroup
            className="d-md-inline-flex"
            data={toggleData}
            selected={selectedTab}
            onChange={handleToggleButtonClick}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Current Location:</label>
          <GeoLocationComponent
            location={geoLocation}
            setLocation={setGeoLocation}
            mapboxToken={mapboxToken}
          />
        </div>
        <div className="m-0">
          <label className="form-label">Note:</label>
          <Controller
            control={control}
            name="note"
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <StylishNewTextArea
                onChange={(e) => onChange(e.target.value)}
                value={value || ''}
              />
            )}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
          <StylishNewButton
            type="submit"
            className="button--primary"
            onClick={handleSubmit(onNewResourceDataSubmit)}
          >
            Save
          </StylishNewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
