import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { SharedIcon } from '../../SharedIcon/SharedIcon';

export const StylishNewSearchBar = ({
  className,
  value,
  onChangeSearchTerm,
  placeholder,
  icon,
}) => {
  const [searchTerm, setSearchTerm] = useState(value || '');
  useEffect(() => {
    setSearchTerm(value || '');
  }, [value]);
  const onSearchReset = () => {
    setSearchTerm('');
    onChangeSearchTerm('');
  };

  const onChange = (event) => {
    setSearchTerm(event.target.value);
    onChangeSearchTerm(event.target.value);
  };

  return (
    <div
      className={`form-search has-reset form-icon ${
        !!className ? className : ''
      }`}
    >
      <DebounceInput
        value={searchTerm}
        minLength={1}
        debounceTimeout={300}
        onChange={(e) => onChange(e)}
        placeholder={placeholder || 'Search...'}
        className="form-control"
      />
      <span className="icon">
        <SharedIcon iconName={icon || 'search'} />
      </span>
      {!!searchTerm && (
        <span className="icon icon-reset" onClick={onSearchReset}>
          <SharedIcon iconName={'close'} size={'sm'} />
        </span>
      )}
    </div>
  );
};
