import { saveAs } from 'file-saver'

export default function downloadFile(filenameWithoutType,filetype,base64Data)
{
    let dataBlob;
    // Convert the data to be blob-able 
    let binary = atob(base64Data.replace(/\s/g, ''));
    let len = binary.length;
    let buffer = new ArrayBuffer(len);
    let view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }

    if(filetype==="PDF")
    {
    	dataBlob = new Blob([view],{type : 'application/pdf'});
    }
    
    saveAs(dataBlob,filenameWithoutType+"."+filetype)
}