import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import { selectUserGuid } from '../../../slices/commonSelectors';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../assets/data/config';
import { useActualIncidentId } from './commonHooks';

export type IncidentHospital = {
  id: string;
  hospital_id: string;
  incident_id: string;
  user_guid: string;
  version: number;
  hospital_name?: string;
  description?: string;
  address?: string;
  contact_number?: string;
  travel_time_air?: string;
  travel_time_ground?: string;
  burn_center: boolean;
  heli_pad: boolean;
  timestamp: Date;
  archived: boolean;
};

export const keys = {
  base: 'incident_hospitals',
  get all() { return [keys.base] },
  list: (incidentId: string, userId: string) => [keys.base, incidentId, userId],
} as const;

export type CreateIncidentHospitalReqData = {
  hospital_id: string;
  incident_id: string;
  hospital_name?: string;
  description?: string;
  address?: string;
  contact_number?: string;
  travel_time_air?: string;
  travel_time_ground?: string;
  burn_center?: boolean;
  heli_pad?: boolean;
  archived?: boolean;
};

export const useIncidentHospitals = () => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.list(incidentId, user_guid),
    queryFn: async () => {
      const resp = await axios.get<IncidentHospital[]>(
        `${config.apiGateway.ics_incident_hospitals}/${incidentId}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
  });
};

export const useCreateIncidentHospital = () => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data: CreateIncidentHospitalReqData) => {
      return await axios.post(
        `${config.apiGateway.ics_incident_hospitals}/${incidentId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(incidentId, user_guid) });
    },
    onError: (error: any) => {
      toast.error(error.message, toastConfig as any);
    },
  });
  return mutation;
};

interface EditIncidentHospitalReqData {
  id: string;
  hospital_name?: string;
  description?: string;
  address?: string;
  contact_number?: string;
  travel_time_air?: string;
  travel_time_ground?: string;
  burn_center?: boolean;
  heli_pad?: boolean;
  archived?: boolean;
}

export const useEditIncidentHospital = (hospitalId: string) => {
  const user_guid = useSelector(selectUserGuid);
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: EditIncidentHospitalReqData) => {
      return await axios.put(
        `${config.apiGateway.ics_incident_hospitals}/${incidentId}/${hospitalId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(incidentId, user_guid) });
    },
    onError: (error: any) => {
      toast.error(error.message, toastConfig as any);
    },
  });
};
