export default function sitrepName(type,data)
{
	if(type==="AOR")
	{
		return data.name
	}
	else if(type==="EVENTS")
	{
		return getPreciseEventName(data)
	}
}

function getPreciseEventName(event) {

  if(!!event.name)
  {
    return event.name
  }

  if (!event || !event.hazard_name) return 'Unknown Event';

  let preciseEventName = '';

  switch (event.hazard_name) {
    case 'Earthquakes':
      preciseEventName = `M${event?.properties?.mag || 'N/A'} ${event?.properties.place}`;
      preciseEventName = `${event.hazard_name}: ${preciseEventName}`;
      break;
    case 'Wild Fires':
      preciseEventName = event?.properties?.poly_IncidentName + " Fire" || 'Unknown Wildfire';
      break;
    case 'Hurricane':
      let mainData = event?.hurricane_data_points?.find(
          (p) => p.storm_data_category === 'Observed Points'
        );
      if (mainData) {
        preciseEventName = `${mainData?.unified_storm_category_name} ${mainData?.unified_storm_name}`;
      } else {
        preciseEventName = 'Unknown Hurricane/Tropical Storm';
      }
      break;
    case 'Power Outage':
      preciseEventName = `${event?.event?.county_name || 'Unknown County'} - ${event?.event?.state_name || 'Unknown State'}`;
      break;
    case 'NWS':
      preciseEventName = event?.event?.properties?.headline || 'Unknown NWS Event';
      break;
    default:
      preciseEventName = 'Unknown Event';
  }

  if(event.hazard_data_category==="NWS alerts")
  {
  	return event?.properties?.headline || event?.event?.properties?.headline
  }

  if(!!event.name)
  {
    preciseEventName = event.name
  }

  return preciseEventName
}
