export const Config201 = {
    formname: "ICS 201",
    filename: "ICS_201.PDF",
    field: [
        {
            key: 'Incident Name',
            type: 'Text',
            value: '',
        },
        {
            key: 'Incident Number',
            type: 'Text',
            value: '',
        },
        {
            key: 'Date',
            type: 'Text',

            value: '',
        },
        {
            key: 'Time',
            type: 'Text',
            value: '',
        },

        {
            key: '4 MapSketch include sketch showing the total area of operations the incident sitearea impacted and threatened areas overflight results trajectories impacted shorelines or other graphics depicting situational status and resource assignment',
            type: 'Image',
            value: '',
        },
        {
            key: '5 Situation Summary and Health and Safety Briefing for briefings or transfer of command Recognize potential incident Health and Safety Hazards and develop necessary measures remove hazard provide personal protective equipment warn people of the hazard to protect responders from those hazards',
            type: 'Text',
            value: '',
        },
        {
            key: '6 Prepared by Name',
            type: 'Text',
            value: '',
        },
        {
            key: '6 Prepared by Name_2',
            type: 'Text',
            value: '',
        },
        {
            key: '6 Prepared by Name_3',
            type: 'Text',
            value: '',
        },
        {
            key: '6 Prepared by Name_4',
            type: 'Text',
            value: '',
        },
        {
            key: 'PositionTitle',
            type: 'Text',
            value: '',
        },
        {
            key: 'PositionTitle_2',
            type: 'Text',
            value: '',
        },
        {
            key: 'PositionTitle_3',
            type: 'Text',
            value: '',
        },
        {
            key: 'PositionTitle_4',
            type: 'Text',
            value: '',
        },
        {
            key: 'DateTime',
            type: 'Text',
            value: '',
        },
        {
            key: 'DateTime_2',
            type: 'Text',
            value: '',
        },
        {
            key: 'DateTime_3',
            type: 'Text',
            value: '',
        },
        {
            key: 'DateTime_4',
            type: 'Text',
            value: '',
        },
        {
            key: 'Signature',
            type: 'Signature',
            value: '',
        },
        {
            key: 'Signature_2',
            type: 'Signature',
            value: '',
        },
        {
            key: 'Signature_3',
            type: 'Signature',
            value: '',
        },
        {
            key: 'Signature_4',
            type: 'Signature',
            value: '',
        },
        {
            key: '7 Current and Planned Objectives',
            type: 'List',
            value: [],
        },
        {
            key: 'Action',
            type: 'Table',
            value: [],
        },
        {
            key: 'Resource',
            type: 'Table',
            value: [],
        },
        {
            key: 'Organization Chart',
            type: 'OrgChart',
            value: [
                {
                    key: 'Incident Commanders',
                    type: 'List',
                    value: [],
                },
                {
                    key: 'Liaison Officer',
                    type: 'List',
                    value: [],
                },

                {
                    key: 'Safety Officer',
                    type: 'List',
                    value: [],
                },
                {
                    key: 'Public Information Officer',
                    type: 'List',
                    value: [],
                },
                {
                    key: 'Planning Section Chief_2',
                    type: 'List',
                    value: [],
                },
                {
                    key: 'Operations Section Chief_2',
                    type: 'List',
                    value: [],
                },
                {
                    key: 'FinanceAdministration Section Chief',
                    type: 'List',
                    value: [],
                },
                {
                    key: 'Logistics Section Chief',
                    type: 'List',
                    value: [],
                },
            ],
        },


    ]
}

