import {
  CHANGE_FEATURE_FLAGS_SUCCESS,
  CHANGE_FEATURE_FLAGS_ERROR,
  SEND_FAKE_ERROR_HANDLER,
} from 'constants/feature-flag-action-types';

import config from 'constants/apiConfig';
import axios from 'axios';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

const changeFeatureFlagsSuccess = (flags) => ({
  type: CHANGE_FEATURE_FLAGS_SUCCESS,
  payload: flags,
});

function changeFeatureFlagsError(error) {
  const errorMessage = 'changeFeatureFlagsError\n\n' + error;
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: CHANGE_FEATURE_FLAGS_ERROR,
  };
}

export function changeFeatureFlags(flagArray) {
  return (dispatch) => {
    try {
      dispatch(changeFeatureFlagsSuccess(flagArray));
    } catch (error) {
      changeFeatureFlagsError(error);
    }
  };
}

function SendFakeErrorHandler(error) {
  const errorMessage = 'sendFakeErrorHandler\n\n' + error;
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: SEND_FAKE_ERROR_HANDLER,
  };
}

export function sendFakeError() {
  return (dispatch) => {
    return axios(config.apiGateway.sendFakeError, {
      method: 'POST',
    })
      .then(() => {
        // DO NOTHING
      })
      .catch((error) => {
        dispatch(SendFakeErrorHandler(error));
      });
  };
}
