import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import * as dayjs from 'dayjs';
import { fetchAllNotificationLogSummaries } from 'actions/notificationActions';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import moment from 'moment';

export default function SelectSendAttemptDialog({
  show,
  currentLoggedInstance,
  setCurrentLoggedInstance,
  onClose,
}) {
  const reduxDispatch = useDispatch();

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const allNotificationLogSummaries = useSelector((state) => {
    return (
      (state.app.allNotificationLogSummaries &&
        state.app.allNotificationLogSummaries.map((summary) => {
          return {
            ...summary,
            printable_timestamp: moment(
              summary.notification_sent_at_timestamp
            ).format('MM/DD/YYYY h:mm A'),
            template_title: summary.notification_at_time_of_send.template_title,
          };
        })) ||
      []
    );
  });

  useEffect(() => {
    reduxDispatch(
      fetchAllNotificationLogSummaries(reduxCurrentlySelectedGroup.group_guid)
    );
  }, [reduxCurrentlySelectedGroup]);

  const columns = [
    {
      dataField: 'subject',
      text: 'Subject',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Subject' },
    },
    {
      dataField: 'template_title',
      text: 'Template Title',
      sort: true,
      attrs: { title: 'Template Title' },
    },
    {
      dataField: 'printable_timestamp',
      text: 'Date/Time',
      sort: true,
      sortFunc: (...data) => {
        const order = data[2];
        const obja = moment(data[0]).valueOf();
        const objb = moment(data[1]).valueOf();
        if (order === 'asc') {
          return objb - obja;
        }
        return obja - objb; // desc
      },
      attrs: { title: 'Date/Time' },
    },
    {
      dataField: 'user_name',
      text: 'Sent By',
      sort: true,
      attrs: { title: 'Sent By' },
    },
  ];

  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectAll: true,
    selected: !!currentLoggedInstance ? [currentLoggedInstance.id] : [],
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setCurrentLoggedInstance(row);
      } else {
        setCurrentLoggedInstance();
      }
    },
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Sent Notifications</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Select Notification Attempt</h5>
        <StylishNewTable
          keyField={'id'}
          columns={columns}
          rows={allNotificationLogSummaries}
          hasPagination={true}
          paginationPerPageItem="10"
          selectRow={selectRow}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className={'button--primary'}
            onClick={onClose}
            disabled={!currentLoggedInstance}
          >
            Select
          </StylishNewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
