export const IAPCoverSheetConfig = {
    formname: "IAP COVER SHEET",
    filename: "IAPCoverSheet-CG.pdf",
    field: [
        {
            key: '1 Incident Name',
            type: 'Text',
            value: '',
        },
        {
            key: 'OpDateTimeForm',
            type: 'Text',
            value: '123123123',
        },
        {
            key: 'OpDateTimeTo',
            type: 'Text',
            value: '',
        },
        {
            key: 'Preparedby',
            type: 'Text',
            value: '',
        },

        {
            key: 'PreparedByDateTime',
            type: 'Text',
            value: '',
        },
        {
            key: 'ApprovedBy',
            type: 'Table',
            value: [],
        },
        {
            key: 'CheckList',
            type: 'Table',
            value: [],
        },
        {
            key: 'OtherAttachments',
            type: 'Table',
            value: [],
        },
    ]
};