export const SEND_NOTIFICATION_REQUEST = 'SEND_NOTIFICATION_REQUEST';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_ERROR = 'SEND_NOTIFICATION_ERROR';
export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR';
export const UPSERT_NOTIFICATION_REQUEST = 'UPSERT_NOTIFICATION_REQUEST';
export const UPSERT_NOTIFICATION_SUCCESS = 'UPSERT_NOTIFICATION_SUCCESS';
export const UPSERT_NOTIFICATION_ERROR = 'UPSERT_NOTIFICATION_ERROR';
export const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_ERROR = 'DELETE_NOTIFICATION_ERROR';
export const FETCH_NOTIFICATION_LOG_REQUEST = 'FETCH_NOTIFICATION_LOG_REQUEST';
export const FETCH_NOTIFICATION_LOG_SUCCESS = 'FETCH_NOTIFICATION_LOG_SUCCESS';
export const FETCH_NOTIFICATION_LOG_ERROR = 'FETCH_NOTIFICATION_LOG_ERROR';
export const FETCH_NOTIFICATION_LOG_INSTANCE_REQUEST =
  'FETCH_NOTIFICATION_LOG_INSTANCE_REQUEST';
export const FETCH_NOTIFICATION_LOG_INSTANCE_SUCCESS =
  'FETCH_NOTIFICATION_LOG_INSTANCE_SUCCESS';
export const FETCH_NOTIFICATION_LOG_INSTANCE_ERROR =
  'FETCH_NOTIFICATION_LOG_INSTANCE_ERROR';
export const FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_REQUEST =
  'FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_REQUEST';
export const FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_SUCCESS =
  'FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_SUCCESS';
export const FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_ERROR =
  'FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_ERROR';
export const CREATE_DISTRIBUTION_LIST = 'CREATE_DISTRIBUTION_LIST';
export const FETCH_ALL_DISTRIBUTION_LIST = 'FETCH_ALL_DISTRIBUTION_LIST';
export const DELETE_DISTRIBUTION_LIST_ITEM = 'DELETE_DISTRIBUTION_LIST_ITEM';
export const CLEAR_NOTIFICATION_STATUS = 'CLEAR_NOTIFICATION_STATUS'
export const CLEAR_UPSERT_NOTIFICATION_STATUS = 'CLEAR_UPSERT_NOTIFICATION_STATUS';
export const SET_NOTIFICATION_PAGE_DATA = 'SET_NOTIFICATION_PAGE_DATA';
export const CLEAR_NOTIFICATION_PAGE_DATA = 'CLEAR_NOTIFICATION_PAGE_DATA'; 