import { getAllLogsForTask } from 'actions/TaskManagementActions';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function TaskManagementLogs({ task_id }) {
  const reduxDispatch = useDispatch();
  const allLogs = useSelector((state) => state.app.allTaskLogs);
  const { user } = useSelector((state) => {
    return state.app;
  });
  const columns_report_details = [
    {
      text: 'Name',
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <button
                className="button--icon primary weight-700 rounded-circle p-0 me-3"
                style={{
                  pointerEvents: 'none',
                  userSelect: 'none',
                  fontSize: '12px',
                }}
              >
                {(row?.user_name || row.email_address)
                  .match(/(\b\S)?/g)
                  .join('')
                  .match(/(^\S|\S$)?/g)
                  .join('')
                  .toUpperCase()}
              </button>
              {row?.user_name || row.email_address}
            </div>
          </>
        );
      },
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
    },
    {
      dataField: 'log_text',
      text: 'Log',
      sort: true,
      attrs: { title: 'Type' },
    },
    {
      dataField: 'created_at',
      text: 'Created At',
      sort: true,
      attrs: { title: 'Created At' },
      formatter: (cell, row) =>
        moment(row?.created_at).format('MM/DD/YYYY HH:mm'),
    },
  ];

  useEffect(() => {
    if (task_id) {
      reduxDispatch(getAllLogsForTask(task_id));
    }
  }, [task_id]);
  return (
    <div className="mb-4 mb-md-5">
      <StylishNewTable
        keyField={'id'}
        columns={columns_report_details}
        rows={allLogs}
      />
    </div>
  );
}
