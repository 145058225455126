// https://docs.mapbox.com/mapbox-gl-js/example/3d-buildings/
// The 'building' layer in the Mapbox Streets
// vector tileset contains building height data
// from OpenStreetMap.
export const Map3DBuildingsConfig = {
	'id': 'add-3d-buildings',
	'source': 'composite',
	'source-layer': 'building',
	'filter': ['==', 'extrude', 'true'],
	'type': 'fill-extrusion',
	'minzoom': 15,
	'paint': {
	  'fill-extrusion-color': '#aaa',
	   
	  // Use an 'interpolate' expression to
	  // add a smooth transition effect to
	  // the buildings as the user zooms in.
	  'fill-extrusion-height': [
	    'interpolate',
	    ['linear'],
	    ['zoom'],
	    15,
	    0,
	    15.05,
	    ['get', 'height']
	  ],
	  'fill-extrusion-base': [
	    'interpolate',
	    ['linear'],
	    ['zoom'],
	    15,
	    0,
	    15.05,
	    ['get', 'min_height']
	  ],
	  'fill-extrusion-opacity': 0.9
	}
}