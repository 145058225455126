import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import NewDistributionListDialog from 'components/Notifications/NewDistributionListDialog';
import EditDistributionListDialog from 'components/Notifications/EditDistributionListDialog';
import {
  deleteDistributionList,
  editDistributionList,
  createDistributionList,
  fetchAllDistributionList,
} from 'actions/notificationActions';
import { fetchRostersForGroups } from '../../actions/roleActions';
import { fetchNestedCategoryItems } from '../../actions/locationAction';
import { StylishNewChip } from 'components/DesignSystems/New/StylishNewChip';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { ExpandRowProps } from 'react-bootstrap-table-next';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const recipientTypes = [
  'Email',
  'SMS',
  'Pager',
  'Text-To-Speech (cell)',
  'Text-To-Speech (home)',
];
type ListObj = {
  id: string;
  name: string;
};
type RowObj = {
  id: string;
  members: string[];
  nonMembers: { id: string; content: string }[];
  teamLocation?: { location_id: string };
};

const OrgTeams: React.FC = () => {
  const dispatch = useDispatch();
  const [isNewTeamDialogOpen, setIsNewTeamDialogOpen] = useState<boolean>();
  const [isEditTeamDialogOpen, setIsEditTeamDialogOpen] = useState<any>();
  const [distributionLists, setDistributionLists] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState<string | null>(
    null
  );

  const nestedCategoryItems = useSelector((state: any) => {
    return state.app.nestedCategoryItems;
  });

  const nestedCategories = useSelector((state: any) => {
    return state.app.nestedCategories;
  });

  const validateRBACPermission = useSelector((state: any) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const selectedGroup = useSelector((state: any) => {
    return state.app.currentlySelectedGroup;
  });

  const distributionListItems = useSelector((state: any) => {
    return state.app.distributionListItems;
  });

  useEffect(() => {
    if (selectedGroup) {
      dispatch(fetchAllDistributionList(selectedGroup.group_guid));
      dispatch(fetchNestedCategoryItems());
      dispatch(fetchRostersForGroups([selectedGroup.group_guid]));
    }
  }, [selectedGroup]);

  useEffect(() => {
    setDistributionLists(
      distributionListItems.map(
        (item: { teamLocation?: { location_id: string } }) => {
          const foundItem =
            nestedCategoryItems &&
            nestedCategoryItems.find(
              (catItem: { id: string }) =>
                catItem.id === item?.teamLocation?.location_id
            );
          const foundCategory =
            nestedCategories &&
            nestedCategories.find(
              (cat: { id: string }) => cat.id === foundItem?.category_id
            );
          const label = foundItem?.category_item_name
            ? `${foundCategory ? foundCategory.category_name + ' ' : ''} ${
                foundItem?.category_item_name
              }`
            : '';

          return {
            ...item,
            location: label,
          };
        }
      )
    );
  }, [distributionListItems]);

  const handleNewTeam = (list: ListObj) => {
    if (list) {
      dispatch(createDistributionList(list));
    }
    setIsNewTeamDialogOpen(false);
  };

  const handleEditTeam = (list: ListObj) => {
    if (list) {
      dispatch(editDistributionList(list));
    }

    setIsEditTeamDialogOpen(false);
  };

  const handleTeamDeleteClick = (idToDelete: string) => {
    dispatch(deleteDistributionList(idToDelete));
    setShowConfirmDialog(null);
  };

  const handleTeamEditClick = (list: RowObj) => {
    setIsEditTeamDialogOpen(list);
  };

  const roster = useSelector((state: any) => {
    let rosters = state.app.rostersForGroups;
    if (Array.isArray(rosters) && rosters.length) {
      rosters = rosters
        .filter((member) => {
          return member.group_guid === selectedGroup.group_guid;
        })
        .map((member) => {
          return {
            ...member,
            role_string: member.role_assignments.reduce(
              (acc: string, item: { name: string }) => {
                acc = acc ? acc + ', ' + item.name : item.name;
                return acc;
              },
              ''
            ),
          };
        });
    }
    return rosters || [];
  });

  const allColumn = [
    {
      dataField: 'name',
      text: 'Relevant Team',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Relevant Team' },
    },
    {
      dataField: 'location',
      text: 'Area of Responsibility',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Area of Responsibility' },
    },
  ];

  const renderNestedCategory = (props: string) => {
    const foundItem = nestedCategoryItems?.find(
      (item: { id: string }) => item.id === props
    );
    const foundCategory = nestedCategories?.find(
      (cat: { id: string }) => cat.id === foundItem?.category_id
    );
    if (!foundItem) return null;
    return (
      <div className="mt-3">
        <span>
          {`${
            foundCategory ? foundCategory.category_name + ' ' : ''
          } Area of Responsibility : ${foundItem.category_item_name}`}
        </span>
      </div>
    );
  };

  const expandRow: ExpandRowProps<RowObj> = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="form-chip-group">
            {row.members
              .filter((member) =>
                roster.some(
                  (person: { user_guid: string }) => person.user_guid === member
                )
              )
              .map((member) => {
                const user = roster.find(
                  (person: { user_guid: string }) => person.user_guid === member
                );
                const name = user.user_name || user.email_address;
                return (
                  <StylishNewChip
                    key={`key-${member}`}
                    className="notificationComposeScreen-recipientListChip"
                    label={name}
                  />
                );
              })}
            {!!row.nonMembers &&
              row.nonMembers.map((member) => {
                return (
                  <StylishNewChip
                    key={`key-${member.id}`}
                    className="notificationComposeScreen-recipientListChip"
                    label={member.content}
                  />
                );
              })}
          </div>
          {row.teamLocation?.location_id &&
            renderNestedCategory(row?.teamLocation?.location_id)}
          <hr className="dashed my-3" />
          <div className="button-group mt-4">
            <StylishNewButton
              customButton
              type="button"
              className="button--icon primary ms-2"
              onClick={() => handleTeamEditClick(row)}
            >
              <SharedIcon iconName="edit" />
            </StylishNewButton>
            <StylishNewButton
              customButton
              type="button"
              className="button--icon primary ms-2"
              onClick={() => setShowConfirmDialog(row.id)}
            >
              <SharedIcon iconName="delete" />
            </StylishNewButton>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({
      isAnyExpands,
    }: {
      isAnyExpands: boolean;
    }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }: { expanded: boolean }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  return (
    <>
      <div className="d-md-flex align-items-center mb-3">
        <h4 className="m-0">Team Rosters</h4>
        {validateRBACPermission['Create Role'] ? (
          <>
            <StylishNewButton
              className={`button--primary ms-auto mt-2 mt-md-0`}
              onClick={() => setIsNewTeamDialogOpen(true)}
            >
              New Team
            </StylishNewButton>
          </>
        ) : (
          <>
            <div className={'ms-auto text-end'}>
              <StylishNewButton
                className={`button--primary mt-2 mt-md-0`}
                disabled
              >
                New Team
              </StylishNewButton>
              <span className="form-text form-error">
                You don&apos;t have permission.
              </span>
            </div>
          </>
        )}
      </div>
      <div className="form-block">
        <StylishNewTable
          keyField={'id'}
          columns={allColumn}
          rows={distributionLists}
          expandRow={expandRow}
        />
      </div>

      {isNewTeamDialogOpen && (
        <NewDistributionListDialog
          show={isNewTeamDialogOpen}
          roster={roster}
          onClose={(list: ListObj) => handleNewTeam(list)}
          recipientTypes={recipientTypes}
          distributionLists={distributionLists}
        />
      )}

      {isEditTeamDialogOpen && (
        <EditDistributionListDialog
          roster={roster}
          onClose={(list: ListObj) => handleEditTeam(list)}
          selectedDistributionList={isEditTeamDialogOpen}
          distributionLists={distributionLists}
          show={isEditTeamDialogOpen}
          teamLocation={isEditTeamDialogOpen.teamLocation}
        />
      )}
      {!!showConfirmDialog && (
        <StylishConfirmDialog
          show={!!showConfirmDialog}
          dialogTitle={'Delete This Team'}
          dialogContent={'Are you sure you want to delete this team?'}
          onClose={() => setShowConfirmDialog(null)}
          onConfirm={() => handleTeamDeleteClick(showConfirmDialog)}
        />
      )}
    </>
  );
};

export default OrgTeams;
