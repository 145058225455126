import React from 'react';
import dayjs from 'dayjs';

export default function ICSIncidentMedicalTransportation({ row }) {
  return (
    <div className="ICSIncidentMedicalTransportation">
      <div className="mb-4">
        <h6 className="mb-2">Ambulance Service:</h6>
        <p className="m-0">{row.ambulance_service}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Description:</h6>
        <p className="m-0">{row.description}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Address:</h6>
        <p className="m-0">{row.address}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Contact Number:</h6>
        <p className="m-0">{row.contact_number}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Paramedics On Board:</h6>
        <p className="m-0">{row.paramedics_on_board ? 'Yes' : 'No'}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Last Modified By:</h6>
        <p className="m-0">{row.user_name}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Archived:</h6>
        <p className="m-0">{row.archived ? 'Yes' : 'No'}</p>
      </div>
    </div>
  );
}
