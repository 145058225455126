import React, { fetchBriefing } from 'actions/diceActions';
import { getAllObjectiveToIncident } from 'actions/incidentActions';
import EditIncidentDialog from 'components/IncidentConfig/EditIncidentDialog';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { endLoading } from 'reducers/loading/loading.action';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const FormBuilderDashboardIncidentDetails = () => {
  const reduxDispatch = useDispatch();
  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const incidentObjectives = useSelector(
    (state) => state.app.incidentObjectives
  );
  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const isValidateRBACPermissionForActionLoaded = useSelector((state) => {
    return state.app.isValidateRBACPermissionForActionLoaded;
  });
  const briefingLoaded = useSelector((state) => {
    return state.app.isBriefingLoaded;
  });
  const briefing = useSelector((state) => state.app.briefing);
  const user = useSelector((state) => state.prepare.user);

  const [isEditIncidentOpen, setIsEditIncidentOpen] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    if (!!reduxCurrentIncident && !!reduxCurrentIncident.id) {
      reduxDispatch(getAllObjectiveToIncident());
      reduxDispatch(endLoading());
      if (
        isValidateRBACPermissionForActionLoaded &&
        reduxValidateRBACPermissionForActionResult &&
        reduxValidateRBACPermissionForActionResult['Read Pod Data']
      ) {
        reduxDispatch(fetchBriefing());
      }
    }
  }, [reduxCurrentIncident]);

  const onIncidentRenameClick = () => {
    if (reduxCurrentIncident.teams_id) {
      setShowConfirmDialog(true);
    } else {
      setIsEditIncidentOpen(true);
    }
  };

  function onConfirmDialog() {
    setShowConfirmDialog(false);
    setIsEditIncidentOpen(true);
  }

  const timeLabel = () => {
    if (reduxCurrentIncident?.current_period) {
      const timeFormat =
        user.timeFormat === 'hour12Mode'
          ? 'DD/MM/YYYY hh:mm A'
          : 'DD/MM/YYYY HH:mm';

      const startTime = moment(
        reduxCurrentIncident?.operational_period_start_date_time
      ).format(timeFormat);
      const endTime = moment(
        reduxCurrentIncident?.operational_period_start_date_time
      )
        .add(reduxCurrentIncident?.operational_period_interval, 'hours')
        .format(timeFormat);

      return `${startTime} - ${endTime}`;
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="m-0">Incident Details</h4>
        <Dropdown className="flex-shrink-0 ms-auto">
          <Dropdown.Toggle className="button--icon">
            <SharedIcon iconName="more_vert" bold />
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            <Dropdown.Item as="button" onClick={onIncidentRenameClick}>
              <SharedIcon iconName="stylus" />
              Edit Incident Name
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <p className="weight-600">
        <span className="d-block">{reduxCurrentIncident?.name}</span>
        {timeLabel()}
      </p>
      <ul className="list square">
        <li>
          {!!incidentObjectives && incidentObjectives.length > 0
            ? incidentObjectives.length
            : 0}{' '}
          - Objectives
        </li>
      </ul>
      {!!briefing && (
        <p>
          <span className="weight-600 d-block">Incident Current Situation</span>
          {briefing?.situation}
        </p>
      )}
      {isEditIncidentOpen && (
        <EditIncidentDialog
          show={isEditIncidentOpen}
          incident={reduxCurrentIncident}
          onClose={() => setIsEditIncidentOpen(false)}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Incident Rename Confirmation'}
          dialogContent={
            'Renaming the incident will not rename the Team Site. Do you want to proceed with renaming this incident?'
          }
          dialogButtonText={'Continue'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmDialog}
        />
      )}
    </>
  );
};
