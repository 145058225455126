import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../assets/data/config';
import {
  selectGroupGuid,
  selectUserGuid
} from '../../../slices/commonSelectors';

export type CustomResourceType = {
  id: string;
  name: string;
  type: string;
  description?: string;
  group_guid: string;
  archived: boolean;
};

export const keys = {
  base: 'customResources',
  get all() { return [keys.base] },
  list: (groupGuid: string, userId: string) => [keys.base, groupGuid, userId],
} as const;

export type CreateCustomResourceReqData = {
  name: string;
  type: string;
  description?: string;
  group_guid: string;
  archived?: boolean;
};

interface EditCustomResourceReqData {
  id: string;
  name?: string;
  type?: string;
  description?: string;
  group_guid?: string;
  archived?: boolean;
}

export const useCustomResources = () => {
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useSelector(selectGroupGuid);
  return useQuery({
    queryKey: keys.list(group_guid, user_guid),
    queryFn: async () => {
      const resp = await axios.get<CustomResourceType[]>(
        `${config.apiGateway.ics_custom_resource_types}/${group_guid}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
  });
};


export const useCreateCustomResource = () => {
  const user_guid = useSelector(selectUserGuid);
  const queryClient = useQueryClient();
  const group_guid = useSelector(selectGroupGuid);
  const mutation = useMutation({
    mutationFn: async (data: CreateCustomResourceReqData) => {
      return await axios.post(
        `${config.apiGateway.ics_custom_resource_types}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(group_guid, user_guid) });
    },
    onError: (error: any) => {
      toast.error(error.message, toastConfig as any);
    },
  });
  return mutation;
};

export const useEditCustomResource = (customResourceId: string) => {
  const user_guid = useSelector(selectUserGuid);
  const queryClient = useQueryClient();
  const group_guid = useSelector(selectGroupGuid);
  return useMutation({
    mutationFn: async (data: EditCustomResourceReqData) => {
      return await axios.put(
        `${config.apiGateway.ics_custom_resource_types}/${customResourceId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(group_guid, user_guid) });
    },
    onError: (error: any) => {
      toast.error(error.message, toastConfig as any);
    },
  });
};
