export const Config207 = {
  formname: 'ICS 207',
  filename: 'ICS_207.PDF',
  field: [
    {
      key: '1 Incident Name_11',
      type: 'Text',
      value: '',
    },
    {
      key: 'Date From',
      type: 'Text',
      value: '',
    },
    {
      key: 'Date To',
      type: 'Text',
      value: '',
    },
    {
      key: 'Time From',
      type: 'Text',
      value: '',
    },
    {
      key: 'Time To',
      type: 'Text',
      value: '',
    },
    {
      key: 'Organization Chart',
      type: 'OrgChart',
      value: [
        {
          key: 'Incident Commanders_2',
          type: 'List',
          value: [],
        },
        {
          key: 'Liaison Officer_3',
          type: 'List',
          value: [],
        },

        {
          key: 'Safety Officer_4',
          type: 'List',
          value: [],
        },
        {
          key: 'Public Information Officer_2',
          type: 'List',
          value: [],
        },
        {
          key: 'Planning Section Chief_3',
          type: 'List',
          value: [],
        },
        {
          key: 'Operations Section ChiefRow1',
          type: 'List',
          value: [],
        },
        {
          key: 'Staging Area Manager',
          type: 'List',
          value: [],
        },
        {
          key: 'Resources Unit Ldr',
          type: 'List',
          value: [],
        },
        {
          key: 'Situation Unit Ldr',
          type: 'List',
          value: [],
        },
        {
          key: 'Documentation Unit Ldr',
          type: 'List',
          value: [],
        },
        {
          key: 'Demobilization Unit Ldr',
          type: 'List',
          value: [],
        },
        {
          key: 'Support Branch Dir',
          type: 'List',
          value: [],
        },
        {
          key: 'Supply Unit Ldr',
          type: 'List',
          value: [],
        },
        {
          key: 'Facilities Unit Ldr',
          type: 'List',
          value: [],
        },
        {
          key: 'Ground Spt Unit Ldr',
          type: 'List',
          value: [],
        },
        {
          key: 'Time Unit Ldr',
          type: 'List',
          value: [],
        },
        {
          key: 'Procurement Unit Ldr',
          type: 'List',
          value: [],
        },
        {
          key: 'CompClaims Unit Ldr',
          type: 'List',
          value: [],
        },
        {
          key: 'Cost Unit Ldr',
          type: 'List',
          value: [],
        },
        {
          key: 'Service Branch Dir',
          type: 'List',
          value: [],
        },
        {
          key: 'Comms Unit Ldr',
          type: 'List',
          value: [],
        },
        {
          key: 'Medical Unit Ldr',
          type: 'List',
          value: [],
        },
        {
          key: 'Food Unit Ldr',
          type: 'List',
          value: [],
        },
        {
          key: 'FinanceAdmin Section Chief',
          type: 'List',
          value: [],
        },
        {
          key: 'Logistics Section Chief_2',
          type: 'List',
          value: [],
        },
      ],
    },
    {
      key: '4 Prepared by Name_2',
      type: 'Text',
      value: '',
    },
    {
      key: 'PositionTitle_9',
      type: 'Text',
      value: '',
    },
    {
      key: 'Signature_13',
      type: 'Image',
      value: '',
    },
    {
      key: 'DateTime_11',
      type: 'Text',
      value: '',
    },
  ],
};
