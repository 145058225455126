import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import './SubscriptionRequired.css'

export default function SubscriptionRequired() {

  return (
    <div className="SubscriptionRequired">
      <div className="SubscriptionRequired-inner">
        <h1>Premium users only</h1>
        <div>
          Pratus Premium can be purchased at the
          <Link to={'/profile'}>
            &nbsp;Profile Page
          </Link>
        </div>
      </div>
    </div>
  );
}
