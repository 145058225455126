
export const samplePointSource = {
  id: '',
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: []
        },
        generateId: true
      }
    ]
  }
}

export const sampleIconSource = {
  id: '',
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: []
        },
        generateId: true
      }
    ]
  }
}

export const sampleDrawSource = {
  'type': 'geojson',
  'data': {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: []
        },
        generateId: true
      }
    ]
  }
}

export const sampleSources = [
  
]
