import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import {
  getAllIncidentLogs,
  getUserIncidentWorkLogTimes,
  downloadIncidentLogs,
} from 'actions/incidentActions';
import { useDispatch, useSelector } from 'react-redux';
import EditLogDialog from './EditLogDialog';
import moment from 'moment';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { endLoading } from 'reducers/loading/loading.action';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export default function ViewLogDialog({ show, onClose, incident_id }) {
  const reduxDispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(true);
  const [logs, setLogs] = useState([]);
  const [totalhours, setTotalHours] = useState('');
  const [filters, setFilters] = useState([]);

  const incidentLogs = useSelector((state) => {
    return state.app.incidentLogs;
  });

  useEffect(() => {
    reduxDispatch(getAllIncidentLogs(incident_id));
  }, []);

  useEffect(() => {
    if (incidentLogs) {
      const newLogs = [];
      const beforeFilter = [
        ...incidentLogs?.incidentMembersLogs,
        ...incidentLogs.incidentNonMembersLogs,
      ];
      let totalMinWorked = 0;
      if (filters.length === 0) {
        if (incidentLogs) {
          setLogs(
            beforeFilter.map((data) => ({
              ...data,
              keyId: data.user_guid || data.non_member_guid,
            }))
          );
          setTotalHours(incidentLogs?.totalhours);
        }
        return;
      }

      filters.forEach((filter) => {
        const filteredLog = beforeFilter.filter((log) => {
          const parameterName = filter.columnName;
          const parameterValue = log[parameterName];

          if (parameterValue && parameterValue.includes(filter['value'])) {
            totalMinWorked += Number(log.minutes);
            return true;
          }
          return false;
        });
        newLogs.push(...filteredLog);
      });

      const hourInWords =
        totalMinWorked >= 60
          ? `${Math.floor(totalMinWorked / 60)} hour(s) and ${
              totalMinWorked % 60
            } minute(s)`
          : `${totalMinWorked % 60} minute(s)`;
      setTotalHours(hourInWords);
      setLogs(
        newLogs.map((data) => ({
          ...data,
          keyId: data.user_guid || data.non_member_guid,
        }))
      );
    }
  }, [incidentLogs, filters]);

  useEffect(() => {
    if (!isLoaded) {
      onClose();
    }
  }, [isLoaded, onClose]);

  useEffect(() => {
    reduxDispatch(endLoading());
  }, [logs]);

  const reduxValidateRBACPermissionForActionResult =
    useSelector((state) => {
      return state.app.validateRBACPermissionForActionResult;
    }) || [];

  const [editLogDialogOpen, setEditLogDialogOpen] = useState();

  const onExpandRow = (index) => {
    if (index >= incidentLogs?.incidentMembersLogs.length) {
      return;
    }
    reduxDispatch(
      getUserIncidentWorkLogTimes(incidentLogs?.incidentMembersLogs[index])
    );
  };

  const handleDownloadLogs = () => {
    reduxDispatch(downloadIncidentLogs());
  };
  const logFilter = (filterVal, data, field) => {
    let totalMinWorked = 0;
    data.forEach((item) => {
      if (
        filterVal === '' ||
        (item[field] &&
          item[field].toLowerCase().includes(filterVal.toLowerCase()))
      ) {
        totalMinWorked += item.minutes;
      }
    });
    const hourInWords =
      totalMinWorked >= 60
        ? `${Math.floor(totalMinWorked / 60)} hour(s) and ${
            totalMinWorked % 60
          } minute(s)`
        : `${totalMinWorked % 60} minute(s)`;
    setTotalHours((old) => (old !== hourInWords ? hourInWords : old));
  };
  const hourSortFilter = (filterVal, data, field) => {};
  const allColumn = [
    {
      dataField: 'email_address',
      text: 'Incident Member',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Incident Member' },
      filter: textFilter({
        onFilter: (filterVal, data) =>
          logFilter(filterVal, data, 'email_address'),
      }),
    },
    {
      dataField: 'location',
      text: 'Location',
      sort: true,
      attrs: { title: 'Location' },
      filter: textFilter({
        onFilter: (filterVal, data) => logFilter(filterVal, data, 'location'),
      }),
    },
    {
      dataField: 'minutes_worked_in_words',
      text: 'Hours',
      sort: true,
      sortFunc: (...data) => {
        const order = data[2];
        const obja = data[4];
        const objb = data[5];
        if (order === 'asc') {
          return objb.minutes - obja.minutes;
        }
        return obja.minutes - objb.minutes; // desc
      },
      attrs: { title: 'Hours' },
      filter: textFilter({
        onFilter: (filterVal, data) =>
          logFilter(filterVal, data, 'minutes_worked_in_words'),
      }),
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div
              className={
                row.login_time && row.logout_time ? 'col-md-4' : 'col-md-6'
              }
            >
              <h6>Profile</h6>
              <span className="d-block">
                Phone (Mobile): {row.profile_settings?.mobile_phone_number}
              </span>
              <span className="d-block">
                Phone (Home): {row.profile_settings?.home_phone_number}
              </span>
              <span className="d-block">
                Pager: {row.profile_settings?.pager_address}
              </span>
            </div>
            <div
              className={`mt-4 mt-md-0 ${
                row.login_time && row.logout_time ? 'col-md-4' : 'col-md-6'
              }`}
            >
              <div className="mb-3">
                <h6>Incident Seat</h6>
                <span className="d-block">{row.incident_seat}</span>
              </div>
              <div className="m-0">
                <h6>Notes</h6>
                <span className="d-block">{row.notes}</span>
              </div>
            </div>
            {row.login_time && row.logout_time && (
              <>
                <div className="col-md-4 mt-4 mt-md-0">
                  <h6>Login &amp; Logout</h6>
                  {moment(row.login_time).format('MM/DD/YYYY @ HH:mm')} -{' '}
                  {moment(row.logout_time).format('MM/DD/YYYY @ HH:mm')}
                  {/* We aren't yet ready to allow editing of non-member logs
                  {reduxValidateRBACPermissionForActionResult[
                    'Add Member to Incident'
                  ] && (
                    <span
                      className="anchor ms-2"
                      onClick={() => setEditLogDialogOpen({ log: {}, row })}
                    >
                      Edit
                    </span>
                  )} */}
                </div>
              </>
            )}
            {row.logs && row.logs.length > 0 && (
              <div className="col-md-12 mt-4">
                <table className="table no-filter">
                  <thead>
                    <tr className="empty">
                      <th scope="col" style={{ width: '50px' }}>
                        #
                      </th>
                      <th scope="col">Login</th>
                      <th scope="col">Logout</th>
                      <th scope="col">Last Edited</th>
                      <th
                        scope="col"
                        className="text-md-center"
                        style={{ width: '100px' }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {row.logs.map((log, idx) => {
                      return (
                        <>
                          <tr key={log.work_log_id}>
                            <td title="#">{idx + 1}</td>
                            <td title="Login">
                              {moment(log.login_time).format(
                                'MM/DD/YYYY @ HH:mm'
                              )}
                            </td>
                            <td title="Logout">
                              {moment(log.logout_time).format(
                                'MM/DD/YYYY @ HH:mm'
                              )}
                            </td>
                            <td title="Last Edited">
                              {reduxValidateRBACPermissionForActionResult[
                                'Add Member to Incident'
                              ] &&
                              !!log &&
                              !!row &&
                              log.edited_by_user_guid ? (
                                <>
                                  {moment(log.edit_time).format(
                                    'MM/DD/YYYY @ HH:mm'
                                  )}
                                </>
                              ) : (
                                <>-</>
                              )}
                            </td>
                            <td title="Action" className="text-md-center">
                              {reduxValidateRBACPermissionForActionResult[
                                'Add Member to Incident'
                              ] &&
                              !!log &&
                              !!row ? (
                                <>
                                  <StylishNewButton
                                    customButton
                                    className="button--icon"
                                    onClick={() =>
                                      setEditLogDialogOpen({ log })
                                    }
                                  >
                                    <SharedIcon iconName="stylus" />
                                  </StylishNewButton>
                                </>
                              ) : (
                                <>-</>
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
    onExpand: (row, isExpand, rowIndex, e) => {
      onExpandRow(rowIndex);
    },
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>View Log</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {logs ? (
            <>
              <p className="weight-500 mb-4">
                Aggregate hours by active filter:{' '}
                {totalhours !== '' && totalhours}
              </p>
              <StylishNewTable
                classes={'with-filter-sort'}
                keyField={'keyId'}
                rows={logs}
                columns={allColumn}
                expandRow={expandRow}
                filter={filterFactory()}
              />
            </>
          ) : (
            <>
              <p className="m-0">Loading...</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            {logs && (
              <StylishNewButton
                className="button--primary"
                onClick={handleDownloadLogs}
              >
                Download Logs
              </StylishNewButton>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      {editLogDialogOpen && (
        <EditLogDialog
          show={editLogDialogOpen}
          log={editLogDialogOpen.log}
          onClose={() => setEditLogDialogOpen(false)}
        />
      )}
    </>
  );
}
