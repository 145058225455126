import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mapboxgl from '!mapbox-gl';

import * as turf from '@turf/turf';
import Accordion from 'react-bootstrap/Accordion';
import { MdLocationOn, MdOutlineArticle } from 'react-icons/md';

import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

import IconClose from '../../../assets/images/icon__times.svg';
import IconArrowRight from '../../../assets/images/icon__arrow--right.svg';
import IconArrowLeft from '../../../assets/images/icon__arrow--left.svg';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput.js';
import incidentReportEffect from '../mapEffects/incidentReportEffect';
import { getAllInitialIncidentReport } from 'actions/ReportActions';
import { fetchNestedCategoryItems } from 'actions/locationAction';
import IncidentReportDetails from '../mapComponents/IncidentReportDetails';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { SharedIcon } from '../../SharedIcon/SharedIcon';
import { useNavigate } from 'react-router-dom';

const MapIncidentReport = (props) => {
  const {
    map,
    mapHasLoaded,
    apiPrefix,
    selectedReport,
    setSelectedReport,
    setLayerClicked,
    onClose,
  } = props;

  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  // Selector variables
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });
  const reduxNestedCategoryItems = useSelector((state) => {
    return state.app.nestedCategoryItems;
  });
  const allInitialIncidentReport = useSelector((state) => {
    return state.app.allInitialIncidentReport;
  });

  // State Declarations
  const [showSidebarSecondary, setShowSidebarSecondary] = useState(false);
  const [
    incidentReportsVisibleToggle,
    setIncidentReportsVisibleToggle,
  ] = useState(false);
  const [reportSearchInput, setReportSearchInput] = useState('');
  const [markers, setMarkers] = useState([]);

  // functions
  const incidentReportData = useMemo(() => {
    return (allInitialIncidentReport || []).map((item) => {
      return {
        ...item,
        incident_type: item.incident_types
          ? item.incident_types.join(', ')
          : item.incidentType,
        alreadyEscalated: item.current_status.includes('Escalated'),
      };
    });
  }, [allInitialIncidentReport]);

  const {
    groupedReportByCategory,
    filteredIncidentReportData,
  } = useMemo(() => {
    let filteredIncidentReportData = [];
    if (!!incidentReportData && !!incidentReportData.length) {
      filteredIncidentReportData = incidentReportData.filter((d) =>
        d.incident_name.toLowerCase().includes(reportSearchInput.toLowerCase())
      );
    }

    // sort nexted category items
    let sortedCategoryItems = [];
    if (reduxNestedCategoryItems && reduxNestedCategoryItems?.length > 0) {
      sortedCategoryItems = reduxNestedCategoryItems.sort((a, b) =>
        a.category_item_name.localeCompare(b.category_item_name)
      );
    }

    // grouped reports
    let groupedReportData = filteredIncidentReportData.reduce(
      (groups, report) => {
        const category = sortedCategoryItems.find(
          (categoryItem) => categoryItem.id === report?.aor_id
        );
        const categoryName = category?.category_item_name || 'None-Category';
        if (!groups[categoryName]) {
          groups[categoryName] = [];
        }

        groups[categoryName].push({
          ...report,
          geo_location: category?.geo_location,
          aor_location: category?.aor_location,
          categoryName: categoryName,
        });

        return groups;
      },
      {}
    );

    const groupedReportByCategory = Object.keys(groupedReportData)
      .sort()
      .reduce((acc, key) => {
        acc[key] = groupedReportData[key].sort((a, b) =>
          a.incident_name.localeCompare(b.incident_name)
        );
        return acc;
      }, {});

    return { groupedReportByCategory, filteredIncidentReportData };
  }, [reduxNestedCategoryItems, incidentReportData, reportSearchInput]);

  const handleClickClose = () => {
    onClose();
  };

  const sidebarSecondaryHandler = (item) => {
    setShowSidebarSecondary(true);
    setSelectedReport(item);
  };

  const flyToGeolocation = (item) => {
    if (
      !!item.geo_location &&
      item.geo_location.geojson &&
      item.geo_location.geojson.data &&
      item.geo_location.geojson.data.features &&
      item.geo_location.geojson.data.features.length
    ) {
      var box = turf.bbox(item.geo_location.geojson.data.features[0].geometry);
      map.current.fitBounds(box, { padding: 50, maxZoom: 15 });
    }
  };

  // useEffect
  useEffect(() => {
    if (reduxCurrentlySelectedGroup?.group_guid) {
      reduxDispatch(fetchNestedCategoryItems());
      reduxDispatch(getAllInitialIncidentReport());
    }
  }, [reduxCurrentlySelectedGroup]);

  useEffect(() => {
    if (selectedReport) {
      setShowSidebarSecondary(true);
    }
  }, selectedReport);

  useEffect(() => {
    if (
      map?.current &&
      mapHasLoaded &&
      !!reduxCurrentlySelectedGroup &&
      !!filteredIncidentReportData?.length
    ) {
      incidentReportEffect(
        map,
        mapboxgl,
        filteredIncidentReportData,
        apiPrefix,
        reduxCurrentlySelectedGroup,
        incidentReportsVisibleToggle,
        setLayerClicked,
        markers,
        setMarkers
      );
    }
  }, [
    map,
    mapHasLoaded,
    reduxCurrentlySelectedGroup,
    incidentReportsVisibleToggle,
    mapboxgl,
    filteredIncidentReportData,
  ]);

  function createIncidentReportButtonClicked()
  {
    navigate(`/report/new`, { replace: true });
  }

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        {showSidebarSecondary && (
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={() => {
              setSelectedReport(null);
              setShowSidebarSecondary(false);
            }}
          >
            <img src={IconArrowLeft} alt="" />
          </StylishNewButton>
        )}
        <h4 className="m-0">Incident Report</h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={() => handleClickClose()}
        >
          <img src={IconClose} alt="" />
        </StylishNewButton>
      </div>
      <div className="sidebar-inner">
        {!showSidebarSecondary ? (
          <>
            <div className="d-flex align-items-center mb-4">
              <Link to="/report/new" className="button button--primary button--sml ms-auto">
                Create Incident Report
                <SharedIcon iconName="open_in_new" classes="ms-2" />
              </Link>
            </div>
            <div className="d-flex align-items-center mb-3">
              <span>Show Incident Reports</span>
              <StylishSwitcher
                className="ms-auto"
                checked={incidentReportsVisibleToggle}
                onChange={() =>
                  setIncidentReportsVisibleToggle(!incidentReportsVisibleToggle)
                }
              />
            </div>
            <StylishNewInput
              placeholder="Search Incident Report..."
              value={reportSearchInput}
              onChange={(e) => setReportSearchInput(e.target.value)}
              className={'mb-4'}
            />
            <div className="scroll scroll-events">
              {!!incidentReportData &&
              !!incidentReportData.length &&
              Object.keys(groupedReportByCategory).length > 0 ? (
                <Accordion defaultActiveKey="0" className="standard-accordion">
                  {Object.keys(groupedReportByCategory).map(
                    (category, index) => {
                      let categoryName = category;
                      return (
                        <Accordion.Item
                          eventKey={index}
                          key={'category-' + index}
                          className="mt-0"
                        >
                          <Accordion.Header className="border-b">
                            {categoryName} (
                            {groupedReportByCategory[category].length})
                          </Accordion.Header>
                          <Accordion.Body>
                            {groupedReportByCategory[category].map(
                              (item, idx) => {
                                let incident_name = item.incident_name;
                                return (
                                  <div
                                    key={'filteredEth-' + idx}
                                    className={'report-' + idx}
                                  >
                                    {idx !== 0 ? (
                                      <hr
                                        key={idx}
                                        className="dashed thin w-10 m-0"
                                      />
                                    ) : null}
                                    <div
                                      className={`d-flex align-items-center cursor-pointer py-3`}
                                      key={item.id}
                                      onClick={() => {
                                        sidebarSecondaryHandler(item);
                                      }}
                                    >
                                      <span style={{ color: 'unset' }}>
                                        <MdOutlineArticle className="img-h-24 me-3" />
                                      </span>
                                      <span className="text-truncate">
                                        {incident_name}
                                      </span>
                                      <img
                                        src={IconArrowRight}
                                        alt=""
                                        className="ms-auto img-h-16"
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    }
                  )}
                </Accordion>
              ) : (
                <p className="m-0">No Incident Report Found</p>
              )}
            </div>
          </>
        ) : (
          <div className="tab-wrapper map-tab-container">
            <div
              className="anchor anchor--white cursor-pointer d-flex align-items-center bg-gray-900 p-3 rounded mb-3"
              onClick={() => flyToGeolocation(selectedReport)}
            >
              <MdLocationOn className="img-h-20 me-3" />
              {selectedReport?.geo_location?.centroid?.geometry?.coordinates ? (
                selectedReport?.geo_location?.name ? (
                  <>
                    {selectedReport?.geo_location?.name ||
                      selectedReport?.categoryName}
                  </>
                ) : (
                  <>{'Click to Zoom'}</>
                )
              ) : (
                <></>
              )}
            </div>
            <IncidentReportDetails report={selectedReport} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MapIncidentReport;
