import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { PropsWithChildren, useContext } from 'react';
import { Card } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';

interface Props {
  eventKey: string;
  hasIcon?: boolean;
}

export const AccordionHeader = ({
  children,
  eventKey,
  hasIcon = true,
}: PropsWithChildren<Props>) => {
  const { activeEventKey } = useContext(AccordionContext);
  const handleClick = useAccordionButton(eventKey);

  return (
    <Card.Header className="cursor-pointer border-0 p-3" onClick={handleClick}>
      <div className="d-flex align-items-center">
        {hasIcon && activeEventKey !== eventKey ? (
          <SharedIcon iconName="arrow_right" />
        ) : null}

        {hasIcon && activeEventKey === eventKey ? (
          <SharedIcon iconName="arrow_drop_down" />
        ) : null}

        <div className="flex-grow-1">{children}</div>
      </div>
    </Card.Header>
  );
};
