import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconUpload from 'images/icons/icon__upload.svg';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import {
  deleteIapChecklistAttachment,
  deleteIapCoverSheetAttachment,
  deleteIapCoverSheetAttachmentApprovedByIC,
  getIapCoverSheet,
  upsertIapChecklistAttachment,
  upsertIapCoverSheet,
  upsertIapCoverSheetAttachment,
  upsertIapCoverSheetAttachmentApprovedByIC,
} from 'actions/iapCoverSheetAction';
import generateUUID from 'utils/sharedUtils/generateUUID';
import axios from 'axios';
import apiConfig from 'constants/apiConfig';
import IconFile from 'images/icons/icon__file.svg';
import IconClose from 'images/icons/icon__times.svg';
import EditOperationalPeriodDialog from 'components/IncidentConfig/EditOperationalPeriodDialog';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { getRosterForIncident } from 'actions/incidentActions';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { toast } from 'react-toastify';
import { Dropdown } from 'react-bootstrap';
import { generateSignedUrl } from 'actions/diceActions';
import { getPlanningWorksheet } from 'actions/IAPForm204Actions';
import { IAPCoverSheetConfig } from './FormTemplates/FormConfig/IAPCoverSheetConfig';
import { toastConfig } from 'assets/data/config';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export default function IAPCoverSheet({
  formRecordData,
  onFormRecordDataUpdate,
}) {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const {
    control,
    formState: { errors },
    setValue,
  } = useForm({ mode: 'onChange' });

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxRosterForIncident = useSelector((state) => {
    return state.app.rosterForIncident;
  });

  const reduxUser = useSelector((state) => {
    return state.app.user;
  });
  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });
  const allFormRows = [
    {
      id: '1',
      name: 'ICS 202-CG (Incident Objectives)',
      url: '/incident_management/ICS_202-CG',
      hasUpload: false,
      hasView: true,
      hasDelete: false,
    },
    {
      id: '2',
      name: 'ICS 202A-CG (Command Direction)',
      hasUpload: true,
      hasView: false,
      hasDelete: false,
    },
    {
      id: '3',
      name:
        'ICS 203-CG (Organization List) – OR – ICS 207-CG (Organization Chart)',
      url: '/incident_configuration',
      hasUpload: false,
      hasView: true,
      hasDelete: false,
    },
    {
      id: '4',
      name:
        'ICS 204-CGs (Assignment Lists) One Copy each of any ICS 204-CG attachments',
      url: '/incident_management/ICS_204-CG',
      hasUpload: false,
      hasView: true,
      hasDelete: false,
    },
    {
      id: '6',
      name: 'ICS 206-CG (Medical Plan)',
      url: '/incident_management/ICS_206-CG',
      hasUpload: false,
      hasView: true,
      hasDelete: false,
    },
    {
      id: '7',
      name: 'ICS 208-CG (Site Safety and Health Plan)',
      url: '/incident_management/ICS_208-CG',
      hasUpload: false,
      hasView: true,
      hasDelete: false,
    },
    {
      id: '8',
      name: 'Map / Chart',
      hasUpload: true,
      hasView: false,
      hasDelete: false,
    },
    {
      id: '9',
      name: 'Weather Forecast / Tides/Currents',
      hasUpload: true,
      hasView: false,
      hasDelete: false,
    },
  ];

  const columns_report_data = [
    {
      dataField: 'org',
      text: 'Org',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Incident Name' },
    },
    {
      dataField: 'name',
      text: 'name',
      sort: true,
      attrs: { title: 'Incident Type' },
    },
    {
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Incident Commander"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={() => onDeleteApprovedBy(row)}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];
  const iapCoverSheet = useSelector((state) => state.app.iapCoverSheet);
  const [deleteConfirmationType, setDeleteConfirmationType] = useState(null);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState(null);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [planningSectionChiefs, setPlanningSectionChiefs] = useState([]);
  const [isUserPSC, setIsUserPSC] = useState(false);
  const [isUserIC, setIsUserIC] = useState(false);
  const [isUserAlreadyApproved, setIsUserAlreadyApproved] = useState(false);
  const [approvedByUsers, setApprovedByUsers] = useState([]);
  const [deleteCheckListFileData, setDeleteCheckListFileData] = useState(null);
  const [allFormsWithData, setAllFormsWithData] = useState([]);
  const [selectedForm, setSelectedForm] = useState([]);
  const [formState, setFormState] = useState(IAPCoverSheetConfig);
  const [isPrepared, setIsPrepared] = useState(false);

  const [
    isEditOperationalPeriodOpen,
    setIsEditOperationalPeriodOpen,
  ] = useState(false);
  useEffect(() => {
    if (!!reduxCurrentIncident) {
      setValue('incident_name', reduxCurrentIncident.name);
      updateFormState('1 Incident Name', reduxCurrentIncident.name);
      if (!!reduxCurrentIncident.current_period) {
        updateFormState(
          'OpDateTimeForm',
          moment(
            reduxCurrentIncident.operational_period_start_date_time
          ).format('YYYY-MM-DD HH:mm')
        );
        updateFormState(
          'OpDateTimeTo',
          moment(reduxCurrentIncident.operational_period_start_date_time)
            .add(reduxCurrentIncident.operational_period_interval, 'hours')
            .format('YYYY-MM-DD HH:mm')
        );
        reduxDispatch(getIapCoverSheet());
        reduxDispatch(getPlanningWorksheet());
      }
    }
    reduxDispatch(getRosterForIncident());
  }, [reduxCurrentIncident]);
  useEffect(() => {
    if (!!iapCoverSheet) {
      setIsPrepared(iapCoverSheet?.is_prepared);
      if (!!iapCoverSheet?.prepared_by_date_time) {
        updateFormState(
          'PreparedByDateTime',
          moment(iapCoverSheet?.prepared_by_date_time).format(
            'YYYY-MM-DD HH:mm'
          )
        );
        setValue(
          'prepared_by_date_time',
          moment(iapCoverSheet?.prepared_by_date_time).format(
            'YYYY-MM-DD HH:mm'
          )
        );
      }
      if (!!iapCoverSheet?.prepared_by_user_guid) {
        updateFormState(
          'Preparedby',
          iapCoverSheet?.prepared_by_profile_settings?.userName ||
            iapCoverSheet?.prepared_by_email_address
        );
        setValue('prepared_by', {
          value: iapCoverSheet?.prepared_by_user_guid,
          label:
            iapCoverSheet?.prepared_by_profile_settings?.userName ||
            iapCoverSheet?.prepared_by_email_address,
        });
      }
      const approvedByUsersPdf = [];
      const approvedByUsers = iapCoverSheet?.approved_by_ic.map(
        (a_user, index) => {
          approvedByUsersPdf.push({
            key: `ORG ${index + 1}`,
            value: reduxCurrentlySelectedGroup.group_name,
            type: 'Text',
          });
          approvedByUsersPdf.push({
            key: `NAME ${index + 1}`,
            value: a_user.profile_settings?.userName || a_user?.email_address,
            type: 'Text',
          });
          return {
            ...a_user,
            org: reduxCurrentlySelectedGroup.group_name,
            name: a_user.profile_settings?.userName || a_user?.email_address,
          };
        }
      );
      setApprovedByUsers(approvedByUsers);
      updateFormState('ApprovedBy', approvedByUsersPdf);
      if (isUserIC) {
        const u = iapCoverSheet?.approved_by_ic.find(
          (a_user) => a_user.user_guid === reduxUser.user_guid
        );
        if (!!u) {
          setIsUserAlreadyApproved(true);
        } else {
          setIsUserAlreadyApproved(false);
        }
      } else {
        setIsUserAlreadyApproved(false);
      }
      let currentlySelectedForm = [...selectedForm];
      if (
        iapCoverSheet.uploaded_form &&
        iapCoverSheet.uploaded_form.length > 0
      ) {
        const updatedllFormsWithData = allFormRows.map((form) => {
          if (currentlySelectedForm.includes(form.id)) {
            currentlySelectedForm = currentlySelectedForm.filter(
              (f) => f !== form.id
            );
          }
          const formWithFiles = iapCoverSheet.uploaded_form.find(
            (uploadedForm) => uploadedForm.form_name === form.name
          );
          if (!!formWithFiles) {
            currentlySelectedForm.push(form.id);
            return {
              ...form,
              file_url: formWithFiles.file_url,
              file_id: formWithFiles.id,
              hasUpload: false,
              hasView: true,
              hasDelete: true,
            };
          } else {
            return form;
          }
        });
        setAllFormsWithData(updatedllFormsWithData);
      } else {
        currentlySelectedForm = currentlySelectedForm.filter(
          (f) => f !== '2' || f !== '8' || f !== '9'
        );
        setAllFormsWithData(allFormRows);
      }

      const checkListPdf = [];
      const checkedForms = iapCoverSheet.iap_checklist
        ? iapCoverSheet.iap_checklist?.map((name) => {
            return allFormRows.find((form) => form.name === name).id;
          })
        : [];
      [...currentlySelectedForm, ...checkedForms].forEach((formId) => {
        switch (formId) {
          case '1':
            checkListPdf.push({
              key: 'ICS 202CG Incident Objectives',
              value: true,
              type: 'Check',
            });
            break;
          case '2':
            checkListPdf.push({
              key: 'ICS 202ACG Command Direction',
              value: true,
              type: 'Check',
            });
            break;
          case '3':
            checkListPdf.push({
              key:
                'ICS 203CG Organization List  OR  ICS 207CG Organization Chart',
              value: true,
              type: 'Check',
            });
            break;
          case '4':
            checkListPdf.push({
              key: 'ICS 204CGs Assignment Lists',
              value: true,
              type: 'Check',
            });
            break;
          case '5':
            checkListPdf.push({
              key: 'ICS 205CG Communications Plan',
              value: true,
              type: 'Check',
            });
            break;
          case '6':
            checkListPdf.push({
              key: 'ICS 206CG Medical Plan',
              value: true,
              type: 'Check',
            });
            break;
          case '7':
            checkListPdf.push({
              key: 'ICS 208CG Site Safety Plan or Note SSP Location',
              value: true,
              type: 'Check',
            });
            break;
          case '8':
            checkListPdf.push({
              key: 'Map  Chart',
              value: true,
              type: 'Check',
            });
            break;
          case '9':
            checkListPdf.push({
              key: 'Weather Forecast  TidesCurrents',
              value: true,
              type: 'Check',
            });
            break;
        }
      });

      setSelectedForm([...currentlySelectedForm, ...checkedForms]);
      updateFormState('CheckList', checkListPdf);
      if (!!iapCoverSheet && iapCoverSheet.attachments.length > 0) {
        const updatedAttachments = iapCoverSheet.attachments.map(
          (attachment, index) => {
            return {
              key: `Other Attachments ${index + 1}`,
              value: attachment.name,
              type: 'Text',
            };
          }
        );
        updateFormState('OtherAttachments', updatedAttachments);
      } else {
        updateFormState('OtherAttachments', []);
      }
    } else {
      setAllFormsWithData(allFormRows);
    }
  }, [iapCoverSheet, isUserIC]);
  useEffect(() => {
    if (!!reduxRosterForIncident && reduxRosterForIncident.length > 0) {
      const pSectionChiefs = reduxRosterForIncident
        .filter((users) => users.incident_seat === 'Planning Section Chief')
        .map((users) => ({
          value: users,
          label:
            users.profile_settings.userName ||
            users.profile_settings.email_address,
        }));
      setPlanningSectionChiefs(pSectionChiefs);
    }
  }, [reduxRosterForIncident]);
  useEffect(() => {
    if (!!reduxUser && !!reduxRosterForIncident) {
      const user = reduxRosterForIncident.find(
        (incidentUser) => incidentUser.user_guid === reduxUser.user_guid
      );
      if (!!user) {
        if (user.incident_seat === 'Planning Section Chief') {
          setIsUserPSC(true);
        } else {
          setIsUserPSC(false);
        }
        if (user.incident_seat === 'Incident Commander') {
          setIsUserIC(true);
        } else {
          setIsUserIC(false);
        }
      } else {
        setIsUserPSC(false);
      }
    }
  }, [reduxUser, reduxRosterForIncident]);
  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('group_guid', reduxCurrentlySelectedGroup?.group_guid);
    try {
      reduxDispatch(startLoading());
      const resp = await axios.post(apiConfig.apiGateway.uploadFile, formData);
      reduxDispatch(
        upsertIapCoverSheetAttachment({
          id: generateUUID(),
          name: file.name,
          url: resp.data.fileUrl,
        })
      );
    } catch (error) {
      //Error File Upload
      toast.error('Error Uploading File', toastConfig);
      reduxDispatch(endLoading());
    }
  };
  const onDeleteApprovedBy = (row) => {
    setDeleteConfirmationType('Approved By');
    setDeleteConfirmationText(
      'Are you sure you want to delete this Approved By?'
    );
    setDeleteConfirmationData(row);
    setShowConfirmDialog(true);
  };
  const onConfirmDeleteApprovedByIC = () => {
    reduxDispatch(
      deleteIapCoverSheetAttachmentApprovedByIC(
        deleteConfirmationData?.incident_commander_id,
        deleteConfirmationData?.iap_cover_sheet_id
      )
    );
    setShowConfirmDialog(false);
  };
  const onConfirmedDeleteFile = () => {
    reduxDispatch(deleteIapCoverSheetAttachment(deleteConfirmationData?.id));
    setShowConfirmDialog(false);
  };
  const onMarkAsPrepired = () => {
    reduxDispatch(
      upsertIapCoverSheet({
        ...iapCoverSheet,
        id: iapCoverSheet?.id || generateUUID(),
        is_prepared: true,
        prepared_by: reduxUser.user_guid,
        prepared_by_date_time: moment().toISOString(),
      })
    );
  };
  const onMarkAsReviewd = () => {
    if (!iapCoverSheet || !iapCoverSheet.is_prepared) {
      toast.error('This IAP Cover Sheet is not prepared yet', toastConfig);
      return;
    }
    reduxDispatch(
      upsertIapCoverSheetAttachmentApprovedByIC(
        reduxUser.user_guid,
        iapCoverSheet.id
      )
    );
  };
  const onUploadFormFile = async (file, row) => {
    if (!!iapCoverSheet?.is_prepared) {
      toast.error(
        'This IAP Cover Sheet is already prepared. You cannot upload the files',
        toastConfig
      );
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('group_guid', reduxCurrentlySelectedGroup.group_guid);
    try {
      reduxDispatch(startLoading());
      const resp = await axios.post(apiConfig.apiGateway.uploadFile, formData);
      reduxDispatch(
        upsertIapChecklistAttachment({
          id: generateUUID(),
          form_name: row.name,
          file_url: resp.data.fileUrl,
        })
      );
    } catch (error) {
      //Error File Upload
      toast.error('Error Uploading File', toastConfig);
      reduxDispatch(endLoading());
    }
  };
  const onDeleteUploadFile = (formData) => {
    if (!!iapCoverSheet?.is_prepared) {
      toast.error(
        'This IAP Cover Sheet is already prepared. You cannot delete the uploaded files',
        toastConfig
      );
      return;
    }
    setDeleteCheckListFileData(formData);
    setDeleteConfirmationType('Checklist File');
    setDeleteConfirmationText('Are you sure you want to delete this Form?');
    setShowConfirmDialog(true);
  };
  const onConfirmDeleteUploadFile = () => {
    reduxDispatch(
      deleteIapChecklistAttachment(deleteCheckListFileData.file_id)
    );
    setDeleteCheckListFileData(null);
    setDeleteConfirmationText('');
    setShowConfirmDialog(false);
  };

  const onViewForm = async (formData) => {
    if (formData.url) {
      navigate(formData.url);
      return;
    }
    const d = await reduxDispatch(generateSignedUrl(formData.file_url.trim()));
    window.open(d, '_blank');
  };

  const allFormColumns = [
    {
      dataField: 'name',
      text: 'Name',
      attrs: { title: 'Incident Name' },
    },
    {
      text: 'Action',
      isDummyField: true,
      formatExtraData: iapCoverSheet,
      formatter: (cell, row, rowIndex, formatExtraData) =>
        row.hasUpload || row.hasDelete || row.hasView ? (
          <Dropdown className="flex-shrink-0 ms-auto">
            {formatExtraData?.is_prepared && !row.hasView ? null : (
              <>
                <Dropdown.Toggle className="button--icon">
                  <SharedIcon iconName="more_vert" bold />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <>
                    {row.hasDelete && !formatExtraData?.is_prepared && (
                      <Dropdown.Item
                        key={row.id + 'Delete'}
                        as="button"
                        onClick={() => onDeleteUploadFile(row)}
                      >
                        Delete Uploaded File
                      </Dropdown.Item>
                    )}
                    {!formatExtraData?.is_prepared && row.hasView ? (
                      <Dropdown.Item
                        key={row.id + 'View'}
                        as="button"
                        onClick={() => onViewForm(row)}
                      >
                        View
                      </Dropdown.Item>
                    ) : (
                      !row.hasUpload && (
                        <Dropdown.Item
                          key={row.id + 'View'}
                          as="button"
                          onClick={() => onViewForm(row)}
                        >
                          View
                        </Dropdown.Item>
                      )
                    )}
                    {row.hasUpload && !formatExtraData?.is_prepared && (
                      <Dropdown.Item
                        as="button"
                        className="dropdown-item__upload"
                        key={row.id + 'Upload'}
                      >
                        <input
                          key={row.id + 'Upload Input'}
                          type="file"
                          onInput={(e) =>
                            onUploadFormFile(e.target.files[0], row)
                          }
                        />
                        Upload File
                      </Dropdown.Item>
                    )}
                  </>
                </Dropdown.Menu>
              </>
            )}
          </Dropdown>
        ) : null,
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];
  const selectRowForm = {
    mode: 'checkbox',
    clickToSelect: false,
    hideSelectAll: true,
    selected: selectedForm,
    nonSelectable:
      iapCoverSheet && !!iapCoverSheet.is_prepared
        ? ['1', '2', '3', '4', '5', '6', '7', '8', '9']
        : ['2', '8', '9'],

    selectionHeaderRenderer: ({ mode, ...rest }) => {
      return (
        <div className="form-checkbox align-items-center">
          <input type={mode} {...rest} disabled />
          <span className="icon"></span>
        </div>
      );
    },
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} disabled />
        <span className="icon"></span>
      </div>
    ),

    onSelect: (row, isSelect) => {
      let oldSelectedForm = [...selectedForm];
      if (isSelect) {
        oldSelectedForm = [...oldSelectedForm, row.id];
      } else {
        oldSelectedForm = oldSelectedForm.filter((x) => x !== row.id);
      }
      setSelectedForm(oldSelectedForm);
      const selectedFormName = allFormRows
        .filter(
          (x) =>
            x.id !== '2' &&
            x.id !== '8' &&
            x.id !== '9' &&
            oldSelectedForm.includes(x.id)
        )
        .map((x) => x.name);
      reduxDispatch(startLoading());
      reduxDispatch(
        upsertIapCoverSheet({
          ...iapCoverSheet,
          id: iapCoverSheet?.id || generateUUID(),
          iap_checklist: selectedFormName,
        })
      );
    },
  };

  const updateFormState = (key, value) => {
    const index = formState.field.findIndex((field) => field.key === key);
    if (index !== -1) {
      let obj = { ...formState.field[index] };
      obj = { ...obj, value: value };
      formState.field[index] = obj;
      setFormState(formState);
      onFormRecordDataUpdate(formState);
    }
  };

  return (
    <>
      <div className="mb-4">
        <h4 className="mb-2">Planning Section</h4>
        <p className="fst-italic weight-500 m-0">
          Only the seats Planning Section Chief can modify this section. Seats
          can be managed at the{' '}
          <Link to="/incident_configuration">Duty Roster</Link>{' '}
        </p>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>1. Incident Name</h4>
        <label className="form-label">Name:</label>
        <Controller
          control={control}
          name="incident_name"
          rules={{ required: true }}
          render={({ field: { value } }) => <p>{value}</p>}
        />
        {errors.incident_name?.type === 'required' && (
          <span className="form-text form-error mb-3">
            This field is required
          </span>
        )}
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>2. Operational Period</h4>
        {!!reduxCurrentIncident && reduxCurrentIncident?.current_period && (
          <>
            <div className="form-group">
              <label className="form-label">From:</label>
            </div>
            <div className="mb-3">
              {moment(
                reduxCurrentIncident?.operational_period_start_date_time
              ).format('MM/DD/YYYY HH:mm')}
            </div>
            <div className="form-group">
              <label className="form-label">To:</label>
            </div>
            <div className="mb-3">
              {moment(reduxCurrentIncident?.operational_period_start_date_time)
                .add(reduxCurrentIncident?.operational_period_interval, 'hours')
                .format('MM/DD/YYYY HH:mm')}
            </div>
          </>
        )}
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>3. Approved by Incident Commander(s)</h4>
        <StylishNewTable
          keyField={'incident_commander_id'}
          columns={columns_report_data}
          rows={approvedByUsers}
        />
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>INCIDENT ACTION PLAN</h4>
        <h6>
          The items checked below are included in this Incident Action Plan
        </h6>
        <StylishNewTable
          keyField={'id'}
          columns={allFormColumns}
          rows={allFormsWithData}
          selectRow={selectRowForm}
        />
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>Other Attachments</h4>
        {!!iapCoverSheet && iapCoverSheet.attachments.length > 0 && (
          <div className="row mb-2">
            {iapCoverSheet.attachments.map((file, index) => {
              return (
                <div key={file} className="col-md-3 col-xl-2 my-2">
                  <div className="cards cards__uploaded-file">
                    {!iapCoverSheet?.is_prepared && (
                      <span
                        className="remove"
                        onClick={(e) => {
                          e.preventDefault();
                          setDeleteConfirmationData(file);
                          setDeleteConfirmationType('Delete File');
                          setDeleteConfirmationText(
                            'Are you sure you want to delete this file?'
                          );
                          setShowConfirmDialog(true);
                        }}
                      >
                        <img src={IconClose} alt="" />
                      </span>
                    )}
                    <span className="icon">
                      <img src={IconFile} alt="" />
                    </span>
                    <span className="title">{file.name}</span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="form-upload">
          <div>
            <span className="d-flex justify-content-center mb-2">
              <img src={IconUpload} alt="" />
            </span>
            <span className="d-block">
              Drop Your Files Here or Click to Upload
            </span>
          </div>
          <input
            type="file"
            disabled={!!iapCoverSheet?.is_prepared}
            onInput={(e) => {
              uploadFile(e.target.files[0]);
            }}
          />
        </div>
        {iapCoverSheet?.is_prepared && (
          <span className="form-text form-error mb-3">
            You can't upload documents, IAP Cover Sheet is already prepared
          </span>
        )}
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>4. Prepared By</h4>
        {planningSectionChiefs && planningSectionChiefs.length > 0 ? (
          <>
            <div className="mb-4 row">
              <div className="col-md-6">
                <label className="form-label">Name:</label>
                <Controller
                  control={control}
                  name="prepared_by"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewSelect
                      options={planningSectionChiefs}
                      value={value}
                      placeholder="Select a Type"
                      onChange={onChange}
                      isDisabled={!!iapCoverSheet?.is_prepared}
                      isClearable={true}
                      isSearchable={true}
                      isMulti={false}
                    />
                  )}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Date/ Time:</label>
                <Controller
                  defaultValue={moment().format('YYYY-MM-DD HH:mm')}
                  control={control}
                  name="prepared_by_date_time"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput value={value} disabled={true} />
                  )}
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="form-label">
                Signature: <span className="color-danger">*</span>
              </label>
              <p className="fst-italic weight-500 m-0">
                Signatures are used to electronically sign Forms.
              </p>
            </div>
            <StylishNewButton
              disabled={
                !reduxValidateRBACPermissionForActionResult['Write Pod Data'] ||
                !isUserPSC ||
                !!iapCoverSheet?.is_prepared
              }
              type="button"
              className="button--primary"
              onClick={onMarkAsPrepired}
            >
              Mark as Prepared
            </StylishNewButton>
            {!reduxValidateRBACPermissionForActionResult['Write Pod Data'] ||
              (!isUserPSC && (
                <span className="form-text form-error mb-3">
                  You don't have permission to edit this
                </span>
              ))}
            {!!iapCoverSheet?.is_prepared && (
              <span className="form-text form-error mb-3">
                This IAP Cover Sheet is already prepared
              </span>
            )}
          </>
        ) : (
          <p className="fst-italic weight-500 m-0">
            Planning Section Chief can be managed at the{' '}
            <Link to="/incident_configuration">Duty Roster</Link>{' '}
          </p>
        )}
      </div>

      {!isUserAlreadyApproved ? (
        <>
          <hr className="mt-5 mb-4" />
          <div className="button-group align-items-end flex-column">
            <StylishNewButton
              type="button"
              className="button--primary"
              onClick={onMarkAsReviewd}
              disabled={!isUserIC || !iapCoverSheet?.is_prepared}
            >
              Approve
            </StylishNewButton>
            {!isUserIC && (
              <span className="form-text form-error">
                *Only for Incident Commander
              </span>
            )}
            {!iapCoverSheet?.is_prepared && (
              <span className="form-text form-error">
                *IAP Cover Sheet is not prepared yet
              </span>
            )}
          </div>
        </>
      ) : (
        <div className="mb-4">
          <h6>You already approved this IAP Cover Sheet</h6>
        </div>
      )}
      {isEditOperationalPeriodOpen && (
        <EditOperationalPeriodDialog
          show={isEditOperationalPeriodOpen}
          onClose={() => setIsEditOperationalPeriodOpen(false)}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={deleteConfirmationText}
          onClose={() => {
            setShowConfirmDialog(false);
            setDeleteConfirmationData(null);
            setDeleteConfirmationType(null);
            setDeleteConfirmationText(null);
          }}
          onConfirm={() =>
            deleteConfirmationType === 'Delete File'
              ? onConfirmedDeleteFile()
              : deleteConfirmationType === 'Approved By'
              ? onConfirmDeleteApprovedByIC()
              : deleteConfirmationType === 'Checklist File'
              ? onConfirmDeleteUploadFile()
              : null
          }
        />
      )}
    </>
  );
}
