export const GENERATE_TEAMS_SITE_REQUEST = 'GENERATE_TEAMS_SITE_REQUEST';
export const GENERATE_TEAMS_SITE_SUCCESS = 'GENERATE_TEAMS_SITE_SUCCESS';
export const GET_TEAMS_TEMPLATE_SUCCESS = 'GET_TEAMS_TEMPLATE_SUCCESS';
export const GENERATE_TEAMS_SITE_ERROR = 'GENERATE_TEAMS_SITE_ERROR';
export const UPDATE_TEAMS_TENANT_ID = 'UPDATE_TEAMS_TENANT_ID';
export const UPLOAD_FILE_TO_TEAMS_REQUEST = 'UPLOAD_FILE_TO_TEAMS_REQUEST';
export const UPLOAD_FILE_TO_TEAMS_SUCCESS = 'UPLOAD_FILE_TO_TEAMS_SUCCESS';
export const UPLOAD_FILE_TO_TEAMS_ERROR = 'UPLOAD_FILE_TO_TEAMS_ERROR';
export const CHECK_TEAMS = 'CHECK_TEAMS';
export const CHECK_TEAMS_STATUS_SUCCESS = 'CHECK_TEAMS_STATUS_SUCCESS';
