import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import { fetchIcsMedicalAidStations } from 'slices/ics_medicalAidStationsSlice';

import { getUserGuid, getGroupGuid } from 'slices/sliceHelpers';

// Slice
const ics_incidentMedicalAidStationsSlice = createSlice({
    name: 'ics_incident_medical_aid_stations',
    initialState: {
        ics_incident_medical_aid_stations: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        fetchIcsIncidentMedicalAidStationsSuccess: (state, action) => {
            state.ics_incident_medical_aid_stations = action.payload;
            state.status = 'succeeded';
        },
        createIcsIncidentMedicalAidStationSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        updateIcsIncidentMedicalAidStationSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    fetchIcsIncidentMedicalAidStationsSuccess,
    createIcsIncidentMedicalAidStationSuccess,
    updateIcsIncidentMedicalAidStationSuccess,
    setError
} = ics_incidentMedicalAidStationsSlice.actions;

export default ics_incidentMedicalAidStationsSlice.reducer;

export const createIcsIncidentMedicalAidStation = (incidentId, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_incident_medical_aid_stations}/${incidentId}`, { ...data, user_guid });
        dispatch(fetchIcsMedicalAidStations(group_guid));
        dispatch(fetchIcsIncidentMedicalAidStations(incidentId));
        dispatch(createIcsIncidentMedicalAidStationSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsIncidentMedicalAidStations = (incidentId) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_incident_medical_aid_stations}/${incidentId}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsIncidentMedicalAidStationsSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsIncidentMedicalAidStation = (incidentId, id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_incident_medical_aid_stations}/${incidentId}/${id}`, { ...data, user_guid });
        dispatch(fetchIcsMedicalAidStations(group_guid));
        dispatch(fetchIcsIncidentMedicalAidStations(incidentId));
        dispatch(updateIcsIncidentMedicalAidStationSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
