import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import { Incident, useAllIncidents } from './hooks/incidentHooks';
import StylishNewSelect from '../DesignSystems/New/StylishNewSelect';
import { useDispatch } from 'react-redux';
import {
  updateCurrentIncident,
} from 'actions/diceActions';

const SelectIncidentModal = () => {
  const [selectedIncident, setSelectedIncident] = useState<Incident | null>(
    null
  );
  const navigate = useNavigate();

  const incidents = useAllIncidents();

  const dispatch = useDispatch();

  const handleSelect = () => {
    if (!selectedIncident) return;
    dispatch(updateCurrentIncident(selectedIncident)); 
    navigate(`/iap/${selectedIncident.id}/dashboard`);
  };

  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>Incident Selection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {incidents.data ? (
          <>
            <p>Please select a workspace to work in. </p>
            <StylishNewSelect
              options={incidents.data.map((exer) => ({
                label: exer.name,
                value: exer.id,
              }))}
              onChange={(e) => {
                setSelectedIncident(
                  incidents.data.find((incident) => incident.id === e.value) ||
                    null
                );
              }}
              isClearable={false}
              isSearchable={false}
              isMulti={false}
            />
          </>
        ) : incidents.isLoading ? (
          <p>Loading incidents. Please wait...</p>
        ) : (
          <p>There are no incidents in this organization. Please create one.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="button button--secondary" as={Link as any} to="/map">
          Go to Map
        </Button>
        {incidents.data?.length === 0 ? (
          <Button
            className="button button--primary"
            as={Link as any}
            to="/groups/incidents"
          >
            Create a New Incident
          </Button>
        ) : (
          <Button
            className="button button--primary"
            onClick={handleSelect}
            disabled={selectedIncident === null}
          >
            Select Incident
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SelectIncidentModal;
