import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import NewICSResourceRequestItemDialog from './NewICSResourceRequestItemDialog';

export default function ResourceRequestItems({
  resourceRequestId,
  ics_resources,
  ics_resource_request_items,
  resourceRequestItems,
  setResourceRequestItems,
  isNewResourceRequest,
  ics_resource_sites
}) {
  const dispatch = useDispatch();
  const [isNewResourceRequestItemDialogOpen, setIsNewResourceRequestItemDialogOpen] = useState(false);
  const [editResourceRequestItemData, setEditResourceRequestItemData] = useState(null);
  const [modalType, setModalType] = useState('New');

  const getResourceName = (resource_id) => {
    const resource = ics_resources.find((res) => res.id === resource_id);
    return resource ? resource.name : 'Unknown';
  };

  const allColumns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      formatter: (cell, row) => getResourceName(row.resource_id),
      attrs: { title: 'Name' },
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: (cell) => <b>{cell}</b>,
      attrs: { title: 'Status' },
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
      sort: true,
      attrs: { title: 'Quantity' },
    },
    {
      dataField: 'priority',
      text: 'Priority',
      sort: true,
      attrs: { title: 'Priority' },
    },
    {
      dataField: 'cost',
      text: 'Cost',
      sort: true,
      attrs: { title: 'Cost' },
    },
    {
      dataField: 'requested_reporting_location_text',
      text: 'Reporting Location',
      sort: true,
      attrs: { title: 'Reporting Location' },
    },
    {
      dataField: 'requested_reporting_datetime',
      text: 'Requested Reporting Date/Time',
      sort: true,
      attrs: { title: 'Requested Reporting Date/Time' },
    },
    {
      dataField: 'arrival_date_time_estimated',
      text: 'Estimated Arrival Date/Time',
      sort: true,
      attrs: { title: 'Estimated Arrival Date/Time' },
    },
    {
      dataField: 'arrival_date_time_actual',
      text: 'Actual Arrival Date/Time',
      sort: true,
      attrs: { title: 'Actual Arrival Date/Time' },
    },
  ];

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewResourceRequestItemDialogOpen(true);
    setEditResourceRequestItemData(row);
  };

  const onNewResourceRequestItem = () => {
    setModalType('New');
    setIsNewResourceRequestItemDialogOpen(true);
  };

  const onDelete = (id) => {
    setResourceRequestItems(resourceRequestItems.filter(item => item.id !== id));
  };

  return (
    <>
      <p className="weight-500 mb-4">
        Resource Request Items
      </p>
      <StylishNewButton
        type="button"
        className="button--primary mb-4"
        onClick={onNewResourceRequestItem}
      >
        Add Resource Request Item
      </StylishNewButton>
      <StylishNewTable
        keyField={'id'}
        rows={resourceRequestItems}
        columns={allColumns}
        expandRow={{
          showExpandColumn: true,
          expandByColumnOnly: true,
          renderer: (row) => (
            <div className="button-group">
              <StylishNewButton
                className="button--primary"
                onClick={() => onEdit(row)}
              >
                Edit
              </StylishNewButton>
              <StylishNewButton
                className="button--danger"
                onClick={() => onDelete(row.id)}
              >
                Delete
              </StylishNewButton>
            </div>
          ),
        }}
      />
      {isNewResourceRequestItemDialogOpen && (
        <NewICSResourceRequestItemDialog
          show={isNewResourceRequestItemDialogOpen}
          setEditResourceRequestItemData={setEditResourceRequestItemData}
          editResourceRequestItemData={editResourceRequestItemData}
          modalType={modalType}
          onClose={() => {setIsNewResourceRequestItemDialogOpen(false);setEditResourceRequestItemData()}}
          ics_resources={ics_resources}
          resourceRequestId={resourceRequestId}
          resourceRequestItems={resourceRequestItems}
          setResourceRequestItems={setResourceRequestItems}
          isNewResourceRequest={isNewResourceRequest}
          ics_resource_sites={ics_resource_sites}
        />
      )}
    </>
  );
}
