import React,{useState, useEffect, useRef} from 'react';

import { HuePicker } from 'react-color';

import './LayerDataPopup.css'

function getInitRows(layer,feature)
{
  let result = [{"k":"","v":""}]

  if(!!feature && !!feature.properties)
  {
    result = Object.keys(feature.properties).map(k=>{
      return {"k":k,"v":feature.properties[k]}
    })
  }
  
  return result
}

function getLayerColor(layer)
{
  return (!!layer.paint && !!layer.paint[`${layer.type}-color`] && layer.paint[`${layer.type}-color`]) || null
}

export default function LayerDataPopup({
  layer,
  layers,
  setLayers,
  updateLayer,
  removeLayer,
  clickPopup,
  e,
  feature,
})
{  
  const [rows, setRows] = useState(getInitRows(layer,feature))
  const [stylePanelOpen, setStylePanelOpen] = useState(true)
  const [color,setColor] = useState(getLayerColor(layer))
  const featRef = useRef(React.createRef()) 

  useEffect(()=> {
    if(!!feature) featRef.current = feature
  },[feature])

  useEffect(()=>{
    setRows(getInitRows(layer,feature))   
  },[layer,feature])

  function addRow()
  {
    setRows([...rows,{"k":"","v":""}])
  }

  function updateRows(row,val,key)
  {
    const newRows = rows.map(r=>{
      if(JSON.stringify(r)===JSON.stringify(row))
      {
        r[key] = val
        return r
      }
      else
      {
        return r
      }
    })
    setRows(newRows)
  }

  function closeAndSave()
  {
    
    let newLayer = {...layer}
    if(!!newLayer.paint) newLayer.paint[`${layer.type}-color`] = color
    const newProps = {}
    rows.forEach(r=>{
      newProps[r.k] = r.v
    })
    updateLayer(layer,newLayer,featRef.current,newProps)
    clickPopup.remove()
  }

  function closeWithoutSaving()
  {
    setRows([{"k":"","v":""}])
    clickPopup.remove()
  }

  return (
    <div className="Map-LayerDataPopup">
      <div className="Map-LayerDataPopup-Title">
        <div className="Map-LayerDataPopup-Title-Text">
          {layer.id}
        </div>
      </div>
      <div className="Map-LayerDataPopup-Data">
        <div className="Map-LayerDataPopup-Metadata">
          <div className="Map-LayerDataPopup-Metadata-table-wrap">
            <table className="Map-LayerDataPopup-Metdatadata-table">
              <tbody>
                {rows.map((r,i)=>{
                  return (
                    <tr className={`Map-LayerDataPopup-Metdatadata-tr ${i%2!==0 && "Map-LayerDataPopup-Metdatadata-tr-odd"}`} key={"Map-LayerDataPopup-Metdatadata-tr-"+i}>
                      <td className="Map-LayerDataPopup-Metdatadata-td">
                        <input 
                          className="Map-LayerDataPopup-Metdatadata-td-key-input"
                          onChange={(e)=>updateRows(r,e.target.value,"k")}
                          value={r.k || ""}
                        />
                      </td>
                      <td className="Map-LayerDataPopup-Metdatadata-td">
                        <input 
                          className="Map-LayerDataPopup-Metdatadata-td-val-input" 
                          onChange={(e)=>updateRows(r,e.target.value,"v")}
                          value={r.v || ""}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="Map-LayerDataPopup-Metadata-controls">
            <div className="Map-LayerDataPopup-Metadata-controls-add-row-wrap">
              <button className="Map-LayerDataPopup-Metadata-controls-add-row-button" onClick={()=>addRow()}>+ Add row</button>
            </div>
            <div className="Map-LayerDataPopup-Metadata-controls-style-wrap">
              <button className="Map-LayerDataPopup-Metadata-controls-style-button" onClick={()=>setStylePanelOpen(!stylePanelOpen)}>Style</button>
            </div>
          </div>
        </div>
        {!!stylePanelOpen && !!color && (
          <div className="Map-LayerDataPopup-Style-Panel">
            <div className="Map-LayerDataPopup-Style-Panel-Title">
              <HuePicker 
                color={color}
                onChangeComplete={(color)=>setColor(color.hex)}
              />
            </div>
          </div>
        )}
        <div className="Map-LayerDataPopup-controls-wrap">
          <div className="Map-LayerDataPopup-controls-inner">
            <div className="Map-LayerDataPopup-controls-save-and-cancel-wrap">
              <div className="Map-LayerDataPopup-controls-save-inner">
                <button className="Map-LayerDataPopup-controls-save-button" onClick={()=>closeAndSave()}>Save</button>
              </div>
              <div className="Map-LayerDataPopup-controls-cancel-inner">
                <button className="Map-LayerDataPopup-controls-cancel-button" onClick={()=>closeWithoutSaving()}>Cancel</button>
              </div>
            </div>
            <div className="Map-LayerDataPopup-controls-delete-wrap">
              <div className="Map-LayerDataPopup-controls-delete-inner">
                <button className="Map-LayerDataPopup-controls-delete-button" onClick={()=>removeLayer(layer)}>Delete feature</button>
              </div>
            </div>
            
            
          </div>
        </div>
      </div>
    </div>
  )
}