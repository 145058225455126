import { LOADING_REQUEST, LOADING_SUCCESS } from "./loading.constants";
const initialState = {
  isLoading: false,
};

const loading = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default loading;
