
const owm_source = 'owm_source';
const owm_layer = 'owm_layer';

const owmLayerConfig = {
  id: owm_layer,
  source: owm_source,
  type: 'raster',
  interactive: true,
  generateId: true,
  metadata: {
    owmLayer: true,
  }
};

export default function owmLayerEffect(
  owmToken,
  map,
  weatherLayer,
  mapHasLoaded,
  selectedDatetime,
  setLayerClicked
) {
  if (!mapHasLoaded) return;

  if(!!map.current.getLayer(owm_layer)) {
    map.current.removeLayer(owm_layer)
  }
  if(!!map.current.getSource(owm_source)) {
    map.current.removeSource(owm_source)
  }

  if (weatherLayer) {
    const adjustedDate = selectedDatetime.unix();

    map.current.addSource(owm_source, {
      'type': 'raster',
      'tiles': [
        (weatherLayer.value + owmToken).replace("&fill",`&date=${adjustedDate}&fill`)
      ],
      'minzoom': 1,
      'maxzoom': 22
    });
    map.current.addLayer(owmLayerConfig);

    map.current.on('click', (e) => {    
      const currentLayers = map.current.getStyle().layers;
      if (currentLayers.findIndex(layer => layer.id === owm_layer) > -1) {
        setLayerClicked({
          layer: owmLayerConfig,
          e: e,
        })
      }
    });
    map.current.on('touchend', (e) => {});
  }
}