import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { Dropdown } from 'react-bootstrap';

export const AssignmentCalendar = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <button onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </button>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Assignment Calendar
          </li>
        </ol>
      </nav>
      <div className="d-md-flex flex-wrap align-items-center mb-4">
        <StylishNewButton className="button--primary w-100 w-md-auto">
          Create Assignment
        </StylishNewButton>
        <div className="button-group toggle ms-auto mt-3 mt-md-0">
          <NavLink
            className="button--icon lrg flex-grow-1"
            to={'/incident_management/assignments_list'}
          >
            <i className="fa fa-bars" aria-hidden="true"></i>
          </NavLink>
          <NavLink
            className="button--icon lrg flex-grow-1"
            to={'/incident_management/assignments_board'}
          >
            <i className="fa fa-th-large" aria-hidden="true"></i>
          </NavLink>
          <NavLink
            className="button--icon lrg flex-grow-1"
            to={'/incident_management/assignments_calendar'}
          >
            <i className="fa fa-calendar" aria-hidden="true"></i>
          </NavLink>
        </div>
        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 mt-3 mt-lg-0">
          <StylishNewSearchBar
            className="ms-lg-3 me-3 me-md-0"
            onChangeSearchTerm={onChangeSearchTerm}
          />
          <StylishNewButton
            customButton
            className="button--icon lrg ms-auto ms-lg-3 flex-shrink-0"
          >
            <SharedIcon iconName="question_mark" />
          </StylishNewButton>
          <Dropdown className="flex-shrink-0 ms-3">
            <Dropdown.Toggle className="button--icon lrg">
              <SharedIcon iconName="more_vert" bold />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item>
                <SharedIcon iconName="add" bold />
                New Branch
              </Dropdown.Item>
              <Dropdown.Item>
                <SharedIcon iconName="visibility" />
                View Assignment
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <p className="weight-600">Assignment Calendar Coming Soon !!!</p>
    </>
  );
};
