import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton'
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput'
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';
import { productionEnv } from 'constants/debug';
import { useEffect, useState } from 'react'
import generateUUID from 'utils/sharedUtils/generateUUID'
import { useDispatch } from 'react-redux'
import { upsertAOR } from 'actions/locationAction'

export const AddUpdateAor = ({
    show,
    onClose,
    mode,
    editAorData
}) => {
    const mapboxToken = window.env.MAPBOX_ACCESS_TOKEN;

    const [geoLocation, setGeoLocation] = useState(editAorData?.location);
    const reduxDispatch = useDispatch();

    const { handleSubmit, control, setValue, formState: { errors } } = useForm({ mode: 'onChange' });

    useEffect(() => {
        if (!!editAorData && mode === 'Edit') {
            setValue('name', editAorData.name);
            setValue('description', editAorData.description);
            setGeoLocation(editAorData.location);
        }
    }, [editAorData, mode]);

    const onSubmit = (data) => {
        const current_location_geom =
            !!geoLocation && !!geoLocation.geojson
                ? geoLocation.geojson.data.features[0].geometry
                : null;
        const aor = {
            id: editAorData?.id || generateUUID(),
            name: data.name,
            description: data.description,
            location: geoLocation,
            current_location_geom: current_location_geom,
        }
        reduxDispatch(upsertAOR(aor));
        onClose();
    };
    return (
        <Modal
            show={show}
            onHide={onClose}
            centered
            backdrop="static"
            keyboard={false}
            size='lg'
        >
            <Modal.Header closeButton closeVariant="white">
                <Modal.Title>
                    {`${mode === 'Edit' ? 'Update' : 'Add'} Area of Responsibility (AOR)`}
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <div className="mb-3">
                        <label className="form-label">Name</label>
                        <Controller
                            control={control}
                            name="name"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <StylishNewInput
                                    placeholder="Enter Name"
                                    type="text"
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                        {errors?.name && <span className="form-text form-error">
                            This field is required
                        </span>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Description</label>
                        <Controller
                            control={control}
                            name="description"
                            render={({ field: { onChange, value } }) => (
                                <StylishNewTextArea
                                    placeholder="Enter Description"
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Location:</label>
                        <GeoLocationComponent
                            location={geoLocation}
                            setLocation={setGeoLocation}
                            mapboxToken={mapboxToken}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className="button-group">
                        <StylishNewButton
                            type="button"
                            className="button--secondary button--reverse"
                            onClick={onClose}
                        >
                            Cancel
                        </StylishNewButton>
                        <StylishNewButton
                            type="submit"
                            className="button--primary"

                        >
                            {`${mode === 'Edit' ? 'Update' : 'Add'}` + ' Area of Responsibility (AOR)'}
                        </StylishNewButton>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
