import config from 'constants/apiConfig';
import axios from 'axios';

import {
  FETCH_ADLS_REQUEST,
  FETCH_ADLS_SUCCESS,
  FETCH_ADLS_ERROR,
  UPSERT_ADL_REQUEST,
  UPSERT_ADL_SUCCESS,
  UPSERT_ADL_ERROR,
  DELETE_ADL_REQUEST,
  DELETE_ADL_SUCCESS,
  DELETE_ADL_ERROR,
  ASSIGN_ADL_REQUEST,
  ASSIGN_ADL_SUCCESS,
  ASSIGN_ADL_ERROR,
  UNASSIGN_ADL_REQUEST,
  UNASSIGN_ADL_SUCCESS,
  UNASSIGN_ADL_ERROR,
  FETCH_HVAS_REQUEST,
  FETCH_HVAS_SUCCESS,
  FETCH_HVAS_ERROR,
  FETCH_HVA_DATA_REQUEST,
  FETCH_HVA_DATA_SUCCESS,
  FETCH_HVA_DATA_ERROR,
  UPSERT_HVA_REQUEST,
  UPSERT_HVA_SUCCESS,
  UPSERT_HVA_ERROR,
  DELETE_HVA_REQUEST,
  DELETE_HVA_SUCCESS,
  DELETE_HVA_ERROR,
  FETCH_HVA_TEMPLATE_CATEGORIES_REQUEST,
  FETCH_HVA_TEMPLATE_CATEGORIES_SUCCESS,
  FETCH_HVA_TEMPLATE_CATEGORIES_ERROR,
  UPSERT_HVA_TEMPLATE_CATEGORY_REQUEST,
  UPSERT_HVA_TEMPLATE_CATEGORY_SUCCESS,
  UPSERT_HVA_TEMPLATE_CATEGORY_ERROR,
  FETCH_HVA_TEMPLATE_RISKS_REQUEST,
  FETCH_HVA_TEMPLATE_RISKS_SUCCESS,
  FETCH_HVA_TEMPLATE_RISKS_ERROR,
  UPSERT_HVA_TEMPLATE_RISK_REQUEST,
  UPSERT_HVA_TEMPLATE_RISK_SUCCESS,
  UPSERT_HVA_TEMPLATE_RISK_ERROR,
  FETCH_HVA_TEMPLATE_COLUMNS_REQUEST,
  FETCH_HVA_TEMPLATE_COLUMNS_SUCCESS,
  FETCH_HVA_TEMPLATE_COLUMNS_ERROR,
  CLONE_HVA_REQUEST,
  CLONE_HVA_SUCCESS,
  CLONE_HVA_ERROR,
  FETCH_ALERTS_REQUEST,
  FETCH_ALERTS_SUCCESS,
  UPDATE_ALERT_REQUEST,
  UPDATE_ALERT_SUCCESS,
  UPDATE_ALERT_ERROR,
  FETCH_GEOLOCATION_RISK_ORG_REQUEST,
  FETCH_GEOLOCATION_RISK_ORG_SUCCESS,
  FETCH_GEOLOCATION_RISK_ORG_ERROR,
} from 'constants/alert-action-types';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

function fetchADLsRequest() {
  return {
    type: FETCH_ADLS_REQUEST,
  };
}

function fetchADLsSuccess(adls) {
  return {
    type: FETCH_ADLS_SUCCESS,
    payload: adls,
  };
}

function fetchADLsError(error) {
  const errorMessage =
    'fetchADLsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_ADLS_ERROR,
  };
}

export const fetchADLs = () => {
  return (dispatch, getState) => {
    dispatch(fetchADLsRequest());
    const { group_guid } = getState().app.currentlySelectedGroup;
    return axios(config.apiGateway.fetchADLs, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(fetchADLsSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchADLsError(error));
      });
  };
};

function upsertADLRequest() {
  return {
    type: UPSERT_ADL_REQUEST,
  };
}

function upsertADLSuccess(adl) {
  return {
    type: UPSERT_ADL_SUCCESS,
    payload: adl,
  };
}

function upsertADLError(error) {
  const errorMessage =
    'upsertADLError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPSERT_ADL_ERROR,
  };
}

export const upsertADL = (adl) => {
  return (dispatch, getState) => {
    dispatch(upsertADLRequest());
    return axios(config.apiGateway.upsertADL, {
      method: 'POST',
      data: JSON.stringify({
        adl,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(upsertADLSuccess(data));
        dispatch(fetchADLs());
      })
      .catch((error) => {
        dispatch(upsertADLError(error));
      });
  };
};

function deleteADLRequest() {
  return {
    type: DELETE_ADL_REQUEST,
  };
}

function deleteADLSuccess() {
  return {
    type: DELETE_ADL_SUCCESS,
  };
}

function deleteADLError(error) {
  const errorMessage =
    'deleteADLError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: DELETE_ADL_ERROR,
  };
}

export const deleteADL = (list_id) => {
  return (dispatch) => {
    dispatch(deleteADLRequest());
    return axios(config.apiGateway.deleteADL, {
      method: 'POST',
      data: JSON.stringify({
        list_id,
      }),
    })
      .then(() => {
        dispatch(deleteADLSuccess());
        dispatch(fetchADLs());
      })
      .catch((error) => {
        dispatch(deleteADLError(error));
      });
  };
};

function assignADLRequest() {
  return {
    type: ASSIGN_ADL_REQUEST,
  };
}

function assignADLSuccess() {
  return {
    type: ASSIGN_ADL_SUCCESS,
  };
}

function assignADLError(error) {
  const errorMessage =
    'assignADLError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: ASSIGN_ADL_ERROR,
  };
}

export const assignADL = (id, org_id, adl_id, risk_level, aor_id) => {
  return (dispatch, getState) => {
    dispatch(assignADLRequest());
    return axios(config.apiGateway.assignADL, {
      method: 'POST',
      data: JSON.stringify({
        id,
        org_id,
        adl_id,
        risk_level,
        aor_id,
      }),
    })
      .then(() => {
        dispatch(assignADLSuccess());
        dispatch(fetchADLs());
      })
      .catch((error) => {
        dispatch(assignADLError(error));
      });
  };
};

function unassignADLRequest() {
  return {
    type: UNASSIGN_ADL_REQUEST,
  };
}

function unassignADLSuccess() {
  return {
    type: UNASSIGN_ADL_SUCCESS,
  };
}

function unassignADLError(error) {
  const errorMessage =
    'unassignADLError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UNASSIGN_ADL_ERROR,
  };
}

export const unassignADL = (adl_id) => {
  return (dispatch) => {
    dispatch(unassignADLRequest());
    return axios(config.apiGateway.unassignADL, {
      method: 'POST',
      data: JSON.stringify({
        adl_id,
      }),
    })
      .then(() => {
        dispatch(unassignADLSuccess());
        dispatch(fetchADLs());
      })
      .catch((error) => {
        dispatch(unassignADLError(error));
      });
  };
};

function fetchHVAsRequest() {
  return {
    type: FETCH_HVAS_REQUEST,
  };
}

function fetchHVAsSuccess(HVAs) {
  return {
    type: FETCH_HVAS_SUCCESS,
    payload: HVAs,
  };
}

function fetchHVAsError(error) {
  const errorMessage =
    'fetchHVAsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_HVAS_ERROR,
  };
}

export const fetchHVAs = () => {
  return (dispatch, getState) => {
    dispatch(fetchHVAsRequest());
    const { group_guid } = getState().app.currentlySelectedGroup;
    return axios(config.apiGateway.fetchHVAs, {
      method: 'POST',
      data: JSON.stringify({
        org_id: group_guid,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(fetchHVAsSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchHVAsError(error));
      });
  };
};

function fetchHVADataRequest() {
  return {
    type: FETCH_HVA_DATA_REQUEST,
  };
}

function fetchHVADataSuccess(HVAData) {
  return {
    type: FETCH_HVA_DATA_SUCCESS,
    payload: HVAData,
  };
}

function fetchHVADataError(error) {
  const errorMessage =
    'fetchHVADataError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_HVA_DATA_ERROR,
  };
}

export const fetchHVAData = (hva_id) => {
  return (dispatch, getState) => {
    dispatch(fetchHVADataRequest());
    return axios(config.apiGateway.fetchHVAData, {
      method: 'POST',
      data: JSON.stringify({
        hva_id,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(fetchHVADataSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchHVADataError(error));
      });
  };
};

function upsertHVARequest() {
  return {
    type: UPSERT_HVA_REQUEST,
  };
}

function upsertHVASuccess() {
  return {
    type: UPSERT_HVA_SUCCESS,
  };
}

function upsertHVAError(error) {
  const errorMessage =
    'upsertHVAError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPSERT_HVA_ERROR,
  };
}

export const upsertHVA = (hva) => {
  return (dispatch) => {
    dispatch(upsertHVARequest());
    return axios(config.apiGateway.upsertHVA, {
      method: 'POST',
      data: JSON.stringify({
        hva,
      }),
    })
      .then(() => {
        dispatch(upsertHVASuccess());
        dispatch(fetchHVAs());
      })
      .catch((error) => {
        dispatch(upsertHVAError(error));
      });
  };
};

function deleteHVARequest() {
  return {
    type: DELETE_HVA_REQUEST,
  };
}

function deleteHVASuccess() {
  return {
    type: DELETE_HVA_SUCCESS,
  };
}

function deleteHVAError(error) {
  const errorMessage =
    'deleteHVAError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: DELETE_HVA_ERROR,
  };
}

export const deleteHVA = (hva_id) => {
  return (dispatch) => {
    dispatch(deleteHVARequest());
    return axios(config.apiGateway.deleteHVA, {
      method: 'POST',
      data: JSON.stringify({
        hva_id,
      }),
    })
      .then(() => {
        dispatch(deleteHVASuccess());
        dispatch(fetchHVAs());
      })
      .catch((error) => {
        dispatch(deleteHVAError(error));
      });
  };
};

function fetchHVATemplateCategoriesRequest() {
  return {
    type: FETCH_HVA_TEMPLATE_CATEGORIES_REQUEST,
  };
}

function fetchHVATemplateCategoriesSuccess(HVAs) {
  return {
    type: FETCH_HVA_TEMPLATE_CATEGORIES_SUCCESS,
    payload: HVAs,
  };
}

function fetchHVATemplateCategoriesError(error) {
  const errorMessage =
    'fetchHVATemplateCategoriesError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_HVA_TEMPLATE_CATEGORIES_ERROR,
  };
}

export const fetchHVATemplateCategories = () => {
  return (dispatch, getState) => {
    dispatch(fetchHVATemplateCategoriesRequest());
    const { group_guid } = getState().app.currentlySelectedGroup;
    return axios(config.apiGateway.fetchHVATemplateCategories, {
      method: 'POST',
      data: JSON.stringify({
        org_id: group_guid,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(fetchHVATemplateCategoriesSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchHVATemplateCategoriesError(error));
      });
  };
};

function upsertHVATemplateCategoryRequest() {
  return {
    type: UPSERT_HVA_TEMPLATE_CATEGORY_REQUEST,
  };
}

function upsertHVATemplateCategorySuccess() {
  return {
    type: UPSERT_HVA_TEMPLATE_CATEGORY_SUCCESS,
  };
}

function upsertHVATemplateCategoryError(error) {
  const errorMessage =
    'upsertHVATemplateCategoryError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPSERT_HVA_TEMPLATE_CATEGORY_ERROR,
  };
}

export const upsertHVATemplateCategory = (cat) => {
  return (dispatch) => {
    dispatch(upsertHVATemplateCategoryRequest());
    return axios(config.apiGateway.upsertHVATemplateCategory, {
      method: 'POST',
      data: JSON.stringify({
        cat,
      }),
    })
      .then(() => {
        dispatch(upsertHVATemplateCategorySuccess());
      })
      .catch((error) => {
        dispatch(upsertHVATemplateCategoryError(error));
      });
  };
};

function deleteHVATemplateCategoryRequest() {
  return {
    type: DELETE_HVA_REQUEST,
  };
}

function deleteHVATemplateCategorySuccess() {
  return {
    type: DELETE_HVA_SUCCESS,
  };
}

function deleteHVATemplateCategoryError(error) {
  const errorMessage =
    'deleteHVATemplateCategoryError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: DELETE_HVA_ERROR,
  };
}

export const deleteHVATemplateCategory = (id) => {
  return (dispatch) => {
    dispatch(deleteHVATemplateCategoryRequest());
    return axios(config.apiGateway.deleteHVATemplateCategory, {
      method: 'POST',
      data: JSON.stringify({
        id,
      }),
    })
      .then(() => {
        dispatch(deleteHVATemplateCategorySuccess());
      })
      .catch((error) => {
        dispatch(deleteHVATemplateCategoryError(error));
      });
  };
};

function fetchHVATemplateRisksRequest() {
  return {
    type: FETCH_HVA_TEMPLATE_RISKS_REQUEST,
  };
}

function fetchHVATemplateRisksSuccess(risks) {
  return {
    type: FETCH_HVA_TEMPLATE_RISKS_SUCCESS,
    payload: risks,
  };
}

function fetchHVATemplateRisksError(error) {
  const errorMessage =
    'fetchHVATemplateRisksError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_HVA_TEMPLATE_RISKS_ERROR,
  };
}

export const fetchHVATemplateRisks = () => {
  return (dispatch, getState) => {
    dispatch(fetchHVATemplateRisksRequest());
    const { group_guid } = getState().app.currentlySelectedGroup;
    return axios(config.apiGateway.fetchHVATemplateRisks, {
      method: 'POST',
      data: JSON.stringify({
        org_id: group_guid,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(fetchHVATemplateRisksSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchHVATemplateRisksError(error));
      });
  };
};

function upsertHVATemplateRiskRequest() {
  return {
    type: UPSERT_HVA_TEMPLATE_RISK_REQUEST,
  };
}

function upsertHVATemplateRiskSuccess() {
  return {
    type: UPSERT_HVA_TEMPLATE_RISK_SUCCESS,
  };
}

function upsertHVATemplateRiskError(error) {
  const errorMessage =
    'upsertHVATemplateRiskError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPSERT_HVA_TEMPLATE_RISK_ERROR,
  };
}

export const upsertHVATemplateRisk = (cat) => {
  return (dispatch) => {
    dispatch(upsertHVATemplateRiskRequest());
    return axios(config.apiGateway.upsertHVATemplateRisk, {
      method: 'POST',
      data: JSON.stringify({
        cat,
      }),
    })
      .then(() => {
        dispatch(upsertHVATemplateRiskSuccess());
      })
      .catch((error) => {
        dispatch(upsertHVATemplateRiskError(error));
      });
  };
};

function deleteHVATemplateRiskRequest() {
  return {
    type: DELETE_HVA_REQUEST,
  };
}

function deleteHVATemplateRiskSuccess() {
  return {
    type: DELETE_HVA_SUCCESS,
  };
}

function deleteHVATemplateRiskError(error) {
  const errorMessage =
    'deleteHVATemplateRiskError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: DELETE_HVA_ERROR,
  };
}

export const deleteHVATemplateRisk = (id) => {
  return (dispatch) => {
    dispatch(deleteHVATemplateRiskRequest());
    return axios(config.apiGateway.deleteHVATemplateRisk, {
      method: 'POST',
      data: JSON.stringify({
        id,
      }),
    })
      .then(() => {
        dispatch(deleteHVATemplateRiskSuccess());
      })
      .catch((error) => {
        dispatch(deleteHVATemplateRiskError(error));
      });
  };
};

function fetchHVATemplateColumnsRequest() {
  return {
    type: FETCH_HVA_TEMPLATE_COLUMNS_REQUEST,
  };
}

function fetchHVATemplateColumnsSuccess(cols) {
  return {
    type: FETCH_HVA_TEMPLATE_COLUMNS_SUCCESS,
    payload: cols,
  };
}

function fetchHVATemplateColumnsError(error) {
  const errorMessage =
    'fetchHVATemplateColumnsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_HVA_TEMPLATE_COLUMNS_ERROR,
  };
}

export const fetchHVATemplateColumns = () => {
  return (dispatch, getState) => {
    dispatch(fetchHVATemplateColumnsRequest());
    return axios(config.apiGateway.fetchHVATemplateColumns, {
      method: 'POST',
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(fetchHVATemplateColumnsSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchHVATemplateColumnsError(error));
      });
  };
};

function cloneHVARequest() {
  return {
    type: CLONE_HVA_REQUEST,
  };
}

function cloneHVASuccess() {
  return {
    type: CLONE_HVA_SUCCESS,
  };
}

function cloneHVAError(error) {
  const errorMessage =
    'cloneHVAError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: CLONE_HVA_ERROR,
  };
}

export const cloneHVA = (hva) => {
  return (dispatch, getState) => {
    dispatch(cloneHVARequest());
    return axios(config.apiGateway.cloneHVA, {
      method: 'POST',
      data: JSON.stringify({
        hva,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(cloneHVASuccess(data));
        dispatch(fetchHVAs());
      })
      .catch((error) => {
        dispatch(cloneHVAError(error));
      });
  };
};

function fetchAlertsRequest() {
  return {
    type: FETCH_ALERTS_REQUEST,
  };
}

function fetchAlertsSuccess(alerts) {
  return {
    type: FETCH_ALERTS_SUCCESS,
    payload: alerts,
  };
}

function fetchAlertsError(error) {
  const errorMessage =
    'fetchAlertsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_ADLS_ERROR,
  };
}

export const fetchAlerts = () => {
  return (dispatch, getState) => {
    dispatch(fetchAlertsRequest());
    const { group_guid } = getState().app.currentlySelectedGroup;
    return axios(config.apiGateway.fetchAlerts, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(fetchAlertsSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchAlertsError(error));
      });
  };
};

function updateAlertRequest() {
  return {
    type: UPDATE_ALERT_REQUEST,
  };
}

function updateAlertSuccess() {
  return {
    type: UPDATE_ALERT_SUCCESS,
  };
}

function updateAlertError(error) {
  const errorMessage =
    'updateAlertError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_ALERT_ERROR,
  };
}

export const updateAlert = (alert) => {
  return (dispatch, getState) => {
    dispatch(updateAlertRequest());
    return axios(config.apiGateway.updateAlert, {
      method: 'POST',
      data: JSON.stringify({
        alert,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(fetchAlerts());
        dispatch(updateAlertSuccess(data));
      })
      .catch((error) => {
        dispatch(updateAlertError(error));
      });
  };
};

function fetchGeolocationRiskOrgRequest() {
  return {
    type: FETCH_GEOLOCATION_RISK_ORG_REQUEST,
  };
}

function fetchGeolocationRiskOrgSuccess(aor, data) {
  return {
    type: FETCH_GEOLOCATION_RISK_ORG_SUCCESS,
    payload: { aor, data },
  };
}

function fetchGeolocationRiskOrgError(error) {
  const errorMessage =
    'fetch Geolocation Risk Alert Error\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_GEOLOCATION_RISK_ORG_ERROR,
  };
}

export function fetchGeolocationRiskOrg(
  group_guid,
  selectedDatetime,
  aor = null,
  newReport = false
) {
  let interval;
  return (dispatch, getState) => {
    dispatch(fetchGeolocationRiskOrgRequest());
    return axios(config.apiGateway.fetchGeolocationAtRiskToOrg, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
        selectedDatetime,
        aor,
        newReport,
      }),
    })
      .then((response) => {
        if (!newReport && response?.data?.output) {
          dispatch(
            fetchGeolocationRiskOrgSuccess(aor, response.data.output[0])
          );
          return;
        }
        interval = setInterval(() => {
          axios(config.apiGateway.fetchGeolocationAtRiskToOrg, {
            method: 'POST',
            data: JSON.stringify({
              group_guid,
              selectedDatetime,
              aor,
            }),
          })
            .then((pollResponse) => {
              if (pollResponse.data.status === 'Complete') {
                dispatch(
                  fetchGeolocationRiskOrgSuccess(
                    aor,
                    pollResponse.data.output[0]
                  )
                );
                clearInterval(interval);
              } else if (pollResponse.data.status === 'Error') {
                dispatch(
                  fetchGeolocationRiskOrgError(
                    'Alerts processing error.  Please try again.'
                  )
                );
                clearInterval(interval);
              }
            })
            .catch((error) => {
              console.error('Error checking Alerts task status:', error);
              dispatch(fetchGeolocationRiskOrgError(error));
              clearInterval(interval);
            });
        }, 5000); // Check every 5 seconds
      })
      .catch((error) => {
        console.error('Error with Alerts task:', error);
        dispatch(fetchGeolocationRiskOrgError(error));
        clearInterval(interval);
      });
  };
}
