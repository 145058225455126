import momentTz from 'moment-timezone';

export const getLoaclTimeZoneAndOffset = () => {
  const abbr = momentTz
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .zoneAbbr();
  const offset = new Date().getTimezoneOffset();

  const timezone =
    abbr +
    '/GMT' +
    (offset < 0 ? '+' + Math.abs(offset) / 60 : '-' + offset / 60);
  return { timezone, offset: -offset };
};
