export const IncidentTypes = [
  'Special Event',
  'Medical',
  'Oil Spill',
  'Aviation',
  'Fire',
  'Avalanche',
  'Drought',
  'Earthquake',
  'Epidemic',
  'Flood Hurricane/Typhoon',
  'Space weather',
  'Tornado',
  'Tsunami',
  'Volcanic eruption',
  'Winter storm',
  'Dam failure',
  'Hazardous materials release',
  'Industrial accident',
  'Levee failure',
  'Mine accident',
  'Pipeline explosion',
  'Radiological release',
  'Train derailment',
  'Transportation accident',
  'Urban conflagration',
  'Utility disruption',
  'Active shooter incident',
  'Armed assault',
  'Biological attack',
  'Chemical attack',
  'Cyber-attack against data',
  'Cyber-attack against infrastructure',
  'Explosives attack',
  'Improvised nuclear attack',
  'Nuclear terrorism attack',
  'Radiological attack',
];

export const BPIncidentTypes = [
  { value: 'Abandon Facility', label: 'Abandon Facility' },
  { value: 'Animal Handling', label: 'Animal Handling' },
  {
    value: 'ASDS Cyber Incident Response',
    label: 'ASDS Cyber Incident Response',
  },
  {
    value: 'Attendant Vessel Collision(Drive Off / Drift Off)',
    label: 'Attendant Vessel Collision(Drive Off / Drift Off)',
  },
  { value: 'Business Continuity', label: 'Business Continuity' },
  {
    value: 'Damaged or Loss of Critical Equipment / Pipelines',
    label: 'Damaged or Loss of Critical Equipment / Pipelines',
  },
  {
    value: 'Drilling Area – Gas Release',
    label: 'Drilling Area – Gas Release',
  },
  {
    value: 'Fire – Accommodations and Hull',
    label: 'Fire – Accommodations and Hull',
  },
  { value: 'Fire – Jet Pressurized', label: 'Fire – Jet Pressurized' },
  {
    value: 'Fire and Explosion - Topsides',
    label: 'Fire and Explosion - Topsides',
  },
  {
    value: 'Hazardous Waste Spill / Release',
    label: 'Hazardous Waste Spill / Release',
  },
  { value: 'Helicopter Crash', label: 'Helicopter Crash' },
  {
    value: 'Loss of Facility Stability and Flooding',
    label: 'Loss of Facility Stability and Flooding',
  },
  { value: 'Loss of Well Control', label: 'Loss of Well Control' },
  { value: 'Man Overboard', label: 'Man Overboard' },
  {
    value: 'Medical Emergency / Fatality',
    label: 'Medical Emergency / Fatality',
  },
  { value: 'Medivac', label: 'Medivac' },
  { value: 'Mooring Failure', label: 'Mooring Failure' },
  { value: 'Passing Vessel Collision', label: 'Passing Vessel Collision' },
  { value: 'Process Area - Gas Release', label: 'Process Area - Gas Release' },
  {
    value: 'Process Area – Major Liquid Hydrocarbon Release',
    label: 'Process Area – Major Liquid Hydrocarbon Release',
  },
  { value: 'Rescue', label: 'Rescue' },
  {
    value: 'Riser Failure / Loss of Containment',
    label: 'Riser Failure / Loss of Containment',
  },
  { value: 'Security Response', label: 'Security Response' },
  { value: 'Severe Weather', label: 'Severe Weather' },
  {
    value: 'Subsea Release(Flow Line or Subsea System)',
    label: 'Subsea Release(Flow Line or Subsea System)',
  },
  { value: 'Unplanned Loss of Power', label: 'Unplanned Loss of Power' },
  {
    value: 'No ERP Scenario Tested(Muster drill only)',
    label: 'No ERP Scenario Tested(Muster drill only)',
  },
];
