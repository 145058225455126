export const UPDATE_LASSO_FILTER_SUCCESS = 'UPDATE_LASSO_FILTER_SUCCESS' 
export const UPDATE_LASSO_FILTER_ERROR = 'UPDATE_LASSO_FILTER_ERROR' 
export const UPDATE_MAP_LOCATION = 'UPDATE_MAP_LOCATION' 
export const UPDATE_MAP_LOCATION_ERROR = 'UPDATE_MAP_LOCATION_ERROR' 
export const DASHBOARD_LAYOUT_UPDATE = 'DASHBOARD_LAYOUT_UPDATE' 
export const DASHBOARD_LAYOUT_UPDATE_ERROR = 'DASHBOARD_LAYOUT_UPDATE_ERROR' 
export const ZOOM_MAP_TO_DATA = 'ZOOM_MAP_TO_DATA'
export const ZOOM_MAP_TO_DATA_ERROR = 'ZOOM_MAP_TO_DATA_ERROR'
export const UPDATE_LASSO_COORDINATES_REQUEST = 'UPDATE_LASSO_COORDINATES_REQUEST';
export const UPDATE_LASSO_COORDINATES_SUCCESS = 'UPDATE_LASSO_COORDINATES_SUCCESS';
export const UPDATE_LASSO_COORDINATES_ERROR = 'UPDATE_LASSO_COORDINATES_ERROR';
export const TOGGLE_MAP_LEGEND_VISIBLE_REQUEST = 'TOGGLE_MAP_LEGEND_VISIBLE_REQUEST';
export const TOGGLE_MAP_LEGEND_VISIBLE_SUCCESS = 'TOGGLE_MAP_LEGEND_VISIBLE_SUCCESS';
export const TOGGLE_MAP_LEGEND_VISIBLE_ERROR = 'TOGGLE_MAP_LEGEND_VISIBLE_ERROR';
export const TOGGLE_MAP_DATA_PANE_VISIBLE_REQUEST = 'TOGGLE_MAP_DATA_PANE_VISIBLE_REQUEST';
export const TOGGLE_MAP_DATA_PANE_VISIBLE_SUCCESS = 'TOGGLE_MAP_DATA_PANE_VISIBLE_SUCCESS';
export const TOGGLE_MAP_DATA_PANE_VISIBLE_ERROR = 'TOGGLE_MAP_DATA_PANE_VISIBLE_ERROR';
export const UPDATE_MAP_DATA_PANE_DATA_REQUEST = 'UPDATE_MAP_DATA_PANE_DATA_REQUEST'
export const UPDATE_MAP_DATA_PANE_DATA_SUCCESS = 'UPDATE_MAP_DATA_PANE_DATA_SUCCESS'
export const UPDATE_MAP_DATA_PANE_DATA_ERROR = 'UPDATE_MAP_DATA_PANE_DATA_ERROR'
export const ADD_TO_MAP_DATA_PANE_DATA_REQUEST = 'ADD_TO_MAP_DATA_PANE_DATA_REQUEST'
export const ADD_TO_MAP_DATA_PANE_DATA_SUCCESS = 'ADD_TO_MAP_DATA_PANE_DATA_SUCCESS'
export const ADD_TO_MAP_DATA_PANE_DATA_ERROR = 'ADD_TO_MAP_DATA_PANE_DATA_ERROR'
export const REMOVE_FROM_MAP_DATA_PANE_DATA_REQUEST = 'REMOVE_FROM_MAP_DATA_PANE_DATA_REQUEST'
export const REMOVE_FROM_MAP_DATA_PANE_DATA_SUCCESS = 'REMOVE_FROM_MAP_DATA_PANE_DATA_SUCCESS'
export const REMOVE_FROM_MAP_DATA_PANE_DATA_ERROR = 'REMOVE_FROM_MAP_DATA_PANE_DATA_ERROR'
