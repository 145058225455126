import React, { useEffect, useState } from 'react';
import AddEditWorkspace from 'components/GroupsRoute/AddEditWorkspace';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { toastConfig } from 'assets/data/config';
import { PaginationDropdown } from '../DesignSystems/PaginationDropdown';
import {
  endLoading,
  startLoading,
} from '../../reducers/loading/loading.action';
import { fetchExerciseWorkspaces } from 'actions/workspaceActions';

export default function GroupWorkspaceUpdated() {
  const [allWorkspaceList, setallWorkspaceLists] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [
    showWorkspaceListEditDialog,
    setshowWorkspaceListEditDialog,
  ] = useState(false);
  const [
    showWorkspaceListDeleteDialog,
    setshowWorkspaceListDeleteDialog,
  ] = useState(false);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [deleteWorkspaceId, setdeleteWorkspaceId] = useState(null);
  const [orderBy, setOrderBy] = useState('');

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const dispatch = useDispatch();

  const user = useSelector((state) => {
    // TODO: obviously this should just be done in the reducer.
    // BEWARE using this user in dependency arrays. depend on a specific property.
    return {
      ...state.app.user,
      ...state.prepare.user,
    };
  });

  const columns = [
    {
      dataField: 'displayName',
      text: 'Title',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
    },
    {
      dataField: 'ownertype',
      text: 'Type',
      attrs: { title: 'Type' },
      sort: true,

      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('type_asc');
        } else if (order === 'desc') {
          setOrderBy('type_desc');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'shortName',
      text: 'Short Title',
      attrs: { title: 'Short Title' },
      sort: true,

      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('title_asc');
        } else if (order === 'desc') {
          setOrderBy('title_desc');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        if (row.status && row.status === 'Available') {
          return <span className="status status--available">{cell}</span>;
        } else if (row.status && row.status === 'Archived') {
          return <span className="status status--archived">{cell}</span>;
        }
      },
      attrs: { title: 'Status' },
      sort: true,

      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('st_asc');
        } else if (order === 'desc') {
          setOrderBy('st_desc');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Last Update',
      attrs: { title: 'Last Update' },
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('update_asc');
        } else if (order === 'desc') {
          setOrderBy('update_desc');
        } else {
          setOrderBy('');
        }
        return;
      },
      formatter: (cell, row, rowIndex) => (
        <>{moment(row.updatedAt).fromNow()}</>
      ),
    },
    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) =>
        row.shortName.toLowerCase() !== 'global' ? (
          <StylishNewTableAction
            name="Workspace"
            cell={cell}
            row={row}
            rowIndex={rowIndex}
            onDelete={onDeleteWorkspace}
            onEdit={onEditWorkspaceList}
          />
        ) : null,
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const getWorkspace = async () => {
    if (!reduxCurrentlySelectedGroup) {
      return;
    }
    dispatch(startLoading());

    try {
      const { dataset, count } = await Network.get(
        API.getAllWorkspaceByOrganizationId,
        {
          limit: perPage,
          page: page,
          search: searchTerm,
          sort: orderBy,
          organizationId: reduxCurrentlySelectedGroup?.group_guid,
        }
      ).then((data) => data.data.response);

      setallWorkspaceLists(dataset);
      dispatch(
        fetchExerciseWorkspaces(
          user.user_guid,
          reduxCurrentlySelectedGroup.group_guid
        )
      );
      setTotal(count);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    getWorkspace();
  }, [page, perPage, searchTerm, orderBy, reduxCurrentlySelectedGroup]);

  const onAddWorkspaceList = () => {
    setDialogType('Add');
    setshowWorkspaceListEditDialog(true);
  };

  const onDeleteWorkspace = (row) => {
    setdeleteWorkspaceId(row.workspaceId);
    setDialogType('Delete');
    setshowWorkspaceListDeleteDialog(true);
  };

  const onEditWorkspaceList = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setshowWorkspaceListEditDialog(true);
  };

  const onCloseDialog = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setshowWorkspaceListEditDialog(false);
    setshowWorkspaceListDeleteDialog(false);
    if (afterSave === true) {
      getWorkspace();
    }
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteWorkspace, {
        workspaceId: deleteWorkspaceId,
      });
      setdeleteWorkspaceId(null);
      getWorkspace();
      toast.success('Workspace deleted successfully', toastConfig);
      setshowWorkspaceListDeleteDialog(false);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onChangeSearchTerm = (term) => {
    setPage(1);
    setSearchTerm(term);
  };

  useEffect(() => {
    setSearchTerm('');
  }, [reduxCurrentlySelectedGroup]);

  return (
    <>
      <div className="d-md-flex align-items-center mb-3">
        <h4 className="m-0">Workspace</h4>
        <StylishNewButton
          className="button--primary mt-2 mt-md-0 ms-auto"
          onClick={onAddWorkspaceList}
        >
          Add Workspace
        </StylishNewButton>
      </div>

      <div className="d-flex flex-column flex-md-row align-items-md-center mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <p className="m-0 me-3 text-nowrap">{total} Workspaces</p>
          <div className="">
            <PaginationDropdown
              setPage={setPage}
              setPerPage={setPerPage}
              options={[
                { value: 10, label: '10 per page' },
                { value: 30, label: '30 per page' },
                { value: 60, label: '60 per page' },
                { value: 120, label: '120 per page' },
              ]}
            />
          </div>
        </div>
        <div className="ms-md-auto mt-3 mt-md-0">
          <StylishNewSearchBar
            value={searchTerm}
            onChangeSearchTerm={onChangeSearchTerm}
          />
        </div>
      </div>
      <div className="form-block">
        <StylishNewTable
          keyField="displayName"
          columns={columns}
          rows={allWorkspaceList}
          // onEdit={onEditWorkspaceList}
          pagination
          page={page}
          perPage={perPage}
          total={total}
          onPageChange={(page) => setPage(page)}
        />
      </div>
      {showWorkspaceListEditDialog && (
        <AddEditWorkspace
          show={showWorkspaceListEditDialog}
          onClose={onCloseDialog}
          dialogType={dialogType}
          updateDialogData={updateDialogData}
        />
      )}
      {showWorkspaceListDeleteDialog && (
        <StylishConfirmDialog
          show={showWorkspaceListDeleteDialog}
          onClose={onCloseDialog}
          dialogType={dialogType}
          dialogTitle={'Delete Workspace'}
          dialogContent={'Are you sure you want to delete this Workspace?'}
          updateDialogData={updateDialogData}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
}
