import { Offcanvas, Row } from 'react-bootstrap';
import React from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { EditEntityDrawerProps } from './EntityListProps';
import {
  IncidentTactic,
  useEditIncidentTactic,
} from './hooks/incidentTacticHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import { ParentRelationships } from './ParentRelationships';
import { useIncidentStrategies } from './hooks/incidentStrategyHooks';
import RelationshipPickerField from './formFields/RelationshipPickerField';
import { useWorkAssignments } from './hooks/workAssignmentHooks';
import { useIncidentResourceRequirements } from './hooks/incidentResourceRequirementHooks';

type FormFields = {
  name: string;
  description: string;
  work_assignments: UUID[];
  incident_resources: UUID[];
};

const TacticEditDrawer: React.FC<EditEntityDrawerProps<IncidentTactic>> = ({
  toggle,
  selectedEntity: editedEntity,
}) => {
  const form = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      name: editedEntity?.name ?? '',
      description: editedEntity?.description ?? '',
      work_assignments: editedEntity?.work_assignments ?? [],
      incident_resources: editedEntity?.incident_resources ?? [],
    },
  });
  const { handleSubmit } = form;

  const incidentStrategies = useIncidentStrategies((iss) => {
    return editedEntity.strategies
      .map((strategyId) => iss.find((is) => is.strategy_id === strategyId))
      .filter((x) => !!x);
  });

  const workAssignmentOptions = useWorkAssignments();
  const incidentResourceOptions = useIncidentResourceRequirements();

  const mutation = useEditIncidentTactic(editedEntity.tactic_id);

  const onNewTacticDataSubmit = async (formData: FormFields) => {
    const payload = {
      tactic_id: editedEntity.tactic_id,
      name: formData.name,
      description: formData.description,
      archived: false,
      work_assignments: formData.work_assignments,
      incident_resources: formData.incident_resources,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  const toggleActive = async () => {
    if (editedEntity.strategies.length > 0) return;
    await mutation.mutateAsync({
      ...editedEntity,
      archived: !editedEntity.archived,
    });
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Edit Tactic">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onNewTacticDataSubmit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextField
                title="Name"
                fieldName="name"
                rules={{
                  required: { value: true, message: 'This field is required' },
                  maxLength: {
                    value: 300,
                    message: 'The maximum length is 300 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Description"
                fieldName="description"
                rules={{
                  maxLength: {
                    value: 300,
                    message: 'The maximum length is 300 characters',
                  },
                }}
                variant="full"
              />
            </Row>
            <Row>
              <ParentRelationships
                label="Strategies"
                parents={incidentStrategies.data}
              />
            </Row>
            <Row
              style={{
                maxHeight: '50vh',
                overflow: 'auto',
              }}
            >
              <RelationshipPickerField
                label={'Work Assignments'}
                fieldName={'work_assignments'}
                defaultValue={editedEntity.work_assignments}
                options={workAssignmentOptions.data}
                identity={(entity) => entity?.work_assignment_id}
                title={(entity) => entity?.work_assignment?.substring(0, 40)}
                cardType={'work-assignment'}
              />
            </Row>
            <Row
              style={{
                maxHeight: '50vh',
                overflow: 'auto',
              }}
            >
              <RelationshipPickerField
                label={'Incident Resource Requirements'}
                fieldName={'incident_resources'}
                defaultValue={editedEntity.incident_resources}
                options={incidentResourceOptions.data}
              />
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={toggleActive}
                disabled={editedEntity.strategies.length > 0}
              >
                {(!editedEntity.archived&&"Deactivate"||"Activate")}
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary button--reverse"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default TacticEditDrawer;
