import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewICSActionDialog from 'components/ICS/NewICSActionDialog';

import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

import ICSIncidentAction from './ICSIncidentAction';

import { updateIcsAction } from 'slices/ics_actionsSlice';

import {
  createIcsIncidentAction,
  updateIcsIncidentAction,
} from 'slices/ics_incidentActionsSlice';

export default function ICSActionsDialog({
  show,
  onClose,
  ics_actions,
  ics_incident_actions,
  incident_id = null,
  incident_roster
}) {
  const reduxDispatch = useDispatch();

  const [isNewActionDialogOpen, setIsNewActionDialogOpen] = useState(false);
  const [modalType, setModalType] = useState('New');
  const [editActionData, setEditActionData] = useState();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [archiveConfirmationData, setArchiveConfirmationData] = useState(null);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const [dialogActions, setDialogActions] = useState([...ics_incident_actions]);

  useEffect(() => {
    setDialogActions(ics_incident_actions);
  }, [ics_incident_actions]);

  useEffect(() => {
    // Use to rerender
  }, [ics_actions]);

  let allColumns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      attrs: { title: 'Name' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      attrs: { title: 'Description' },
    },
  ];

  if (!!incident_id) {
    allColumns = [
      ...allColumns,
      // {
      //   dataField: 'poc',
      //   text: 'POC',
      //   sort: true,
      //   attrs: { title: 'POC' },
      // },
      // {
      //   dataField: 'briefed_poc',
      //   text: 'Briefed POC',
      //   sort: true,
      //   attrs: { title: 'Briefed POC' },
      // },
      // {
      //   dataField: 'start_date',
      //   text: 'Start Date',
      //   sort: true,
      //   attrs: { title: 'Start Date' },
      // },
      // {
      //   dataField: 'target_date',
      //   text: 'Target Date',
      //   sort: true,
      //   attrs: { title: 'Target Date' },
      // },
      // {
      //   dataField: 'actual_Date',
      //   text: 'Actual Date',
      //   sort: true,
      //   attrs: { title: 'Actual Date' },
      // },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        attrs: { title: 'Status' },
      },
      // {
      //   dataField: 'timestamp',
      //   text: 'Timestamp',
      //   sort: true,
      //   attrs: { title: 'Timestamp' },
      // },
      {
        dataField: 'user_name',
        text: 'Last Modified By',
        sort: true,
        attrs: { title: 'Last Modified By' },
      },
      {
        dataField: 'archived',
        text: 'Archived',
        sort: true,
        attrs: { title: 'Archived' },
      },
    ];
  } else {
    allColumns = [
      ...allColumns,
      {
        dataField: 'archived',
        text: 'Archived',
        sort: true,
        attrs: { title: 'Archived' },
      },
    ];
  }

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <ICSIncidentAction
            row={row}
            ics_actions={ics_actions}
            ics_incident_actions={ics_incident_actions}
            incident_id={incident_id}
          />
          {(!incident_id ||
            !!ics_incident_actions.find((ia) => ia.action_id === row.id)) && (
            <div className="button-group">
              <StylishNewButton
                className="button--primary"
                onClick={() => onEdit(row)}
              >
                Edit
              </StylishNewButton>
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={() => onArchive(row)}
              >
                {row.archived ? `Unarchive` : `Archive`}
              </StylishNewButton>
            </div>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    selected: ics_actions
      .filter((a) => {
        const incAction = ics_incident_actions.find(
          (ia) => ia.action_id === a.id && !ia.archived
        );
        return !!incAction;
      })
      .map((a) => a.id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex) => {
      const existingIncidentAction = ics_incident_actions.find(
        (ia) => ia.action_id === row.id
      );
      if (existingIncidentAction) {
        reduxDispatch(
          updateIcsIncidentAction(incident_id, existingIncidentAction.id, {
            ...existingIncidentAction,
            checklist: JSON.stringify(existingIncidentAction.checklist),
            archived: !existingIncidentAction.archived,
          })
        );
      } else {
        // Create a new incident action
        const incidentActionData = {
          action_id: row.id,
          name: row.name,
          description: row.description,
          user_guid: row.user_guid,
          incident_id: incident_id,
          group_guid: reduxCurrentlySelectedGroup.group_guid,
          checklist: JSON.stringify(row.checklist),
          archived: false,
        };
        reduxDispatch(createIcsIncidentAction(incident_id, incidentActionData));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setDialogActions(rows) : setDialogActions([]);
    },
  };

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewActionDialogOpen(true);
    setEditActionData({
      ...row,
      versions: ics_incident_actions.find((ia) => ia.action_id === row.id)
        ?.versions,
    });
  };

  const onArchive = (row) => {
    setShowConfirmDialog(true);
    setArchiveConfirmationData(row);
  };

  const onConfirmedArchiveActionData = () => {
    const payload = {
      ...archiveConfirmationData,
      incident_id,
      archived: !archiveConfirmationData.archived,
      checklist: JSON.stringify(archiveConfirmationData.checklist)
    };

    if (!!incident_id) {
      reduxDispatch(
        updateIcsIncidentAction(
          incident_id,
          archiveConfirmationData.id,
          payload
        )
      );
    } else {
      reduxDispatch(updateIcsAction(archiveConfirmationData.id, payload));
    }

    setShowConfirmDialog(false);
    setArchiveConfirmationData(null);
  };

  const onNewAction = () => {
    setModalType('New');
    setIsNewActionDialogOpen(true);
  };

  const tableRows = ics_actions.map((a) => {
    const incAction = ics_incident_actions.find((ia) => ia.action_id === a.id);
    if (incAction) {
      return {
        ...incAction,
        id: a.id,
      };
    } else {
      return a;
    }
  });

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Select Incident Actions</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">
          {reduxCurrentlySelectedGroup?.group_name}
        </p>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={onNewAction}
        >
          New Action
        </StylishNewButton>
        <div>
          <label>
            {!!incident_id && (
              <>
                Select to add to incident. De-select to archive. Re-select to
                un-archive.
              </>
            )}
          </label>
        </div>
        <StylishNewTable
          keyField={'id'}
          rows={tableRows}
          columns={allColumns}
          expandRow={expandRow}
          selectRow={(!!incident_id && selectRow) || undefined}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewActionDialogOpen && (
        <NewICSActionDialog
          show={isNewActionDialogOpen}
          setEditActionData={setEditActionData}
          setModalType={setModalType}
          editActionData={editActionData}
          modalType={modalType}
          onClose={() => setIsNewActionDialogOpen(false)}
          incident_id={incident_id}
          incident_roster={incident_roster}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Archive Confirmation'}
          dialogContent={'Are you sure you want to archive this Action?'}
          dialogButtonText={'Archive'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedArchiveActionData}
        />
      )}
    </Modal>
  );
}
