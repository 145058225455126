import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export default function StylishNewTableAction({
  name,
  // cell,
  row,
  // rowIndex,
  // onAdd,
  onDelete,
  onEdit,
  onView,
  onAddOn,
}) {
  return (
    <>
      <Dropdown className="dropdown-actions">
        <Dropdown.Toggle className="button--icon">
          <SharedIcon iconName="more_vert" bold />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          {onView && (
            <Dropdown.Item href="#" onClick={() => onView(row)}>
              <SharedIcon iconName="visibility" />
              View {name && name}
            </Dropdown.Item>
          )}
          {onEdit && (
            <Dropdown.Item href="#" onClick={() => onEdit(row)}>
              <SharedIcon iconName="stylus" />
              Edit {name && name}
            </Dropdown.Item>
          )}
          {onDelete && (
            <Dropdown.Item href="#" onClick={() => onDelete(row)}>
              <SharedIcon iconName="delete" />
              Delete {name && name}
            </Dropdown.Item>
          )}
          {onAddOn && (
            <Dropdown.Item href="#" onClick={() => onAddOn(row)}>
              <SharedIcon iconName="add" />
              Add-On for {name && name}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
