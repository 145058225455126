import { Offcanvas, Row } from 'react-bootstrap';
import React from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { EditEntityDrawerProps } from './EntityListProps';
import {
  IncidentObjective,
  useEditIncidentObjective,
} from './hooks/incidentObjectiveHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import RelationshipPickerField from './formFields/RelationshipPickerField';
import { useStrategies } from './hooks/strategyHooks';

type FormFields = {
  name: string;
  description: string;
  strategies: UUID[];
};

const ObjectiveEditDrawer: React.FC<
  EditEntityDrawerProps<IncidentObjective>
> = ({ toggle, selectedEntity: editedEntity }) => {
  const form = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      name: editedEntity?.name ?? '',
      description: editedEntity?.description ?? '',
      strategies: editedEntity?.strategies ?? [],
    },
  });
  const { handleSubmit } = form;

  const strategyOptions = useStrategies();

  const mutation = useEditIncidentObjective(editedEntity.objective_id);

  const onNewObjectiveDataSubmit = async (formData: FormFields) => {
    const payload = {
      objective_id: editedEntity.objective_id,
      name: formData.name,
      description: formData.description,
      strategies: formData.strategies,
      archived: false,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  const toggleActive = async () => {
    await mutation.mutateAsync({
      ...editedEntity,
      archived: !editedEntity.archived,
    });
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Edit Objective">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onNewObjectiveDataSubmit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextField
                title="Name"
                fieldName="name"
                rules={{
                  required: { value: true, message: 'This field is required' },
                  maxLength: {
                    value: 300,
                    message: 'This maximum length is 300 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Description"
                fieldName="description"
                rules={{
                  maxLength: {
                    value: 300,
                    message: 'This maximum length is 300 characters',
                  },
                }}
                variant="full"
              />
            </Row>
            <Row style={{
              maxHeight: '50vh',
              overflow: 'auto',
            }}>
              <RelationshipPickerField
                label={`Strategies`}
                fieldName="strategies"
                defaultValue={editedEntity.strategies}
                options={ strategyOptions.data }
              />
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={toggleActive}
              >
                {(!editedEntity.archived&&"Deactivate"||"Activate")}
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default ObjectiveEditDrawer;
