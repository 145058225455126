import config from 'constants/apiConfig';
import axios from 'axios';

import {
  updateCurrentlySelected,
  fetchIncidents,
} from 'actions/profileActions';

import {
  FETCH_AI2FP_DATA_REQUEST,
  FETCH_AI2FP_DATA_SUCCESS,
  FETCH_AI2FP_DATA_ERROR,
  FETCH_CORE_COMPONENTS_REQUEST,
  FETCH_CORE_COMPONENTS_SUCCESS,
  FETCH_CORE_COMPONENTS_ERROR,
  UPSERT_CORE_COMPONENT_REQUEST,
  UPSERT_CORE_COMPONENT_SUCCESS,
  UPSERT_CORE_COMPONENT_ERROR,
  LINK_CORE_COMPONENTS_REQUEST,
  LINK_CORE_COMPONENTS_SUCCESS,
  LINK_CORE_COMPONENTS_ERROR,
  UNLINK_CORE_COMPONENTS_REQUEST,
  UNLINK_CORE_COMPONENTS_SUCCESS,
  UNLINK_CORE_COMPONENTS_ERROR,
  DELETE_CORE_COMPONENT_REQUEST,
  DELETE_CORE_COMPONENT_SUCCESS,
  DELETE_CORE_COMPONENT_ERROR,
  UPDATE_CURRENT_AOR_REQUEST,
  UPDATE_CURRENT_AOR_SUCCESS,
  UPDATE_CURRENT_AOR_ERROR,
  FETCH_THRESHOLDS_FOR_AOR_REQUEST,
  FETCH_THRESHOLDS_FOR_AOR_SUCCESS,
  FETCH_THRESHOLDS_FOR_AOR_ERROR,
  UPSERT_THRESHOLD_REQUEST,
  UPSERT_THRESHOLD_SUCCESS,
  UPSERT_THRESHOLD_ERROR,
  UPDATE_CURRENT_ANOMALY_REQUEST,
  UPDATE_CURRENT_ANOMALY_SUCCESS,
  UPDATE_CURRENT_ANOMALY_ERROR,
  FETCH_ANOMALIES_REQUEST,
  FETCH_ANOMALIES_SUCCESS,
  FETCH_ANOMALIES_ERROR,
  ARCHIVE_ANOMALY_REQUEST,
  ARCHIVE_ANOMALY_SUCCESS,
  ARCHIVE_ANOMALY_ERROR,
  ANOMALY_TO_INCIDENT_REQUEST,
  ANOMALY_TO_INCIDENT_SUCCESS,
  ANOMALY_TO_INCIDENT_ERROR,
} from 'constants/ai2fp-action-types';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';
import { endLoading } from 'reducers/loading/loading.action';

function fetchAI2FPDataRequest() {
  return {
    type: FETCH_AI2FP_DATA_REQUEST,
  };
}

function fetchAI2FPDataSuccess(cc_table_name, cc_table_id, data) {
  return {
    type: FETCH_AI2FP_DATA_SUCCESS,
    payload: { cc_table_name, cc_table_id, data },
  };
}

function fetchAI2FPDataError(error) {
  const errorMessage =
    'fetchAI2FPDataError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_AI2FP_DATA_ERROR,
  };
}

export const fetchAI2FPData = (cc_table_name, cc_table_id) => {
  return (dispatch, getState) => {
    dispatch(fetchAI2FPDataRequest());
    const { currentAOR } = getState().app;
    const aor_id = !!currentAOR && currentAOR.id;
    return axios(config.apiGateway.fetchAI2FPData, {
      method: 'POST',
      data: JSON.stringify({
        cc_table_name,
        cc_table_id,
        aor_id,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(fetchAI2FPDataSuccess(cc_table_name, cc_table_id, data));
      })
      .catch((error) => {
        dispatch(fetchAI2FPDataError(error));
      });
  };
};

function fetchCoreComponentsRequest() {
  return {
    type: FETCH_CORE_COMPONENTS_REQUEST,
  };
}

function fetchCoreComponentsSuccess(data) {
  return {
    type: FETCH_CORE_COMPONENTS_SUCCESS,
    payload: data,
  };
}

function fetchCoreComponentsError(error) {
  const errorMessage =
    'fetchCoreComponentsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_CORE_COMPONENTS_ERROR,
  };
}

export const fetchCoreComponents = (group_guid) => {
  return (dispatch) => {
    dispatch(fetchCoreComponentsRequest());
    return axios(config.apiGateway.fetchCoreComponents, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(fetchCoreComponentsSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchCoreComponentsError(error));
      });
  };
};

function upsertCoreComponentRequest() {
  return {
    type: UPSERT_CORE_COMPONENT_REQUEST,
  };
}

function upsertCoreComponentSuccess(data) {
  return {
    type: UPSERT_CORE_COMPONENT_SUCCESS,
    payload: data,
  };
}

function upsertCoreComponentError(error) {
  const errorMessage =
    'upsertCoreComponentError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPSERT_CORE_COMPONENT_ERROR,
  };
}

export const upsertCoreComponent = (cc) => {
  return (dispatch) => {
    dispatch(upsertCoreComponentRequest());
    return axios(config.apiGateway.upsertCoreComponent, {
      method: 'POST',
      data: JSON.stringify({
        cc,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(fetchCoreComponents(cc.group_guid));
        dispatch(upsertCoreComponentSuccess(data));
        // toast.success('Upsert Core Components successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(upsertCoreComponentError(error));
      });
  };
};

function linkCoreComponentsRequest() {
  return {
    type: LINK_CORE_COMPONENTS_REQUEST,
  };
}

function linkCoreComponentsSuccess(data) {
  return {
    type: LINK_CORE_COMPONENTS_SUCCESS,
    payload: data,
  };
}

function linkCoreComponentsError(error) {
  const errorMessage =
    'linkCoreComponentsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: LINK_CORE_COMPONENTS_ERROR,
  };
}

export const linkCoreComponents = (cc1, cc2) => {
  return (dispatch) => {
    dispatch(linkCoreComponentsRequest());
    return axios(config.apiGateway.linkCoreComponents, {
      method: 'POST',
      data: JSON.stringify({
        cc1,
        cc2,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(fetchCoreComponents(cc1.group_guid));
        dispatch(linkCoreComponentsSuccess(data));
        // toast.success('Link Core Components successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(linkCoreComponentsError(error));
      });
  };
};

function unlinkCoreComponentsRequest() {
  return {
    type: UNLINK_CORE_COMPONENTS_REQUEST,
  };
}

function unlinkCoreComponentsSuccess(data) {
  return {
    type: UNLINK_CORE_COMPONENTS_SUCCESS,
    payload: data,
  };
}

function unlinkCoreComponentsError(error) {
  const errorMessage =
    'unlinkCoreComponentsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UNLINK_CORE_COMPONENTS_ERROR,
  };
}

export const unlinkCoreComponents = (cc1, cc2) => {
  return (dispatch) => {
    dispatch(unlinkCoreComponentsRequest());
    return axios(config.apiGateway.unlinkCoreComponents, {
      method: 'POST',
      data: JSON.stringify({
        cc1,
        cc2,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(unlinkCoreComponentsSuccess(data));
        dispatch(fetchCoreComponents(cc1.group_guid));
        // toast.success('Unlink Core Components successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(unlinkCoreComponentsError(error));
      });
  };
};

function deleteCoreComponentRequest() {
  return {
    type: DELETE_CORE_COMPONENT_REQUEST,
  };
}

function deleteCoreComponentSuccess(data) {
  return {
    type: DELETE_CORE_COMPONENT_SUCCESS,
    payload: data,
  };
}

function deleteCoreComponentError(error, type) {
  // const errorMessage =
  // 'deleteCoreComponentError\n\n' +
  // ((!!error &&
  //   !!error.message &&
  //   typeof error.message === 'string' &&
  //   error.message) ||
  //   '') +
  // '\n\nIf you continue to experience this error, contact support@disastertech.com';
  let errorMessage;
  if (error.response['data'].ERROR.includes('Foreign Key Violation')) {
    errorMessage = `We can't delete this ${type}, because this is associated with other functionalities`;
  } else {
    errorMessage =
      'deleteCoreComponentError\n\n' +
      ((!!error &&
        !!error.message &&
        typeof error.message === 'string' &&
        error.message) ||
        '') +
      '\n\nIf you continue to experience this error, contact support@disastertech.com';
  }

  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: DELETE_CORE_COMPONENT_ERROR,
  };
}

export const deleteCoreComponent = (cc) => {
  return (dispatch) => {
    dispatch(deleteCoreComponentRequest());
    return axios(config.apiGateway.deleteCoreComponent, {
      method: 'POST',
      data: JSON.stringify({
        cc,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(deleteCoreComponentSuccess(data));
        dispatch(fetchCoreComponents(cc.group_guid));
        // toast.success('Delete Core Components successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(deleteCoreComponentError(error, cc.cc_type));
        dispatch(endLoading());
      });
  };
};

function updateCurrentAORRequest() {
  return {
    type: UPDATE_CURRENT_AOR_REQUEST,
  };
}

function updateCurrentAORSuccess(aor) {
  return {
    type: UPDATE_CURRENT_AOR_SUCCESS,
    payload: aor,
  };
}

function updateCurrentAORError(error) {
  const errorMessage =
    'updateCurrentAORError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_CURRENT_AOR_ERROR,
  };
}

export const updateCurrentAOR = (aor) => {
  return (dispatch, getState) => {
    dispatch(updateCurrentAORRequest());
    const { currently_selected } = getState().app.user;
    let new_currently_selected = {
      ...currently_selected,
      aor_id: !!aor && aor.id,
    };
    return axios(config.apiGateway.updateCurrentlySelected, {
      method: 'POST',
      data: JSON.stringify({
        currently_selected: new_currently_selected,
      }),
    })
      .then(() => {
        dispatch(updateCurrentAORSuccess(aor));
        // toast.success('Update Currently Selected successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(updateCurrentAORError(error));
      });
  };
};

function fetchThresholdsForAORRequest() {
  return {
    type: FETCH_THRESHOLDS_FOR_AOR_REQUES,
  };
}

function fetchThresholdsForAORSuccess(thresholds) {
  return {
    type: FETCH_THRESHOLDS_FOR_AOR_SUCCESS,
    payload: thresholds,
  };
}

function fetchThresholdsForAORError(error) {
  const errorMessage =
    'fetchThresholdsForAORError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_THRESHOLDS_FOR_AOR_ERROR,
  };
}

export const fetchThresholdsForAOR = (aor) => {
  return (dispatch) => {
    dispatch(fetchThresholdsForAORRequest());
    return axios(config.apiGateway.fetchThresholdsForAOR, {
      method: 'POST',
      data: JSON.stringify({
        aor,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(fetchThresholdsForAORSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchThresholdsForAORError(error));
      });
  };
};

function upsertThresholdRequest() {
  return {
    type: UPSERT_THRESHOLD_REQUEST,
  };
}

function upsertThresholdSuccess(threshold) {
  return {
    type: UPSERT_THRESHOLD_SUCCESS,
    payload: threshold,
  };
}

function upsertThresholdError(error) {
  const errorMessage =
    'upsertThresholdError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPSERT_THRESHOLD_ERROR,
  };
}

export const upsertThreshold = (threshold) => {
  return (dispatch, getState) => {
    dispatch(upsertThresholdRequest());
    const { currentAOR } = getState().app;
    return axios(config.apiGateway.upsertThreshold, {
      method: 'POST',
      data: JSON.stringify({
        threshold,
      }),
    })
      .then(() => {
        dispatch(fetchThresholdsForAOR(currentAOR));
        dispatch(upsertThresholdSuccess(threshold));
        // toast.success('Upsert Threshold', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(upsertThresholdError(error));
      });
  };
};

function updateCurrentAnomalyRequest() {
  return {
    type: UPDATE_CURRENT_ANOMALY_REQUEST,
  };
}

function updateCurrentAnomalySuccess(anomaly) {
  return {
    type: UPDATE_CURRENT_ANOMALY_SUCCESS,
    payload: anomaly,
  };
}

function updateCurrentAnomalyError(error) {
  const errorMessage =
    'updateCurrentAnomalyError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_CURRENT_ANOMALY_ERROR,
  };
}

export const updateCurrentAnomaly = (anomaly) => {
  return (dispatch, getState) => {
    try {
      dispatch(updateCurrentAnomalyRequest());
      dispatch(updateCurrentAnomalySuccess(anomaly));
    } catch (e) {
      dispatch(updateCurrentAnomalyError(error));
    }
  };
};

function fetchAnomaliesRequest() {
  return {
    type: FETCH_ANOMALIES_REQUEST,
  };
}

function fetchAnomaliesSuccess(anomalies) {
  return {
    type: FETCH_ANOMALIES_SUCCESS,
    payload: anomalies,
  };
}

function fetchAnomaliesError(error) {
  const errorMessage =
    'fetchAnomaliesError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_ANOMALIES_ERROR,
  };
}

export const fetchAnomalies = () => {
  return (dispatch, getState) => {
    dispatch(fetchAnomaliesRequest());
    const { currentAOR } = getState().app;
    return axios(config.apiGateway.fetchAnomalies, {
      method: 'POST',
      data: JSON.stringify({
        aor_id: currentAOR.id,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(fetchAnomaliesSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchAnomaliesError(error));
      });
  };
};

function archiveAnomalyRequest() {
  return {
    type: ARCHIVE_ANOMALY_REQUEST,
  };
}

function archiveAnomalySuccess(archivedAnomaly) {
  return {
    type: ARCHIVE_ANOMALY_SUCCESS,
    payload: archivedAnomaly,
  };
}

function archiveAnomalyError(error) {
  const errorMessage =
    'archiveAnomalyError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: ARCHIVE_ANOMALY_ERROR,
  };
}

export const archiveAnomaly = (anomaly_id) => {
  return (dispatch, getState) => {
    dispatch(archiveAnomalyRequest());
    return axios(config.apiGateway.archiveAnomaly, {
      method: 'POST',
      data: JSON.stringify({
        anomaly_id: anomaly_id,
      }),
    })
      .then((response) => {
        const data = response.data || {};
        dispatch(fetchAnomalies());
        dispatch(archiveAnomalySuccess(data));
        // toast.success('Archive Anomaly successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(archiveAnomalyError(error));
      });
  };
};

function anomalyToIncidentRequest() {
  return {
    type: ANOMALY_TO_INCIDENT_REQUEST,
  };
}

function anomalyToIncidentSuccess(anomaly, incident, dashboard) {
  return {
    type: ANOMALY_TO_INCIDENT_SUCCESS,
    payload: { anomaly, incident, dashboard },
  };
}

function anomalyToIncidentError(error) {
  const errorMessage =
    'anomalyToIncidentError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: ANOMALY_TO_INCIDENT_ERROR,
  };
}

export const anomalyToIncident = (anomaly, incident, dashboard) => {
  return (dispatch, getState) => {
    dispatch(anomalyToIncidentRequest());

    const { user_guid } = getState().app.user;
    return axios(config.apiGateway.createIncident, {
      method: 'POST',
      data: JSON.stringify({
        user_guid: user_guid,
        name: incident.name.trim(),
        group_guid: incident.group_guid,
        severity: incident.severity,
        type: incident.incidentType,
        incident_types: [incident.incidentType],
      }),
    })
      .then((response) => {
        const incident = response.data || {};
        axios(config.apiGateway.anomalyToIncident, {
          method: 'POST',
          data: JSON.stringify({
            anomaly_id: anomaly.id,
            incident_id: incident.id,
            anomaly_dashboard_id: dashboard.id,
          }),
        })
          .then((response) => {
            // TODO create an Incident, then use that to fill this
            const data = response.data || {};
            dispatch(fetchAnomalies());
            dispatch(fetchIncidents());
            dispatch(anomalyToIncidentSuccess(anomaly, incident, dashboard));
            // toast.success('Anomaly to Incident successful', {
            //   autoClose: 2000,
            // });
          })
          .catch((error) => {
            dispatch(anomalyToIncidentError(error));
          });
      })
      .catch((error) => {
        // Handle exception where name exists
        if (
          error.response &&
          error.response.data &&
          error.response.data.ERROR &&
          error.response.data.ERROR === 'Incident Name Exists'
        ) {
          dispatch(
            anomalyToIncidentError({
              message: 'This Incident name is in use.  Please enter a new one.',
            })
          );
        } else {
          dispatch(anomalyToIncidentError(error));
        }
      });
  };
};
