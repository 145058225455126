import React, { useEffect, useState } from 'react';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import dayjs from 'dayjs';

export default function ICSIncidentResourceRequest({
  row,
  ics_resource_requests,
  ics_incident_resource_requests,
}) {
  const versions = ics_incident_resource_requests.find(
    (ir) => ir.resource_request_id === row.id
  )?.versions;

  const [version, setVersion] = useState(!!versions && versions[0]);

  const [name, setName] = useState(row.name);
  const [timestamp, setTimestamp] = useState(row.timestamp);
  const [archived, setArchived] = useState(row.archived);
  const [lastModifiedBy, setLastModifiedBy] = useState(row.user_name);

  const [nameChanged, setNameChanged] = useState(false);
  const [timestampChanged, setTimestampChanged] = useState(false);
  const [archivedChanged, setArchivedChanged] = useState(false);
  const [lastModifiedByChanged, setLastModifiedByChanged] = useState(false);

  useEffect(() => {
    if (!!version) {
      setName(version.name);
      setTimestamp(version.timestamp);
      setArchived(version.archived);
      setLastModifiedBy(version.user_name);

      // Check if changed from prior
      const vi = versions.findIndex((v) => v.id === version.id);
      if (!!versions[vi + 1]) {
        setNameChanged(versions[vi + 1].name !== version.name);
        setTimestampChanged(versions[vi + 1].timestamp !== version.timestamp);
        setArchivedChanged(versions[vi + 1].archived !== version.archived);
        setLastModifiedByChanged(
          versions[vi + 1].user_name !== version.user_name
        );
      } else {
        setNameChanged(false);
        setTimestampChanged(false);
        setArchivedChanged(false);
        setLastModifiedByChanged(false);
      }
    } else {
      setName(row.name);
      setTimestamp(row.timestamp);
      setArchived(row.archived);
      setLastModifiedBy(row.user_name);
      setNameChanged(false);
      setTimestampChanged(false);
      setArchivedChanged(false);
      setLastModifiedByChanged(false);
    }
  }, [version]);

  useEffect(() => {
    if (!!versions) {
      setVersion(versions[0]);
    }
  }, [versions]);

  return (
    <div className="ICSIncidentResourceRequest">
      {!!versions && (
        <div className="mb-4">
          <h6 className="mb-2">Version:</h6>
          <StylishNewSelect
            options={versions.map((v) => {
              return {
                label: dayjs(v.timestamp).format('YYYY-MM-DD HH:mm'),
                value: v,
              };
            })}
            defaultValue={version}
            value={dayjs(version.timestamp).format('YYYY-MM-DD HH:mm')}
            onChange={(e) => {
              setVersion(e.value);
            }}
            placeholder={dayjs(version.timestamp).format('YYYY-MM-DD HH:mm')}
            isClearable={true}
            isSearchable={true}
            isMulti={false}
            isDisabled={false}
            className="SitrepSection-Select"
          />
        </div>
      )}
      <div className="mb-4">
        <h6 className="mb-2">Name:</h6>
        <p className="m-0" style={{ color: nameChanged ? 'yellow' : 'white' }}>
          {name}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Timestamp:</h6>
        <p
          className="m-0"
          style={{ color: timestampChanged ? 'yellow' : 'white' }}
        >
          {dayjs(timestamp).format('YYYY-MM-DD HH:mm')}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Archived:</h6>
        <p
          className="m-0"
          style={{ color: archivedChanged ? 'yellow' : 'white' }}
        >
          {archived ? 'Yes' : 'No'}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Last Modified By:</h6>
        <p
          className="m-0"
          style={{ color: lastModifiedByChanged ? 'yellow' : 'white' }}
        >
          {lastModifiedBy}
        </p>
      </div>
    </div>
  );
}
