import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import gjv from 'geojson-validation';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

import * as shapefile from 'shapefile';
import proj4 from 'proj4';

import { v4 as uuidv4 } from 'uuid';

import * as turf from '@turf/turf';

import { StylishNewButton as Button } from 'components/DesignSystems/New/StylishNewButton';
import SearchIcon from '@mui/icons-material/Search';

import './GeoLocationComponent.css';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DebounceInput } from 'react-debounce-input';
import classNames from 'classnames';

gjv.define('Position', (position) => {
  //the postion must be valid point on the earth, x between -180 and 180
  const errors = [];
  if (position[0] < -180 || position[0] > 180) {
    errors.push('the x must be between -180 and 180');
  }
  if (position[1] < -90 || position[1] > 90) {
    errors.push('the y must be between -90 and 90');
  }
  return errors;
});

const sampleDrawSource = {
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [],
        },
        generateId: true,
      },
    ],
  },
};
export const samplePointSource = {
  id: '',
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [],
        },
        generateId: true,
      },
    ],
  },
};

export const sampleDrawLayer = {
  id: '',
  type: 'fill',
  source: '', // reference the data source
  layout: {},
  paint: {
    'fill-color': '#0080ff', // blue color fill
    'fill-opacity': 0.5,
    'fill-antialias': true,
    'fill-outline-color': '#000000',
  },
  metadata: {
    usermade: true,
  },
};
export const samplePointLayer = {
  id: '',
  type: 'circle',
  source: '', // reference the data source
  layout: {},
  paint: {
    'circle-color': '#0080ff', // blue color fill
    'circle-opacity': 0.5,
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      1,
      1,
      5,
      2,
      10,
      3,
      15,
      5,
      20,
      10,
      24,
      20,
    ],
  },
  metadata: {
    usermade: true,
  },
};

export default function GeoLocationComponent({
  location,
  setLocation,
  mapboxToken=null,
  isShowMarker = false,
  markerColor = 'blue',
  hideSelectLocationButton = false,
  readOnly = false,
}) {
  const mapboxTokenActual = window.env.MAPBOX_ACCESS_TOKEN || process.env.MAPBOX_ACCESS_TOKEN || mapboxToken;
  mapboxgl.accessToken = mapboxTokenActual || mapboxToken;


  const mapContainer = useRef(null);
  const map = useRef(null);
  const drawRef = useRef(null);
  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });
  const [findLocationActive, setFindLocationActive] = useState(false);

  const [lng, setLng] = useState(-77);
  const [lat, setLat] = useState(39);
  const [zoom, setZoom] = useState(3);
  const [basemap, setBasemap] = useState(
    'mapbox://styles/mapbox/satellite-streets-v11'
  );
  const [selectedLat, setSelectedLat] = useState(null);
  const [selectedLng, setSelectedLng] = useState(null);

  const [nameEditable, setNameEditable] = useState(false);
  const [addressEditable, setAddressEditable] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [editedAddress, setEditedAddress] = useState('');

  useEffect(() => {
    if (!!nameEditable) {
      setEditedName(location.name);
    }
  }, [nameEditable]);

  useEffect(() => {
    if (!!addressEditable) {
      setEditedAddress(location.address);
    }
  }, [addressEditable]);

  function saveName() {
    setLocation({ ...location, name: editedName });
    setNameEditable(false);
    setEditedName('');
  }

  function cancelName() {
    setNameEditable(false);
    setEditedName('');
  }

  function saveAddress() {
    setLocation({ ...location, address: editedAddress });
    setAddressEditable(false);
    setEditedAddress('');
  }

  function cancelAddress() {
    setAddressEditable(false);
    setEditedAddress('');
  }

  // Initialize Map
  useEffect(() => {
    if (!findLocationActive || map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: basemap,
      center: [lng, lat],
      zoom: zoom,
      projection: 'mercator',
      preserveDrawingBuffer: false,
    });
    map.current.getCanvas().id = 'mapcanvas';

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
    });
    !hideSelectLocationButton && map.current.addControl(geocoder);
    geocoder.on('result', (event) => {
      const feature = { type: 'Feature', geometry: event.result.geometry };
      const layerID = 'Custom Layer';
      const sourceID = layerID + ' Source';

      let newSource = {
        ...sampleDrawSource,
        data: {
          ...sampleDrawSource.data,
          features: [{ ...feature, id: 0 }],
        },
      };
      let newLayer = {
        ...samplePointLayer,
        id: layerID,
        source: sourceID,
      };
      if (!!map.current.getSource(sourceID)) {
        map.current.removeLayer(newLayer.id);
        map.current.removeSource(sourceID);
      }

      map.current.addSource(sourceID, newSource);
      map.current.addLayer(newLayer);
      createLocationFromGeojson(feature);
    });

    !hideSelectLocationButton &&
      map.current.addControl(new mapboxgl.NavigationControl());
    map.current.addControl(new mapboxgl.FullscreenControl(), 'top-left');

    const Draw = new MapboxDraw({
      controls: {
        direct_select: true,
        combine_features: false,
        uncombine_features: false,
        line_string: false,
        point: true,
        polygon: true,
        trash: true,
      },
    });

    drawRef.current = Draw;

    // Todo: The polygon is currently underneath all the data. It should be on top.
    !hideSelectLocationButton && map.current.addControl(Draw, 'top-left');

    map.current.on('draw.create', (e) => {
      const feature = e.features[0];
      // const layerID = 'Custom Layer';
      const layerID = 'geolocation_tile_layer';
      // const sourceID = layerID + ' Source';
      const sourceID = 'geolocation_tile_source';

      let newSource = {
        ...sampleDrawSource,
        data: {
          ...sampleDrawSource.data,
          features: [{ ...feature, id: 0 }],
        },
      };

      delete newSource.id;

      let newLayer;

      if (feature.geometry.type === 'Point') {
        newLayer = {
          ...samplePointLayer,
          id: layerID,
          source: sourceID,
        };
      } else {
        newLayer = {
          ...sampleDrawLayer,
          id: layerID,
          source: sourceID,
        };
      }

      // Layers have to be added directly here, then inserted into hooks, because event based programming "Freezes" state variables at declaration time
      if (!!map.current.getSource(sourceID)) {
        map.current.removeLayer(newLayer.id);
        map.current.removeSource(sourceID);
      }

      map.current.addSource(sourceID, newSource);
      map.current.addLayer(newLayer);
      // drawRef.current.trash();

      createLocationFromGeojson(feature);
    });

    map.current.on('draw.update', (e) => {});

    map.current.on('draw.delete', (e) => {
      map.current.removeLayer('geolocation_tile_layer');
      map.current.removeSource('geolocation_tile_source');
    });

    map.current.on('draw.modechange', (e) => {
      if (e.mode === 'draw_polygon') {
        Draw.deleteAll().changeMode('draw_polygon');
        map.current.getCanvas().style.cursor = 'crosshair';
      }

      if (e.mode === 'draw_point') {
        map.current.getCanvas().style.cursor = 'crosshair';
      }

      if (e.mode === 'direct_select') {
        Draw.changeMode('simple_select');
        map.current.getCanvas().style.cursor = 'grab';
      }

      if (e.mode === 'simple_select') {
        map.current.getCanvas().style.cursor = 'grab';
      }
    });

    map.current.on('idle', () => {});
  });

  // Load map defaults once map has initialized
  useEffect(() => {
    if (!findLocationActive || !map.current) return; // wait for map to initialize
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });

    // Load extras
    map.current.on('load', () => {
      if (!!location) {
        var box = turf.bbox(location.geojson.data.features[0].geometry);
        map.current.fitBounds(box, { padding: 50, maxZoom: 15 });
      }
    });

    map.current.on('resize', (e) => {
      // console.log("resize", e);
    });

    map.current.on('zoom', (e) => {});

    map.current.on('click', (e) => {});
  });

  useEffect(() => {
    if (
      !!location &&
      !!location.geojson.data &&
      drawRef &&
      drawRef.current &&
      map?.current
    ) {
      drawRef.current.add(location.geojson.data);
    }
  }, [location, drawRef?.current, map?.current]);

  useEffect(() => {
    if (!drawRef?.current) {
      const Draw = new MapboxDraw({
        controls: {
          direct_select: true,
          combine_features: false,
          uncombine_features: false,
          line_string: false,
          point: true,
          polygon: true,
          trash: true,
        },
      });

      drawRef.current = Draw;
    }
  }, [location, drawRef]);

  useEffect(() => {
    if (
      !!location &&
      !!location.geojson.data.features &&
      !!location.geojson.data.features.length
    ) {
      var box = turf.bbox(location.geojson.data.features[0].geometry);
      map?.current?.fitBounds(box, { padding: 50, maxZoom: 15 });
    }
    if (
      map.current &&
      isShowMarker &&
      location?.centroid?.geometry?.coordinates?.length > 0
    ) {
      const lngLat = location?.centroid?.geometry?.coordinates;
      const markers = document.getElementsByClassName('mapboxgl-marker');
      if (markers.length) {
        markers[0].remove();
      }
      new mapboxgl.Marker({ color: markerColor })
        .setLngLat(lngLat)
        .addTo(map.current);
      setSelectedLat(lngLat[1]);
      setSelectedLng(lngLat[0]);
    }
    const lngLat = location?.centroid?.geometry?.coordinates;
    if (!!lngLat && lngLat?.length > 0) {
      setSelectedLat(lngLat[1]);
      setSelectedLng(lngLat[0]);
    }
    document.addEventListener('webkitfullscreenchange', () =>
      setTimeout(() => map.current.resize(), 0)
    );
    return () => {
      document.removeEventListener('webkitfullscreenchange', () =>
        setTimeout(() => map.current.resize(), 0)
      );
    };
  }, [location, map.current]);

  function createLocationFromGeojson(gj) {
    // 1: Normalize all Locations to FeatureCollections
    let normalizedGeojsonFeatures = [];
    if (gj.type === 'FeatureCollection') {
      normalizedGeojsonFeatures = gj.features;
    } else if (gj.type === 'Feature') {
      normalizedGeojsonFeatures = [gj];
    }

    // 2: Get centroid if not point, otherwise create centroid
    let coordinates = normalizedGeojsonFeatures[0].geometry.coordinates;
    let centroid = coordinates;
    let placeUrl;

    if (normalizedGeojsonFeatures[0].geometry.type === 'Polygon') {
      const polygon = turf.polygon(coordinates);
      centroid = turf.centroid(polygon);
      placeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${centroid.geometry.coordinates[0]},${centroid.geometry.coordinates[1]}.json?access_token=${mapboxTokenActual}`;
    } else if (normalizedGeojsonFeatures[0].geometry.type === 'MultiPolygon') {
      const polygon = turf.multiPolygon(coordinates);
      centroid = turf.centroid(polygon);
      placeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${centroid.geometry.coordinates[0]},${centroid.geometry.coordinates[1]}.json?access_token=${mapboxTokenActual}`;
    } else if (normalizedGeojsonFeatures[0].geometry.type === 'Point') {
      centroid = normalizedGeojsonFeatures[0];
      placeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates[0]},${coordinates[1]}.json?access_token=${mapboxTokenActual}`;
    }

    // 3: Geocode for address
    fetch(placeUrl)
      .then((res) => res.json())
      .then((response) => {
        // console.log('place 1: ', response);
        const place = response.features.find(
          (f) =>
            f.id.includes('place') ||
            f.id.includes('district') ||
            f.id.includes('region') ||
            f.id.includes('country')
        );
        const placeName = (!!place && place.place_name) || '';
        // console.log('place 2: ', place);

        const addressFeature = response.features.find(
          (f) =>
            f.id.includes('address') ||
            f.id.includes('poi') ||
            f.id.includes('postcode')
        );
        const addressName =
          (!!addressFeature && addressFeature.place_name) || '';

        let newLocation = {
          id: uuidv4(),
          address: addressName,
          name: placeName,
          centroid,
          geojson: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: normalizedGeojsonFeatures,
            },
          },
        };
        setLocation(newLocation);
        setSelectedLat(newLocation.centroid.geometry.coordinates[1]);
        setSelectedLng(newLocation.centroid.geometry.coordinates[0]);
      });
  }

  function onFileChange(event) {
    if (!!event.target && !!event.target.files && !!event.target.files.length) {
      const filename = event.target.files[0].name;
      const filenameArray = filename.split('.');

      let reader;
      if (
        filenameArray[filenameArray.length - 1].includes('json') ||
        filenameArray[filenameArray.length - 1].includes('geojson')
      ) {
        reader = new FileReader();
        reader.onload = onReaderLoadGeojson;
        reader.readAsText(event.target.files[0]);
      } else if (
        filenameArray[filenameArray.length - 1].includes('shp') ||
        filenameArray[filenameArray.length - 1].includes('zip')
      ) {
        reader = new FileReader();
        reader.onload = onReaderLoadShapefile;
        reader.readAsArrayBuffer(event.target.files[0]);
      } else {
        toast.error(
          'Error: Unknown File Type.  Only .geojson, .json, or .shp files are accepted.'
        );
      }
    }
  }

  function onReaderLoadShapefile(event) {
    shapefile.read(event.target.result).then((source) => {
      if (gjv.valid(source)) {
        createLocationFromGeojson(source);
      } else {
        toast.error('Error: Shapefile incorrectly formatted');
      }
    });
  }

  function onReaderLoadGeojson(event) {
    var obj;
    try {
      obj = JSON.parse(event.target.result);
    } catch (e) {
      alert('Geojson not correctly formatted');
    }
    if (gjv.valid(obj)) {
      const layerID = 'Custom Layer';
      const sourceID = layerID + ' Source';

      let newSource = {
        ...sampleDrawSource,
        data: {
          ...sampleDrawSource.data,
          features: [{ ...obj, id: 0 }],
        },
      };

      delete newSource.id;

      let newLayer = {
        ...sampleDrawLayer,
        id: layerID,
        source: sourceID,
      };

      // Layers have to be added directly here, then inserted into hooks, because event based programming "Freezes" state variables at declaration time
      if (!!map.current.getSource(sourceID)) {
        map.current.removeLayer(newLayer.id);
        map.current.removeSource(sourceID);
      }

      map.current.addSource(sourceID, newSource);
      map.current.addLayer(newLayer);
      createLocationFromGeojson(obj);
    }
  }

  function searchClicked() {
    if (!!location) {
      var box = turf.bbox(location.geojson.data.features[0].geometry);
      map.current.fitBounds(box, { padding: 50, maxZoom: 15 });
    }
  }
  const onChangeLatLang = (value, type) => {
    const geojson = { ...samplePointSource };
    if (type === 'lat') {
      if (value > 90 || value < -90) {
        toast.error('Latitude should be between -90 and 90');
        return;
      }
      setSelectedLat(value);
      if (selectedLng) {
        geojson.data.features[0].geometry.coordinates = [selectedLng, value];
        createLocationFromGeojson(geojson.data);
      }
    } else if (type === 'lng') {
      if (value > 180 || value < -180) {
        toast.error('Longitude should be between -180 and 180');
        return;
      }
      if (selectedLat) {
        geojson.data.features[0].geometry.coordinates = [value, selectedLat];
        createLocationFromGeojson(geojson.data);
      }
    }
  };

  useEffect(() => {
    if (
      !!location &&
      location.geojson &&
      location.geojson.data &&
      location.geojson.data.features &&
      location.geojson.data.features.length &&
      map.current
    ) {
      const box = turf.bbox(location.geojson.data.features[0].geometry);
      map.current?.fitBounds(box, { padding: 50, maxZoom: 15 });
      const geolocation_tile_layer = 'geolocation_tile_layer';
      const geolocation_tile_source = 'geolocation_tile_source';
      const newSource = {
        ...sampleDrawSource,
        data: {
          ...sampleDrawSource.data,
          features: location.geojson.data.features,
        },
      };
      const newLayer = {
        ...sampleDrawLayer,
        id: geolocation_tile_layer,
        source: geolocation_tile_source,
      };

      if (!!map.current.getLayer(geolocation_tile_layer)) {
        map.current.removeLayer(geolocation_tile_layer);
      }

      if (!!map.current.getSource(geolocation_tile_source)) {
        map.current.removeSource(geolocation_tile_source);
      }

      map.current?.addSource(geolocation_tile_source, newSource);
      map.current?.addLayer(newLayer);
    }
  }, [location, map.current]);

  return (
    <div className="GeoLocationComponent">
      {(!location && (
        <>
          {(!!findLocationActive && (
            <div className="GeoLocationComponent-Find-Button-Wrap">
              <Button
                className="button--secondary"
                onClick={() => setFindLocationActive(false)}
                type="button"
              >
                Cancel Geolocation
              </Button>
            </div>
          )) || (
            <div className="GeoLocationComponent-Find-Button-Wrap">
              <Button
                className="button--secondary"
                onClick={() => setFindLocationActive(true)}
                type="button"
              >
                {!hideSelectLocationButton
                  ? 'Edit Geolocation'
                  : 'View Geolocation'}
              </Button>
            </div>
          )}
        </>
      )) || (
        <div className="GeoLocationComponent-Preview">
          <div className="GeoLocationComponent-Details m-2">
            <div className="GeoLocationComponent-Name">
              <span className="GeoLocationComponent-Details-Title">Name: </span>
              {!!nameEditable ? (
                <>
                  <input
                    onChange={(e) => setEditedName(e.target.value)}
                    value={editedName || ''}
                  />
                  <div className="m-1">
                    <Button onClick={() => saveName()} type="button">
                      Accept Name Change
                    </Button>
                  </div>
                  <div className="m-1">
                    <Button onClick={() => cancelName()} type="button">
                      Cancel Name Change
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  {!!location.name ? (
                    <span
                      onClick={() => !readOnly && setNameEditable(true)}
                      className={classNames(
                        'GeoLocationComponent-Details-Text',
                        {
                          'GeoLocationComponent-Details-Text-Editable': !readOnly,
                        }
                      )}
                    >
                      {location.name}
                    </span>
                  ) : (
                    <span
                      onClick={() => !readOnly && setNameEditable(true)}
                      className={classNames(
                        'GeoLocationComponent-Details-Text',
                        {
                          'GeoLocationComponent-Details-Text-Editable': !readOnly,
                        }
                      )}
                    >
                      {''}
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="GeoLocationComponent-Address">
              <span className="GeoLocationComponent-Details-Title">
                Address:{' '}
              </span>
              {(!!addressEditable && (
                <>
                  <input
                    onChange={(e) => setEditedAddress(e.target.value)}
                    value={editedAddress || ''}
                  />
                  <div className="m-1">
                    <Button type="button" onClick={() => saveAddress()}>
                      Accept Address Change
                    </Button>
                  </div>
                  <div className="m-1">
                    <Button type="button" onClick={() => cancelAddress()}>
                      Cancel Address Change
                    </Button>
                  </div>
                </>
              )) || (
                <>
                  {(!!location.address && (
                    <span
                      onClick={() => !readOnly && setAddressEditable(true)}
                      className={classNames(
                        'GeoLocationComponent-Details-Text',
                        {
                          'GeoLocationComponent-Details-Text-Editable': !readOnly,
                        }
                      )}
                    >
                      {location.address}
                    </span>
                  )) || (
                    <span
                      onClick={() => !readOnly && setAddressEditable(true)}
                      className={classNames(
                        'GeoLocationComponent-Details-Text',
                        {
                          'GeoLocationComponent-Details-Text-Editable': !readOnly,
                        }
                      )}
                    >
                      {''}
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="GeoLocationComponent-Coordinates">
              <span className="GeoLocationComponent-Details-Title">
                Lng/Lat:{' '}
              </span>
              <span className="GeoLocationComponent-Details-Text">
                {JSON.stringify(location.centroid.geometry.coordinates)}
              </span>
            </div>
          </div>
          {(!!findLocationActive && (
            <div className="GeoLocationComponent-Find-Button-Wrap">
              <Button
                type="button"
                className="GeoLocationComponent-Find-Button"
                onClick={() => setFindLocationActive(false)}
              >
                Accept Geolocation
              </Button>
            </div>
          )) ||
          !hideSelectLocationButton ? (
            <div className="GeoLocationComponent-Find-Button-Wrap">
              <Button
                type="button"
                className="button--secondary"
                onClick={() => setFindLocationActive(true)}
              >
                {!hideSelectLocationButton
                  ? 'View/Edit Location'
                  : 'View Location'}
              </Button>
            </div>
          ) : null}
        </div>
      )}
      <div
        className="mt-3 GeoLocationComponent-Map-Active-Wrap"
        style={{ display: `${(!findLocationActive && 'none') || 'block'}` }}
      >
        {!hideSelectLocationButton && (
          <div className="row mb-3">
            <div className="col-md-6">
              <label className="form-label">Lat</label>
              <DebounceInput
                name="lat"
                type="number"
                placeholder="Lat"
                className="form-control"
                minLength={1}
                debounceTimeout={300}
                value={selectedLat}
                onChange={(e) => onChangeLatLang(e.target.value, 'lat')}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Lng</label>

              <DebounceInput
                name="lng"
                type="number"
                placeholder="Lng"
                className="form-control"
                minLength={1}
                debounceTimeout={300}
                value={selectedLng}
                onChange={(e) => onChangeLatLang(e.target.value, 'lng')}
              />
            </div>
          </div>
        )}
        <div className="GeoLocationComponent-Map-Wrap">
          <div
            ref={mapContainer}
            className="GeoLocationComponent-Map-Container"
          >
            {!!location && (
              <div
                className={`icon-button GeoLocationComponent-Map-Icon-Button GeoLocationComponent-Map-Search`}
                onClick={() => searchClicked()}
              >
                <SearchIcon />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
