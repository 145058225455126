import React from 'react';
import ProfileSettings from './ProfileSettings';

export default function ProfileScreen({
  userId,
  userName,
  userEmail,
  profileSettings,
}) {

  return (
    <>
      <ProfileSettings
        userId={userId}
        userName={userName}
        userEmail={userEmail}
        profileSettings={profileSettings}
      />
    </>
  );
}
