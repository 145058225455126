import { useMatch } from 'react-router-dom';
import { selectUserGuid, useAppSelector } from '../../slices/commonSelectors';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../constants/apiConfig';

import { isUUID } from '../../utils/isUUID';

export const useFocusedUser = () => {
  const profileEditMatch = useMatch({ path: '/profile/:user_guid' });

  const focused_user_guid = profileEditMatch?.params.user_guid;

  const user_guid = useAppSelector(selectUserGuid);

  return useQuery({
    queryKey: ['focused_user', focused_user_guid],
    queryFn: () => {
      return axios(config.apiGateway.getUserById, {
        method: 'POST',
        data: JSON.stringify({
          focus_user_id: focused_user_guid,
        }),
      }).then((res) => {
        return res.data;
      });
    },
    enabled: isUUID(focused_user_guid) && focused_user_guid !== user_guid,
  });
};
