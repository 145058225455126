import React from 'react'

const EntraIdAuth: React.FC = () => {

 return (
    <div>
      Using Entra ID for authentication.
    </div>
  )
}


export default EntraIdAuth