import React from 'react';
import ReactECharts from 'echarts-for-react';
import {
  RTO_TIME_FRAME_OPTIONS,
  STABILITY_LIST,
  TASK_COLOR_TAB_DATA,
  TASK_STATUS_LIST,
} from './TaskManagementConstant';
import { getProcessStability } from './TaskManagementUtil';

const assessmentColors = [
  '#D92D20', // error-600
  '#F79009', // warning-500
  '#039855', // success-600
]

const assessmentConfigOptions = {
  grid: { top: 20, right: 20, bottom: 20, left: 20 },
  xAxis: {
    type: 'category',
    data: ['Not Completed', 'In Progress', 'Complete'],
    axisLabel: {
      interval: 0,
      fontSize: 12,
    },
  },
  yAxis: {
    type: 'value',
  },
  series: [
    {
      data: [100, 200, 300],
      type: 'bar',
      smooth: true,
      itemStyle: {
        color: function (params) {
          return assessmentColors[params.dataIndex];
        },
      },
    },
  ],
  toolbox: {
    show: false,
  },
  tooltip: {
    trigger: 'axis',
  },
};

const stabilityColors = [
  '#6C6E70', // fossil
  '#B54708', // warning-700
  '#EF8C6E', // carrot
  '#027A48', // success-700
  '#F04438', // error-500
  '#4273D5', // ocean
]

const stabilityConfigOptions = {
  grid: { top: 20, right: 20, bottom: 20, left: 20 },
  xAxis: {
    type: 'category',
    data: ['NA', 'IR', 'PS', 'ST', 'DI', 'NC'],
    axisLabel: {
      interval: 0,
      fontSize: 12,
    },
  },
  yAxis: {
    type: 'value',
  },
  series: [
    {
      data: [100, 200, 300],
      type: 'bar',
      smooth: true,
      itemStyle: {
        color: function (params) {
          return stabilityColors[params.dataIndex];
        },
      },
    },
  ],
  toolbox: {
    show: false,
  },
  tooltip: {
    trigger: 'axis',
  },
};

export default function TaskManagementGraph(props) {
  const {
    tasks,
    title = null,
    height = 500,
    selectedColorTab = TASK_COLOR_TAB_DATA[0].value,
    ...restProps
  } = props;

  const assessmentCounts = {
    'Not Completed': 0,
    'In Progress': 0,
    Completed: 0,
  };

  const stabilityCounts = {
    'Needs Assessment': 0,
    'In-Restoration': 0,
    'Partially Stable': 0,
    Stable: 0,
    Disrupted: 0,
    'Non-Critical': 0,
  };

  const stabilityList = Object.values(STABILITY_LIST).map((item) => ({
    label: item.label,
    color: item.color,
  }));

  tasks.forEach((task) => {
    const status = task.status;
    if (Object.hasOwn(assessmentCounts, status)) {
      assessmentCounts[status]++;
    }

    const rtoTimeFrameObj = RTO_TIME_FRAME_OPTIONS.find(
      (frame) => frame.value === task?.rto_time_frame
    );
    const stability = getProcessStability(
      task?.status,
      task?.deadline_date,
      task?.restoration_status,
      parseInt(task?.rto),
      rtoTimeFrameObj,
      task?.is_critical
    );

    if (stability && Object.hasOwn(stabilityCounts, stability.value)) {
      stabilityCounts[stability.value]++;
    }
  });

  const dataAssessmentConfig = {
    ...assessmentConfigOptions,
    series: [
      {
        data: Object.values(assessmentCounts),
        type: 'bar',
        smooth: true,
        itemStyle: {
          color: function (params) {
            var colorList = TASK_STATUS_LIST.map((status) => status.color);
            return colorList[params.dataIndex];
          },
        },
      },
    ],
  };

  const dataStabilityConfig = {
    ...stabilityConfigOptions,
    series: [
      {
        data: Object.values(stabilityCounts),
        type: 'bar',
        smooth: true,
        itemStyle: {
          color: function (params) {
            var colorList = stabilityList.map((status) => status.color);
            return colorList[params.dataIndex];
          },
        },
      },
    ],
  };

  let config = dataAssessmentConfig;
  if (selectedColorTab === TASK_COLOR_TAB_DATA[1].value) {
    config = dataStabilityConfig;
  }

  return (
    <div className="form-block" {...restProps}>
      {title && <h4>{title}</h4>}
      <span>Total: {tasks?.length || 0}</span>
      <ReactECharts
        option={config}
        style={{ width: '100%', height: height + 'px' }}
      ></ReactECharts>
      <div className="d-flex mt-4 flex-wrap">
        {/* Assessment status */}
        {selectedColorTab === TASK_COLOR_TAB_DATA[0].value
          ? TASK_STATUS_LIST.map((status) => {
              return (
                <div className="d-flex align-items-center" key={status.label}>
                  <div
                    style={{ background: status.color }}
                    className="ms-3 graph-label-box"
                  ></div>
                  {status.label}
                </div>
              );
            })
          : null}

        {/* Stability status */}
        {selectedColorTab === TASK_COLOR_TAB_DATA[1].value
          ? stabilityList.map((stability) => {
              return (
                <div
                  className="d-flex align-items-center"
                  key={stability.label}
                >
                  <div
                    style={{ background: stability.color }}
                    className="ms-3 graph-label-box"
                  ></div>
                  {stability.label}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}
