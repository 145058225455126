import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import {
  selectGroupGuid,
  selectUserGuid,
} from '../../../slices/commonSelectors';
import { toastConfig } from '../../../assets/data/config';
import { toast } from 'react-toastify';
import { keys as incidentStrategyKeys } from './incidentStrategyHooks';

export type Coms = {
  id: UUID;
  group_guid: string;
  type: string;
  branch: string;
  division: string;
  method_of_contact: string;
  zone_group: string;
  channel_number: string;
  function: string;
  channel_name: string;
  assignment: string;
  rx_frequency: string;
  rx_tone_nac: string;
  tx_frequency: string;
  tx_tone_nac: string;
  mode: string;
  remarks: string;
  special_instructions: string;
  timestamp: Date;
  archived: boolean;
};

export const keys = {
  base: 'coms',
  get all() {
    return [keys.base];
  },
  get: function (group_guid: UUID, user_guid: UUID) {
    return [keys.base, group_guid, user_guid];
  },
} as const;

export const useComs = () => {
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.get(group_guid, user_guid),
    queryFn: async () => {
      const resp = await axios.get<Coms[]>(
        `${config.apiGateway.ics_coms}/${group_guid}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
  });
};

export type CreateComsReqData = {
  name: string;
  description: string;
  incident_id?: UUID;
};

export const useCreateComs = () => {
  const group_guid = useSelector(selectGroupGuid);
  const user_guid = useSelector(selectUserGuid);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: CreateComsReqData) => {
      return await axios.post<CreateComsReqData, Coms>(
        `${config.apiGateway.ics_coms}`,
        {
          ...data,
          group_guid,
          user_guid,
        }
      );
    },
    onSuccess: (_, reqObj) => {
      queryClient.invalidateQueries({
        queryKey: keys.get(group_guid, user_guid),
      });
      if (reqObj.incident_id) {
        queryClient.invalidateQueries({
          queryKey: incidentStrategyKeys.all,
        });
      }
    },
    onError: (error) => {
      toast.error(error.message, toastConfig as any);
    },
  });
};