import config from 'constants/apiConfig';
import axios from 'axios';
import { GET_ALL_EPIC_WORKSPACE } from 'constants/workspace-action-types';
import Network from '../PREPAREsrc/service/Network';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';
import { updateAllWorkSpaceList } from 'PREPAREsrc/store/workspaces/workspaces.action';
import API from '../PREPAREsrc/service/api';

function workSpaceError(errorApi, error) {
  const errorMessage =
    errorApi +
    '\n\n' +
    error +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
}

export const getAllEpicWorkspace = () => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.getAllWorkspace, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_ALL_EPIC_WORKSPACE, payload: response.data });
      })
      .catch((error) => {
        dispatch(workSpaceError(config.apiGateway.getAllWorkspace, error));
      });
  };
};

export const fetchExerciseWorkspaces = (userId, orgId) => {
  return async (dispatch) => {
    try {
      const { data: workspaces } = await Network.get(
        API.getAllWorkspacesByOrgAndUser,
        {
          userId,
          orgId: orgId,
        }
      );
      dispatch(updateAllWorkSpaceList(workspaces));
    } catch (error) {
      console.error('error fetching exercise workspaces');
      //toast.error('Error fetching exercise workspaces', noAutoClose);
      throw error;
    }
  };
};
