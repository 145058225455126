export const FETCH_ADLS_REQUEST = 'FETCH_ADLS_REQUEST';
export const FETCH_ADLS_SUCCESS = 'FETCH_ADLS_SUCCESS';
export const FETCH_ADLS_ERROR = 'FETCH_ADLS_ERROR';
export const UPSERT_ADL_REQUEST = 'UPSERT_ADL_REQUEST';
export const UPSERT_ADL_SUCCESS = 'UPSERT_ADL_SUCCESS';
export const UPSERT_ADL_ERROR = 'UPSERT_ADL_ERROR';
export const DELETE_ADL_REQUEST = 'DELETE_ADL_REQUEST';
export const DELETE_ADL_SUCCESS = 'DELETE_ADL_SUCCESS';
export const DELETE_ADL_ERROR = 'DELETE_ADL_ERROR';
export const ASSIGN_ADL_REQUEST = 'ASSIGN_ADL_REQUEST';
export const ASSIGN_ADL_SUCCESS = 'ASSIGN_ADL_SUCCESS';
export const ASSIGN_ADL_ERROR = 'ASSIGN_ADL_ERROR';
export const UNASSIGN_ADL_REQUEST = 'UNASSIGN_ADL_REQUEST';
export const UNASSIGN_ADL_SUCCESS = 'UNASSIGN_ADL_SUCCESS';
export const UNASSIGN_ADL_ERROR = 'UNASSIGN_ADL_ERROR';

export const FETCH_HVAS_REQUEST = 'FETCH_HVAS_REQUEST';
export const FETCH_HVAS_SUCCESS = 'FETCH_HVAS_SUCCESS';
export const FETCH_HVAS_ERROR = 'FETCH_HVAS_ERROR';
export const FETCH_HVA_DATA_REQUEST = 'FETCH_HVA_DATA_REQUEST';
export const FETCH_HVA_DATA_SUCCESS = 'FETCH_HVA_DATA_SUCCESS';
export const FETCH_HVA_DATA_ERROR = 'FETCH_HVA_DATA_ERROR';
export const UPSERT_HVA_REQUEST = 'UPSERT_HVA_REQUEST';
export const UPSERT_HVA_SUCCESS = 'UPSERT_HVA_SUCCESS';
export const UPSERT_HVA_ERROR = 'UPSERT_HVA_ERROR';
export const DELETE_HVA_REQUEST = 'DELETE_HVA_REQUEST';
export const DELETE_HVA_SUCCESS = 'DELETE_HVA_SUCCESS';
export const DELETE_HVA_ERROR = 'DELETE_HVA_ERROR';

export const FETCH_HVA_TEMPLATE_CATEGORIES_REQUEST = 'FETCH_HVA_TEMPLATE_CATEGORIES_REQUEST';
export const FETCH_HVA_TEMPLATE_CATEGORIES_SUCCESS = 'FETCH_HVA_TEMPLATE_CATEGORIES_SUCCESS';
export const FETCH_HVA_TEMPLATE_CATEGORIES_ERROR = 'FETCH_HVA_TEMPLATE_CATEGORIES_ERROR';
export const UPSERT_HVA_TEMPLATE_CATEGORY_REQUEST = 'UPSERT_HVA_TEMPLATE_CATEGORY_REQUEST';
export const UPSERT_HVA_TEMPLATE_CATEGORY_SUCCESS = 'UPSERT_HVA_TEMPLATE_CATEGORY_SUCCESS';
export const UPSERT_HVA_TEMPLATE_CATEGORY_ERROR = 'UPSERT_HVA_TEMPLATE_CATEGORY_ERROR';
export const DELETE_HVA_TEMPLATE_CATEGORY_REQUEST = 'DELETE_HVA_TEMPLATE_CATEGORY_REQUEST';
export const DELETE_HVA_TEMPLATE_CATEGORY_SUCCESS = 'DELETE_HVA_TEMPLATE_CATEGORY_SUCCESS';
export const DELETE_HVA_TEMPLATE_CATEGORY_ERROR = 'DELETE_HVA_TEMPLATE_CATEGORY_ERROR';

export const FETCH_HVA_TEMPLATE_RISKS_REQUEST = 'FETCH_HVA_TEMPLATE_RISKS_REQUEST';
export const FETCH_HVA_TEMPLATE_RISKS_SUCCESS = 'FETCH_HVA_TEMPLATE_RISKS_SUCCESS';
export const FETCH_HVA_TEMPLATE_RISKS_ERROR = 'FETCH_HVA_TEMPLATE_RISKS_ERROR';
export const UPSERT_HVA_TEMPLATE_RISK_REQUEST = 'UPSERT_HVA_TEMPLATE_RISK_REQUEST';
export const UPSERT_HVA_TEMPLATE_RISK_SUCCESS = 'UPSERT_HVA_TEMPLATE_RISK_SUCCESS';
export const UPSERT_HVA_TEMPLATE_RISK_ERROR = 'UPSERT_HVA_TEMPLATE_RISK_ERROR';
export const DELETE_HVA_TEMPLATE_RISK_REQUEST = 'DELETE_HVA_TEMPLATE_RISK_REQUEST';
export const DELETE_HVA_TEMPLATE_RISK_SUCCESS = 'DELETE_HVA_TEMPLATE_RISK_SUCCESS';
export const DELETE_HVA_TEMPLATE_RISK_ERROR = 'DELETE_HVA_TEMPLATE_RISK_ERROR';

export const FETCH_HVA_TEMPLATE_COLUMNS_REQUEST = 'FETCH_HVA_TEMPLATE_COLUMNS_REQUEST'
export const FETCH_HVA_TEMPLATE_COLUMNS_SUCCESS = 'FETCH_HVA_TEMPLATE_COLUMNS_SUCCESS'
export const FETCH_HVA_TEMPLATE_COLUMNS_ERROR = 'FETCH_HVA_TEMPLATE_COLUMNS_ERROR'

export const CLONE_HVA_REQUEST = 'CLONE_HVA_REQUEST';
export const CLONE_HVA_SUCCESS = 'CLONE_HVA_SUCCESS';
export const CLONE_HVA_ERROR = 'CLONE_HVA_ERROR';

export const FETCH_ALERTS_REQUEST = 'FETCH_ALERTS_REQUEST';
export const FETCH_ALERTS_SUCCESS = 'FETCH_ALERTS_SUCCESS';
export const FETCH_ALERTS_ERROR = 'FETCH_ALERTS_ERROR';

export const UPDATE_ALERT_REQUEST = 'UPDATE_ALERT_REQUEST';
export const UPDATE_ALERT_SUCCESS = 'UPDATE_ALERT_SUCCESS';
export const UPDATE_ALERT_ERROR = 'UPDATE_ALERT_ERROR';

export const FETCH_GEOLOCATION_RISK_ORG_REQUEST = 'FETCH_GEOLOCATION_RISK_ORG_REQUEST';
export const FETCH_GEOLOCATION_RISK_ORG_SUCCESS = 'FETCH_GEOLOCATION_RISK_ORG_SUCCESS';
export const FETCH_GEOLOCATION_RISK_ORG_ERROR = 'FETCH_GEOLOCATION_RISK_ORG_ERROR';

export const FETCH_GEOLOCATION_RISK_ORG_RESULT_REQUEST = 'FETCH_GEOLOCATION_RISK_ORG_RESULT_REQUEST';
export const FETCH_GEOLOCATION_RISK_ORG_RESULT_SUCCESS = 'FETCH_GEOLOCATION_RISK_ORG_RESULT_SUCCESS';
export const FETCH_GEOLOCATION_RISK_ORG_RESULT_ERROR = 'FETCH_GEOLOCATION_RISK_ORG_RESULT_ERROR';
