import React, { useEffect } from 'react';
import Error from './Error';
import { useRouteError } from 'react-router-dom';
import StackTrace from 'stacktrace-js';

const RouteError: React.FC = () => {
  const [error, setError] = React.useState<string>('');
  const routerError = useRouteError() as any;

  useEffect(() => {
    if (typeof routerError === 'object') {
      setError(routerError.message);
      StackTrace.fromError(routerError)
        .then(async (err) => {
          const [errDetails] = err;
          const {
            fileName,
            lineNumber,
            columnNumber,
            functionName,
          } = errDetails;
          const errMessage = `${routerError.message}\nin ${fileName}\nat line ${lineNumber}\n at column ${columnNumber}\nin '${functionName}' method`;
          setError(errMessage );
        })
        .catch((err) => {
          const errMessage = `${routerError.message}:\n ${routerError.stack
            .split('\n')
            .slice(0, 4)
            .join('\n')} \n ----------- \n ${err.message}`;
          setError( errMessage );
        });
    }
  }, [routerError]);

  return <Error error={error} refreshable={false} />;
};

export default RouteError;
