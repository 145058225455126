import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visibleFeatures: [],
  lassoedFeatures: [],
  lassoIsActive: false,
  CCsOnMap: [],
  eventToggles: [
    { hazard_data_category: 'Earthquakes' },
    { hazard_data_category: 'Hurricane' },
    { hazard_data_category: 'NWS alerts' },
    { hazard_data_category: 'Power Outage' },
    { hazard_data_category: 'Wild Fires' },
  ],
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setVisibleFeatures(state, action) {
      state.visibleFeatures = action.payload;
    },
    setLassoedFeatures(state, action) {
      state.lassoedFeatures = action.payload;
    },
    setLassoIsActive(state, action) {
      state.lassoIsActive = action.payload;
    },
    setCCsOnMap(state, action) {
      state.CCsOnMap = action.payload;
    },
    setEventToggles(state, action) {
      state.eventToggles = action.payload;
    },
  },
});

export const {
  setVisibleFeatures,
  setLassoedFeatures,
  setLassoIsActive,
  setCCsOnMap,
  setEventToggles,
} = mapSlice.actions;

export const lassoedFeatures = (state) => state.map.lassoedFeatures;
export const visibleFeatures = (state) => state.map.visibleFeatures;
export const lassoIsActive = (state) => state.map.lassoIsActive;
export const CCsOnMap = (state) => state.map.CCsOnMap;
export default mapSlice;
