import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import { fetchIcsHospitals } from 'slices/ics_hospitalsSlice';

import { getUserGuid, getGroupGuid } from 'slices/sliceHelpers';

// Slice
const ics_incidentHospitalsSlice = createSlice({
    name: 'ics_incident_hospitals',
    initialState: {
        ics_incident_hospitals: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        fetchIcsIncidentHospitalsSuccess: (state, action) => {
            state.ics_incident_hospitals = action.payload;
            state.status = 'succeeded';
        },
        createIcsIncidentHospitalSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        updateIcsIncidentHospitalSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    fetchIcsIncidentHospitalsSuccess,
    createIcsIncidentHospitalSuccess,
    updateIcsIncidentHospitalSuccess,
    setError
} = ics_incidentHospitalsSlice.actions;

export default ics_incidentHospitalsSlice.reducer;

export const createIcsIncidentHospital = (incidentId, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_incident_hospitals}/${incidentId}`, { ...data, user_guid });
        dispatch(fetchIcsHospitals(group_guid));
        dispatch(fetchIcsIncidentHospitals(incidentId));
        dispatch(createIcsIncidentHospitalSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsIncidentHospitals = (incidentId) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_incident_hospitals}/${incidentId}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsIncidentHospitalsSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsIncidentHospital = (incidentId, id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_incident_hospitals}/${incidentId}/${id}`, { ...data, user_guid });
        dispatch(fetchIcsHospitals(group_guid));
        dispatch(fetchIcsIncidentHospitals(incidentId));
        dispatch(updateIcsIncidentHospitalSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
