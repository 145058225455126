import React from 'react';
import { MdOutlineWarning } from "react-icons/md";

import './EventLayerPopup.css'
import { getStormCategory } from './EventDetails';
import { eventHazardsCategory } from '../constants/eventHazards';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import { timezoneAbbreviations } from '../constants/timezoneAbbreviation';
import { getUSAStateAbbreviation } from '../constants/usaStatesAbbreviation';

dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(timezone)

export default function EventLayerPopup({
  layer,
  e,
  features,
  map,
  events,
  handleEventSelected,
  handleCustomEventSelected
}) {
  function eventSelected(feature) {
    const id = feature.properties.instance_id || feature.properties.id
    const event = events.find((ev) => ev.instance_id === id);
    if (!!event) {
      handleEventSelected(event)
    }
  }

  function customEventSelected(feature) {
    const id = feature.properties.id;
    handleCustomEventSelected(feature.properties);
  }

  function convertToUTC(dateString) {
    // Extract timezone abbreviation from the end of the string
    const tzAbbreviation = dateString.split(' ').pop();

    const givenTimezone = timezoneAbbreviations.find((_timezone) => _timezone.abbr === tzAbbreviation);
    if (!givenTimezone) {
      return null;
    }

    // Strip abbreviation from date string
    const cleanedDateString = dateString.replace(tzAbbreviation, '').trim();
    let localDate = dayjs(cleanedDateString, 'YYYY-MM-DD h:mm A ddd');
    localDate = localDate.subtract(givenTimezone.offset, 'hour');

    const utcFormattedDate = localDate.format('YY-MM-DD HHmm');
    return utcFormattedDate;
  }

  return (
    <>
      <h6 className="m-0">Events</h6>
      <hr className="dashed thin my-1" />
      {!!features && features.length ? (
        <>
          <ul className="list">
            {features.map((f, idx) => {

              let hazard_name = f.properties.hazard_name;
              let hazard_category = f.properties.hazard_data_category || f.properties.hazard_name;
              let hazard_color = eventHazardsCategory
                  .filter(c => c.hazard_data_category === hazard_category)?.[0]?.hazard_color || '#ff0000';

              if(hazard_name === "Hurricane") {
                let props = f.properties.properties

                if (typeof props === 'string') {
                  props = JSON.parse(props)
                }

                // Datetime handling

                let finalDateTime
                let otherTimeInfo = f.properties.other_time_info
                if(typeof otherTimeInfo === 'string') {
                  otherTimeInfo = JSON.parse(otherTimeInfo)
                }
                finalDateTime = otherTimeInfo.observed_time || otherTimeInfo?.forecast_time
                let finalFormattedDate = dayjs(finalDateTime).format('YY-MM-DD HHmm')

                // Forecast time is only offered as a formatted string with time zone, so we have to reformat and convert to UTC
                if(otherTimeInfo?.forecast_time) {
                  finalFormattedDate = convertToUTC(finalDateTime);
                }

                // /Datetime handling
                const data_category = (f.properties.storm_data_category.includes("Observed") ? "(Observed)" : "(Forecast)");
                const wind_speed = f.properties.storm_data_category.includes("Observed") ? props.INTENSITY : props.MAXWIND;
                const storm_category = getStormCategory(wind_speed);
                if(f.properties.geometry_type==="Point") {
                  hazard_name = `${data_category} ${storm_category.category} ${props.STORMNAME} ${wind_speed || '-'} kt ${finalFormattedDate} UTC`;
                } else {
                  hazard_name = `${props.STORMNAME}: ${data_category}`;
                }
                
              } else if (hazard_name === "Earthquakes") {
                let props = f.properties.properties
                if (typeof props === 'string') {
                  props = JSON.parse(props)
                }
                hazard_name += `: ${props?.place}`
              } else if (hazard_name === "Power Outage") {
                let props = f.properties
                const placeName = (props.political_boundaries_type === 'county' && `${props.county_name} County, ${getUSAStateAbbreviation(props.state_name)}`) ||
                                  (props.political_boundaries_type === 'state' && getUSAStateAbbreviation(props.state_name));
                const poweroutagePercent = (props.poweroutage_percent || 0).toFixed(2);
                hazard_name += `: ${placeName} ${poweroutagePercent}%`
              } else if(f.sourceLayer==="public.custom_polygon_events_layer"||f.sourceLayer==="public.custom_point_events_layer")
              {
                hazard_name = `(Custom Event) ${f.properties.name}`
              }

              return (
                <li
                  className="cursor-pointer event-layer-item d-flex"
                  key={'EventLayerPopup-Feature-' + idx}
                  onClick={() => {
                    if (f.sourceLayer==="public.custom_polygon_events_layer"||f.sourceLayer==="public.custom_point_events_layer") {
                      customEventSelected(f);
                    } else {
                      eventSelected(f);
                    }
                  }}
                >
                  <span className="custom-color"
                    style={{
                      color: hazard_color,
                    }}
                  >
                    <MdOutlineWarning className="img-h-20 me-1" />
                  </span>
                  <div>
                  {hazard_name} 
                  {!events && (
                    <div>Loading Event Details...<i className="fa fa-spinner fa-pulse"></i></div>
                  )}
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <p className="m-0 weight-500">No Events.</p>
      )}
      <hr className="thin my-1" />
    </>
  );
}
