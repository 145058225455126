import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import dayjs from 'dayjs';

interface TimeSeriesDataPoint {
  timestamp: string;
  quantity: number;
  kind: string;
  name: string;
}

interface TimeSeriesChartProps {
  title: string;
  dataSeries: Array<{ name: string; data: TimeSeriesDataPoint[] }>;
  currentTimestamp: string;
}

const TimeSeriesChart: React.FC<TimeSeriesChartProps> = ({ title, dataSeries, currentTimestamp }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = echarts.init(chartRef.current);

      const allTimestamps = Array.from(
        new Set(
          dataSeries.flatMap(series =>
            series.data.map(item => item.timestamp)
          ).concat(currentTimestamp)
        )
      ).sort();

      const lastRecordedQuantities: Record<string, number | null> = {};

      const seriesData = dataSeries.map((series, seriesIndex) => {
        const data = allTimestamps.map((timestamp, index) => {
          const item = series.data.find(v => v.timestamp === timestamp);
          const isFirstDataPoint = series.data.findIndex(v => v.timestamp === timestamp) === 0;

          if (item) {
            lastRecordedQuantities[series.name] = item.quantity;
            return {
              value: item.quantity,
              symbol: 'circle',
              symbolSize: isFirstDataPoint ? 10 : 6,
              itemStyle: {
                color: seriesIndex === 0 ? 'blue' : seriesIndex === 1 ? 'yellow' : 'green',
                borderColor: isFirstDataPoint ? (seriesIndex === 0 ? 'blue' : seriesIndex === 1 ? 'yellow' : 'green') : 'none',
                borderWidth: isFirstDataPoint ? 2 : 0,
              }
            };
          } else {
            return null;  // Gaps filled with null, connectNulls will handle continuity
          }
        });

        return {
          name: series.name,
          type: 'line',
          data: data,
          connectNulls: true,  // Connect the line even when there are null values
        };
      });

      const option = {
        color: ['blue', 'yellow', 'green'], // Set color array: first series is blue, second is yellow, third is green
        title: {
          text: title,
          top: 10,
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          z: 10000,
          backgroundColor: 'rgba(50, 50, 50, 0.8)',
          borderColor: '#333',
          textStyle: {
            color: '#fff',
          },
          formatter: function (params: any) {
            const formattedTime = dayjs(params[0]?.axisValue).format('YYYY-MM-DD hh:mm UTC');
            let tooltipContent = `${formattedTime}<br/>`;

            params.forEach((param: any) => {
              const timestamp = param.axisValue;
              const seriesIndex = param.seriesIndex;
              const item = dataSeries[seriesIndex].data.find(v => v.timestamp === timestamp);

              if (item) {
                lastRecordedQuantities[param.seriesName] = item.quantity;  // Update last valid data
                const seriesLabel = `${item.name} - (${item.kind}): ${item.quantity}`;
                tooltipContent += `${param.marker} ${seriesLabel}<br/>`;
              } else if (lastRecordedQuantities[param.seriesName] !== null) {
                // Use the last recorded quantity if no data for this timestamp
                tooltipContent += `${param.marker} ${param.seriesName}: ${lastRecordedQuantities[param.seriesName]}<br/>`;
              }
            });
            return tooltipContent;
          }
        },
        legend: {
          orient: 'vertical',
          left: 'center',
          top: '40px',
          textStyle: {
            fontSize: 12,
          },
          itemGap: 10,
          padding: [10, 10, 20, 10],
          data: dataSeries.map(series => series.name),
        },
        grid: {
          top: '30%', // Adjusted to make space for the title
          bottom: '30%', // Adjusted to avoid cutting off the bottom part
          left: '10%',
          right: '10%',
        },
        xAxis: {
          type: 'category',
          data: allTimestamps,
          axisLabel: {
            rotate: 45,
            formatter: (value: string) => dayjs(value).format('YYYY-MM-DD hh:mm UTC'),
          },
        },
        yAxis: {
          type: 'value',
        },
        dataZoom: [
          {
            type: 'slider',
            xAxisIndex: 0,
            start: 0,
            end: 100,
            bottom: '5%',  // Positioned above the bottom of the grid
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            start: 0,
            end: 100,
          },
        ],
        series: seriesData,
      };

      chartInstance.setOption(option);

      return () => {
        chartInstance.dispose();
      };
    }
  }, [dataSeries, currentTimestamp, title]);

  return <div ref={chartRef} style={{ width: '100%', height: '400px' }} />;
};

export default TimeSeriesChart;
