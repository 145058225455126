export const TeamsActivated = [
  { value: 'TRT', label: 'TRT' },
  { value: 'IMT', label: 'IMT' },
  { value: 'BST', label: 'BST' },
  { value: 'Severe Weather', label: 'Severe Weather' },
  { value: 'BCT', label: 'BCT' },
  { value: 'CST', label: 'CST' },
  { value: 'EST', label: 'EST' },
];

export const CallTypes = [
  { value: 'Drill', label: 'Drill' },
  { value: 'Incident', label: 'Incident' },
];

export const PossibleReportStatuses = [
  { value: 'Submitted', label: 'Submitted'},
  { value: 'In Review', label: 'In Review'},
  { value: 'Reviewed: Ongoing', label: 'Reviewed: Ongoing'},
  { value: 'Reviewed: Concluded', label: 'Reviewed: Concluded'},
  { value: 'Escalated: Ongoing', label: 'Escalated: Ongoing'},
  { value: 'Escalated: Concluded', label: 'Escalated: Concluded'},
]

export const Entity = [
  { value: 'C&A', label: 'C&A' },
  { value: 'C&P', label: 'C&P'},
  { value: 'Finance', label: 'Finance' },
  { value: 'G&LCE', label: 'G&LCE' },
  { value: 'I&E', label: 'I&E' },
  { value: 'P&C', label: 'P&C' },
  { value: 'P&O', label: 'P&O' },
  { value: 'RC&S', label: 'RC&S' },
  { value: 'T&S', label: 'T&S' },
];

export const EntitySub = [
  {
    Entity: 'C&A',
    'Sub Entity': 'C&A',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
  {
    Entity: 'C&P',
    'Sub Entity': 'Aviation',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
  {
    Entity: 'C&P',
    'Sub Entity': 'Castrol',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
  {
    Entity: 'C&P',
    'Sub Entity': 'Fuel supply and midstream',
    Enterprise: 'FS&M Americas',
    Asset: 'N/A',
  },
  {
    Entity: 'C&P',
    'Sub Entity': 'Mobility & convenience Americas',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
  {
    Entity: 'C&P',
    'Sub Entity': 'Mobility & convenience ESA',
    Enterprise: 'M&C Southern Africa',
    Asset: 'N/A',
  },
  {
    Entity: 'C&P',
    'Sub Entity': 'Mobility convenience & midstream AsPac',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
  {
    Entity: 'Finance',
    'Sub Entity': 'GBS',
    Enterprise: 'GBS - Africa',
    Asset: 'N/A',
  },
  {
    Entity: 'Finance',
    'Sub Entity': 'GBS',
    Enterprise: 'GBS - Americas Mexico',
    Asset: 'N/A',
  },
  {
    Entity: 'Finance',
    'Sub Entity': 'GBS',
    Enterprise: 'GBS - Asia',
    Asset: 'N/A',
  },
  {
    Entity: 'Finance',
    'Sub Entity': 'GBS',
    Enterprise: 'GBS - Australia and NZ',
    Asset: 'N/A',
  },
  {
    Entity: 'Finance',
    'Sub Entity': 'GBS',
    Enterprise: 'GBS - Europe',
    Asset: 'N/A',
  },
  {
    Entity: 'Finance',
    'Sub Entity': 'GBS',
    Enterprise: 'GBS - India',
    Asset: 'N/A',
  },
  {
    Entity: 'Finance',
    'Sub Entity': 'Procurement',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
  {
    Entity: 'Finance',
    'Sub Entity': 'Treasury',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
  {
    Entity: 'G&LCE',
    'Sub Entity': 'Gas business',
    Enterprise: 'India',
    Asset: 'N/A',
  },
  {
    Entity: 'G&LCE',
    'Sub Entity': 'Gas business',
    Enterprise: 'Indonesia',
    Asset: 'N/A',
  },
  {
    Entity: 'G&LCE',
    'Sub Entity': 'Gas business',
    Enterprise: 'Mauritania & Senegal',
    Asset: 'N/A',
  },
  {
    Entity: 'G&LCE',
    'Sub Entity': 'Gas business',
    Enterprise: 'Mexico',
    Asset: 'N/A',
  },
  {
    Entity: 'G&LCE',
    'Sub Entity': 'Gas business',
    Enterprise: 'Middle East',
    Asset: 'Oman',
  },
  {
    Entity: 'G&LCE',
    'Sub Entity': 'Gas business',
    Enterprise: 'North Africa',
    Asset: 'Egypt',
  },
  {
    Entity: 'G&LCE',
    'Sub Entity': 'Gas business',
    Enterprise: 'Russia',
    Asset: 'N/A',
  },
  {
    Entity: 'G&LCE',
    'Sub Entity': 'Gas business',
    Enterprise: 'Trinidad and Tobago',
    Asset: 'N/A',
  },
  {
    Entity: 'I&E',
    'Sub Entity': 'I&E',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
  {
    Entity: 'P&C',
    'Sub Entity': 'People & culture',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
  {
    Entity: 'P&C',
    'Sub Entity': 'Workplace Solutions',
    Enterprise: 'Workplace Solutions - South East UK',
    Asset: 'N/A',
  },
  {
    Entity: 'P&C',
    'Sub Entity': 'Workplace Solutions',
    Enterprise: 'Workplace Solutions - Westlake',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'bp Solutions ',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Brazil',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'HSE & Carbon',
    Enterprise: 'Remediation Management',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'AGT',
    Asset: 'Azerbaijan',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'AGT',
    Asset: 'Georgia',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'AGT',
    Asset: 'Turkey',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'Angola',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'Canada',
    Asset: 'Calgary',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'Canada',
    Asset: "St John's",
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'Canada',
    Asset: 'Stenna Ice Max',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'GoM',
    Asset: 'Atlantis',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'GoM',
    Asset: 'Argos',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'GoM',
    Asset: 'Fourchon Docks',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'GoM',
    Asset: 'Houma Heliport',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'GoM',
    Asset: 'Houma PMF ',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'GoM',
    Asset: 'Mad Dog',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'GoM',
    Asset: 'Na Kika',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'GoM',
    Asset: 'Thunder Horse',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'GoM',
    Asset: 'Westlake Campus',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'GoM',
    Asset: 'Diamond Auriga',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'GoM',
    Asset: 'Diamond BlackHornet',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'GoM',
    Asset: 'Diamond BlackLion',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Oil Regions',
    Enterprise: 'North Sea',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Refining',
    Enterprise: 'Castellon Refinery',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Refining',
    Enterprise: 'Cherry Point Refinery',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Refining',
    Enterprise: 'Gelsenkirchen Refinery',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Refining',
    Enterprise: 'Lingen Refinery',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Refining',
    Enterprise: 'Rotterdam Refinery',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Refining',
    Enterprise: 'Terminals & Pipelines - France',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Refining',
    Enterprise: 'Terminals & Pipelines - Turkey',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Refining',
    Enterprise: 'Terminals & Pipelines - UK',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Refining',
    Enterprise: 'Terminals & Pipelines - US',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Refining',
    Enterprise: 'Toledo Refinery',
    Asset: 'N/A',
  },
  {
    Entity: 'P&O',
    'Sub Entity': 'Refining',
    Enterprise: 'Whiting Refinery',
    Asset: 'N/A',
  },
  {
    Entity: 'RC&S',
    'Sub Entity': 'ISC',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
  {
    Entity: 'RC&S',
    'Sub Entity': 'Pension Trustees',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
  {
    Entity: 'T&S',
    'Sub Entity': 'GPTA',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
  {
    Entity: 'T&S',
    'Sub Entity': 'GPTI',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
  {
    Entity: 'T&S',
    'Sub Entity': 'RPT',
    Enterprise: 'RPT Americas',
    Asset: 'N/A',
  },
  {
    Entity: 'T&S',
    'Sub Entity': 'RPT',
    Enterprise: 'RPT AP&ME',
    Asset: 'N/A',
  },
  {
    Entity: 'T&S',
    'Sub Entity': 'RPT',
    Enterprise: 'RPT E&A',
    Asset: 'N/A',
  },
  {
    Entity: 'T&S',
    'Sub Entity': 'Shipping',
    Enterprise: 'N/A',
    Asset: 'N/A',
  },
];
