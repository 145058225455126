import React, { useState, useEffect } from 'react';
import ReportList from './ReportList';
import CreateEditInitialIncidentReport from './CreateEditInitialIncidentReport';
import ViewReport from './ViewReport';
import { useNavigate, useLocation } from 'react-router-dom';
import getQueryParams from 'utils/getQueryParams';

export default function ReportPageInner() {
  const [selectedReport, setSelectedReport] = useState();
  const [selectedTab, setSelectedTab] = useState('View');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.search) {
      if (location.pathname.includes('/new')) {
        setSelectedTab('Create');
      } else {
        setSelectedTab('View');
      }
    } else {
      const queryParams = getQueryParams(location.search);
      if (queryParams.id && queryParams.type === 'view') {
        setSelectedTab('View Single');
      }
    }
  }, [location]);

  const onChangeTab = (selectedReport, tab) => {
    if (selectedReport) {
      setSelectedReport(selectedReport);
      navigate(`/report?id=${selectedReport.id}`,{replace:true});
    } else {
      setSelectedReport(undefined);

      if (tab === 'Create') {
        navigate(`/report/new`,{ replace: true });
      }

      if (tab === 'View') {
        navigate('/report');
      }
    }

    setSelectedTab(tab);
  };

  return (
    <>
      {selectedTab === 'View' ? (
        <ReportList
          selectedReport={selectedReport}
          onChangeTab={(selectedReport, tab) =>
            onChangeTab(selectedReport, tab)
          }
        />
      ) : selectedTab === 'Create' ? (
        <CreateEditInitialIncidentReport
          selectedReport={selectedReport}
          onChangeTab={onChangeTab}
        />
      ) : selectedTab === 'View Single' ? (
        <ViewReport
          selectedReport={selectedReport}
          onChangeTab={(selectedReport, tab) =>
            onChangeTab(selectedReport, tab)
          }
        />
      ) : null}
    </>
  );
}
