import React from 'react';
import { StylishNewButton } from '../../DesignSystems/New/StylishNewButton';

export default function ICSIncidentWorkAssignment({ row, selectCallback }) {
  return (
    <div className="ICSIncidentWorkAssignment">
      <div className="mb-4">
        <h6 className="mb-2">Work Assignment:</h6>
        <p className="m-0">{row.work_assignment}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Branch:</h6>
        <p className="m-0">{row.branch}</p>
        {/* TODO: Link branch and division with their entities. Right now just shows their uuid. */}
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Division/Group:</h6>
        <p className="m-0">{row.division_group}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Resources:</h6>
        <p className="m-0">{row.resources}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Coms:</h6>
        <p className="m-0">TODO</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Overhead Positions:</h6>
        <p className="m-0">{row.overhead_positions}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Special Equipment/Supplies:</h6>
        <p className="m-0">{row.special_equipment_supplies}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Reporting Location:</h6>
        <p className="m-0">{row.reporting_location}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Requested Arrival Time:</h6>
        <p className="m-0">{row.requested_arrival_time}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Special Instructions:</h6>
        <p className="m-0">{row.special_instructions}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Last Modified By:</h6>
        <p className="m-0">{row.user_name}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Archived:</h6>
        <p className="m-0">{row.archived ? 'Yes' : 'No'}</p>
      </div>
      <StylishNewButton
        className="button button--link"
        onClick={() => selectCallback(row)}
      >
        Edit
      </StylishNewButton>
    </div>
  );
}
