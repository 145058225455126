import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

import {
  createIcsWorkAssignment,
  updateIcsWorkAssignment,
} from 'slices/ics_workAssignmentsSlice';

import { updateIcsIncidentWorkAssignment } from 'slices/ics_incidentWorkAssignmentsSlice';

export default function NewICSWorkAssignmentDialog({
  show,
  setEditWorkAssignmentData,
  editWorkAssignmentData,
  modalType,
  setModalType,
  onClose,
  incident_id,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const [isLoaded, setIsLoaded] = useState(true);
  const [descriptionError, setDescriptionError] = useState({
    hasError: true,
    message: '',
    touched: false,
  });

  const [workAssignmentId, setWorkAssignmentId] = useState('');
  const reduxDispatch = useDispatch();
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (!isLoaded) {
      onClose();
      setModalType('New');
      setEditWorkAssignmentData(undefined);
    }
  }, [isLoaded, onClose]);

  useEffect(() => {
    if (modalType === 'New') {
      setWorkAssignmentId(generateUUID());
    } else if (modalType === 'Edit') {
      setWorkAssignmentId(editWorkAssignmentData.id);
      setValue('branch', editWorkAssignmentData.branch);
      setValue('division_group', editWorkAssignmentData.division_group);
      setValue('work_assignment', editWorkAssignmentData.work_assignment);
      setValue('resources', editWorkAssignmentData.resources);
      setValue('overhead_positions', editWorkAssignmentData.overhead_positions);
      setValue('special_equipment_supplies', editWorkAssignmentData.special_equipment_supplies);
      setValue('reporting_location', editWorkAssignmentData.reporting_location);
      setValue('requested_arrival_time', editWorkAssignmentData.requested_arrival_time);
      setValue('special_instructions', editWorkAssignmentData.special_instructions);
    }
  }, [modalType]);

  const onNewWorkAssignmentDataSubmit = (data) => {
    let payload = {
      id: workAssignmentId,
      work_assignment_id: workAssignmentId,
      branch: data.branch,
      division_group: data.division_group,
      work_assignment: data.work_assignment,
      resources: [], // TODO resources relations data.resources,
      overhead_positions: data.overhead_positions,
      special_equipment_supplies: data.special_equipment_supplies,
      reporting_location: data.reporting_location,
      requested_arrival_time: data.requested_arrival_time,
      special_instructions: data.special_instructions,
      incident_id: incident_id,
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      archived: false,
    };
    if (modalType === 'New') {
      reduxDispatch(createIcsWorkAssignment(payload));
      onClose();
    } else if (modalType === 'Edit') {
      if (!incident_id) {
        reduxDispatch(updateIcsWorkAssignment(payload.id, payload));
      } else if (!!incident_id) {
        payload.id = editWorkAssignmentData?.id;
        reduxDispatch(
          updateIcsIncidentWorkAssignment(incident_id, payload.id, payload)
        );
      }
      onClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Work Assignment</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewWorkAssignmentDataSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">ID</label>
            <StylishNewInput value={workAssignmentId} readOnly disabled />
          </div>

          <div className="m-0">
            <label className="form-label">Work Assignment:</label>
            <Controller
              control={control}
              name="work_assignment"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.work_assignment && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>

          <div className="m-0">
            <label className="form-label">Branch:</label>
            <Controller
              control={control}
              name="branch"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Division/Group:</label>
            <Controller
              control={control}
              name="division_group"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          
          <div className="m-0">
            <label className="form-label">Resources:</label>
            <Controller
              control={control}
              name="resources"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Overhead Positions:</label>
            <Controller
              control={control}
              name="overhead_positions"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Special Equipment/Supplies:</label>
            <Controller
              control={control}
              name="special_equipment_supplies"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Reporting Location:</label>
            <Controller
              control={control}
              name="reporting_location"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Requested Arrival Time:</label>
            <Controller
              control={control}
              name="requested_arrival_time"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="datetime-local"
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Special Instructions:</label>
            <Controller
              control={control}
              name="special_instructions"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
