import config from 'constants/apiConfig';
import axios from 'axios';
import {
  GET_ALL_GROUP_LOCATION,
  GET_ALL_LOCATION_ENTITY,
  GET_ALL_LOCATION_SUB_ENTITY,
  GET_ALL_LOCATION_ENTERPRISE,
  GET_ALL_LOCATION_ASSET,
  FETCH_NESTED_CATEGORIES,
  FETCH_NESTED_CATEGORY_ITEMS,
  FETCH_AOR,
} from 'constants/location-action-types';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { fetchAllDistributionList } from './notificationActions';
import {
  getAllInitialIncidentReportChecklist,
  getAllInitialIncidentReportTeamChecklist,
} from './ReportActions';
import { fetchCoreComponents } from './ai2fpActions';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

function parseError(api, error) {
  let errorMessage;
  if (error.response['data'].includes('violates foreign key constraint')) {
    errorMessage =
      "We can't delete this, because it is associated with other functionalities";
  } else {
    errorMessage = `${api} \n There was an error saving your Form.\n ${
      error.response && error.response.data && error.response.data.ERROR
    }
     \nIf you continue to experience this error, contact support@disastertech.com`;
  }
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
}

export const fetchNestedCategoryItems = () => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.fetchNestedCategoryItems, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        if (
          !!getState().app.currentlySelectedGroup &&
          getState().app.currentlySelectedGroup.group_guid
        ) {
          dispatch(
            fetchCoreComponents(
              getState().app.currentlySelectedGroup.group_guid
            )
          );
        }
        dispatch({ type: FETCH_NESTED_CATEGORY_ITEMS, payload: response.data });
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.fetchNestedCategoryItems, error));
      });
  };
};

export const fetchNestedCategories = () => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.fetchNestedCategories, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        dispatch({ type: FETCH_NESTED_CATEGORIES, payload: response.data });
      })
      .catch((error) => {
        dispatch(endLoading());
        dispatch(parseError(config.apiGateway.fetchNestedCategories, error));
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

export const upsertNestedCategory = (category) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.upsertNestedCategory, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        category,
      }),
    })
      .then(() => {
        dispatch(fetchNestedCategories());
      })
      .catch((error) => {
        dispatch(endLoading());
        dispatch(parseError(config.apiGateway.upsertNestedCategory, error));
      });
  };
};

export const upsertNestedCategoryItem = (item) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.upsertNestedCategoryItem, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        item,
      }),
    })
      .then(() => {
        dispatch(endLoading());
        dispatch(fetchNestedCategoryItems());
      })
      .catch((error) => {
        dispatch(endLoading());
        dispatch(parseError(config.apiGateway.upsertNestedCategoryItem, error));
      });
  };
};

export const deleteNestedCategory = (category) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.deleteNestedCategory, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        category_id: category.id,
      }),
    })
      .then(() => {
        dispatch(fetchNestedCategories());
        dispatch(
          fetchAllDistributionList(
            getState().app.currentlySelectedGroup.group_guid
          )
        );
      })
      .catch((error) => {
        dispatch(endLoading());
        dispatch(parseError(config.apiGateway.deleteNestedCategory, error));
      });
  };
};

export const deleteNestedCategoryItem = (itemId) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.deleteNestedCategoryItem, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        item_id: itemId,
      }),
    })
      .then(() => {
        dispatch(endLoading());
        dispatch(fetchNestedCategoryItems());
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.deleteNestedCategoryItem, error));
        dispatch(endLoading());
      });
  };
};

export const upsertLocationEntity = (location) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.upsertGroupLocationEntity, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        location,
      }),
    })
      .then(() => {
        dispatch(endLoading());
        dispatch(getAllLocationEntity());
      })
      .catch((error) => {
        dispatch(endLoading());
        dispatch(
          parseError(config.apiGateway.upsertGroupLocationEntity, error)
        );
      });
  };
};

export const upsertLocationSubEntity = (location) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.upsertGroupLocationSubEntity, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        location,
      }),
    })
      .then(() => {
        dispatch(endLoading());
        dispatch(getAllLocationSubEntity(location.ref_location_id));
      })
      .catch((error) => {
        dispatch(endLoading());
        dispatch(
          parseError(config.apiGateway.upsertGroupLocationSubEntity, error)
        );
      });
  };
};

export const upsertLocationEnterprise = (location) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.upsertGroupLocationEnterprice, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        location,
      }),
    })
      .then(() => {
        dispatch(endLoading());
        dispatch(getAllLocationEnterprise(location.ref_location_id));
      })
      .catch((error) => {
        dispatch(endLoading());
        dispatch(
          parseError(config.apiGateway.upsertGroupLocationEnterprice, error)
        );
      });
  };
};

export const upsertLocationAsset = (location) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.upsertGroupLocationAsset, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        location,
      }),
    })
      .then(() => {
        dispatch(endLoading());
        dispatch(getAllLocationAsset(location.ref_location_id));
      })
      .catch((error) => {
        dispatch(endLoading());
        dispatch(parseError(config.apiGateway.upsertGroupLocationAsset, error));
      });
  };
};

export const getAllLocationEntity = () => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.getAllGroupLocationEntity, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_ALL_LOCATION_ENTITY, payload: response.data });
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.getAllGroupLocationEntity, error)
        );
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

export const getAllLocationSubEntity = (location_entity_id) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.getAllGroupLocationSubEntity, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        location_entity_id,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_ALL_LOCATION_SUB_ENTITY, payload: response.data });
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.getAllGroupLocationSubEntity, error)
        );
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

export const getAllLocationEnterprise = (location_sub_entity_id) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.getAllGroupLocationEnterprice, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        location_sub_entity_id,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_ALL_LOCATION_ENTERPRISE, payload: response.data });
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.getAllGroupLocationEnterprice, error)
        );
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

export const getAllLocationAsset = (location_enterprise_id) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.getAllGroupLocationAsset, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        location_enterprise_id,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_ALL_LOCATION_ASSET, payload: response.data });
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.getAllGroupLocationAsset, error));
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

export const getAllLocation = () => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.getAllGroupLocation, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_ALL_GROUP_LOCATION, payload: response.data });
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.getAllGroupLocation, error));
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

export const deleteLocationEntity = (location_entity_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteGroupLocationEntity, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        location_entity_id,
      }),
    })
      .then(() => {
        dispatch(getAllLocationEntity());
        dispatch(getAllLocationSubEntity());
        dispatch(getAllLocationEnterprise());
        dispatch(getAllLocationAsset());
        dispatch(getAllLocation());
        dispatch(
          fetchAllDistributionList(
            getState().app.currentlySelectedGroup.group_guid
          )
        );
        // toast.success('Delete Group Location Entity successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.deleteGroupLocationEntity, error)
        );
      });
  };
};

export const deleteLocationSubEntity = (location_sub_entity_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteGroupLocationSubEntity, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        location_sub_entity_id,
      }),
    })
      .then(() => {
        dispatch(getAllLocationSubEntity());
        dispatch(getAllLocationEnterprise());
        dispatch(getAllLocationAsset());
        dispatch(getAllLocation());
        dispatch(
          fetchAllDistributionList(
            getState().app.currentlySelectedGroup.group_guid
          )
        );
        // toast.success('Delete Group Location Subentity successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.deleteGroupLocationSubEntity, error)
        );
      });
  };
};

export const deleteLocationEnterprise = (location_enterprice_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteGroupLocationEnterprice, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        location_enterprice_id,
      }),
    })
      .then(() => {
        dispatch(getAllLocationEnterprise());
        dispatch(getAllLocationAsset());
        dispatch(getAllLocation());
        dispatch(
          fetchAllDistributionList(
            getState().app.currentlySelectedGroup.group_guid
          )
        );
        // toast.success('Delete Group Location Enterprise successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(
          parseError(config.apiGateway.deleteGroupLocationEnterprice, error)
        );
      });
  };
};

export const deleteLocationAsset = (location_asset_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteGroupLocationAsset, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        location_asset_id,
      }),
    })
      .then(() => {
        dispatch(getAllLocationAsset());
        dispatch(getAllLocation());
        dispatch(
          fetchAllDistributionList(
            getState().app.currentlySelectedGroup.group_guid
          )
        );
        dispatch(getAllInitialIncidentReportTeamChecklist());
        dispatch(getAllInitialIncidentReportChecklist());
        // toast.success('Delete Group Location Asset successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.deleteGroupLocationAsset, error));
      });
  };
};

export const upsertGroupLocation = (location) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.upsertGroupLocation, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        location,
      }),
    })
      .then(() => {
        dispatch(getAllLocation());
        // toast.success('Upsert Group Location successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.upsertGroupLocation, error));
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

export const getAllAOR = (showLoading) => {
  return (dispatch, getState) => {
    showLoading && dispatch(startLoading());
    return axios(config.apiGateway.getAllAor, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        dispatch({ type: FETCH_AOR, payload: response.data });
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.getAllAor, error));
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

export const upsertAOR = (cc_aor) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.upsertAor, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        aor: cc_aor,
      }),
    })
      .then(() => {
        dispatch(getAllAOR());
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.upsertAor, error));
        dispatch(endLoading());
      });
  };
};

export const deleteAOR = (aor_id) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.deleteAor, {
      method: 'POST',
      data: JSON.stringify({
        aor_id: aor_id,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        dispatch(getAllAOR());
      })
      .catch((error) => {
        dispatch(parseError(config.apiGateway.deleteAor, error));
        dispatch(endLoading());
      });
  };
};
