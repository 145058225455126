import { upsertTaskComment } from 'actions/TaskManagementActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import TinyMCEWithFormController from 'components/DesignSystems/New/TinyMCEWithFormController';
import { sanitizeHtml } from "../../utils/sanitizeHtml";

export default function TaskManagementComment({ task_id }) {
  const reduxDispatch = useDispatch();
  const allComments = useSelector((state) => state.app.allTaskComments);
  const { user } = useSelector((state) => {
    return state.app;
  });

  const {
    handleSubmit,
    resetField,
    clearErrors,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ mode: 'onChange' });

  const onAddComment = async (data) => {
    const comment = {
      id: generateUUID(),
      task_id: task_id,
      comment: data.comment,
    };
    await reduxDispatch(upsertTaskComment(comment));
    clearErrors('comment');
    resetField('comment');
  };
  return (
    <form>
      {!!allComments &&
        allComments.length > 0 &&
        allComments.map((comment, idx) => {
          const cleanComment = sanitizeHtml(comment?.comment);
          return (
            <>
              <div key={idx}>
                <div className="d-flex align-items-start">
                  <button
                    className="button--icon primary weight-700 rounded-circle p-0 m-0 flex-shrink-0"
                    style={{
                      pointerEvents: 'none',
                      userSelect: 'none',
                      fontSize: '12px',
                    }}
                  >
                    {(comment?.user_name || comment.email_address)
                      .match(/(\b\S)?/g)
                      .join('')
                      .match(/(^\S|\S$)?/g)
                      .join('')
                      .toUpperCase()}
                  </button>

                  <div
                    className="ms-3 flex-grow-1 has-child-p--m-0"
                    dangerouslySetInnerHTML={{ __html: cleanComment }}
                  ></div>
                </div>
                <hr className="dashed my-3" />
              </div>
            </>
          );
        })}

      <div className="d-flex align-items-start">
        <button
          className="button--icon primary weight-700 rounded-circle p-0 m-0 flex-shrink-0"
          style={{
            pointerEvents: 'none',
            userSelect: 'none',
            fontSize: '12px',
          }}
        >
          {(user?.user_name || user.email_address)
            .match(/(\b\S)?/g)
            .join('')
            .match(/(^\S|\S$)?/g)
            .join('')
            .toUpperCase()}
        </button>
        <div className="ms-3 flex-grow-1">
          <TinyMCEWithFormController
            name="comment"
            control={control}
            placeholder="Write a Comment"
            readOnly={false}
            rules={{ required: true }}
          />
          {errors.comment && (
            <span className="form-text form-error">This field is required</span>
          )}
        </div>
      </div>

      <StylishNewButton
        className="button--primary w-100 w-md-auto mt-4"
        type={'button'}
        onClick={handleSubmit(onAddComment)}
        disabled={isSubmitting}
      >
        Submit Comment
      </StylishNewButton>
    </form>
  );
}
