import React from 'react';
import { Modal } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

export default function PDFPreviewDialog({
  show,
  onClose,
  onCreatePDFClick,
  children,
}) {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>PDF Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className={'button--primary'}
            onClick={onCreatePDFClick}
          >
            Create PDF
          </StylishNewButton>
          <StylishNewButton
            className={'button--secondary button--reverse'}
            onClick={onClose}
          >
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
