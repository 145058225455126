import React, {useState, useEffect} from "react";

const REFRESH_TIME_MINS = 3;

function Error({ error, refreshable = false, ...restProps }) {
  const [remainingSeconds, setRemainingSeconds] = useState(REFRESH_TIME_MINS * 60);

  let intervalId = null;

	const handleToClipboard = () => {
		if (error) {
			const elem = document.createElement("p");
			elem.textContent = error;
			document.body.appendChild(elem);

			const selection = document.getSelection();
			const range = document.createRange();

			range.selectNode(elem);
			selection.removeAllRanges();
			selection.addRange(range);
			document.execCommand("copy");

			selection.removeAllRanges();
			document.body.removeChild(elem);
		}
	};

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${minutes}:${formattedSeconds}`;
  }

  useEffect(() => {
    intervalId = setInterval(() => {
      setRemainingSeconds(prevVal => prevVal > 0 ? (prevVal - 1) : 0);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const refreshInterval = REFRESH_TIME_MINS * 60 * 1000;

    const refreshTimerId = setTimeout(() => {
      if (refreshable) {
        // Refresh the function after 3 minutes
        window.location.reload();
      }
    }, refreshInterval);

    return () => {
      clearTimeout(refreshTimerId); // Clean up the refresh timer when the component unmounts
    };
  }, []);

	return (
		<div {...restProps} className="bg-gray-800 rounded p-3 p-md-4 w-75 mx-auto mt-5">

      <h2>PRATUS app has encountered an unexpected issue.</h2>
      <h5>The app is crashed. Sorry about that!</h5>
      {error && (
        <span>
          {error.split('\n').map((line) => (
            <>
              {line} <br />
            </>
          ))}
        </span>
      )}

      {refreshable && 
        <div className="d-flex my-2">
          <div className="d-flex m-auto fs-4 fw-bold">
            The app will refresh within 
            <div style={{width: '40px', marginLeft: '8px'}}>{formatTime(remainingSeconds)}</div>
          </div>
        </div>
      }
      <div className="d-flex justify-content-between mt-3 w-75 mx-auto">
        <button
          className="mr-2 button button--secondary"
          onClick={() => {
            handleToClipboard();
          }}
        >
          Copy Error to Clipboard!
        </button>

        <button
          className="mr-2 button button--secondary"
          onClick={() => {
            window.location.reload();
          }}
        >
          Click here to try refreshing...
        </button>
      </div>
		</div>
	);
}

export default Error;
