import { useMutation, useQuery } from '@tanstack/react-query';
import apiConfig from '../../../constants/apiConfig';
import axios, { AxiosError } from 'axios';
import { queryClient } from '../../../App';
import {
  selectGroupGuid,
  useAppSelector,
} from '../../../slices/commonSelectors';
import { toastConfig } from '../../../assets/data/config';
import { toast } from 'react-toastify';

export const useRedisHealth = () => {
  return useQuery({
    queryKey: ['redisHealth'],
    queryFn: async () => {
      const response = await axios.get(apiConfig.apiGateway.redisHealthCheck);
      return response.data;
    },
  });
};

export type NotificationJob = {
  id: UUID;
  short_description: string;
  notification_id: UUID;
  email_from: string;
  request_user_guid: UUID;
  repeatOptions: {
    pattern: string;
  };
  scheduler_name: string;
  job_type: string;
  job_options: {
    wind_location: UUID;
    threshold: number;
  };
  group_guid: UUID;
  active: boolean;
  aor_id: UUID;
};

type NotificationJobResponse = {
  notificationJobs: NotificationJob[];
  repeatableJobs: any[];
  oneTimeJobs: any[];
};

export const useNotificationJobs = () => {
  const groupGuid = useAppSelector(selectGroupGuid);
  return useQuery({
    queryKey: ['notificationJobs', groupGuid],
    queryFn: async () => {
      const response = await axios.get<NotificationJobResponse>(
        apiConfig.apiGateway.notification_jobs,
        {
          params: {
            group_guid: groupGuid,
          },
        }
      );
      return response.data;
    },
  });
};

export const invalidateNotificationJobs = () => {
  queryClient.invalidateQueries({
    queryKey: ['notificationJobs'],
  });
};

type CreateNotificationJobDTO = {
  short_description: string;
  group_guid: UUID;
  job_type: string;
  job_options: {
    wind_location: UUID;
    threshold: number;
  };
  repeat_options: {
    pattern: string;
  };
  notification: any;
};

type UpdateNotificationJobDTO = {
  id: UUID;
  active?: boolean;
};

export const createNotificationJob = () => {
  return useMutation<
    NotificationJob,
    AxiosError<{ message: string }>,
    CreateNotificationJobDTO
  >({
    mutationFn: async (data) => {
      return await axios.post(apiConfig.apiGateway.notification_jobs, data);
    },
    onSuccess: () => {
      invalidateNotificationJobs();
    },
    onError: (error) => {
      toast.error(error.response?.data.message ?? error.message, toastConfig);
    },
  });
};

export const updateNotificationJob = () => {
  return useMutation<
    NotificationJob,
    AxiosError<{ message: string }>,
    UpdateNotificationJobDTO
  >({
    mutationFn: async (data) => {
      return await axios.put(
        `${apiConfig.apiGateway.notification_jobs}/${data.id}`,
        {
          active: data.active,
        }
      );
    },
    onSuccess: () => {
      invalidateNotificationJobs();
    },
    onError: (error) => {
      toast.error(error.response?.data.message ?? error.message, toastConfig);
    },
  });
};

type AOR = {
  category_id: 'b25e2dd3-29cd-4447-9c7e-b09942782e6a';
  cc_type: 'Areas of Responsibility';
  current_location_geom: '{"type":"Polygon","coordinates":[[[-82.631025371,35.035055456],[-82.529091757,34.609963764],[-82.312992497,34.539461899],[-82.117279959,34.549537254],[-82.170285438,35.158487792],[-82.300760463,35.205143169],[-82.635102715,35.12514613],[-82.631025371,35.035055456]]]}';
  description: 'Home';
  geolocation: { id: 'feea6fed-4348-4097-a054-261819f8af0d' };
  group_guid: 'd3adac60-3f5a-448b-8794-9464b0271e1d';
  id: 'eedd4eed-0785-4ead-bf5a-cc541a773331';
  location: { id: 'feea6fed-4348-4097-a054-261819f8af0d' };
  name: string;
  parent_item_id: null;
};

type CoreComponentResponse = {
  CC_Relations: any;
  CCs: {
    Actions: any[];
    'Areas of Responsibility': AOR[];
    Objectives: any[];
    'Reliability Indicators': any[];
    'Risk Factors': any[];
    'Supporting Assets and Resources': any[];
  };
  cc_connections: any[];
};

export const useAors = () => {
  const group_guid = useAppSelector(selectGroupGuid);
  return useQuery({
    queryKey: ['coreComponents'],
    queryFn: async () => {
      const response = await axios.post<CoreComponentResponse>(
        apiConfig.apiGateway.fetchCoreComponents,
        {
          group_guid,
        }
      );
      return response.data.CCs['Areas of Responsibility'];
    },
  });
};
