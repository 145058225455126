export const getUserGuid = (getState) => {
    const { app } = getState();
    return app.user.user_guid;
};

export const getGroupGuid = (getState) => {
    const { app } = getState();
    return app.currentlySelectedGroup.group_guid
};

export const getIncidentGuid = (getState) => {
    const {app} = getState();
    return app.currentIncident?.id
}