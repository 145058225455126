import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
  currentDatetime: new Date(),
  selectedDatetime: new Date(),
}

const ai2fpSlice = createSlice({
  name: 'ai2fp',
  initialState,
  reducers: {
    setCurrentDatetime(state, action) {
      state.currentDatetime = action.payload
    },
    setSelectedDatetime(state,action) {
      state.selectedDatetime = action.payload
    }
  },
})

export const { 
  setCurrentDatetime,
  setSelectedDatetime 
} = ai2fpSlice.actions
export const currentDatetime = state => state.ai2fp.currentDatetime
export const selectedDatetime = state => state.ai2fp.selectedDatetime
export default ai2fpSlice
