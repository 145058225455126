import { StylishNewButton } from '../../DesignSystems/New/StylishNewButton';
import React from 'react';
import { ResourceSet } from '../hooks/resourceSetHooks';

const ResourceSetCard: React.FC<{
  row: ResourceSet;
  selectCallback: (set: ResourceSet) => void;
}> = ({ row, selectCallback }) => {
  return (
    <>
      <div className="mb-4">
        <h6 className="mb-2">Resource ID:</h6>
        <p className="m-0">{row.resource_set_id}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Resource Site ID:</h6>
        <p className="m-0">{row.resource_site_id}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Quantity:</h6>
        <p className="m-0">{row.quantity}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Status:</h6>
        <p className="m-0">{row.status}</p>
      </div>
      <StylishNewButton
        className="button button--link"
        onClick={() => selectCallback(row)}
      >
        Edit
      </StylishNewButton>
    </>
  );
};
export default ResourceSetCard;
