import {
  FETCH_RESOURCE_REQUEST,
  UPSERT_FORM_RECORD_ERROR,
  FETCH_ALL_RESOURCE_REQUEST,
} from 'constants/form-action-types';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import config from 'constants/apiConfig';
import axios from 'axios';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

function parseError(api, error) {
  const errorMessage =
    `${api}` +
    'There was an error saving your Form.  No associated Workflows have modified. Please try again.' +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPSERT_FORM_RECORD_ERROR,
  };
}

export const fetchResourceRequest = (id) => (dispatch, getState) =>
  axios(config.apiGateway.fetchResourceRequest, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      operational_period_id: getState().app.currentIncident.current_period,
      id,
    }),
  })
    .then((response) => {
      dispatch({ type: FETCH_RESOURCE_REQUEST, payload: response.data[0] });

      dispatch(endLoading());
    })
    .catch((error) => {
      dispatch(endLoading());
      dispatch(parseError(config.apiGateway.fetchResourceRequest, error));
    });

export const fetchAllResourceRequest = () => (dispatch, getState) =>
  axios(config.apiGateway.fetchAllResourceRequest, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
    }),
  })
    .then((response) => {
      dispatch({ type: FETCH_ALL_RESOURCE_REQUEST, payload: response.data });

      dispatch(endLoading());
    })
    .catch((error) => {
      dispatch(endLoading());
      dispatch(parseError(config.apiGateway.fetchAllResourceRequest, error));
    });

export const updateResourceRequest = (submitData) => (dispatch, getState) =>
  axios(config.apiGateway.createResourceRequest, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      operational_period_id: getState().app.currentIncident.current_period,
      ...submitData,
    }),
  })
    .then(() => {
      dispatch(startLoading());
      dispatch(fetchResourceRequest(submitData.id));
      // toast.success('Create Resource Request successful', {
      //   autoClose: 2000,
      // });
      dispatch(fetchAllResourceRequest());
    })
    .catch((error) => {
      dispatch(endLoading());
      dispatch(parseError(config.apiGateway.createResourceRequest, error));
    });

export const deleteResourceRequest = (resourceId) => (dispatch, getState) =>
  axios(config.apiGateway.deleteResourceRequest, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      operational_period_id: getState().app.currentIncident.current_period,
      id: resourceId,
    }),
  })
    .then(() => {
      dispatch(startLoading());
      dispatch(fetchAllResourceRequest());
      // toast.success('Delete Resource Request successful', {
      //   autoClose: 2000,
      // });
    })
    .catch((error) => {
      dispatch(endLoading());
      dispatch(parseError(config.apiGateway.deleteResourceRequest, error));
    });

export const createUpdateOrder = (orderData) => (dispatch, getState) =>
  axios(config.apiGateway.createUpdateOrder, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      operational_period_id: getState().app.currentIncident.current_period,
      ...orderData,
    }),
  })
    .then(() => {
      dispatch(startLoading());
      dispatch(fetchResourceRequest(orderData.resource_request_id));
      dispatch(fetchAllResourceRequest());
      // toast.success('Create Update Order successful', {
      //   autoClose: 2000,
      // });
    })
    .catch((error) => {
      dispatch(endLoading());
      dispatch(parseError(config.apiGateway.createUpdateOrder, error));
    });

export const deleteOrder = (deleteOrderData) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteOrder, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        operational_period_id: getState().app.currentIncident.current_period,
        id: deleteOrderData.deleteOrderId,
      }),
    })
      .then(() => {
        dispatch(startLoading());
        dispatch(fetchResourceRequest(deleteOrderData.resourceRequestId));
        // toast.success('Delete Order successful', {
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        dispatch(endLoading());
        dispatch(parseError(config.apiGateway.deleteOrder, error));
      });
  };
};
