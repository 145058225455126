import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { getUserGuid, getGroupGuid } from 'slices/sliceHelpers';

// Slice
const resourceSetsSlice = createSlice({
    name: 'ics_resource_sets',
    initialState: {
        ics_resource_sets: [],
        ics_resource_set_usage: {},
        status: 'idle',
        error: null,
        isProcessing: false, // Added to manage loading state
    },
    reducers: {
        createResourceSetSuccess: (state, action) => {
            state.ics_resource_sets.push(action.payload);
        },
        fetchResourceSetsSuccess: (state, action) => {
            state.ics_resource_sets = action.payload;
        },
        updateResourceSetSuccess: (state, action) => {
            const index = state.ics_resource_sets.findIndex((set) => set.id === action.payload.id);
            state.ics_resource_sets[index] = action.payload;
        },
        splitResourceSetSuccess: (state, action) => {
            state.isProcessing = false; // Reset loading state after split
        },
        joinResourceSetsSuccess: (state, action) => {
            state.isProcessing = false; // Reset loading state after split
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.isProcessing = false; // Reset loading state after split
        },
        fetchResourceSetUsageSuccess: (state, action) => {
            state.ics_resource_set_usage = action.payload
        },
        setProcessing: (state, action) => {
            state.isProcessing = action.payload; // Set loading state
        },
    },
});

export const {
    createResourceSetSuccess,
    fetchResourceSetsSuccess,
    updateResourceSetSuccess,
    splitResourceSetSuccess,
    joinResourceSetsSuccess,
    setError,
    fetchResourceSetUsageSuccess,
    setProcessing,
} = resourceSetsSlice.actions;

export default resourceSetsSlice.reducer;

export const createResourceSet = (data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_resource_sets}`, {...data, user_guid});
        dispatch(fetchResourceSets(group_guid));
        dispatch(createResourceSetSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchResourceSets = (group_guid) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_resource_sets}/${group_guid}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchResourceSetsSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateResourceSet = (id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_resource_sets}/${id}`, {...data, user_guid});
        dispatch(fetchResourceSets(group_guid));
        dispatch(updateResourceSetSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchResourceSetUsage = (id) => async (dispatch, getState) => {
    try {
        const response = await axios.get(`${config.apiGateway.ics_resource_sets}/usage/${id}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchResourceSetUsageSuccess(response.data));
        dispatch(fetchResourceSets(group_guid));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const splitResourceSet = (resource_set_id, quantity, name) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        dispatch(setProcessing(true)); // Set loading state
        const response = await axios.post(`${config.apiGateway.ics_resource_sets}/${resource_set_id}/split`, { quantity, name, user_guid });
        dispatch(fetchResourceSets(group_guid));
        dispatch(splitResourceSetSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const joinResourceSets = (source_id, target_id) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        dispatch(setProcessing(true)); // Set loading state
        const response = await axios.post(`${config.apiGateway.ics_resource_sets}/${target_id}/join/${source_id}`, { user_guid });
        dispatch(fetchResourceSets(group_guid));
        dispatch(joinResourceSetsSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};



