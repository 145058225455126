export const ResourceTypes = [
    {
        "ID": "PTB-1008",
        "Type": "Position Task Book",
        "name": "Access and Functional Needs Advisor",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "9-509-1284",
        "Type": "Position Qualification",
        "name": "Access and Functional Needs Advisor (NQS)",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "3-509-1000",
        "Type": "Position Qualification",
        "name": "Advanced Emergency Medical Technician",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "4-508-1273",
        "Type": "Resource Typing Definition",
        "name": "Aerial Apparatus \u2013 Fire",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "3-509-1001",
        "Type": "Position Qualification",
        "name": "Aeromedical Transport Manager",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-509-1228",
        "Type": "Position Qualification",
        "name": "Aeromedical Transport Officer",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-509-1003",
        "Type": "Position Qualification",
        "name": "Aeromedical Transport Paramedic",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-508-1194",
        "Type": "Resource Typing Definition",
        "name": "Air Ambulance Fixed-Wing (Critical Care Transport)",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-508-1026",
        "Type": "Resource Typing Definition",
        "name": "Air Ambulance Fixed-Wing (Non-Critical Care Transport)",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-508-1195",
        "Type": "Resource Typing Definition",
        "name": "Air Ambulance Rotary-Wing (Critical Care Transport)",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-508-1027",
        "Type": "Resource Typing Definition",
        "name": "Air Ambulance Rotary-Wing (Non-Critical Care)",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-509-1002",
        "Type": "Position Qualification",
        "name": "Air Medical Transport Mechanic",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "2-509-1423",
        "Type": "Position Qualification",
        "name": "Air Operations Branch Director (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "PTB-1009",
        "Type": "Position Task Book",
        "name": "Air Operations Branch Director (Single Type)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "8-508-1007",
        "Type": "Resource Typing Definition",
        "name": "Air Search Team (Fixed-Wing)",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "2-509-1424",
        "Type": "Position Qualification",
        "name": "Air Support Group Supervisor (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "PTB-1010",
        "Type": "Position Task Book",
        "name": "Air Support Group Supervisor (Single Type)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-509-1425",
        "Type": "Position Qualification",
        "name": "Air Tactical Group Supervisor (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "PTB-1011",
        "Type": "Position Task Book",
        "name": "Air Tactical Group Supervisor (Single Type)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-508-1038",
        "Type": "Resource Typing Definition",
        "name": "Airborne Communications Relay (Fixed-Wing) (CAP)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Communications"
    },
    {
        "ID": "2-508-1037",
        "Type": "Resource Typing Definition",
        "name": "Airborne Communications Relay Team (Fixed-Wing)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Communications"
    },
    {
        "ID": "8-508-1008",
        "Type": "Resource Typing Definition",
        "name": "Airborne Reconnaissance (Fixed Wing)",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "4-509-1478",
        "Type": "Position Qualification",
        "name": "Airport Firefighter",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "3-508-1028",
        "Type": "Resource Typing Definition",
        "name": "Ambulance Ground Team",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-509-1011",
        "Type": "Position Qualification",
        "name": "Ambulance Operator",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-508-1029",
        "Type": "Resource Typing Definition",
        "name": "Ambulance Strike Team",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-509-1224",
        "Type": "Position Qualification",
        "name": "Ambulance Strike Team Leader",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-508-1030",
        "Type": "Resource Typing Definition",
        "name": "Ambulance Task Force",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "1-508-1221",
        "Type": "Resource Typing Definition",
        "name": "Animal and Agriculture Damage Assessment Team",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Situational Assessment"
    },
    {
        "ID": "1-509-1331",
        "Type": "Position Qualification",
        "name": "Animal Behavior Specialist",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "1-509-1332",
        "Type": "Position Qualification",
        "name": "Animal Care and Handling Specialist",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "1-509-1333",
        "Type": "Position Qualification",
        "name": "Animal Control/Humane Officer",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "1-509-1334",
        "Type": "Position Qualification",
        "name": "Animal Decontamination Specialist",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Environmental Response/Health and Safety"
    },
    {
        "ID": "1-509-1335",
        "Type": "Position Qualification",
        "name": "Animal Depopulation Specialist",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Environmental Response/Health and Safety"
    },
    {
        "ID": "1-508-1222",
        "Type": "Resource Typing Definition",
        "name": "Animal Depopulation Team",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Environmental Response/Health and Safety"
    },
    {
        "ID": "1-509-1336",
        "Type": "Position Qualification",
        "name": "Animal Emergency Response Shelter Manager",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "1-509-1337",
        "Type": "Position Qualification",
        "name": "Animal Emergency Response Team Leader",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "1-508-1223",
        "Type": "Resource Typing Definition",
        "name": "Animal Evacuation, Transport, and Re-Entry Team",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Critical Transportation"
    },
    {
        "ID": "1-509-1338",
        "Type": "Position Qualification",
        "name": "Animal Intake and Reunification Specialist",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "1-508-1224",
        "Type": "Resource Typing Definition",
        "name": "Animal Search and Rescue Team",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "1-509-1339",
        "Type": "Position Qualification",
        "name": "Animal Search and Rescue Technician",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "1-508-1226",
        "Type": "Resource Typing Definition",
        "name": "Animal Sheltering Team - Animal-Only Shelter",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "1-508-1227",
        "Type": "Resource Typing Definition",
        "name": "Animal Sheltering Team - Cohabitated Shelter",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "1-508-1228",
        "Type": "Resource Typing Definition",
        "name": "Animal Sheltering Team - Collocated Shelter",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "7-509-1476",
        "Type": "Position Qualification",
        "name": "Architect",
        "resource_category": "Public Works",
        "primary_core_capability": "Situational Assessment"
    },
    {
        "ID": "4-508-1274",
        "Type": "Resource Typing Definition",
        "name": "Area Command Team \u2013 Firefighting",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "7-509-1094",
        "Type": "Position Qualification",
        "name": "Assistant Public Works Director - Logistics",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-509-1093",
        "Type": "Position Qualification",
        "name": "Assistant Public Works Director - Operations",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "12-509-1220",
        "Type": "Position Qualification",
        "name": "Behavioral Health Chaplaincy Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-508-1186",
        "Type": "Resource Typing Definition",
        "name": "Behavioral Health Community Services Team",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1221",
        "Type": "Position Qualification",
        "name": "Behavioral Health Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1222",
        "Type": "Position Qualification",
        "name": "Behavioral Health Team Leader",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "6-509-1258",
        "Type": "Position Qualification",
        "name": "Boat Crew Member (Law Enforcement)",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "8-509-1256",
        "Type": "Position Qualification",
        "name": "Boat Crew Member (Search and Rescue)",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "6-509-1257",
        "Type": "Position Qualification",
        "name": "Boat Operator (Law Enforcement)",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "8-509-1255",
        "Type": "Position Qualification",
        "name": "Boat Operator (Search and Rescue)",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "6-508-1176",
        "Type": "Resource Typing Definition",
        "name": "Bomb Response Team",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "Interdiction and Disruption"
    },
    {
        "ID": "6-509-1207",
        "Type": "Position Qualification",
        "name": "Bomb Technician",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "Interdiction and Disruption"
    },
    {
        "ID": "8-509-1175",
        "Type": "Position Qualification",
        "name": "Canine Search Specialist - Disaster/Structural Collapse Human Remains",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1176",
        "Type": "Position Qualification",
        "name": "Canine Search Specialist - Disaster/Structural Collapse Live",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1177",
        "Type": "Position Qualification",
        "name": "Canine Search Specialist - Land Human Remains",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1178",
        "Type": "Position Qualification",
        "name": "Canine Search Specialist - Land Live",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1179",
        "Type": "Position Qualification",
        "name": "Canine Search Specialist - Water Human Remains",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-508-1164",
        "Type": "Resource Typing Definition",
        "name": "Canine Search Team - Disaster/Structural Collapse Human Remains",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-508-1165",
        "Type": "Resource Typing Definition",
        "name": "Canine Search Team - Disaster/Structural Collapse Live",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-508-1166",
        "Type": "Resource Typing Definition",
        "name": "Canine Search Team \u2013 Land Human Remains",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-508-1167",
        "Type": "Resource Typing Definition",
        "name": "Canine Search Team - Land Live",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-508-1168",
        "Type": "Resource Typing Definition",
        "name": "Canine Search Team - Water Human Remains",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-508-1015",
        "Type": "Resource Typing Definition",
        "name": "Cave Search and Rescue Team",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1397",
        "Type": "Position Qualification",
        "name": "Cave Search and Rescue Team Leader",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1181",
        "Type": "Position Qualification",
        "name": "Cave Search and Rescue Technician",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "4-509-1477",
        "Type": "Position Qualification",
        "name": "Chemical Operations Support Specialist",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Situational Assessment"
    },
    {
        "ID": "PTB-1119",
        "Type": "Position Task Book",
        "name": "Chemical Operations Support Specialist (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1118",
        "Type": "Position Task Book",
        "name": "Chemical Operations Support Specialist (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1117",
        "Type": "Position Task Book",
        "name": "Chemical Operations Support Specialist (Type 3)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "7-509-1095",
        "Type": "Position Qualification",
        "name": "Civil Engineer",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "5-509-1244",
        "Type": "Position Qualification",
        "name": "Communications Technician (NQS)",
        "resource_category": "Communications",
        "primary_core_capability": "Operational Communications"
    },
    {
        "ID": "5-509-1328",
        "Type": "Position Qualification",
        "name": "Communications Unit Leader (NQS)",
        "resource_category": "Communications",
        "primary_core_capability": "Operational Communications"
    },
    {
        "ID": "10-509-1464",
        "Type": "Position Qualification",
        "name": "Community Emergency Response Team (CERT) Section Chief",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Community Resilience"
    },
    {
        "ID": "PTB-1109",
        "Type": "Position Task Book",
        "name": "Community Emergency Response Team (CERT) Section Chief",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "10-509-1465",
        "Type": "Position Qualification",
        "name": "Community Emergency Response Team (CERT) Team Leader",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Community Resilience"
    },
    {
        "ID": "PTB-1110",
        "Type": "Position Task Book",
        "name": "Community Emergency Response Team (CERT) Team Leader (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1111",
        "Type": "Position Task Book",
        "name": "Community Emergency Response Team (CERT) Team Leader (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "10-509-1466",
        "Type": "Position Qualification",
        "name": "Community Emergency Response Team (CERT) Team Volunteer",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Community Resilience"
    },
    {
        "ID": "PTB-1112",
        "Type": "Position Task Book",
        "name": "Community Emergency Response Team (CERT) Volunteer (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1108",
        "Type": "Position Task Book",
        "name": "Community Emergency Response Team (CERT) Volunteer (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "1-508-1229",
        "Type": "Resource Typing Definition",
        "name": "Companion Animal Decontamination Team",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Environmental Response/Health and Safety"
    },
    {
        "ID": "10-509-1288",
        "Type": "Position Qualification",
        "name": "Compensation/Claims Unit Leader (NQS)",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "PTB-1014",
        "Type": "Position Task Book",
        "name": "Compensation/Claims Unit Leader (Single Type)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "13-509-1252",
        "Type": "Position Qualification",
        "name": "Computer Network Defense Infrastructure Support Specialist",
        "resource_category": "Cybersecurity",
        "primary_core_capability": "Cybersecurity"
    },
    {
        "ID": "13-509-1251",
        "Type": "Position Qualification",
        "name": "Computer Network Defense Analyst",
        "resource_category": "Cybersecurity",
        "primary_core_capability": "Cybersecurity"
    },
    {
        "ID": "2-509-1426",
        "Type": "Position Qualification",
        "name": "Cost Unit Leader (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "PTB-1015",
        "Type": "Position Task Book",
        "name": "Cost Unit Leader (Single Type)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "4-508-1275",
        "Type": "Resource Typing Definition",
        "name": "Crew Transport (Firefighting Crew)",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "6-508-1231",
        "Type": "Resource Typing Definition",
        "name": "Crisis Negotiation Team",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "6-509-1342",
        "Type": "Position Qualification",
        "name": "Crisis Negotiation Team Leader",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "6-509-1343",
        "Type": "Position Qualification",
        "name": "Crisis Negotiation Team Negotiator",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "2-508-1041",
        "Type": "Resource Typing Definition",
        "name": "Critical Incident Stress Management Team",
        "resource_category": "Incident Management",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "13-509-1250",
        "Type": "Position Qualification",
        "name": "Cyber Incident Responder",
        "resource_category": "Cybersecurity",
        "primary_core_capability": "Cybersecurity"
    },
    {
        "ID": "13-508-1212",
        "Type": "Resource Typing Definition",
        "name": "Cyber Incident Response Team",
        "resource_category": "Cybersecurity",
        "primary_core_capability": "Cybersecurity"
    },
    {
        "ID": "PTB-1016",
        "Type": "Position Task Book",
        "name": "Damage Assessment Coordinator",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "10-509-1411",
        "Type": "Position Qualification",
        "name": "Damage Assessment Coordinator (NQS)",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1232",
        "Type": "Resource Typing Definition",
        "name": "Damage Assessment Team \u2013 Public Works",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "13-509-1254",
        "Type": "Position Qualification",
        "name": "Data Administration Specialist",
        "resource_category": "Cybersecurity",
        "primary_core_capability": "Cybersecurity"
    },
    {
        "ID": "7-508-1233",
        "Type": "Resource Typing Definition",
        "name": "Debris Assessment Team",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1234",
        "Type": "Resource Typing Definition",
        "name": "Debris Monitoring Team",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-509-1347",
        "Type": "Position Qualification",
        "name": "Debris Operations Manager",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-509-1348",
        "Type": "Position Qualification",
        "name": "Debris Planning Manager",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-509-1098",
        "Type": "Position Qualification",
        "name": "Debris Supervisor",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-509-1460",
        "Type": "Position Qualification",
        "name": "Debris Technical Specialist",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "2-509-1427",
        "Type": "Position Qualification",
        "name": "Demobilization Unit Leader (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "PTB-1017",
        "Type": "Position Task Book",
        "name": "Demobilization Unit Leader (Single Type)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "12-509-1043",
        "Type": "Position Qualification",
        "name": "Dental Assistant/Hygienist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1044",
        "Type": "Position Qualification",
        "name": "Dentist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1045",
        "Type": "Position Qualification",
        "name": "Dialysis Technician",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1367",
        "Type": "Position Qualification",
        "name": "Dietitian/Nutritionist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "13-509-1253",
        "Type": "Position Qualification",
        "name": "Digital Forensics Specialist",
        "resource_category": "Cybersecurity",
        "primary_core_capability": "Cybersecurity"
    },
    {
        "ID": "10-508-1200",
        "Type": "Resource Typing Definition",
        "name": "Disaster Cost Recovery Management Team",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Economic Recovery"
    },
    {
        "ID": "10-509-1231",
        "Type": "Position Qualification",
        "name": "Disaster Cost Recovery Management Team Leader",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Economic Recovery"
    },
    {
        "ID": "10-509-1232",
        "Type": "Position Qualification",
        "name": "Disaster Recovery Data Collection Specialist",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Economic Recovery"
    },
    {
        "ID": "10-509-1230",
        "Type": "Position Qualification",
        "name": "Disaster Recovery Finance Specialist",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Economic Recovery"
    },
    {
        "ID": "9-508-1185",
        "Type": "Resource Typing Definition",
        "name": "Distribution of Emergency Supplies Task Force",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "9-509-1329",
        "Type": "Position Qualification",
        "name": "Distribution of Emergency Supplies Team Leader",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "11-508-1251",
        "Type": "Resource Typing Definition",
        "name": "Distribution Support Team",
        "resource_category": "Logistics and Transportation",
        "primary_core_capability": "Logistics and Supply Chain Management"
    },
    {
        "ID": "6-509-1375",
        "Type": "Position Qualification",
        "name": "Dive Team Leader",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "6-509-1374",
        "Type": "Position Qualification",
        "name": "Diver",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "PTB-1018",
        "Type": "Position Task Book",
        "name": "Division/Group Supervisor",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-509-1406",
        "Type": "Position Qualification",
        "name": "Division/Group Supervisor (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "2-509-1428",
        "Type": "Position Qualification",
        "name": "Documentation Unit Leader (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "PTB-1019",
        "Type": "Position Task Book",
        "name": "Documentation Unit Leader (Single Type)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "9-508-1190",
        "Type": "Resource Typing Definition",
        "name": "Donated Goods Warehouse Management Team",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "9-509-1217",
        "Type": "Position Qualification",
        "name": "Donations Call Center Supervisor",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "9-508-1191",
        "Type": "Resource Typing Definition",
        "name": "Donations Coordination Task Force",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "PTB-1020",
        "Type": "Position Task Book",
        "name": "Donations Coordination Task Force Leader",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "9-509-1282",
        "Type": "Position Qualification",
        "name": "Donations Coordination Task Force Leader (NQS)",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "PTB-1021",
        "Type": "Position Task Book",
        "name": "Donations Specialist",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "9-509-1283",
        "Type": "Position Qualification",
        "name": "Donations Specialist (NQS)",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "9-508-1187",
        "Type": "Resource Typing Definition",
        "name": "Drive-Through Point of Distribution Team",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "3-508-1031",
        "Type": "Resource Typing Definition",
        "name": "Emergency Care Task Force",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-509-1008",
        "Type": "Position Qualification",
        "name": "Emergency Medical Responder",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-509-1227",
        "Type": "Position Qualification",
        "name": "Emergency Medical Services Logistics Officer",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-509-1225",
        "Type": "Position Qualification",
        "name": "Emergency Medical Services Medical Officer",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-509-1226",
        "Type": "Position Qualification",
        "name": "Emergency Medical Services Operations Officer",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-508-1236",
        "Type": "Resource Typing Definition",
        "name": "Emergency Medical Services Task Force",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-509-1350",
        "Type": "Position Qualification",
        "name": "Emergency Medical Services Task Force Leader",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-509-1010",
        "Type": "Position Qualification",
        "name": "Emergency Medical Technician",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "8-509-1130",
        "Type": "Position Qualification",
        "name": "Emergency Services Rescue Manager",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1131",
        "Type": "Position Qualification",
        "name": "Emergency Services Rescue Technician",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "3-509-1012",
        "Type": "Position Qualification",
        "name": "Emergency Vehicle Operator - Heavy",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "4-509-1479",
        "Type": "Position Qualification",
        "name": "Emergency Vehicle Technician",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "4-508-1276",
        "Type": "Resource Typing Definition",
        "name": "Engine \u2013 Fire (Pumper)",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "7-509-1099",
        "Type": "Position Qualification",
        "name": "Engineering Manager",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "PTB-1002",
        "Type": "Position Task Book",
        "name": "Environmental and Historic Preservation (EHP) Environmental Specialist",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1003",
        "Type": "Position Task Book",
        "name": "Environmental and Historic Preservation (EHP) Historic Preservation Specialist",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "10-509-1400",
        "Type": "Position Qualification",
        "name": "Environmental and Historic Preservation Environmental Specialist (NQS)",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Natural and Cultural Resources"
    },
    {
        "ID": "10-509-1399",
        "Type": "Position Qualification",
        "name": "Environmental and Historic Preservation Historic Preservation Specialist (NQS)",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Natural and Cultural Resources"
    },
    {
        "ID": "7-509-1416",
        "Type": "Position Qualification",
        "name": "Environmental Compliance Specialist \u2013 Water Sector Infrastructure",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "12-509-1048",
        "Type": "Position Qualification",
        "name": "Environmental Health Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Environmental Response/Health and Safety"
    },
    {
        "ID": "12-508-1135",
        "Type": "Resource Typing Definition",
        "name": "Environmental Health Team",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Environmental Response/Health and Safety"
    },
    {
        "ID": "12-509-1049",
        "Type": "Position Qualification",
        "name": "Environmental Health Team Leader",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Environmental Response/Health and Safety"
    },
    {
        "ID": "2-508-1045",
        "Type": "Resource Typing Definition",
        "name": "EOC Management Support Team",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "12-509-1242",
        "Type": "Position Qualification",
        "name": "Epidemiological Information Technology Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-508-1133",
        "Type": "Resource Typing Definition",
        "name": "Epidemiological Response Team",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1050",
        "Type": "Position Qualification",
        "name": "Epidemiologist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1051",
        "Type": "Position Qualification",
        "name": "Epidemiology Interviewer",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "7-509-1101",
        "Type": "Position Qualification",
        "name": "Equipment Operator",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "2-508-1048",
        "Type": "Resource Typing Definition",
        "name": "Evacuation Coordination Team",
        "resource_category": "Incident Management",
        "primary_core_capability": "Critical Transportation"
    },
    {
        "ID": "2-509-1351",
        "Type": "Position Qualification",
        "name": "Evacuation Coordination Team Leader",
        "resource_category": "Incident Management",
        "primary_core_capability": "Critical Transportation"
    },
    {
        "ID": "9-508-1192",
        "Type": "Resource Typing Definition",
        "name": "Evacuation Shelter",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "11-509-1325",
        "Type": "Position Qualification",
        "name": "Facilities Unit Leader (NQS)",
        "resource_category": "Logistics and Transportation",
        "primary_core_capability": "Logistics and Supply Chain Management"
    },
    {
        "ID": "PTB-1107",
        "Type": "Position Task Book",
        "name": "Facilities Unit Leader (Single Type)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "12-509-1267",
        "Type": "Position Qualification",
        "name": "Fatality Management Autopsy Technician",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1388",
        "Type": "Position Qualification",
        "name": "Fatality Management Call Taker Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1391",
        "Type": "Position Qualification",
        "name": "Fatality Management Dental/Medical Records Acquisition Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-508-1217",
        "Type": "Resource Typing Definition",
        "name": "Fatality Management Disaster Portable Morgue Unit",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1259",
        "Type": "Position Qualification",
        "name": "Fatality Management Disaster Portable Morgue Unit Building/Arrangements Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1260",
        "Type": "Position Qualification",
        "name": "Fatality Management Disaster Portable Morgue Unit Communications Coordinator",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1261",
        "Type": "Position Qualification",
        "name": "Fatality Management Disaster Portable Morgue Unit Communications Information Technology Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1262",
        "Type": "Position Qualification",
        "name": "Fatality Management Disaster Portable Morgue Unit Communications Programming Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1263",
        "Type": "Position Qualification",
        "name": "Fatality Management Disaster Portable Morgue Unit Facilities Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "PTB-1065",
        "Type": "Position Task Book",
        "name": "Fatality Management Disaster Portable Morgue Unit Leader",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "12-509-1327",
        "Type": "Position Qualification",
        "name": "Fatality Management Disaster Portable Morgue Unit Leader (NQS)",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "PTB-1066",
        "Type": "Position Task Book",
        "name": "Fatality Management Disaster Portable Morgue Unit Supply Specialist",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "12-509-1326",
        "Type": "Position Qualification",
        "name": "Fatality Management Disaster Portable Morgue Unit Supply Specialist (NQS)",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1268",
        "Type": "Position Qualification",
        "name": "Fatality Management DNA Collection Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1275",
        "Type": "Position Qualification",
        "name": "Fatality Management Embalming and Casketing Mortuary Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1269",
        "Type": "Position Qualification",
        "name": "Fatality Management Forensic Anthropologist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1270",
        "Type": "Position Qualification",
        "name": "Fatality Management Forensic Pathologist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1277",
        "Type": "Position Qualification",
        "name": "Fatality Management Forensic Photographer",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1264",
        "Type": "Position Qualification",
        "name": "Fatality Management Human Remains Recovery Collection Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1265",
        "Type": "Position Qualification",
        "name": "Fatality Management Human Remains Recovery Documentation Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-508-1218",
        "Type": "Resource Typing Definition",
        "name": "Fatality Management Human Remains Recovery Team",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "PTB-1025",
        "Type": "Position Task Book",
        "name": "Fatality Management Human Remains Recovery Team Leader",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "12-509-1322",
        "Type": "Position Qualification",
        "name": "Fatality Management Human Remains Recovery Team Leader (NQS)",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1279",
        "Type": "Position Qualification",
        "name": "Fatality Management Human Remains Storage and Release Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1266",
        "Type": "Position Qualification",
        "name": "Fatality Management Human Remains Transportation Staging Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1395",
        "Type": "Position Qualification",
        "name": "Fatality Management Information Collection Coordinator",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1389",
        "Type": "Position Qualification",
        "name": "Fatality Management Interview Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "PTB-1023",
        "Type": "Position Task Book",
        "name": "Fatality Management Morgue Admitting Specialist",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "12-509-1315",
        "Type": "Position Qualification",
        "name": "Fatality Management Morgue Admitting Specialist (NQS)",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1271",
        "Type": "Position Qualification",
        "name": "Fatality Management Morgue Dental Assistant",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1276",
        "Type": "Position Qualification",
        "name": "Fatality Management Morgue Escort",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1272",
        "Type": "Position Qualification",
        "name": "Fatality Management Morgue Fingerprint Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1273",
        "Type": "Position Qualification",
        "name": "Fatality Management Morgue Forensic Odontologist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-508-1219",
        "Type": "Resource Typing Definition",
        "name": "Fatality Management Morgue Forensic Team",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "PTB-1024",
        "Type": "Position Task Book",
        "name": "Fatality Management Morgue Forensic Team Leader",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "12-509-1314",
        "Type": "Position Qualification",
        "name": "Fatality Management Morgue Forensic Team Leader (NQS)",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-508-1220",
        "Type": "Resource Typing Definition",
        "name": "Fatality Management Morgue Processing Unit",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "PTB-1022",
        "Type": "Position Task Book",
        "name": "Fatality Management Morgue Processing Unit Leader",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "12-509-1313",
        "Type": "Position Qualification",
        "name": "Fatality Management Morgue Processing Unit Leader (NQS)",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1278",
        "Type": "Position Qualification",
        "name": "Fatality Management Personal Effects Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1274",
        "Type": "Position Qualification",
        "name": "Fatality Management Radiology Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1390",
        "Type": "Position Qualification",
        "name": "Fatality Management Records Management Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1393",
        "Type": "Position Qualification",
        "name": "Fatality Management Training Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1394",
        "Type": "Position Qualification",
        "name": "Fatality Management Victim Information Center Data/Records Coordinator",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1392",
        "Type": "Position Qualification",
        "name": "Fatality Management Victim Information Center Documentation Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-508-1249",
        "Type": "Resource Typing Definition",
        "name": "Fatality Management Victim Information Center Team",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "12-509-1396",
        "Type": "Position Qualification",
        "name": "Fatality Management Victim Information Center Team Leader",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "9-509-1218",
        "Type": "Position Qualification",
        "name": "Feeding Services Team Leader",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "9-509-1084",
        "Type": "Position Qualification",
        "name": "Field Kitchen Manager",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "9-508-1000",
        "Type": "Resource Typing Definition",
        "name": "Field Kitchen Unit",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "PTB-1102",
        "Type": "Position Task Book",
        "name": "Finance and Administration Section Chief (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1101",
        "Type": "Position Task Book",
        "name": "Finance and Administration Section Chief (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1026",
        "Type": "Position Task Book",
        "name": "Finance and Administration Section Chief (Type 3)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-509-1031",
        "Type": "Position Qualification",
        "name": "Finance/Administration Section Chief (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "4-509-1480",
        "Type": "Position Qualification",
        "name": "Fire Apparatus Operator",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "4-508-1277",
        "Type": "Resource Typing Definition",
        "name": "Fire Boat",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "4-508-1278",
        "Type": "Resource Typing Definition",
        "name": "Fire Engine Strike Team \u2013 Structural",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "4-509-1482",
        "Type": "Position Qualification",
        "name": "Fire Inspector",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "4-509-1483",
        "Type": "Position Qualification",
        "name": "Fire Officer",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "4-509-1481",
        "Type": "Position Qualification",
        "name": "Firefighter (Structural)",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "8-508-1265",
        "Type": "Resource Typing Definition",
        "name": "Fixed Wing Search Team / Disaster Reconnaissance",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "4-508-1279",
        "Type": "Resource Typing Definition",
        "name": "Foam Tender \u2013 Firefighting",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "9-508-1272",
        "Type": "Resource Typing Definition",
        "name": "Food Service Delivery Unit",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "11-509-1293",
        "Type": "Position Qualification",
        "name": "Food Unit Leader (NQS)",
        "resource_category": "Logistics and Transportation",
        "primary_core_capability": "Logistics and Supply Chain Management"
    },
    {
        "ID": "PTB-1027",
        "Type": "Position Task Book",
        "name": "Food Unit Leader (Single Type)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1067",
        "Type": "Position Task Book",
        "name": "Forensic Group Supervisor",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-509-1429",
        "Type": "Position Qualification",
        "name": "Forensic Group Supervisor (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "4-508-1280",
        "Type": "Resource Typing Definition",
        "name": "Fuel Tender",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "6-509-1280",
        "Type": "Position Qualification",
        "name": "Fusion Liaison Officer",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "Intelligence and Information Sharing"
    },
    {
        "ID": "7-509-1418",
        "Type": "Position Qualification",
        "name": "Generator Support Team Leader - Water Sector Infrastructure",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "14-508-1210",
        "Type": "Resource Typing Definition",
        "name": "Geographic Information Systems (GIS) Field Data Collection Team",
        "resource_category": "Geographic Info Systems and Info Technology",
        "primary_core_capability": "Planning"
    },
    {
        "ID": "14-508-1211",
        "Type": "Resource Typing Definition",
        "name": "Geographic Information Systems (GIS) Map Support Team",
        "resource_category": "Geographic Info Systems and Info Technology",
        "primary_core_capability": "Planning"
    },
    {
        "ID": "14-509-1324",
        "Type": "Position Qualification",
        "name": "Geographic Information Systems Analyst (NQS)",
        "resource_category": "Geographic Info Systems and Info Technology",
        "primary_core_capability": "Planning"
    },
    {
        "ID": "PTB-1100",
        "Type": "Position Task Book",
        "name": "Geographic Information Systems Analyst (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1069",
        "Type": "Position Task Book",
        "name": "Geographic Information Systems Analyst (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "14-509-1198",
        "Type": "Position Qualification",
        "name": "Geographic Information Systems Field Data Entry Technician",
        "resource_category": "Geographic Info Systems and Info Technology",
        "primary_core_capability": "Planning"
    },
    {
        "ID": "PTB-1028",
        "Type": "Position Task Book",
        "name": "Geographic Information Systems Specialist",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "14-509-1323",
        "Type": "Position Qualification",
        "name": "Geographic Information Systems Specialist (NQS)",
        "resource_category": "Geographic Info Systems and Info Technology",
        "primary_core_capability": "Planning"
    },
    {
        "ID": "PTB-1068",
        "Type": "Position Task Book",
        "name": "Geographic Information Systems Supervisor",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "14-509-1297",
        "Type": "Position Qualification",
        "name": "Geographic Information Systems Supervisor (NQS)",
        "resource_category": "Geographic Info Systems and Info Technology",
        "primary_core_capability": "Planning"
    },
    {
        "ID": "21-509-1452",
        "Type": "Position Qualification",
        "name": "Geological Survey Support Specialist",
        "resource_category": "Damage Assessment",
        "primary_core_capability": "Risk Management for Protection Programs and Activities"
    },
    {
        "ID": "21-509-1450",
        "Type": "Position Qualification",
        "name": "Geology Field Reconnaissance Specialist",
        "resource_category": "Damage Assessment",
        "primary_core_capability": "Risk Management for Protection Programs and Activities"
    },
    {
        "ID": "21-508-1263",
        "Type": "Resource Typing Definition",
        "name": "Geology Field Reconnaissance Team",
        "resource_category": "Damage Assessment",
        "primary_core_capability": "Risk Management for Protection Programs and Activities"
    },
    {
        "ID": "PTB-1029",
        "Type": "Position Task Book",
        "name": "Ground Support Unit Leader",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "11-509-1298",
        "Type": "Position Qualification",
        "name": "Ground Support Unit Leader (NQS)",
        "resource_category": "Logistics and Transportation",
        "primary_core_capability": "Logistics and Supply Chain Management"
    },
    {
        "ID": "4-508-1281",
        "Type": "Resource Typing Definition",
        "name": "Hand Crew",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "PTB-1004",
        "Type": "Position Task Book",
        "name": "Hazard Mitigation Community Education and Outreach Specialist",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "15-509-1407",
        "Type": "Position Qualification",
        "name": "Hazard Mitigation Community Education and Outreach Specialist (NQS)",
        "resource_category": "Mitigation",
        "primary_core_capability": "Public Information and Warning"
    },
    {
        "ID": "PTB-1005",
        "Type": "Position Task Book",
        "name": "Hazard Mitigation Community Planner Specialist",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "15-509-1408",
        "Type": "Position Qualification",
        "name": "Hazard Mitigation Community Planner Specialist (NQS)",
        "resource_category": "Mitigation",
        "primary_core_capability": "Planning"
    },
    {
        "ID": "PTB-1006",
        "Type": "Position Task Book",
        "name": "Hazard Mitigation Engineering and Architect Specialist",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "15-509-1409",
        "Type": "Position Qualification",
        "name": "Hazard Mitigation Engineering and Architect Specialist (NQS)",
        "resource_category": "Mitigation",
        "primary_core_capability": "Community Resilience"
    },
    {
        "ID": "PTB-1007",
        "Type": "Position Task Book",
        "name": "Hazard Mitigation Floodplain Management Specialist",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "15-509-1410",
        "Type": "Position Qualification",
        "name": "Hazard Mitigation Floodplain Management Specialist (NQS)",
        "resource_category": "Mitigation",
        "primary_core_capability": "Long-term Vulnerability Reduction"
    },
    {
        "ID": "15-509-1200",
        "Type": "Position Qualification",
        "name": "Hazard Mitigation Officer",
        "resource_category": "Mitigation",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "15-508-1175",
        "Type": "Resource Typing Definition",
        "name": "Hazard Mitigation Operations Team",
        "resource_category": "Mitigation",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "15-509-1199",
        "Type": "Position Qualification",
        "name": "Hazard Mitigation Outreach Specialist",
        "resource_category": "Mitigation",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "15-509-1201",
        "Type": "Position Qualification",
        "name": "Hazard Mitigation Planner",
        "resource_category": "Mitigation",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "15-508-1174",
        "Type": "Resource Typing Definition",
        "name": "Hazard Mitigation Planning Team",
        "resource_category": "Mitigation",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "15-509-1202",
        "Type": "Position Qualification",
        "name": "Hazard Mitigation Risk Analyst",
        "resource_category": "Mitigation",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "4-508-1248",
        "Type": "Resource Typing Definition",
        "name": "Hazardous Materials Response Team",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Environmental Response/Health and Safety"
    },
    {
        "ID": "4-509-1387",
        "Type": "Position Qualification",
        "name": "Hazardous Materials Technician",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Environmental Response/Health and Safety"
    },
    {
        "ID": "12-508-1238",
        "Type": "Resource Typing Definition",
        "name": "Healthcare Resource Coordination and Support Team",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "4-508-1282",
        "Type": "Resource Typing Definition",
        "name": "Helicopter \u2013 Firefighting and Rescue",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "8-509-1170",
        "Type": "Position Qualification",
        "name": "Helicopter Search and Rescue Crew Chief",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1171",
        "Type": "Position Qualification",
        "name": "Helicopter Search and Rescue Pilot",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1172",
        "Type": "Position Qualification",
        "name": "Helicopter Search and Rescue Technician",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-508-1162",
        "Type": "Resource Typing Definition",
        "name": "Helicopter/Rotary Wing Search and Rescue Team",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "10-508-1254",
        "Type": "Resource Typing Definition",
        "name": "Housing Task Force (NQS)",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Housing"
    },
    {
        "ID": "PTB-1030",
        "Type": "Position Task Book",
        "name": "Housing Task Force Field Coordinator",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "10-509-1414",
        "Type": "Position Qualification",
        "name": "Housing Task Force Field Coordinator (NQS)",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Housing"
    },
    {
        "ID": "PTB-1031",
        "Type": "Position Task Book",
        "name": "Housing Task Force Leader",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "10-509-1413",
        "Type": "Position Qualification",
        "name": "Housing Task Force Leader (NQS)",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Housing"
    },
    {
        "ID": "10-508-1214",
        "Type": "Resource Typing Definition",
        "name": "Human Services Disaster Assessment Team",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Economic Recovery"
    },
    {
        "ID": "PTB-1032",
        "Type": "Position Task Book",
        "name": "Human Services Disaster Assessment Team Leader",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "9-509-1430",
        "Type": "Position Qualification",
        "name": "Human Services Disaster Assessment Team Leader (NQS)",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "9-509-1431",
        "Type": "Position Qualification",
        "name": "Human Services Recovery Support Specialist (NQS)",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "PTB-1075",
        "Type": "Position Task Book",
        "name": "Human Services Recovery Support Specialist (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1033",
        "Type": "Position Task Book",
        "name": "Human Services Recovery Support Specialist (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "10-508-1213",
        "Type": "Resource Typing Definition",
        "name": "Human Services Recovery Support Team",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Economic Recovery"
    },
    {
        "ID": "0-508-1179",
        "Type": "Resource Typing Definition",
        "name": "Human-Portable Radiation Detector",
        "resource_category": "Prevention",
        "primary_core_capability": "Screening, Search, and Detection"
    },
    {
        "ID": "7-509-1417",
        "Type": "Position Qualification",
        "name": "Hydraulic Modeler",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "2-509-1037",
        "Type": "Position Qualification",
        "name": "Incident Command System Public Information Officer (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Public Information and Warning"
    },
    {
        "ID": "PTB-1088",
        "Type": "Position Task Book",
        "name": "Incident Command System Public Information Officer (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1087",
        "Type": "Position Task Book",
        "name": "Incident Command System Public Information Officer (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1048",
        "Type": "Position Task Book",
        "name": "Incident Command System Public Information Officer (Type 3)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-509-1032",
        "Type": "Position Qualification",
        "name": "Incident Commander (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "PTB-1083",
        "Type": "Position Task Book",
        "name": "Incident Commander (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1081",
        "Type": "Position Task Book",
        "name": "Incident Commander (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1076",
        "Type": "Position Task Book",
        "name": "Incident Commander (Type 3)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-508-1050",
        "Type": "Resource Typing Definition",
        "name": "Incident Management Team",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "4-508-1283",
        "Type": "Resource Typing Definition",
        "name": "Incident Management Team \u2013 Firefighting",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "10-509-1398",
        "Type": "Position Qualification",
        "name": "Incident/Exercise Evaluator (NQS)",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Situational Assessment"
    },
    {
        "ID": "PTB-1089",
        "Type": "Position Task Book",
        "name": "Incident/Exercise Evaluator (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1085",
        "Type": "Position Task Book",
        "name": "Incident/Exercise Evaluator (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1035",
        "Type": "Position Task Book",
        "name": "Incident/Exercise Evaluator (Type 3)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-509-1157",
        "Type": "Position Qualification",
        "name": "Individual Assistance Disaster Assessment Team Leader",
        "resource_category": "Incident Management",
        "primary_core_capability": "Situational Assessment"
    },
    {
        "ID": "6-509-1281",
        "Type": "Position Qualification",
        "name": "Intelligence Analyst",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "Intelligence and Information Sharing"
    },
    {
        "ID": "PTB-1098",
        "Type": "Position Task Book",
        "name": "Intelligence and Investigations Section Chief (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1091",
        "Type": "Position Task Book",
        "name": "Intelligence and Investigations Section Chief (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1037",
        "Type": "Position Task Book",
        "name": "Intelligence and Investigations Section Chief (Type 3)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1036",
        "Type": "Position Task Book",
        "name": "Intelligence Group Supervisor",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-509-1433",
        "Type": "Position Qualification",
        "name": "Intelligence Group Supervisor (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Intelligence and Information Sharing"
    },
    {
        "ID": "2-509-1432",
        "Type": "Position Qualification",
        "name": "Intelligence/Investigations Section Chief (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Intelligence and Information Sharing"
    },
    {
        "ID": "4-508-1284",
        "Type": "Resource Typing Definition",
        "name": "Interagency Buying Team \u2013 Firefighting",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "PTB-1038",
        "Type": "Position Task Book",
        "name": "Investigative Operations Group Supervisor",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-509-1434",
        "Type": "Position Qualification",
        "name": "Investigative Operations Group Supervisor (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Intelligence and Information Sharing"
    },
    {
        "ID": "PTB-1039",
        "Type": "Position Task Book",
        "name": "Investigative Support Group Supervisor",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-509-1435",
        "Type": "Position Qualification",
        "name": "Investigative Support Group Supervisor (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "12-508-1134",
        "Type": "Resource Typing Definition",
        "name": "Isolation and Quarantine",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-508-1241",
        "Type": "Resource Typing Definition",
        "name": "Laboratory Team",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "7-509-1419",
        "Type": "Position Qualification",
        "name": "Laboratory Technician Specialist - Water/Wastewater",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "5-508-1247",
        "Type": "Resource Typing Definition",
        "name": "Land Mobile Radio Support Team",
        "resource_category": "Communications",
        "primary_core_capability": "Operational Communications"
    },
    {
        "ID": "8-509-1191",
        "Type": "Position Qualification",
        "name": "Land Search and Rescue Strike Team/Task Force Leader",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-508-1173",
        "Type": "Resource Typing Definition",
        "name": "Land Search and Rescue Team",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1192",
        "Type": "Position Qualification",
        "name": "Land Search and Rescue Team Leader",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1193",
        "Type": "Position Qualification",
        "name": "Land Search and Rescue Technician",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "6-508-1002",
        "Type": "Resource Typing Definition",
        "name": "Law Enforcement Aviation - Helicopters - Patrol & Surveillance",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "6-508-1003",
        "Type": "Resource Typing Definition",
        "name": "Law Enforcement Observation Aircraft (Fixed-Wing)",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "PTB-1040",
        "Type": "Position Task Book",
        "name": "Liaison Officer",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-509-1033",
        "Type": "Position Qualification",
        "name": "Liaison Officer (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "7-508-1255",
        "Type": "Resource Typing Definition",
        "name": "Locating Team \u2013 Water Sector Infrastructure",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "8-509-1132",
        "Type": "Position Qualification",
        "name": "Logistics Search and/or Rescue Technician",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "2-509-1034",
        "Type": "Position Qualification",
        "name": "Logistics Section Chief (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "PTB-1084",
        "Type": "Position Task Book",
        "name": "Logistics Section Chief (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1082",
        "Type": "Position Task Book",
        "name": "Logistics Section Chief (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1041",
        "Type": "Position Task Book",
        "name": "Logistics Section Chief (Type 3)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "11-508-1252",
        "Type": "Resource Typing Definition",
        "name": "Logistics Staging Unit",
        "resource_category": "Logistics and Transportation",
        "primary_core_capability": "Logistics and Supply Chain Management"
    },
    {
        "ID": "11-508-1253",
        "Type": "Resource Typing Definition",
        "name": "Logistics Support Team",
        "resource_category": "Logistics and Transportation",
        "primary_core_capability": "Logistics and Supply Chain Management"
    },
    {
        "ID": "9-508-1193",
        "Type": "Resource Typing Definition",
        "name": "Long-Term Shelter",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "0-508-1177",
        "Type": "Resource Typing Definition",
        "name": "Maritime Preventive Radiological Nuclear Detection Team",
        "resource_category": "Prevention",
        "primary_core_capability": "Screening, Search, and Detection"
    },
    {
        "ID": "9-509-1330",
        "Type": "Position Qualification",
        "name": "Mass Care Specialist",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "9-509-1219",
        "Type": "Position Qualification",
        "name": "Mass Evacuee Support Registration and Tracking Specialist",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "9-508-1199",
        "Type": "Resource Typing Definition",
        "name": "Mass Evacuee Support Task Force",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "9-509-1229",
        "Type": "Position Qualification",
        "name": "Mass Evacuee Support Task Force Leader",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "9-508-1198",
        "Type": "Resource Typing Definition",
        "name": "Mass Evacuee Support Team",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "9-509-1223",
        "Type": "Position Qualification",
        "name": "Mass Evacuee Support Team Leader",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "PTB-1042",
        "Type": "Position Task Book",
        "name": "Mass Fatality Management Group Supervisor",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "12-509-1318",
        "Type": "Position Qualification",
        "name": "Mass Fatality Management Group Supervisor (NQS)",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Fatality Management Services"
    },
    {
        "ID": "7-509-1461",
        "Type": "Position Qualification",
        "name": "Mechanic",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "12-509-1355",
        "Type": "Position Qualification",
        "name": "Medical Communications/Information Technology Coordinator",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1361",
        "Type": "Position Qualification",
        "name": "Medical Countermeasure Point of Dispensing Intake/Line Flow Manager",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1362",
        "Type": "Position Qualification",
        "name": "Medical Countermeasure Point of Dispensing Logistics Manager",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-508-1239",
        "Type": "Resource Typing Definition",
        "name": "Medical Countermeasure Point of Dispensing Management Team",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1363",
        "Type": "Position Qualification",
        "name": "Medical Countermeasure Point of Dispensing Management Team Leader",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1356",
        "Type": "Position Qualification",
        "name": "Medical Equipment Coordinator",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1371",
        "Type": "Position Qualification",
        "name": "Medical Laboratory Technician",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1372",
        "Type": "Position Qualification",
        "name": "Medical Laboratory Technologist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1357",
        "Type": "Position Qualification",
        "name": "Medical Materials Coordinator",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1241",
        "Type": "Position Qualification",
        "name": "Medical Officer",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1358",
        "Type": "Position Qualification",
        "name": "Medical Records Coordinator",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "8-509-1133",
        "Type": "Position Qualification",
        "name": "Medical Search and/or Rescue Technician",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "12-509-1359",
        "Type": "Position Qualification",
        "name": "Medical Security Coordinator",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1239",
        "Type": "Position Qualification",
        "name": "Medical Systems Assessment Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1360",
        "Type": "Position Qualification",
        "name": "Medical Team Logistics Coordinator",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-509-1009",
        "Type": "Position Qualification",
        "name": "Medical Team or Task Force Leader",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "PTB-1113",
        "Type": "Position Task Book",
        "name": "Medical Unit Leader",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "10-509-1317",
        "Type": "Position Qualification",
        "name": "Medical Unit Leader (NQS)",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "8-509-1243",
        "Type": "Position Qualification",
        "name": "Mine Search and Rescue Strike Team/Task Force Leader",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-508-1169",
        "Type": "Resource Typing Definition",
        "name": "Mine Search and Rescue Team",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1182",
        "Type": "Position Qualification",
        "name": "Mine Search and Rescue Team Leader",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1183",
        "Type": "Position Qualification",
        "name": "Mine Search and Rescue Technician",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "PTB-1044",
        "Type": "Position Task Book",
        "name": "Missing Persons Group Supervisor",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-509-1436",
        "Type": "Position Qualification",
        "name": "Missing Persons Group Supervisor (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "2-508-1053",
        "Type": "Resource Typing Definition",
        "name": "Mobile Communications Center (Also referred to as \"Mobile EOC\")",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Communications"
    },
    {
        "ID": "4-508-1128",
        "Type": "Resource Typing Definition",
        "name": "Mobile Communications Unit (Law/Fire)",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Operational Communications"
    },
    {
        "ID": "9-508-1188",
        "Type": "Resource Typing Definition",
        "name": "Mobile Distribution Team",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "6-508-1240",
        "Type": "Resource Typing Definition",
        "name": "Mobile Field Force",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "6-509-1364",
        "Type": "Position Qualification",
        "name": "Mobile Field Force Officer",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "6-509-1365",
        "Type": "Position Qualification",
        "name": "Mobile Field Force Supervisor",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "6-509-1366",
        "Type": "Position Qualification",
        "name": "Mobile Field Force Team Leader",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "9-508-1271",
        "Type": "Resource Typing Definition",
        "name": "Mobile Kitchen Unit",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "8-509-1194",
        "Type": "Position Qualification",
        "name": "Mountain Search and Rescue Strike Team/Task Force Leader",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-508-1171",
        "Type": "Resource Typing Definition",
        "name": "Mountain Search and Rescue Team",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1195",
        "Type": "Position Qualification",
        "name": "Mountain Search and Rescue Team Leader",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1196",
        "Type": "Position Qualification",
        "name": "Mountain Search and Rescue Technician",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "3-508-1033",
        "Type": "Resource Typing Definition",
        "name": "Multi-Patient Medical Transport Vehicle",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "2-509-1035",
        "Type": "Position Qualification",
        "name": "Operations Section Chief (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "PTB-1093",
        "Type": "Position Task Book",
        "name": "Operations Section Chief (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1092",
        "Type": "Position Task Book",
        "name": "Operations Section Chief (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1045",
        "Type": "Position Task Book",
        "name": "Operations Section Chief (Type 3)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "7-508-1202",
        "Type": "Resource Typing Definition",
        "name": "Operations Team \u2013 Wastewater Treatment Facility",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1256",
        "Type": "Resource Typing Definition",
        "name": "Operations Team \u2013 Water Treatment Facility",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "11-509-1405",
        "Type": "Position Qualification",
        "name": "Ordering Team Leader",
        "resource_category": "Logistics and Transportation",
        "primary_core_capability": "Logistics and Supply Chain Management"
    },
    {
        "ID": "12-508-1142",
        "Type": "Resource Typing Definition",
        "name": "Palliative Care Team",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "3-509-1015",
        "Type": "Position Qualification",
        "name": "Paramedic",
        "resource_category": "Emergency Medical Services",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1062",
        "Type": "Position Qualification",
        "name": "Patient Care Technician",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "6-508-1034",
        "Type": "Resource Typing Definition",
        "name": "Patrol Team",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "6-509-1368",
        "Type": "Position Qualification",
        "name": "Patrol Team Leader",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "6-509-1369",
        "Type": "Position Qualification",
        "name": "Patrol Team Officer",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "6-509-1370",
        "Type": "Position Qualification",
        "name": "Patrol Team Supervisor",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "9-508-1189",
        "Type": "Resource Typing Definition",
        "name": "Pedestrian Point of Distribution Team",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "0-508-1180",
        "Type": "Resource Typing Definition",
        "name": "Personal Radiation Detector",
        "resource_category": "Prevention",
        "primary_core_capability": "Screening, Search, and Detection"
    },
    {
        "ID": "12-509-1063",
        "Type": "Position Qualification",
        "name": "Pharmacist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-508-1242",
        "Type": "Resource Typing Definition",
        "name": "Pharmacy Team",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1064",
        "Type": "Position Qualification",
        "name": "Pharmacy Technician",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1065",
        "Type": "Position Qualification",
        "name": "Phlebotomist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "7-509-1453",
        "Type": "Position Qualification",
        "name": "Pipeline Inspector",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "2-509-1036",
        "Type": "Position Qualification",
        "name": "Planning Section Chief (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "PTB-1099",
        "Type": "Position Task Book",
        "name": "Planning Section Chief (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1094",
        "Type": "Position Task Book",
        "name": "Planning Section Chief (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1046",
        "Type": "Position Task Book",
        "name": "Planning Section Chief (Type 3)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "4-509-1484",
        "Type": "Position Qualification",
        "name": "Plans Examiner I/II",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "7-508-1257",
        "Type": "Resource Typing Definition",
        "name": "Plant Utility Control Systems Team \u2013 Water Sector Infrastructure",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "4-508-1285",
        "Type": "Resource Typing Definition",
        "name": "Portable Pump",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "10-509-1447",
        "Type": "Position Qualification",
        "name": "Post-Disaster Building Safety Evaluation Strike Team Leader",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "10-509-1445",
        "Type": "Position Qualification",
        "name": "Post-Disaster Building Safety Evaluation Strike Team Technical Supervisor",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "10-508-1261",
        "Type": "Resource Typing Definition",
        "name": "Post-Disaster Building Safety Evaluation Team",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "10-509-1448",
        "Type": "Position Qualification",
        "name": "Post-Disaster Building Safety Evaluator",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "10-509-1446",
        "Type": "Position Qualification",
        "name": "Post-Disaster Complex Architectural System Condition Evaluator",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "10-509-1449",
        "Type": "Position Qualification",
        "name": "Post-Disaster Complex Structural Condition Evaluator",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "0-509-1210",
        "Type": "Position Qualification",
        "name": "Preventive Radiological Nuclear Detection Screener",
        "resource_category": "Prevention",
        "primary_core_capability": "Screening, Search, and Detection"
    },
    {
        "ID": "0-508-1178",
        "Type": "Resource Typing Definition",
        "name": "Preventive Radiological Nuclear Detection Team",
        "resource_category": "Prevention",
        "primary_core_capability": "Screening, Search, and Detection"
    },
    {
        "ID": "0-509-1209",
        "Type": "Position Qualification",
        "name": "Preventive Radiological Nuclear Detection Team Leader",
        "resource_category": "Prevention",
        "primary_core_capability": "Screening, Search, and Detection"
    },
    {
        "ID": "0-509-1208",
        "Type": "Position Qualification",
        "name": "Preventive Radiological Nuclear Detection Team Operator",
        "resource_category": "Prevention",
        "primary_core_capability": "Screening, Search, and Detection"
    },
    {
        "ID": "2-509-1437",
        "Type": "Position Qualification",
        "name": "Procurement Unit Leader (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "PTB-1047",
        "Type": "Position Task Book",
        "name": "Procurement Unit Leader (Single Type)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1115",
        "Type": "Position Task Book",
        "name": "Public Assistance Program Delivery Manager",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "16-509-1474",
        "Type": "Position Qualification",
        "name": "Public Assistance Program Delivery Manager",
        "resource_category": "Recovery",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "16-509-1473",
        "Type": "Position Qualification",
        "name": "Public Assistance Site Inspector",
        "resource_category": "Recovery",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "PTB-1114",
        "Type": "Position Task Book",
        "name": "Public Assistance Site Inspector",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "12-509-1068",
        "Type": "Position Qualification",
        "name": "Public Health and Medical Support Team Leader in a Shelter",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-508-1144",
        "Type": "Resource Typing Definition",
        "name": "Public Health and Medical Systems Assessment Team",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1040",
        "Type": "Position Qualification",
        "name": "Public Health and Medical Systems Assessment Team Leader",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-508-1143",
        "Type": "Resource Typing Definition",
        "name": "Public Health and Medical Team in a Shelter",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1240",
        "Type": "Position Qualification",
        "name": "Public Health Data Entry Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1238",
        "Type": "Position Qualification",
        "name": "Public Health Systems Assessment Specialist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "6-508-1005",
        "Type": "Resource Typing Definition",
        "name": "Public Safety Dive Team",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "4-509-1027",
        "Type": "Position Qualification",
        "name": "Public Safety Telecommunicator I/II",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Operational Communications"
    },
    {
        "ID": "7-509-1103",
        "Type": "Position Qualification",
        "name": "Public Works Director",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-509-1462",
        "Type": "Position Qualification",
        "name": "Public Works Safety Specialist",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-509-1463",
        "Type": "Position Qualification",
        "name": "Public Works Supervisor",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1244",
        "Type": "Resource Typing Definition",
        "name": "Public Works Support Team",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-509-1106",
        "Type": "Position Qualification",
        "name": "Public Works Systems Manager",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-509-1235",
        "Type": "Position Qualification",
        "name": "Public Works Systems Technician",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-509-1377",
        "Type": "Position Qualification",
        "name": "Public Works Team Leader",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "4-508-1286",
        "Type": "Resource Typing Definition",
        "name": "Quint Fire Apparatus",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "8-508-1019",
        "Type": "Resource Typing Definition",
        "name": "Radio Direction Finding Team",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "0-508-1181",
        "Type": "Resource Typing Definition",
        "name": "Radio-Isotope Identification Device",
        "resource_category": "Prevention",
        "primary_core_capability": "Screening, Search, and Detection"
    },
    {
        "ID": "PTB-1116",
        "Type": "Position Task Book",
        "name": "Radiological Emergency Preparedness Program (REPP) Exercise Evaluator",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "4-509-1475",
        "Type": "Position Qualification",
        "name": "Radiological Emergency Preparedness Program Exercise Evaluator",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Situational Assessment"
    },
    {
        "ID": "17-509-1415",
        "Type": "Position Qualification",
        "name": "Radiological Operations Support Specialist",
        "resource_category": "Screening, Search, and Detection",
        "primary_core_capability": "Situational Assessment"
    },
    {
        "ID": "PTB-1080",
        "Type": "Position Task Book",
        "name": "Radiological Operations Support Specialist (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1079",
        "Type": "Position Task Book",
        "name": "Radiological Operations Support Specialist (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1071",
        "Type": "Position Task Book",
        "name": "Radiological Operations Support Specialist (Type 3)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "12-508-1243",
        "Type": "Resource Typing Definition",
        "name": "Radiological Services Team",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1373",
        "Type": "Position Qualification",
        "name": "Radiologist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1072",
        "Type": "Position Qualification",
        "name": "Radiology Technician",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "21-508-1260",
        "Type": "Resource Typing Definition",
        "name": "Rapid Needs Assessment Team",
        "resource_category": "Damage Assessment",
        "primary_core_capability": "Situational Assessment"
    },
    {
        "ID": "PTB-1063",
        "Type": "Position Task Book",
        "name": "Rapid Needs Assessment Team Leader",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-509-1438",
        "Type": "Position Qualification",
        "name": "Rapid Needs Assessment Team Leader (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Situational Assessment"
    },
    {
        "ID": "PTB-1064",
        "Type": "Position Task Book",
        "name": "Rapid Needs Assessment Technical Specialist",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-509-1439",
        "Type": "Position Qualification",
        "name": "Rapid Needs Assessment Technical Specialist (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Situational Assessment"
    },
    {
        "ID": "12-509-1073",
        "Type": "Position Qualification",
        "name": "Receiving, Staging, and Storage Distribution Team Leader",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1074",
        "Type": "Position Qualification",
        "name": "Receiving, Staging, and Storage Finance/Administration Team Leader",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1075",
        "Type": "Position Qualification",
        "name": "Receiving, Staging, and Storage Logistics Team Leader",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1076",
        "Type": "Position Qualification",
        "name": "Receiving, Staging, and Storage Operations Team Leader",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1077",
        "Type": "Position Qualification",
        "name": "Receiving, Staging, and Storage Tactical Communications Team Leader",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-508-1137",
        "Type": "Resource Typing Definition",
        "name": "Receiving, Staging, and Storage Task Force",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1078",
        "Type": "Position Qualification",
        "name": "Receiving, Staging, and Storage Task Force Leader",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "12-509-1079",
        "Type": "Position Qualification",
        "name": "Registered Nurse",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "2-509-1381",
        "Type": "Position Qualification",
        "name": "Remote Pilot-In-Command",
        "resource_category": "Incident Management",
        "primary_core_capability": "Situational Assessment"
    },
    {
        "ID": "7-508-1258",
        "Type": "Resource Typing Definition",
        "name": "Repair and Start-Up Team \u2013 Wastewater Treatment Facility",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1154",
        "Type": "Resource Typing Definition",
        "name": "Repair and Start-Up Team \u2013 Water Treatment Facility",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1059",
        "Type": "Resource Typing Definition",
        "name": "Repair and Start-Up Team Lift and Pump Stations \u2013 Wastewater Facility",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1145",
        "Type": "Resource Typing Definition",
        "name": "Repair and Start-Up Team Water Pump Facilities \u2013 Water Production",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1201",
        "Type": "Resource Typing Definition",
        "name": "Repair Team \u2013 Water Distribution System",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1146",
        "Type": "Resource Typing Definition",
        "name": "Repair Team Sewer Mains \u2013 Wastewater",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "2-509-1440",
        "Type": "Position Qualification",
        "name": "Resources Unit Leader (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Communications"
    },
    {
        "ID": "PTB-1049",
        "Type": "Position Task Book",
        "name": "Resources Unit Leader (Single Type)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "12-509-1080",
        "Type": "Position Qualification",
        "name": "Respiratory Therapist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "2-509-1441",
        "Type": "Position Qualification",
        "name": "Safety Officer (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "PTB-1090",
        "Type": "Position Task Book",
        "name": "Safety Officer (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1086",
        "Type": "Position Task Book",
        "name": "Safety Officer (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1050",
        "Type": "Position Task Book",
        "name": "Safety Officer (Type 3)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1051",
        "Type": "Position Task Book",
        "name": "Science and Technology Advisor",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "10-509-1309",
        "Type": "Position Qualification",
        "name": "Science and Technology Advisor (NQS)",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "11-509-1247",
        "Type": "Position Qualification",
        "name": "Service Branch Director (NQS)",
        "resource_category": "Logistics and Transportation",
        "primary_core_capability": "Logistics and Supply Chain Management"
    },
    {
        "ID": "PTB-1052",
        "Type": "Position Task Book",
        "name": "Service Branch Director (Single Type)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "7-508-1208",
        "Type": "Resource Typing Definition",
        "name": "Sewer System Cleaning \u2013 Wastewater",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1207",
        "Type": "Resource Typing Definition",
        "name": "Sewer System Closed Circuit Television Team \u2013 Wastewater",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "9-509-1308",
        "Type": "Position Qualification",
        "name": "Shelter Facilities Support Team Leader (NQS)",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "PTB-1097",
        "Type": "Position Task Book",
        "name": "Shelter Facilities Support Team Leader (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1096",
        "Type": "Position Task Book",
        "name": "Shelter Facilities Support Team Leader (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1095",
        "Type": "Position Task Book",
        "name": "Shelter Facilities Support Team Leader (Type 3)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1053",
        "Type": "Position Task Book",
        "name": "Shelter Facilities Support Team Leader (Type 4)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "9-508-1196",
        "Type": "Resource Typing Definition",
        "name": "Shelter Facility Selection Team",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "9-508-1155",
        "Type": "Resource Typing Definition",
        "name": "Shelter Management Team",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "PTB-1077",
        "Type": "Position Task Book",
        "name": "Shelter Manager (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "9-509-1085",
        "Type": "Position Qualification",
        "name": "Shelter Manager (NQS)",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "PTB-1054",
        "Type": "Position Task Book",
        "name": "Shelter Manager (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1078",
        "Type": "Position Task Book",
        "name": "Shelter Manager (Type 3)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1073",
        "Type": "Position Task Book",
        "name": "Shelter Resident Services Team Leader (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "9-509-1307",
        "Type": "Position Qualification",
        "name": "Shelter Resident Services Team Leader (NQS)",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "PTB-1055",
        "Type": "Position Task Book",
        "name": "Shelter Resident Services Team Leader (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1074",
        "Type": "Position Task Book",
        "name": "Shelter Resident Services Team Leader (Type 3)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "9-508-1197",
        "Type": "Resource Typing Definition",
        "name": "Short-Term Shelter",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "2-509-1442",
        "Type": "Position Qualification",
        "name": "Situation Unit Leader (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Situational Assessment"
    },
    {
        "ID": "PTB-1056",
        "Type": "Position Task Book",
        "name": "Situation Unit Leader (Single Type)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "12-509-1081",
        "Type": "Position Qualification",
        "name": "Social Worker",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "6-508-1245",
        "Type": "Resource Typing Definition",
        "name": "Special Weapons and Tactics Team",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "6-509-1380",
        "Type": "Position Qualification",
        "name": "Special Weapons and Tactics Team Commander",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "6-509-1379",
        "Type": "Position Qualification",
        "name": "Special Weapons and Tactics Team Leader",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "6-509-1378",
        "Type": "Position Qualification",
        "name": "Special Weapons and Tactics Team Officer",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "12-508-1141",
        "Type": "Resource Typing Definition",
        "name": "Specialty Services Team",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "10-509-1305",
        "Type": "Position Qualification",
        "name": "State Coordinating Officer (NQS)",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "PTB-1057",
        "Type": "Position Task Book",
        "name": "State Coordinating Officer (Type 1)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1070",
        "Type": "Position Task Book",
        "name": "State Coordinating Officer (Type 2)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "9-509-1083",
        "Type": "Position Qualification",
        "name": "State Mass Care Coordinator",
        "resource_category": "Mass Care Services",
        "primary_core_capability": "Mass Care Services"
    },
    {
        "ID": "8-508-1264",
        "Type": "Resource Typing Definition",
        "name": "Stillwater/Flood Search and Rescue Team",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1459",
        "Type": "Position Qualification",
        "name": "Stillwater/Flood Search and Rescue Team Leader",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1458",
        "Type": "Position Qualification",
        "name": "Stillwater/Flood Search and Rescue Technician",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1166",
        "Type": "Position Qualification",
        "name": "Structural Collapse Rescue Technician",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1164",
        "Type": "Position Qualification",
        "name": "Structural Collapse - Search Technician",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-508-1159",
        "Type": "Resource Typing Definition",
        "name": "Structural Collapse Rescue Team",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1167",
        "Type": "Position Qualification",
        "name": "Structural Collapse Rescue Team Leader",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-508-1158",
        "Type": "Resource Typing Definition",
        "name": "Structural Collapse Search Team",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1165",
        "Type": "Position Qualification",
        "name": "Structural Collapse Search Team Leader",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "7-509-1105",
        "Type": "Position Qualification",
        "name": "Structural Engineer",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "13-509-1248",
        "Type": "Position Qualification",
        "name": "Supervisory Control and Data Acquisition Controller Specialist",
        "resource_category": "Cybersecurity",
        "primary_core_capability": "Cybersecurity"
    },
    {
        "ID": "13-509-1249",
        "Type": "Position Qualification",
        "name": "Supervisory Control and Data Acquisition Server Specialist",
        "resource_category": "Cybersecurity",
        "primary_core_capability": "Cybersecurity"
    },
    {
        "ID": "11-509-1304",
        "Type": "Position Qualification",
        "name": "Supply Unit Leader (NQS)",
        "resource_category": "Logistics and Transportation",
        "primary_core_capability": "Logistics and Supply Chain Management"
    },
    {
        "ID": "PTB-1058",
        "Type": "Position Task Book",
        "name": "Supply Unit Leader (Single Type)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "PTB-1059",
        "Type": "Position Task Book",
        "name": "Support Branch Director",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "11-509-1443",
        "Type": "Position Qualification",
        "name": "Support Branch Director (NQS)",
        "resource_category": "Logistics and Transportation",
        "primary_core_capability": "Logistics and Supply Chain Management"
    },
    {
        "ID": "4-508-1287",
        "Type": "Resource Typing Definition",
        "name": "Support Water Tender \u2013 Firefighting (Tanker)",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "12-509-1082",
        "Type": "Position Qualification",
        "name": "Surgical Technician/Technologist",
        "resource_category": "Medical and Public Health",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "8-508-1020",
        "Type": "Resource Typing Definition",
        "name": "Swiftwater/Flood Search and Rescue Team",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1184",
        "Type": "Position Qualification",
        "name": "Swiftwater/Flood Search and Rescue Team Leader",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1236",
        "Type": "Position Qualification",
        "name": "Swiftwater/Flood Search and Rescue Technician",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1186",
        "Type": "Position Qualification",
        "name": "Swiftwater/Flood Search and Rescue Technician - Boat Bowman",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1187",
        "Type": "Position Qualification",
        "name": "Swiftwater/Flood Search and Rescue Technician - Boat Operator",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "7-508-1205",
        "Type": "Resource Typing Definition",
        "name": "System Flushing and Flow Testing Team \u2013 Water Distribution",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "4-508-1288",
        "Type": "Resource Typing Definition",
        "name": "Tactical Water Tender \u2013 Firefighting (Tanker)",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "2-509-1382",
        "Type": "Position Qualification",
        "name": "Technical Specialist - Unmanned Aircraft System",
        "resource_category": "Incident Management",
        "primary_core_capability": "Situational Assessment"
    },
    {
        "ID": "6-509-1376",
        "Type": "Position Qualification",
        "name": "Tender",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "2-509-1444",
        "Type": "Position Qualification",
        "name": "Time Unit Leader (NQS)",
        "resource_category": "Incident Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "PTB-1060",
        "Type": "Position Task Book",
        "name": "Time Unit Leader (Single Type)",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "2-509-1352",
        "Type": "Position Qualification",
        "name": "Traffic Control Specialist",
        "resource_category": "Incident Management",
        "primary_core_capability": "Critical Transportation"
    },
    {
        "ID": "2-509-1353",
        "Type": "Position Qualification",
        "name": "Transportation Specialist",
        "resource_category": "Incident Management",
        "primary_core_capability": "Critical Transportation"
    },
    {
        "ID": "PTB-1061",
        "Type": "Position Task Book",
        "name": "Tribal/State Disaster Recovery Coordinator",
        "resource_category": "N/A",
        "primary_core_capability": "N/A"
    },
    {
        "ID": "10-509-1412",
        "Type": "Position Qualification",
        "name": "Tribal/State Disaster Recovery Coordinator (NQS)",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "2-508-1246",
        "Type": "Resource Typing Definition",
        "name": "Unmanned Aircraft System Team",
        "resource_category": "Incident Management",
        "primary_core_capability": "Situational Assessment"
    },
    {
        "ID": "8-509-1168",
        "Type": "Position Qualification",
        "name": "Urban Search and Rescue (US&R) Task Force Leader",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-508-1266",
        "Type": "Resource Typing Definition",
        "name": "Urban Search and Rescue Incident Support Team",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1471",
        "Type": "Position Qualification",
        "name": "Urban Search and Rescue Logistics Specialist",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-509-1472",
        "Type": "Position Qualification",
        "name": "Urban Search and Rescue Medical Specialist",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-508-1262",
        "Type": "Resource Typing Definition",
        "name": "Urban Search and Rescue Task Force",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "8-508-1021",
        "Type": "Resource Typing Definition",
        "name": "US&R Incident Support Team",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "7-509-1420",
        "Type": "Position Qualification",
        "name": "Utility Worker Specialist - Water Sector Infrastructure",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "0-508-1182",
        "Type": "Resource Typing Definition",
        "name": "Vehicle-Mounted Radiological Nuclear Detection System",
        "resource_category": "Prevention",
        "primary_core_capability": "Screening, Search, and Detection"
    },
    {
        "ID": "1-509-1116",
        "Type": "Position Qualification",
        "name": "Veterinarian",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "1-509-1341",
        "Type": "Position Qualification",
        "name": "Veterinary Assistant",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "1-508-1230",
        "Type": "Resource Typing Definition",
        "name": "Veterinary Medical Team",
        "resource_category": "Animal Emergency Response",
        "primary_core_capability": "Public Health, Healthcare, and Emergency Medical Services"
    },
    {
        "ID": "5-508-1250",
        "Type": "Resource Typing Definition",
        "name": "Virtual Operations Support Team",
        "resource_category": "Communications",
        "primary_core_capability": "Operational Communications"
    },
    {
        "ID": "5-509-1401",
        "Type": "Position Qualification",
        "name": "Virtual Operations Support Team Administrator",
        "resource_category": "Communications",
        "primary_core_capability": "Operational Communications"
    },
    {
        "ID": "5-509-1402",
        "Type": "Position Qualification",
        "name": "Virtual Operations Support Team Leader",
        "resource_category": "Communications",
        "primary_core_capability": "Operational Communications"
    },
    {
        "ID": "5-509-1403",
        "Type": "Position Qualification",
        "name": "Virtual Operations Support Team Member",
        "resource_category": "Communications",
        "primary_core_capability": "Operational Communications"
    },
    {
        "ID": "10-509-1404",
        "Type": "Position Qualification",
        "name": "Voluntary Agency Liaison",
        "resource_category": "Emergency Management",
        "primary_core_capability": "Operational Coordination"
    },
    {
        "ID": "7-509-1421",
        "Type": "Position Qualification",
        "name": "Wastewater Operations Team Leader",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1206",
        "Type": "Resource Typing Definition",
        "name": "Water Main Leak Locating Team \u2013 Water Distribution",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-509-1422",
        "Type": "Position Qualification",
        "name": "Water Operations Team Leader",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1100",
        "Type": "Resource Typing Definition",
        "name": "Water Pump, Dewatering",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1101",
        "Type": "Resource Typing Definition",
        "name": "Water Pump, Drinking Water Supply \u2013 Untreated Source",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1103",
        "Type": "Resource Typing Definition",
        "name": "Water Pump, Wastewater",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1102",
        "Type": "Resource Typing Definition",
        "name": "Water Pump, Water Distribution",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "7-508-1259",
        "Type": "Resource Typing Definition",
        "name": "Water Valve Maintenance (Truck/Trailer) \u2013 Water Distribution",
        "resource_category": "Public Works",
        "primary_core_capability": "Infrastructure Systems"
    },
    {
        "ID": "6-508-1216",
        "Type": "Resource Typing Definition",
        "name": "Waterborne Response Team",
        "resource_category": "Law Enforcement Operations",
        "primary_core_capability": "On-scene Security, Protection and Law Enforcement"
    },
    {
        "ID": "8-508-1215",
        "Type": "Resource Typing Definition",
        "name": "Waterborne Search and Rescue Team",
        "resource_category": "Search and Rescue",
        "primary_core_capability": "Mass Search and Rescue Operations"
    },
    {
        "ID": "4-509-1485",
        "Type": "Position Qualification",
        "name": "Wildland Firefighter",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    },
    {
        "ID": "4-509-1486",
        "Type": "Position Qualification",
        "name": "Wildland/Urban Interface Protection Specialist",
        "resource_category": "Fire/Hazardous Materials",
        "primary_core_capability": "Fire Management and Suppression"
    }
]