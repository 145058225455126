export const FETCH_ACTIVITIES_REQUEST = 'FETCH_ACTIVITIES_REQUEST';
export const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
export const FETCH_ACTIVITIES_ERROR = 'FETCH_ACTIVITIES_ERROR';
export const FETCH_ACTIVITY_RECORDS_REQUEST = 'FETCH_ACTIVITY_RECORDS_REQUEST';
export const FETCH_ACTIVITY_RECORDS_SUCCESS = 'FETCH_ACTIVITY_RECORDS_SUCCESS';
export const FETCH_ACTIVITY_RECORDS_ERROR = 'FETCH_ACTIVITY_RECORDS_ERROR';
export const CREATE_ACTIVITY_REQUEST = 'CREATE_ACTIVITY_REQUEST';
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
export const CREATE_ACTIVITY_ERROR = 'CREATE_ACTIVITY_ERROR';
export const EDIT_ACTIVITY_REQUEST = 'EDIT_ACTIVITY_REQUEST';
export const EDIT_ACTIVITY_SUCCESS = 'EDIT_ACTIVITY_SUCCESS';
export const EDIT_ACTIVITY_ERROR = 'EDIT_ACTIVITY_ERROR';
export const DELETE_ACTIVITY_REQUEST = 'DELETE_ACTIVITY_REQUEST';
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS';
export const DELETE_ACTIVITY_ERROR = 'DELETE_ACTIVITY_ERROR';
export const UPLOAD_ACTIVITY_RECORDS_REQUEST = 'UPLOAD_ACTIVITY_RECORDS_REQUEST';
export const UPLOAD_ACTIVITY_RECORDS_SUCCESS = 'UPLOAD_ACTIVITY_RECORDS_SUCCESS';
export const UPLOAD_ACTIVITY_RECORDS_ERROR = 'UPLOAD_ACTIVITY_RECORDS_ERROR';
export const EDIT_ACTIVITY_RECORD_REQUEST = 'EDIT_ACTIVITY_RECORD_REQUEST';
export const EDIT_ACTIVITY_RECORD_SUCCESS = 'EDIT_ACTIVITY_RECORD_SUCCESS';
export const EDIT_ACTIVITY_RECORD_ERROR = 'EDIT_ACTIVITY_RECORD_ERROR';
export const DELETE_ACTIVITY_RECORD_REQUEST = 'DELETE_ACTIVITY_RECORD_REQUEST';
export const DELETE_ACTIVITY_RECORD_SUCCESS = 'DELETE_ACTIVITY_RECORD_SUCCESS';
export const DELETE_ACTIVITY_RECORD_ERROR = 'DELETE_ACTIVITY_RECORD_ERROR';
export const ASSIGN_ACTIVITY_RECORD_REQUEST = 'ASSIGN_ACTIVITY_RECORD_REQUEST';
export const ASSIGN_ACTIVITY_RECORD_SUCCESS = 'ASSIGN_ACTIVITY_RECORD_SUCCESS';
export const ASSIGN_ACTIVITY_RECORD_ERROR = 'ASSIGN_ACTIVITY_RECORD_ERROR';
export const FETCH_ASSIGNMENTS_FOR_ACTIVITY_REQUEST = 'FETCH_ASSIGNMENTS_FOR_ACTIVITY_REQUEST';
export const FETCH_ASSIGNMENTS_FOR_ACTIVITY_SUCCESS = 'FETCH_ASSIGNMENTS_FOR_ACTIVITY_SUCCESS';
export const FETCH_ASSIGNMENTS_FOR_ACTIVITY_ERROR = 'FETCH_ASSIGNMENTS_FOR_ACTIVITY_ERROR';
export const NOTIFY_ASSIGNEE_REQUEST = 'NOTIFY_ASSIGNEE_REQUEST';
export const NOTIFY_ASSIGNEE_SUCCESS = 'NOTIFY_ASSIGNEE_SUCCESS';
export const NOTIFY_ASSIGNEE_ERROR = 'NOTIFY_ASSIGNEE_ERROR';
export const RESPOND_TO_TASK_ASSIGNMENT_REQUEST = 'RESPOND_TO_TASK_ASSIGNMENT_REQUEST';
export const RESPOND_TO_TASK_ASSIGNMENT_SUCCESS = 'RESPOND_TO_TASK_ASSIGNMENT_SUCCESS';
export const RESPOND_TO_TASK_ASSIGNMENT_ERROR = 'RESPOND_TO_TASK_ASSIGNMENT_ERROR';
export const COMPLETE_TASK_ASSIGNMENT_REQUEST = 'COMPLETE_TASK_ASSIGNMENT_REQUEST';
export const COMPLETE_TASK_ASSIGNMENT_SUCCESS = 'COMPLETE_TASK_ASSIGNMENT_SUCCESS';
export const COMPLETE_TASK_ASSIGNMENT_ERROR = 'COMPLETE_TASK_ASSIGNMENT_ERROR';