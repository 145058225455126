import React, { useState } from 'react';
import { ResourceSet, useResourceSets } from './hooks/resourceSetHooks';
import { ExpandRowProps } from 'react-bootstrap-table-next';
import ExpandDownArrow from '../DesignSystems/buttons/ExpandDownArrow';
import ExpandRightArrow from '../DesignSystems/buttons/ExpandRightArrow';
import ResourceSetCard from './cards/ResourceSetCard';
import PageTitle from '../headers/PageTitle';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import StylishNewTable from '../DesignSystems/New/StylishNewTable';
import ResourceSetEditDrawer from './ResourceSetEditDrawer';
import ResourceSetAddDrawer from './ResourceSetAddDrawer';

const IAPResources: React.FC = () => {
  const [modal, setModal] = useState<'new' | 'edit' | undefined>();
  const [selectedResource, setSelectedResource] = useState<
    ResourceSet | undefined
  >();

  const resourceSets = useResourceSets();

  const editRow = (row: ResourceSet) => {
    setSelectedResource(row);
    setModal('edit');
  };

  const columns = [
    { dataField: 'name', text: 'Name', sort: true, attrs: { title: 'Name' } },
    { dataField: 'kind', text: 'Kind', sort: true, attrs: { title: 'Kind' } },
    { dataField: 'type', text: 'Type', sort: true, attrs: { title: 'Type' } },
    {
      dataField: 'quantity',
      text: 'Quantity',
      sort: true,
      attrs: { title: 'Quantity' },
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      attrs: { title: 'Status' },
    },
  ];

  const expandRow: ExpandRowProps<ResourceSet> = {
    renderer: (row: ResourceSet) => {
      return <ResourceSetCard row={row} selectCallback={editRow} />;
    },
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? <ExpandDownArrow /> : <ExpandRightArrow />,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <span className="sr-only">Details</span>
            <ExpandDownArrow />
          </>
        );
      }
      return (
        <>
          <span className="sr-only">Details</span>
          <ExpandRightArrow />
        </>
      );
    },
  };

  return (
    <div className="maincontent">
      <PageTitle title="Resource Sets">
        <StylishNewButton
          className="button--primary"
          onClick={() => {
            setModal('new');
          }}
        >
          New Resource Set
        </StylishNewButton>
      </PageTitle>
      {resourceSets.isSuccess && (
        <StylishNewTable
          keyField={'id'}
          columns={columns}
          rows={resourceSets.data}
          expandRow={expandRow}
        />
      )}
      {modal === 'new' ? (
        <ResourceSetAddDrawer toggle={() => setModal(undefined)} />
      ) : modal === 'edit' && selectedResource ? (
        <ResourceSetEditDrawer
          toggle={() => setModal(undefined)}
          selectedEntity={selectedResource}
        />
      ) : null}
    </div>
  );
};
export default IAPResources;
