export const FETCH_AI2FP_DATA_REQUEST = 'FETCH_AI2FP_DATA_REQUEST';
export const FETCH_AI2FP_DATA_SUCCESS = 'FETCH_AI2FP_DATA_SUCCESS';
export const FETCH_AI2FP_DATA_ERROR = 'FETCH_AI2FP_DATA_ERROR';
export const FETCH_CORE_COMPONENTS_REQUEST = 'FETCH_CORE_COMPONENTS_REQUEST';
export const FETCH_CORE_COMPONENTS_SUCCESS = 'FETCH_CORE_COMPONENTS_SUCCESS';
export const FETCH_CORE_COMPONENTS_ERROR = 'FETCH_CORE_COMPONENTS_ERROR';
export const UPSERT_CORE_COMPONENT_REQUEST = 'UPSERT_CORE_COMPONENT_REQUEST';
export const UPSERT_CORE_COMPONENT_SUCCESS = 'UPSERT_CORE_COMPONENT_SUCCESS';
export const UPSERT_CORE_COMPONENT_ERROR = 'UPSERT_CORE_COMPONENT_ERROR';
export const LINK_CORE_COMPONENTS_REQUEST = 'LINK_CORE_COMPONENTS_REQUEST';
export const LINK_CORE_COMPONENTS_SUCCESS = 'LINK_CORE_COMPONENTS_SUCCESS';
export const LINK_CORE_COMPONENTS_ERROR = 'LINK_CORE_COMPONENTS_ERROR';
export const UNLINK_CORE_COMPONENTS_REQUEST = 'UNLINK_CORE_COMPONENTS_REQUEST';
export const UNLINK_CORE_COMPONENTS_SUCCESS = 'UNLINK_CORE_COMPONENTS_SUCCESS';
export const UNLINK_CORE_COMPONENTS_ERROR = 'UNLINK_CORE_COMPONENTS_ERROR';
export const DELETE_CORE_COMPONENT_REQUEST = 'DELETE_CORE_COMPONENT_REQUEST';
export const DELETE_CORE_COMPONENT_SUCCESS = 'DELETE_CORE_COMPONENT_SUCCESS';
export const DELETE_CORE_COMPONENT_ERROR = 'DELETE_CORE_COMPONENT_ERROR';
export const UPDATE_CURRENT_AOR_REQUEST = 'UPDATE_CURRENT_AOR_REQUEST'
export const UPDATE_CURRENT_AOR_SUCCESS = 'UPDATE_CURRENT_AOR_SUCCESS'
export const UPDATE_CURRENT_AOR_ERROR = 'UPDATE_CURRENT_AOR_ERROR'
export const FETCH_THRESHOLDS_FOR_AOR_REQUEST = 'FETCH_THRESHOLDS_FOR_AOR_REQUEST'
export const FETCH_THRESHOLDS_FOR_AOR_SUCCESS = 'FETCH_THRESHOLDS_FOR_AOR_SUCCESS'
export const FETCH_THRESHOLDS_FOR_AOR_ERROR = 'FETCH_THRESHOLDS_FOR_AOR_ERROR'
export const UPSERT_THRESHOLD_REQUEST = 'UPSERT_THRESHOLD_REQUEST'
export const UPSERT_THRESHOLD_SUCCESS = 'UPSERT_THRESHOLD_SUCCESS'
export const UPSERT_THRESHOLD_ERROR = 'UPSERT_THRESHOLD_ERROR'
export const UPDATE_CURRENT_ANOMALY_REQUEST = 'UPDATE_CURRENT_ANOMALY_REQUEST'
export const UPDATE_CURRENT_ANOMALY_SUCCESS = 'UPDATE_CURRENT_ANOMALY_SUCCESS'
export const UPDATE_CURRENT_ANOMALY_ERROR = 'UPDATE_CURRENT_ANOMALY_ERROR'
export const FETCH_ANOMALIES_REQUEST = 'FETCH_ANOMALIES_REQUEST'
export const FETCH_ANOMALIES_SUCCESS = 'FETCH_ANOMALIES_SUCCESS'
export const FETCH_ANOMALIES_ERROR = 'FETCH_ANOMALIES_ERROR'
export const ARCHIVE_ANOMALY_REQUEST = 'ARCHIVE_ANOMALY_REQUEST'
export const ARCHIVE_ANOMALY_SUCCESS = 'ARCHIVE_ANOMALY_SUCCESS'
export const ARCHIVE_ANOMALY_ERROR = 'ARCHIVE_ANOMALY_ERROR'
export const ANOMALY_TO_INCIDENT_REQUEST = 'ANOMALY_TO_INCIDENT_REQUEST'
export const ANOMALY_TO_INCIDENT_SUCCESS = 'ANOMALY_TO_INCIDENT_SUCCESS'
export const ANOMALY_TO_INCIDENT_ERROR = 'ANOMALY_TO_INCIDENT_ERROR'