export const meeting_template = [
  {
    label: 'Unified Command Objectives Meeting',
    value: 'Unified Command Objectives Meeting',
  },
  {
    label: 'Command and General Staff meeting',
    value: 'Command and General Staff meeting',
  },
  { label: 'Tactics Meeting', value: 'Tactics Meeting' },
  { label: 'Planning Meeting', value: 'Planning Meeting' },
  { label: 'Operations Briefing', value: 'Operations Briefing' },
  { label: 'Incident Briefing', value: 'Incident Briefing' },
];
