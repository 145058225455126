import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { useSelector } from 'react-redux';

import { selectUserGuid } from './commonSelectors';

// Slice
const ics_incidentInfoSlice = createSlice({
    name: 'ics_incident_info',
    initialState: {
        ics_incident_info: {},
        status: 'idle',
        error: null,
        map_sketch: null, // is this here or is it on ics_incident_info?
    },
    reducers: {
        fetchIcsIncidentInfoStart: (state) => {
            state.status = 'loading';
        },
        fetchIcsIncidentInfoSuccess: (state, action) => {
            state.ics_incident_info = action.payload;
            state.status = 'succeeded';
        },
        updateIcsIncidentInfoSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        fetchIcsIncidentInfoMapSketchSuccess: (state, action) => {
            state.map_sketch = action.payload;
            state.status = 'succeeded';
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.status = 'failed'
        },
    },
});

export const {
    fetchIcsIncidentInfoStart,
    fetchIcsIncidentInfoSuccess,
    updateIcsIncidentInfoSuccess,
    fetchIcsIncidentInfoMapSketchSuccess,
    setError,
} = ics_incidentInfoSlice.actions;

export default ics_incidentInfoSlice.reducer;


// Thunk to fetch incident info versions
export const fetchIcsIncidentInfo = (incident_id) => async (dispatch, getState) => {
    dispatch(fetchIcsIncidentInfoStart(getState()));
    try {
        const response = await axios.get(`${config.apiGateway.ics_incident_info}/${incident_id}`);
        dispatch(fetchIcsIncidentInfoSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsIncidentInfo = (incidentId, data) => async (dispatch, getState) => {
    const state = getState()
    const user_guid = state?.app?.user?.user_guid
    const formData = new FormData();
    dispatch(fetchIcsIncidentInfoStart(getState()));
    Object.keys(data).forEach(key => {
        if (key === 'map_sketch' && typeof data[key] === 'object' && data[key]) {
            formData.append(key, data[key], data[key]?.name || "map_sketch"); // Append file with its name
        } else {
            formData.append(key, data[key]);
        }
    });

    try {
        const response = await axios.put(`${config.apiGateway.ics_incident_info}/${incidentId}`, formData, {
            headers: {
                'user_guid': user_guid,
                'Content-Type': 'multipart/form-data'
            }
        });
        dispatch(fetchIcsIncidentInfo(incidentId))
        dispatch(updateIcsIncidentInfoSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
}

export const fetchIcsIncidentInfoMapSketch = (id) => async (dispatch, getState) => {
    dispatch(fetchIcsIncidentInfoStart(getState()));
    try {
        const response = await axios.get(`${config.apiGateway.ics_incident_info}/map_sketch/${id}`);
        dispatch(fetchIcsIncidentInfoMapSketchSuccess(response?.data?.map_sketch));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

