import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import { getUserGuid } from 'slices/sliceHelpers';

// Slice
const ics_incidentActionsSlice = createSlice({
    name: 'ics_incident_actions',
    initialState: {
        ics_incident_actions: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        fetchIcsIncidentActionsStart: (state) => {
            state.status = 'loading';
        },
        fetchIcsIncidentActionsSuccess: (state, action) => {
            state.ics_incident_actions = action.payload;
            state.status = 'succeeded';
        },
        createIcsIncidentActionSuccess: (state, action) => {
            state.status='succeeded'
        },
        updateIcsIncidentActionSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const {
    fetchIcsIncidentActionsStart,
    fetchIcsIncidentActionsSuccess,
    createIcsIncidentActionSuccess,
    updateIcsIncidentActionSuccess,
    setError,
} = ics_incidentActionsSlice.actions;

export default ics_incidentActionsSlice.reducer;

// Thunk to fetch incident actions versions
export const fetchIcsIncidentActions = (incident_id) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    dispatch(fetchIcsIncidentActionsStart());
    try {
        const response = await axios.get(`${config.apiGateway.ics_incident_actions}/${incident_id}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsIncidentActionsSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

// Thunk to create a new version of an incident action
export const createIcsIncidentAction = (incidentId, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_incident_actions}/${incidentId}`, { ...data, user_guid });
        dispatch(fetchIcsIncidentActions(incidentId));
        dispatch(createIcsIncidentActionSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

// Thunk to update (insert new version) an incident action
export const updateIcsIncidentAction = (incidentId, id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_incident_actions}/${incidentId}/${id}`, { ...data, user_guid });
        dispatch(fetchIcsIncidentActions(incidentId));
        dispatch(updateIcsIncidentActionSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
