
export const owmLayers = [
	{
		key: "Convective precipitation",
		value: "https://maps.openweathermap.org/maps/2.0/weather/PAC0/{z}/{x}/{y}?opacity=0.3&units=imperial&fill_bound=true&appid=",
		legend: "1:ACAAF7; 10:8D8AF3; 20:706EC2; 40:5658FF; 100:5B5DB1; 200:3E3F85",
		forecast: false,
	},
	{
		key: "Precipitation intensity",
		value: "https://maps.openweathermap.org/maps/2.0/weather/PR0/{z}/{x}/{y}?opacity=0.3&units=imperial&fill_bound=true&appid=",
		legend: "0.000005:FEF9CA; 0.000009:B9F7A8; 0.000014:93F57D; 0.000023:78F554; 0.000046:50B033; 0.000092:387F22; 0.000231:204E11; 0.000463:F2A33A; 0.000694:E96F2D; 0.000926:EB4726; 0.001388:B02318; 0.002315:971D13; 0.023150:090A08",
		forecast: false,
	},
	{
		key: "Accumulated precipitation",
		value: "https://maps.openweathermap.org/maps/2.0/weather/PA0/{z}/{x}/{y}?opacity=0.3&units=imperial&fill_bound=true&appid=",
		legend: "0:00000000; 0.1:C8969600; 0.2:9696AA00; 0.5:7878BE19; 1:6E6ECD33; 10:5050E1B2; 140:1414FFE5",
		forecast: false,
	},
	{
		key: "Accumulated precipitation - rain",
		value: "https://maps.openweathermap.org/maps/2.0/weather/PAR0/{z}/{x}/{y}?opacity=0.3&units=imperial&fill_bound=true&appid=",
		legend: "0:E1C86400; 0.1:C8963200; 0.2:9696AA00; 0.5:7878BE00; 1:6E6ECD4C; 10:5050E1B2; 140:1414FFE5",
		forecast: false,
	},
	{
		key: "Accumulated precipitation - snow",
		value: "https://maps.openweathermap.org/maps/2.0/weather/PAS0/{z}/{x}/{y}?opacity=0.3&units=imperial&fill_bound=true&appid=",
		legend: "0:00000000; 5:00D8FFFF; 10:00B6FFFF; 25.076:9549FF",
		forecast: false,
	},
	{
		key: "Depth of snow",
		value: "https://maps.openweathermap.org/maps/2.0/weather/SD0/{z}/{x}/{y}?opacity=0.3&units=imperial&fill_bound=true&appid=",
		legend: "0.05:EDEDED; 0.1:D9F0F4; 0.2:A5E5EF; 0.3:7DDEED; 0.4:35D2EA; 0.5:00CCE8; 0.6:706DCE; 0.7:514FCC; 0.8:3333CC; 0.9:1818CC; 1.2:C454B7; 1.5:C12CB0; 1.8:BF00A8; 2.5:85408C; 3.0:7F2389; 4.0:790087; 15:E80068",
		forecast: false,
	},
	{
		key: "Wind Speed",
		value: "https://maps.openweathermap.org/maps/2.0/weather/WS10/{z}/{x}/{y}?opacity=0.3&units=metric&fill_bound=true&appid=",
		//legend: "1:FFFFFF00; 5:EECECC66; 15:B364BCB3; 25:3F213BCC; 50:744CACE6; 100:4600AFFF; 200:0D1126FF"
		legend: "0:6472B3FF; 10:5C93A7FF; 20:5D8E7AFF; 40:76A047FF; 60:9B6F5F; 80:8A518EFF; 120:64879EFF",
		forecast: true,
	},
	// {
	// 	key: "Wind Speed and Direction",
	// 	value: "https://maps.openweathermap.org/maps/2.0/weather/WND/{z}/{x}/{y}?opacity=0.3&units=imperial&fill_bound=true&appid=",
	// 	legend: "1:FFFFFF00; 5:EECECC66; 15:B364BCB3; 25:3F213BCC; 50:744CACE6; 100:4600AFFF; 200:0D1126FF"
	// },
	{
		key: "Pressure",
		value: "https://maps.openweathermap.org/maps/2.0/weather/APM/{z}/{x}/{y}?opacity=0.3&units=imperial&fill_bound=true&appid=",
		legend: "94000:0073FF; 96000:00AAFF; 98000:4BD0D6; 100000:8DE7C7; 101000:B0F720; 102000:F0B800; 104000:FB5515; 106000:F3363B; 108000:C60000",
		forecast: true,
	},
	{
		key: "Temperature",
		value: "https://maps.openweathermap.org/maps/2.0/weather/TA2/{z}/{x}/{y}?opacity=0.3&units=imperial&fill_bound=true&appid=",
		legend: "-85:821692; -70:821692; -50:821692; -40:821692; -20:8257DB; -0:208CEC; 15:20C4E8; 32:23DDDD; 50:C2FF28; 70:FFF028; 77:FFC228; 86:FC8014",
		forecast: true,
	},
	{
		key: "Temperature (Dew Point)",
		value: "https://maps.openweathermap.org/maps/2.0/weather/TD2/{z}/{x}/{y}?opacity=0.3&units=imperial&fill_bound=true&appid=",
		legend: "-85:821692; -70:821692; -50:821692; -40:821692; -20:8257DB; -0:208CEC; 15:20C4E8; 32:23DDDD; 50:C2FF28; 70:FFF028; 77:FFC228; 86:FC8014",
		forecast: true,
	},
	// {
	// 	key: "Soil temperature 0-10 сm",
	// 	value: "https://maps.openweathermap.org/maps/2.0/weather/TS0/{z}/{x}/{y}?opacity=0.3&units=imperial&fill_bound=true&appid=",
	// 	legend: "203.15:491763; 228.15:4E1378; 235.15:514F9B; 239.15:446DA9; 243.15:5C85B7; 247.15:739FC5; 251.15:88A7C9; -255.15:6CBCD4; 259.15:87CADC; 263.15:A7D8E5; 267.15:A7D5AD; 271.15:D2E9C8; 275.15:FEFEBB; 279.15:F5CEBB; 283.15:F2B68A; 287.15:EE934F; 291.15:EB702D; 295.15:E8706E; 303.15:CC2C44; 313.15:CC0000; 323.15:990000"
	// },
	// {
	// 	key: "Soil temperature >10 сm",
	// 	value: "https://maps.openweathermap.org/maps/2.0/weather/TS10/{z}/{x}/{y}?opacity=0.3&units=imperial&fill_bound=true&appid=",
	// 	legend: "203.15:491763; 228.15:4E1378; 235.15:514F9B; 239.15:446DA9; 243.15:5C85B7; 247.15:739FC5; 251.15:88A7C9; -255.15:6CBCD4; 259.15:87CADC; 263.15:A7D8E5; 267.15:A7D5AD; 271.15:D2E9C8; 275.15:FEFEBB; 279.15:F5CEBB; 283.15:F2B68A; 287.15:EE934F; 291.15:EB702D; 295.15:E8706E; 303.15:CC2C44; 313.15:CC0000; 323.15:990000"
	// },
	{
		key: "Humidity",
		value: "https://maps.openweathermap.org/maps/2.0/weather/HRD0/{z}/{x}/{y}?opacity=0.3&units=imperial&fill_bound=true&appid=",
		legend: "0:db1200; 20:965700; 40:ede100; 60:8bd600; 80:00a808; 100:000099; 100.1:000099",
		forecast: true,
	},
	{
		key: "Cloudiness",
		value: "https://maps.openweathermap.org/maps/2.0/weather/CL/{z}/{x}/{y}?opacity=0.3&units=imperial&fill_bound=true&appid=",
		legend: "0:FFFFFF00; 10:FDFDFF19; 20:FCFBFF26; 30:FAFAFF33; 40:F9F8FF4C; 50:F7F7FF66; 60:F6F5FF8C; 70:F4F4FFBF; 80:E9E9DFCC; 90:DEDEDED8; 100:D2D2D2FF; 200:D2D2D2FF",
		forecast: true,
	},
]