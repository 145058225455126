import React from 'react';

export default function CommonLayerPopup(props) {
  const { 
    children
  } = props;

  return (
    <div>
      <h5 className="mb-0">Popup Layers</h5>
      <hr className="my-1" />
      {children}
    </div>
  );
}
