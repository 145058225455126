import React from 'react';
export const StylishNewRadio = ({
  className,
  disabled,
  checked,
  onChange,
  key,
  label,
  id,
  value,
  name,
}) => {
  return (
    <>
      <div
        className={
          'form-radio align-items-center ' + (className ? className : '')
        }
      >
        <input
          type="radio"
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          value={value}
          key={key}
          name={name}
        />
        <span className="icon"></span>
        <label className="form-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </>
  );
};
