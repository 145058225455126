import { ReactNode } from 'react';

interface Props {
  pageTitle: string;
  children: ReactNode;
  pageSubTitle?: string;
}

export const TemplateStep = ({ pageTitle, children, pageSubTitle }: Props) => {
  return (
    <div className="step-content w-100 mt-3">
      <div className="title">{pageTitle}</div>
      <div className="description">{pageSubTitle}</div>
      {children}
    </div>
  );
};
