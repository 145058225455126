import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import { fetchIcsWorkAssignments } from 'slices/ics_workAssignmentsSlice';

import { getUserGuid, getGroupGuid } from 'slices/sliceHelpers';

// Slice
const ics_incidentWorkAssignmentsSlice = createSlice({
    name: 'ics_incidentWorkAssignments',
    initialState: {
        ics_incident_work_assignments: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        fetchIcsIncidentWorkAssignmentsSuccess: (state, action) => {
            state.ics_incident_work_assignments = action.payload;
            state.status = 'succeeded';
        },
        createIcsIncidentWorkAssignmentSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        updateIcsIncidentWorkAssignmentSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    fetchIcsIncidentWorkAssignmentsSuccess,
    createIcsIncidentWorkAssignmentSuccess,
    updateIcsIncidentWorkAssignmentSuccess,
    setError
} = ics_incidentWorkAssignmentsSlice.actions;

export default ics_incidentWorkAssignmentsSlice.reducer;

export const createIcsIncidentWorkAssignment = (incidentId, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_incident_work_assignments}/${incidentId}`, {...data, user_guid});
        dispatch(fetchIcsWorkAssignments(group_guid));
        dispatch(fetchIcsIncidentWorkAssignments(incidentId));
        dispatch(createIcsIncidentWorkAssignmentSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsIncidentWorkAssignments = (incidentId) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_incident_work_assignments}/${incidentId}`, {
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsIncidentWorkAssignmentsSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsIncidentWorkAssignment = (incidentId, id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_incident_work_assignments}/${incidentId}/${id}`, {...data, user_guid});
        dispatch(fetchIcsWorkAssignments(group_guid));
        dispatch(fetchIcsIncidentWorkAssignments(incidentId));
        dispatch(updateIcsIncidentWorkAssignmentSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
