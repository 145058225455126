import { Row } from 'react-bootstrap';
import FormSelectField from '../../IAP/formFields/FormSelectField';
import FormTextField from '../../IAP/formFields/FormTextField';
import React from 'react';
import { useAors } from './notificationJobHooks';
import { Link } from 'react-router-dom';

export function SelectAlertTypeStep() {
  const aorQuery = useAors();

  return (
    <>
      <Row>
        <div className={'txt--sml txt--bold pb-2'}>
          Notes on making new alerts.
        </div>
        <div className={'txt--sml text-weak pb-3'}>
          <p>
            You MUST have at least one AOR defined before creating a new alert.
          </p>
          <p>
            Create a Notification Template for the best results. Go to the
            Notification page, compose a new notification and save it as a
            template. All the recipient and messaging information will be
            reusable. There are notification limitations unless a template is
            used.
          </p>
          <Link to="/notifications/compose">Add a Template</Link>
        </div>
      </Row>

      <Row>
        <FormSelectField
          title={'Choose the alert type'}
          fieldName={'jobType'}
          options={[
            {
              value: 'riskJob',
              label: 'Wind Risk Alert',
            },
          ]}
          rules={{
            required: { value: true, message: 'Please select an alert type' },
          }}
          variant={'half'}
        />
      </Row>
      <Row>
        <FormTextField
          title={'Write a short description for this alert'}
          fieldName={'short_description'}
          rules={{
            maxLength: {
              value: 200,
              message: 'Please provide a description less than 200 characters',
            },
          }}
        />
      </Row>
      <Row>
        <Row>
          <FormSelectField
            title={'Choose the location to monitor'}
            fieldName={'aor'}
            options={aorQuery.data?.map((aor) => ({
              value: aor.id,
              label: aor.name,
            }))}
            rules={{
              required: { value: true, message: 'Please select an AOR' },
            }}
            variant={'half'}
          />
        </Row>
        <FormTextField
          title={'Threshold Value (mph)'}
          fieldName={'threshold'}
          rules={{
            required: { value: true, message: 'Please set a threshold value' },
            pattern: {
              value: /^[0-9]+$/,
              message: 'Please enter a valid number',
            },
          }}
          variant={'half'}
        />
      </Row>
      <Row>
        <FormSelectField
          title={'Choose how frequently to check for changes'}
          fieldName={'pattern'}
          options={[
            {
              value: '*/5 * * * *',
              label: 'Every 5 Minutes',
            },
            {
              value: '*/15 * * * *',
              label: 'Every 15 Minutes',
            },
          ]}
          rules={{
            required: { value: true, message: 'Please select an alert type' },
          }}
          variant={'half'}
        />
      </Row>
    </>
  );
}
