import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';

import './Chart.css';

import { formatDataForChart, formatDataForVisualMap } from './ChartHelpers';

export default function Chart({
  layer,
  owmToken,
  clickEventObject,
  selectedDatetime,
  forecastData,
  setForecastData,
}) {

  useEffect(() => {
    if (!forecastData) {
      fetch(
        `https://pro.openweathermap.org/data/2.5/forecast/hourly?lat=${clickEventObject.lngLat.lat}&lon=${clickEventObject.lngLat.lng}&units=imperial&appid=${owmToken}`
      )
        .then((res) => res.json())
        .then((response) => {
          setForecastData(response);
        })
        .catch((error) => {
          console.error('owm ERROR', error);
        });
    }
  }, [layer,forecastData]);

  const chartSeries = formatDataForChart(layer, forecastData,selectedDatetime)

  const chartConfig = {
    title: {
      text: layer.key,
    },
    backgroundColor: '#222529',
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      backgroundColor: '#343a40',
      borderColor: '#495057',
      textStyle: {
        color:"#FFF"
      },
    },
    legend: {
      data: [layer.key],
    },
    grid: {
      left: '5%',
      right: '20%',
      bottom: '15%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
        dataZoom: {
          yAxisIndex: 'none',
        },
        restore: {},
      },
    },
    dataZoom: [
      {
        id: 'dataZoomX',
        type: 'slider',
        xAxisIndex: [0][0],
        filterMode: 'filter',
      },
      {
        id: 'dataZoomY',
        type: 'slider',
        yAxisIndex: [0],
        filterMode: 'empty',
      },
    ],
    xAxis: [
      {
        name: 'Site Time',
        type: 'time',
        scale: true,
        data: [],
        boundaryGap: false,
      },
      {
        name: 'UTC',
        type: 'time',
        scale: true,
        data: [],
      },
    ],
    yAxis: {
      type: 'value',
      scale: true,
    },
    series: [chartSeries],
    visualMap: formatDataForVisualMap(layer, forecastData),
  };

  function onChartReady() {}

  function onChartClick(e) {}

  const onEvents = {
    click: onChartClick,
    //'legendselectchanged': this.onChartLegendselectchanged
  };

  return (
    <div className="map-chart">
      {(!!forecastData && (
        <>
          {(!!chartSeries && !!chartSeries.data && !!chartSeries.data.length && (
            <ReactECharts
              option={chartConfig}
              theme={'dark'}
              onChartReady={onChartReady}
              onEvents={onEvents}
              opts={{ renderer: 'svg' }}
              className="Map-Chart"
            />
          )) || (
            <div className="weight-600 p-3">Data out of 4 day forecast range</div>
          )}
        </>
      )) || (<div className="weight-600 p-3">Loading chart...</div>)}
    </div>
  );
}
