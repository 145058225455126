import { Offcanvas, Row } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { AddEntityDrawerProps } from './EntityListProps';
import {
  WorkAssignment,
  useCreateWorkAssignment,
} from './hooks/workAssignmentHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import FormDateTimeField from './formFields/FormDateTimeField';
import { useActualIncidentId } from './hooks/commonHooks';
import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';
import FormSelectField from './formFields/FormSelectField';
import { work_assignment_statuses } from 'components/ICS/ICSConstants';
import RelationshipPickerField from './formFields/RelationshipPickerField';
import { Coms, useComs } from './hooks/comsHooks';
import { StrikeTeam, useStrikeTeams } from './hooks/strikeTeamHooks';
import { ResourceSet, useResourceSets } from './hooks/resourceSetHooks';
import BranchDrawer from './branch/BranchDrawer';
import DivisionDrawer from './division/DivisionDrawer';

import { getAllDivision } from 'actions/IAPForm204Actions';
import { useDispatch, useSelector } from 'react-redux';

type FormFields = {
  work_assignment_id: string;
  branch?: string;
  division?: string;
  work_assignment: string;
  resources?: string;
  overhead_positions?: string;
  reporting_location?: string;
  requested_arrival_time?: Date;
  special_instructions?: string;
  archived?: boolean;
  geolocation: any;
  communications: UUID[];
  strike_teams: UUID[];
  resource_sets: UUID[];
  leader: string;
  leader_contact: string;
  status: string;
  emergency_coms_medical: string;
  emergency_coms_evac: string;
  emergency_coms_other: string;
};

type Branch = {
  id: string;
  name: string;
};

type Division = {
  id: string;
  name: string;
};

const WorkAssignmentAddDrawer: React.FC<AddEntityDrawerProps> = ({
  toggle,
}) => {
  const form = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      communications: [],
      strike_teams: [],
      resource_sets: [],
    },
  });
  const { handleSubmit, setValue } = form;
  const reduxDispatch = useDispatch();

  const mutation = useCreateWorkAssignment();
  const incidentId = useActualIncidentId();
  const [geolocation, setGeolocation] = useState<any>();
  const [branchDrawerOpen, setBranchDrawerOpen] = useState(false); // Controls BranchDrawer visibility
  const [selectedBranch, setSelectedBranch] = useState<Branch | null>(null);
  const [divDrawerOpen, setDivDrawerOpen] = useState(false); // Controls BranchDrawer visibility
  const [selectedDiv, setSelectedDiv] = useState<Branch | null>(null);

  const communicationOptions = useComs();
  const strikeTeamOptions = useStrikeTeams();
  const resourceSetOptions = useResourceSets();

  const onNewWorkAssignmentDataSubmit = async (formData: FormFields) => {
    const payload = {
      ...formData,
      archived: formData.archived ?? false,
      incident_id: incidentId,
      geolocation: geolocation,
      branch: selectedBranch?.id, // Use selected branch ID
      division: selectedDiv?.id,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  const handleBranchSelect = (branch: Branch) => {
    setSelectedBranch(branch);
    setValue('branch', branch.name); // Set branch name in form field
    setBranchDrawerOpen(false);
  };

  const openBranchDrawer = () => {
    setBranchDrawerOpen(true);
  };

  const closeBranchDrawer = () => {
    setBranchDrawerOpen(false);
  };

  const handleDivSelect = (division: Division) => {
    setSelectedDiv(division);
    setValue('division', division.name); // Set branch name in form field
    setDivDrawerOpen(false);
  };

  const openDivDrawer = () => {
    setDivDrawerOpen(true);
  };

  const closeDivhDrawer = () => {
    setDivDrawerOpen(false);
  };

  useEffect(() => {
    if (!!selectedBranch) {
      reduxDispatch(getAllDivision(selectedBranch.id));
    }
  }, [selectedBranch]);

  return (
    <DrawerWrapper toggle={toggle} title="Add Work Assignment">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onNewWorkAssignmentDataSubmit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextAreaField
                title="Work Assignment"
                fieldName="work_assignment"
                rules={{
                  required: { value: true, message: 'This field is required' },
                }}
                variant="full"
              />
            </Row>
            <Row>
              <div className="mb-3">
                <label className="form-label">Branch</label>
                <div className="d-flex">
                  <input
                    className="form-control"
                    value={selectedBranch?.name || ''}
                    readOnly
                    placeholder="Select Branch"
                    onClick={openBranchDrawer}
                  />
                  <StylishNewButton
                    type="button"
                    className="button--secondary ms-2"
                    onClick={openBranchDrawer}
                  >
                    Select Branch
                  </StylishNewButton>
                </div>
              </div>
            </Row>
            <Row>
              <div className="mb-3">
                <label className="form-label">Division</label>
                <div className="d-flex">
                  <input
                    className="form-control"
                    value={selectedDiv?.name || ''}
                    readOnly
                    placeholder="Select Division"
                    onClick={openDivDrawer}
                  />
                  <StylishNewButton
                    type="button"
                    className="button--secondary ms-2"
                    onClick={openDivDrawer}
                  >
                    Select Division
                  </StylishNewButton>
                </div>
              </div>
            </Row>
            <Row>
              <FormTextField
                title="Leader"
                fieldName="leader"
                rules={{
                  maxLength: {
                    value: 255,
                    message: 'This maximum length is 255 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextField
                title="Leader Contact #"
                fieldName="leader_contact"
                rules={{
                  maxLength: {
                    value: 255,
                    message: 'This maximum length is 255 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Overhead Positions"
                fieldName="overhead_positions"
                variant="full"
              />
            </Row>
            <Row>
              <div className="m-0">
                <label className="form-label">Reporting Location:</label>
                <GeoLocationComponent
                  location={geolocation}
                  setLocation={setGeolocation}
                />
              </div>
            </Row>
            <Row>
              <FormDateTimeField
                title="Requested Arrival"
                fieldName="requested_arrival_time"
                variant="half"
                type="datetime-local"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Special Instructions"
                fieldName="special_instructions"
                variant="full"
              />
            </Row>
            <Row>
              <FormSelectField
                title="Status"
                fieldName="status"
                rules={{
                  required: { value: true, message: 'This field is required' },
                }}
                options={work_assignment_statuses}
                variant="half"
              />
            </Row>
            <Row>
              <RelationshipPickerField<Coms>
                label={'Communications'}
                fieldName={'communications'}
                defaultValue={[]}
                options={communicationOptions.data ?? []}
                title={(entity) => entity.channel_name ?? entity.branch}
                cardType={'coms'}
              />
            </Row>
            <Row>
              <RelationshipPickerField<StrikeTeam>
                label={'Strike Teams'}
                fieldName={'strike_teams'}
                defaultValue={[]}
                options={strikeTeamOptions.data}
              />
            </Row>
            <Row>
              <RelationshipPickerField<ResourceSet>
                label={'Resource Sets'}
                fieldName={'resource_sets'}
                defaultValue={[]}
                options={resourceSetOptions.data}
              />
            </Row>
            <Row>
              <FormTextField
                title="Emergency Coms - Medical"
                fieldName="emergency_coms_medical"
                rules={{
                  maxLength: {
                    value: 255,
                    message: 'This maximum length is 255 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextField
                title="Emergency Coms - Evacuation"
                fieldName="emergency_coms_evac"
                rules={{
                  maxLength: {
                    value: 255,
                    message: 'This maximum length is 255 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextField
                title="Emergency Coms - Other"
                fieldName="emergency_coms_other"
                rules={{
                  maxLength: {
                    value: 255,
                    message: 'This maximum length is 255 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            {/* Add other fields as necessary */}
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>

      {!!branchDrawerOpen && (
        <BranchDrawer
          show={branchDrawerOpen} // Controls whether the BranchDrawer is shown
          onClose={closeBranchDrawer}
          selectedBranch={selectedBranch}
          updateSelectedBranch={handleBranchSelect} // Updates the selected branch in the form
          parent="Work Assignment" // Pass context if necessary
          rowSelectable={true} // Ensure that rowSelectable is passed correctly
        />
      )}

      {!!divDrawerOpen && (
        <DivisionDrawer
          show={divDrawerOpen}
          onClose={closeDivhDrawer}
          selectedDivision={selectedDiv}
          selectedBranch={selectedBranch}
          updateSelectedDivision={handleDivSelect}
          parent="Work Assignment"
          rowSelectable={true}
        />
      )}
    </DrawerWrapper>
  );
};

export default WorkAssignmentAddDrawer;
