import React from 'react';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { Button } from 'react-bootstrap';

type Step = {
  id: number;
  label: string;
  isActive: boolean;
}

interface Props {
  steps: Step[];
  onBack?: () => void;
}

export const PratusBreadcrumb = ({ steps, onBack }: Props) => {
  if (!steps.length) {
    return null;
  }

  return (
    <div className="breadcrumbs-container d-flex align-items-center gap-1">
      <Button
        className="d-flex m-0 me-3 p-0 border-0 bg-transparent"
        onClick={onBack ? onBack : undefined}
      >
        <SharedIcon iconName="arrow_back" size="md" />
      </Button>

      <div>
        {steps.map((step, index) => (
          <React.Fragment key={step.id}>
            <span
              className={`cursor-pointer text-uppercase ${
                index >= steps.length - 1
                  ? 'active'
                  : 'inactive'
              }`}
            >
              {step.label}
            </span>

            {index !== steps.length - 1 ? (
              <span className="mx-2">/</span>
            ) : null}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
