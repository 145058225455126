export const DELETE_INCIDENT_ASSET_REQUEST = 'DELETE_INCIDENT_ASSET_REQUEST';
export const DELETE_INCIDENT_ASSET_SUCCESS = 'DELETE_INCIDENT_ASSET_SUCCESS';
export const DELETE_INCIDENT_ASSET_ERROR = 'DELETE_INCIDENT_ASSET_ERROR';
export const TOGGLE_ASSETS_ON_MAP_REQUEST = 'TOGGLE_ASSETS_ON_MAP_REQUEST';
export const TOGGLE_ASSETS_ON_MAP_SUCCESS = 'TOGGLE_ASSETS_ON_MAP_SUCCESS';
export const TOGGLE_ASSETS_ON_MAP_ERROR = 'TOGGLE_ASSETS_ON_MAP_ERROR';
export const TOGGLE_ASSET_ON_MAP_REQUEST = 'TOGGLE_ASSET_ON_MAP_REQUEST';
export const TOGGLE_ASSET_ON_MAP_SUCCESS = 'TOGGLE_ASSET_ON_MAP_SUCCESS';
export const TOGGLE_ASSET_ON_MAP_ERROR = 'TOGGLE_ASSET_ON_MAP_ERROR';
export const FETCH_DATASET_GROUPS_REQUEST = 'FETCH_DATASET_GROUPS_REQUEST';
export const FETCH_DATASET_GROUPS_SUCCESS = 'FETCH_DATASET_GROUPS_SUCCESS';
export const FETCH_DATASET_GROUPS_ERROR = 'FETCH_DATASET_GROUPS_ERROR';
export const UPSERT_SELECTED_DATASET_GROUPS_REQUEST = 'UPSERT_SELECTED_DATASET_GROUPS_REQUEST';
export const UPSERT_SELECTED_DATASET_GROUPS_SUCCESS = 'UPSERT_SELECTED_DATASET_GROUPS_SUCCESS';
export const UPSERT_SELECTED_DATASET_GROUPS_ERROR = 'UPSERT_SELECTED_DATASET_GROUPS_ERROR';
export const GET_SELECTED_DATASET_GROUPS_REQUEST = 'GET_SELECTED_DATASET_GROUPS_REQUEST';
export const GET_SELECTED_DATASET_GROUPS_SUCCESS = 'GET_SELECTED_DATASET_GROUPS_SUCCESS';
export const GET_SELECTED_DATASET_GROUPS_ERROR = 'GET_SELECTED_DATASET_GROUPS_ERROR';
export const FETCH_ASSET_RISKS_REQUEST = 'FETCH_ASSET_RISKS_REQUEST';
export const FETCH_ASSET_RISKS_SUCCESS = 'FETCH_ASSET_RISKS_SUCCESS';
export const FETCH_ASSET_RISKS_ERROR = 'FETCH_ASSET_RISKS_ERROR'; 
export const FETCH_PRECOMPILED_ASSET_RISKS_REQUEST = 'FETCH_PRECOMPILED_ASSET_REQUEST';
export const FETCH_PRECOMPILED_ASSET_RISKS_SUCCESS = 'FETCH_PRECOMPILED_ASSET_SUCCESS';
export const FETCH_PRECOMPILED_ASSET_RISKS_ERROR = 'FETCH_PRECOMPILED_ASSET_RISKS';
